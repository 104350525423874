import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Grid, InputAdornment, Typography } from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import CustomCard from "../../../components/Card";
import CustomInput from "../../../components/Input";
import { apiRequest } from "../../../app/services/requests";
import SelectClient from "../../../components/SelectClient";
import CustomDialog from "../../../components/CustomDialog";
import CustomButton from "../../../components/Button";
import IRATypography from "../../../components/ira-typography/ira-typography";
import Breadcrumb from "../../../components/Breadcrumb";
import ActionCell from "../../../components/TableComponent/action-cell/action-cell";
import NameCell from "../../../components/TableComponent/name-cell/name-cell";
import { IRATableColumnProps } from "../../../components/ira-table/ira-table.interfaces";
import { IRATable } from "../../../components/ira-table/ira-table";
import DateTimeCell from "../../../components/TableComponent/date-time-cell";
import moment from "moment";
import Pagination from "../../../components/Pagination";
import PublishConformationForm from "./publish-confirmation-form";
import VersionHistoryList from "./version-history-list";

interface ISelectDialog {
  open?: boolean;
  data?: any;
}

const PlanList = (props: any) => {
  const { showOnlyList = true, hideBreadScrum } = props;
  const navigate = useNavigate();
  const [openSelectClientDialog, setOpenSelectClientDialog] =
    useState<ISelectDialog>({
      open: false,
      data: {},
    });
  const [openPublishDialog, setOpenPublishDialog] = useState<ISelectDialog>({
    open: false,
    data: {},
  });

  const [openUpgradeDialog, setOpenUpgradeDialog] = useState<any>({
    open: false,
    data: {},
  });

  const [openVersionHistoryDialog, setOpenVersionHistoryDialog] = useState<any>(
    {
      open: false,
      data: {},
    }
  );

  const [planList, setPlanList] = useState({ results: [], totalCount: 0 });
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState<any>(10);
  const [skip, setSkip] = useState<any>(0);
  const [filters, setFilters] = useState({
    search: "",
  });

  const handleSearch = (e: any) => {
    const searchString = e.target.value;
    setFilters({
      search: searchString,
    });
  };

  useEffect(() => {
    getAllPlanList(filters);
  }, [filters, skip, limit]);

  const getAllPlanList = (filters?: any) => {
    // const payload = {
    //   ...(filters.search && {
    //     searchText: filters.search,
    //   }),
    // };
    apiRequest({
      url: `master-plan/list?skip=${skip}&limit=${limit}${
        Boolean(filters.search?.length) ? `&search=${filters.search}` : ""
      }`,
      // data: payload,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          setPlanList(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  const breadcrumbs = [
    <RouterLink to="/Dashboard" style={{ textDecoration: "none" }} key="1001">
      <IRATypography
        key="2"
        textVariant="h12WithSemiBold"
        sx={{ fontSize: 14, color: "#222222" }}
      >
        Home
      </IRATypography>
    </RouterLink>,
    <IRATypography
      key="3"
      textVariant="h12WithSemiBold"
      sx={{ fontSize: 14, color: "#222222" }}
    >
      Plans List
    </IRATypography>,
  ];

  const handleClick = (option: any, rowData: any) => {
    if (option === "assignServices") {
      navigate(
        `/plan/${rowData?.id}/assign-services/${rowData?.categories[0]}`
      );
    }
    if (option === "selectedServices") {
      navigate(
        `/plan/${rowData?.id}/selected-services/${rowData?.categories[0]}`
      );
    }
    if (option === "edit") {
      navigate(`/master/plan/${rowData?.id}/edit`);
    }
    if (option === "publish") {
      setOpenPublishDialog({ open: true, data: { planId: rowData?.id } });
    }
    if (option === "upgrade") {
      setOpenUpgradeDialog({ open: true, data: { planId: rowData?.id } });
    }
    if (option === "versionHistory") {
      setOpenVersionHistoryDialog({
        open: true,
        data: { planId: rowData?.id, planCode: rowData?.code },
      });
    }
  };

  const activityTableColumns = () =>
    [
      {
        name: "planCode",
        label: "Plan Code",
        component: NameCell,
        sortable: false,
        style: { width: "10%" },
      },
      {
        name: "createdAt",
        label: "Created At",
        component: DateTimeCell,
        sortable: false,
        style: { width: "10%" },
      },
      {
        name: "planName",
        label: "Plan Name",
        component: NameCell,
        sortable: false,
        style: { width: "30%" },
      },
      {
        name: "premium",
        label: "Premium",
        component: NameCell,
        sortable: false,
        style: { width: "10%" },
      },
      {
        name: "claimLimit",
        label: "Claim Limit",
        component: NameCell,
        sortable: false,
        style: { width: "10%" },
      },
      {
        name: "categories",
        label: "Plan Categories",
        component: NameCell,
        sortable: false,
        style: { width: "20%" },
      },
      {
        name: "status",
        label: "Status",
        component: NameCell,
        sortable: false,
        style: { width: "10%" },
      },
      {
        name: "version",
        label: "Version",
        component: NameCell,
        sortable: false,
        style: { width: "10%" },
      },
      {
        name: "action",
        label: "Action",
        component: (props: any) => {
          return (
            <ActionCell
              actions={[
                { id: "assignServices", name: "Assign Services" },
                { id: "selectedServices", name: "Selected Services" },
                ...(props?.data?.status === "draft"
                  ? [
                      { id: "edit", name: "Edit" },
                      { id: "publish", name: "Publish" },
                      { id: "versionHistory", name: "Version History" },
                    ]
                  : []),
                ...(props?.data?.status === "published"
                  ? [
                      { id: "edit", name: "Edit" },
                      { id: "upgrade", name: "Update Plan" },
                      { id: "versionHistory", name: "Version History" },
                    ]
                  : []),
              ]}
              onClick={(data) => handleClick(data, { ...props?.data })}
            />
          );
        },
        style: { width: "10%" },
      } as any,
    ] as Array<IRATableColumnProps>;

  const activityTableData = () => {
    return planList?.results?.map((row: any) => {
      let modifiedCategories = row?.categories;
      if (typeof row?.categories === "string") {
        modifiedCategories = row?.categories?.replace(/{|}/g, "").split(",");
      }
      return {
        ...row,
        planCode: {
          name: row?.code,
        },
        createdAt: {
          dateTime: moment(row?.createdAt).format("DD-MM-YYYY"),
        },
        planName: {
          name: row?.name,
        },
        premium: {
          name: row?.premium ?? 0,
        },
        claimLimit: {
          name: row?.limit ?? 0,
        },
        categories: {
          name: modifiedCategories?.length
            ? modifiedCategories?.join(", ")
            : "NA",
        },
        status: {
          name: row?.status ?? 0,
        },
        version: {
          name: `v.${row?.version}`,
        },
        action: {
          data: {
            id: row?.id,
            status: row?.status,
            categories: modifiedCategories,
            code: row?.code,
          },
        },
      };
    });
  };

  const handleButtonClick = (type: string) => {
    navigate(`/master/plan/create`);
  };

  const handlePageChange = (page?: any) => {
    const newSkip = page * limit;
    setSkip(newSkip);
  };

  const handleUpgradeClose = () => {
    setOpenUpgradeDialog({ open: false, data: {} });
  };

  const upgradePlan = () => {
    apiRequest({
      url: `master-plan/${openUpgradeDialog?.data?.planId}/upgrade`,
      method: "POST",
    })
      .then((res) => {
        handleUpgradeClose();
        getAllPlanList({});
      })
      .catch((err) => {});
  };

  return (
    <>
      {!hideBreadScrum && (
        <Box
          sx={{ color: "#222222" }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Breadcrumb
            title={`Plans List`}
            key="case-list-breadcrumb"
            breadcrumbsArr={breadcrumbs}
          />

          <Box style={{ display: "flex", gap: "8px" }} sx={{ pt: 3, pb: 3 }}>
            <CustomButton
              onClick={() => handleButtonClick("addCase")}
              title={"+ Create Plan"}
              backgroundColor={"#5C4EFF"}
            />
          </Box>
        </Box>
      )}

      <Box>
        <CustomCard>
          <Grid container sx={{ padding: "10px" }}>
            <Grid item md={3} sx={{ background: "#F0F2F6", pb: "10px" }}>
              <CustomInput
                size={"small"}
                placeholder="Search by Plan Name"
                placeholderColor="#204289"
                placeholderColorOpacity={1}
                placeholderSize={15}
                value={filters?.search}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      {filters?.search.length > 0 && (
                        <InputAdornment
                          sx={{ cursor: "pointer" }}
                          position="end"
                          onClick={() =>
                            setFilters({
                              search: "",
                            })
                          }
                        >
                          <Close />
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
            </Grid>
            <Box sx={{ width: "100%" }}>
              <IRATable
                columns={activityTableColumns()}
                data={activityTableData()}
                onSort={(name: string, order: string) =>
                  alert(`sort requested for ${name} with order ${order}`)
                }
              />
              <Pagination
                onPageChange={(data: any) => {
                  handlePageChange(data);
                }}
                onRowsPerPageChange={(limit?: number, skip?: number) => {
                  setLimit(limit);
                  setSkip(0);
                }}
                totalCount={planList.totalCount}
              />
            </Box>
          </Grid>
        </CustomCard>
      </Box>

      <CustomDialog>
        <Box>
          <SelectClient
            onClose={() => setOpenSelectClientDialog({ open: false, data: {} })}
            navigateTo={(data: string) => {
              navigate(`/cases/patient/${"121213"}/add`);
            }}
          />
        </Box>
      </CustomDialog>

      {openPublishDialog?.open && (
        <CustomDialog
          width={"400px"}
          open={openPublishDialog.open}
          title={"Publish Plan"}
          handleClose={() => setOpenPublishDialog({ open: false, data: {} })}
        >
          <Box>
            <Box mb={3}>
              <Typography>Are you want to publish the plan ?</Typography>
            </Box>
            <PublishConformationForm
              planId={openPublishDialog?.data?.planId}
              onClose={() => setOpenPublishDialog({ open: false, data: {} })}
              onGetAllPlanList={() => getAllPlanList({})}
            />
          </Box>
        </CustomDialog>
      )}
      {openUpgradeDialog?.open && (
        <CustomDialog
          width={"400px"}
          open={openUpgradeDialog.open}
          title={"Upgrade Plan"}
          handleClose={handleUpgradeClose}
        >
          <Box>
            <Box mb={3}>
              <Typography>Are you want to upgrade the plan ?</Typography>
            </Box>
            {/* Additional Sections can be added as needed */}
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <CustomButton
                border="1px solid #000000"
                color="#000000"
                title="No"
                bgColor="#ffffff"
                onClick={handleUpgradeClose}
                borderRadius="5px"
              />
              <Box marginLeft={1}>
                {" "}
                {/* Add a margin to create space between buttons */}
                <CustomButton
                  title={"Yes"}
                  type="button"
                  borderRadius="5px"
                  handleButtonClick={() => upgradePlan()}
                />
              </Box>
            </Box>
          </Box>
        </CustomDialog>
      )}

      {openVersionHistoryDialog?.open && (
        <CustomDialog
          maxWidth={"800px"}
          open={openVersionHistoryDialog.open}
          title={"Version History"}
          handleClose={() => {
            setOpenVersionHistoryDialog({ open: false, data: {} });
          }}
        >
          <Box>
            <VersionHistoryList
              planCode={openVersionHistoryDialog?.data?.planCode}
            />
          </Box>
        </CustomDialog>
      )}
    </>
  );
};

export default React.memo(PlanList);
