import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Card, CardContent, Typography } from "@mui/material";
import CustomCard from "../../components/Card";
import CaseHistory from "./case-history";
import PreOperativePhotos from "./pre-operative-photos";
import TreatmentPlanning from "./treatment-planning";
import CustomButton from "../../components/Button";
import CaseInvestigation from "./Investigation";
import { apiRequest } from "../../app/services/requests";
import { useNavigate, useParams } from "react-router-dom";
import Examination from "./Examination";
import Breadcrumb from "../../components/Breadcrumb";
import IRATypography from "../../components/ira-typography/ira-typography";
import { Link as RouterLink } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import moment from "moment";

const AddEditCase = (props: any) => {
  const cardOptions = [
    {
      id: 1,
      cardIcon: "",
      cardTitle: "Case History",
      actionName: "Add/Edit",
      description: `Lorem Ipsum is simply dummy text of the
        printing and typesetting industry.`,
      cardType: "caseHistory", // Add cardType property
    },
    {
      id: 2,
      cardIcon: "",
      cardTitle: "Examination",
      actionName: "Add/Edit",
      description: `Lorem Ipsum is simply dummy text of the
        printing and typesetting industry.`,
      cardType: "examination", // Add cardType property
    },
    {
      id: 3,
      cardIcon: "",
      cardTitle: "Investigation",
      actionName: "Add/Edit",
      description: `Lorem Ipsum is simply dummy text of the
        printing and typesetting industry.`,
      cardType: "investigation", // Add cardType property
    },
    {
      id: 4,
      cardIcon: "",
      cardTitle: "Pre Operative Photographs",
      actionName: "Add/Edit",
      description: `Lorem Ipsum is simply dummy text of the
        printing and typesetting industry.`,
      cardType: "preOperativePhotographs", // Add cardType property
    },
    {
      id: 5,
      cardIcon: "",
      cardTitle: "Tentative Treatment Planning",
      actionName: "Add/Edit",
      description: `Lorem Ipsum is simply dummy text of the
        printing and typesetting industry.`,
      cardType: "tentativeTreatmentPlanning", // Add cardType property
    },
  ];

  const [selectedCaseOption, setSelectedCaseOption] = useState<any>({
    cardType: null,
  });

  const navigate = useNavigate();
  const [clientDetails, setClientDetails] = useState<any>({});
  const [investigationData, setInvestigationData] = useState<any>([]);
  const { patientId } = useParams();

  const handleAddEditClick = (cardOption: any) => {
    setSelectedCaseOption(cardOption);
  };

  const handleClose = () => {
    setSelectedCaseOption({
      cardType: null,
    });
  };

  const [dentalFormData, setDentalFormData] = useState({});

  const renderCardComponent = (cardType: string) => {
    switch (cardType) {
      case "caseHistory":
        return <CaseHistory isViewOnly={false} onClose={handleClose} />;
      case "examination":
        return (
          <Examination
            investigationData={investigationData}
            setInvestigationData={setInvestigationData}
            dentalFormData={dentalFormData}
            setDentalFormData={setDentalFormData}
            // {...commonActions}
            openModal={""}
            handleModal={""}
            periodentalChart={""}
            setPeriodentalChart={""}
            selectedPeriodentalData={""}
            defaultPeriodentalData={""}
            setSelectedPeriodentalData={""}
            treatments={""}
          />
        );
      case "investigation":
        return <CaseInvestigation onClose={handleClose} />;
      case "preOperativePhotographs":
        return <PreOperativePhotos onClose={handleClose} />;
      case "tentativeTreatmentPlanning":
        return <TreatmentPlanning onClose={handleClose} />;
      default:
        return null; // Default case, you can handle accordingly
    }
  };

  const breadcrumbs = [
    <RouterLink to="/Dashboard" style={{ textDecoration: "none" }} key="1001">
      <IRATypography
        key="2"
        textVariant="h12WithSemiBold"
        sx={{ fontSize: 14, color: "#222222" }}
      >
        Home
      </IRATypography>
    </RouterLink>,
    <IRATypography
      key="3"
      textVariant="h12WithSemiBold"
      sx={{ fontSize: 14, color: "#222222" }}
    >
      Add Case
    </IRATypography>,
  ];

  useEffect(() => {
    getClientDetails();
  }, []);

  const getClientDetails = () => {
    apiRequest({
      url: `client/${patientId}`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          setClientDetails(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };
  const navigateToClientProfile = () => {
    navigate(`/client/${clientDetails?.id}/view/plan-details`);
  };

  return (
    <Container maxWidth="lg" sx={{ padding: "0 !important" }}>
      {/* <Box pt={2}>
        <Breadcrumb
          title={`Add Case`}
          key="case-list-breadcrumb"
          breadcrumbsArr={breadcrumbs}
        />
      </Box> */}
      <Box mt={1} display={"flex"}>
        <Grid container spacing={2}>
          {/* 3 columns */}
          <Grid item xs={4}>
            <Box>
              {/* <CustomCard
                style={{ height: "100vh", backgroundColor: "#FFFFFF" }}
              >
                <Box padding={2}>
                  <Box>
                    <Typography color={"#6A6A6A"} fontSize={"15px"}>
                      Client Details
                    </Typography>
                    <Box display={"flex"} justifyContent={"end"} mt={1}>
                      <Typography
                        sx={{
                          textAlign: "right",
                          color: "#5C4EFF",
                          fontSize: "12px",
                          cursor: "pointer",
                        }}
                        onClick={navigateToClientProfile}
                      >
                        View Client Profile
                        <OpenInNewIcon sx={{ fontSize: "12px" }} />
                      </Typography>
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <Typography color={"#6A6A6A"} fontSize={"15px"}>
                      Patient
                    </Typography>
                  </Box>
                  <Box
                    mt={2}
                    sx={{
                      background: "#E1F1FA",
                      padding: "20px 30px",
                      borderRadius: "10px",
                    }}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDirection={"column"}
                  >
                    <Typography color={"#6A6A6A"} fontSize={"13px"}>
                      Patient Name :{" "}
                      <b>
                        {clientDetails?.firstName} {clientDetails?.lastName}
                      </b>
                    </Typography>
                    <Typography color={"#6A6A6A"} fontSize={"13px"}>
                      Case ID : <b>{clientDetails?.id}</b>
                    </Typography>
                  </Box>
                  <Box mt={3}>
                    <Typography color={"#6A6A6A"} fontSize={"16px"}>
                      Patient Details
                    </Typography>
                    <br></br>
                    <Box>
                      <Typography
                        color={"#6A6A6A"}
                        fontSize={"13px"}
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        Mobile: <b> {clientDetails?.mobileNumber}</b>
                      </Typography>
                      <Typography
                        color={"#6A6A6A"}
                        fontSize={"13px"}
                        mt={2}
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        Sex: <b> {clientDetails?.gender}</b>
                      </Typography>{" "}
                      <Typography
                        color={"#6A6A6A"}
                        fontSize={"13px"}
                        mt={2}
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        DOB:{" "}
                        <b>
                          {moment(clientDetails?.dateOfBirth).format(
                            "DD-MM-YYYY"
                          )}
                        </b>
                      </Typography>
                      <Typography
                        color={"#6A6A6A"}
                        fontSize={"13px"}
                        mt={2}
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        Relation: <b>Spouse</b>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </CustomCard> */}
            </Box>
          </Grid>
          {/* 9 columns */}
          <Grid item xs={12}>
            {/* Card 1 */}
            <Paper sx={{ padding: "15px", height: "100%" }}>
              {!selectedCaseOption?.cardType ? (
                <>
                  <Box mb={3}>
                    <Typography variant="h6">Add Case Details</Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    height="100%" // Ensure the Box takes full height of the parent Paper
                    flexWrap="wrap" // Allow flex items to wrap to the next row
                  >
                    <Box display="flex" flexDirection="column">
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        flexWrap="wrap"
                        gap={4}
                      >
                        {cardOptions?.map((cardOption, index) => (
                          <Card
                            key={`${cardOption?.id}-${index}`}
                            style={{
                              width: "350px",
                              height: "100px",
                              borderRadius: "8px",
                            }}
                          >
                            <CardContent>
                              <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent={"space-between"}
                              >
                                <Box
                                  fontSize="16px"
                                  fontWeight="bold"
                                  marginBottom="8px"
                                >
                                  {cardOption?.cardTitle}
                                </Box>
                                <CustomButton
                                  variant="contained"
                                  color="primary"
                                  title={"Add +"}
                                  onClick={() => handleAddEditClick(cardOption)}
                                />
                              </Box>
                            </CardContent>
                          </Card>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <>{renderCardComponent(selectedCaseOption.cardType)}</>
              )}
            </Paper>
          </Grid>
        </Grid>
        <></>
      </Box>
    </Container>
  );
};

export default AddEditCase;
