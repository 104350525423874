import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { styles } from "./styles";
import { apiRequest } from "../../app/services/requests";
import { useNavigate } from "react-router-dom";

const CaseHistoryDetails = (props: any) => {
  const { caseHistoryID } = props;
  console.log(caseHistoryID, "CHid");

  const [caseHistoryDetails, setCaseHistoryDetails] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (caseHistoryID && caseHistoryID.caseHistoryId) {
      getCaseHistoryById(caseHistoryID.caseHistoryId);
    }
  }, [caseHistoryID]);

  const getCaseHistoryById = (id: string) => {
    apiRequest({
      url: `case/case-history/${id}`,
      method: "GET",
    })
      .then((res: any) => {
        if (res) {
          setCaseHistoryDetails(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  if (!caseHistoryDetails) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <>
      <Box sx={{ padding: 3, gap: 2 }}>
        <Typography>Case Title</Typography>
        <Typography>
          {" "}
          Title: <strong>{caseHistoryDetails.beneficiaryName}</strong>
        </Typography>
      </Box>
      <Divider />

      <Box sx={{ padding: 3 }}>
        <Typography>
          <strong>History</strong>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography>Present Illness:</Typography>
              <Typography>
                {caseHistoryDetails.presentIllness || "N/A"}
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography>Past Illness:</Typography>
              <Typography>{caseHistoryDetails.pastIllness || "N/A"}</Typography>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography>Family History Illness:</Typography>
              <Typography>
                {caseHistoryDetails.familyHistoryIllness || "N/A"}
              </Typography>
            </Box>
          </Grid>

          <Grid item sm={6}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography>Drug History:</Typography>
              <Typography>{caseHistoryDetails.drugHistory || "N/A"}</Typography>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography>Allergies:</Typography>
              <Typography>{caseHistoryDetails.allergies || "N/A"}</Typography>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography>Medication:</Typography>
              <Typography>{caseHistoryDetails.medication || "N/A"}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography>Medical Information:</Typography>
              <Typography>
                {caseHistoryDetails.medicalInformation || "N/A"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />

      <Box sx={{ padding: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography>Previous Treatments:</Typography>
              <Typography>
                {caseHistoryDetails.previousTreatments || "N/A"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />

      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={1}
        color="#6A6A6A"
        fontSize="14px"
      >
        <Typography>Treatment documents</Typography>
      </Box>

      <Box sx={{ display: "flex", padding: 3 }}>
        {[1, 2, 3, 4].map((index) => (
          <Box sx={{ position: "relative" }} width={"100px"} key={index}>
            <Box>
              <img
                src="https://ecomposer.io/cdn/shop/articles/image9.png?v=1689814372"
                alt={`treatment-document-${index}`}
                style={styles.imageOperative}
              />
              <Box sx={{ position: "absolute", top: "0", right: "0" }}>
                <HighlightOffIcon />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default CaseHistoryDetails;
