import PrivateRoute from "./PrivateRoute";
import HospitalProfile from "../screens/Hospital/hospital-profile";
import ManageClient from "../screens/Client/ManageClient/manage-client";
import ClientDetails from "../screens/Client/client-details";
import ClientList from "../screens/Client/client-list";
import CaseList from "../screens/CasesAndClaims/case-list";
import AddEditCase from "../screens/CasesAndClaims/add-edit-case";
import AppointmentDetails from "../screens/Appointment/appointment-details";
import AppointmentList from "../screens/Appointment/appointment-list";
import SelectPlan from "../screens/Plans/select-plan";
import CreateHealthPlan from "../screens/Plans/HealthPlan/create-health-plan";
import DiscountPlan from "../screens/Plans/DiscountPlan/create-discount-plan";
import CasesClaimsReportList from "../screens/Reports/cases-claims-report-list";
import TreatmentReportList from "../screens/Reports/treatment-report-list";
import ApprovalAppliedReportList from "../screens/Reports/approval-applied-report-list";
import AppointmentReportList from "../screens/Reports/appointment-report-list";
import QueryList from "../screens/Query/query-list";
import PlanAction from "../screens/Plans/PlanAction/plan-action";
import PageNotFound from "./PageNotFound";
import Dashboard from "../screens/Dashboard";
import AddEditUser from "../screens/Users/AddEditUser/add-edit-user";
import UserList from "../screens/Users/UsersList/user-list";
import UserView from "../screens/Users/UserView";
import CaseDetails from "../screens/CasesAndClaims/case-details";
import EditTreatment from "../screens/CasesAndClaims/edit-treatment-planning";
import PendingTreatmentCases from "../screens/CasesAndClaims/pending-treatment-cases";
import PaymentMethod from "../screens/Plans/PlanAction/payment-method";
import AddEditCaseHistory from "../screens/CasesAndClaims/CaseHistory/add-edit-case-history";
import PlanList from "../screens/Masters/Plans/plan-list";
import AddEditMasterPlan from "../screens/Masters/Plans/master-plan-create-edit";
import DoctorProfileCard from "../screens/Doctor/doctor-profile-card";
import AddEditHospital from "../screens/Hospital/add-edit-hospital";
import ClinicList from "../screens/Hospital/DentalServices/clinic-list";
import ServiceSetup from "../screens/Masters/SelectedServices/service-setup";
import AssignServices from "../screens/Masters/assign-services";
import SelectedServices from "../screens/Masters/selected-services";

const routes = [
  {
    path: "/dashboard",
    element: <Dashboard />,
    accessRoles: ["superAdmin", "clinic"],
  },

  // hospital starts here
  {
    path: "/hospital/:hospitalId/profile/:currentTab",
    element: <HospitalProfile />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/hospital/doctors/view/:currentTab",
    element: <DoctorProfileCard />,
    accessRoles: ["superAdmin", "clinic"],
  },

  {
    path: "/hospital/add",
    element: <AddEditHospital />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/hospital/:hospitalId/edit",
    element: <AddEditHospital />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/hospital/list",
    element: <ClinicList />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/add/master-plan",
    element: <AddEditMasterPlan />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/plan/:planId/assign-services/:currentTab",
    element: <AssignServices />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/plan/:planId/selected-services/:currentTab",
    element: <SelectedServices />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/service/setup",
    element: <ServiceSetup />,
    accessRoles: ["superAdmin", "clinic"],
  },
  // hospital starts here
  //
  {
    path: "/user/add",
    element: <AddEditUser />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/user/list",
    element: <UserList />,
    accessRoles: ["superAdmin", "clinic"],
  },

  {
    path: "/user/:userId/view",
    element: <UserView />,
    accessRoles: ["superAdmin", "clinic"],
  },
  ///Users Routes Ends Here

  // client routes starts here
  {
    path: "/clients",
    element: <ClientList />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/client/add",
    element: <ManageClient />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/client/:clientId/edit",
    element: <ManageClient />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/client/:clientId/view/:currentTab",
    element: <ClientDetails />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/client/list",
    element: <ClientList />,
    accessRoles: ["superAdmin", "clinic"],
  },
  // client routes ends here

  // appointment routes starts here
  {
    path: "/appointments",
    element: <AppointmentList />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/appointments/:appointmentID/details",
    element: <AppointmentDetails />,
    accessRoles: ["superAdmin", "clinic"],
  },
  // appointment routes ends here

  // plans routes starts here
  {
    path: "/client/:clientId/select-plan",
    element: <SelectPlan />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/client/:clientId/plan/health",
    element: <CreateHealthPlan />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/client/:clientId/plan/discount",
    element: <DiscountPlan />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/client/:clientId/Add",
    element: <AddEditCaseHistory />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/client/:clientId/plan/payment",
    element: <PaymentMethod />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/client/:clientId/plan/action/:actionName",
    element: <PlanAction />,
    accessRoles: ["superAdmin", "clinic"],
    allowedActions: ["renewal", "upgrade", "upgrade-renewal", "cancel"],
  },
  {
    path: "/master/plan-list",
    element: <PlanList />,
    accessRoles: ["superAdmin", "clinic"],
    // allowedActions: ["renewal", "upgrade", "upgrade-renewal", "cancel"],
  },
  {
    path: "/master/plan/create",
    element: <AddEditMasterPlan />,
    accessRoles: ["superAdmin", "clinic"],
    // allowedActions: ["renewal", "upgrade", "upgrade-renewal", "cancel"],
  },
  {
    path: "/master/plan/:planId/edit",
    element: <AddEditMasterPlan />,
    accessRoles: ["superAdmin", "clinic"],
    // allowedActions: ["renewal", "upgrade", "upgrade-renewal", "cancel"],
  },
  // plan routes ends here

  // cases routes starts here
  {
    path: "/cases",
    element: <CaseList />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/cases/patient/:patientId/add",
    element: <AddEditCase />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/cases/patient/:patientId/:caseId/edit",
    element: <AddEditCase />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/cases/:caseId/details/:currentTab",
    element: <CaseDetails />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/cases/applied-for-approval",
    element: <ApprovalAppliedReportList />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/cases/pending-treatments",
    element: <PendingTreatmentCases />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/query-list",
    element: <QueryList />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/cases/patient/:patientId/:caseId/edit",
    element: <EditTreatment />,
    accessRoles: ["superAdmin", "clinic"],
  },
  // cases routes ends here

  // reports routes starts here
  {
    path: "/reports/cases-and-claims",
    element: <CasesClaimsReportList />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/reports/treatments",
    element: <TreatmentReportList />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/reports/approval-and-applied",
    element: <ApprovalAppliedReportList />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/reports/appointments",
    element: <AppointmentReportList />,
    accessRoles: ["superAdmin", "clinic"],
  },
  {
    path: "/page-not-found",
    element: <PageNotFound />,
    accessRoles: ["superAdmin", "clinic"],
  },
  // reports routes ends here
];

const protectedRoutes = routes.map((route: any) => ({
  ...route,
  element: (
    <PrivateRoute
      roles={route.accessRoles || []}
      allowedActions={route.allowedActions}
    >
      {route.element}
    </PrivateRoute>
  ),
}));

export default protectedRoutes;
