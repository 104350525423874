import React, { useEffect, useState } from "react";
import { useAppContext } from "./app/context/AppContext";
import Layout from "./components/Layout";
import LoginPage from "./screens/Login/login";
import { useNavigate } from "react-router-dom";

function App() {
  const { user: contextUser } = useAppContext() as any;
  const [user, setUser] = useState(contextUser);
  const navigate = useNavigate();

  const navigateBasedOnRole = () => {
    const stingyUser = localStorage.getItem("user");
    if (stingyUser) {
      const user = JSON.parse(stingyUser);
      if (user && user.userType) {
        switch (user.userType) {
          case "clinic":
            navigate("dashboard");
            break;
          case "admin":
            navigate("dashboard");
            break;
          case "client":
            navigate("dashboard");
            break;
          default:
            navigate("login");
        }
      } else {
        navigate("login");
      }
    } else {
      navigate("login");
    }
  };

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user") || "{}");
    setUser(storedUser);
  }, [contextUser]);

  useEffect(() => {
    // navigateBasedOnRole();
  }, []);

  return <>{!user?.accessToken ? <LoginPage /> : <Layout />}</>;
}

export default App;
