import MuiButton, { buttonClasses } from "@mui/material/Button";
import * as React from "react";
import { styled } from "@mui/material/styles";

import { IButtonProps } from "./button.interface";

export const ButtonRoot = (props: IButtonProps) => {
  const { label, btnVariant, btnSize, rounded, loading, children, ...rest } =
    props;

  const renderContent = () => {
    if (loading) {
      return <span className="PrismButton-text">Loading</span>;
    }

    if (label) {
      return <span className="PrismButton-text">{label}</span>;
    }

    return children;
  };

  return <MuiButton {...rest}>{renderContent()}</MuiButton>;
};

const Button = styled(ButtonRoot)(
  ({
    theme,
    btnVariant = "primary",
    btnSize = "medium",
    color = "primary",
    rounded = false,
    loading = false,
  }) => ({
    ...theme.typography.button,
    textTransform: "none",
    [`& .${buttonClasses.endIcon}`]: {
      marginLeft: theme.spacing(1),
      marginRight: 0,
    },
    [`& .${buttonClasses.startIcon}`]: {
      marginRight: theme.spacing(1),
      marginLeft: 0,
    },
    /**
     * Variant
     */
    ...(btnVariant === "primary" && {
      backgroundColor: theme.palette.customColors.lightBlack,
      color: theme.palette.common.white,
    }),
    ...(btnVariant === "secondary" && {
      backgroundColor: "transparent",
      color: theme.palette.customColors.lightBlack,
      border: `1px solid ${theme.palette.customColors.lightBlack}`,
    }),
    ...(btnVariant === "tertiary" && {
      backgroundColor: "inherit",
      color: theme.palette.customColors.lightBlack,

      "& .PrismButton-text": {
        ...(btnVariant === "tertiary" && {
          textDecoration: "underline",
        }),
      },
    }),
    ...(btnVariant === "tag" && {
      backgroundColor: theme.palette.customColors.grey,
    }),
    /**
     * Size
     */
    ...(btnSize === "large" && {
      padding: theme.spacing(4, 6),
      borderRadius: theme.shape.borderRadius,
    }),
    ...(btnSize === "medium" && {
      padding: theme.spacing(3, 6),
      borderRadius: theme.shape.borderRadius,
    }),
    ...(btnSize === "small" && {
      padding: theme.spacing(2, 3),
      borderRadius: theme.shape.borderRadius,
    }),
    ...(btnSize === "smaller" && {
      padding: theme.spacing(2, 3),
      borderRadius: theme.shape.borderRadius,
      // ...theme.prism.button.typography.smaller,
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: 500,
    }),
    ...(btnSize === "smallest" && {
      padding: theme.spacing(5 / 4, 2),
      borderRadius: theme.shape.borderRadius,
      // ...theme.prism.button.typography.smallest,
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: 400,
    }),
    ...(btnVariant === "tag" && {
      fontWeight: theme.typography.fontWeightRegular,
      borderRadius: (theme.shape.borderRadius * 13) / 2,
    }),
    /**
     * Rounded
     */
    ...(rounded &&
      btnVariant !== "tertiary" &&
      btnVariant !== "tag" && {
        borderRadius: theme.shape.borderRadius * 7,
      }),
    /**
     * Action
     */
    "&:hover": {
      ...(btnVariant === "primary" && {
        backgroundColor: theme.palette.customColors.grey,
      }),
      ...(btnVariant === "secondary" && {
        backgroundColor: "#D9DAD6",
        // theme.prism.button.action.hoverBackground.secondary,
      }),
      ...(btnVariant === "tertiary" && {
        backgroundColor: "transparent",
      }),
      ...(btnVariant === "tag" && {
        backgroundColor: theme.palette.customColors.grey,
      }),
    },
    [`&.${buttonClasses.disabled}`]: {
      ...(btnVariant === "primary" && {
        backgroundColor: theme.palette.customColors.grey,
        color: theme.palette.common.white,
      }),
      ...(btnVariant === "secondary" && {
        borderColor: "#9DA3AB",
        //  theme.prism.button.action.disabledBackground.secondary,
        color: "#9DA3AB",
        //  theme.prism.button.action.disabledBackground.secondary,
      }),
      ...(btnVariant === "tertiary" && {
        backgroundColor: theme.palette.customColors.grey,
      }),
      ...(btnVariant === "tag" && {
        backgroundColor: theme.palette.customColors.grey,
      }),
    },
    ...(loading && {
      pointerEvents: "none",
    }),
  })
);

export default Button;
