import React, { useEffect, useState } from "react";
import { IRATable } from "../../components/ira-table/ira-table";
import DateTimeCell from "../../components/TableComponent/date-time-cell";
import { IRATableColumnProps } from "../../components/ira-table/ira-table.interfaces";
import NameCell from "../../components/TableComponent/name-cell/name-cell";
import ActionCell from "../../components/TableComponent/action-cell";
import { Box } from "@mui/material";
import CustomButton from "../../components/Button";
import CustomDialog from "../../components/CustomDialog";
import AddEditDoctor from "./add-edit-doctor";
import { apiRequest } from "../../app/services/requests";
import { useNavigate, useParams } from "react-router-dom";
import DoctorProfileCard from "./doctor-profile-card";
import moment from "moment";

const DoctorList = (props: any) => {
  const [openSelectClientDialog, setOpenSelectClientDialog] = useState<any>({
    open: false,
    data: {},
  });
  const [doctorList, setDoctorList] = useState("") as any;

  const params = useParams();
  const { hospitalId } = params || {};
  const navigate = useNavigate();

  const [openDoctorProfileViewModel, setOpenDoctorProfileViewModel] = useState({
    open: false,
    data: {} as any,
  });

  useEffect(() => {
    if (hospitalId) {
      getDoctorsList(hospitalId);
    }
  }, [hospitalId]);

  // Here clinicId alias hospitalId both are same
  const getDoctorsList = (hospitalId: any) => {
    apiRequest({
      url: `clinic/${hospitalId}/doctor/list`, // Use the userId from the URL
      method: "POST",
    })
      .then((res) => {
        if (res) {
          setDoctorList(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  const activityTableColumns = () =>
    [
      {
        name: "doctorId",
        label: "Doctor Id",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "doctorName",
        label: "Doctor Name",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "gender",
        label: "Gender",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "joiningDate",
        label: "Joining Date",
        component: DateTimeCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "email",
        label: "Email",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "action",
        label: "Action",
        component: (props: any) => {
          return ActionCell({
            button: true,
            backgroundColor: "rgba(229, 229, 230, 1)",
            btnSize: "smallest",
            disabled: false,
            infoMessage: null,
            label: "Views",
            onClick: () => {
              setOpenDoctorProfileViewModel({
                open: true,
                data: props.data,
              });
            },
            ...props,
          } as any); // <-- This 'as any' is not needed and might be causing the issue
        },
      } as any,
    ] as Array<IRATableColumnProps>;

  const activityTableData = () => {
    return doctorList?.data?.map((row: any) => {
      return {
        ...row,
        doctorId: {
          name: row?.id,
        },
        doctorName: {
          name: `${row?.firstName} ${row?.lastName} `,
        },
        gender: {
          name: row?.gender,
        },
        joiningDate: {
          dateTime: moment(row?.createdAt).format("DD-MM-YYYY"),
        },
        email: {
          name: `${row?.email}`,
        },
        action: {
          data: {
            id: row?.id,
          },
        },
      };
    });
  };

  const handleAddDoctorClick = () => {
    setOpenSelectClientDialog({ open: true });
  };

  return (
    <>
      <CustomDialog
        fullWidth={true}
        open={openDoctorProfileViewModel.open}
        handleClose={() =>
          setOpenDoctorProfileViewModel({ open: false, data: {} })
        }
        title={"Doctor Profile"}
        maxWidth={"lg"}
      >
        <DoctorProfileCard
          hospitalId={hospitalId}
          doctorId={openDoctorProfileViewModel?.data?.id}
        />
      </CustomDialog>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "95%",
          marginLeft: "3%",
        }}
        mr={2}
      >
        <Box style={{ display: "flex", gap: "8px" }}>
          <CustomButton
            bgColor={"rgba(92, 78, 255, 1)"}
            onClick={() => handleAddDoctorClick()}
            title={"Add Doctor"}
          />
        </Box>
      </Box>
      <Box mt={2} style={{ width: "95%", marginLeft: "3%" }}>
        <IRATable
          columns={activityTableColumns()}
          data={activityTableData()}
          onSort={(name: string, order: string) =>
            alert(`sort requested for ${name} with order ${order}`)
          }
        />
      </Box>
      {openSelectClientDialog.open && (
        <CustomDialog
          open={openSelectClientDialog.open}
          handleClose={() =>
            setOpenSelectClientDialog({ open: false, data: {} })
          }
          title={"Add Doctor"}
          fullScreen
          maxWidth
          height={"auto"}
        >
          <Box width={"100%"}>
            <AddEditDoctor hospitalId={hospitalId} />
          </Box>
        </CustomDialog>
      )}
    </>
  );
};

export default DoctorList;
