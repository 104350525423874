import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CustomCard from "../Card";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { apiRequest } from "../../app/services/requests";

const CaseViewCard = (props: any) => {
  const [clientData, setClientData] = useState({}) as any;
  // console.log(clientData, "ClientData");

  const navigate = useNavigate();
  const clientData1 = {
    sex: "Female",
    DOB: "06 Sep 1999",
    relation: "Spouse",
    status: "Paid",
    caseStatus: "New Case",
  };

  useEffect(() => {
    getAppointmentById();
  }, []);
  const getAppointmentById = () => {
    apiRequest({
      url: `case/4`, // Use the userId from the URL

      method: "GET",
    })
      .then((res: any) => {
        setClientData(res);
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };
  const navigateToClientProfile = () => {
    navigate(`/client/${clientData?.id}/view/plan-details`);
  };

  return (
    <CustomCard
      sx={{
        width: "300px",

        padding: "16px",
        borderRadius: "11px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Typography sx={{ fontSize: "14px" }}>Client Details</Typography>
            <Typography
              sx={{
                textAlign: "right",
                color: "#5C4EFF",
                fontSize: "12px",
                cursor: "pointer",
              }}
              onClick={navigateToClientProfile}
            >
              {" "}
              View Client Profile
              <OpenInNewIcon sx={{ fontSize: "12px" }} />
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>Patient</Typography>
          </Box>
        </Grid>

        <Box sx={{ padding: "17px", textAlign: "center" }}>
          <Card
            sx={{
              width: "269px",
              height: "104px",
              background: "#E1F1FA",
              borderRadius: "11px",
              textAlign: "center",
              padding: "28px",
            }}
          >
            <Typography
              sx={{ font: "#313035", fontSize: "14px", fontWeight: "500px" }}
            >
              Patient Name:{" "}
              {`${clientData?.client?.firstName} ${clientData?.client?.lastName}`}
            </Typography>
            <Typography sx={{ font: "#313035", fontSize: "12px" }}>
              Case ID: {clientData?.client?.id}{" "}
            </Typography>
          </Card>
        </Box>

        <Grid item xs={12}>
          <Box>
            <Typography fontSize={14} sx={{ color: "#6A6A6A" }}>
              Patient Details
            </Typography>
          </Box>
        </Grid>

        <Grid container xs={12}>
          <Grid item xs={12}>
            <Box
              paddingRight={1}
              mt={"10px"}
              pl={2}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Typography sx={{ fontSize: "12px" }}>Sex:</Typography>
              <Typography variant="body2">
                {clientData?.client?.gender}{" "}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              mt={"10px"}
              pl={2}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Typography sx={{ fontSize: "12px", color: "#222222" }}>
                DOB:
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", color: "#222222" }}
              >
                {clientData?.client?.dateOfBirth}{" "}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              mt={"10px"}
              pl={2}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Typography sx={{ fontSize: "12px", color: "#222222" }}>
                Relation:
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", color: "#222222" }}
              >
                {clientData1.relation}{" "}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box mt={"10px"} pl={2}>
              <Typography sx={{ color: "#6A6A6A", fontSize: "14px" }}>
                Case Cost Details
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              mt={"10px"}
              pl={2}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Typography sx={{ fontSize: "12px", color: "#222222" }}>
                Status:
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", color: "#222222" }}
              >
                {clientData?.client?.status}{" "}
              </Typography>
            </Box>
          </Grid>

          <Box
            sx={{
              paddingLeft: "17px",
              paddingRight: "17px",
              paddingTop: "10px",
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                background: "#68D4B48C",
                borderRadius: "11px",
                height: "40px",
                width: "269px",
                pl: 2,
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#222222",
                  textAlign: "center",
                  p: 1.5,
                  fontWeight: "500",
                }}
              >
                Case Total Cost: Rs. 12,000.00
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              paddingLeft: "17px",
              paddingRight: "17px",
              paddingTop: "10px",
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                background: "#FFB51040",
                borderRadius: "11px",
                height: "40px",
                width: "269px",
                pl: 2,
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#222222",
                  textAlign: "center",
                  p: 1.5,
                  fontWeight: "500",
                }}
              >
                Payment Amount: Rs. 6,000.00
              </Typography>
            </Box>
          </Box>

          <Grid item xs={12}>
            <Box
              mt={"10px"}
              pl={2}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Typography sx={{ fontSize: "12px", color: "#222222" }}>
                Case Status
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", color: "#CB8C00" }}
              >
                {clientData1.caseStatus}{" "}
              </Typography>
            </Box>
          </Grid>

          <Box
            sx={{
              paddingLeft: "17px",
              paddingRight: "17px",
              paddingTop: "10px",
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                background: "#5C4EFE40",
                borderRadius: "11px",
                height: "40px",
                width: "269px",
                pl: 2,
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#313035",
                  textAlign: "center",
                  p: 1.5,
                  fontWeight: "500",
                }}
              >
                Claim Amount: Rs. 6,000.00
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              paddingLeft: "23px",
              paddingRight: "23px",
              paddingTop: "10px",
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                background: "#5C4EFF",
                borderRadius: "5px",
                height: "34px",
                width: "244px",
                padding: "7px, 23px, 7px, 23px",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#FFFFFF",
                  textAlign: "center",
                  p: 0.5,
                  fontWeight: "500",
                }}
              >
                View Approval Details{" "}
                <OpenInNewIcon sx={{ fontSize: "12px" }} />
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </CustomCard>
  );
};

export default CaseViewCard;
