export const ILLNESS = [
  {
    name: "intermittent Pain since 3days",
    value: "intermittent Pain since 3days",
  },
  {
    name: "intermittent Pain since a week",
    value: "intermittent Pain since a week",
  },
  {
    name: "intermittent Pain since 1month",
    value: "intermittent Pain since 1month",
  },
  {
    name: "intermittent Pain since 3 months",
    value: "intermittent Pain since 3 months",
  },
  {
    name: "intermittent Pain since 6 months",
    value: "intermittent Pain since 6 months",
  },
  {
    name: "intermittent Pain since 1 year",
    value: "intermittent Pain since 1 year",
  },
  {
    name: "intermittent Pain since few years",
    value: "intermittent Pain since few years",
  },
  {
    name: "Continuous Pain since 3days",
    value: "Continuous Pain since 3days",
  },
  {
    name: "Continuous Pain since a week",
    value: "Continuous Pain since a week",
  },
  {
    name: "Continuous Pain since 1 month",
    value: "Continuous Pain since 1 month",
  },
  {
    name: "Continuous Pain since 3 months",
    value: "Continuous Pain since 3 months",
  },
  {
    name: "Continuous Pain since 6 months",
    value: "Continuous Pain since 6 months",
  },
  {
    name: "Continuous Pain since 1 year",
    value: "Continuous Pain since 1 year",
  },
  {
    name: "Continuous Pain since few years",
    value: "Continuous Pain since few years",
  },
  {
    name: "Intermittent pain since 3 days",
    value: "Intermittent pain since 3 days",
  },
  {
    name: "Intermittent pain since a week",
    value: "Intermittent pain since a week",
  },
  {
    name: "Intermittent pain since 1 month",
    value: "Intermittent pain since 1 month",
  },
  {
    name: "Intermittent pain since 3 months",
    value: "Intermittent pain since 3 months",
  },
  {
    name: "Intermittent pain since 6 months",
    value: "Intermittent pain since 6 months",
  },
  {
    name: "Intermittent pain since 1 year",
    value: "Intermittent pain since 1 year",
  },
  {
    name: "Intermittent pain since few years",
    value: "Intermittent pain since few years",
  },
  {
    name: "Continuous pain since 3 days",
    value: "Continuous pain since 3 days",
  },
  {
    name: "Continuous pain since a week",
    value: "Continuous pain since a week",
  },
  {
    name: "Continuous pain since 1month",
    value: "Continuous pain since 1month",
  },
  {
    name: "Continuous pain since 3 months",
    value: "Continuous pain since 3 months",
  },
  {
    name: "Continuous pain since 6 months",
    value: "Continuous pain since 6 months",
  },
  {
    name: "Continuous pain since 1 year",
    value: "Continuous pain since 1 year",
  },
  {
    name: "Continuous pain since few years.",
    value: "Continuous pain since few years.",
  },
];

export const FAMILY_ILLNESS = [
  {
    name: "Diabetes Mellitus",
    value: "Diabetes Mellitus",
  },
  {
    name: "Hypertension",
    value: "Hypertension",
  },
  {
    name: "Thyroid",
    value: "Thyroid",
  },
  {
    name: "Cardiac disease",
    value: "Cardiac disease",
  },
  {
    name: "Kidney Ailment",
    value: "Kidney Ailment",
  },
  {
    name: "Brain related problem",
    value: "Brain related problem",
  },
  {
    name: "Diabetes Mellitus",
    value: "Diabetes Mellitus",
  },
  {
    name: "Hypertension",
    value: "Hypertension",
  },
  {
    name: "Thyroid",
    value: "Thyroid",
  },
  {
    name: "Cardiac disease",
    value: "Cardiac disease",
  },
  {
    name: "Kidney Ailment",
    value: "Kidney Ailment",
  },
  {
    name: "Brain related problem",
    value: "Brain related problem",
  },
  {
    name: "Psychiatric problems",
    value: "Psychiatric problems",
  },
  {
    name: "Malignancies",
    value: "Malignancies",
  },
];

export const HABITS = [
  {
    name: "Thumb Sucking",
    value: "Thumb Sucking",
  },
  {
    name: "Tongue Thrusting",
    value: "Tongue Thrusting",
  },
  {
    name: "Mouth Breathing",
    value: "Mouth Breathing",
  },
  {
    name: "Nail Biting",
    value: "Nail Biting",
  },
  {
    name: "Lip Biting",
    value: "Lip Biting",
  },
  {
    name: "Lip Sucking",
    value: "Lip Sucking",
  },
  {
    name: "Alcohol consumption",
    value: "Alcohol consumption",
  },
  {
    name: "Smoking",
    value: "Smoking",
  },
  {
    name: "Gutka Chewing",
    value: "Gutka Chewing",
  },
  {
    name: "Pan chewing",
    value: "Pan chewing",
  },
  {
    name: "Tobacco chewing",
    value: "Tobacco chewing",
  },
];

export const DRUG_HISTORY = [
  {
    name: "Allergic to Penicillin",
    value: "Allergic to Penicillin",
  },
  {
    name: "Allergic to Sulphide drugs",
    value: "Allergic to Sulphide drugs",
  },
  {
    name: "Allergic to Ibuprofen",
    value: "Allergic to Ibuprofen",
  },
];

export const MEDICAL_INFORMATION = [
  {
    name: "Diabetes Mellitus",
    value: "Diabetes Mellitus",
  },
  {
    name: "Hypertension",
    value: "Hypertension",
  },
  {
    name: "Thyroid",
    value: "Thyroid",
  },
  {
    name: "Cardiac disease",
    value: "Cardiac disease",
  },
  {
    name: "Kidney Ailment",
    value: "Kidney Ailment",
  },
  {
    name: "Brain related problem",
    value: "Brain related problem",
  },
  {
    name: "Herpes Simplex (HSV1)",
    value: "Herpes Simplex (HSV1)",
  },
  {
    name: "Anemia",
    value: "Anemia",
  },
  {
    name: "Addison’s Disease",
    value: "Addison’s Disease",
  },
  {
    name: "McCune-Albright ",
    value: "McCune-Albright ",
  },
  {
    name: "Peuz-Jeghers",
    value: "Peuz-Jeghers",
  },
  {
    name: "Neurofibromatosis ",
    value: "Neurofibromatosis ",
  },
  {
    name: "Chronic liver disease",
    value: "Chronic liver disease",
  },
  {
    name: "Aphthous lesions ",
    value: "Aphthous lesions ",
  },
  {
    name: "SLE",
    value: "SLE",
  },
  {
    name: "Hepatitis C",
    value: "Hepatitis C",
  },
  {
    name: "Pemphigus",
    value: "Pemphigus",
  },
  {
    name: "Crohn’s Disease",
    value: "Crohn’s Disease",
  },
  {
    name: "Ulcerative colitis",
    value: "Ulcerative colitis",
  },
  {
    name: "Behcet’s syndrome ",
    value: "Behcet’s syndrome ",
  },
  {
    name: "Kawasaki disease",
    value: "Kawasaki disease",
  },
  {
    name: "Angular chelitis",
    value: "Angular chelitis",
  },
  {
    name: "Sarcoidosis",
    value: "Sarcoidosis",
  },
  {
    name: "Leukemia",
    value: "Leukemia",
  },
  {
    name: "HIV",
    value: "HIV",
  },
  {
    name: "Thrombocytopenia",
    value: "Thrombocytopenia",
  },
];

export const USING_MEDICATIONS = [
  {
    name: "Reversible Pulpits",
    value: "Reversible Pulpits",
  },
  {
    name: "Irreversible Pulpitis",
    value: "Irreversible Pulpitis",
  },
  {
    name: "Acute Pulpitis",
    value: "Acute Pulpitis",
  },
  {
    name: "Chronic Pulpitis",
    value: "Chronic Pulpitis",
  },
  {
    name: "Hyperplastic Pulpitis",
    value: "Hyperplastic Pulpitis",
  },
  {
    name: "Dentoalveolar abscess",
    value: "Dentoalveolar abscess",
  },
  {
    name: "Periapical Abscess",
    value: "Periapical Abscess",
  },
  {
    name: "Periodontal Abscess",
    value: "Periodontal Abscess",
  },
  {
    name: "Gingival Abscess",
    value: "Gingival Abscess",
  },
  {
    name: "Aggressive Periodontitis",
    value: "Aggressive Periodontitis",
  },
  {
    name: "Chronic Periodontitis",
    value: "Chronic Periodontitis",
  },
  {
    name: "Temporomandibular Disorder",
    value: "Temporomandibular Disorder",
  },
  {
    name: "Dental Caries",
    value: "Dental Caries",
  },
];

export const ALLERGIES = [
  {
    name: "Pollen",
    value: "Pollen",
  },
  {
    name: "Peanuts",
    value: "Peanuts",
  },
  {
    name: "tree nuts",
    value: "tree nuts",
  },
  {
    name: "wheat",
    value: "wheat",
  },
  {
    name: "soy",
    value: "soy",
  },
  {
    name: "fish",
    value: "fish",
  },
  {
    name: "shellfish",
    value: "shellfish",
  },
  {
    name: "eggs",
    value: "eggs",
  },
  {
    name: "milk",
    value: "milk",
  },
  {
    name: "mites",
    value: "mites",
  },
  {
    name: "mold",
    value: "mold",
  },
  {
    name: "-Antibiotics : Pencillin , Amoxicillin, Ampicillin, tetracycline",
    value: "-Antibiotics : Pencillin , Amoxicillin, Ampicillin, tetracycline",
  },
  {
    name: "-NSAIDS : Ibuprofen, Naproxen",
    value: "-NSAIDS : Ibuprofen, Naproxen",
  },
  {
    name: "-Aspirin",
    value: "-Aspirin",
  },
  {
    name: "-Sulfa drugs",
    value: "-Sulfa drugs",
  },
];

export const CLINICAL_FINDINGS = [
  "Dental Caries",
  "Gingvitis",
  "Tooth Mobility",
  "Dental Abscess",
  "Tooth Discolorization",
  "Oral Lesions",
  "X-Ray FIndings",
  "Pulp Vitality",
  "Oral Habits",
  "Oral Cancer",
];

export const PROVISIONAL_DIAGNOSIS = [
  "Reversible Pulpits",
  "Irreversible Pulpitis",
  "Acute Pulpitis",
  "Chronic Pulpitis",
  "Hyperplastic Pulpitis",
  "Dentoalveolar abscess",
  "Periapical Abscess",
  "Periodontal Abscess",
  "Gingival Abscess",
  "Aggressive Periodontitis",
];

export const MAJOR_INVESTIGATIONS = [
  "OPG - Orthopantomograph",
  "CBCT MAXILLA FULL VOLUME - Cone Beam Computed Tomography",
  "CBCT MANDIBLE FULL VOLUME - Cone Beam Computed Tomography",
  "CT - Computed Tomography",
  "ULTRASOUND",
  "MRI - Magnetic resonance Imaging",
];

export const DRUG_TYPES = [
  {
    id: "Tablet",
    name: "Tablet",
  },
  {
    id: "capsules",
    name: "capsules",
  },
  {
    id: "Liquids",
    name: "Liquids",
  },
  {
    id: "Creams",
    name: "Creams",
  },
  {
    id: "Patches",
    name: "Patches",
  },
  {
    id: "Tablet",
    name: "Tablet",
  },
  {
    id: "capsules",
    name: "capsules",
  },
  {
    id: "Liquids",
    name: "Liquids",
  },
  {
    id: "Creams",
    name: "Creams",
  },
  {
    id: "capsules",
    name: "capsules",
  },
  {
    id: "Liquids",
    name: "Liquids",
  },
  {
    id: "capsules",
    name: "capsules",
  },
];

export const DRUG_NAMES = [
  {
    id: "Augmentin",
    name: "Augmentin",
  },
  {
    id: "Mox CV",
    name: "Mox CV",
  },
  {
    id: "Oro CV",
    name: "Oro CV",
  },
  {
    id: "Metrogyl",
    name: "Metrogyl",
  },
  {
    id: "Flagyl",
    name: "Flagyl",
  },
  {
    id: "Ordent",
    name: "Ordent",
  },
  {
    id: "Zerodol SP",
    name: "Zerodol SP",
  },
  {
    id: "Acecloren A",
    name: "Acecloren A",
  },
  {
    id: "Combiflam",
    name: "Combiflam",
  },
  {
    id: "Ketorol DT",
    name: "Ketorol DT",
  },
  {
    id: "Clohex ADS",
    name: "Clohex ADS",
  },
  {
    id: "Clohex Heal",
    name: "Clohex Heal",
  },
];

export const SCHEME_COMPANY_NAMES = [
  {
    id: "Delta Dental",
    name: "Delta Dental",
  },
  {
    id: "Aetna",
    name: "Aetna",
  },
  {
    id: "Well Point Inc",
    name: "Well Point Inc",
  },
  {
    id: "Aditya Birla",
    name: "Aditya Birla",
  },
  {
    id: "TATA AIG",
    name: "TATA AIG",
  },
];

export const SCHEME_NAMES = [
  {
    id: "STATE GOVERNMENT DENTAL INSURANCE",
    name: "STATE GOVERNMENT DENTAL INSURANCE",
  },
  {
    id: "TS GENCO",
    name: "TS GENCO",
  },
  {
    id: "STATE GOVERNMENT REIMBURSEMENT",
    name: "STATE GOVERNMENT REIMBURSEMENT",
  },
  {
    id: "AROGYA BHADRATHA",
    name: "AROGYA BHADRATHA",
  },
  {
    id: "TSSPDCL",
    name: "TSSPDCL",
  },
];

export const SCHEME_TYPES = [
  {
    id: "REIMBURSMENT",
    name: "REIMBURSMENT",
  },
  {
    id: "FULL PAYMENT",
    name: "FULL PAYMENT",
  },
  {
    id: "PART PAYMENT",
    name: "PART PAYMENT",
  },
  {
    id: "20% PAYMENT",
    name: "20% PAYMENT",
  },
  {
    id: "Part payment",
    name: "Part payment",
  },
  {
    id: "Instant Payment",
    name: "Instant Payment",
  },
  {
    id: "Full payment",
    name: "Full payment",
  },
  {
    id: "Payment",
    name: "Payment",
  },
  {
    id: "AB Receive From Govt",
    name: "AB Receive From Govt",
  },
  {
    id: "Part payment",
    name: "Part payment",
  },
];

export const PROVISIONAL_DIAGNOSIS_INVESTIGATON = [
  {
    name: "Reversible Pulpits",
    value: "Reversible Pulpits",
  },
  {
    name: "Irreversible Pulpitis",
    value: "Irreversible Pulpitis",
  },
  {
    name: "Acute Pulpitis",
    value: "Acute Pulpitis",
  },
  {
    name: "Chronic Pulpitis",
    value: "Chronic Pulpitis",
  },
  {
    name: "Hyperplastic Pulpitis",
    value: "Hyperplastic Pulpitis",
  },
  {
    name: "Dentoalveolar abscess",
    value: "Dentoalveolar abscess",
  },
  {
    name: "Periapical Abscess",
    value: "Periapical Abscess",
  },
  {
    name: "Periodontal Abscess",
    value: "Periodontal Abscess",
  },
  {
    name: "Gingival Abscess",
    value: "Gingival Abscess",
  },
  {
    name: "Aggressive Periodontitis",
    value: "Aggressive Periodontitis",
  },
  {
    name: "Chronic Periodontitis",
    value: "Chronic Periodontitis",
  },
  {
    name: "Temporomandibular Disorder",
    value: "Temporomandibular Disorder",
  },
  {
    name: "Dental Caries",
    value: "Dental Caries",
  },
];

export const DEFAULT_PRES_DOSAGE = [
  "10mg",
  "20mg",
  "100mg",
  "150mg",
  "200mg",
  "228mg",
  "250mg",
  "375mg",
  "457mg",
  "625mg",
  "1000mg",
  "200mg+500mg",
  "1 ml",
  "2 ml",
  "2.5 ml",
  "3 ml",
  "3.5 ml",
  "4 ml",
  "5 ml",
  "6 ml",
  "7 ml",
  "8 ml",
  "9 ml",
  "10 ml",
];

export const PRES_DOSAGES = DEFAULT_PRES_DOSAGE.map((dosage) => ({
  id: dosage,
  name: dosage,
}));

export const DEFAULT_PRES_DURATION = [
  "One Day",
  "Two Days",
  "Three Days",
  "Four Days",
  "One Week",
  "Ten Days",
  "Two Weeks",
  "SOS - Whenever needed",
  "One Month",
];

export const PRES_DURATIONS = DEFAULT_PRES_DURATION.map((duration) => ({
  id: duration,
  name: duration,
}));

export const DEFAULT_PRES_INSTRUCTIONS = [
  "Any symptoms other than side effects of the drug like, itching, rashes",
  "Use the drugs as prescribed by your doctor to be more effective",
  "Irregular usage and discontinuing before the course of the drug will not serve the purpose",
  "Discuss in detail about your existing medication, Allergies and other health issues before the prescription",
];

export const PRES_INSTRUCTIONS = DEFAULT_PRES_INSTRUCTIONS.map(
  (instruction) => ({
    value: instruction,
    name: instruction,
  })
);

export const PRES_QuANTITY = Array.from({ length: 20 }, (_, index) => ({
  id: index + 1,
  name: `${index + 1}`,
}));

export const DEFAULT_PRES_FREQUENCY = [
  "Once Daily (1 - 0 - 0) Morning",
  "Once Daily (0 - 1 - 0) Afternoon",
  "Once Daily (0 - 0 - 1) Night",
  "Twice Daily (1 - 0 - 1) Morning Night",
  "Twice Daily (1 - 1 - 0) Morning Afternoon",
  "Twice Daily (0 - 1 - 1) Afternoon Night",
  "Thrice Daily (1 - 1 - 1) Morning Afternoon Night",
  "Four Times (1 - 1 - 1 - 1) Every 6hours",
];

export const PRES_FREQUENCY = DEFAULT_PRES_FREQUENCY.map((frequency) => {
  return {
    id: frequency,
    name: frequency,
  };
});
