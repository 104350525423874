import React, { createContext, useContext } from "react";

const appContext = createContext({});
export function AppContextProvider(props:any) {

  
  const userData = JSON.parse(localStorage.getItem("user") || "{}");

  const { children } = props;

  const [user, setUser] = React.useState(userData);

  const handleLoginSuccess = (data:any) => {
    setUser(data);
  };

  const handleLogoutSuccess = () => {
    localStorage.removeItem("user");
    setUser({});
  };

  return (
    <appContext.Provider
      value={{
        handleLoginSuccess: handleLoginSuccess,
        handleLogoutSuccess: handleLogoutSuccess,
        user,
        setUser: setUser,
      }}
    >
      {children}
    </appContext.Provider>
  );
}

export function useAppContext() {
  return useContext(appContext);
}
