import React, { useMemo, useState } from "react";
import LabelBox from "../../../components/LabelBox";
import { Grid, Typography, Box } from "@mui/material";
import ArrowCircleUp from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDown from "@mui/icons-material/ArrowCircleDown";
import Divider from "@mui/material/Divider";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import CloseIcon from "@mui/icons-material/Close";
import AddEditNotes from "./AddEditNotes";
import CustomButton from "../../../components/Button";
import CustomModal from "../../../components/Modal";
import { TOOTH_SURFACES_PERIODONTAL } from "../../../../src/app/utilities/constants";
import { groupByToothFindings } from "../../../../src/app/utilities/utils";
import { styles } from "./styles";
import "./index.css";

interface captureData {
  teethNo: any;
  inputValues?: any;
  colorCodes?: any;
  notes?: any;
}

const teeths11to28 = [
  18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28,
];
const teeths31to48 = [
  48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38,
];

const PeriodontalChart = (Props: any) => {
  const {
    handleModal,
    investigationData = [],
    setInvestigationData = () => {},
    periodentalChart,
    setPeriodentalChart,
    selectedPeriodentalData = {},
    isForPreviousTreatments = false,
  } = Props;
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [selectedNotesData, setSelectedNotesData] = useState<any>({});
  const [onlyUpdatedData, setOnlyUpdatedData] = useState<any>([]);
  const TOOTHSURFACESPERIODONTAL: {
    [key: string]: any;
  } = TOOTH_SURFACES_PERIODONTAL;

  const getInputType = (
    periodontalData: any,
    data: captureData,
    periodontal: any,
    periodentalChartType: any,
    mainIndex: any,
    captureIndex: any,
    periodentalType: any
  ) => {
    const id = `${periodontal.text}${data.teethNo}`;
    if (data.inputValues) {
      return (data.inputValues || []).map((val: any, inputIndex: Number) => (
        <input
          type="number"
          onKeyDown={(e) => limit(e)}
          onKeyUp={(e) => limit(e)}
          id={id}
          style={{ width: "16px", border: 0 }}
          placeholder={val || 0}
          onBlur={(e) =>
            updateState(
              e.target.value,
              mainIndex,
              captureIndex,
              inputIndex,
              periodentalChartType,
              periodontalData,
              "inputValues",
              periodentalType
            )
          }
          onInput={(e: any) => {
            if (periodontal.text === "Mobility") {
              return (e.target.value = e.target.value.match(/[0-3]/));
            }
            return (e.target.value = e.target.value.substr(0, 1));
          }}
        />
      ));
    }
    if (data.colorCodes) {
      return (data.colorCodes || []).map((val: any, inputIndex: Number) => (
        <FiberManualRecordIcon
          sx={
            data.colorCodes.length > 1
              ? {
                  fontSize: "1rem",
                  color: val ? periodontal.backgroundColor : "#eaeaea",
                }
              : { color: val ? periodontal.backgroundColor : "#eaeaea" }
          }
          onClick={() =>
            updateState(
              !val,
              mainIndex,
              captureIndex,
              inputIndex,
              periodentalChartType,
              periodontalData,
              "colorCodes",
              periodentalType
            )
          }
        />
      ));
    }
    if (!data.inputValues && !data.colorCodes && periodontal.text === "Note") {
      return (
        <>
          {}
          <CreateOutlinedIcon
            sx={styles.actionIcon}
            onClick={(e) =>
              handleNotes({
                periodontalData,
                mainIndex,
                captureIndex,
                periodentalChartType,
                notes:
                  periodontalData[mainIndex].captureData[captureIndex].notes,
                periodentalType,
              })
            }
          />
        </>
      );
    }
  };

  const handleNotes = (data: any) => {
    setSelectedNotesData(data);
    setOpenNotesModal(true);
  };

  const direction = (teeths: any, reverse = false) => (
    <Grid container sx={{ flexWrap: "nowrap", alignItems: "center" }}>
      <Grid item>
        <Box
          sx={{
            width: "160px",
          }}
        >
          <Grid
            container
            mt={5}
            mb={2}
            sx={{
              justifyContent: "space-between",
              alignItems: "start",
            }}
          >
            <Grid item>
              <Typography sx={{ color: "#204289" }}>
                {reverse ? "Lingual" : "Bucal"}
              </Typography>
            </Grid>
            <Grid item>
              <ArrowCircleUp sx={{ color: "#204289" }} />
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            mt={2}
            mb={5}
            sx={{
              justifyContent: "space-between",
              alignItems: "start",
            }}
          >
            <Grid item>
              <Typography sx={{ color: "#204289" }}>
                {reverse ? "Bucal" : "Lingual"}
              </Typography>
            </Grid>
            <Grid item>
              <ArrowCircleDown sx={{ color: "#204289" }} />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item>
        <Grid
          container
          spacing={2}
          sx={{ flexWrap: "nowrap", alignItems: "center" }}
        >
          <Grid item sx={{ marginLeft: "17px" }}>
            <Grid container sx={{ flexWrap: "nowrap", alignItems: "center" }}>
              {teeths.slice(0, 8).map((val: any) => (
                <Grid item sx={{ width: "54px" }}>
                  <Grid
                    container
                    sx={{
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Grid item>
                      <Box
                        component="img"
                        sx={{
                          height: "96px",
                        }}
                        alt={val + "alt"}
                        src={require(`../../../app/assets/teeth/Teeth Default/Teeth/${val}.svg`)}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "#204289" }}
                        gutterBottom
                      >
                        {val}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item>
            <Grid container sx={{ flexWrap: "nowrap", alignItems: "center" }}>
              {teeths.slice(8, 16).map((val: any) => (
                <Grid item sx={{ width: "54px" }}>
                  <Grid
                    container
                    sx={{
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Grid item>
                      <Box
                        component="img"
                        sx={{
                          height: "96px",
                        }}
                        alt={val + "alt"}
                        src={require(`../../../app/assets/teeth/Teeth Default/Teeth/${val}.svg`)}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "#204289" }}
                        gutterBottom
                      >
                        {val}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const setUpdateData = (inputData: any) => {
    const foundIndex = onlyUpdatedData.findIndex(
      (data: any) => data.toothNumbers === inputData.toothNumbers
    );
    if (foundIndex >= 0) {
      const clonedData = JSON.parse(JSON.stringify(onlyUpdatedData));
      const periodontalFindings = {
        ...onlyUpdatedData[foundIndex].findings,
        ...inputData.findings,
      };
      let surfaces: any = [];
      if (inputData.surface.length) {
        const foundIndex1 = (
          onlyUpdatedData[foundIndex].surface || []
        ).findIndex((data: any) => data.value === inputData.surface[0].value);
        const cloned = JSON.parse(
          JSON.stringify(onlyUpdatedData[foundIndex].surface)
        );
        if (foundIndex1 >= 0) {
          cloned[foundIndex1].findings = {
            ...onlyUpdatedData[foundIndex].surface[foundIndex1].findings,
            ...inputData.surface[0].findings,
          };
          cloned[foundIndex1].periodontalData = {
            ...onlyUpdatedData[foundIndex].surface[foundIndex1].periodontalData,
            ...inputData.surface[0].periodontalData,
          };
          surfaces = cloned;
        } else {
          surfaces = [
            ...onlyUpdatedData[foundIndex].surface,
            ...inputData.surface,
          ];
        }
      }

      clonedData[foundIndex] = {
        ...onlyUpdatedData[foundIndex],
        ...inputData,
        findings: periodontalFindings,
        surface: surfaces,
        periodontalData: {
          ...onlyUpdatedData[foundIndex].periodontalData,
          ...inputData.periodontalData,
        },
        examinationNotes: inputData.examinationNotes,
        selectedTeeth: true,
      };
      setOnlyUpdatedData(clonedData);
    } else {
      setOnlyUpdatedData([...onlyUpdatedData, inputData]);
    }
  };

  const convertArrayToString = (data: any) => {
    let strg = "";
    if (data.colorCodes) {
      strg = data.colorCodes.map((color: any) => (color ? "Y" : "N")).join("");
    } else if (data.inputValues) {
      strg = data.inputValues.join("");
    }
    return strg;
  };

  const updateState = (
    value: any,
    mainIndex: any,
    captureIndex: any,
    inputIndex: any,
    periodentalChartType: any,
    periodontalData: any,
    type: any,
    periodentalType: any
  ) => {
    const copiedPeriodontalData = JSON.parse(JSON.stringify(periodontalData));
    const captureData = copiedPeriodontalData[mainIndex].captureData;
    let data: any = {
      findings: {},
      examinationNotes: "",
      periodontalData: {},
      selectedTeeth: true,
      surface: [],
    };
    if (type !== "notes") {
      const inputData = captureData[captureIndex][type];
      inputData[inputIndex] = value;
      captureData[captureIndex][type] = inputData;
      const periodontalFindings = {
        [`${copiedPeriodontalData[mainIndex].text} ${periodentalType}`]:
          convertArrayToString(captureData[captureIndex]),
      };
      data.findings = periodontalFindings;
    } else {
      captureData[captureIndex][type] = value;
      data.examinationNotes = value;
    }
    data.periodontalData = {
      [`${copiedPeriodontalData[mainIndex].text} ${periodentalChartType}`]:
        captureData[captureIndex],
    };
    const surfaceName = TOOTHSURFACESPERIODONTAL[periodentalChartType];
    if (type !== "notes") {
      data.surface = [
        {
          name: surfaceName,
          value: surfaceName,
          periodentalChartType,
          findings: {
            [`${copiedPeriodontalData[mainIndex].text}`]: convertArrayToString(
              captureData[captureIndex]
            ),
          },
          periodontalData: {
            [`${copiedPeriodontalData[mainIndex].text}`]:
              captureData[captureIndex],
          },
        },
      ];
    }
    copiedPeriodontalData[mainIndex].captureData = captureData;
    setPeriodentalChart({
      ...periodentalChart,
      [periodentalChartType]: copiedPeriodontalData,
    });
    setUpdateData({
      teethNumber: captureData[captureIndex].teethNo,
      toothNumbers: captureData[captureIndex].teethNo,
      isPrimaryTeeth: false,
      teethType: "periodental",
      ...data,
    });
  };

  const getBucalLingualGrid = (
    periodontalData: any,
    periodentalChartType: any,
    periodentalType: any
  ) => {
    return (
      <>
        {periodontalData.map((periodontal: any, mainIndex: Number) => {
          return (
            <Grid container spacing={2} sx={{ flexWrap: "nowrap" }}>
              <Grid item>
                <LabelBox
                  backgroundColor={periodontal.backgroundColor}
                  text={periodontal.text}
                  textRight={periodentalType}
                  color={periodontal.color}
                />
              </Grid>
              <Grid item>
                <Grid
                  container
                  sx={{
                    flexWrap: "nowrap",
                  }}
                >
                  {(periodontal.captureData || [])
                    .slice(0, 8)
                    .map((data: any, captureIndex: Number) => {
                      return (
                        <Grid item>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              border: "1px solid #8080807a",
                              padding: "0px 2px",
                            }}
                          >
                            <Typography
                              sx={{
                                height: "30px",
                                width: "48px",
                                fontSize: "0.8rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              {getInputType(
                                periodontalData,
                                data,
                                periodontal,
                                periodentalChartType,
                                mainIndex,
                                captureIndex,
                                periodentalType
                              )}
                            </Typography>
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  sx={{
                    flexWrap: "nowrap",
                  }}
                >
                  {(periodontal.captureData || [])
                    .slice(8, 16)
                    .map((data: any, indexing: any) => {
                      let captureIndex = indexing + 8;
                      return (
                        <Grid item>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              border: "1px solid #8080807a",
                              padding: "0px 2px",
                            }}
                          >
                            <Typography
                              sx={{
                                height: "30px",
                                width: "48px",
                                fontSize: "0.8rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              {" "}
                              {getInputType(
                                periodontalData,
                                data,
                                periodontal,
                                periodentalChartType,
                                mainIndex,
                                captureIndex,
                                periodentalType
                              )}{" "}
                            </Typography>
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </>
    );
  };

  const getBucalGrid: any = useMemo(
    () => getBucalLingualGrid(periodentalChart.bucal, "bucal", "B"),
    [periodentalChart, onlyUpdatedData]
  );

  const getLingualGrid: any = useMemo(
    () => getBucalLingualGrid(periodentalChart.Lingual, "Lingual", "L"),
    [periodentalChart, onlyUpdatedData]
  );

  const getBucalReverseGrid: any = useMemo(
    () =>
      getBucalLingualGrid(periodentalChart.bucalReverse, "bucalReverse", "B"),
    [periodentalChart, onlyUpdatedData]
  );

  const addPeriodentalData = () => {
    if (isForPreviousTreatments) {
      const clonedData = JSON.parse(JSON.stringify(investigationData));
      let arr: any = [...clonedData];
      for (const data of onlyUpdatedData) {
        const foundIndex = clonedData.findIndex(
          (d: any) =>
            d.toothNumbers === data.toothNumbers &&
            d.teethType === "periodental"
        );
        if (foundIndex >= 0) {
          clonedData[foundIndex] = {
            ...clonedData[foundIndex],
            ...data,
            findings: {
              ...clonedData[foundIndex].findings,
              ...data.findings,
            },
            periodontalData: {
              ...clonedData[foundIndex].periodontalData,
              ...data.periodontalData,
            },
            examinationNotes:
              data.examinationNotes || clonedData[foundIndex].examinationNotes,
          };
          arr = clonedData;
        } else {
          arr = [...arr, data];
        }
      }
      setInvestigationData(arr);
      handleModal(false);
    } else {
      const clonedData = JSON.parse(JSON.stringify(investigationData));
      let arr: any = [...clonedData];
      for (const data of onlyUpdatedData) {
        const foundIndex = arr.findIndex(
          (d: any) =>
            d.toothNumbers === data.toothNumbers &&
            d.teethType === "periodental"
        );
        if (foundIndex >= 0) {
          const periodontalFindings = {
            ...arr[foundIndex].findings,
            ...data.findings,
          };
          let surfaces: any = [];
          if (data.surface.length) {
            for (const [idx, val] of data.surface.entries()) {
              const foundIndex1 = (arr[foundIndex].surface || []).findIndex(
                (data: any) => data.value === val.value
              );
              const cloned = JSON.parse(
                JSON.stringify(arr[foundIndex].surface)
              );
              if (foundIndex1 >= 0) {
                cloned[foundIndex1].findings = {
                  ...arr[foundIndex].surface[foundIndex1].findings,
                  ...val.findings,
                };
                cloned[foundIndex1].periodontalData = {
                  ...arr[foundIndex].surface[foundIndex1].periodontalData,
                  ...val.periodontalData,
                };
                surfaces = cloned;
              } else {
                surfaces = [...arr[foundIndex].surface, ...data.surface];
              }
            }
          } else {
            surfaces = [...arr[foundIndex].surface, ...data.surface];
          }
          arr[foundIndex] = {
            ...arr[foundIndex],
            ...data,
            findings: periodontalFindings,
            periodontalData: {
              ...arr[foundIndex].periodontalData,
              ...data.periodontalData,
            },
            surface: surfaces,
            examinationNotes:
              data.examinationNotes || arr[foundIndex].examinationNotes,
          };
        } else {
          arr = [...arr, data];
        }
      }
      setInvestigationData(arr);
    }
    handleModal(false);
  };

  const handleOpenNotesModal = () => {
    setOpenNotesModal(!openNotesModal);
  };

  function limit(element: any) {
    if (element.target.value.length > 1) {
      return (element.target.value = element.target.value.substr(0, 1));
    }
  }

  return (
    <>
      <Grid container>
        {getBucalGrid}
        {direction(teeths11to28)}
        {getLingualGrid}
      </Grid>
      <Divider
        sx={{
          margin: "15px 0px",
        }}
      />
      <Grid container>
        {getBucalLingualGrid(
          periodentalChart.lingualReverse,
          "lingualReverse",
          "L"
        )}
        {direction(teeths31to48, true)}
        {getBucalReverseGrid}
      </Grid>
      <Box mt={2}>
        <Grid
          container
          flexDirection={"row"}
          spacing={1}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Grid item>
            <CustomButton
              title={"Cancel"}
              handleButtonClick={() => handleModal(false)}
              backgroundcolor={"#FFFFFF"}
              bordercolor={"#204289"}
              color={"#204289"}
              width={110}
            />
          </Grid>
          <Grid item>
            <CustomButton
              title={
                isForPreviousTreatments
                  ? "Apply"
                  : Object.keys(selectedPeriodentalData).length
                  ? "Update"
                  : "Add"
              }
              handleButtonClick={addPeriodentalData}
              backgroundcolor={"#204289"}
              bordercolor={"#204289"}
              color={"#fffff"}
              width={110}
              padding={7}
            />
          </Grid>
        </Grid>
      </Box>
      <CustomModal
        zIndex={"99999"}
        open={openNotesModal}
        width={"500px"}
        height={"250px"}
      >
        <Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              fontSize={"22px"}
              fontFamily={"poppins"}
              fontWeight={"600"}
              color={"#204289"}
            >
              Notes
            </Typography>
            <CloseIcon onClick={handleOpenNotesModal} />
          </Box>
          <Box mt={"20px"}>
            <AddEditNotes
              onCancel={handleOpenNotesModal}
              notes={selectedNotesData.notes || ""}
              handleNotesSubmit={(value: any) => {
                updateState(
                  value.notes,
                  selectedNotesData.mainIndex,
                  selectedNotesData.captureIndex,
                  0,
                  selectedNotesData.periodentalChartType,
                  selectedNotesData.periodontalData,
                  "notes",
                  selectedNotesData.periodentalType
                );
                setOpenNotesModal(false);
              }}
            />
          </Box>
        </Box>
      </CustomModal>
    </>
  );
};

export default React.memo(PeriodontalChart);
