import React, { useEffect, useState } from "react";
import { IRATable } from "../../components/ira-table/ira-table";
import { IRATableColumnProps } from "../../components/ira-table/ira-table.interfaces";
import NameCell from "../../components/TableComponent/name-cell/name-cell";
import ActionCell from "../../components/TableComponent/action-cell";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import CustomDialog from "../../components/CustomDialog";
import SelectClient from "../../components/SelectClient";
import style from "../CasesAndClaims/case-list.module.css";
import ResponseCell from "../../components/TableComponent/responce-cell/response-cell";
import { apiRequest } from "../../app/services/requests";

interface ISelectDialog {
  open?: boolean;
  data?: any;
}

const result = [
  {
    id: "124",
    createdAt: "2024-01-10T10:37:43.271+00:00",
    investigation: {
      toothNo: 18,
      provisionalDiagnosis: "Select Tooth, Invistigation, Implant",
      findings: "Calculus, Dental Carries",
      finalDiagnosis: "Select Tooth, Invistigation, Implant",
      plannedTreatments: "Access opening and BMP done, Access Opening",
      investigation: "OPG",
      examNotes: "Notes",
    },
  },
  {
    id: "124",
    createdAt: "2024-01-10T10:37:43.271+00:00",
    investigation: {
      toothNo: 18,
      provisionalDiagnosis: "Select Tooth, Invistigation, Implant",
      findings: "Calculus, Dental Carries",
      finalDiagnosis: "Select Tooth, Invistigation, Implant",
      plannedTreatments: "Access opening and BMP done, Access Opening",
      investigation: "OPG",
      examNotes: "Notes",
    },
  },
];

 

const Investigation = (props: any) => {
  const {ExaminationID} = props;

  const [investigationData, setInvestigationData] = useState() as any;
  // console.log("Data:", investigationData)
  const navigate = useNavigate();
  const [openSelectClientDialog, setOpenSelectClientDialog] =
    useState<ISelectDialog>({
      open: false,
      data: {},
    });

  // const [caseList, setCaseList] = useState({ data: [], totalCount: 0 });

  useEffect(() => {
    if (ExaminationID && ExaminationID.examinationId) {
      getExaminationById(ExaminationID.examinationId);
    }
  }, [ExaminationID]);

  const getExaminationById = (id: string) => {
    apiRequest({
      url: `case/examination/${id}`, // Use the id parameter instead of caseHistoryId variable

      method: "GET",
    })
      .then((res) => {
        if (res) {
          setInvestigationData(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
    }

  const activityTableColumns = () =>
    [
      {
        name: "teethNo",
        label: "Teeth NO.",
        component: NameCell,
        sortable: false,
        style: { width: "250px" },
      },

      {
        name: "provisionalDiagnosis",
        label: "Provisional Diagnosis",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "investigation",
        label: "Investigation",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "findings",
        label: "Findings",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "finalDiagnosis",
        label: "Final Diagnosis",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "examinationNotes",
        label: "Exam Notes",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "treatmentsPlanned",
        label: "Planned Treatments",
        component: NameCell,
        sortable: false,
        style: { width: "400px" },
      },

      {
        name: "action",
        label: "Action",
        component: (props: any) => {
          return ActionCell({
            button: true,
            btnVariant: "primary",
            btnSize: "smallest",
            disabled: false,
            infoMessage: null,
            label: "Edit",
            onClick: () => {
              // onViewResult(props?.data);
              navigate(`/cases/${props?.data?.id}/details/${"case-history"}`);
            },
            ...props,
          } as any); // <-- This 'as any' is not needed and might be causing the issue
        },
      } as any,
    ] as Array<IRATableColumnProps>;

    

  const activityTableData = () => {
    return investigationData?.examinationLines?.map((row: any) => {


      // let surface: any
      // row?.surface?.map((row1: any) => {
      //   surface = row1
      // })

      // let suggestedTreatments: any
      // row?.suggestedTreatments?.map((row2: any) => {
      //   suggestedTreatments = row2
      // })

      let investigation: any
      row?.investigation?.map((row4: any) => {
        investigation = row4
      })

      let finalDiagnosis: any
      row?.finalDiagnosis?.map((row5: any) => {
        finalDiagnosis = row5
      })
      let treatmentsPlanned: any
      row?.treatmentsPlanned?.map((row5: any) => {
        treatmentsPlanned = row5
      })
    
      return {
        ...row,
        investigationId: {
          name: row?.id,
        },
        createdAt: {
          dateTime: row?.createdAt,
        },
        teethNo: {
          name: `${row?.teethNumber}`,
        },
        provisionalDiagnosis: {
          name: `${row?.provisionalDiagnosis}`,
        },
        investigation: {
          name: `${investigation?.name }`,
        },
        findings: {
          name: `${row?.findings ?? 0}`,
        },
        finalDiagnosis: {
          name: `${finalDiagnosis?.name}`,
        },
        examinationNotes: {
          name: `${row?.examinationNotes}`,
        },

        treatmentsPlanned: {
          name: `${treatmentsPlanned?.name},${treatmentsPlanned?.name}`
        },

        action: {
          data: {
            id: row?.id,
            status: "reviewed",
            project: {
              id: row?.id,
            },
          },
        },
      };
    });
  };

  return (
    <>
      <Box sx={{ pl: 3, pr: 3, borderRadius: 3 }}>
        <IRATable
          className={style.table_heading_layout}
          key={"case-examination-table"}
          columns={activityTableColumns()}
          data={activityTableData()}
          onSort={(name: string, order: string) =>
            alert(`sort requested for ${name} with order ${order}`)
          }
        />
        {openSelectClientDialog.open && (
          <CustomDialog
            width={"400px"}
            open={openSelectClientDialog.open}
            handleClose={() =>
              setOpenSelectClientDialog({ open: false, data: {} })
            }
            title={"Select Client"}
            maxWidth={"lg"}
          >
            <Box>
              <SelectClient
                onClose={() =>
                  setOpenSelectClientDialog({ open: false, data: {} })
                }
                navigateTo={(data: string) => {
                  navigate(`/cases/patient/${"121213"}/add`);
                }}
              />
            </Box>
          </CustomDialog>
        )}
      </Box>
    </>
  );
};

export default React.memo(Investigation);
