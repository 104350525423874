import React from "react";

const SmartText = ({ text = '', length = 12 }) => {
    const [showLess, setShowLess] = React.useState(true);

    if (text && text.length < length) {
        return <p>{text}</p>;
    }

    return (
        <div>
            <p style={{ wordBreak: 'break-word' }}>{showLess ? `${text ? text.slice(0, length) : ''}...` : text}</p>
            <a
                style={{ color: "#204289", cursor: "pointer" }}
                onClick={() => setShowLess(!showLess)}
            >
                &nbsp;View {showLess ? "More" : "Less"}
            </a>
        </div>
    );
};

export default SmartText;