import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { array, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";

import {
  CASE_HISTORY_RELATIONSHIPS,
  FILE_PATHS,
  URLS,
} from "../../app/utilities/constants";
import CustomButton from "../../components/Button";
import CustomAutocomplete from "../../components/Autocomplete";
import {
  ALLERGIES,
  DRUG_HISTORY,
  FAMILY_ILLNESS,
  HABITS,
  ILLNESS,
  MEDICAL_INFORMATION,
  USING_MEDICATIONS,
} from "../../app/utilities/staticData";
import { useEffect, useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import { GENDER } from "../../app/utilities/constants";
import { useAppContext } from "../../app/context/AppContext";
import CustomDatePicker from "../../components/DatePicker";
import CustomSelectPicker from "../../components/SelectPicker";
import CustomInput from "../../components/Input";
import { apiRequest } from "../../app/services/requests";
import FileUploader from "../../components/FileUploader/file-upload";
import { getTrimmedString } from "../../app/utilities/utils";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import moment from "moment";

interface IDocuments {
  key: string;
  originalname: string;
  url: string;
  type: string;
}

const defaultPrevTreatment = {
  treatmentName: "",
  treatmentMonthYear: "",
};

const defaultClinicalHistoryDetails = {
  name: "",
  dob: new Date(),
  gender: "",
  relation: "",
  beneficiaryName: "",
  chiefComplaints: "",
  presentIllness: [],
  pastIllness: [],
  familyHistoryIllness: [],
  drugHistory: [],
  medication: [],
  allergies: [],
  medicalInformation: [],
  habits: [],
  previousTreatments: [defaultPrevTreatment],
};

const FamilyCaseHistory = (props: any) => {
  const {
    onStep2Submit,
    showSkip,
    onClickBack,
    handleCancel,
    appointmentId,
    treatments,
    selectedPlanType,
    editData,
    dialogData,
  } = props;
  const { isClientCaseHistory = false } = dialogData || {};

  const navigate = useNavigate();

  const { planId } = useParams();

  const { setLoader }: any = useAppContext();
  const { CASE_HISTORY_APPOINTMENT_URL, GET_COURSE_MASTER_URL } = URLS;

  const { PATIENTS_PATH } = FILE_PATHS;

  const [tabVal, setTabVal] = useState("photo");

  const [chiefComplaintsData, setChiefComplaintsData] = useState([]);

  let defaultDocs = [
    {
      documentName: "007a6f8e-8251-4079-aee8-ed95d0748a56-images.jpeg",
      documentType: "photo",
      documentPath:
        "patients/1706003178729-007a6f8e-8251-4079-aee8-ed95d0748a56-images.jpeg",
      fileType: "image/jpeg",
    },
    // {
    //   documentName:
    //     "8f173d18-c78a-4d28-85e3-24580afd15e2-SampleJPGImage_1mbmb - Copy.jpg",
    //   documentType: "photo",
    //   documentPath:
    //     "patients/1706003196379-8f173d18-c78a-4d28-85e3-24580afd15e2-SampleJPGImage_1mbmb - Copy.jpg",
    //   fileType: "image/jpeg",
    // },
    // {
    //   documentName: "f369b4b32e715461.png",
    //   documentType: "photo",
    //   documentPath: "patients/1706003196380-f369b4b32e715461.png",
    //   fileType: "image/png",
    // },
    // {
    //   documentName: "pexels-tom-fisk-1519753.jpg",
    //   documentType: "photo",
    //   documentPath: "patients/1706003196382-pexels-tom-fisk-1519753.jpg",
    //   fileType: "image/jpeg",
    // },
  ];

  const [medicalRecords, setMedicalRecords] = useState<any>([]);

  const caseHistorySchema = object({
    beneficiaryName: string().required("Beneficiary Name is required"),
    chiefComplaints: array()
      .min(1, "Chief Complaint is required")
      .required("Chief Complaint is required"),
    presentIllness: array()
      .min(1, "Present Illness is required")
      .required("Present Illness is required"),
    pastIllness: array()
      .min(1, "Past Illness is required")
      .required("Past Illness is required"),
    ...(!isClientCaseHistory && {
      name: string().ensure().required("Name is required"),
      dob: string().ensure().required("Date of Birth is required"),
      gender: string().ensure().required("Gender is required"),
      relation: string().ensure().required("Relation is required"),
    }),
  } as any);

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    watch,
    formState: { isValid: clinicalHistoryFormValid, errors },
  } = useForm({
    resolver: yupResolver(caseHistorySchema),
    defaultValues: defaultClinicalHistoryDetails,
  });

  const formKeys = Object.keys(defaultClinicalHistoryDetails);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "previousTreatments", // Path to the array field
  });

  useEffect(() => {
    if (editData?.id) {
      reset({
        ...editData,
        ...editData?.caseHistory,
      });
      const _medicalRecords = editData?.caseHistory?.documents?.map(
        (file: any) => ({
          url: file,
          originalname: '',
          key: file,
          type: "",
        })
      );
      setMedicalRecords(_medicalRecords);
    }
  }, [editData?.id]);

  const styles = {
    breadcrumbSubtext: {
      fontFamily: "Poppins",
      fontSize: 13,
      color: "#021025",
      fontWeight: "550",
    },
    Container: {
      padding: 2,
    },
    TotalAppointments: {
      fontFamily: "poppins",
      fontSize: 20,
      color: "#021025",
      fontWeight: 600,
    },
    iconContainer: {
      backgroundColor: "primary.main",
      padding: 2,
      borderRadius: "10px",
      height: 38,
      width: 38,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "5px",
      cursor: "pointer",
    },
    addIcon: {
      color: "white",
    },
  } as any;

  const onSubmit = (data: any) => {
    const { name, relation, gender, dob, ...caseDetails } = data;
    const filteredCaseDetails = Object.keys(caseDetails)
      .filter((key) => formKeys.includes(key))
      .reduce((obj: any, key) => {
        obj[key] = caseDetails[key];
        return obj;
      }, {} as { [key: string]: any });
    const finalPayload = {
      name,
      relation,
      gender,
      dob: moment(dob).format("YYYY-MM-DD"),
      caseHistory: {
        ...filteredCaseDetails,
        documents: medicalRecords?.map((file: IDocuments) => file.url) ?? [],
      },
    };

    if (isClientCaseHistory) {
      apiRequest({
        url: `plan/${planId}/update-case-history`,
        data: finalPayload.caseHistory,
        method: "PATCH",
      })
        .then((res) => {
          handleCancel && handleCancel(true);
        })
        .catch((err) => {});
    } else if (editData?.id) {
      apiRequest({
        url: `plan/${planId}/family-member/${editData.id}/update`,
        data: finalPayload,
        method: "PATCH",
      })
        .then((res) => {
          if (res) {
            handleCancel && handleCancel(true);
          }
        })
        .catch((err) => {
          if (err?.statusCode === 403 || err?.statusCode === 401) {
            if (navigate) {
              navigate("/login"); // Navigate to "/login" route
            }
          }
        });
    } else {
      apiRequest({
        url: `plan/${planId}/family-member/add`,
        data: finalPayload,
        method: "POST",
      })
        .then((res) => {
          if (res) {
            handleCancel && handleCancel(true);
          }
        })
        .catch((err) => {
          if (err?.statusCode === 403 || err?.statusCode === 401) {
            if (navigate) {
              navigate("/login"); // Navigate to "/login" route
            }
          }
        });
    }
  };

  const removeImage = (file: IDocuments) => {
    setMedicalRecords((prevStepDetails: any) => {
      return prevStepDetails.filter((item: any) => item.url !== file.url);
    });
  };

  return (
    <Box p={2}>
      {selectedPlanType === "simple" ? (
        <>
          <Box>{"No Case History required Confirm your plan details"}</Box>
          <Box>{"Plan Details Comes here"}</Box>
          <Grid
            container
            flexDirection={"row"}
            spacing={0.5}
            justifyContent={"end"}
            mt={2}
          >
            <Grid item>
              <CustomButton
                border="1px solid #000000"
                color="#000000"
                title="Back"
                bgColor="#ffffff"
                handleButtonClick={onClickBack}
                borderRadius="5px"
              />
            </Grid>
            <Grid item>
              <CustomButton
                border="1px solid #000000"
                color="#000000"
                title="Cancel"
                bgColor="#ffffff"
                handleButtonClick={() => navigate("/users/all")}
                borderRadius="5px"
              />
            </Grid>
            <Grid item>
              <CustomButton
                type="button"
                onClick={() => onStep2Submit("data")}
                title={"Confirm & Next"}
                backgroundcolor={"#204289"}
                bordercolor={"#204289"}
                color={"#fffff"}
                padding={7}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Client Details Section */}
            {!isClientCaseHistory && (
              <Box mt={2}>
                <Typography mb={1.5} mt={2.5}>
                  Family Details
                </Typography>

                <Grid container spacing={2} mb={2}>
                  <>
                    <Grid item xs={6} md={4}>
                      <Controller
                        name={`name`}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            label={"Enter Name"}
                            size={"small"}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={6} md={4}>
                      <Controller
                        name={`dob`}
                        control={control}
                        render={({
                          field: { ref, ...field },
                          fieldState: { error },
                        }) => (
                          <CustomDatePicker
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            maxDate={new Date()}
                            fullWidth={"fullWidth"}
                            label={"Select Date of Birth"}
                            onChange={(event: any) => {
                              // field.onChange(event ? getYearMonth(event.$d) : null);
                              field.onChange(event);
                            }}
                            // views={["month", "year"]}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={6} md={4}>
                      <Controller
                        name={`gender`}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomSelectPicker
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            size={"small"}
                            fullWidth={true}
                            width={"100%"}
                            selectData={GENDER}
                            label={"Select Gender"}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={6} md={4}>
                      <Controller
                        name={`relation`}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomSelectPicker
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            size={"small"}
                            fullWidth={true}
                            width={"100%"}
                            selectData={CASE_HISTORY_RELATIONSHIPS}
                            label={"Select Relation"}
                          />
                        )}
                      />
                    </Grid>
                  </>
                </Grid>
              </Box>
            )}
            <Box sx={styles.formContainer}>
              <Box sx={styles.fieldContainer} mt={1}>
                <Grid
                  container
                  justifyContent={"space-evenly"}
                  alignItems={"center"}
                  rowSpacing={2}
                  spacing={4}
                >
                  <Grid item xs={6} md={6}>
                    <Controller
                      name={"beneficiaryName"}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomSelectPicker
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          size={"small"}
                          fullWidth={true}
                          width={"100%"}
                          selectData={[
                            {
                              id: "1",
                              name: "Member1",
                            },
                            {
                              id: "2",
                              name: "Member2",
                            },
                          ]}
                          label={"Select Beneficiary"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Controller
                      name="chiefComplaints"
                      control={control}
                      render={({
                        field: { ref, onChange },
                        fieldState: { error },
                      }) => (
                        <CustomAutocomplete
                          value={
                            getValues("chiefComplaints")
                              ? getValues("chiefComplaints")
                              : []
                          }
                          key={"chiefComplaints"}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          multiple
                          ref={ref}
                          addText
                          onChange={(event: React.SyntheticEvent, value: any) =>
                            onChange(value)
                          }
                          options={chiefComplaintsData}
                          label="Chief Complaint"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Controller
                      name="presentIllness"
                      control={control}
                      render={({
                        field: { ref, onChange },
                        fieldState: { error },
                      }) => (
                        <CustomAutocomplete
                          // field={field}
                          value={
                            getValues("presentIllness")
                              ? getValues("presentIllness")
                              : []
                          }
                          key={"presentIllness"}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          ref={ref}
                          multiple
                          addText
                          onChange={(event: React.SyntheticEvent, value: any) =>
                            onChange(value)
                          }
                          options={ILLNESS}
                          label="Present illness"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Controller
                      name="pastIllness"
                      control={control}
                      render={({
                        field: { ref, onChange },
                        fieldState: { error },
                      }) => (
                        <CustomAutocomplete
                          // field={field}
                          value={
                            getValues("pastIllness")
                              ? getValues("pastIllness")
                              : []
                          }
                          key={"pastIllness"}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          ref={ref}
                          multiple
                          addText
                          onChange={(event: React.SyntheticEvent, value: any) =>
                            onChange(value)
                          }
                          options={ILLNESS}
                          label="Past illness"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Controller
                      name="familyHistoryIllness"
                      control={control}
                      // rules={{ required: true }}
                      render={({
                        field: { ref, onChange },
                        fieldState: { error },
                      }) => (
                        <CustomAutocomplete
                          value={
                            getValues("familyHistoryIllness")
                              ? getValues("familyHistoryIllness")
                              : []
                          }
                          key={"familyHistoryIllness"}
                          // error={!!error}
                          // helperText={error ? error.message : ""}
                          ref={ref}
                          multiple
                          addText
                          onChange={(event: React.SyntheticEvent, value: any) =>
                            onChange(value)
                          }
                          options={FAMILY_ILLNESS}
                          label="Family illness"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Controller
                      name="drugHistory"
                      control={control}
                      // rules={{ required: true }}
                      render={({
                        field: { ref, onChange },
                        fieldState: { error },
                      }) => (
                        <CustomAutocomplete
                          value={
                            getValues("drugHistory")
                              ? getValues("drugHistory")
                              : []
                          }
                          key={"drugHistory"}
                          // error={!!error}
                          // helperText={error ? error.message : ""}
                          ref={ref}
                          multiple
                          addText
                          onChange={(event: React.SyntheticEvent, value: any) =>
                            onChange(value)
                          }
                          options={DRUG_HISTORY}
                          label="Drug History"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Controller
                      name="medication"
                      control={control}
                      // rules={{ required: true }}
                      render={({
                        field: { ref, onChange },
                        fieldState: { error },
                      }) => (
                        <CustomAutocomplete
                          value={
                            getValues("medication")
                              ? getValues("medication")
                              : []
                          }
                          key={"medication"}
                          // error={!!error}
                          // helperText={error ? error.message : ""}
                          ref={ref}
                          multiple
                          addText
                          onChange={(event: React.SyntheticEvent, value: any) =>
                            onChange(value)
                          }
                          options={USING_MEDICATIONS}
                          label="Using any Medication"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={6}>
                    <Box sx={styles.fieldContainer1}>
                      <Controller
                        name="allergies"
                        control={control}
                        // rules={{ required: true }}
                        render={({
                          field: { ref, onChange },
                          fieldState: { error },
                        }) => (
                          <CustomAutocomplete
                            value={
                              getValues("allergies")
                                ? getValues("allergies")
                                : []
                            }
                            key={"allergies"}
                            // error={!!error}
                            // helperText={error ? error.message : ""}
                            ref={ref}
                            multiple
                            addText
                            onChange={(
                              event: React.SyntheticEvent,
                              value: any
                            ) => onChange(value)}
                            options={ALLERGIES}
                            label="Select Allergies"
                          />
                        )}
                      />
                    </Box>
                  </Grid>

                  <Grid item md={6}>
                    <Box sx={styles.fieldContainer1}>
                      <Controller
                        name="medicalInformation"
                        control={control}
                        // rules={{ required: true }}
                        render={({
                          field: { ref, onChange },
                          fieldState: { error },
                        }) => (
                          <CustomAutocomplete
                            value={
                              getValues("medicalInformation")
                                ? getValues("medicalInformation")
                                : []
                            }
                            key={"medicalInformation"}
                            // error={!!error}
                            // helperText={error ? error.message : ""}
                            ref={ref}
                            multiple
                            addText
                            onChange={(
                              event: React.SyntheticEvent,
                              value: any
                            ) => onChange(value)}
                            options={MEDICAL_INFORMATION}
                            label={"Medical information"}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box sx={styles.fieldContainer1}>
                      <Controller
                        name="habits"
                        control={control}
                        // rules={{ required: true }}
                        render={({
                          field: { ref, onChange },
                          fieldState: { error },
                        }) => (
                          <CustomAutocomplete
                            value={
                              getValues("habits") ? getValues("habits") : []
                            }
                            key={"habits"}
                            // error={!!error}
                            // helperText={error ? error.message : ""}
                            ref={ref}
                            multiple
                            addText
                            onChange={(
                              event: React.SyntheticEvent,
                              value: any
                            ) => onChange(value)}
                            options={HABITS}
                            label="Select Habits"
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={styles.fieldContainer} mt={2}>
                {/* Communication Details Section */}
                <Grid container flexDirection={"row"} spacing={2}>
                  <Grid item xs={12} md={12} mb={2}>
                    <Box
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignContent={"center"}
                      gap={2}
                    >
                      <Typography sx={styles.label1}>
                        Previous Dental Treatments
                      </Typography>
                      <IconButton
                        color="primary"
                        onClick={() =>
                          append({
                            treatmentName: "",
                            treatmentMonthYear: "",
                          })
                        }
                        sx={{ padding: 0 }}
                      >
                        <AddOutlinedIcon />
                      </IconButton>
                    </Box>
                  </Grid>

                  {fields.map((previous, index) => (
                    <Grid
                      key={previous.id}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                      sx={{ marginBottom: 2, paddingLeft: "15px" }}
                    >
                      <Grid item xs={12} sm={5}>
                        <Controller
                          name={`previousTreatments.${index}.treatmentName`}
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <CustomInput
                              {...field}
                              error={!!error}
                              helperText={error ? error.message : ""}
                              label={"Enter Treatment Name"}
                              size="small"
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={5}>
                        <Controller
                          name={`previousTreatments.${index}.treatmentMonthYear`}
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <CustomDatePicker
                              {...field}
                              error={!!error}
                              helperText={error ? error.message : ""}
                              maxDate={new Date()}
                              label={"Select Treatment Month and Year"}
                              onChange={(event: any) => field.onChange(event)}
                              views={["month", "year"]}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <IconButton
                          color="primary"
                          onClick={() => remove(index)} // Remove the current treatment
                          disabled={index === 0} // Disable the remove button for the first item
                        >
                          <RemoveOutlinedIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>

                <Typography sx={styles.label1} mt={1.5}>
                  Documents
                </Typography>

                <Box mt={2}>
                  <FileUploader
                    key={"documents"}
                    uploadType={"documents"}
                    uploadedFiles={medicalRecords}
                    setUploadedFiles={setMedicalRecords}
                    accept={"image/png,image/jpeg,image/jpg"}
                    multiple
                    uploadText={"Upload Documents"}
                  />
                </Box>
                <Box display={"flex"} gap={1} overflow={"auto"} width="100%">
                  {medicalRecords &&
                    medicalRecords?.map((file: IDocuments, index: number) => (
                      <Box
                        display={"flex"}
                        justifyContent={"flex-start"}
                        mt={1}
                      >
                        <Typography variant="body2">
                          {file?.originalname
                            ? file.originalname
                            : `${getTrimmedString(file.url, 10)}`}
                        </Typography>
                        <DeleteForeverIcon
                          fontSize="small"
                          key={file.url + index}
                          style={{ cursor: "pointer" }}
                          color="error"
                          onClick={() => {
                            removeImage(file);
                          }}
                        />
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
            <Grid
              container
              flexDirection={"row"}
              spacing={0.5}
              justifyContent={"end"}
              mt={2}
            >
              <Grid item>
                <CustomButton
                  border="1px solid #000000"
                  color="#000000"
                  title="Cancel"
                  bgColor="#ffffff"
                  handleButtonClick={() => navigate("/users/all")}
                  borderRadius="5px"
                />
              </Grid>
              <Grid item>
                <CustomButton
                  title={"Clear"}
                  handleButtonClick={() => reset({})}
                  border="1px solid #000000"
                  color="#000000"
                  bgColor="#ffffff"
                />
              </Grid>
              <Grid item>
                <CustomButton
                  type="submit"
                  title={"Submit"}
                  backgroundcolor={"#204289"}
                  bordercolor={"#204289"}
                  color={"#fffff"}
                  width={110}
                  padding={7}
                />
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </Box>
  );
};

export default FamilyCaseHistory;
