import React, { useEffect, useState } from "react";
import { IRATable } from "../../components/ira-table/ira-table";
import DateTimeCell from "../../components/TableComponent/date-time-cell";
import { IRATableColumnProps } from "../../components/ira-table/ira-table.interfaces";
import NameCell from "../../components/TableComponent/name-cell/name-cell";
import ActionCell from "../../components/TableComponent/action-cell";
import Breadcrumb from "../../components/Breadcrumb";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import IRATypography from "../../components/ira-typography";
import { Box } from "@mui/material";
import CustomButton from "../../components/Button";
import CustomDialog from "../../components/CustomDialog";
import SelectClient from "../../components/SelectClient";
import { theme } from "../../theme/theme";
import { apiRequest } from "../../app/services/requests";

interface ISelectDialog {
  open?: boolean;
  data?: any;
}

const RequestList = (props: any) => {
  const { showOnlyList = true, hideBreadScrum } = props;
  const navigate = useNavigate();

  const [requestList, setRequestList] = useState<any>({
    totalCount: 0,
    results: [],
  });

  const result = [
    {
      id: "123",
      createdAt: "2024-01-10T10:37:43.271+00:00",
      patient: {
        id: 1,
        firstName: "Nick",
        lastName: "JK",
        mobile: 9988665577,
      },
      service: "OP",
      totalCost: 15000,
      claim: 10000,
      status: "Claim",
    },
    {
      id: "124",
      createdAt: "2024-01-10T10:37:43.271+00:00",
      patient: {
        id: 1,
        firstName: "Nick",
        lastName: "JK",
        mobile: 9988665577,
      },
      service: "Treatment",
      totalCost: 15000,
      claim: 10000,
      status: "New Case",
    },
  ];

  const breadcrumbs = [
    <RouterLink to="/Dashboard" style={{ textDecoration: "none" }}>
      <IRATypography key="2" textVariant="h12WithSemiBold">
        Home
      </IRATypography>
    </RouterLink>,
    <IRATypography key="2" textVariant="h12WithSemiBold">
      Billing
    </IRATypography>,
  ];

  useEffect(() => {
    getPendingRequests();
  }, []);

  const getPendingRequests = () => {
    apiRequest({
      url: `plan/pending-list`,
      method: "POST",
    })
      .then((res) => {
        if (res) {
          setRequestList(res);
        }
      })
      .catch((err) => {
        // if (err?.statusCode === 403 || err?.statusCode === 401) {
        //   if (navigate) {
        //     navigate("/login"); // Navigate to "/login" route
        //   }
        // }
      });
  };

  const activityTableColumns = () =>
    [
      {
        name: "requestId",
        label: "Req.Id",
        component: NameCell,
        sortable: false,
        style: { width: "250px", color: theme.palette.customColors.main },
      },
      {
        name: "masterPlan",
        label: "Plan",
        component: NameCell,
        sortable: false,
        style: { width: "250px", color: theme.palette.customColors.main },
      },
      {
        name: "createdAt",
        label: "Created At",
        component: DateTimeCell,
        sortable: false,
        style: { width: "250px", color: "#CB8C00" },
      },
      {
        name: "client",
        label: "Client",
        component: NameCell,
        sortable: false,
        style: { width: "250px" },
      },
      {
        name: "status",
        label: "Status",
        component: NameCell,
        sortable: false,
        style: { width: "250px" },
      },
      {
        name: "action",
        label: "Action",
        component: (props: any) => {
          return ActionCell({
            button: true,
            btnVariant: "primary",
            btnSize: "smallest",
            disabled: false,
            infoMessage: null,
            label: "View",
            onClick: () => {
              navigate(`/request/plan/${props?.data?.id}/details`);
            },
            ...props,
          } as any); // <-- This 'as any' is not needed and might be causing the issue
        },
      } as any,
    ] as Array<IRATableColumnProps>;

  const activityTableData = () => {
    return requestList?.results?.map((row: any) => {
      return {
        ...row,
        requestId: {
          name: row?.id,
        },
        masterPlan: {
          name: `${row?.masterPlan?.code} - ${row?.masterPlan?.name}`,
        },
        createdAt: {
          dateTime: row?.createdAt,
        },
        client: {
          name: `${row?.client?.firstName} ${row?.client?.lastName} `,
        },
        status: {
          name: `${row?.status}`,
        },
        action: {
          data: {
            id: row?.id,
          },
        },
      };
    });
  };

  const handleButtonClick = (type: string) => {
    // if (type === "addCase") {
    //   setOpenSelectClientDialog({ open: true });
    // } else {
    //   navigate(`/plan/${}/details`);
    // }
  };

  return (
    <>
      {!hideBreadScrum && (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          {/* <Breadcrumb title={`Query List`} breadcrumbsArr={[]} /> */}

          {/* <Box style={{ display: "flex", gap: "8px" }}>
            <CustomButton
              onClick={() => handleButtonClick("Button1")}
              title={"Export Cases"}
            />
          </Box> */}
        </Box>
      )}

      {showOnlyList && (
        <Box>
          <IRATable
            columns={activityTableColumns()}
            data={activityTableData()}
            onSort={(name: string, order: string) =>
              alert(`sort requested for ${name} with order ${order}`)
            }
          />
        </Box>
      )}
    </>
  );
};

export default React.memo(RequestList);
