import { useForm, Controller } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../../components/Input";
import CustomButton from "../../../components/Button";
import { apiRequest } from "../../../app/services/requests";

const PublishConformationForm = (props: any) => {
  const { onClose, planId } = props;

  // console.log(currentEquipment, isEdit);

  const userDefaultValues = () => {
    return {
      comments: "",
    };
  };

  const navigate = useNavigate();

  const schema = object({
    comments: string().required("Comments are required"),
  });

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...userDefaultValues() },
  });

  const onSubmit = (formData: any) => {
    apiRequest({
      url: `master-plan/${planId}/publish`,
      method: "POST",
      data: { comments: formData?.comments },
    })
      .then((res) => {
        onClose();
        props?.onGetAllPlanList();
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Client Details Section */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Controller
              name="comments"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Enter Comments..."}
                  multiline
                  size={"small"}
                  rows={3}
                />
              )}
            />
          </Grid>
        </Grid>

        {/* Additional Sections can be added as needed */}
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <CustomButton
            border="1px solid #000000"
            color="#000000"
            title="No"
            bgColor="#ffffff"
            onClick={() => props.onClose()}
            borderRadius="5px"
          />
          <Box marginLeft={1}>
            {" "}
            {/* Add a margin to create space between buttons */}
            <CustomButton title={"Yes"} type="submit" borderRadius="5px" />
          </Box>
        </Box>
      </form>
    </>
  );
};

export default PublishConformationForm;
