import { useEffect, useState } from "react";
import { apiRequest } from "../../app/services/requests";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import CustomSelectPicker from "../../components/SelectPicker";

const DoctorsCell = () => {
  const [doctorsData, setDoctorsData] = useState<any>([]);
  const navigate = useNavigate();
  const { control } = useForm();

  useEffect(() => {
    getDoctorsById();
  }, []);

  const getDoctorsById = () => {
    apiRequest({
      url: `clinic/clinicId/doctor/list`, // Use the userId from the URL
      method: "POST",
    })
      .then((res: any) => {
        let data = res?.data?.map((item: any) => ({
          name: `${item?.firstName} ${item?.lastName}`,
          id: item?.id,
        }));
        setDoctorsData(data);
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  return (
    <div>
      {/* <Grid item xs={6} md={6}> */}
      <Controller
        name="doctorId"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <CustomSelectPicker
            {...field}
            error={!!error}
            helperText={error ? error.message : ""}
            label={"Doctor Name"}
            size={"small"}
            fullWidth={true}
            width={"100%"}
            selectData={doctorsData}
          />
        )}
      />
      {/* </Grid> */}
    </div>
  );
};

export default DoctorsCell;
