import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles((theme: any) => ({
  accordion: {
    border: "1px solid #e0e0e0", // Replace with your desired border color
    borderRadius: "20px",
    marginBottom: "1px",
    "&:last-child": {
      marginBottom: 0,
    },
  },
  accordionSummary: {
    backgroundColor: "#f5f5f5", // Replace with your desired background color
    borderRadius: "1px",
  },
  // expandIcon: {
  //   color: theme.palette.text.secondary,
  // },
}));

const CustomAccordion = (props: any) => {
  const {
    title,
    children,
    isSummaryChildren = false,
    summaryChildren,
    ...rest
  } = props;
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const classes = useStyles();

  return (
    <div>
      <Accordion
        // expanded={expanded === "panel1"}
        // onChange={handleChange("panel1")}
        // className={classes.accordion}
        sx={{ borderRadius: props.borderRadius }}
        {...rest}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            height: "48px !important",
            borderRadius: props.borderRadius,
          }}
        >
          {summaryChildren ? (
            summaryChildren
          ) : (
            <>
              <Typography>{title}</Typography>
            </>
          )}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CustomAccordion;
