export const styles = {
  breadcrumbTitle: {
    fontWeight: "600",
    fontSize: 18,
    color:"#222222"
  },
  breadcrumbContainer: {
    marginTop:10,
  },
};
