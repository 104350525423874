import { IRATable } from "../../components/ira-table/ira-table";
import DateTimeCell from "../../components/TableComponent/date-time-cell";
import { IRATableColumnProps } from "../../components/ira-table/ira-table.interfaces";
import NameCell from "../../components/TableComponent/name-cell/name-cell";
import ActionCell from "../../components/TableComponent/action-cell";
import { Box } from "@mui/material";
import moment from "moment";

const ClientClaims = () => {
  const result = [
    {
      id: "123",
      createdAt: "2024-01-10T10:37:43.271+00:00",
      patient: {
        id: 1,
        firstName: "Nick",
        lastName: "JK",
      },
      bookingType: "OP",
      treatment: "-",
    },
    {
      id: "124",
      createdAt: "2024-01-10T10:37:43.271+00:00",
      patient: {
        id: 1,
        firstName: "Ram",
        lastName: "G",
      },
      bookingType: "TREATMENT",
      treatment: "Root Canal",
    },
  ];

  const activityTableColumns = () =>
    [
      {
        name: "caseId",
        label: "Case Id",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "createdAt",
        label: "Case Date",
        component: DateTimeCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "patientName",
        label: "Patient Name",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "relation",
        label: "Relation",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "appliedAt",
        label: "Applied Date",
        component: DateTimeCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "claim",
        label: "Claim",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "status",
        label: "Status",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "action",
        label: "Action",
        component: (props: any) => {
          return ActionCell({
            button: true,
            btnVariant: "primary",
            btnSize: "smallest",
            disabled: false,
            infoMessage: null,
            label: "View",
            onClick: () => {
              // onViewResult(props?.data);
              alert("navigate to appointment view" + props.data?.id);
            },
            ...props,
          } as any); // <-- This 'as any' is not needed and might be causing the issue
        },
      } as any,
    ] as Array<IRATableColumnProps>;

  const activityTableData = () => {
    return result?.map((row: any) => {
      return {
        ...row,
        caseId: {
          name: "Case_01",
        },
        createdAt: {
          dateTime: moment(row.createdAt).format("DD-MM-YYYY"),
        },
        patientName: {
          name: `${row?.patient?.firstName} ${row?.patient?.lastName} `,
        },
        relation: {
          name: `spouse `,
        },
        appliedAt: {
          dateTime: row?.createdAt,
        },
        claim: {
          name: `5000`,
        },
        status: {
          name: `Claimed`,
        },
        action: {
          data: {
            id: row?.id,
            status: "reviewed",
            project: {
              id: row?.id,
            },
          },
        },
      };
    });
  };

  return (
    <>
      <Box p={3}>
        <IRATable
          columns={activityTableColumns()}
          data={activityTableData()}
          onSort={(name: string, order: string) =>
            alert(`sort requested for ${name} with order ${order}`)
          }
        />
      </Box>
    </>
  );
};

export default ClientClaims;
