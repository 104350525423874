import { useForm, Controller } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSelectPicker from "../../../components/SelectPicker";
import CustomButton from "../../../components/Button";
import CustomInput from "../../../components/Input";
import { apiRequest } from "../../../app/services/requests";
import { useNavigate } from "react-router-dom";

const AddEditEquipment = (props: any) => {
  const { currentSubCategory, onClose, isEdit, currentEquipment, clinicId } =
    props;

  // console.log(currentEquipment, isEdit);

  const userDefaultValues = (isEdit: boolean) => {
    if (!isEdit) {
      return {
        equipmentType: "",
        equipmentDetails: "",
      } as any;
    } else {
      return {
        equipmentType: currentEquipment?.equipmentType,
        equipmentDetails: currentEquipment?.equipmentDetails,
      };
    }
  };

  const navigate = useNavigate();

  const schema = object({
    equipmentType: string().required("Type is required"),
    equipmentDetails: string().required("Details are required"),
  });

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...userDefaultValues(isEdit) },
  });

  const onSubmit = (formData: any) => {
    apiRequest({
      url:
        isEdit === true
          ? `clinic/equipment/${currentEquipment?.id}/update`
          : `clinic/equipment/add`,
      data: { ...formData, clinicId: Number(clinicId) },
      method: isEdit === true ? "PATCH" : "POST",
    })
      .then((res: any) => {
        onClose();
        props.getAllEquipments();
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Client Details Section */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Controller
              name="equipmentType"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectPicker
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Equipment Type"}
                  size={"small"}
                  fullWidth={true}
                  width={"100%"}
                  selectData={[
                    {
                      id: "chairs",
                      name: "Chairs",
                    },
                    {
                      id: "electronics",
                      name: "Electronics",
                    },
                    {
                      id: "light",
                      name: "Lights",
                    },
                    {
                      id: "tables",
                      name: "Tables",
                    },
                  ]}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="equipmentDetails"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Equipment Details"}
                  multiline
                  size={"small"}
                  rows={3}
                />
              )}
            />
          </Grid>
        </Grid>

        {/* Additional Sections can be added as needed */}
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <CustomButton
            border="1px solid #000000"
            color="#000000"
            title="Cancel"
            bgColor="#ffffff"
            onClick={() => props.onClose()}
            borderRadius="5px"
          />
          <Box marginLeft={1}>
            {" "}
            {/* Add a margin to create space between buttons */}
            <CustomButton
              title={currentSubCategory?.open === "add" ? "Create" : "Save"}
              type="submit"
              borderRadius="5px"
            />
          </Box>
        </Box>
      </form>
    </>
  );
};

export default AddEditEquipment;
