import React, { useEffect, useState } from "react";
import { Box, Checkbox, Grid, Typography } from "@mui/material";
import CustomButton from "../../../components/Button";
import { apiRequest } from "../../../app/services/requests";
import CustomDialog from "../../../components/CustomDialog";
import ServiceSetup from "./service-setup";

const SelectedServicesSummary = (props: any) => {
  const { onClose, planId, category } = props || {};

  const [serviceList, setServiceList] = useState<any>({
    results: [],
    totalCount: 0,
  });

  const [openServiceSetUpDialog, setOpenServiceSetUpDialog] = useState<any>({
    open: false,
    data: {},
  });

  useEffect(() => {
    if (planId) {
      getServicesSummaryByPlan(planId);
    }
  }, [planId]);

  const getServicesSummaryByPlan = (planId: any) => {
    apiRequest({
      url: `treatment/treatments-by-master-plan-summary?category=${category}&masterPlanId=${planId}&limit=20&offset=0`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          // Map through the results and add both isSelected and original_enableFree
          const updatedResult = res.results.map((treatment: any) => ({
            ...treatment,
            isSelected: treatment.master_plan_treatment_id ? true : false,
            original_enableFree: treatment.enableFree, // Store original value
          }));

          // Update serviceList with the modified results
          setServiceList({
            ...res,
            results: updatedResult,
          });
        }
      })
      .catch((err) => {
        // Handle error
      });
  };

  return (
    <>
      <Box mt={"10px"} px={3}>
        <Grid container spacing={2}>
          {Array.isArray(serviceList?.results) &&
            serviceList.results.map((service: any, index: number) => (
              <Grid item xs={6} key={index}>
                <Grid container spacing={2}>
                  <Grid item xs={8} key={index}>
                    <Box
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      gap={"20px"}
                    >
                      <Box>
                        <Box
                          display={"flex"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                        >
                          <Checkbox
                            disableRipple
                            size="small"
                            checked={service.isSelected}
                            onChange={
                              (event) => {
                                event?.preventDefault();
                              }
                              // handleCheckedConsentForm(event, index, "service")
                            }
                          />
                          <Box>
                            <Typography sx={{ fontSize: "14px" }}>
                              {`${service?.code}-${service.name}`}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    {service.isSelected && service.enableFree && (
                      <Box>
                        <Box
                          display={"flex"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                        >
                          <Checkbox
                            disableRipple
                            size="small"
                            checked={service.enableFree}
                            onChange={(event) => event?.preventDefault()}
                          />
                          <Box>
                            <Typography sx={{ fontSize: "14px" }}>
                              {"free"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={2} key={index}>
                    <Box>
                      <CustomButton
                        border="1px solid #000000"
                        color="#000000"
                        title="Setup"
                        bgColor="#ffffff"
                        onClick={() =>
                          setOpenServiceSetUpDialog({
                            open: true,
                            data: { ...service },
                          })
                        }
                        padding={0}
                        width={"60px"}
                        borderRadius="5px"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
        {openServiceSetUpDialog.open && (
          <CustomDialog
            open={openServiceSetUpDialog.open}
            handleClose={() =>
              setOpenServiceSetUpDialog({ open: false, data: {} })
            }
            title={"Service Setup"}
            fullScreen
            maxWidth
            height={"auto"}
          >
            <Box width={"100%"}>
              <ServiceSetup
                planId={planId}
                serviceId={
                  openServiceSetUpDialog?.data?.master_plan_treatment_id
                }
                onClose={(action?: any) => {
                  setOpenServiceSetUpDialog({ open: false, data: {} });
                  if (action === "fetch" && planId) {
                    getServicesSummaryByPlan(planId);
                  }
                }}
              />
            </Box>
          </CustomDialog>
        )}
      </Box>
    </>
  );
};

export default SelectedServicesSummary;
