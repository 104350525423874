import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router";
import RenewalPlanDetails from "./renewal-plan-details";
import UpgradePlanDetails from "./upgrade-plan-details";
import UpgradeRenewalPlanDetails from "./upgrade-renewal-plan-details";
import CancelPlanDetails from "./cancel-plan-details";
import ClientProfileCard from "../../../components/ClientProfileCard";
import IRATypography from "../../../components/ira-typography/ira-typography";
import Breadcrumb from "../../../components/Breadcrumb";
import { Link as RouterLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { apiRequest } from "../../../app/services/requests";

const PlanAction = (props: any) => {
  const { clientId, actionName = "" } = useParams();
  const [clientData, setClientData] = useState();
  // console.log(clientId, "clientId");
  // console.log(clientData, "ClientData1");
  const navigate = useNavigate();

  useEffect(() => {
    getClientDetails();
  }, []);

  const getClientDetails = () => {
    apiRequest({
      url: `client/${clientId}`,
      method: "GET",
    })
      .then((res: any) => {
        if (res) {
          setClientData(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  const handleBackArrow = () => {
    navigate(`/client/${clientId}/view/plan-details`);
  };
  const renderPlanByActionName = (actionName: string) => {
    switch (actionName) {
      case "renewal":
        return <RenewalPlanDetails onHandleBackArrow={handleBackArrow} />;

      case "upgrade":
        return <UpgradePlanDetails onHandleBackArrow={handleBackArrow} />;

      case "upgrade-renewal":
        return (
          <UpgradeRenewalPlanDetails onHandleBackArrow={handleBackArrow} />
        );
      case "cancel":
        return <CancelPlanDetails onHandleBackArrow={handleBackArrow} />;
      default:
        return null;
    }
  };

  const breadcrumbs = [
    <RouterLink to="/Dashboard" style={{ textDecoration: "none" }} key="1001">
      <IRATypography
        key="2"
        textVariant="h12WithSemiBold"
        sx={{ fontSize: 14, color: "#222222" }}
      >
        Home
      </IRATypography>
    </RouterLink>,
    <IRATypography
      key="3"
      textVariant="h12WithSemiBold"
      sx={{ fontSize: 14, color: "#222222" }}
    >
      Client Profile
    </IRATypography>,
  ];

  return (
    <Container maxWidth="lg" sx={{ padding: "0 !important" }}>
      <Box sx={{ mt: 2 }}>
        <Breadcrumb
          title={`Client Profile`}
          key="case-list-breadcrumb"
          breadcrumbsArr={breadcrumbs}
        />
      </Box>
      <Box mt={2}>
        <Grid container spacing={2}>
          {/* 3 columns */}
          <Grid item xs={3.5}>
            <Box>
              <ClientProfileCard clientDetails={clientData} />
            </Box>
          </Grid>
          {/* 9 columns */}
          <Grid item xs={8.5}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap={2}
              mb={2.5}
            ></Box>

            {renderPlanByActionName(actionName)}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PlanAction;
