import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "../../components/Input";
import CustomButton from "../../components/Button";
import { ORGANIZATION_MEMBERSHIP_OPTIONS } from "../../app/utilities/constants";

import CustomAutocomplete from "../../components/Autocomplete";
import CustomUpload from "../../components/CustomUpload";

const DoctorCareerDetailsStep2 = (props: any) => {
  const {
    currentSubCategory = {} as any,
    isEdit,
    onStep2Submit,
    onClickBack,
    data,
  } = props;

  const [chiefComplaintsData, setChiefComplaintsData] = useState([]);

  const userDefaultValues = () => {
    return {
      clinicRegistrationNumber: "232323",
      yearOfGraduation: "2020",
      yearOfPostGraduation: "2023",
      workExperienceInYear: "",
      awardsAndRecognitions: "",
      trainingOrCourses: "",
      isOrganizationMembership: "",
      educationCertificate: "",
      achievementCertificate: "",
      experienceCertificate: "",
      additionalDocument: "",
    } as any;
  };

  const schema = object({
    clinicRegistrationNumber: string().required(
      "clinic Registration Number is required"
    ),
  });

  const { control, handleSubmit, getValues, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: userDefaultValues(),
  });

  const onSubmit = (formData: any) => {
    onStep2Submit({ ...formData });
  };

  return (
    <>
      <Container maxWidth="lg">
        <Box mt={4} style={{ width: "80%", marginLeft: "10%" }}>
          <Box>
            <Box px={4} py={4}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                name="careerForm"
                key={"career_form_doctor"}
              >
                {/* Client Details Section */}
                <Typography variant="body1">Career Details</Typography>

                <Grid container spacing={3} mt={1}>
                  {/* Add client details fields here */}
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="clinicRegistrationNumber"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          label={"Enter Dental Clinic No."}
                          InputProps={""}
                          size={"small"}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3} mt={1}>
                  {/* Add client details fields here */}
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="yearOfGraduation"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          label={"Enter Year of Graduation"}
                          InputProps={""}
                          size={"small"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="yearOfPostGraduation"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          label={"Enter Year of Post Graduation"}
                          InputProps={""}
                          size={"small"}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3} mt={1}>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="workExperienceInYear"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          label={"Enter Work Experience in Year"}
                          InputProps={""}
                          size={"small"}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                {/* Communication Details Section */}
                <Box mt={3} mb={1}>
                  <Typography variant="body1">Additional Details</Typography>
                </Box>

                <Grid container item spacing={3}>
                  <Grid item xs={6} md={6}>
                    <Box>
                      <Controller
                        name="awardsAndRecognitions"
                        control={control}
                        render={({
                          field: { ref, onChange },
                          fieldState: { error },
                        }) => (
                          <CustomAutocomplete
                            value={
                              getValues("awardsAndRecognitions")
                                ? getValues("awardsAndRecognitions")
                                : []
                            }
                            key={"awardsAndRecognitions"}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            multiple
                            ref={ref}
                            addText
                            onChange={(
                              event: React.SyntheticEvent,
                              value: any
                            ) => onChange(value)}
                            options={chiefComplaintsData}
                            label="Select or Enter Awards and Recognition"
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Box>
                      <Controller
                        name="trainingOrCourses"
                        control={control}
                        render={({
                          field: { ref, onChange },
                          fieldState: { error },
                        }) => (
                          <CustomAutocomplete
                            value={
                              getValues("trainingOrCourses")
                                ? getValues("trainingOrCourses")
                                : []
                            }
                            key={"trainingOrCourses"}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            multiple
                            ref={ref}
                            addText
                            onChange={(
                              event: React.SyntheticEvent,
                              value: any
                            ) => onChange(value)}
                            options={chiefComplaintsData}
                            label="Select or Enter Training and Courses"
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>

                {/* Communication Details Section */}
                <Box mt={3} mb={1}>
                  <Typography variant="body1">
                    Organization Membership
                  </Typography>
                </Box>

                <Box>
                  <Controller
                    name={`isOrganizationMembership`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl component="fieldset">
                        <RadioGroup {...field} row>
                          {ORGANIZATION_MEMBERSHIP_OPTIONS.map(
                            (option: any, idx: any) => (
                              <FormControlLabel
                                key={idx}
                                value={option.id}
                                control={<Radio />}
                                label={option.name}
                              />
                            )
                          )}
                        </RadioGroup>
                        {error && (
                          <FormHelperText sx={{ color: "#d32f2f !important" }}>
                            {error.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Box>

                {/* Education Documents Section */}
                <Box mt={4}>
                  {/* <Typography variant="body1">Education Documents*</Typography> */}
                  <Grid container spacing={3} mt={1}>
                    <Grid item xs={6} md={6}>
                      <Typography variant="body1">
                        Education Documents*
                      </Typography>
                      <Controller
                        name="educationCertificate"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <CustomUpload
                              key={"educationCertificate"}
                              onChange={(file) => field.onChange(file)}
                              onBlur={field.onBlur}
                              error={!!error}
                              helperText={error ? error.message : ""}
                            />
                            {/* <Box
                              style={{
                                borderRadius: "5px",
                                borderStyle: "dashed",
                                padding: "5px",
                                paddingLeft: "10px",
                                borderColor: "rgba(92, 78, 255, 1)",
                              }}
                            >
                              <Typography variant="body1">
                                <span style={{ color: "rgba(92, 78, 255, 1)" }}>
                                  Drag and Drop
                                </span>{" "}
                                or{" "}
                                <span
                                  style={{
                                    color: "rgba(92, 78, 255, 1)",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Upload
                                </span>{" "}
                                to Add Document
                              </Typography>
                            </Box> */}
                          </>
                        )}
                      />
                    </Grid>

                    <Grid item xs={6} md={6}>
                      <Typography variant="body1">
                        Achievements(optional)
                      </Typography>
                      <Controller
                        name="achievementCertificate"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <CustomUpload
                              key={"achievementCertificate"}
                              onChange={(file) => field.onChange(file)}
                              onBlur={field.onBlur}
                              error={!!error}
                              helperText={error ? error.message : ""}
                            />
                            {/* <Box
                              style={{
                                borderRadius: "5px",
                                borderStyle: "dashed",
                                padding: "5px",
                                paddingLeft: "10px",
                                borderColor: "rgba(92, 78, 255, 1)",
                              }}
                            >
                              <Typography variant="body1">
                                <span style={{ color: "rgba(92, 78, 255, 1)" }}>
                                  Drag and Drop
                                </span>{" "}
                                or{" "}
                                <span
                                  style={{
                                    color: "rgba(92, 78, 255, 1)",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Upload
                                </span>{" "}
                                to Add Document
                              </Typography>
                            </Box> */}
                          </>
                        )}
                      />
                    </Grid>

                    <Grid item xs={6} md={6}>
                      <Typography variant="body1">
                        Experience Documents(if any)
                      </Typography>
                      <Controller
                        name="experienceCertificate"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <CustomUpload
                              key={"experienceCertificate"}
                              onChange={(file) => field.onChange(file)}
                              onBlur={field.onBlur}
                              error={!!error}
                              helperText={error ? error.message : ""}
                            />
                            {/* <Box
                              style={{
                                borderRadius: "5px",
                                borderStyle: "dashed",
                                padding: "5px",
                                paddingLeft: "10px",
                                borderColor: "rgba(92, 78, 255, 1)",
                              }}
                            >
                              <Typography variant="body1">
                                <span style={{ color: "rgba(92, 78, 255, 1)" }}>
                                  Drag and Drop
                                </span>{" "}
                                or{" "}
                                <span
                                  style={{
                                    color: "rgba(92, 78, 255, 1)",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Upload
                                </span>{" "}
                                to Add Document
                              </Typography>
                            </Box> */}
                          </>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography variant="body1">
                        Additional Documents(optional)
                      </Typography>
                      <Controller
                        name="additionalDocument"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <CustomUpload
                              key={"additionalDocument"}
                              onChange={(file) => field.onChange(file)}
                              onBlur={field.onBlur}
                              error={!!error}
                              helperText={error ? error.message : ""}
                            />
                            {/* <Box
                              style={{
                                borderRadius: "5px",
                                borderStyle: "dashed",
                                padding: "5px",
                                paddingLeft: "10px",
                                borderColor: "rgba(92, 78, 255, 1)",
                              }}
                            >
                              <Typography variant="body1">
                                <span style={{ color: "rgba(92, 78, 255, 1)" }}>
                                  Drag and Drop
                                </span>{" "}
                                or{" "}
                                <span
                                  style={{
                                    color: "rgba(92, 78, 255, 1)",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Upload
                                </span>{" "}
                                to Add Document
                              </Typography>
                            </Box> */}
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Box mr={1}>
            <CustomButton
              border="1px solid #000000"
              color="#000000"
              title="Back"
              bgColor="#ffffff"
              handleButtonClick={onClickBack}
              borderRadius="5px"
            />
          </Box>

          <Box marginLeft={1}>
            {" "}
            {/* Add a margin to create space between buttons */}
            <CustomButton
              bgColor="rgba(92, 78, 255, 1)"
              title={currentSubCategory?.open === "add" ? "Create" : "Submit"}
              type="button"
              borderRadius="5px"
              handleButtonClick={() => {
                onStep2Submit({ ...getValues() });
              }}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default DoctorCareerDetailsStep2;
