import { useState } from "react";
import { Box } from "@mui/material";
import CustomButton from "../Button";
import ClientPlanDetailsCard from "../ClientPlanDetailsCard";
import ClientSearch from "../ClientProfileCard/ClientSearch";
import { useNavigate } from "react-router-dom";

const SelectClient = (props: any) => {
  const [showPatientDetails, setShowPatientDetails] = useState() as any;

  const [clientData, setClientData] = useState({}) as any;

  const { openSelectClientDialog } = props;

  const navigate = useNavigate();

  const continueToProceed = () => {
    if (
      showPatientDetails === true &&
      // props.fromCommomnDashboard === true &&
      openSelectClientDialog?.data?.type === "addCase"
    ) {
      navigate(`/cases/client/${clientData?.clientData?.id}/add`);
    } else if (
      showPatientDetails === true &&
      // props.fromCommomnDashboard === true &&
      openSelectClientDialog?.data?.type === "renewal"
    ) {
      navigate(`/client/${clientData?.clientData?.id}/plan/action/renewal`); //renewal
    } else if (
      showPatientDetails === true &&
      // props.fromCommomnDashboard === true &&
      openSelectClientDialog?.data?.type === "upgrade"
    ) {
      navigate(`/client/${clientData?.clientData?.id}/plan/action/upgrade`); // upgrade
    } else if (
      showPatientDetails === true &&
      // props.fromCommomnDashboard === true &&
      openSelectClientDialog?.data?.type === "upgrade-renewal"
    ) {
      navigate(
        `/client/${clientData?.clientData?.id}/plan/action/upgrade-renewal`
      ); //upgrade-renewal
    } else if (
      showPatientDetails === true &&
      // props.fromCommomnDashboard === true &&
      openSelectClientDialog?.data?.type === "cancel"
    ) {
      navigate(`/client/${clientData?.clientData?.id}/plan/action/cancel`); // upgrade
    } else if (
      showPatientDetails === true &&
      // props.fromCommomnDashboard === true &&
      openSelectClientDialog?.data?.type === "health"
    ) {
      navigate(`/client/${clientData?.clientData?.id}/plan/health`); //health Plan
    } else if (
      showPatientDetails === true &&
      // props.fromCommomnDashboard === true &&
      openSelectClientDialog?.data?.type === "discount"
    ) {
      navigate(`/client/${clientData?.clientData?.id}/plan/discount`); //Discount Plan
    } else if (
      showPatientDetails === true &&
      // props.fromCommomnDashboard === true &&
      openSelectClientDialog?.data?.type === "customize"
    ) {
      navigate(`/client/${clientData?.clientData?.id}/plan/customize`); //Discount Plan
    }
  };
  console.log("Open", openSelectClientDialog);

  return (
    <>
      {!showPatientDetails ? (
        <Box>
          <ClientSearch
            setClientData={(data: any) => {
              setClientData(data);
              if (data) {
                setShowPatientDetails(true);
              }
            }}
          />
        </Box>
      ) : (
        <ClientPlanDetailsCard client={clientData} />
      )}

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <CustomButton
          border="1px solid #000000"
          color="#000000"
          title="Cancel"
          bgColor="#ffffff"
          onClick={() => props.onClose()}
          borderRadius="5px"
        />
        <Box marginLeft={1}>
          <CustomButton
            title={"Proceed"}
            type="button"
            borderRadius="5px"
            onClick={continueToProceed}
            disabled={clientData?.value ? false : true}
          />
        </Box>
      </Box>
    </>
  );
};

export default SelectClient;
