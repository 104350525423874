import { Box, Grid, Typography } from "@mui/material";
import CustomButton from "../../../components/Button";
import { theme } from "../../../theme/theme";

import { useEffect, useState } from "react";
import { PaymentSuccessScreen } from "../components/payment-success";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "../../../app/services/requests";
import { useSnackbar } from "../../../app/context/useSnackbar";
import CustomDialog from "../../../components/CustomDialog";
import ClientAppointmentForm from "../../Appointment/client-book-appointment-form";
import { isEmpty } from "lodash";

declare global {
  interface Window {
    Razorpay: any;
  }
}

export const PaymentDetailsWithLabel = ({ label, value }: any) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Typography
          color={theme.palette.customColors.blackGrey}
          fontSize={"16px"}
          variant="h6"
          fontWeight={"600"}
        >
          {label}
        </Typography>
      </Box>
      <Box>
        <Typography
          color={theme.palette.customColors.blackGrey}
          fontSize={"16px"}
          variant="h6"
          fontWeight={"600"}
        >
          {`₹  ${value}`}
        </Typography>
      </Box>
    </Box>
  );
};

const Step3 = (props: any) => {
  const { onClickBack } = props;
  const { openSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const { clientId, orderId, planCode } = useParams<any>();

  const [clientDetails, setClientDetails] = useState("") as any;

  const [showPaymentSuccessScreen, setShowPaymentSuccessScreen] =
    useState<any>(false);

  const [orderDetails, setOrderDetails] = useState<any>({});

  const [orderSuccess, setOrderSuccess] = useState({});
  const [openSelectClientDialog, setOpenSelectClientDialog] = useState(false);
  const currentUserDetails = JSON.parse(localStorage.getItem("user") || "{}");

  useEffect(() => {
    getClientDetails();
    if (orderId) {
      getOrderDetailsById(orderId);
    }
  }, [orderId]);

  const getClientDetails = () => {
    apiRequest({
      url: `client/${clientId}`,
      method: "GET",
    })
      .then((res: any) => {
        if (res) {
          setClientDetails(res);
        }
      })
      .catch((err) => {
        console.error("err", err);
      });
  };

  const getOrderDetailsById = (orderId?: any) => {
    apiRequest({
      url: `order/record/${orderId}`,
      method: "GET",
    })
      .then((res: any) => {
        if (res) {
          setOrderDetails({ ...res });
        }
      })
      .catch((err) => {
        console.error("err", err);
      });
  };

  function loadScript(src?: any) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const proceedPayment = async (orderId?: any) => {
    try {
      const res = await apiRequest({
        url: `order/${orderId}/proceed-payment`,
        method: "POST",
      });
      setOrderDetails(res);
      return res;
    } catch (err: any) {
      openSnackbar(err?.response?.data?.message, "error");
      throw err;
    }
  };

  function formatAmountForPayment(amount: number, currency: string): number {
    const threeDecimalCurrencies = ["BHD", "JOD", "KWD", "OMR", "TND"]; // Add more if needed

    if (threeDecimalCurrencies.includes(currency)) {
      const amountStr = amount.toFixed(3); // Ensure three decimal places
      const adjustedAmount = amountStr.slice(0, -1) + "0"; // Replace last digit with 0
      return Math.round(parseFloat(adjustedAmount) * 100); // Convert to minor units
    }

    return Math.round(amount * 100); // Default for two-decimal currencies
  }

  async function displayRazorpay() {
    try {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        openSnackbar(
          "Razorpay SDK failed to load. Please check your internet connection.",
          "error"
        );
        return;
      }

      if (!orderDetails?.id) {
        openSnackbar("Order details are missing. Please try again.", "error");
        return;
      }

      // ✅ Wait for updated order details
      const updatedOrder: any = await proceedPayment(orderDetails?.id);
      if (!updatedOrder) {
        openSnackbar("Failed to proceed with payment.", "error");
        return;
      }

      const {
        id: orderId,
        advancedPrice = 2000,
        currency = "INR",
      } = updatedOrder;

      const formattedAmount = formatAmountForPayment(advancedPrice, currency);

      if (!window.Razorpay) {
        openSnackbar(
          "Razorpay is not available. Please refresh the page and try again.",
          "error"
        );
        return;
      }

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: formattedAmount,
        currency: currency,
        name: "DentAssure",
        description: "Test Transaction",
        image: require("../../../app/assets/logo.png"),
        order_id: updatedOrder?.payment?.orderId, // ✅ Use updated order details
        handler: async (paymentResponse: any) => {
          try {
            const paymentData = {
              orderId: updatedOrder?.payment?.orderId, // ✅ Use updated order ID
              paymentId: paymentResponse.razorpay_payment_id,
            };

            const response: any = await apiRequest({
              url: `order/${updatedOrder.id}/status`,
              data: paymentData,
              method: "PATCH",
            });
            if (response?.status === "success") {
              openSnackbar("Your payment was successful!", "success");
              setOrderSuccess(response);
              // setTimeout(() => navigate("/dashboard"), 3000);
            } else {
              openSnackbar("Payment failed. Please try again.", "error");
            }
          } catch (error: any) {
            console.error("Payment API Error:", error);
            openSnackbar(
              error?.response?.data?.message || "Payment processing failed.",
              "error"
            );
          }
        },
        prefill: {
          name: `${clientDetails?.firstName ?? ""} ${
            clientDetails?.lastName ?? ""
          }`,
          email: clientDetails?.email ?? "",
          contact: clientDetails?.mobile ?? "",
        },
        notes: {
          address: "Example Corporate Office",
        },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error("Razorpay Payment Error:", error);
      openSnackbar(
        "An unexpected error occurred. Please try again later.",
        "error"
      );
    }
  }

  const {
    discount,
    totalPrice,
    totalPriceAfterDiscount,
    totalPriceAfterTax: amountToBePaid,
    tax,
    totalMembers,
    planPrice,
    advancedPrice,
  } = orderDetails;

  return (
    <>
      <Box p={2}>
        {showPaymentSuccessScreen ? (
          <PaymentSuccessScreen />
        ) : (
          <Box display="flex" flexDirection="column" gap={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography
                color={theme.palette.customColors.blackGrey}
                fontSize={"16px"}
                variant="h6"
                fontWeight={"600"}
              >
                Payment Details
              </Typography>
              <Typography
                color={theme.palette.customColors.blackGrey}
                fontSize={"16px"}
                variant="h6"
                fontWeight={"600"}
                alignItems="center"
              >
                {`Family members : ${totalMembers}`}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              style={{ backgroundColor: "#f5f5f5" }}
              gap={1}
              p={2}
            >
              <PaymentDetailsWithLabel
                label="Actual Plan Amount"
                value={planPrice}
              />
              <PaymentDetailsWithLabel
                label={`Plan Premium (${planPrice} × ${totalMembers})`}
                value={totalPrice}
              />
              {discount?.amount && (
                <PaymentDetailsWithLabel
                  label={`Discount (${discount?.percentage}%)`}
                  value={discount?.amount}
                />
              )}
              {discount?.amount && (
                <PaymentDetailsWithLabel
                  label="After Discount"
                  value={totalPriceAfterDiscount}
                />
              )}
              <PaymentDetailsWithLabel
                label={`${tax?.name} (${tax?.percentage}%)`}
                value={tax?.amount}
              />
              <PaymentDetailsWithLabel
                label="Total Amount"
                value={amountToBePaid}
              />
              <PaymentDetailsWithLabel
                label={`Advance Amount (20% of Total Amount)`}
                value={advancedPrice}
              />
            </Box>
          </Box>
        )}

        {showPaymentSuccessScreen === false && (
          <Grid
            container
            flexDirection={"row"}
            spacing={0.5}
            justifyContent={"end"}
            mt={4}
          >
            {!isEmpty(orderSuccess) && (
              <Grid item>
                <CustomButton
                  title="Book Appointment"
                  backgroundcolor={"#204289"}
                  bordercolor={"#204289"}
                  color={"#fffff"}
                  handleButtonClick={() => setOpenSelectClientDialog(true)}
                  borderRadius="5px"
                />
              </Grid>
            )}
            <Grid item>
              <CustomButton
                border="1px solid #000000"
                color="#000000"
                title="Back"
                bgColor="#ffffff"
                disabled={!isEmpty(orderSuccess)}
                handleButtonClick={() =>
                  navigate(
                    `/client/${clientId}/plan/standard/2/${orderDetails?.planId}/${planCode}`
                  )
                }
                borderRadius="5px"
              />
            </Grid>
            <Grid item>
              <CustomButton
                border="1px solid #000000"
                color="#000000"
                title="Cancel"
                bgColor="#ffffff"
                handleButtonClick={() => navigate("/dashboard")}
                borderRadius="5px"
              />
            </Grid>
            <Grid item>
              <CustomButton
                type="submit"
                title={"Pay Now"}
                disabled={!isEmpty(orderSuccess)}
                backgroundcolor={"#204289"}
                bordercolor={"#204289"}
                color={"#fffff"}
                handleButtonClick={displayRazorpay}
                width={110}
                padding={7}
              />
            </Grid>
          </Grid>
        )}
      </Box>
      {openSelectClientDialog && (
        <CustomDialog
          width={"650px"}
          open={openSelectClientDialog}
          handleClose={() => setOpenSelectClientDialog(false)}
          title={"Book Appointment"}
          maxWidth={"lg"}
        >
          <Box>
            <ClientAppointmentForm
              userDetails={currentUserDetails}
              onClose={() => setOpenSelectClientDialog(false)}
            />
          </Box>
        </CustomDialog>
      )}
    </>
  );
};

export default Step3;
