import Typography, { TypographyProps } from "@mui/material/Typography";
// import { makeStyles } from 'tss-react/mui';
import clsx from "clsx";
import React from "react";
import { pxToRem } from "../../app/utilities/utils";

/* eslint-disable-next-line */
export interface IRATypographyProps extends TypographyProps {
  textVariant:
    | "h1"
    | "h1WithBold"
    | "h2"
    | "h2WithLight"
    | "h2WithBold"
    | "h2WithSemiBold"
    | "h3"
    | "h3WithLight"
    | "h3WithSemiBold"
    | "h3WithBold"
    | "h4WithBold"
    | "h4WithSemiBold"
    | "h4"
    | "h5"
    | "h5WithSemiBold"
    | "h5WithBold"
    | "h6"
    | "h6WithSemiBold"
    | "h6WithBold"
    | "h6WithLight"
    | "h7"
    | "h7WithSemiBold"
    | "h7WithBold"
    | "h7WithMediumBold"
    | "h8"
    | "h8WithExtraLight"
    | "h8WithLight"
    | "h8WithSemiBold"
    | "h8WithBold"
    | "h8WithMedium"
    | "h9"
    | "h9WithLight"
    | "h9WithExtraLight"
    | "h9WithMedium"
    | "h9WithSemiBold"
    | "h9WithBold"
    | "h10"
    | "h10WithLight"
    | "h10WithSemiBold"
    | "h10WithBold"
    | "h11"
    | "h11WithLight"
    | "h11WithMedium"
    | "h11WithSemiBold"
    | "h11WithBold"
    | "h12"
    | "h12WithMedium"
    | "h12WithSemiBold";
}
export const IRATypography = (props: IRATypographyProps) => {
  const { textVariant, className, ...rest } = props;

  
  const classes = {
    h1: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 700,
      fontSize: pxToRem(40),
      lineHeight: "150%",
      letterSpacing: "normal",
    },

    h2: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(30),
      lineHeight: "150%",
      letterSpacing: "normal",
    },

    h3: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 700,
      fontSize: pxToRem(26),
      lineHeight: "150%",
      letterSpacing: "normal",
    },

    h4: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(24),
      lineHeight: "150%",
      letterSpacing: "normal",
    },

    h5: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 700,
      fontSize: pxToRem(22),
      lineHeight: "150%",
      letterSpacing: "normal",
    },

    h6: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(20),
      lineHeight: "150%",
      letterSpacing: "normal",
    },
    h7: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(18),
      lineHeight: "150%",
      letterSpacing: "normal",
    },
    h8: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(16),
      lineHeight: "150%",
      letterSpacing: "normal",
    },
    h9: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 400,
      fontSize: '2px',
      lineHeight: "150%",
      letterSpacing: "normal",
    },
    h10: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(13),
      lineHeight: "150%",
      letterSpacing: "normal",
    },
    h11: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(12),
      lineHeight: "150%",
      letterSpacing: "normal",
    },
    h12: {
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(10),
      lineHeight: "150%",
      letterSpacing: "normal",
    },
    extraLight: {
      fontWeight: 200,
    },
    light: {
      fontWeight: 300,
    },
    medium: {
      fontWeight: 500,
    },
    semiBold: {
      fontWeight: 600,
    },
    bold: {
      fontWeight: 700,
    },
  } as any;

  const getClassName = (customClassName: string = "") => {
    let baseClass = "";
    switch (textVariant) {
      case "h1":
        baseClass = classes.h1;
        break;
      case "h1WithBold":
        baseClass = clsx(classes.h1, classes.bold);
        break;
      case "h2":
        baseClass = classes.h2;
        break;
      case "h2WithLight":
        baseClass = clsx(classes.h2, classes.light);
        break;
      case "h2WithSemiBold":
        baseClass = clsx(classes.h2, classes.semiBold);
        break;
      case "h2WithBold":
        baseClass = clsx(classes.h2, classes.bold);
        break;
      case "h3":
        baseClass = classes.h3;
        break;
      case "h3WithLight":
        baseClass = clsx(classes.h3, classes.light);
        break;
      case "h3WithSemiBold":
        baseClass = clsx(classes.h3, classes.semiBold);
        break;
      case "h3WithBold":
        baseClass = clsx(classes.h3, classes.bold);
        break;
      case "h4":
        baseClass = classes.h4;
        break;
      case "h4WithBold":
        baseClass = clsx(classes.h4, classes.bold);
        break;
      case "h4WithSemiBold":
        baseClass = clsx(classes.h4, classes.semiBold);
        break;
      case "h5":
        baseClass = classes.h5;
        break;
      case "h5WithSemiBold":
        baseClass = clsx(classes.h5, classes.semiBold);
        break;
      case "h5WithBold":
        baseClass = clsx(classes.h5, classes.bold);
        break;
      case "h6":
        baseClass = classes.h6;
        break;
      case "h6WithSemiBold":
        baseClass = clsx(classes.h6, classes.semiBold);
        break;
      case "h6WithBold":
        baseClass = clsx(classes.h6, classes.bold);
        break;
      case "h6WithLight":
        baseClass = clsx(classes.h6, classes.light);
        break;
      case "h7":
        baseClass = classes.h7;
        break;
      case "h7WithSemiBold":
        baseClass = clsx(classes.h7, classes.semiBold);
        break;
      case "h7WithBold":
        baseClass = clsx(classes.h7, classes.bold);
        break;
        break;
      case "h7WithMediumBold":
        baseClass = clsx(classes.h7, classes.medium);
        break;

      case "h8":
        baseClass = classes.h8;
        break;
      case "h8WithExtraLight":
        baseClass = clsx(classes.h8, classes.extraLight);
        break;
      case "h8WithLight":
        baseClass = clsx(classes.h8, classes.light);
        break;
      case "h8WithSemiBold":
        baseClass = clsx(classes.h8, classes.semiBold);
        break;
      case "h8WithBold":
        baseClass = clsx(classes.h8, classes.bold);
        break;
      case "h8WithMedium":
        baseClass = clsx(classes.h8, classes.medium);
        break;
      case "h9":
        baseClass = classes.h9;
        break;
      case "h9WithExtraLight":
        baseClass = clsx(classes.h9, classes.extraLight);
        break;
      case "h9WithLight":
        baseClass = clsx(classes.h9, classes.light);
        break;
      case "h9WithSemiBold":
        baseClass = clsx(classes.h9, classes.semiBold);
        break;
      case "h9WithMedium":
        baseClass = clsx(classes.h9, classes.medium);
        break;
      case "h9WithBold":
        baseClass = clsx(classes.h9, classes.bold);
        break;
      case "h10":
        baseClass = classes.h10;
        break;
      case "h10WithLight":
        baseClass = clsx(classes.h10, classes.light);
        break;
      case "h10WithSemiBold":
        baseClass = clsx(classes.h10, classes.semiBold);
        break;
      case "h10WithBold":
        baseClass = clsx(classes.h10, classes.bold);
        break;
      case "h11":
        baseClass = classes.h11;
        break;
      case "h11WithLight":
        baseClass = clsx(classes.h11, classes.light);
        break;
      case "h11WithMedium":
        baseClass = clsx(classes.h11, classes.medium);
        break;
      case "h11WithSemiBold":
        baseClass = clsx(classes.h11, classes.semiBold);
        break;
      case "h11WithBold":
        baseClass = clsx(classes.h11, classes.bold);
        break;
      case "h12":
        baseClass = classes.h12;
        break;
      case "h12WithMedium":
        baseClass = clsx(classes.h12, classes.medium);
        break;
      case "h12WithSemiBold":
        baseClass = clsx(classes.h12, classes.semiBold);
        break;
    }
    return clsx(baseClass, customClassName);
  };

  return (
    <Typography className={getClassName(className)} {...rest}></Typography>
  );
};

/**
 * @method getTypography
 * @param {String} text
 * @param {IRATypographyProps} props
 * Its a Getter Wrapper function to get Typography
 */
export const getTypography = (text: string, props: IRATypographyProps) => {
  return <IRATypography {...props}>{text}</IRATypography>;
};

export default IRATypography;
