import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Box, Container, Grid, Typography } from "@mui/material";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../.././components/Button";
import { COUNTRY_LIST, PHONE_REG_EXP } from "../.././app/utilities/constants";
import StatesAndCities from "../.././app/utilities/StatesAndCities.json";
import CustomCheckbox from "../.././components/CustomCheckBox";
import CustomAutocomplete from "../.././components/Autocomplete";
import CustomCard from "../.././components/Card";
import styless from "../Client/client.module.css";
import { apiRequest } from "../.././app/services/requests";
import { useSnackbar } from "../.././app/context/useSnackbar";
import CustomInput from "../../components/Input";

const AddEditHospital = (props: any) => {
  const { currentSubCategory = {} as any } = props;

  const { hospitalId } = useParams();
  const navigate = useNavigate();

  const [cities, setCities] = useState<any>([]);
  const [statesAndCities] = useState<any>(StatesAndCities);

  const [otp] = useState<Boolean>(false);
  const [success] = useState<Boolean>(false);

  const { openSnackbar } = useSnackbar();

  const userDefaultValues = () => {
    return {
      zipCode: "",
      city: "",
      state: "",
      country: "",
      addressLine2: "",
      addressLine1: "",
      pan: "",
      aadhar: "",
      email: "",
      maritalStatus: "",
      dateOfBirth: "",
      mobileNumber: "",
      countryCode: "",
      registrationNumber: "",
      hospitalName: "",
      photo: "",
      appointment: "",
      hasWhatsAppForMobileNumber: "",
      alternateMobileNumber: "",
      hasWhatsAppForAlternateMobileNumber: "",
    } as any;
  };

  const schema = object({
    hospitalName: string().required("Hospital Name is required"),
    registrationNumber: string().required("Hospital Regd No is required"),
    mobileNumber: string()
      .required("Mobile number is required")
      .min(10, "Mobile number must be at least 10 characters long")
      .max(10, "Mobile number should not be greater than 10 digits")
      .matches(PHONE_REG_EXP, "Phone number is not valid"),
    email: string()
      .required("Email is required")
      .nullable()
      .email("Email is not valid"),

    addressLine1: string().required("Address Line 1 is required"),
    addressLine2: string().required("Address Line 2 is required"),
    country: string().required("Country is required"),
    state: string().required("State is required"),
    city: string().required("City is required"),
    zipCode: string()
      .required("Zip Code is required")
      .max(6, "Zip Code should be of 6 characters"),
  });

  const { control, handleSubmit, getValues, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: userDefaultValues(),
  });

  useEffect(() => {
    if (hospitalId) {
      getHospitalById(hospitalId);
    }
  }, [hospitalId]);

  const getHospitalById = (hospitalId: any) => {
    apiRequest({
      url: `clinic/${hospitalId}`, // Use the documentId from the URL
      method: "GET",
    })
      .then((res: any) => {
        if (res) {
          reset({
            dateOfBirth: new Date(),
            countryCode: "+91",
            registrationNumber: "",
            ...res,
            ...(res?.address && {
              ...res?.address,
            }),
            hospitalName: res.name,
          });
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  const handleCancel = () => {
    navigate("/dashboard");
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;
    if (files && files.length > 0) {
      const file = files[0];
      // You can handle the file upload logic here (e.g., upload to server or store locally)
    }
  };

  const onSubmit = (data: any) => {
    const formData = getValues();
    const payload = {
      clinicLogo: "logo",
      description: "clinic description",
      name: formData?.hospitalName,
      registrationNumber: formData?.registrationNumber,
      mobileNumber: formData?.mobileNumber,
      email: formData?.email,
      hasWhatsAppForMobileNumber: true,
      alternateMobileNumber: formData?.alternateMobileNumber,
      hasWhatsAppForAlternateMobileNumber: true,
      address: {
        addressLine1: formData?.addressLine1,
        addressLine2: formData?.addressLine2,
        city: formData?.city,
        country: formData?.country,
        state: formData?.state,
        zipCode: formData?.zipCode,
      },
      contactPersonName: "NA",
      contactPersonRole: "NA",
      contactPersonMobileNumber: "1111111111",
      otherDocuments: [],
      aadharDocumentUrl: "*",
      panDocumentUrl: "*",
      timings: {
        friday: "9:00 AM - 5:00 PM",
        monday: "9:00 AM - 5:00 PM",
        sunday: "Closed",
        tuesday: "9:00 AM - 5:00 PM",
        saturday: "9:00 AM - 1:00 PM",
        thursday: "9:00 AM - 5:00 PM",
        wednesday: "9:00 AM - 5:00 PM",
      },
    };

    apiRequest({
      url: hospitalId ? `clinic/${hospitalId}` : `clinic`,
      data: payload,
      method: hospitalId ? "PATCH" : "POST",
    })
      .then((res: any) => {
        openSnackbar("Hospital Saved Successfully", "success");
        if (hospitalId) {
          navigate(`/hospital/${hospitalId}/profile/documents`);
        } else {
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  return (
    <>
      <Container maxWidth="lg" className={otp || success ? styless.long : ""}>
        {" "}
        <Box sx={{ paddingTop: "15px" }}>
          <Typography
            fontSize={"20px"}
            gutterBottom
            style={{ marginBottom: "0em" }}
          >
            {otp ? "Client" : "Hospital Enrollment"}
          </Typography>
          <Typography fontSize={"12px"} style={{ marginBottom: "0.7em" }}>
            {otp ? "Home > Edit Client" : "Home > Hospital Enrollment"}
          </Typography>
        </Box>
        <Box mt={2}>
          <Box
            style={{
              backgroundImage: `url(${require("../.././app/assets/AddNewClient/back.png")})`,
              height: "13vh",
              backgroundRepeat: "round",
              marginBottom: "2vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              style={{ marginLeft: "27vw", height: "70px" }}
              component="img"
              src={require("../.././app/assets/logo.png")}
            ></Box>
            <Box style={{ marginLeft: "10px", width: "52%" }}>
              <Typography
                style={{ color: "rgba(33, 159, 255, 1)", fontSize: "20px" }}
              >
                {"DentAssure Hospital Enrollment Form"}
              </Typography>
              <Typography variant="body2" style={{ width: "60%" }}>
                {
                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                }
              </Typography>
            </Box>
            <CustomButton
              title={"Help"}
              marginRight={"5px"}
              bgColor={"rgba(92, 78, 255, 1)"}
              height={"25px"}
            />
          </Box>

          <CustomCard style={{ borderRadius: "10px" }}>
            <Box px={4} py={4}>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* Client Details Section */}

                <Typography variant="body1" style={{ marginTop: "10px" }}>
                  Hospital Details
                </Typography>

                <Grid container spacing={3} mt={1}>
                  {/* Add client details fields here */}
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="hospitalName"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          label={"Enter Hospital Name"}
                          InputProps={""}
                          size={"small"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="registrationNumber"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          label={"Enter Hospital Regd No"}
                          InputProps={""}
                          size={"small"}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                {/* Communication Details Section */}
                <Box mt={3}>
                  <Typography variant="body1">Communication Details</Typography>
                  {/* Add communication details fields here */}
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4} mt={4}>
                      <Box>
                        <Box style={{ display: "flex" }}>
                          <Controller
                            name="countryCode"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                label={"Ex.+91"}
                                style={{ width: "47px", marginRight: "10px" }}
                                type="text"
                                {...field}
                                error={!!error}
                                helperText={error ? error.message : ""}
                              />
                            )}
                          />
                          <Controller
                            name="mobileNumber"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                label={"Primary Mobile No"}
                                type="text"
                                {...field}
                                error={!!error}
                                helperText={error ? error.message : ""}
                                width={"250px"}
                              />
                            )}
                          />
                        </Box>
                        <Controller
                          name="hasWhatsAppForMobileNumber"
                          control={control}
                          render={({ field }) => (
                            <CustomCheckbox
                              {...field}
                              label="Whatsapp Number"
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} mt={4}>
                      <Box>
                        <Box style={{ display: "flex" }}>
                          <Controller
                            name="countryCode"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                // label={"Primary Mobile No"}
                                style={{ width: "47px", marginRight: "10px" }}
                                type="text"
                                {...field}
                                error={!!error}
                                helperText={error ? error.message : ""}
                              />
                            )}
                          />
                          <Controller
                            name="alternateMobileNumber"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                label={"Secondary Mobile No"}
                                type="text"
                                {...field}
                                error={!!error}
                                helperText={error ? error.message : ""}
                                width={"250px"}
                              />
                            )}
                          />
                        </Box>
                        <Controller
                          name="hasWhatsAppForAlternateMobileNumber"
                          control={control}
                          render={({ field }) => (
                            <CustomCheckbox
                              {...field}
                              label="Whatsapp Number"
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} mt={4}>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            label={"Email"}
                            size={"small"}
                            disabled={Boolean(hospitalId)}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>

                {/* Address for Communication Section */}
                <Box mt={3}>
                  <Typography variant="body1">
                    Address for Communication
                  </Typography>
                  {/* Add address for communication fields here */}

                  <Grid container spacing={3} mt={1}>
                    <Grid item xs={6} md={4}>
                      <Controller
                        name="addressLine1"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            label={"Enter Address Line 1"}
                            InputProps={""}
                            size={"small"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Controller
                        name="addressLine2"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            label={"Enter Address Line 2"}
                            InputProps={""}
                            size={"small"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Box>
                        <Controller
                          name="country"
                          defaultValue={"India"}
                          control={control}
                          rules={{ required: true }}
                          render={({
                            field: { ref, onChange, ...field },
                            fieldState: { error },
                          }) => (
                            <CustomAutocomplete
                              field={field}
                              // disabled
                              error={!!error}
                              helperText={error ? error.message : ""}
                              ref={ref}
                              singleSelection
                              onChange={(
                                event: React.SyntheticEvent,
                                value: any
                              ) => onChange(value)}
                              options={COUNTRY_LIST}
                              value={getValues("country")}
                              label="Country"
                              defaultValue={"India"}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Controller
                        name="state"
                        control={control}
                        defaultValue={getValues("state")}
                        rules={{ required: true }}
                        render={({
                          field: { ref, onChange, ...field },
                          fieldState: { error },
                        }) => (
                          <CustomAutocomplete
                            field={field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            ref={ref}
                            value={getValues("state")}
                            singleSelection
                            onChange={(
                              event: React.SyntheticEvent,
                              value: any
                            ) => {
                              onChange(value);
                              setValue("city", "");
                              setCities(statesAndCities[value] || []);
                            }}
                            options={Object.keys(statesAndCities)}
                            label="State"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Controller
                        name="city"
                        control={control}
                        defaultValue={getValues("city")}
                        rules={{ required: true }}
                        render={({
                          field: { ref, onChange, ...field },
                          fieldState: { error },
                        }) => (
                          <CustomAutocomplete
                            field={field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            ref={ref}
                            value={getValues("city")}
                            singleSelection
                            onChange={(
                              event: React.SyntheticEvent,
                              value: any
                            ) => {
                              onChange(value);
                              setValue("city", value);
                            }}
                            options={cities}
                            label="City"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={4} md={4}>
                      <Controller
                        name="zipCode"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            label={"Zip code"}
                            size={"small"}
                            type={"number"}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>

                {/* Additional Sections can be added as needed */}
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  <CustomButton
                    title={"Cancel"}
                    marginRight={"5px"}
                    bgColor={"rgba(255, 225, 229, 1)"}
                    width={"100px"}
                    color={"rgba(255, 97, 116, 1)"}
                    borderColor={"rgba(255, 97, 116, 1)"}
                    borderWidth={"1px"}
                    borderStyle={"solid"}
                    onClick={handleCancel}
                  />

                  <Box marginLeft={1}>
                    <CustomButton
                      title={
                        currentSubCategory?.open === "add" ? "Create" : "Save"
                      }
                      marginRight={"5px"}
                      bgColor={"rgba(92, 78, 255, 1)"}
                      width={"100px"}
                      type={"submit"}
                    />
                  </Box>
                </Box>
              </form>
            </Box>
          </CustomCard>
        </Box>
      </Container>
    </>
  );
};

export default AddEditHospital;
