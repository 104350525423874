import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import CustomInput from "../../components/Input";
import CustomButton from "../../components/Button";
import styles from "./login.module.css";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import BgImage from "../../app/assets/Dashboard/login.jpg";
import { useNavigate } from "react-router-dom";
import { theme } from "../../theme/theme";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { apiRequest } from "../../app/services/requests";
import { useAppContext } from "../../app/context/AppContext";
import { useSnackbar } from "../../app/context/useSnackbar";

const LoginPage = (props: any) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const { handleLoginSuccess } = useAppContext() as any;

  const { openSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const schema = yup
    .object({
      email: yup
        .string()
        .required("Email is required")
        .test("len", "Must be  10-digit mobile number", (val) => {
          if (!val) return true; // Allow empty values
          const isEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(val);
          const isMobileNumber = /^\d{10}$/.test(val);
          return isEmail || isMobileNumber;
        }),
      password: yup
        .string()
        .required("Password is required")
        .min(6, "The password must be of six characters."),
    })
    .required();
  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { email: "", password: "" },
  });

  const onSubmit = (data: any) => {
    const payload = {
      password: data.password,
      email: data.email,
    };
    apiRequest({
      url: `user/login`,
      data: payload,
      method: "POST",
    })
      .then((res) => {
        if (res?.success === true) {
          localStorage.setItem("user", JSON.stringify(res));
          handleLoginSuccess(res);
          navigate("/dashboard");
          openSnackbar(res?.message, "success");
        }
        if (res?.success === false) {
          openSnackbar(res?.message, "error");
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      <Box bgcolor={"#fff"} position={"fixed"} width={"100%"}>
        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <Grid item xs={7} padding={"0px !important"}>
            <Box
              style={{
                backgroundImage: `url(${BgImage})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
              position={"relative"}
              borderRadius={"30px"}
              height={"94vh"}
              width={"100%"}
              margin={"30px"}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "0px",
                  borderRadius: "30px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box
                  display={"flex"}
                  gap={"10px"}
                  alignItems={"center"}
                  padding={"20px 0px 0px 20px"}
                >
                  <img
                    className={styles.logoImage}
                    alt="Logo"
                    src={require("../../app/assets/logo.png")}
                  />
                  <Box>
                    <Typography
                      fontSize={"24px"}
                      color={theme.palette.customColors.main}
                    >
                      DentaAssure
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={5} style={{ backgroundColor: "white" }}>
            <Box paddingTop={"130px"}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={"2px"}
                alignItems={"center"}
              >
                <Typography className={styles.MainHeading}>Sign In</Typography>
              </Box>
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Box className={styles.row}>
                  <Grid
                    item
                    xs={10}
                    md={8}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"26px"}
                  >
                    <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
                      <Typography
                        color={"#021025"}
                        fontWeight={"550"}
                        fontSize={"12px"}
                      >
                        Email Address
                      </Typography>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            helperText={error ? error.message : ""}
                            error={!!error}
                            placeholder={"Enter Email Address"}
                            size={"small"}
                          />
                        )}
                      />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography
                          color={"#021025"}
                          fontWeight={"550"}
                          fontSize={"12px"}
                        >
                          Password
                        </Typography>
                      </Box>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            helperText={error ? error.message : ""}
                            error={!!error}
                            placeholder={"Enter Password"}
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  sx={{ cursor: "pointer" }}
                                >
                                  {showPassword ? (
                                    <VisibilityIcon
                                      onClick={handleClickShowPassword}
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      onClick={handleClickShowPassword}
                                    />
                                  )}
                                </InputAdornment>
                              ),
                            }}
                            size={"small"}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                </Box>
                <Box className={styles.row}>
                  <Grid item xs={10} md={8} mt={"20px"}>
                    <CustomButton
                      type={"submit"}
                      title={"Sign In"}
                      backgroundcolor={"#204289"}
                      bordercolor={"#204289"}
                      color={"#fffff"}
                      width={"100%"}
                      padding={8}
                    />
                  </Grid>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default LoginPage;
