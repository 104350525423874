import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../app/context/AppContext";
import Step3 from "./step3";
import Stepper from "../../components/stepper/stepper";
import { useSnackbar } from "../../app/context/useSnackbar";
import DoctorPersonDetailsStep1 from "./step1";
import DoctorCareerDetailsStep2 from "./step2";

const AddEditDoctor = (props: any) => {
  const { currentSubCategory = {} as any, isEdit, hospitalId } = props;

  const navigate = useNavigate();

  const [step, setStep] = useState<any>(0);

  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (step !== 0) {
      // dispatch(getOnboardingPageDetails({ where: { pageNumber: step + 1 } }));
    }
    if (step === 1) {
      // dispatch(getDegrees({ where: { field: 'DEGREES' } }));
    }
    if (step === 2) {
      // dispatch(getTechnicalCourses({ where: { field: 'TECHNICAL_COURSES' } }));
      // dispatch(
      //   getCertificationCourses({ where: { field: 'CERTIFICATION_COURSES' } })
      // );
    }
  }, [step]);

  useEffect(() => {
    return () => {
      // dispatch(setCurrentStep(0));
      setStep(0);
    };
  }, []);

  const [selectedPlanType, setSelectedPlanType] = useState<any>("");

  const handleStep1Submit = (formData: any) => {
    setSelectedPlanType(formData?.planType);
    setFormData({ ...formData });
    setStep(step + 1);
  };

  const handleStep2Submit = (formData: any) => {
    setFormData((prev) => ({
      ...prev,
      formData,
    }));
    setStep(step + 1);
  };

  const handleStep3Submit = (formData: any) => {
    setStep(step + 3);
  };

  const handleSkip = () => {
    // if (!user.onboarding.isSkipped) {
    //   user.onboarding.isSkipped = true;
    //   setUser({ ...user });
    //   dispatch(skipOnboarding());
    // }
    // onOnboardingSkip();
  };

  const handleBack = () => {
    setStep((prevStep: any) => step - 1);
  };

  function getCurrentStep(step: number) {
    switch (step) {
      case 0:
        return (
          <DoctorPersonDetailsStep1
            onStep1Submit={handleStep1Submit}
            onClickSkip={handleSkip}
            showSkip={false}
          />
        );
      case 1:
        return (
          <DoctorCareerDetailsStep2
            onStep2Submit={handleStep2Submit}
            onClickBack={handleBack}
            selectedPlanType={selectedPlanType}
            formData={formData}
          />
        );
      case 2:
        return (
          <Step3 onStep3Submit={handleStep3Submit} onClickBack={handleBack} />
        );
    }
  }

  return (
    <>
      <Box
        width={"98%"}
        style={{
          justifyContent: "center",
          marginLeft: "1%",
          borderRadius: 20,
          background: "white",
        }}
      >
        <Box mt={2}>
          <Box px={4} pt={2} style={{ display: "ruby-text" }}>
            <Stepper
              sx={{
                "& .MuiStep-root": {
                  paddingLeft: "4px !important",
                  paddingRight: "4px !important",
                },
              }}
              steps={3}
              style={{ width: "50%" }}
              activeStep={step}
              stepperVariant="circle"
              stepOptions={{
                "0": "Personal Details",
                "1": "Career & Additional",
                "2": "Completed",
              }}
            />
          </Box>
          {/* <Box minHeight="300px">{getCurrentStep(step)}</Box> */}
          <Box minHeight="300px">{getCurrentStep(step)}</Box>
        </Box>
      </Box>
    </>
  );
};

export default AddEditDoctor;
