export const styles = {
    nametext: {
        fontSize: 14,
        fontFamily: "poppins",
        fontWeight: "550",
        color: '#204289',
        
    },
    selectedTeethLabel : {
        fontFamily : 'Poppins',
        fontSize : 18,
        color : 'primary.main'
    },
    screenContainer : {
        marginTop  : 2,
        marginRight :2,
        marginBottom : 2
    },
    cardHeader : {
        fontFamily : 'Poppins',
        fontSize : 12,
        color : 'primary.main',
        fontWeight : 500
    },
    Tablist: {
        '.css-heg063-MuiTabs-flexContainer': {
            display: "flex !important",
            gap: "15px !important"
        }
    },
    tableCell : {
        fontFamily : 'Poppins',
        fontSize : 12,
        color : '#204289',
        textAlign : 'center',
        textDecoration: 'none',
        marginLeft : 1,
        marginTop : 0.5
    },
    actionIcon : {
        color : '#204289',
        fontSize : 20,
    },
    deleteActionIcon: {
        color: '#CE0505',
        fontSize : 20
    },
    deleteTableCell: {
        fontFamily : 'Poppins',
        fontSize : 12,
        textAlign : 'center',
        textDecoration: 'none',
        marginLeft : 1,
        marginTop : 0.5,
        color: '#CE0505'
    },
    closeicon: {
        color: "#104076",
        cursor: "pointer"
    },
    buttonContainer: {
        marginRight: 6,
        marginBottom: 3,
        marginTop: 3
    },
}