import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { IRATableCellCustomization } from "../../ira-table/ira-table.interfaces";
import { IRATableCell } from "../../ira-table";
import IRATableCellView from "../ira-table-cell-view";

import { IRAButtonProps } from "../ira-button/ira-button.interfaces";
import Button from "../button";
import { theme } from "../../../theme/theme";
import MoreActions from "./more-actions";

/* eslint-disable-next-line */
export interface ActionCellProps
  // Partial<IRAMoreProps>,
  extends Partial<Omit<IRAButtonProps, "onClick">>,
    IRATableCellCustomization {
  button?: boolean;
  label?: React.ReactNode;
  data?: any;
  popover?: any;
  children?: React.ReactNode | React.ReactNodeArray;
  exportRef?: boolean;
  onClick: (
    data:
      | any
      | { data: any; id: string; event: React.MouseEvent<HTMLButtonElement> }
  ) => void;
  domId?: string;
  infoMessage?: string;
  actions?: any;
}

export const ActionCell = (props: ActionCellProps) => {
  const {
    actions,
    button,
    label,
    children,
    onClick,
    exportRef,
    data,
    infoMessage,
    style,
    className,
    ...buttonProps
  } = props;

  const referenceId = `action-button-${props.domId}`;
  return (
    <IRATableCell
      {...{ className, style }}
      {...(exportRef ? { domId: referenceId } : {})}
    >
      <>
        {children ? (
          children
        ) : button ? (
          <IRATableCellView
            line1={
              buttonProps?.disabled ? (
                infoMessage ? (
                  <Tooltip title={infoMessage} placement="top">
                    <Box sx={{ display: "flex", justifyContent: "center", cursor:'not-allowed', opacity:'0.5' }}>
                      <Button
                        {...(buttonProps as any)}
                        label={label}
                        sx={{
                          background: theme.palette.customColors.lightWhiteGrey,
                          color: "#313035",
                          padding: "5px 10px",
                          fontWeight: "600",
                          cursor:'not-allowed'
                        }}
                      />
                    </Box>
                  </Tooltip>
                ) : (
                  <span />
                )
              ) : (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    sx={{
                      background: theme.palette.customColors.lightWhiteGrey,
                      color: "#313035",
                      padding: "5px 10px",
                      fontWeight: "600",
                    }}
                    {...(buttonProps as any)}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                      exportRef
                        ? onClick({
                            data,
                            id: referenceId,
                            event,
                          } as any)
                        : onClick(data ?? {})
                    }
                    label={label ? label : null}
                  />
                </Box>
              )
            }
          />
        ) : (
          <>{actions && <MoreActions options={actions} onClick={onClick} />}</>
        )}
      </>
    </IRATableCell>
  );
};

export default ActionCell;
