import React, { useEffect } from "react";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import "./tabs.css";
import { theme } from "../../theme/theme";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tab-panel"
      hidden={value !== index}
      id={`simple-tab-panel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function SecondaryCustomTabs(props) {
  const {
    tabOptions,
    onTabChange,
    currentTab = 0,
    isIndexRequired = false,
  } = props;

  const [value, setValue] = React.useState(currentTab);

  useEffect(() => {
    setValue(currentTab);
  }, [currentTab]);

  const handleChange = (event, newIndex) => {
    if (isIndexRequired) {
      setValue(newIndex);
      onTabChange(newIndex);
    } else {
      const selectedTab = tabOptions[newIndex];
      setValue(selectedTab?.id);
      onTabChange(selectedTab?.id);
    }
  };

  return (
    <TabContext value={value}>
      <Box
        sx={
          {
            // borderBottom: 1,
            // borderColor: "divider",
          }
        }
      >
        <TabList
          onChange={handleChange}
          aria-label="lab API tabs example"
          sx={
            {
              // bgcolor: theme.palette.customColors.white,
              // boxShadow: theme.palette.customTabBoxShadow,
              // borderTopLeftRadius: "14px",
              // borderTopRightRadius: "14px",
            }
          }
        >
          {tabOptions?.map((item, index) => {
            return (
              <Tab
                key={item?.id}
                label={item?.name}
                sx={{
                  "&.Mui-selected": {
                    bgcolor: theme.palette.customColors.white,
                    // boxShadow: theme.palette.customTabBoxShadow,
                    fontFamily: theme.typography.bold,
                  },
                  "&.MuiTab-root": {
                    textTransform: "none",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    fontFamily: theme.typography.bold,
                  },
                }}
              />
            );
          })}
        </TabList>
      </Box>
      <Box
        sx={
          {
            // bgcolor: theme.palette.primary.lightGrey,
            // boxShadow: theme.palette.customTabBoxShadow.boxShadow,
          }
        }
      >
        {tabOptions?.map((option, index) => {
          return (
            <Box mt={3}>
              <TabPanel value={value} key={option?.id} index={index}>
                {option.component}
              </TabPanel>
            </Box>
          );
        })}
      </Box>
    </TabContext>
  );
}
