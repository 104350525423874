import React, { useState, useEffect } from "react";
import { Container, Typography, Paper, Box } from "@mui/material";
import "./dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import CustomDialog from "../../components/CustomDialog";
import BookAppointmentForm from "../Appointment/book-appointment-form";
import SelectClient from "../../components/SelectClient";
import CustomCard from "../../components/Card";
import { theme } from "../../theme/theme";
import { useAppContext } from "../../app/context/AppContext";
import RequestList from "../Requests/request-list";
import LeadList from "../SalesExecutive/lead-list";
import CustomSpinner from "../../components/Spinner";
import IRATypography from "../../components/ira-typography";
import Breadcrumb from "../../components/Breadcrumb";
import CustomButton from "../../components/Button";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const SalesExecutiveDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [isFlipped, setFlipped] = useState(false);
  const [isFlipped1, setFlipped1] = useState(false);
  const [isFlipped2, setFlipped2] = useState(false);
  const [isFlipped3, setFlipped3] = useState(false);
  const [isFlipped4, setFlipped4] = useState(false);
  const [isFlipped5, setFlipped5] = useState(false);
  const [openSelectClientDialog, setOpenSelectClientDialog] = useState<any>({
    open: false,
    data: {},
  });

  const { user } = useAppContext() as any;

  const [openBookAppointmentDialog, setOpenBookAppointmentDialog] =
    useState<any>({
      open: false,
      data: {},
    });

  const handleLinkClick = (type?: string) => {
    if (type === "bookAppointment") {
      setOpenBookAppointmentDialog({ open: true, data: { type } });
    } else {
      setOpenSelectClientDialog({ open: true, data: { type } });
    }
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const breadcrumbs = [
    <RouterLink to="/Dashboard" style={{ textDecoration: "none" }} key="1001">
      <IRATypography
        key="2"
        textVariant="h12WithSemiBold"
        sx={{ fontSize: 14, color: "#222222" }}
      >
        Home
      </IRATypography>
    </RouterLink>,
    <IRATypography
      key="3"
      textVariant="h12WithSemiBold"
      sx={{ fontSize: 14, color: "#222222" }}
    >
      Dashboard
    </IRATypography>,
  ];

  const renderCards = (role?: string) => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: "12px",
          }}
        >
          <Breadcrumb
            title={`Dashboard`}
            key="lead-list-breadcrumb"
            breadcrumbsArr={breadcrumbs}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          gap={2}
          mb={4}
        >
          {/* Card 1 */}
          <Paper
            elevation={3}
            sx={{
              p: 2,
              flexBasis: "27%",
              borderRadius: "15px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <Typography
              variant="h6"
              sx={{ color: "#000", fontWeight: "500", fontSize: "18px" }}
            >
              Clients Onboarded
            </Typography>
            {/* <Typography variant="h6" sx={{color:"#5C4EFF"}}>Graph</Typography> */}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h2"
                style={{ fontWeight: "700", fontSize: "42px" }}
              >
                150
              </Typography>
              <CustomSpinner color={"success"} size={60} value={65} />
            </Box>
          </Paper>
          {/* Card 2 */}
          <Paper
            elevation={3}
            sx={{
              p: 2,
              flexBasis: "27%",
              borderRadius: "15px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <Typography
              variant="h6"
              sx={{ color: "#000", fontWeight: "500", fontSize: "18px" }}
            >
              Clinics
            </Typography>
            {/* <Typography variant="h6" sx={{color:"#5C4EFF"}}>Graph</Typography> */}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h2"
                style={{ fontWeight: "700", fontSize: "42px" }}
              >
                150
              </Typography>
              <CustomSpinner color={"warning"} size={60} value={65} />
            </Box>
          </Paper>

          {/* Card 3 */}
          <Paper
            elevation={3}
            sx={{
              p: 2,
              flexBasis: "27%",
              borderRadius: "15px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <Typography
              variant="h6"
              sx={{ color: "#000", fontWeight: "500", fontSize: "18px" }}
            >
              Leads
            </Typography>
            {/* <Typography variant="h6" sx={{color:"#5C4EFF"}}>Graph</Typography> */}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h2"
                style={{ fontWeight: "700", fontSize: "42px" }}
              >
                150
              </Typography>
              <CustomSpinner color={"secondary"} size={60} value={65} />
            </Box>
          </Paper>

          <Box
            flexDirection={"column"}
            gap={"10px"}
            display={"flex"}
            flexBasis={"27%"}
          >
            {/* Card 5 */}
            <Paper
              elevation={3}
              sx={{
                p: 2,
                flexBasis: "20%",
                display: "flex",
                justifyContent: "space-between",
                borderRadius: "15px",
                alignItems: "center",
                gap: "10px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <CustomSpinner color={"error"} size={30} value={65} />
                <Typography
                  variant="h6"
                  sx={{ color: "#000", fontWeight: "500", fontSize: "18px" }}
                >
                  Pending
                </Typography>
              </Box>
              <Typography
                variant="h6"
                sx={{ color: "#000", fontWeight: "500", fontSize: "20px" }}
              >
                50
              </Typography>
            </Paper>

            {/* Card 6 */}
            <Paper
              elevation={3}
              sx={{
                p: 2,
                flexBasis: "20%",
                display: "flex",
                justifyContent: "space-between",
                borderRadius: "15px",
                alignItems: "center",
                gap: "10px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <CustomSpinner color={"primary"} size={30} value={65} />
                <Typography
                  variant="h6"
                  sx={{ color: "#000", fontWeight: "500", fontSize: "18px" }}
                >
                  Closed
                </Typography>
              </Box>
              <Typography
                variant="h6"
                sx={{ color: "#000", fontWeight: "500", fontSize: "20px" }}
              >
                200
              </Typography>
            </Paper>
          </Box>
        </Box>
        <div className="flexbox">
          <div className="w33p">
            {/* Clinic */}
            {role !== "local-admin" && (
              <Paper
                onMouseEnter={() => {
                  setFlipped(true);
                  setFlipped1(false);
                  setFlipped2(false);
                  setFlipped3(false);
                  setFlipped4(false);
                  setFlipped5(false);
                }}
                onMouseLeave={() => setFlipped(false)}
                className={`flip-card ${isFlipped ? "flipped" : ""}`}
                elevation={3}
                sx={{
                  p: 2,
                  flexBasis: "30%",
                  flexGrow: 1,
                  background:
                    "linear-gradient(rgba(227, 227, 255, 1), rgba(255, 229, 231, 1))",
                  borderRadius: "15px",
                  height: "170px",
                }}
              >
                <Box className="flip-card-inner">
                  <Box className="flip-card-front">
                    <Box
                      component="img"
                      width={"100%"}
                      src={require("../../app/assets/Dashboard/client_enrollment.png")}
                    ></Box>
                    <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                      Marketing
                    </Typography>
                  </Box>

                  {isFlipped ? (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      height="100%"
                      flexWrap="wrap"
                      className="flip-card-back"
                    >
                      <Box display="flex" flexDirection="column">
                        <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                          Marketing
                        </Typography>
                        <ul
                          className="pl-5"
                          style={{
                            listStyleType: "square",
                            marginTop: "2px",
                            color: "#5C4EFF",
                            fontSize: "14px",
                            lineHeight: "28px",
                          }}
                        >
                          <li>
                            <Link
                              to="/lead/list"
                              style={{ color: "#000", textDecoration: "none" }}
                            >
                              Leads
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/lead/list"
                              style={{ color: "#000", textDecoration: "none" }}
                            >
                              Sales
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/lead/list"
                              style={{ color: "#000", textDecoration: "none" }}
                            >
                              Client Details For Marketing
                            </Link>
                          </li>
                        </ul>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              </Paper>
            )}
          </div>
          <div className="w33p">
            {/* Client */}
            <Paper
              onMouseEnter={() => {
                setFlipped1(true);
                setFlipped(false);
                setFlipped2(false);
                setFlipped3(false);
                setFlipped4(false);
                setFlipped5(false);
              }}
              onMouseLeave={() => setFlipped1(false)}
              className={`flip-card ${isFlipped1 ? "flipped" : ""}`}
              elevation={3}
              sx={{
                p: 2,
                flexBasis: "30%",
                flexGrow: 1,
                background:
                  "linear-gradient(rgba(227, 227, 255, 1), rgba(255, 229, 231, 1))",
                borderRadius: "15px",
                height: "170px",
              }}
            >
              <Box className="flip-card-inner">
                <Box className="flip-card-front">
                  <Box
                    component="img"
                    width={"100%"}
                    src={require("../../app/assets/Dashboard/hospital_profile.png")}
                  ></Box>
                  <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                    Teams
                  </Typography>
                </Box>

                {isFlipped1 ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    height="100%"
                    flexWrap="wrap"
                    className="flip-card-back"
                  >
                    <Box display="flex" flexDirection="column">
                      <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                        Teams
                      </Typography>
                      <ul
                        className="pl-5"
                        style={{
                          listStyleType: "square",
                          marginTop: "2px",
                          color: "#5C4EFF",
                          fontSize: "14px",
                          lineHeight: "28px",
                        }}
                      >
                        <li>
                          <Link
                            to="/lead/list"
                            style={{ color: "#000", textDecoration: "none" }}
                          >
                            Teams List
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/lead/list"
                            style={{ color: "#000", textDecoration: "none" }}
                          >
                            By Sales
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/lead/list"
                            style={{ color: "#000", textDecoration: "none" }}
                          >
                            By Leads
                          </Link>
                        </li>
                      </ul>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Paper>
          </div>
          <div className="w33p">
            {/* Plans */}
            {role !== "local-admin" && (
              <Paper
                onMouseEnter={() => {
                  setFlipped2(true);
                  setFlipped1(false);
                  setFlipped(false);
                  setFlipped3(false);
                  setFlipped4(false);
                  setFlipped5(false);
                }}
                onMouseLeave={() => setFlipped2(false)}
                className={`flip-card ${isFlipped2 ? "flipped" : ""}`}
                elevation={3}
                sx={{
                  p: 2,
                  flexBasis: "30%",
                  flexGrow: 1,
                  background:
                    "linear-gradient(rgba(152, 209, 255, 1), rgba(237, 255, 245, 1))",
                  borderRadius: "15px",
                  height: "170px",
                }}
              >
                <Box className="flip-card-inner">
                  <Box className="flip-card-front">
                    <Box
                      component="img"
                      width={"100%"}
                      src={require("../../app/assets/Dashboard/dentassure_plan.png")}
                    ></Box>
                    <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                      Clients
                    </Typography>
                  </Box>

                  {isFlipped2 ? (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      height="100%"
                      flexWrap="wrap"
                      className="flip-card-back"
                    >
                      <Box display="flex" flexDirection="column">
                        <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                          Clients
                        </Typography>
                        <ul
                          className="pl-5"
                          style={{
                            listStyleType: "square",
                            marginTop: "2px",
                            color: "#5C4EFF",
                            fontSize: "14px",
                            lineHeight: "28px",
                          }}
                        >
                          <li>
                            <Link
                              to="/lead/list"
                              style={{ color: "#000", textDecoration: "none" }}
                            >
                              Standard Plan
                            </Link>
                          </li>
                          {/* <li>
                            <Link
                              to="/lead/list"
                              style={{ color: "#000", textDecoration: "none" }}
                            >
                              Customized Plan
                            </Link>
                          </li> */}
                          <li>
                            <Link
                              to="/lead/list"
                              style={{ color: "#000", textDecoration: "none" }}
                            >
                              Discount Plan
                            </Link>
                          </li>
                        </ul>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              </Paper>
            )}
          </div>
          <div className="w33p">
            {/* Appointments */}
            {role !== "local-admin" && (
              <Paper
                onMouseEnter={() => {
                  setFlipped3(true);
                  setFlipped1(false);
                  setFlipped(false);
                  setFlipped2(false);
                  setFlipped4(false);
                  setFlipped5(false);
                }}
                onMouseLeave={() => setFlipped3(false)}
                className={`flip-card ${isFlipped3 ? "flipped" : ""}`}
                elevation={3}
                sx={{
                  p: 2,
                  flexBasis: "30%",
                  flexGrow: 1,
                  background:
                    "linear-gradient(rgba(254, 235, 246, 1), rgba(229, 165, 246, 1))",
                  borderRadius: "15px",
                  height: "170px",
                }}
              >
                <Box className="flip-card-inner">
                  <Box className="flip-card-front">
                    <Box
                      component="img"
                      width={"100%"}
                      src={require("../../app/assets/Dashboard/book_appointment.png")}
                    ></Box>
                    <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                      Products
                    </Typography>
                  </Box>

                  {isFlipped3 ? (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      height="100%" // Ensure the Box takes full height of the parent Paper
                      flexWrap="wrap" // Allow flex items to wrap to the next row
                      className="flip-card-back"
                    >
                      <Box display="flex" flexDirection="column">
                        <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                          Products
                        </Typography>
                        <ul
                          className="pl-5"
                          style={{
                            listStyleType: "square",
                            marginTop: "2px",
                            color: "#5C4EFF",
                            fontSize: "14px",
                            lineHeight: "28px",
                          }}
                        >
                          <li>
                            <Link
                              to="/lead/list"
                              style={{ color: "#000", textDecoration: "none" }}
                            >
                              Products
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/lead/list"
                              style={{ color: "#000", textDecoration: "none" }}
                            >
                              Add Product
                            </Link>
                          </li>
                        </ul>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              </Paper>
            )}
          </div>
          <div className="w33p">
            {/* Cases */}
            {role !== "local-admin" && (
              <Paper
                onMouseEnter={() => {
                  setFlipped4(true);
                  setFlipped1(false);
                  setFlipped(false);
                  setFlipped2(false);
                  setFlipped3(false);
                  setFlipped5(false);
                }}
                onMouseLeave={() => setFlipped4(false)}
                className={`flip-card ${isFlipped4 ? "flipped" : ""}`}
                elevation={3}
                sx={{
                  p: 2,
                  flexBasis: "30%",
                  flexGrow: 1,
                  background:
                    "linear-gradient(rgba(228, 207, 245, 1), rgba(132, 197, 247, 1))",
                  borderRadius: "15px",
                  height: "170px",
                }}
              >
                <Box className="flip-card-inner">
                  <Box className="flip-card-front">
                    <Box
                      component="img"
                      width={"100%"}
                      src={require("../../app/assets/Dashboard/cases_claims.png")}
                    ></Box>
                    <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                      Queries
                    </Typography>
                  </Box>

                  {isFlipped4 ? (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      height="100%"
                      flexWrap="wrap"
                      className="flip-card-back"
                    >
                      <Box display="flex" flexDirection="column">
                        <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                          Queries
                        </Typography>
                        <ul
                          className="pl-5"
                          style={{
                            listStyleType: "square",
                            marginTop: "2px",
                            color: "#5C4EFF",
                            fontSize: "14px",
                            lineHeight: "28px",
                          }}
                        >
                          <li>
                            <Link
                              to="/lead/list"
                              style={{ color: "#000", textDecoration: "none" }}
                            >
                              Queries List
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/lead/list"
                              style={{ color: "#000", textDecoration: "none" }}
                            >
                              Add Case
                            </Link>
                          </li>
                        </ul>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              </Paper>
            )}
          </div>
          <div className="w33p">
            {/* Reports */}
            <Paper
              onMouseEnter={() => {
                setFlipped5(true);
                setFlipped1(false);
                setFlipped(false);
                setFlipped2(false);
                setFlipped3(false);
                setFlipped4(false);
              }}
              onMouseLeave={() => setFlipped5(false)}
              className={`flip-card ${isFlipped5 ? "flipped" : ""}`}
              elevation={3}
              sx={{
                p: 2,
                flexBasis: "30%",
                flexGrow: 1,
                background:
                  "linear-gradient(rgba(245, 163, 174, 1), rgba(205, 225, 249, 1))",
                borderRadius: "15px",
                height: "170px",
              }}
            >
              <Box className="flip-card-inner">
                <Box className="flip-card-front">
                  <Box
                    component="img"
                    width={"100%"}
                    src={require("../../app/assets/Dashboard/reports.png")}
                  ></Box>
                  <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                    Tickets
                  </Typography>
                </Box>

                {isFlipped5 ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    height="100%"
                    flexWrap="wrap"
                    className="flip-card-back"
                  >
                    <Box display="flex" flexDirection="column">
                      <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                        Tickets
                      </Typography>
                      <ul
                        className="pl-5"
                        style={{
                          listStyleType: "square",
                          marginTop: "2px",
                          color: "#5C4EFF",
                          fontSize: "14px",
                          lineHeight: "28px",
                        }}
                      >
                        <li>
                          <Link
                            to="/lead/list"
                            style={{ color: "#000", textDecoration: "none" }}
                          >
                            My Tickets
                          </Link>
                        </li>
                      </ul>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Paper>
            {openSelectClientDialog.open && (
              // <CustomAutocomplete></CustomAutocomplete>
              <CustomDialog
                width={"600px"}
                open={openSelectClientDialog.open}
                handleClose={() =>
                  setOpenSelectClientDialog({ open: false, data: {} })
                }
                title={"Select Client"}
                maxWidth={"lg"}
              >
                <>
                  <Box>
                    <SelectClient
                      onClose={() =>
                        setOpenSelectClientDialog({ open: false, data: {} })
                      }
                      openSelectClientDialog={openSelectClientDialog}
                      fromCommomnDashboard={true}
                    />
                  </Box>
                </>
              </CustomDialog>
            )}

            {openBookAppointmentDialog.open && (
              <CustomDialog
                width={"650px"}
                open={openBookAppointmentDialog.open}
                handleClose={() =>
                  setOpenBookAppointmentDialog({ open: false, data: {} })
                }
                title={"Book Appointment"}
                maxWidth={"lg"}
              >
                <Box>
                  <BookAppointmentForm
                    onClose={() =>
                      setOpenBookAppointmentDialog({ open: false, data: {} })
                    }
                    navigateTo={() => {
                      navigate(`/cases/client/${"121213"}/add`);
                    }}
                  />
                </Box>
              </CustomDialog>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Container maxWidth="lg" sx={{ marginTop: 2 }}>
        <>
          {renderCards()}
          <Box mt={4}>
            <CustomCard>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                p={"16px 16px 0px 16px"}
                textAlign={"center"}
              >
                <Box style={{ display: "flex" }}>
                  <Typography
                    fontSize={"14px"}
                    sx={{ color: theme.palette.customColors.grey }}
                  >
                    Recent Leads
                  </Typography>
                  <Typography
                    ml={1.5}
                    sx={{
                      background: "rgba(104, 212, 180, 1)",
                      padding: "3px 10px",
                      borderRadius: "7px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    02
                  </Typography>
                </Box>
                <Box>
                  <Link
                    to="/lead/list"
                    style={{
                      marginBottom: 2,
                      textDecoration: "none",
                      color: theme.palette.customColors.main,
                      fontSize: "14px",
                    }}
                  >
                    View All
                  </Link>
                </Box>
              </Box>
              <Box p={2}>
                <LeadList hideBreadScrum hideSearch />
              </Box>
            </CustomCard>
          </Box>
        </>
      </Container>
    </>
  );
};

export default SalesExecutiveDashboard;
