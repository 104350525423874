import React, { useEffect, useState } from "react";
import { Box, Checkbox, Grid, Typography } from "@mui/material";
import CustomButton from "../../../components/Button";
import { apiRequest } from "../../../app/services/requests";
import { useNavigate } from "react-router-dom";

const AssignServiceForm = (props: any) => {
  const { onClose, planId, category } = props || {};

  const navigate = useNavigate();

  const [serviceList, setServiceList] = useState<any>({
    results: [],
    totalCount: 0,
  });

  useEffect(() => {
    if (planId) {
      getPreventiveServicesByPlan(planId);
    }
  }, [planId]);

  const getPreventiveServicesByPlan = (planId: any) => {
    apiRequest({
      url: `treatment/treatments-by-master-plan?category=${category}&masterPlanId=${planId}&limit=100&offset=0`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          // Map through the results and add both isSelected and original_enableFree
          const updatedResult = res.results.map((treatment: any) => ({
            ...treatment,
            isSelected: treatment.master_plan_treatment_id ? true : false,
            original_enableFree: treatment.enableFree, // Store original value
          }));

          // Update serviceList with the modified results
          setServiceList({
            ...res,
            results: updatedResult,
          });
        }
      })
      .catch((err) => {
        // Handle error
      });
  };

  const handleCheckedConsentForm = (
    event: any,
    index: number,
    type?: string
  ) => {
    const { checked } = event.target;
    setServiceList((prevForms: any) => {
      const clonedForms = { ...prevForms };
      if (type === "service") {
        clonedForms.results[index].isSelected = checked;
      }
      if (type === "free") {
        clonedForms.results[index].enableFree = checked;
      }
      return clonedForms; // Update this to return clonedForms instead of prevForms
    });
  };

  const handleSubmit = () => {
    const payload = {
      category,
      newTreatments: [],
      updateTreatments: [],
      removeTreatments: [],
    } as any;

    serviceList.results.forEach((treatment: any) => {
      const originalEnableFree = treatment.original_enableFree; // Assuming original state is stored
      const originalIsSelected = treatment.master_plan_treatment_id
        ? true
        : false; // Based on master_plan_treatment_id

      if (treatment.isSelected) {
        if (!treatment.master_plan_treatment_id) {
          // New treatments: no master_plan_treatment_id
          payload.newTreatments.push({
            treatmentId: treatment.id,
            enableFree: treatment.enableFree || false,
          });
        } else if (
          treatment.enableFree !== originalEnableFree ||
          treatment.isSelected !== originalIsSelected
        ) {
          // Update treatments if either enableFree or isSelected has changed
          payload.updateTreatments.push({
            treatmentId: treatment.master_plan_treatment_id,
            enableFree: treatment.enableFree || false,
          });
        }
      } else if (
        treatment.master_plan_treatment_id &&
        !treatment.isSelected &&
        !treatment.enableFree
      ) {
        // Remove treatments if both isSelected and enableFree are false
        payload.removeTreatments.push(treatment.master_plan_treatment_id);
      }
    });

    // send the payload to the API
    apiRequest({
      url: `master-plan/${planId}/assign-treatment`,
      data: payload,
      method: "POST",
    })
      .then((res) => {
        alert("Services have been assigned or updated successfully!");
        onClose(false);
      })
      .catch((err) => {
        // if (err?.statusCode === 403 || err?.statusCode === 401) {
        //   if (navigate) {
        //     navigate("/login"); // Navigate to "/login" route
        //   }
        // }
      });
  };

  return (
    <>
      <Box mt={"10px"} p={3}>
        <Grid container spacing={2}>
          {Array.isArray(serviceList?.results) &&
            serviceList.results.map((service: any, index: number) => (
              <Grid item xs={6} key={index}>
                <Grid container spacing={2}>
                  <Grid item xs={8} key={index}>
                    <Box
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      gap={"20px"}
                    >
                      <Box>
                        <Box
                          display={"flex"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                        >
                          <Checkbox
                            size="small"
                            checked={service.isSelected}
                            onChange={(event) =>
                              handleCheckedConsentForm(event, index, "service")
                            }
                          />
                          <Box>
                            <Typography sx={{ fontSize: "14px" }}>
                              <Typography sx={{ fontSize: "14px" }}>
                                {`${service?.code}-${service.name}`}
                              </Typography>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    {service.isSelected && (
                      <Box>
                        <Box
                          display={"flex"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                        >
                          <Checkbox
                            size="small"
                            checked={service.enableFree}
                            onChange={(event) =>
                              handleCheckedConsentForm(event, index, "free")
                            }
                          />
                          <Box>
                            <Typography sx={{ fontSize: "14px" }}>
                              {"free"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>

        {/* Additional Sections can be added as needed */}
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <CustomButton
            border="1px solid #000000"
            color="#000000"
            title="Cancel"
            bgColor="#ffffff"
            onClick={() => onClose && onClose(true)}
            borderRadius="5px"
          />
          <Box marginLeft={1}>
            {/* Add a margin to create space between buttons */}
            <CustomButton
              title={"Submit"}
              type="button"
              borderRadius="5px"
              onClick={handleSubmit}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AssignServiceForm;
