import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
// import { downloadFileFromS3 } from "./uploadToS3";

export const getColoredChairsList = (chairs) => {
  let items = [];
  chairs.forEach((chair) => {
    let chairId = chair.id;
    let chairName = chair.chairName;
    let chairColor = chair.color;
    items.push({
      ...getColorAppliedChair(chairId, chairName, chairColor),
      ChairDoctors: chair.ChairDoctors || [],
      assistant: chair.assistant || {},
      maxNoOfBookings: chair.maxNoOfBookings || 0,
      chairType: chair.chairType,
    });
  });
  return items;
};

export const getColorAppliedChair = (chairId, chairName, chairColor) => {
  let circleElement = React.createElement(
    "div",
    {
      style: {
        backgroundColor: chairColor,
        width: "10px",
        height: "10px",
        borderRadius: "50%",
      },
    },
    ""
  );

  let chairElement = React.createElement(
    "p",
    {
      style: {
        color: chairColor,
        margin: "0px",
        fontFamily: "Poppins",
        fontWeight: "500",
        fontSize: "11px",
      },
    },
    chairName
  );

  let chairElementAndChairStatusElement = React.createElement(
    "div",
    { style: { display: "flex", alignItems: "center", padding: "0px" } },
    chairElement
  );

  let htmlElement = React.createElement(
    "div",
    {
      style: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
        padding: "0px",
      },
    },
    circleElement,
    chairElementAndChairStatusElement
  );

  return {
    id: chairId,
    name: htmlElement,
    chairColorName: chairName,
    chairName,
  };
};

export const ProfilePic = (props) => {
  const {
    profilePhoto,
    styles = {},
    isProfilePic = true,
    onClick = () => {},
  } = props;

  // const [photo, setPhoto] = useState("");
  // useEffect(() => {
  //   if (profilePhoto) {
  //     downloadFileFromS3(profilePhoto)
  //       .then((res:any) => {
  //         const { downloadURL = "" } = res;
  //         setPhoto(downloadURL);
  //       })
  //       .catch(() => setPhoto(""));
  //   }
  // }, [profilePhoto]);

  return (
    <></>
    // <Box
    //   component={"img"}
    //   alt="photo"
    //   src={
    //     photo ? photo : require('../../app/assets/Hospital/rect1.png')
    //     // ||
    //     // (isProfilePic
    //     //   ? require("../assets/profile.png")
    //     //   : require("../assets/dummy-thumbnail.jpg"))
    //   }
    //   sx={styles}
    //   onClick={onClick}
    // ></Box>
  );
};
