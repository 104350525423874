import React from "react";
import DoctorCareerDetailsStep2 from "../../Doctor/step2";
import { Box, Typography } from "@mui/material";

const AdditionalInfo = (props: any) => {
  return (
    <>
      <Box p={2} lineHeight={5}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography>Year of graduation</Typography>
          <Typography>Year of postgraduation</Typography>
          <Typography>Work Experiance</Typography>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography>
            <b>2002</b>
          </Typography>
          <Typography>
            <b>2008</b>
          </Typography>
          <Typography>
            <b>15 Years</b>
          </Typography>
        </Box>
        <hr></hr>
        <Box>
          <Typography>Organisation Membership:</Typography>
          <Typography>
            <b>Yes</b>
          </Typography>
        </Box>
        <hr></hr>
        <Box>
          <Typography>Extra training/courses</Typography>
        </Box>
        <Box display={"flex"} gap={2}>
          <Typography>
            <b>Training1,</b>
          </Typography>
          <Typography>
            <b>Training2,</b>
          </Typography>
          <Typography>
            <b>Training3,</b>
          </Typography>
          <Typography>
            <b>Training4,</b>
          </Typography>
          <Typography>
            <b>Training5</b>
          </Typography>
        </Box>
        <hr></hr>
        <Box>
          <Typography>Extra training/courses</Typography>
        </Box>
        <Box display={"flex"} gap={2}>
          <Typography>
            <b>Training1,</b>
          </Typography>
          <Typography>
            <b>Training2,</b>
          </Typography>
          <Typography>
            <b>Training3,</b>
          </Typography>
          <Typography>
            <b>Training4,</b>
          </Typography>
          <Typography>
            <b>Training5</b>
          </Typography>
        </Box>
        <hr></hr>
        <Box>
          <Typography>Rewards/Recognitions</Typography>
          <Typography>
            <b>Best doctor of the year 2008, Happy smile award 2006</b>
          </Typography>
        </Box>
        <hr></hr>
      </Box>
    </>
  );
};

export default AdditionalInfo;
