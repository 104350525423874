import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
  Box,
  Container,
  Grid,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, useNavigate } from "react-router-dom";
import { useSnackbar } from "../../../app/context/useSnackbar";
import { useAppContext } from "../../../app/context/AppContext";
import CustomInput from "../../../components/Input";
import CustomButton from "../../../components/Button";
import {
  COUNTRY_LIST,
  MARTIAL_STATUS1,
  PHONE_REG_EXP,
  GENDER_DATA,
  USER_ROLES,
} from "../../../app/utilities/constants";

import StatesAndCities from "../../../app/utilities/StatesAndCities.json";
import CustomDatePicker from "../../../components/DatePicker";

import CustomAutocomplete from "../../../components/Autocomplete";
import CustomCard from "../../../components/Card";
import { apiRequest } from "../../../app/services/requests";
import CustomCheckbox from "../../../components/CustomCheckBox";
import FileUploader from "../../../components/FileUploader/file-upload";
import CustomSelect from "../../../components/Select/CustomSelect";
import CustomSelectPicker from "../../../components/SelectPicker";
import { getOnlyDate } from "../../../app/utilities/utils";

const AddEditUser = (props: any) => {
  const navigate = useNavigate();

  const { handleLogoutSuccess, user } = useAppContext() as any;
  const { openSnackbar } = useSnackbar() as any;

  const [cities, setCities] = useState<any>([]);
  const [statesAndCities] = useState<any>(StatesAndCities);

  const [uploadAadharCard, setUploadAadharCard] = useState<any>("");
  const [uploadPanCard, setUploadPanCard] = useState<any>("");
  const [uploadEducationCertificates, setUploadEducationCertificates] =
    useState<any>({
      educationCertificatesResponseFiles: [],
    });
  const [workExperienceCertificates, setWorkExperienceCertificates] =
    useState<any>({
      workExperienceCertificatesResponseFiles: [],
    });
  const [achievementCertificates, setAchievementCertificates] = useState<any>({
    achievementCertificatesResponseFiles: [],
  });
  const [additionalCertificates, setAdditionalCertificates] = useState<any>({
    additionalCertificatesResponseFiles: [],
  });

  const [awardsAndRewardsDetails, setAwardsAndRewardsDetails] = useState([
    { awardsAndRewards: "" },
  ]);

  const [trainingsAndCoursesDetails, setTrainingsAndCoursesDetails] = useState([
    { trainingsAndCourses: "" },
  ]);

  const [bdsMdsDocs, setBdsMdsDocs] = useState<any>({
    bdsFile: "",
    mdsFile: "",
  });

  const userDefaultValues = () => {
    return {
      // zipCode: "232323",
      // city: "Anantapur",
      // state: "Andhra Pradesh",
      // country: "India",
      // addressLine2: "q",
      // addressLine1: "q",
      // pan: "",
      // aadhar: "",
      // email: "mnarendrasoft20@gmail.com",
      // maritalStatus: "married",
      // dateOfBirth: new Date(),
      // mobileNumber: "9603263099",
      // phonecode: "+91",
      // gender: "male",
      // lastName: "mnb",
      // firstName: "narendra",
      // appointment: "No : I9089A519GH818",
      // alternateMobileNumber: "",
      // dentalCouncilNumber: "121212",
      // graduationYear: "2023",
      // graduationInstitute: "St.mary's Institute",
      // postGraduationYear: "1998",
      // postGraduationInstitute: "St.mary's Institute",
      // experienceInYears: "12",
      // awardsAndRewardsDetails: [{ awardsAndRewards: "" }],
      // trainingsAndCoursesDetails: [{ trainingsAndCourses: "" }],
    } as any;
  };

  const schema = object({
    firstName: string().required("First name is required"),
    lastName: string().required("Last name is required"),
    gender: string().ensure().required("Gender is required"),
    mobileNumber: string()
      .required("Mobile number is required")
      .matches(PHONE_REG_EXP, "Phone number is not valid"),
    dateOfBirth: string().ensure().required("Date of birth is required"),
    maritalStatus: string().required("Email is required"),
    email: string()
      .required("Email is required")
      .nullable()
      .email("Email is not valid"),
    addressLine1: string().required("Address Line 1 is required"),
    addressLine2: string().required("Address Line 2 is required"),
    country: string().required("Country is required"),
    state: string().required("State is required"),
    city: string().required("City is required"),
    zipCode: string()
      .required("Zip Code is required")
      .max(6, "Zip Code should be of 6 characters"),
  });

  const { control, handleSubmit, getValues, setValue, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: userDefaultValues(),
  });
  const watchUserType = watch("userType");
  const { remove: deleteAwardsAndRewards } = useFieldArray({
    control,
    name: "awardsAndRewardsDetails",
  });

  const { remove: deleteTrainingsAndCourses } = useFieldArray({
    control,
    name: "trainingsAndCoursesDetails",
  });

  const addAwardsAndRewards = () => {
    setAwardsAndRewardsDetails([
      ...awardsAndRewardsDetails,
      { awardsAndRewards: "" },
    ]);
  };
  const addTrainingsAndCourses = () => {
    setTrainingsAndCoursesDetails([
      ...trainingsAndCoursesDetails,
      { trainingsAndCourses: "" },
    ]);
  };

  const removeAwardsAndRewards = (index: number) => {
    const modifiedAwardsAndRewards = [...awardsAndRewardsDetails];
    modifiedAwardsAndRewards.splice(index, 1);
    setAwardsAndRewardsDetails(modifiedAwardsAndRewards);
    deleteAwardsAndRewards(index);
  };

  const removeTrainingsAndCourses = (index: number) => {
    const trainingsAndCourses = [...trainingsAndCoursesDetails];
    trainingsAndCourses.splice(index, 1);
    setTrainingsAndCoursesDetails(trainingsAndCourses);
    deleteTrainingsAndCourses(index);
  };

  const deleteEducationCertificates = (index: number) => {
    const updatedCertificates = { ...uploadEducationCertificates };
    const updatedFiles = [
      ...(updatedCertificates.educationCertificatesResponseFiles || []),
    ];
    updatedFiles.splice(index, 1);
    updatedCertificates.educationCertificatesResponseFiles = updatedFiles;
    setUploadEducationCertificates(updatedCertificates);
  };

  const deleteWorkExperienceCertificates = (index: any) => {
    const updatedCertificates = {
      ...workExperienceCertificates,
      coverImagesResponseFiles: [
        ...workExperienceCertificates?.workExperienceCertificatesResponseFiles,
      ],
    };
    workExperienceCertificates?.workExperienceCertificatesResponseFiles.splice(
      index,
      1
    );
    setWorkExperienceCertificates(updatedCertificates);
  };
  const deleteAchievementCertificates = (index: any) => {
    const updatedCertificates = {
      ...achievementCertificates,
      coverImagesResponseFiles: [
        ...achievementCertificates?.achievementCertificatesResponseFiles,
      ],
    };
    achievementCertificates?.achievementCertificatesResponseFiles.splice(
      index,
      1
    );
    setWorkExperienceCertificates(updatedCertificates);
  };
  const deleteAdditionalCertificates = (index: any) => {
    const updatedCertificates = {
      ...additionalCertificates,
      coverImagesResponseFiles: [
        ...additionalCertificates?.additionalCertificatesResponseFiles,
      ],
    };
    additionalCertificates?.additionalCertificatesResponseFiles.splice(
      index,
      1
    );
    setAdditionalCertificates(updatedCertificates);
  };

  const handleUpload = (data: any, uploadType: string) => {
    setBdsMdsDocs((prev: any) => ({ ...prev, [uploadType]: data }));
  };

  const onSubmit = (formData: any) => {
    const modifiedData = {
      userType: formData?.userType,
      panDocumentUrl: uploadPanCard?.url,
      adhaarDocumentUrl: uploadAadharCard?.url,
      email: formData?.email,
      maritalStatus: formData?.maritalStatus,
      dateOfBirth: getOnlyDate(formData?.dateOfBirth),
      mobileNumber: formData?.mobileNumber,
      gender: formData?.gender,
      lastName: formData?.lastName,
      firstName: formData?.firstName,
      alternateMobileNumber: formData?.alternateMobileNumber,
      dentalCouncilNumber: formData?.dentalCouncilNumber,
      graduationYear: formData?.graduationYear,
      graduationInstitute: formData?.graduationInstitute,
      postGraduationYear: formData?.postGraduationYear,
      postGraduationInstitute: formData?.postGraduationInstitute,
      experienceInYears: formData?.experienceInYears,
      awardsAndRewards: formData?.awardsAndRewardsDetails?.map(
        (item: any) => item?.awardsAndRewards
      ),
      trainingsAndCourses: formData?.trainingsAndCoursesDetails?.map(
        (item: any) => item?.trainingsAndCourses
      ),
      educationCertificates:
        uploadEducationCertificates?.educationCertificatesResponseFiles?.map(
          (item: any) => item?.url
        ),
      workExperienceCertificates:
        workExperienceCertificates?.workExperienceCertificatesResponseFiles?.map(
          (item: any) => item?.url
        ),
      achievementCertificates:
        achievementCertificates?.achievementCertificatesResponseFiles?.map(
          (item: any) => item?.url
        ),

      additionalCertificates:
        additionalCertificates?.additionalCertificatesResponseFiles?.map(
          (item: any) => item?.url
        ),

      address: {
        addressLine1: formData?.addressLine1,
        addressLine2: formData?.addressLine2,
        state: formData?.state,
        city: formData?.city,
        country: formData?.country,
        zipCode: formData?.zipCode,
      },
      createdById: user?.data?.id,
      otherDocuments: [""],
      bdsFile: bdsMdsDocs?.bdsFile?.url,
      mdsFile: bdsMdsDocs?.mdsFile?.url,
    };

    apiRequest({
      url: `user/create`,
      data: modifiedData,
      method: "POST",
    })
      .then((res) => {
        if (res) {
          navigate("/user/list");
          openSnackbar("User Created Successfully", "success");
        }
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const cancelHandle = () => {
    navigate("/dashboard");
  };
  return (
    <>
      <Container maxWidth="lg" sx={{ marginTop: 2 }}>
        {" "}
        <Box>
          <Typography
            fontSize={"20px"}
            gutterBottom
            style={{ marginBottom: "0em" }}
          >
            {"User Enrollment"}
          </Typography>
          <Typography fontSize={"12px"} style={{ marginBottom: "0.7em" }}>
            {"Home > User Enrollment"}
          </Typography>
        </Box>
        <Box mt={2}>
          <Box
            style={{
              backgroundImage: `url(${require("../../../app/assets/AddNewClient/back.png")})`,
              height: "13vh",
              backgroundRepeat: "round",
              marginBottom: "2vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              style={{ marginLeft: "27vw", height: "70px" }}
              component="img"
              src={require("../../../app/assets/logo.png")}
            ></Box>
            <Box style={{ marginLeft: "10px", width: "52%" }}>
              <Typography
                style={{ color: "rgba(33, 159, 255, 1)", fontSize: "20px" }}
              >
                {"Dentassure User Enrollment Form"}
              </Typography>
              <Typography variant="body2" style={{ width: "60%" }}>
                {
                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                }
              </Typography>
            </Box>
            <CustomButton
              title={"Help"}
              marginRight={"5px"}
              bgColor={"rgba(92, 78, 255, 1)"}
              height={"25px"}
            />
          </Box>

          <CustomCard style={{ borderRadius: "10px" }}>
            <Box px={4} py={4}>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* Client Details Section */}

                <Typography variant="body1">Users Details</Typography>

                <Grid item xs={4} md={4} mt={2}>
                  <Controller
                    name="userType"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <CustomSelectPicker
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : ""}
                        label={"Select User Role"}
                        size={"small"}
                        fullWidth={true}
                        width={"100%"}
                        selectData={USER_ROLES}
                        noneRequired={false}
                      />
                    )}
                  />
                </Grid>

                <Grid container spacing={3} mt={1}>
                  {/* Add client details fields here */}
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          label={"Enter First Name"}
                          InputProps={""}
                          size={"small"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          label={"Enter Last Name"}
                          InputProps={""}
                          size={"small"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name={"dateOfBirth"}
                      rules={{ required: true }}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomDatePicker
                          {...field}
                          label={"Date Of Birth"}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          maxDate={new Date()}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid container item spacing={3}>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    mt={6}
                    style={{ display: "flex", paddingTop: "10px" }}
                  >
                    <Typography
                      variant="regular"
                      style={{ paddingTop: "8px", marginRight: "5px" }}
                    >
                      Sex :
                    </Typography>
                    <Controller
                      name={"gender"}
                      rules={{ required: true }}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl component="fieldset">
                          <RadioGroup row {...field}>
                            {GENDER_DATA.map((option: any, idx: any) => (
                              <FormControlLabel
                                key={idx}
                                value={option.id}
                                control={<Radio />}
                                label={option.name}
                              />
                            ))}
                          </RadioGroup>
                          {error && (
                            <FormHelperText
                              sx={{ color: "#d32f2f !important" }}
                            >
                              {error.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    mt={6}
                    style={{ display: "flex", paddingTop: "10px" }}
                  >
                    <Typography
                      variant="regular"
                      style={{ paddingTop: "8px", marginRight: "5px" }}
                    >
                      Martial Status :
                    </Typography>
                    <Controller
                      name={"maritalStatus"}
                      rules={{ required: true }}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl component="fieldset">
                          <RadioGroup row {...field}>
                            {MARTIAL_STATUS1.map((option: any, idx: any) => (
                              <FormControlLabel
                                key={idx}
                                value={option.id}
                                control={<Radio />}
                                label={option.name}
                              />
                            ))}
                          </RadioGroup>
                          {error && (
                            <FormHelperText
                              sx={{ color: "#d32f2f !important" }}
                            >
                              {error.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>

                {/* Communication Details Section */}
                <Box mt={3}>
                  <Typography variant="body1">Communication Details</Typography>
                  {/* Add communication details fields here */}
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4} mt={4}>
                      <Box>
                        <Box style={{ display: "flex" }}>
                          <Controller
                            name="phonecode"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                // label={"Primary Mobile No"}
                                style={{ width: "47px", marginRight: "10px" }}
                                type="text"
                                {...field}
                                error={!!error}
                                helperText={error ? error.message : ""}
                              />
                            )}
                          />
                          <Controller
                            name="mobileNumber"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                label={"Primary Mobile No"}
                                type="text"
                                {...field}
                                error={!!error}
                                helperText={error ? error.message : ""}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} mt={4}>
                      <Box>
                        <Box style={{ display: "flex" }}>
                          <Controller
                            name="phonecode"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                // label={"Primary Mobile No"}
                                style={{ width: "47px", marginRight: "10px" }}
                                type="text"
                                {...field}
                                error={!!error}
                                helperText={error ? error.message : ""}
                              />
                            )}
                          />
                          <Controller
                            name="alternateMobileNumber"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                label={"Secondary Mobile No"}
                                type="text"
                                {...field}
                                error={!!error}
                                helperText={error ? error.message : ""}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} mt={4}>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            label={"Email"}
                            size={"small"}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>

                {/* Personal Identity Section */}
                <Box mt={4}>
                  <Typography variant="body1">Personal Identity</Typography>
                  {/* Add personal identity fields here */}
                  <Grid container spacing={3} mt={1}>
                    <Grid item xs={12} md={4}>
                      <FileUploader
                        key={"aadharCard"}
                        uploadAadharCard={uploadAadharCard}
                        setUploadAadharCard={setUploadAadharCard}
                        accept={"image/png,image/jpeg,image/jpg"}
                        displayType={"aadharCard"}
                        uploadType={"aadharCard"}
                        uploadText={
                          uploadAadharCard?.originalname
                            ? uploadAadharCard?.originalname
                            : "Upload  Aadhar"
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FileUploader
                        key={"panCard"}
                        uploadPanCard={uploadPanCard}
                        setUploadPanCard={setUploadPanCard}
                        accept={"image/png,image/jpeg,image/jpg"}
                        displayType={"panCard"}
                        uploadType={"panCard"}
                        uploadText={
                          uploadPanCard?.originalname
                            ? uploadPanCard?.originalname
                            : "Upload PanCard "
                        }
                      />
                    </Grid>
                    {watchUserType === "daDoctor" && (
                      <>
                        <Grid item xs={12} md={4}>
                          <FileUploader
                            key={"bdsFile"}
                            uploadPanCard={uploadPanCard}
                            handleUpload={(data: any) =>
                              handleUpload(data, "bdsFile")
                            }
                            accept={"image/png,image/jpeg,image/jpg"}
                            displayType={"bdsFile"}
                            uploadType={"bdsFile"}
                            uploadText={
                              bdsMdsDocs.bdsFile.originalname
                                ? bdsMdsDocs.bdsFile.originalname
                                : "Upload BDS"
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FileUploader
                            key={"mdsFile"}
                            handleUpload={(data: any) =>
                              handleUpload(data, "mdsFile")
                            }
                            accept={"image/png,image/jpeg,image/jpg"}
                            displayType={"mdsFile"}
                            uploadType={"mdsFile"}
                            uploadText={
                              bdsMdsDocs.mdsFile.originalname
                                ? bdsMdsDocs.mdsFile.originalname
                                : "Upload MDS"
                            }
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>

                {/* ///Carrer Details Comes Here */}
                <Box mt={3}>
                  <Typography variant="body1">Careerer Details</Typography>
                  <Grid container spacing={3} mt={1}>
                    {watchUserType === "daDoctor" && (
                      <Grid item xs={12} md={4}>
                        <Controller
                          name="dentalCouncilNumber"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <CustomInput
                              {...field}
                              error={!!error}
                              helperText={error ? error.message : ""}
                              label={"Enter Dental Council Number"}
                              InputProps={""}
                              size={"small"}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} md={4}>
                      <Controller
                        name="graduationYear"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            label={"Enter Graduation Year"}
                            InputProps={""}
                            size={"small"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Controller
                        name="graduationInstitute"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            label={"Enter Graduation Institute"}
                            InputProps={""}
                            size={"small"}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Controller
                        name="postGraduationYear"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            label={"Enter Post Graduation Year"}
                            InputProps={""}
                            size={"small"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Controller
                        name="postGraduationInstitute"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            label={"Enter Post Graduation Institute"}
                            InputProps={""}
                            size={"small"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Controller
                        name="experienceInYears"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            label={"Enter Experience In Years"}
                            InputProps={""}
                            size={"small"}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Controller
                    name="hasOrganizationMembership"
                    control={control}
                    render={({ field }) => (
                      <CustomCheckbox
                        {...field}
                        label="Has Organization Membership"
                      />
                    )}
                  />

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      {awardsAndRewardsDetails.map((item, index) => {
                        return (
                          <Box
                            key={index}
                            mt={1}
                            display={"flex"}
                            gap={"10px"}
                            alignItems={"top"}
                            justifyContent={"center"}
                          >
                            <Box width={"100%"}>
                              <Controller
                                render={({ field, fieldState: { error } }) => (
                                  <CustomInput
                                    {...field}
                                    label={"Enter Awards and Rewards Points"}
                                    type={"text"}
                                    error={!!error}
                                    helperText={error ? error.message : ""}
                                  />
                                )}
                                name={`awardsAndRewardsDetails.${index}.awardsAndRewards`}
                                control={control}
                              />
                            </Box>
                            <Box>
                              {index < 1 && (
                                <Button
                                  sx={{
                                    border: "1px solid #5C4EFF",
                                    fontSize: "12px",
                                    color: "#5C4EFF",
                                  }}
                                  onClick={() => addAwardsAndRewards()}
                                >
                                  Add
                                </Button>
                              )}
                              {index > 0 && (
                                <Button
                                  sx={{
                                    border: "1px solid #5C4EFF",
                                    color: "#5C4EFF",
                                    fontSize: "12px",
                                  }}
                                  onClick={() => removeAwardsAndRewards(index)}
                                >
                                  Delete
                                </Button>
                              )}
                            </Box>
                          </Box>
                        );
                      })}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      {trainingsAndCoursesDetails.map((item, index) => {
                        return (
                          <Box
                            key={index}
                            mt={1}
                            display={"flex"}
                            gap={"10px"}
                            alignItems={"top"}
                            justifyContent={"center"}
                          >
                            <Box width={"100%"}>
                              <Controller
                                render={({ field, fieldState: { error } }) => (
                                  <CustomInput
                                    {...field}
                                    label={"Enter Training And Courses"}
                                    type={"text"}
                                    error={!!error}
                                    helperText={error ? error.message : ""}
                                  />
                                )}
                                name={`trainingsAndCoursesDetails.${index}.trainingsAndCourses`}
                                control={control}
                              />
                            </Box>
                            <Box>
                              {index < 1 && (
                                <Button
                                  sx={{
                                    border: "1px solid #5C4EFF",
                                    fontSize: "12px",
                                    color: "#5C4EFF",
                                  }}
                                  onClick={() => addTrainingsAndCourses()}
                                >
                                  Add
                                </Button>
                              )}
                              {index > 0 && (
                                <Button
                                  sx={{
                                    border: "1px solid #5C4EFF",
                                    color: "#5C4EFF",
                                    fontSize: "12px",
                                  }}
                                  onClick={() =>
                                    removeTrainingsAndCourses(index)
                                  }
                                >
                                  Delete
                                </Button>
                              )}
                            </Box>
                          </Box>
                        );
                      })}
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} mt={1}>
                    <Grid item xs={12} md={4}>
                      <FileUploader
                        key={"educationCertificates"}
                        uploadAadharCard={uploadEducationCertificates}
                        setUploadEducationCertificates={
                          setUploadEducationCertificates
                        }
                        accept={"image/png,image/jpeg,image/jpg"}
                        displayType={"educationCertificates"}
                        uploadType={"educationCertificates"}
                        uploadText={"Upload Education Certificate"}
                      />
                      {Boolean(
                        uploadEducationCertificates
                          ?.educationCertificatesResponseFiles?.length
                      ) &&
                        uploadEducationCertificates
                          ?.educationCertificatesResponseFiles?.length && (
                          <Box display={"flex"} gap={"10px "} flexWrap={"wrap"}>
                            {uploadEducationCertificates?.educationCertificatesResponseFiles?.map(
                              (cover: any, index: any) => {
                                return (
                                  <Box
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    gap={"30px"}
                                    bgcolor={"#D5D5D5"}
                                    padding={"5px 10px"}
                                    borderRadius={"5px"}
                                    fontSize={"10px"}
                                    mt={2}
                                  >
                                    {index + 1} {cover?.originalname}
                                    <Typography
                                      component={"span"}
                                      sx={{ cursor: "pointer" }}
                                      onClick={() =>
                                        deleteEducationCertificates(index)
                                      }
                                      fontSize={"12px"}
                                    >
                                      X
                                    </Typography>
                                  </Box>
                                );
                              }
                            )}
                          </Box>
                        )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FileUploader
                        key={"workExperienceCertificates"}
                        uploadAadharCard={workExperienceCertificates}
                        setWorkExperienceCertificates={
                          setWorkExperienceCertificates
                        }
                        accept={"image/png,image/jpeg,image/jpg"}
                        displayType={"workExperienceCertificates"}
                        uploadType={"workExperienceCertificates"}
                        uploadText={"Upload Work Experience Certificates"}
                      />
                      {Boolean(
                        workExperienceCertificates
                          ?.workExperienceCertificatesResponseFiles?.length
                      ) &&
                        workExperienceCertificates
                          ?.workExperienceCertificatesResponseFiles?.length && (
                          <Box display={"flex"} gap={"10px "} flexWrap={"wrap"}>
                            {workExperienceCertificates?.workExperienceCertificatesResponseFiles?.map(
                              (cover: any, index: any) => {
                                return (
                                  <Box
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    gap={"30px"}
                                    bgcolor={"#D5D5D5"}
                                    padding={"5px 10px"}
                                    borderRadius={"5px"}
                                    fontSize={"10px"}
                                    mt={2}
                                  >
                                    {index + 1} {cover?.originalname}
                                    <Typography
                                      component={"span"}
                                      sx={{ cursor: "pointer" }}
                                      onClick={() =>
                                        deleteWorkExperienceCertificates(index)
                                      }
                                      fontSize={"12px"}
                                    >
                                      X
                                    </Typography>
                                  </Box>
                                );
                              }
                            )}
                          </Box>
                        )}
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FileUploader
                        key={"achievementCertificates"}
                        uploadAadharCard={achievementCertificates}
                        setAchievementCertificates={setAchievementCertificates}
                        accept={"image/png,image/jpeg,image/jpg"}
                        displayType={"achievementCertificates"}
                        uploadType={"achievementCertificates"}
                        uploadText={"Upload Achievement Certificates"}
                      />
                      {Boolean(
                        achievementCertificates
                          ?.achievementCertificatesResponseFiles?.length
                      ) &&
                        achievementCertificates
                          ?.achievementCertificatesResponseFiles?.length && (
                          <Box display={"flex"} gap={"10px "} flexWrap={"wrap"}>
                            {achievementCertificates?.achievementCertificatesResponseFiles?.map(
                              (cover: any, index: any) => {
                                return (
                                  <Box
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    gap={"30px"}
                                    bgcolor={"#D5D5D5"}
                                    padding={"5px 10px"}
                                    borderRadius={"5px"}
                                    fontSize={"10px"}
                                    mt={2}
                                  >
                                    {index + 1} {cover?.originalname}
                                    <Typography
                                      component={"span"}
                                      sx={{ cursor: "pointer" }}
                                      onClick={() =>
                                        deleteAchievementCertificates(index)
                                      }
                                      fontSize={"12px"}
                                    >
                                      X
                                    </Typography>
                                  </Box>
                                );
                              }
                            )}
                          </Box>
                        )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} mt={1}>
                    <Grid item xs={12} md={4}>
                      <FileUploader
                        key={"additionalCertificates"}
                        uploadAadharCard={additionalCertificates}
                        setAdditionalCertificates={setAdditionalCertificates}
                        accept={"image/png,image/jpeg,image/jpg"}
                        displayType={"additionalCertificates"}
                        uploadType={"additionalCertificates"}
                        uploadText={"Upload additionalCertificates"}
                      />
                      {Boolean(
                        additionalCertificates
                          ?.additionalCertificatesResponseFiles?.length
                      ) &&
                        additionalCertificates
                          ?.additionalCertificatesResponseFiles?.length && (
                          <Box display={"flex"} gap={"10px "} flexWrap={"wrap"}>
                            {additionalCertificates?.additionalCertificatesResponseFiles?.map(
                              (cover: any, index: any) => {
                                return (
                                  <Box
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    gap={"30px"}
                                    bgcolor={"#D5D5D5"}
                                    padding={"5px 10px"}
                                    borderRadius={"5px"}
                                    fontSize={"10px"}
                                    mt={2}
                                  >
                                    {index + 1} {cover?.originalname}
                                    <Typography
                                      component={"span"}
                                      sx={{ cursor: "pointer" }}
                                      onClick={() =>
                                        deleteAdditionalCertificates(index)
                                      }
                                      fontSize={"12px"}
                                    >
                                      X
                                    </Typography>
                                  </Box>
                                );
                              }
                            )}
                          </Box>
                        )}
                    </Grid>
                  </Grid>
                </Box>
                {/* ///Carrer Details Ends Here */}

                {/* Address for Communication Section */}
                <Box mt={3}>
                  <Typography variant="body1">
                    Address for Communication
                  </Typography>
                  {/* Add address for communication fields here */}

                  <Grid container spacing={3} mt={1}>
                    <Grid item xs={6} md={4}>
                      <Controller
                        name="addressLine1"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            label={"Enter Address Line 1"}
                            InputProps={""}
                            size={"small"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Controller
                        name="addressLine2"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            label={"Enter Address Line 2"}
                            InputProps={""}
                            size={"small"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Box>
                        <Controller
                          name="country"
                          defaultValue={"India"}
                          control={control}
                          rules={{ required: true }}
                          render={({
                            field: { ref, onChange, ...field },
                            fieldState: { error },
                          }) => (
                            <CustomAutocomplete
                              field={field}
                              disabled
                              error={!!error}
                              helperText={error ? error.message : ""}
                              ref={ref}
                              singleSelection
                              onChange={(
                                event: React.SyntheticEvent,
                                value: any
                              ) => onChange(value)}
                              options={COUNTRY_LIST}
                              value={getValues("country")}
                              label="Country"
                              defaultValue={"India"}
                              placeholder={"Country"}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Controller
                        name="state"
                        control={control}
                        defaultValue={getValues("state")}
                        rules={{ required: true }}
                        render={({
                          field: { ref, onChange, ...field },
                          fieldState: { error },
                        }) => (
                          <CustomAutocomplete
                            field={field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            ref={ref}
                            value={getValues("state")}
                            singleSelection
                            onChange={(
                              event: React.SyntheticEvent,
                              value: any
                            ) => {
                              onChange(value);
                              setValue("city", "");
                              setCities(statesAndCities[value] || []);
                            }}
                            options={Object.keys(statesAndCities)}
                            label="State"
                            placeholder={"State"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Controller
                        name="city"
                        control={control}
                        defaultValue={getValues("city")}
                        rules={{ required: true }}
                        render={({
                          field: { ref, onChange, ...field },
                          fieldState: { error },
                        }) => (
                          <CustomAutocomplete
                            field={field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            ref={ref}
                            value={getValues("city")}
                            singleSelection
                            onChange={(
                              event: React.SyntheticEvent,
                              value: any
                            ) => {
                              onChange(value);
                              setValue("city", value);
                            }}
                            options={cities}
                            label="City"
                            placeholder={"City"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Controller
                        name="zipCode"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            label={"Zip code"}
                            size={"small"}
                            type={"number"}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>

                {/* Additional Sections can be added as needed */}
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  <CustomButton
                    title={"Cancel"}
                    marginRight={"5px"}
                    bgColor={"rgba(255, 225, 229, 1)"}
                    width={"100px"}
                    color={"rgba(255, 97, 116, 1)"}
                    borderColor={"rgba(255, 97, 116, 1)"}
                    borderWidth={"1px"}
                    borderStyle={"solid"}
                    onClick={cancelHandle}
                  />
                  <Box marginLeft={1}>
                    <CustomButton
                      type={"submit"}
                      title={"Save"}
                      marginRight={"5px"}
                      bgColor={"rgba(92, 78, 255, 1)"}
                      width={"100px"}
                    />
                  </Box>
                </Box>
              </form>
            </Box>
          </CustomCard>
        </Box>
      </Container>
    </>
  );
};

export default AddEditUser;
