import React, { useEffect, useState } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CustomInput from "../../../components/Input";
import CustomCheckbox from "../../../components/CustomCheckBox";
import CustomButton from "../../../components/Button";
import CustomCard from "../../../components/Card";
import CustomSelectPicker from "../../../components/SelectPicker";
import { apiRequest } from "../../../app/services/requests";
import { convertStringToNumber } from "../utills";
import { useNavigate, useParams } from "react-router-dom";
import {
  payTypeOptions,
  PUBLISH_PLAN_USER_OPTIONS,
} from "../../../app/utilities/constants";

type FormValues = {
  name: string;
  premium: string;
  limit: string;
  coPayType: string;
  coPay: string;
  deductibleType: string;
  deductible: string;
  categories: string[];
  preventiveDiscount?: string;
  preventiveWaitingPeriod?: string;
  preventiveAfterWaitingPeriod?: string;
  preventiveAfterOneYear?: string;
  preventiveAfterTwoYear?: string;
  basicDiscount?: string;
  basicWaitingPeriod?: string;
  basicAfterWaitingPeriod?: string;
  basicAfterOneYear?: string;
  basicAfterTwoYear?: string;
  minorDiscount?: string;
  minorWaitingPeriod?: string;
  minorAfterWaitingPeriod?: string;
  minorAfterOneYear?: string;
  minorAfterTwoYear?: string;
  majorDiscount?: string;
  majorWaitingPeriod?: string;
  majorAfterWaitingPeriod?: string;
  majorAfterOneYear?: string;
  majorAfterTwoYear?: string;
};

const schema = yup.object().shape({
  name: yup.string().required("Plan Name is required"),
  premium: yup
    .number()
    .min(100, "Premium must be more than 100")
    .typeError("Premium must be a number")
    .required("Premium is required"),
  limit: yup
    .number()
    .typeError("Claim Limit must be a number")
    .min(1, "Claim Limit must be a positive number")
    .required("Claim Limit is required"),
  coPayType: yup.string().required("CoPay Type is required"),
  coPay: yup
    .number()
    .typeError("CoPay must be a number")
    .when("coPayType", {
      is: "percentage",
      then: yup
        .number()
        .max(100, "CoPay cannot be more than 100%")
        .required("CoPay is required"),
      otherwise: yup
        .number()
        .lessThan(
          yup.ref("premium"),
          "CoPay must be less than the premium amount"
        )
        .required("CoPay is required"),
    }),
  deductibleType: yup.string().required("Deductible Type is required"),
  deductible: yup
    .number()
    .typeError("Deductible must be a number")
    .when("deductibleType", {
      is: "percentage",
      then: yup
        .number()
        .max(100, "Deductible cannot be more than 100%")
        .required("Deductible is required"),
      otherwise: yup
        .number()
        .lessThan(
          yup.ref("premium"),
          "Deductible must be less than the premium amount"
        )
        .required("Deductible is required"),
    }),

  categories: yup
    .array()
    .of(yup.string())
    .min(1, "At least one category must be selected")
    .required("Categories are required"),

  // Preventive discount fields
  preventiveDiscount: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("preventive"),
    then: yup
      .number()
      .required("Preventive Discount is required")
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  }),
  preventiveWaitingPeriod: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("preventive"),
    then: yup
      .number()
      .required("Preventive Waiting Period is required")
      .min(0, "Must be at least 0")
      .max(365, "Cannot exceed 365"),
    otherwise: yup.number().notRequired(),
  }),
  preventiveAfterWaitingPeriod: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("preventive"),
    then: yup
      .number()
      .required("Preventive After Waiting Period is required")
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  }),
  preventiveAfterOneYear: yup
    .number()
    .typeError("Preventive After One Year must be a number")
    .when("categories", {
      is: (categories: string[]) => categories.includes("preventive"),
      then: yup
        .number()
        .required("Preventive After One Year is required")
        .min(0, "Must be at least 0%")
        .max(100, "Cannot exceed 100%"),
      otherwise: yup.number().notRequired(),
    }),
  preventiveAfterTwoYear: yup
    .number()
    .typeError("Preventive After Two Year must be a number")
    .when("categories", {
      is: (categories: string[]) => categories.includes("preventive"),
      then: yup
        .number()
        .required("Preventive After Two Year is required")
        .min(0, "Must be at least 0%")
        .max(100, "Cannot exceed 100%"),
      otherwise: yup.number().notRequired(),
    }),
  preventiveAfterThreeYear: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("preventive"),
    then: yup
      .number()
      .required("Preventive After Three Year is required")
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  }),
  preventiveAfterFourYear: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("preventive"),
    then: yup
      .number()
      .required("Preventive After Four Year is required")
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  }),

  // Basic discount fields
  basicDiscount: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("basic"),
    then: yup
      .number()
      .required("Basic Discount is required")
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  }),
  basicWaitingPeriod: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("basic"),
    then: yup
      .number()
      .required("Basic Waiting Period is required")
      .min(0, "Must be at least 0")
      .max(365, "Cannot exceed 365"),
    otherwise: yup.number().notRequired(),
  }),
  basicAfterWaitingPeriod: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("basic"),
    then: yup
      .number()
      .required("Basic After Waiting Period is required")
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  }),
  basicAfterOneYear: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("basic"),
    then: yup
      .number()
      .required("Basic After One Year is required")
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  }),
  basicAfterTwoYear: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("basic"),
    then: yup
      .number()
      .required("Basic After Two Year is required")
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  }),
  basicAfterThreeYear: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("basic"),
    then: yup
      .number()
      .required("Basic After Three Year is required")
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  }),
  basicAfterFourYear: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("basic"),
    then: yup
      .number()
      .required("Basic After Four Year is required")
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  }),

  // Minor discount fields
  minorDiscount: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("minor"),
    then: yup
      .number()
      .required("Minor Discount is required")
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  }),
  minorWaitingPeriod: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("minor"),
    then: yup
      .number()
      .required("Minor Waiting Period is required")
      .min(0, "Must be at least 0")
      .max(365, "Cannot exceed 365"),
    otherwise: yup.number().notRequired(),
  }),
  minorAfterWaitingPeriod: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("minor"),
    then: yup
      .number()
      .required("Minor After Waiting Period is required")
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  }),
  minorAfterOneYear: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("minor"),
    then: yup
      .number()
      .required("Minor After One Year is required")
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  }),
  minorAfterTwoYear: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("minor"),
    then: yup
      .number()
      .required("Minor After Two Year is required")
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  }),
  minorAfterThreeYear: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("minor"),
    then: yup
      .number()
      .required("Minor After Three Year is required")
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  }),
  minorAfterFourYear: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("minor"),
    then: yup
      .number()
      .required("Minor After Four Year is required")
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  }),

  // Major discount fields
  majorDiscount: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("major"),
    then: yup
      .number()
      .required("Major Discount is required")
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  }),
  majorWaitingPeriod: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("major"),
    then: yup
      .number()
      .required("Major Waiting Period is required")
      .min(0, "Must be at least 0")
      .max(365, "Cannot exceed 365"),
    otherwise: yup.number().notRequired(),
  }),
  majorAfterWaitingPeriod: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("major"),
    then: yup
      .number()
      .required("Major After Waiting Period is required")
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  }),
  majorAfterOneYear: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("major"),
    then: yup
      .number()
      .required("Major After One Year is required")
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  }),
  majorAfterTwoYear: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("major"),
    then: yup
      .number()
      .required("Major After Two Year is required")
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  }),
  majorAfterThreeYear: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("major"),
    then: yup
      .number()
      .required("Major After Three Year is required")
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  }),
  majorAfterFourYear: yup.number().when("categories", {
    is: (categories: string[]) => categories.includes("major"),
    then: yup
      .number()
      .required("Major After Four Year is required")
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  }),
});

const AddEditMasterPlan = () => {
  const navigate = useNavigate();
  const { planId } = useParams();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      premium: "",
      limit: "0",
      coPayType: "percentage",
      coPay: "0",
      deductibleType: "percentage",
      deductible: "0",
      categories: [],
      preventiveDiscount: undefined,
      preventiveWaitingPeriod: undefined,
      preventiveAfterWaitingPeriod: undefined,
      preventiveAfterOneYear: undefined,
      preventiveAfterTwoYear: undefined,
      preventiveAfterThreeYear: undefined,
      preventiveAfterFourYear: undefined,

      basicDiscount: undefined,
      basicWaitingPeriod: undefined,
      basicAfterWaitingPeriod: undefined,
      basicAfterOneYear: undefined,
      basicAfterTwoYear: undefined,
      basicAfterThreeYear: undefined,
      basicAfterFourYear: undefined,

      minorDiscount: undefined,
      minorWaitingPeriod: undefined,
      minorAfterWaitingPeriod: undefined,
      minorAfterOneYear: undefined,
      minorAfterTwoYear: undefined,
      minorAfterThreeYear: undefined,
      minorAfterFourYear: undefined,

      majorDiscount: undefined,
      majorWaitingPeriod: undefined,
      majorAfterWaitingPeriod: undefined,
      majorAfterOneYear: undefined,
      majorAfterTwoYear: undefined,
      majorAfterThreeYear: undefined,
      majorAfterFourYear: undefined,
      applicableToExistingUsers: true,
    },
  });

  const selectedCategories = useWatch({
    control,
    name: "categories",
    defaultValue: [],
  });

  const selectedDeductibleType = useWatch({
    control,
    name: "deductibleType",
    defaultValue: "percentage",
  });

  const selectedCoPayType = useWatch({
    control,
    name: "coPayType",
    defaultValue: "percentage",
  });

  const premiumWatch = useWatch({
    control,
    name: "premium",
    defaultValue: "",
  });

  useEffect(() => {
    if (premiumWatch) {
      const claimLimit = parseFloat(premiumWatch) * 3; // Multiply premium by 3
      setValue("limit", claimLimit);
    }
  }, [premiumWatch, setValue]);

  useEffect(() => {
    if (planId) {
      getPlanDetails(planId);
    }
  }, [planId]);

  const [planDetails, setPlanDetails] = useState<any>({});

  const getPlanDetails = (planId?: string) => {
    apiRequest({
      url: `master-plan/${planId}/record`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          setPlanDetails({ id: res?.id, status: res?.status });
          reset({
            ...res,
          });
        }
      })
      .catch((err) => {
        //check
      });
  };

  const onSubmit = (data: any) => {
    const formData = convertStringToNumber(data);
    const payload = {
      ...formData,
    };
    if (!planId) {
      apiRequest({
        url: `master-plan/create`,
        data: payload,
        method: "POST",
      })
        .then((res) => {
          alert("New Master Plan has been created successfully!");
          navigate("/master/plan-list");
        })
        .catch((err) => {
          // if (err?.statusCode === 403 || err?.statusCode === 401) {
          //   if (navigate) {
          //     navigate("/login"); // Navigate to "/login" route
          //   }
          // }
        });
    } else {
      const {
        id,
        createdAt,
        updatedAt,
        status,
        code,
        publishedAt,
        comments,
        version,
        applicableToExistingUsers,
        ...updatePayload
      } = payload;
      if (planDetails?.status === "published") {
        updatePayload.applicableToExistingUsers = JSON.parse(
          payload?.applicableToExistingUsers
        );
      }
      apiRequest({
        url: `master-plan/${planId}/update`,
        data: updatePayload,
        method: "PATCH",
      })
        .then((res) => {
          alert("Master Plan has been updated successfully!");
          navigate("/master/plan-list");
        })
        .catch((err) => {});
    }
  };

  const renderCategoryFields = (
    categoryPrefix: "preventive" | "basic" | "minor" | "major" | "other"
  ) => {
    const capitalPrefix =
      categoryPrefix.charAt(0).toUpperCase() + categoryPrefix.slice(1);
    return (
      <>
        <Grid item xs={12} mb={1}>
          <Typography>{capitalPrefix}</Typography>
        </Grid>

        {/* Grid container for fields */}
        <Grid container spacing={2} item xs={12} mb={3}>
          <Grid item xs={4}>
            <Controller
              name={`${categoryPrefix}Discount` as const}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  type={"number"}
                  label={`${capitalPrefix} Immediately Discount(%)`}
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Controller
              name={`${categoryPrefix}WaitingPeriod` as const}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  type={"number"}
                  label={`${capitalPrefix} Waiting Period(In Days)`}
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Controller
              name={`${categoryPrefix}AfterWaitingPeriod` as const}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  type={"number"}
                  label={`${capitalPrefix} Discount After Waiting Period(%)`}
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Controller
              name={`${categoryPrefix}AfterOneYear` as const}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  type={"number"}
                  label={`${capitalPrefix} Discount After One Year (%)`}
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Controller
              name={`${categoryPrefix}AfterTwoYear` as const}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  type={"number"}
                  label={`${capitalPrefix} Discount After Two Years (%)`}
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name={`${categoryPrefix}AfterThreeYear` as const}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  type={"number"}
                  label={`${capitalPrefix} Discount After Three Years (%)`}
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name={`${categoryPrefix}AfterFourYear` as const}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  type={"number"}
                  label={`${capitalPrefix} Discount After Four Years (%)`}
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <CustomCard sx={{ padding: 4, margin: 2 }}>
      <Box mb={1.5}>
        <Typography variant="body1">Create Plan</Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  label="Plan Name"
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="premium"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  label="Premium"
                  type="number"
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="limit"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  label="Claim Limit"
                  type="number"
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="coPayType"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectPicker
                  {...field}
                  label="CoPay Type"
                  error={!!error}
                  helperText={error ? error.message : ""}
                  size={"small"}
                  fullWidth={true}
                  width={"100%"}
                  noneRequired={false}
                  selectData={payTypeOptions}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="coPay"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  label={`CoPay(${selectedCoPayType !== "price" ? "%" : "₹"})`}
                  type="number"
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="deductibleType"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectPicker
                  {...field}
                  label="Deductible Type"
                  error={!!error}
                  helperText={error ? error.message : ""}
                  size={"small"}
                  fullWidth={true}
                  width={"100%"}
                  selectData={payTypeOptions}
                  noneRequired={false}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="deductible"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  label={`Deductible(${
                    selectedDeductibleType !== "price" ? "%" : "₹"
                  })`}
                  type="number"
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Select Categories</Typography>
            <Controller
              name="categories"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "16px",
                      flexWrap: "wrap",
                    }}
                  >
                    {["preventive", "basic", "minor", "major", "other"].map(
                      (category) => (
                        <CustomCheckbox
                          key={category}
                          checked={field.value.includes(category)}
                          onChange={() => {
                            const updatedCategories = field.value.includes(
                              category
                            )
                              ? field.value.filter(
                                  (cat: string) => cat !== category
                                )
                              : [...field.value, category];
                            setValue("categories", updatedCategories); // Update the form value
                          }}
                          label={
                            category.charAt(0).toUpperCase() + category.slice(1)
                          }
                        />
                      )
                    )}
                  </div>
                  {selectedCategories?.length === 0 && error && (
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      {error.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {selectedCategories.includes("preventive") &&
              renderCategoryFields("preventive")}
            {selectedCategories.includes("basic") &&
              renderCategoryFields("basic")}
            {selectedCategories.includes("minor") &&
              renderCategoryFields("minor")}
            {selectedCategories.includes("major") &&
              renderCategoryFields("major")}
            {selectedCategories.includes("other") &&
              renderCategoryFields("other")}

            {["preventive", "basic", "minor", "major", "other"].some(
              (category: string) => selectedCategories?.includes(category)
            ) && (
              <Grid item xs={12} mb={1}>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: "bold !important" }}
                >
                  {"*Note: All discounts with continuous coverage only."}
                </Typography>
              </Grid>
            )}
          </Grid>
          {planDetails?.status === "published" && (
            <Grid item xs={12} md={12} style={{ display: "flex" }}>
              <Controller
                name={`applicableToExistingUsers`}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl component="fieldset">
                    <RadioGroup {...field} row>
                      {PUBLISH_PLAN_USER_OPTIONS.map(
                        (option: any, idx: any) => (
                          <FormControlLabel
                            key={idx}
                            value={option.id}
                            control={<Radio />}
                            label={option.name}
                          />
                        )
                      )}
                    </RadioGroup>
                    {error && (
                      <FormHelperText sx={{ color: "#d32f2f !important" }}>
                        {error.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <CustomButton
                border="1px solid #000000"
                color="#000000"
                title="Cancel"
                bgColor="#ffffff"
                onClick={() => navigate("/master/plan-list")}
                borderRadius="5px"
              />
              <Box marginLeft={1}>
                <CustomButton type="submit" title="Submit" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </form>
    </CustomCard>
  );
};

export default AddEditMasterPlan;
