import { Box, Typography } from "@mui/material";
import CustomButton from "../../components/Button";
import { Link } from "react-router-dom";
import styless from "./doctor.module.css";

const Step3 = (props: any) => {
  const { onClickBack } = props;
  return (
    <>
      <Box p={2} style={{ display: "grid" }}>
        {/* Your Basic Member Details Form or Component */}
        {/* <Box
          style={{
            // height: "180px",
            display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
          }}
        > */}
        {/* <Box p={2}> */}

        <Box
          alignItems={"center"}
          justifyContent={"center"}
          style={{ height: "70px", marginTop: "70px" }}
          className={styless.justing}
          component="img"
          src={require("../../app/assets/AddNewClient/success.png")}
        ></Box>

        <Box
          alignItems={"center"}
          justifyContent={"center"}
          style={{ marginTop: "20px" }}
        >
          <Typography
            style={{ textAlign: "center", color: "rgba(92, 78, 255, 1)" }}
          >
            {" "}
            Doctor Added Successfully!
          </Typography>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          style={{ marginTop: "5px" }}
          justifyContent={"center"}
        >
          <Typography>
            {" "}
            Go to{" "}
            <Link to="/hospital/65745712345/profile/documents">
              Profile Screen
            </Link>
          </Typography>
        </Box>

        <CustomButton
          bgColor="rgba(92, 78, 255, 1)"
          title={"Done"}
          type="submit"
          borderRadius="5px"
          marginLeft={"46%"}
          marginTop={"20px"}
          width={"8%"}
        />
      </Box>
      {/* </Box> */}
      {/* </Box> */}
    </>
  );
};

export default Step3;
