import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import CustomCard from "../../../components/Card";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "../../../app/context/useSnackbar";
import { useAppContext } from "../../../app/context/AppContext";
import Stepper from "../../../components/stepper";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import ClientProfileCard from "../../../components/ClientProfileCard";
import PlanTopCard from "../components/plan-top-card";
import { Link as RouterLink } from "react-router-dom";
import IRATypography from "../../../components/ira-typography/ira-typography";
import Breadcrumb from "../../../components/Breadcrumb";
import { apiRequest } from "../../../app/services/requests";

const CreateHealthPlan = (props: any) => {
  const { currentSubCategory = {} as any, isEdit } = props;

  const navigate = useNavigate();

  const { handleLogoutSuccess } = useAppContext() as any;
  const { openSnackbar } = useSnackbar() as any;

  const [step, setStep] = useState<any>(0);

  const { clientId } = useParams();

  const [clientDet, setClientDet] = useState("") as any;

  useEffect(() => {
    getClientDetails();
  }, []);

  const getClientDetails = () => {
    apiRequest({
      url: `client/${clientId}`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          setClientDet(res);
        }
      })
      .catch((err) => {
        // if (err?.statusCode === 403 || err?.statusCode === 401) {
        //   if (navigate) {
        //     navigate("/login"); // Navigate to "/login" route
        //   }
        // }
      });
  };

  useEffect(() => {
    if (step !== 0) {
      // dispatch(getOnboardingPageDetails({ where: { pageNumber: step + 1 } }));
    }
    if (step === 1) {
      // dispatch(getDegrees({ where: { field: 'DEGREES' } }));
    }
    if (step === 2) {
      // dispatch(getTechnicalCourses({ where: { field: 'TECHNICAL_COURSES' } }));
      // dispatch(
      //   getCertificationCourses({ where: { field: 'CERTIFICATION_COURSES' } })
      // );
    }
  }, [step]);

  useEffect(() => {
    return () => {
      // dispatch(setCurrentStep(0));
      setStep(0);
    };
  }, []);

  const handleStep1Submit = (userTypeId: string) => {
    setStep(step + 1);
  };

  const handleStep2Submit = (data: any) => {
    setStep(step + 1);
  };

  const handleStep3Submit = (data: any) => {
    setStep(step + 3);
  };

  const handleSkip = () => {
    // if (!user.onboarding.isSkipped) {
    //   user.onboarding.isSkipped = true;
    //   setUser({ ...user });
    //   dispatch(skipOnboarding());
    // }
    // onOnboardingSkip();
  };

  const handleBack = () => {
    setStep((prevStep: any) => step - 1);
  };

  const breadcrumbs = [
    <RouterLink to="/Dashboard" style={{ textDecoration: "none" }} key="1001">
      <IRATypography
        key="2"
        textVariant="h12WithSemiBold"
        sx={{ fontSize: 14, color: "#222222" }}
      >
        Home
      </IRATypography>
    </RouterLink>,
    <IRATypography
      key="3"
      textVariant="h12WithSemiBold"
      sx={{ fontSize: 14, color: "#222222" }}
    >
      Client Profile
    </IRATypography>,
    <IRATypography
      key="3"
      textVariant="h12WithSemiBold"
      sx={{ fontSize: 14, color: "#222222" }}
    >
      Buy Plan
    </IRATypography>,
    <IRATypography
      key="3"
      textVariant="h12WithSemiBold"
      sx={{ fontSize: 14, color: "#222222" }}
    >
      Plan Details
    </IRATypography>,
  ];

  function getCurrentStep(step: number) {
    switch (step) {
      case 0:
        return (
          <Step1
            onStep1Submit={handleStep1Submit}
            onClickSkip={handleSkip}
            showSkip={false}
          />
        );
      case 1:
        return (
          <Step3 onStep3Submit={handleStep3Submit} onClickBack={handleBack} />
        );
    }
  }

  return (
    <>
      <Box
        sx={{ color: "#222222" }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumb
          title={`Plan Details`}
          key="case-list-breadcrumb"
          breadcrumbsArr={breadcrumbs}
        />
      </Box>
      <Grid container spacing={2}>
        {/* 3 columns */}
        <Grid item xs={3.5}>
          <Box>
            <ClientProfileCard clientDetails={clientDet} />
          </Box>
        </Grid>
        {/* 9 columns */}
        <Grid item xs={8.5} mt={2}>
          <PlanTopCard planType={"discount"} />
          <Box mt={2}>
            <CustomCard>
              <Box px={4} pt={2}>
                <Stepper
                  sx={{
                    "& .MuiStep-root": {
                      paddingLeft: "4px !important",
                      paddingRight: "4px !important",
                    },
                  }}
                  steps={2}
                  activeStep={step}
                  stepperVariant="circle"
                  stepOptions={{
                    "0": "Personal Details",
                    "1": "Summary & Payment",
                  }}
                />
              </Box>
              <Box minHeight="300px">{getCurrentStep(step)}</Box>
            </CustomCard>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateHealthPlan;
