import React from "react";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import {
  Box,
  Grid,
  MenuItem,
  Select,
  Typography,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { styles } from "./styles";

const CustomSelectPicker = (props: any) => {
  const {
    value,
    onChange,
    size,
    width,
    formControlWidth,
    selectData,
    multiple,
    placeholderText,
    disabled,
    id,
    label,
    required = false,
    sx = {},
    noneRequired = true,
    ...rest
  } = props;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <FormControl
      sx={{
        width: formControlWidth || "100%",
        "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
          color: "#9DA2AB",
          fontSize: "13px",
          opacity: 1,
          top: "-3px",
        },
      }}
      error={props.error}
    >
      {label && <InputLabel id="demo-simple-select-label">{label}</InputLabel>}
      {multiple ? (
        <Select
          {...rest}
          required={required}
          label={label}
          labelId="demo-simple-select-label"
          sx={{
            borderRadius: `10px`,
            borderColor: `#9DA2AB`,
            boxShadow: "0px 0px 10px #00000014",
            borderWidth: 1,
            width: width || "100%",
            color: "#9DA2AB",
            fontSize: 13,
            "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
              color: "#9DA2AB",
              fontSize: "13px",
              opacity: 1,
              top: "-3px",
            },
            "& .MuiSelect-select": {
              whiteSpace: "wrap !important",
            },
            ...sx,
          }}
          multiple
          displayEmpty
          fullWidth={true}
          value={value}
          placeholder={placeholderText}
          onChange={onChange}
          size={size}
          id={id}
          renderValue={(selected: any) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value: any) => (
                <Box sx={styles.tagBackground}>
                  <Grid container flexDirection={"row"}>
                    <Typography sx={styles.tagvalue} key={value}>
                      {value}
                    </Typography>
                    <HighlightOffOutlinedIcon sx={styles.actionIcon} />
                  </Grid>
                </Box>
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
          disabled={disabled ? disabled : false}
        >
          {noneRequired && (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}
          {selectData.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name
                ? item.name
                : item.fullName
                ? item.fullName
                : item.firstName + " " + item.lastName}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select
          {...rest}
          required={required}
          disabled={disabled ? disabled : false}
          label={label}
          labelId="demo-simple-select-label"
          sx={{
            borderRadius: `10px`,
            borderColor: `#9DA2AB`,
            boxShadow: "0px 0px 10px #00000014",
            borderWidth: 1,
            width: width || "100%",
            color: "#9DA2AB",
            fontSize: 13,
            padding: 0.3,
            "& .MuiSelect-outlined.MuiInputBase-input": {
              color: "black",
            },
            "& .MuiSelect-select": {
              whiteSpace: "wrap !important",
            },
            ...sx,
          }}
          size={size}
          value={value}
          onChange={onChange}
          fullWidth={true}
          placeholder={placeholderText}
          renderValue={
            value !== ""
              ? undefined
              : () => {
                  return (
                    <Typography style={{ fontSize: 12, color: "#9DA2AB" }}>
                      {placeholderText ? placeholderText : "Select"}
                    </Typography>
                  );
                }
          }
        >
          {noneRequired && (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}
          {selectData?.length ? (
            selectData?.map((item: any) => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.name
                    ? item.name
                    : item.fullName
                    ? item.fullName
                    : item.firstName + " " + item.lastName}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem value="">
              <em>No Data</em>
            </MenuItem>
          )}
        </Select>
      )}
      {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
};

export default CustomSelectPicker;
