import { createTheme, PaletteOptions } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";
declare module "@mui/material/styles" {
  interface Palette {
    customColors: {
      white: string;
      black: string;
      grey: string;
      lightWhite: string;
      lightBlack: string;
      lightGrey: string;
      blackGrey: string;
      lightWhiteGrey:string
      main:string
    };
    customDialogBackGroundColor: {
      lightGrey: string;
      blackGrey: string;
    };
    customTabBoxShadow: {
      boxShadow: string;
    };
  }

  interface PaletteOptions {
    customColors?: {
      white: string;
      black: string;
      grey: string;
      lightWhite: string;
      lightBlack: string;
      lightGrey: string;
      blackGrey: string;
      lightWhiteGrey:string
      main:string
    };
    customDialogBackGroundColor: {
      lightGrey: string;
      blackGrey: string;
    };
    customTabBoxShadow: {
      boxShadow: string;
    };
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    regular: React.CSSProperties;
    bold: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    regular?: React.CSSProperties;
    bold?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    regular: true;
    bold: true;
  }
}
export const theme = createTheme({
  palette: {
    customColors: {
      main:"#5C4EFF",
      white: "#ffffff",
      black: "#000000",
      grey: "#6E7179",
      lightWhite: "#F8F7F1",
      lightBlack: "#121722",
      lightGrey: "#F8F9FB",
      blackGrey: "#1E1E1E",
      lightWhiteGrey:'#E5E5E6'
    },
    customDialogBackGroundColor: {
      lightGrey: "#F1F2F4",
      blackGrey: "#1E1E1E",
    },
    customTabBoxShadow: {
      boxShadow: "0px 0px 20px 3px rgba(161, 161, 161, 0.25)",
    },
  },
  typography: {
    regular: {
      fontFamily: "MontserratRegular !important",
    },
    bold: {
      fontFamily: "MontserratBold !important",
    },
  },
});
