import { Box, Card, Divider, Grid, Typography } from "@mui/material";
import CustomButton from "../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import ServicesTreatmentsCoveredList from "./services-treatments-covered-list";
import ClientDetailsCounter from "../../Client/client-details-counter";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const RenewalPlanDetails = (props: any) => {
  // console.log(props, "props");
  const navigate = useNavigate();
  const { clientId } = useParams();

  // const { CounterData } = props;

  let data = [
    {
      id: 1,
      heading: "Plans",
      remainingdays: "0",
      color: "#219FFF4D",
    },
    {
      id: 2,
      heading: "Claims",
      claimsCount: "05",
      color: "#68D4B44D",
    },
    {
      id: 3,
      heading: "Cases",
      claimsCount: "08",
      color: "#FF98A54D",
    },
  ];

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", gap: 2, borderRadius: 6 }}>
          {data?.map((item) => {
            return <ClientDetailsCounter CounterData={item} />;
          })}
        </Box>

        <Card sx={{ borderRadius: 3, marginTop: 2 }}>
          <Box>
            {/* <h1>Prasad</h1> */}
            <Box p={2} mt={2}>
              <Box onClick={props?.onHandleBackArrow} display={"flex"}>
                <ArrowBackIcon sx={{ color: "blue", cursor: "pointer" }} />
                <Typography
                  fontWeight={600}
                  sx={{ cursor: "pointer" }}
                >{`Renewal Plan`}</Typography>
              </Box>
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Box>
                      <Box>
                        <Typography>Preventive</Typography>
                      </Box>
                      <Typography>{`PLAN1`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Box>
                        <Typography> Range</Typography>
                      </Box>
                      <Typography>{`Family`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Box>
                        <Typography> Price</Typography>
                      </Box>
                      <Typography>{`15000 monthly`}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Divider sx={{ my: 1 }} /> {/* Adjust margin as needed */}
            <Box p={2}>
              <Box>
                <Typography variant="body1"> Plan Details</Typography>
              </Box>
              <Box my={1}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box display={"flex"} alignItems={"center"}>
                      <Box>
                        <Typography> Application No : </Typography>
                      </Box>
                      <Typography>{`1921928192819281`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box display={"flex"} alignItems={"center"}>
                      <Box>
                        <Typography> Validity : </Typography>
                      </Box>
                      <Typography>{`Monthly`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box display={"flex"} alignItems={"center"}>
                      <Box>
                        <Typography> Type : </Typography>
                      </Box>
                      <Typography>{`Individual`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box display={"flex"} alignItems={"center"}>
                      <Box>
                        <Typography> Payment Amount : </Typography>
                      </Box>
                      <Typography>{`10000`}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box p={2}>
                <Box>
                  <Typography variant="body1">
                    Services Treatment Covered
                  </Typography>
                </Box>
                <Box px={2}>
                  <ServicesTreatmentsCoveredList />
                </Box>
              </Box>
              <Divider sx={{ my: 1 }} /> {/* Adjust margin as needed */}
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box>
                      <Box>
                        <Typography>Preventive</Typography>
                      </Box>
                      <Typography>{`PLAN1`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Box>
                        <Typography> Basic</Typography>
                      </Box>
                      <Typography>{`Family`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Box>
                        <Typography> Minor</Typography>
                      </Box>
                      <Typography>{`15000 monthly`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Box>
                        <Typography> Major</Typography>
                      </Box>
                      <Typography>{`15000 monthly`}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Grid
              container
              flexDirection={"row"}
              spacing={0.5}
              justifyContent={"end"}
              mt={1}
              gap={"20px"}
              padding={2}
            >
              <Grid item>
                <CustomButton
                  border="1px solid #000000"
                  color="#000000"
                  title="Cancel"
                  bgColor="#ffffff"
                  handleButtonClick={() => navigate("/dashboard")}
                  borderRadius="5px"
                />
              </Grid>
              <Grid item>
                <CustomButton
                  handleButtonClick={() =>
                    navigate(`/client/${clientId}/plan/payment`)
                  }
                  type="button"
                  title={"Renewal Plan"}
                  backgroundcolor={"#204289"}
                  bordercolor={"#204289"}
                  color={"#fffff"}
                  width={150}
                  height={45}
                  paddingBottom={10}
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default RenewalPlanDetails;
