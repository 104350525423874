import * as React from "react";
import TablePagination from "@mui/material/TablePagination";

export default function Pagination(props: any) {
  const { onPageChange, onRowsPerPageChange, totalCount } = props || {};
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: any
  ) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    onRowsPerPageChange(parseInt(event.target.value, 10), 0);
  };

  return (
    <TablePagination
      component="div"
      count={totalCount}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}
 