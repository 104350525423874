import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, FormControlLabel } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import CloseIcon from "@mui/icons-material/Close";
import CustomModal from "../../../components/Modal";
import CustomButton from "../../../components/Button";
import CustomAutocomplete from "../../../components/Autocomplete";
import {
  TOOTH_SURFACES,
  SURFACE_TEETH_ELEMENT_IDS,
} from "../../../app/utilities/constants";
import Divider from "@mui/material/Divider";
import {
  FullTeethSurface,
  TeethSurfaceWithoutCenter,
} from "../../../components/SurfaceTeeth";

const teeths11to18 = ["18", "17", "16", "15", "14", "13", "12", "11"];
const teeths21to28 = ["21", "22", "23", "24", "25", "26", "27", "28"];
const teeths31to38 = ["31", "32", "33", "34", "35", "36", "37", "38"];
const teeths41to48 = ["48", "47", "46", "45", "44", "43", "42", "41"];

const teeths51to55 = ["55", "54", "53", "52", "51"];
const teeths61to65 = ["61", "62", "63", "64", "65"];
const teeths81to85 = ["85", "84", "83", "82", "81"];
const teeths71to75 = ["71", "72", "73", "74", "75"];

export const PRIMARY_TOOTHS = [
  ...teeths51to55,
  ...teeths61to65,
  ...teeths81to85,
  ...teeths71to75,
];

const surfacesWithCenter = [
  "18",
  "17",
  "16",
  "15",
  "14",
  "24",
  "25",
  "26",
  "27",
  "28",
  "34",
  "35",
  "36",
  "37",
  "38",
  "48",
  "47",
  "46",
  "45",
  "44",
  "54",
  "55",
  "64",
  "65",
  "84",
  "85",
  "74",
  "75",
];

const surfacesWithoutCenter = [
  "11",
  "12",
  "13",
  "21",
  "22",
  "23",
  "31",
  "32",
  "33",
  "41",
  "42",
  "43",
  "51",
  "52",
  "53",
  "61",
  "62",
  "63",
  "71",
  "72",
  "73",
  "81",
  "82",
  "83",
];

interface DentalChartInterface {
  selectedTooths: any;
  setValue: any;
  isPrimaryTeeth: any;
  findings: any;
  surface: any;
  handleTeethTypeChange: any;
  teethType: any;
  isSurfacesVisiable?: any;
}

const DentalChart = (props: DentalChartInterface) => {
  const {
    selectedTooths,
    setValue,
    isPrimaryTeeth,
    findings,
    surface = [],
    handleTeethTypeChange,
    teethType,
    isSurfacesVisiable = true,
  } = props;
  const [modalToothsurface, setModalToothsurface] = useState<any>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTeeth, setSelectedTeeth] = useState<any>("");
  const [teethSurfaceStyles, setTeethSurfaceStyles] = useState({});

  useEffect(() => {
    setValue(
      "selectedTooths",
      selectedTooths.map((val: any) => ({ ...val, surface }))
    );
  }, [surface.length]);

  useEffect(() => {
    setValue(
      "selectedTooths",
      selectedTooths.map((val: any) => ({ ...val, findings }))
    );
  }, [findings]);

  useEffect(() => {
    setTeethSurfaceStyles(getSurfaceStyles(modalToothsurface, selectedTeeth));
  }, [modalToothsurface]);

  const flipped = (obj: any) =>
    Object.entries(obj).map(([key, value]) => [value, key]);

  const getSurfaceMapping = (teeth: any, reverseMap = false) => {
    let surfaceMap = {};
    if ([...teeths11to18, ...teeths51to55].includes(teeth)) {
      surfaceMap = {
        Mesial: "Right",
        Distal: "Left",
        Lingual: "Bottom",
        Buccal: "Top",
        Central: "Center",
      };
    } else if ([...teeths21to28, ...teeths61to65].includes(teeth)) {
      surfaceMap = {
        Mesial: "Left",
        Distal: "Right",
        Lingual: "Bottom",
        Buccal: "Top",
        Central: "Center",
      };
    } else if ([...teeths41to48, ...teeths81to85].includes(teeth)) {
      surfaceMap = {
        Mesial: "Right",
        Distal: "Left",
        Lingual: "Top",
        Buccal: "Bottom",
        Central: "Center",
      };
    } else if ([...teeths31to38, ...teeths71to75].includes(teeth)) {
      surfaceMap = {
        Mesial: "Left",
        Distal: "Right",
        Lingual: "Top",
        Buccal: "Bottom",
        Central: "Center",
      };
    }
    return reverseMap ? Object.fromEntries(flipped(surfaceMap)) : surfaceMap;
  };

  const getSurfaceStyles = (teethSurfaces: any, teeth: any) => {
    const styles: any = {};
    for (const surface of teethSurfaces) {
      const surfaceMap: any = getSurfaceMapping(teeth);
      styles[surfaceMap[surface.value]] = { fill: "#204289" };
      if (findings) {
        styles[surfaceMap[surface.value]] = { fill: "#845c21" };
      }
    }
    return styles;
  };

  const handleSave = () => {
    const foundIndex = selectedTooths.findIndex(
      (val: any) => val.value === selectedTeeth
    );
    if (foundIndex >= 0) {
      const clonedData = JSON.parse(JSON.stringify(selectedTooths));
      clonedData[foundIndex] = {
        value: selectedTeeth,
        name: selectedTeeth,
        surface: modalToothsurface,
        teethNumber: selectedTeeth,
        findings,
      };
      setValue("selectedTooths", clonedData);
    } else {
      setValue("selectedTooths", [
        ...selectedTooths,
        {
          value: selectedTeeth,
          name: selectedTeeth,
          surface: modalToothsurface,
          teethNumber: selectedTeeth,
          findings,
        },
      ]);
    }
    setModalOpen(false);
  };

  const surfaceSlection = (surfaceType: any) => {
    const surfaceMap: any = getSurfaceMapping(selectedTeeth, true);
    const surfaceValue = surfaceMap[surfaceType];
    const data = { name: surfaceValue, value: surfaceValue };
    const foundTeeth =
      modalToothsurface.find((val: any) => val.value === surfaceValue) || {};
    if (foundTeeth.value) {
      setModalToothsurface(
        modalToothsurface.filter((val: any) => val.value !== surfaceValue)
      );
    } else {
      setModalToothsurface([...modalToothsurface, data]);
    }
  };

  const getSurfaceTeeth = (teeth: any) => {
    const selectedTeeth =
      selectedTooths.find((val: any) => val.value === teeth) || {};
    const styles = getSurfaceStyles(selectedTeeth.surface || [], teeth);
    if (surfacesWithoutCenter.includes(teeth)) {
      return (
        <TeethSurfaceWithoutCenter width={"100%"} teethSurfaceStyles={styles} />
      );
    } else {
      return <FullTeethSurface width={"100%"} teethSurfaceStyles={styles} />;
    }
  };

  const getTeethPath = (teeth: any) => {
    const selectedTeeth =
      selectedTooths.find((val: any) => val.value === teeth) || {};
    const file = `Teeth/${teeth}.svg`;
    let path = `Teeth Default/${file}`;
    if (Object.keys(selectedTeeth).length > 0) {
      path = `Teeth blue/${file}`;
      if (findings) {
        path = `Teeth brown/${file}`;
      }
    }
    return path;
  };

  const getModal = () => {
    return (
      <CustomModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        handleOpen={() => setModalOpen(true)}
        width={"597px"}
        height={"auto"}
      >
        <Box>
          <Box className="flex space-between">
            <Typography
              fontSize={"18px"}
              fontFamily={"poppins"}
              fontWeight={"600"}
              color={"#204289"}
            >
              Tooth {selectedTeeth}
            </Typography>
            <CloseIcon
              onClick={() => setModalOpen(false)}
              className="pointer"
            />
          </Box>
          <Box className="flex-center">
            {surfacesWithoutCenter.includes(selectedTeeth) ? (
              <TeethSurfaceWithoutCenter
                teethSurfaceStyles={teethSurfaceStyles}
                onClick={(e: any) =>
                  e.target.id &&
                  SURFACE_TEETH_ELEMENT_IDS.includes(e.target.id) &&
                  surfaceSlection(e.target.id)
                }
                width="30%"
              />
            ) : (
              <FullTeethSurface
                teethSurfaceStyles={teethSurfaceStyles}
                onClick={(e: any) =>
                  e.target.id &&
                  SURFACE_TEETH_ELEMENT_IDS.includes(e.target.id) &&
                  surfaceSlection(e.target.id)
                }
                width="30%"
              />
            )}
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ marginRight: "30px", borderStyle: "dashed" }}
            />
            <Box className="width50">
              <Box mt={5}>
                <CustomAutocomplete
                  multiple
                  value={modalToothsurface}
                  defaultValue={modalToothsurface}
                  onChange={(
                    event: React.SyntheticEvent,
                    value: any,
                    option: any
                  ) => setModalToothsurface(value)}
                  options={
                    surfacesWithCenter.includes(selectedTeeth)
                      ? TOOTH_SURFACES
                      : TOOTH_SURFACES.filter(
                          (val: any) => val.value !== "Central"
                        )
                  }
                  label="Tooth Surface"
                />
              </Box>
              <Box mt={2}>
                <Grid
                  container
                  flexDirection={"row"}
                  spacing={1}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <Grid item>
                    <CustomButton
                      title={"Cancel"}
                      handleButtonClick={() => setModalOpen(false)}
                      backgroundcolor={"#FFFFFF"}
                      bordercolor={"#204289"}
                      color={"#204289"}
                      width={110}
                    />
                  </Grid>
                  <Grid item>
                    <CustomButton
                      title={"Save"}
                      handleButtonClick={() => handleSave()}
                      backgroundcolor={"#204289"}
                      bordercolor={"#204289"}
                      color={"#fffff"}
                      width={110}
                      padding={7}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </CustomModal>
    );
  };

  const handleSelectTeeth = (teeth: any) => {
    const foundTeeth =
      selectedTooths.find((val: any) => val.value === teeth) || {};
    if (foundTeeth.value) {
      setValue(
        "selectedTooths",
        selectedTooths.filter((val: any) => val.value !== teeth)
      );
    } else {
      setValue("selectedTooths", [
        ...selectedTooths,
        { value: teeth, name: teeth, teethNumber: teeth, surface },
      ]);
    }
  };

  const handleToothClick = (teeth: any) => {
    const foundTeeth =
      selectedTooths.find((val: any) => val.value === teeth) || {};
    setModalToothsurface(foundTeeth.surface || []);
    setSelectedTeeth(teeth);
    setModalOpen(true);
  };

  const getTeeths = (teeths: any, reverse?: any) => {
    return (
      <>
        {teeths.map((teeth: any) => {
          return (
            <Box
              className={`flex ${
                reverse
                  ? "flex-direction-column-reverse"
                  : "flex-direction-column"
              } align-items-center space-between`}
            >
              <Box
                component="img"
                sx={{
                  height: "80px",
                  cursor: "pointer",
                }}
                alt={teeth + "alt"}
                src={require(`../../../app/assets/teeth/${getTeethPath(
                  teeth
                )}`)}
                onClick={() => handleSelectTeeth(teeth)}
              />
              {isSurfacesVisiable && (
                <Box
                  width={"60%"}
                  onClick={() => handleToothClick(teeth)}
                  sx={{ cursor: "pointer" }}
                >
                  {getSurfaceTeeth(teeth)}
                </Box>
              )}
              <Typography
                variant="subtitle2"
                sx={{ color: "#204289" }}
                gutterBottom
              >
                {teeth}
              </Typography>
            </Box>
          );
        })}
      </>
    );
  };

  const getPrimaryTeeth = () => (
    <Box className="flex flex-direction-column">
      <Box className="flex flex-direction-row">
        <Box
          className="flex"
          sx={{
            borderBottom: "2px dashed rgb(224 224 224)",
            borderRight: "2px dashed rgb(224 224 224)",
            paddingBottom: "10px",
            paddingRight: "10px",
          }}
        >
          {getTeeths(teeths51to55)}
        </Box>
        <Box
          className="flex"
          sx={{
            borderBottom: "2px dashed rgb(224 224 224)",
            paddingLeft: "10px",
            paddingBottom: "10px",
          }}
        >
          {getTeeths(teeths61to65)}
        </Box>
      </Box>
      <Box className="flex flex-direction-row">
        <Box className="flex" sx={{ paddingTop: "15px", paddingRight: "10px" }}>
          {getTeeths(teeths81to85, true)}
        </Box>
        <Box
          className="flex"
          sx={{
            borderLeft: "2px dashed rgb(224 224 224)",
            paddingTop: "15px",
            paddingLeft: "10px",
          }}
        >
          {getTeeths(teeths71to75, true)}
        </Box>
      </Box>
    </Box>
  );

  const gapStyle = !isSurfacesVisiable
    ? { gap1: { gap: "4.7px" }, gap2: { gap: "5px" } }
    : {};

  return (
    <Box>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={teethType}
        onChange={handleTeethTypeChange}
        sx={{
          "& .MuiTypography-root": { color: "#204289" },
          "& .MuiButtonBase-root": { color: "#204289" },
        }}
      >
        <FormControlLabel value="custom" control={<Radio />} label="Custom" />
        <FormControlLabel
          value="fullMouth"
          control={<Radio />}
          label="Full Mouth"
        />
        <FormControlLabel
          value="upperTeeth"
          control={<Radio />}
          label="Upper Teeth"
        />
        <FormControlLabel
          value="lowerTeeth"
          control={<Radio />}
          label="Lower Teeth"
        />
      </RadioGroup>
      <br />
      <Box className="flex flex-direction-column align-items-center">
        <Box className="flex flex-direction-column">
          <Box className="flex flex-direction-row">
            <Box
              className="flex"
              sx={{
                borderBottom: "2px dashed rgb(224 224 224)",
                borderRight: "2px dashed rgb(224 224 224)",
                paddingBottom: "10px",
                paddingRight: "10px",
                ...(gapStyle.gap1 || {}),
              }}
            >
              {getTeeths(teeths11to18)}
            </Box>
            <Box
              className="flex"
              sx={{
                borderBottom: "2px dashed rgb(224 224 224)",
                paddingLeft: "10px",
                paddingBottom: "10px",
                ...(gapStyle.gap2 || {}),
              }}
            >
              {getTeeths(teeths21to28)}
            </Box>
          </Box>
          <Box className="flex flex-direction-row">
            <Box
              className="flex"
              sx={{ paddingTop: "15px", paddingRight: "10px" }}
            >
              {getTeeths(teeths41to48, true)}
            </Box>
            <Box
              className="flex"
              sx={{
                borderLeft: "2px dashed rgb(224 224 224)",
                paddingTop: "15px",
                paddingLeft: "10px",
              }}
            >
              {getTeeths(teeths31to38, true)}
            </Box>
          </Box>
          {getModal()}
        </Box>
        {isPrimaryTeeth && (
          <Box sx={{ width: "60%", marginTop: "30px" }}>
            {getPrimaryTeeth()}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DentalChart;
