import { apiRequest } from "../services/requests";
import { URLS, IMAGE_MIME_TYPES } from "../utilities/constants";

function getPresignedUrl(filePath: any, file: any, documentType: any) {
  const { GET_PRESIGNED_URL } = URLS;
  return new Promise((resolve, reject) => {
    const filepath = `${filePath}/${Date.now()}-${file.name}`;
    const payload = {
      ContentType: file.type,
      fileName: filepath,
    };
    apiRequest({ url: GET_PRESIGNED_URL, data: payload, method: "POST" })
      .then(async (response: any) => {
        const { uploadURL, fileName } = response;
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", file.type);
        fetch(uploadURL, {
          method: "PUT",
          headers: myHeaders,
          body: file,
          redirect: "follow",
        })
          .then((response) => response.text())
          .then((result) =>
            resolve({
              documentName: file.name,
              documentType:
                documentType !== "scheme"
                  ? IMAGE_MIME_TYPES.includes(file.type)
                    ? "photo"
                    : "document"
                  : documentType,
              documentPath: filepath,
              fileType: file.type,
            })
          )
          .catch((error) => reject(error));
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function uploadToS3(filePath: any, files: any, documentType: any) {
  let promises: any = [];
  for (let file of files) {
    promises.push(getPresignedUrl(filePath, file, documentType));
  }
  return new Promise((resolve, reject) => {
    Promise.all(promises)
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// export function downloadFileFromS3(
//   filePath: any,
//   fileName: any = "",
//   download: any = false
// ) {
//   const { GET_DOWNLOAD_URL } = URLS;
//   return new Promise((resolve, reject) => {
//     apiRequest({
//       url: GET_DOWNLOAD_URL,
//       data: { fileName: filePath },
//       method: "POST",
//     })
//       .then((response) => {
//         const { uploadURL } = response;
//         if (download) {
//           fetch(uploadURL).then((res) => {
//             res.blob().then((blob) => {
//               const url = window.URL.createObjectURL(new Blob([blob]));
//               const link = document.createElement("a");
//               link.href = url;
//               link.setAttribute("download", fileName);
//               document.body.appendChild(link);
//               link.click();
//               link.parentNode && link.parentNode.removeChild(link);
//               resolve(true);
//             });
//           });
//         } else {
//           resolve({ downloadURL: uploadURL });
//         }
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// }
export default uploadToS3;
