import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import { apiRequest } from "../../../app/services/requests";
import { useNavigate, useParams } from "react-router-dom";

interface Treatment {
  id: number;
  code: string;
  name: string;
  price: number;
}

interface AllServiceListProps {
  fetchServicesAgain?: boolean;
  onSelectedTreatments: (selectedIds: GridRowId[]) => void;
  tabName?: string;
}

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "code",
    headerName: "Code",
    width: 150,
    editable: false,
  },
  {
    field: "name",
    headerName: "Name",
    width: 150,
    editable: false,
  },
  {
    field: "aliasName",
    headerName: "Alias Name",
    width: 150,
    editable: false,
  },
  {
    field: "price",
    headerName: "Price",
    type: "number",
    width: 110,
    editable: false,
  },
];

const AllServiceList: React.FC<AllServiceListProps> = (props) => {
  const { fetchServicesAgain = false, onSelectedTreatments, tabName } = props;
  const [treatmentList, setTreatmentList] = useState<Treatment[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const { hospitalId } = useParams<{ hospitalId: any }>();
  const navigate = useNavigate();
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [allSelectedIds, setAllSelectedIds] = useState<GridRowId[]>([]);

  useEffect(() => {
    if (hospitalId || fetchServicesAgain) {
      getAllTreatmentList(hospitalId, page, pageSize, tabName);
    }
  }, [hospitalId, tabName, fetchServicesAgain, page, pageSize]);

  const getAllTreatmentList = (
    hospitalId: string,
    page: number,
    pageSize: number,
    tabName?: string
  ) => {
    const skip = page * pageSize;
    apiRequest({
      url:
        tabName === "all-services"
          ? `clinic/${hospitalId}/treatment/list`
          : `clinic/${hospitalId}/treatment/assigned-list`,
      data: {
        skip: skip,
        limit: pageSize,
      },
      method: "POST",
    })
      .then((res: any) => {
        if (res) {
          const rowData: Treatment[] = res?.results?.map(
            (result: any, index: number) => {
              const treatment =
                tabName === "all-services"
                  ? {
                      id: result.id || index + 1,
                      code: result.code || "Unknown",
                      name: result.name || "Unknown",
                      aliasName: result?.aliasName ?? "Unknown",
                      price: result.price || 0,
                    }
                  : {
                      id: result.treatment?.id || index + 1,
                      code: result?.treatment?.code || "Unknown",
                      name: result?.treatment?.name || "Unknown",
                      aliasName: result?.aliasName || "Unknown",
                      price: result?.treatment?.price || 0,
                    };
              return treatment;
            }
          );
          setTreatmentList(rowData);
          setTotalRecords(res.totalCount || 0);

          // Update selectionModel to include previously selected IDs
          const updatedSelectionModel = rowData
            .filter((row: Treatment) => allSelectedIds.includes(row.id))
            .map((row: Treatment) => row.id);
          setSelectionModel(updatedSelectionModel);
        }
      })
      .catch((err: any) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          navigate("/login");
        }
      });
  };

  const handleRowSelectionChange = (
    rowSelectionModel: GridRowSelectionModel
  ) => {
    const selectedIds = Array.isArray(rowSelectionModel)
      ? rowSelectionModel
      : [];

    // Update the selection model
    setSelectionModel(selectedIds);
    onSelectedTreatments && onSelectedTreatments(selectedIds);

    // Update allSelectedIds
    setAllSelectedIds((prevSelectedIds) => {
      const newSelectedIds = selectedIds.filter(
        (id) => !prevSelectedIds.includes(id)
      );
      const deselectedIds = prevSelectedIds.filter(
        (id) => !selectedIds.includes(id)
      );

      // Add new selections and remove deselections
      return [
        ...prevSelectedIds.filter((id) => !deselectedIds.includes(id)), // Keep previous selections that were not deselected
        ...newSelectedIds, // Add new selections
      ];
    });
  };

  // useEffect(() => {
  //   onSelectedTreatments(allSelectedIds);
  // }, [allSelectedIds, onSelectedTreatments]);

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={treatmentList}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
        paginationMode="server"
        rowCount={totalRecords}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        paginationModel={{ page, pageSize }}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={handleRowSelectionChange}
        onPaginationModelChange={(paginationModel) => {
          setPage(paginationModel.page);
          setPageSize(paginationModel.pageSize);
        }}
      />
    </Box>
  );
};

export default AllServiceList;
