// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

table.examination-table, .examination-table th, .examination-table td {
    border: 1px dashed rgb(224 224 224);
    border-collapse: collapse;
    padding: 10px;
}

.examination-table thead {
  background-color: #F8F8F8;
}

.charttype-div {
  background: #60CEBD;
  color: #fff;
  padding: 4px;
  font-size: 9px;
  transform: rotate(270deg);
  position: relative;
  width: 100%;
  right: 22px;
  top: 19px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/CasesAndClaims/Examination/index.css"],"names":[],"mappings":"AAAA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA,YAAY;AACZ;EACE,0BAA0B;AAC5B;;AAEA;IACI,mCAAmC;IACnC,yBAAyB;IACzB,aAAa;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,cAAc;EACd,yBAAyB;EACzB,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,SAAS;AACX","sourcesContent":["input::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Firefox */\ninput[type=number] {\n  -moz-appearance: textfield;\n}\n\ntable.examination-table, .examination-table th, .examination-table td {\n    border: 1px dashed rgb(224 224 224);\n    border-collapse: collapse;\n    padding: 10px;\n}\n\n.examination-table thead {\n  background-color: #F8F8F8;\n}\n\n.charttype-div {\n  background: #60CEBD;\n  color: #fff;\n  padding: 4px;\n  font-size: 9px;\n  transform: rotate(270deg);\n  position: relative;\n  width: 100%;\n  right: 22px;\n  top: 19px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
