import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

interface ICustomModalProps {
  children: any;
  handleOpen?: any;
  handleClose?: any;
  open: any;
  width?: any;
  height?: any;
  zIndex?: any;
}

const CustomModal = (props: ICustomModalProps) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#fff",
    borderRadius: "8px",
    boxShadow: 24,
    outline: "none",
    width: props.width ? props.width : 400,
    height: props.height ? props.height : 400,
    p: 3,
    overflow: "auto",
  };

  return (
    <>
      <div>
        <Modal
          // sx={{ zIndex: props.zIndex ? props.zIndex : "11" }}
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>{props.children}</Box>
        </Modal>
      </div>
    </>
  );
};

export default CustomModal;
