const ServicesTreatmentsCoveredList = (props: any) => {
  const listStyle = {
    listStyleType: "square", // Remove default list-style-type (disc/circle/square)
    padding: 0, // Remove default padding
  };

  const listItemStyle = {
    marginBottom: "8px", // Adjust margin between list items
  };

  return (
    <ul style={listStyle}>
      <li
        style={listItemStyle}
      >{`Some treatments once finished on a tooth cannot be done for 1yr, 3yrs, 5 yrs etc.`}</li>
      <li style={listItemStyle}>{`Cap done cannot be claimed for 5 years.`}</li>
      <li style={listItemStyle}>
        I{`Denture cannot be repeated within 5 years.`}
      </li>
      <li style={listItemStyle}>
        I{`Filling cannot be claimed within 2 years.`}
      </li>
      <li style={listItemStyle}>I{`X rays are fixed per year.`}</li>
    </ul>
  );
};

export default ServicesTreatmentsCoveredList;
