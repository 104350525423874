import CustomDatePicker from ".";
import { useForm, Controller } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const NormalDatePicker = () => {


const schema = object ({
    datePicker: string().ensure().required("Date is required"),
  
  })
  const { control} = useForm({
    resolver: yupResolver(schema),
  })
  return(
    <>

<Controller
  name={"datePicker"}
  rules={{ required: true }}
  control={control}
  render={({ field, fieldState: { error } }) => (
    <CustomDatePicker
      {...field}
    //   label={"End Date Of Birth"}
    onChange = {() => console.log()}
      error={!!error}
      helperText={error ? error.message : ""}
      disablePast={true}
    />
  )}
/>
</>
)
}

export default NormalDatePicker;
