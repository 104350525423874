export interface IRequestResponse {
  id: number;
  enableCaseHistory: boolean;
  startsAt: string;
  endsAt: string;
  caseHistory?: any;
  panelDoctorStatus: string;
  srPanelDoctorStatus: string | null;
  localAdminStatus: string | null;
  status: string;
  createdAt: string;
  client: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  };
  masterPlan: {
    id: number;
    type: string;
    code: string;
    name: string;
  };
}

export const statusApprovedRejected = {
  "panel-doctor": "panelDoctorStatus",
  "sr-panel-doctor": "srPanelDoctorStatus",
  "local-admin": "localAdminStatus",

  "panel-doctor-comment": "panelDoctorComment",
  "sr-panel-doctor-comment": "srPanelDoctorComment",
  "local-admin-comment": "localAdminComment",
} as any;
