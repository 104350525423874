import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Link as RouterLink } from "react-router-dom";
import CustomTabs from "../../components/CustomTabs";
import ClientPlanDetails from "./client-plan-details";
import ClientAppointments from "./client-appointments";
import ClientCases from "./client-cases";
import { useNavigate, useParams } from "react-router";
import ClientClaims from "./client-claims";
import ClientProfileCard from "../../components/ClientProfileCard";
import Breadcrumb from "../../components/Breadcrumb";
import IRATypography from "../../components/ira-typography/ira-typography";
import ClientDetailsCounter from "./client-details-counter";
import { apiRequest } from "../../app/services/requests";

const ClientDetails = () => {
  const { clientId, currentTab } = useParams();
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const [clientDetails, setClientDetails] = useState("") as any;

  useEffect(() => {
    getClientDetails();
  }, []);

  const getClientDetails = () => {
    apiRequest({
      url: `client/${clientId}`,
      method: "GET",
    })
      .then((res: any) => {
        if (res) {
          setClientDetails(res);
          if (res?.id) {
            getClientCurrentPlan(res?.id);
          }
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  const getClientCurrentPlan = (clientId: any) => {
    apiRequest({
      url: `plan/current/${clientId}`,
      method: "GET",
    })
      .then((res: any) => {
        if (res) {
          console.log("current plan", res);
          setClientDetails({ ...res, planId: res?.masterPlanId });
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
        }
      });
  };

  const tabs = [
    {
      index: 0,
      id: "plan-details",
      name: "Plan Details",
      component: (
        <ClientPlanDetails clientDetails={{ ...clientDetails, clientId }} />
      ),
    },
    {
      index: 1,
      id: "appointments",
      name: "Appointments",
      component: <ClientAppointments />,
    },
    {
      index: 2,
      id: "cases",
      name: "Cases",
      component: <ClientCases />,
    },
    {
      index: 3,
      id: "claims",
      name: "Claims",
      component: <ClientClaims />,
    },
  ];

  let data = [
    {
      id: 1,
      heading: "Plans",
      remainingdays: "22",
      color: "#219FFF4D",
    },
    {
      id: 2,
      heading: "Claims",
      claimsCount: "05",
      color: "#68D4B44D",
    },
    {
      id: 3,
      heading: "Cases",
      claimsCount: "08",
      color: "#FF98A54D",
    },
  ];

  useEffect(() => {
    if (currentTab) {
      const targetTab: any = tabs.find((tab) => tab.id === currentTab);
      setSelectedTab(targetTab?.index);
    }
  }, [currentTab]);

  const navigate = useNavigate();

  const breadcrumbs = [
    <RouterLink to="/Dashboard" style={{ textDecoration: "none" }} key="1001">
      <IRATypography
        key="2"
        textVariant="h12WithSemiBold"
        sx={{ fontSize: 14, color: "#222222" }}
      >
        Home
      </IRATypography>
    </RouterLink>,
    <IRATypography
      key="3"
      textVariant="h12WithSemiBold"
      sx={{ fontSize: 14, color: "#222222" }}
    >
      Client Profile
    </IRATypography>,
  ];

  return (
    <Container maxWidth="lg" sx={{ padding: "0px !important" }}>
      <Breadcrumb
        title={`Client Profile`}
        key="case-list-breadcrumb"
        breadcrumbsArr={breadcrumbs}
      />
      <Box>
        <Grid container spacing={2}>
          {/* 3 columns */}
          <Grid item xs={3.5} mt={1}>
            <Box>
              <ClientProfileCard clientDetails={clientDetails} />
            </Box>
          </Grid>
          {/* 9 columns */}

          <Grid item xs={8.5} mt={3}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap={2}
              mb={2.5}
            >
              {data?.map((item) => {
                return <ClientDetailsCounter CounterData={item} />;
              })}
            </Box>
            <Paper>
              {/* Content for the second column */}
              <CustomTabs
                tabOptions={tabs}
                currentTab={Number(selectedTab)}
                onTabChange={(value: any) => {
                  navigate(`/client/${clientId}/view/${value}`);
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ClientDetails;
