// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.case-list_home_name__huwda {
    color: #222222;
    size: 12px;
}
.case-list_table_heading_layout__IGD4G {
    background-color: #E7E7E780;
}
.case-list_cases_table_status_claim__wyR99{
color: #CB8C00;
}



`, "",{"version":3,"sources":["webpack://./src/screens/CasesAndClaims/case-list.module.css"],"names":[],"mappings":";;AAEA;IACI,cAAc;IACd,UAAU;AACd;AACA;IACI,2BAA2B;AAC/B;AACA;AACA,cAAc;AACd","sourcesContent":["\n\n.home_name {\n    color: #222222;\n    size: 12px;\n}\n.table_heading_layout {\n    background-color: #E7E7E780;\n}\n.cases_table_status_claim{\ncolor: #CB8C00;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home_name": `case-list_home_name__huwda`,
	"table_heading_layout": `case-list_table_heading_layout__IGD4G`,
	"cases_table_status_claim": `case-list_cases_table_status_claim__wyR99`
};
export default ___CSS_LOADER_EXPORT___;
