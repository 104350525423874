import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import CustomButton from "../../components/Button";
import AppointmentDetailsCard from "../../components/AppointmentDetailsCard";
import Breadcrumb from "../../components/Breadcrumb";
import { Link as RouterLink } from "react-router-dom";
import IRATypography from "../../components/ira-typography";
import CaseHistoryDetails from "../CasesAndClaims/case-history-details";

const AppointmentDetails = () => {
  const breadcrumbs = [
    <RouterLink to="/Dashboard" style={{ textDecoration: "none" }}>
      <IRATypography
        key="2"
        textVariant="h12WithSemiBold"
        fontSize="12px"
        color="#222222"
      >
        Home
      </IRATypography>
    </RouterLink>,
    <IRATypography
      key="2"
      textVariant="h12WithSemiBold"
      fontSize="12px"
      color="#222222"
    >
      Appointment View
    </IRATypography>,
  ];

  return (
    <Container maxWidth="lg" sx={{ padding: "0 !important" }}>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        mb={1}
      >
        <Breadcrumb title={`Appointment View`} breadcrumbsArr={breadcrumbs} />
      </Box>
      <Box>
        <Grid container spacing={2}>
          {/* 3 columns */}
          <Grid item xs={3.5}>
            <Box>
              <AppointmentDetailsCard />
            </Box>
          </Grid>
          {/* 9 columns */}
          <Grid item xs={8.5}>
            {/* Card 1 */}
            <Paper sx={{ padding: "15px", height: "100%" }}>
              <>
                <CaseHistoryDetails />
              </>
            </Paper>
          </Grid>
        </Grid>
        <></>
      </Box>
    </Container>
  );
};

export default AppointmentDetails;
