import React, { useEffect, useState } from "react";
import { IRATable } from "../../components/ira-table/ira-table";
import { IRATableColumnProps } from "../../components/ira-table/ira-table.interfaces";
import NameCell from "../../components/TableComponent/name-cell/name-cell";
import ActionCell from "../../components/TableComponent/action-cell";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import CustomButton from "../../components/Button";
import { apiRequest } from "../../app/services/requests";
import CustomDialog from "../../components/CustomDialog";
import FamilyCaseHistory from "./family-case-history";

const FamilyMemberList = (props: any) => {
  const { showOnlyList = true, hideBreadScrum } = props;
  const navigate = useNavigate();
  const { clientId, planId } = useParams<any>();
  const [clientMemberList, setClientMemberList] = useState({
    results: [],
    totalCount: 0,
  });
  const [searchTerm, setSearchTerm] = React.useState("");
  const [openCaseHistoryModel, setOpenCaseHistoryModel] = useState({
    open: false,
    data: {} as any,
  });

  const [clientPlanDetails, setClientPlanDetails] = useState("") as any;

  useEffect(() => {
    getCurrentPlanById(planId);
  }, [planId]);

  const getCurrentPlanById = (planId?: any) => {
    apiRequest({
      url: `plan/${planId}`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          setClientPlanDetails(res);
        }
      })
      .catch((err) => {
        // if (err?.statusCode === 403 || err?.statusCode === 401) {
        //   if (navigate) {
        //     navigate("/login"); // Navigate to "/login" route
        //   }
        // }
      });
  };

  useEffect(() => {
    getClientFamilyMembers();
  }, [searchTerm]);

  const getClientFamilyMembers = () => {
    apiRequest({
      url: `plan/${planId}/client-family-members`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          setClientMemberList(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  const tableColumns = () =>
    [
      {
        name: "sno",
        label: "S.No",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "name",
        label: "Name",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "relation",
        label: "Relation",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "dob",
        label: "DOB",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "gender",
        label: "Gender",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "caseHistoryStatus",
        label: "Case History Status",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "editAction",
        label: "Action",
        component: (props: any) => {
          return ActionCell({
            button: true,
            btnVariant: "primary",
            btnSize: "smallest",
            disabled: false,
            infoMessage: null,
            label: "Edit",
            onClick: () => {
              setOpenCaseHistoryModel({
                open: true,
                data: { ...props?.data },
              });
            },
            ...props,
          } as any);
        },
      } as any,
    ] as Array<IRATableColumnProps>;

  const tableData = () => {
    return clientMemberList?.results?.map((row: any) => {
      return {
        ...row,
        sno: {
          name: row?.id,
        },
        name: {
          name: `${row?.name ?? ""}`,
        },
        dob: {
          name: `${row?.dob}`,
        },
        gender: {
          name: `${row?.gender}`,
        },
        relation: {
          name: `${row?.relation}`,
        },
        caseHistoryStatus: {
          name: `${row?.status}`,
        },
        editAction: {
          data: {
            ...row,
          },
        },
      };
    });
  };

  const handleClose = (flag?: boolean) => {
    if (flag) {
      getClientFamilyMembers();
    }
    setOpenCaseHistoryModel({ open: false, data: {} });
  };

  const createOrderForThePlan = () => {
    apiRequest({
      url: `order/create`,
      data: {
        planId: Number(planId),
        clientId: Number(clientId),
      },
      method: "POST",
    })
      .then((res) => {
        if (res) {
          navigate(`/client/${clientId}/plan/summary/3/${res?.id}`);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          justifyContent: "space-between",
          pt: "20px",
        }}
      >
        <Box>{"Client Case History"}</Box>

        <CustomButton
          onClick={() => {
            setOpenCaseHistoryModel({
              open: true,
              data: { isClientCaseHistory: true },
            });
          }}
          title={"Add Client Case History"}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          pt: "10px",
        }}
      >
        <Box
          width={"100%"}
          bgcolor={"#E1F1FA"}
          borderRadius={"11px"}
          padding='16px'
          display={"flex"}
          gap={8}
        >
            <Box display='flex' flexDirection='column' gap={1}>
            <Typography variant="body1" fontWeight={600}>Client Name</Typography>
            <Typography variant="body2" >
              {clientPlanDetails?.client?.firstName}{" "}
              {clientPlanDetails?.client?.lastName}
            </Typography>
            </Box>
            <Box display='flex' flexDirection='column' gap={1}>
            <Typography variant="body1" fontWeight={600}>
              ID
            </Typography>
            <Typography variant="body2" >
              {clientPlanDetails?.client?.id}
            </Typography>
            </Box>
            <Box display='flex' flexDirection='column' gap={1}>
            <Typography variant="body1" fontWeight={600}>
              Approval Status
            </Typography>
            <Typography variant="body2" >
              {clientPlanDetails?.caseHistory?.panelDoctorStatus}
            </Typography>
            </Box>
            <Box display='flex' flexDirection='column' gap={1}>
            <Typography variant="body1" fontWeight={600}>
              Comment
            </Typography>
            <Typography variant="body2" >
              {clientPlanDetails?.caseHistory?.panelDoctorComment}
            </Typography>
            </Box>
            <Box display='flex' flexDirection='column' gap={1} alignItems='center'>
            <Typography variant="body1" fontWeight={600}>
              Action
            </Typography>
            <Typography variant="body2" >
              <Button
               sx={{ padding: '0px'}}
               onClick={
                () => {
                  setOpenCaseHistoryModel({
                    open: true,
                    data: { isClientCaseHistory: true, ...clientPlanDetails },
                  });
                }
              }>{'Edit'}</Button>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            pt: "10px",
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          pt: "10px",
        }}
      >
        <Box sx={{ display: "flex", gap: "8px", pt: "20px" }}>
          <CustomButton
            onClick={() => {
              setOpenCaseHistoryModel({ open: true, data: {} });
            }}
            title={"Add Family Member"}
          />
        </Box>
      </Box>

      <Box sx={{ pt: "5px" }}>
        <Card>
          <IRATable
            columns={tableColumns()}
            data={tableData()}
            onSort={(name: string, order: string) =>
              alert(`sort requested for ${name} with order ${order}`)
            }
          />
        </Card>
        <Grid
          container
          flexDirection={"row"}
          spacing={0.5}
          justifyContent={"end"}
          mt={2}
        >
          <Grid item>
            <CustomButton
              border="1px solid #000000"
              color="#000000"
              title="Back"
              bgColor="#ffffff"
              handleButtonClick={() =>
                navigate(`/client/${clientId}/plan/standard/1/${planId}`)
              }
              borderRadius="5px"
            />
          </Grid>
          <Grid item>
            <CustomButton
              border="1px solid #000000"
              color="#000000"
              title="Cancel"
              bgColor="#ffffff"
              handleButtonClick={() => navigate("/users/all")}
              borderRadius="5px"
            />
          </Grid>
          <Grid item>
            <CustomButton
              type="button"
              title={"Next"}
              backgroundcolor={"#204289"}
              bordercolor={"#204289"}
              color={"#fffff"}
              width={110}
              padding={7}
              handleButtonClick={() => {
                createOrderForThePlan();
              }}
            />
          </Grid>
        </Grid>
      </Box>
      {openCaseHistoryModel.open && (
        <CustomDialog
          fullWidth={true}
          open={openCaseHistoryModel.open}
          handleClose={() => setOpenCaseHistoryModel({ open: false, data: {} })}
          title={
            openCaseHistoryModel?.data?.isClientCaseHistory
              ? "Client Case history"
              : "Family with Case history"
          }
          maxWidth={"lg"}
        >
          <FamilyCaseHistory
            editData={openCaseHistoryModel?.data}
            handleCancel={(flag?: boolean) => handleClose(flag)}
            dialogData={{ ...openCaseHistoryModel?.data }}
          />
        </CustomDialog>
      )}
    </>
  );
};

export default React.memo(FamilyMemberList);
