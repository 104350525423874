import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/Button";
import CustomDialog from "../../components/CustomDialog";
import InfrastructureForm from "./infrastructure-form";
import { apiRequest } from "../../app/services/requests";
import { useNavigate, useParams } from "react-router-dom";

const Infrastructure = () => {
  const [openSelectClientDialog, setOpenSelectClientDialog] = useState<any>({
    open: false,
    data: {},
  });

  const [infrastructureData, setInfrastructureData] = useState("") as any;
  const navigate = useNavigate();
  const { hospitalId } = useParams<any>();

  const handleAddDoctorClick = () => {
    setOpenSelectClientDialog({ open: true });
  };

  useEffect(() => {
    if (hospitalId) {
      getInfrastructureByClinic(hospitalId);
    }
  }, [hospitalId]);

  const getInfrastructureByClinic = (hospitalId: any) => {
    apiRequest({
      url: `clinic/${hospitalId}/infrastructure/list`,
      data: {
        // userType: "superAdmin",
      },
      method: "POST",
    })
      .then((res: any) => {
        if (res) {
          setInfrastructureData(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  return (
    <div>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "95%",
          marginLeft: "3%",
          // fontSize: "10px",
        }}
        mr={2}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            gap: "8px",
          }}
        >
          <CustomButton
            width={"170px"}
            height={"35px"}
            // fontSize={"10px"}
            bgColor={"rgba(92, 78, 255, 1)"}
            onClick={() => handleAddDoctorClick()}
            title={
              Boolean(infrastructureData?.data?.length)
                ? "Edit Infrastructure"
                : "Add Infrastructure"
            }
          />
        </Box>
      </Box>
      <Box p={2} style={{ width: "95%", marginLeft: "3%" }}>
        <Box my={1}>
          {infrastructureData?.data?.map((item: any) => {
            return (
              <>
                <Grid item xs={12}>
                  <Box display={"flex"} alignItems={"center"}>
                    <Box>
                      <Typography color="text.primary">
                        {" "}
                        Area in SqFt : <b>{item?.areaInSquareFeet}</b>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Divider sx={{ my: 3 }} />
                <Grid item xs={12}>
                  <Box display={"flex"} alignItems={"center"}>
                    <Box>
                      <Typography color="primary">
                        {" "}
                        Infrastructure Details :{" "}
                      </Typography>
                      <br></br>
                    </Box>
                  </Box>
                </Grid>
                <Grid container spacing={2}>
                  <Divider sx={{ my: 1 }} /> {/* Adjust margin as needed */}
                  <Grid item xs={12}>
                    <Box display={"flex"} alignItems={"center"}>
                      <Box>
                        <Typography color={"text.secondary"}>
                          {" "}
                          Air Conditioned :{" "}
                          <strong>
                            {item?.isAirConditioned === true ? "true" : "false"}
                          </strong>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display={"flex"} alignItems={"center"}>
                      <Box>
                        <Typography color={"text.secondary"}>
                          {" "}
                          Power Backup :{" "}
                          <strong>
                            {item?.isPowerBackup === true ? "true" : "false"}{" "}
                          </strong>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display={"flex"} alignItems={"center"}>
                      <Box>
                        <Typography color={"text.secondary"}>
                          {" "}
                          Wheel Chair Access :{" "}
                          <strong>
                            {item?.isWheelChairAccess === true
                              ? "true"
                              : "false"}{" "}
                          </strong>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display={"flex"} alignItems={"center"}>
                      <Box>
                        <Typography color={"text.secondary"}>
                          {" "}
                          Operation Theater Attached Hospital :{" "}
                          <strong>
                            {item?.isOperationTheaterAttachedHospital === true
                              ? "true"
                              : "false"}
                          </strong>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display={"flex"} alignItems={"center"}>
                      <Box>
                        <Typography color={"text.secondary"}>
                          {" "}
                          Ambulance / Transport Facility :{" "}
                          <strong>
                            {item?.isAmbulanceOrTransportAvailable === true
                              ? "true"
                              : "false"}{" "}
                          </strong>
                        </Typography>
                      </Box>
                      {/* <Typography>{`10000`}</Typography> */}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display={"flex"} alignItems={"center"}>
                      <Box>
                        <Typography color={"text.secondary"}>
                          Clinic ID : <strong>{item?.clinicId}</strong>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <br />
                  <br />
                  <Grid item xs={12}>
                    <Typography color={"text.secondary"}>
                      Total Count :{" "}
                      <strong>{infrastructureData?.totalCount}</strong>
                    </Typography>
                  </Grid>
                </Grid>
              </>
            );
          })}
        </Box>
      </Box>

      {openSelectClientDialog.open && (
        <CustomDialog
          width={"850px"}
          open={openSelectClientDialog.open}
          handleClose={() =>
            setOpenSelectClientDialog({ open: false, data: {} })
          }
          title={
            infrastructureData?.data?.length
              ? "Edit Infrastructure"
              : "Add Infrastructure"
          }
          maxWidth={"lg"}
        >
          <Box>
            <InfrastructureForm
              onClose={() =>
                setOpenSelectClientDialog({ open: false, data: {} })
              }
              navigateTo={(data: string) => {}}
              editData={
                infrastructureData?.data?.length
                  ? infrastructureData?.data[0]
                  : {}
              }
              onFetchInfrastructure={() =>
                getInfrastructureByClinic(hospitalId)
              }
              isEdit={infrastructureData?.data?.length ? true : false}
            />
          </Box>
        </CustomDialog>
      )}
    </div>
  );
};

export default Infrastructure;
