import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { apiRequest } from "../../app/services/requests";
import ActionCell from "../../components/TableComponent/action-cell/action-cell";
import NameCell from "../../components/TableComponent/name-cell/name-cell";
import CustomDialog from "../../components/CustomDialog";
import SelectClient from "../../components/SelectClient";
import style from "./case-list.module.css";
import CustomButton from "../../components/Button";
import { IRATable } from "../../components/ira-table/ira-table";
import EditTreatment from "./edit-treatment-planning";
import DoctorsCell from "../Doctor/dococtors-data";

interface ISelectDialog {
  open: boolean;
  data: any;
}

const TreatmentPlanning = ({ caseDetails, onClose }: any) => {
  // console.log(caseDetails);
  const [treatmentDetails, setTreatmentDetails] = useState<any[]>([]);
  // console.log();
  // const { clientId } = useParams();
  const navigate = useNavigate();
  const [openSelectClientDialog, setOpenSelectClientDialog] =
    useState<ISelectDialog>({
      open: false,
      data: {},
    });
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (caseDetails && caseDetails?.examinationId) {
      getExaminationById(caseDetails?.examinationId);
    }
  }, [caseDetails?.examinationId]);

  const getExaminationById = (examinationId: string) => {
    apiRequest({
      url: `case/examination/${examinationId}/treatment-plannings`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          setTreatmentDetails(res.data);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          navigate("/login");
        }
      });
  };

  const handleSubmit = () => {
    // console.log("formData", data);

    apiRequest({
      url: `case/examination/${caseDetails?.examinationId}/treatment-plannings/update`,
      data: treatmentDetails,
      method: "PATCH",
    })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {});
  };

  const activityTableColumns: any = [
    {
      name: "teethNumber",
      label: "Teeth Number",
      component: NameCell,
      sortable: false,
      style: { width: "25%" },
    },
    {
      name: "treatmentName",
      label: "Treatment Name",
      component: NameCell,
      sortable: false,
      style: { width: "25%" },
    },
    {
      name: "doctorId",
      label: "Doctor",
      component: DoctorsCell,
      sortable: false,
      style: { width: "25%" },
    },

    {
      name: "status",
      label: "Status",
      component: NameCell,
      sortable: false,
      style: { width: "25%" },
    },
    {
      name: "action",
      label: "Action",
      component: (props: any) => {
        return ActionCell({
          button: true,
          btnVariant: "primary",
          btnSize: "smallest",
          disabled: false,
          infoMessage: null,
          label: "Edit",
          onClick: () => {
            navigate(`/cases/patient/:patientId/:caseId/edit`);
          },
          ...props,
        });
      },
    },
  ];

  const activityTableData = () => {
    return treatmentDetails.map((row: any) => ({
      ...row,
      investigationId: {
        name: row.id,
      },
      treatmentName: {
        name: row.treatment.treatmentName,
      },
      doctorName: {
        name: `${row.doctorId}` ?? "N/A",
      },
      teethNumber: {
        name: `${row.teethNumber}`,
      },
      status: {
        name: `${row.status ?? "Active"}`,
      },
      action: {
        data: {
          id: row.id,
          status: "reviewed",
          project: {
            id: row.id,
          },
        },
      },
    }));
  };

  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{ padding: 2 }}>
              <IRATable
                className={style.table_heading_layout}
                key={"case-examination-table"}
                columns={activityTableColumns}
                data={activityTableData()}
                onSort={(name: string, order: string) =>
                  alert(`Sort requested for ${name} with order ${order}`)
                }
              />

              {openSelectClientDialog.open && (
                <CustomDialog
                  width={"400px"}
                  open={openSelectClientDialog.open}
                  handleClose={() =>
                    setOpenSelectClientDialog({ open: false, data: {} })
                  }
                  title={"Select Client"}
                  maxWidth={"lg"}
                >
                  <Box>
                    <SelectClient
                      onClose={() =>
                        setOpenSelectClientDialog({ open: false, data: {} })
                      }
                      navigateTo={(data: string) => {
                        navigate(`/cases/patient/121213/add`);
                      }}
                    />
                  </Box>
                </CustomDialog>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box display={"flex"} justifyContent={"end"} gap={"5px"} padding={"10px"}>
        <CustomButton title={"Cancel"} type={"button"} borderRadius="5px" />
        <CustomButton
          handleButtonClick={handleSubmit}
          title={"Submit"}
          borderRadius="5px"
        />
      </Box>
      {check && <EditTreatment />}
    </>
  );
};

export default TreatmentPlanning;
