export const styles = {
  breadcrumbSubtext: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: "primary.main",
    textDecoration: "none",
  },
  screenContainer: {
    marginTop: 2,
  },
  cardHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rowData: {
    fontFamily: "Poppins",
    fontSize: 12,
    color: "primary.main",
  },
  patientName: {
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 500,
    color: "#021025",
  },
  avatarImage: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  chairColor: {
    color: "#E5050B",
    fontSize: 12,
  },
  chairColorText: {
    fontFamily: "Poppins",
    fontSize: 12,
    color: "#E5050B",
  },
  notesIcon: {
    color: "#204289",
    fontSize: 25,
  },
  actionIcon: {
    color: "#204289",
    fontSize: 24,
    cursor: "pointer",
  },
  filterContainer: {
    padding: 1,
  },
  tableContainer: {
    marginTop: 0,
    // height: '630px',
    // overflowY: "scroll"
  },
  tableCell: {
    fontFamily: "Poppins",
    fontSize: 12,
    color: "#204289",
    textDecoration: "none",
  },
  appointmentDate: {
    fontFamily: "Poppins",
    fontSize: 13,
    fontWeight: 400,
    color: "#204289",
  },
  appointmentTime: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: "#204289",
  },
  Chair: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: "red",
  },
  heading: {
    fontFamily: "Poppins",
    fontSize: 15,
    color: "#021025",
    fontWeight: "600",
    marginTop: 6,
    marginLeft: 1,
  },
  verticalLine: {
    position: "absolute",
    //top : 40,
    zIndex: 99999,
  },
  overflow: {
    height: "400px",
    overflowY: "scroll",
  },
  cursor: {
    cursor: "pointer",
  },
  treatment: {
    padding: "5px 10px",
    backgroundColor: "#fff",
    borderRadius: "16px",
    color: "primary",
    fontSize: "12px",
    border: "1px solid",
    borderColor: "#204289",
    fontFamily: "poppins",
    textAlign: "center",
    fontWeight: "400",
  },
  CheckBoxLabel: {
    fontFamily: "poppins",
    fontSize: "14px",
    color: "#021025",
    fontWeight: "550",
  },
  DragandDrop: {
    border: "1px dashed #204289",
    borderRadius: "10px",
    padding: "8px 16px",
    textAlign: "center",
    fontSize: "16px",
    fontFamily: "poppins",
    color: "#9DA2AB",
  },
  TabColor: {
    textTransform: "none",
    padding: "0px",
    ".css-1t4y33s-MuiButtonBase-root-MuiTab-root": {
      minHeight: "0px !important",
    },
  },
  Tablist: {
    ".css-heg063-MuiTabs-flexContainer": {
      display: "flex !important",
      gap: "15px !important",
      justifyContent: "center",
    },
  },
  Tabpanel: {
    padding: "0px",
  },
  SchemeDocuments: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: "primary.main",
  },
  tabsPanel: {
    padding: "0px",
    paddingTop: "15px",
  },
  closeicon: {
    color: "#104076",
    cursor: "pointer",
  },
  deleteActionIcon: {
    color: "#CE0505",
    fontSize: 20,
  },
  deleteTableCell: {
    fontFamily: "Poppins",
    fontSize: 12,
    textAlign: "center",
    textDecoration: "none",
    marginLeft: 1,
    marginTop: 0.5,
    color: "#CE0505",
  },

  cardHeader: {
    fontFamily: "Poppins",
    fontSize: 12,
    color: "primary.main",
    fontWeight: 500,
  },

  rowData1: {
    fontFamily: "Poppins",
    fontSize: 12,
    color: "primary.main",
    marginLeft: 1,
  },
  label: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: "#021025",
    fontWeight: 600,
  },
  blueLabel: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: "#204289",
    fontWeight: 600,
  },
  label1: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: "#021025",
    fontWeight: 600,
    marginBottom: 2,
  },
  formContainer: {
    marginLeft: 4,
    marginTop: 3,
    marginRight: 4,
  },
  fieldContainer: {
    marginTop: 3,
    marginLeft: 3,
  },
  fieldContainer1: {
    marginTop: 2,
    marginLeft: 3,
  },
  iconContainer: {
    backgroundColor: "primary.main",
    padding: 2,
    borderRadius: "10px",
    height: 38,
    width: 38,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "5px",
    cursor: "pointer",
  },
  addIcon: {
    color: "white",
  },
  buttonContainer: {
    marginRight: 6,
    marginBottom: 3,
    marginTop: 3,
  },
  cardContainer: {
    marginRight: 1,
    marginTop: 2,
  },
  patientId: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: "#204289",
  },
  searchPlaceholder: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: "#204289",
  },
  patientDetails: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: "#204289",
  },
  actionIcon1: {
    color: "#204289",
    fontSize: 30,
    marginTop: 1,
  },
  dropdownContainer: {
    padding: 2,
    position: "absolute",
    zIndex: 99999,
    top: 164,
    backgroundColor: "white",
    width: 373,
    boxShadow: "0px 8px 8px #5D5D5D29",
    opacity: 1,
    borderBottomRightRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  name: {
    fontFamily: "Poppins",
    fontSize: 12,
    color: "primary.main",
    marginLeft: 0.5,
  },
  imageOperative: {
    height: "100px",
    width: "100px",
    padding: "10px",
  },
};
