import { SvgIcon } from "@mui/material";
import React from "react";
import { IconographyProps } from "./stepper.interface";

const CompletedStepIcon = ({
  primaryColor,
  secondaryColor,
  sx,
  ...props
}: IconographyProps) => {
  const primaryDefault = "#33AB3F";
  const secondaryDefault = "white";

  return (
    <SvgIcon
      sx={{ fill: "none", width: 34, height: 35, ...sx }}
      {...props}
      viewBox="0 0 34 35"
    >
      <circle
        cx="17"
        cy="17.582"
        r="14"
        fill={primaryColor ?? primaryDefault}
      />
      <circle
        cx="17"
        cy="17.582"
        r="16.5"
        stroke={primaryColor ?? primaryDefault}
      />
      <path
        d="M13 17.582L15.6667 20.582L21 14.582"
        stroke={secondaryColor ?? secondaryDefault}
        strokeWidth="1.84147"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <circle
        cx="17"
        cy="17.582"
        r="14"
        fill={primaryColor ?? primaryDefault}
      />
      <circle
        cx="17"
        cy="17.582"
        r="16.5"
        stroke={primaryColor ?? primaryDefault}
      />
      <path
        d="M13 17.582L15.6667 20.582L21 14.582"
        stroke={secondaryColor ?? secondaryDefault}
        strokeWidth="1.84147"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default CompletedStepIcon;
