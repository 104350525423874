import { Box } from "@mui/material";
import CaseHistoryForm from "./case-history-form";

const CaseHistory = (props: any) => {
  const { isViewOnly = false, onClose } = props;
  return (
    <>
      {isViewOnly ? (
        <>{"Here Case History details comes here"}</>
      ) : (
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <CaseHistoryForm onClose={onClose} />
        </Box>
      )}
    </>
  );
};

export default CaseHistory;
