// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flip-card {
  perspective: 1000px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.5s !important;
}

.flip-card.flipped {
  transform: rotateY(180deg);
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
}

.flip-card-front,
.flip-card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}

/* .flip-card-front {
  /* background-color: #d7fbda;
  color: green; 
}*/


.flip-card-back {
  /* background-color: #fbd7f8;
  color: blue; */
  transform: rotateY(180deg);
}

.card-content {
  padding: 20px;
  text-align: center;
}

.flip-button {
  width: 100px;
  padding: 10px;
  font-size: 16px;
  margin-top: 10px;
  background-color: #f5d9fa;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/screens/Dashboard/dashboard.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,4BAA4B;EAC5B,qCAAqC;AACvC;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,4BAA4B;AAC9B;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;;;EAGE;;;AAGF;EACE;gBACc;EACd,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".flip-card {\n  perspective: 1000px;\n  position: relative;\n  transform-style: preserve-3d;\n  transition: transform 0.5s !important;\n}\n\n.flip-card.flipped {\n  transform: rotateY(180deg);\n}\n\n.flip-card-inner {\n  width: 100%;\n  height: 100%;\n  transform-style: preserve-3d;\n}\n\n.flip-card-front,\n.flip-card-back {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  backface-visibility: hidden;\n}\n\n/* .flip-card-front {\n  /* background-color: #d7fbda;\n  color: green; \n}*/\n\n\n.flip-card-back {\n  /* background-color: #fbd7f8;\n  color: blue; */\n  transform: rotateY(180deg);\n}\n\n.card-content {\n  padding: 20px;\n  text-align: center;\n}\n\n.flip-button {\n  width: 100px;\n  padding: 10px;\n  font-size: 16px;\n  margin-top: 10px;\n  background-color: #f5d9fa;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
