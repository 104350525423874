// PrivacyPolicy.js
import { AppBar, Box, CssBaseline } from "@mui/material";
import { Container, Grid } from "@mui/material";
import styles from "../../components/Layout/layout.module.css";
import Background from "../../app/assets/Dashboard/background.jpg";

const PrivacyPolicy = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <CssBaseline />
      <Box
        position="fixed"
        sx={{
          boxShadow: "none",
          zIndex: "3",
          width: "100%",
          backgroundColor: "#FFFFFF",
        }}
      >
        {/* header part */}
        <Box padding={"10px 50px 10px 50px"}>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <Box
              sx={{ cursor: "pointer" }}
              display={"flex"}
              gap={"20px"}
              alignItems={"center"}
            >
              <Box
                component="img"
                className={styles?.logoImage}
                alt="Logo"
                src={require("../../app/assets/logo.png")}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {/* background part*/}
      <Grid
        style={{
          backgroundImage: `url(${Background})`,
          backgroundRepeat: "repeat",
          backgroundSize: "cover",
          width: "100%",
        }}
      >
        <Box sx={{ padding: "0px 40px !important", marginTop: "120px" }}>
          <Box mt={10}>
            <h1>Privacy Policy</h1>
            </Box>
            </Box>
            </Grid>
            <Box  sx={{ padding: "20px 40px !important"}}>
            <h2>1. Introduction</h2>
            <p>Welcome to DentAssure. Your privacy is of utmost importance to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you use our website and mobile application.</p>
            <h2>2. Information We Collect</h2>
            <ul>
              <li><strong>Personal Information:</strong> Name, contact details, date of birth, and other identifying information.</li>
              <li><strong>Medical and Insurance Details:</strong> Policy numbers, claim history, and medical reports shared by networking hospitals.</li>
              <li><strong>Payment Information:</strong> Transaction details related to insurance purchases or claim settlements.</li>
              <li><strong>Technical Information:</strong> IP addresses, device information, and usage patterns.</li>
            </ul>
            <h2>3. How We Use Your Information</h2>
            <ul>
              <li>To provide insurance coverage and process claims.</li>
              <li>To communicate with clients, agents, and networking hospitals.</li>
              <li>To improve user experience on our website and app.</li>
              <li>To comply with legal and regulatory requirements.</li>
            </ul>
            <h2>4. Data Sharing and Disclosure</h2>
            <ul>
              <li>We may share information with networking hospitals for claim processing.</li>
              <li>Agents may access necessary client details to assist in policy management.</li>
              <li>Third-party service providers may be used for payment processing and IT support.</li>
              <li>Legal authorities may receive information if required by law.</li>
            </ul>
            <h2>5. Data Security</h2>
            <p>We implement encryption and access control measures to protect your data. However, no system is entirely secure, and we encourage users to take precautions in safeguarding their credentials.</p>
            <h2>6. Your Rights</h2>
            <ul>
              <li>Access, update, or delete your personal data.</li>
              <li>Opt out of marketing communications.</li>
              <li>Request a copy of your data, subject to verification.</li>
            </ul>
            <h2>7. Contact Information</h2>
            <p>For any privacy-related concerns, contact our Data Protection Officer at <a href="mailto:support@dentassureplans.com">support@dentassureplans.com</a>.</p>
          </Box>
        </Box>
  );
};

export default PrivacyPolicy;
