import { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import OtpInput from "react-otp-input";
import CustomButton from "../../components/Button";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import "./index.css";
import { apiRequest } from "../../app/services/requests";
import CustomSpinner from "../Spinner";
import CustomDialog from "../CustomDialog";
import { useAppContext } from "../../app/context/AppContext";
import { useSnackbar } from "../../app/context/useSnackbar";

const Colors = {
  primaryColor: "#219EBC",
  textColor: "#000",
  white: "#fff",
  orange: "#FA8842",
  blueText: "#013047",
  lightBlue: "#FA8842",
  grey1: "#161616",
  red: "#B81D24",
  skyBlue: "#FFF3EB",
  black: "#000",
  grey2: "#67676729",
  blackText: "##161616",
};

const OtpScreen = (props) => {
  const {
    registrationResponse,
    verifyType,
    verifiedMobileNumber,
    loginWithOtp,
    forgotPassword,
    details,
    request,
    verifyTypeViaEmail,
    encryptedMobileResponse,
  } = props;

  const navigate = useNavigate();

  const { openSnackbar } = useSnackbar();

  const { handleLoginSuccess, mobile } = useAppContext();

  const [openUserStatusModal, setOpenUserStatusModal] = useState({
    open: false,
    data: {},
  });

  const encryptData = (text) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      "XkhZG4fW2t2W"
    ).toString();
    const encodedData = encodeURIComponent(data);

    return encodedData;
  };

  const OtpForm = () => {
    const [otp, setOtp] = useState("");

    const [timer, setTimer] = useState(30);

    useEffect(() => {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : prevTimer));
      }, 1000);

      return () => {
        clearInterval(countdown);
      };
    }, []);

    const [loader, setLoader] = useState(false);

    const handleOtpSubmit = (data) => {
      setLoader(true);
      data.preventDefault();
      setTimeout(() => {
        if (verifyType === "registration") {
          apiRequest({
            url: `user/verify-otp`,
            data: {
              otp: Number(otp),
              otp_token: registrationResponse?.otp_token,
            },
            method: "POST",
          })
            .then((res) => {
              openSnackbar(
                res?.success === false ? "Wrong OTP" : "Success! OTP verified",
                `${res?.success === false ? "error" : "success"}`
              );
              if (res?.success === true) {
                apiRequest({
                  url: `user/register`,
                  data: registrationResponse?.payload,
                  method: "POST",
                })
                  .then((res) => {
                    if (res?.role?.code === "student") {
                      // navigate(
                      //   `/register/student/${res?._id}/form?smk=${encryptData(
                      //     res?.mobile
                      //   )}`
                      // );
                    } else {
                      // navigate(`/register/corporate/${res?._id}/form`);
                    }
                  })
                  .catch((err) => {
                    openSnackbar(err?.response?.data?.message, "error");
                  });
              }
              setLoader(false);
            })
            .catch((err) => {
              openSnackbar(err?.response?.data?.message, "error");
              setLoader(false);
            });
        } else if (loginWithOtp === "loginWithOtp") {
          apiRequest({
            url: `user/otp-login`,
            data: {
              otp: Number(otp),
              otp_token: details?.otp_token,
              mobile: verifiedMobileNumber?.mobile,
            },
            method: "POST",
          })
            .then((res) => {
              if (res?.success === false) {
                openSnackbar("Invalid OTP", "error");
              }
              if (res?.data?.status === "pending") {
                setOpenUserStatusModal({ open: true, data: res });
              } else if (res?.data?.status === "active") {
                localStorage.setItem("user", JSON.stringify(res?.data));
                handleLoginSuccess(res?.data);
                if (res?.data?.role?.code === "admin") {
                  navigate("/admin/dashboard");
                } else if (
                  res?.data?.role?.code === "student" ||
                  res?.data?.role?.code === "corporate"
                ) {
                  navigate(`/`);
                } else {
                  localStorage.setItem("user", JSON.stringify(res?.data));
                  handleLoginSuccess(res?.data);
                  if (res?.role?.code === "admin") {
                    navigate("/admin/dashboard");
                  } else if (
                    res?.role?.code === "student" ||
                    res?.role?.code === "corporate"
                  ) {
                    navigate(`/`);
                  }
                }
              }
              if (res?.success) {
                openSnackbar(`${"Success! Logged in with Thrillh"}`, "success");
              }
              setLoader(false);
            })
            .catch((err) => {
              setLoader(false);
              openSnackbar(err?.response?.data?.message, "error");
            });
        } else if (forgotPassword === "forgotPassword") {
          apiRequest({
            url: `user/verify-otp`,
            method: "POST",
            data: {
              otp: Number(otp),
              otp_token: details?.otp_token,
            },
          })
            .then((res) => {
              if (res) {
                props.setForgotResponse(res);
                openSnackbar(
                  res?.success === false
                    ? "Wrong OTP"
                    : "Success! OTP verified ",
                  `${res?.success === false ? "error" : "success"}`
                );
              }
              setLoader(false);
            })
            .catch((err) => {
              openSnackbar(err?.response?.data?.message, "error");
              setLoader(false);
            });
        } else if (
          verifyTypeViaEmail === "studentEmail" ||
          verifyTypeViaEmail === "corporateEmail"
        ) {
          apiRequest({
            url: `email/verify-otp`,
            method: "POST",
            data: {
              email_id: encryptedMobileResponse?.payload?.email,
              ctx: "REGISTRATION",
              otp: Number(otp),
              mobile: mobile?.payload?.mobile,
            },
          })
            .then((res) => {
              openSnackbar(
                res?.success === false ? "Wrong OTP" : "Success! OTP verified ",
                `${res?.success === false ? "error" : "success"}`
              );
              if (res?.success === true) {
                navigate("/login");
                localStorage.removeItem("mobileNumber", JSON.stringify({}));
              }
              setLoader(false);
            })
            .catch((err) => {
              setLoader(false);
              if (err?.success === false) {
                openSnackbar("Invalid OTP", "error");
              }
            });
        } else {
          console.debug("debug");
        }
      }, 200);
    };

    const redirectTo = () => {
      if (openUserStatusModal.data?.data?.role?.code === "student") {
        navigate(
          `/register/student/${openUserStatusModal?.data?.data?.id}/form`
        );
      } else {
        navigate(
          `/register/corporate/${openUserStatusModal?.data?.data?.role?.id}/form`
        );
      }
    };

    const handleRequestOtp = (data) => {
      if (verifyType === "registration") {
        props.requestOTP({
          mobile: registrationResponse?.payload?.mobile,
          userRole: registrationResponse?.payload?.userRole,
          password: registrationResponse?.payload?.password,
        });
      } else {
        props.requestOTP({ mobile: details?.mobile });
      }
    };

    return (
      <>
        <CustomDialog
          width={"400px"}
          open={openUserStatusModal.open}
          title={""}
          onClose={() => setOpenUserStatusModal({ open: false })}
        >
          {/* <ModelInfo
            type={"login"}
            title={"Register"}
            color={"#CB0000"}
            message={"Error"}
            handleClick={redirectTo}
          /> */}
        </CustomDialog>

        <Box>
          <h2 style={styles.heading}>Enter Otp</h2>
          <p style={styles.subHeading}>
            The OTP has been sent to the registered{" "}
            {verifyTypeViaEmail === "studentEmail" ||
            verifyTypeViaEmail === "corporateEmail"
              ? "email"
              : "mobile number"}{" "}
            of your account
          </p>
          <form onSubmit={handleOtpSubmit}>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              gap={6}
            >
              <Grid item>
                <p style={styles.currentMobile}>{details?.mobile}</p>
              </Grid>
            </Grid>

            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <OtpInput
                value={otp}
                onChange={(e) => setOtp(e)}
                numInputs={6}
                shouldAutoFocus={true}
                isInputNum={true}
                focusStyle={{ outline: "none" }}
                separator={<Box m={"3px"} />}
                inputStyle={styles.inputStyle}
                containerStyle={styles.otpContainer}
              />
            </Box>

            {loader ? (
              <>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={"100px"}
                >
                  <CustomSpinner size={"1rem"} />
                </Box>
              </>
            ) : (
              <Box mt={4}>
                <CustomButton
                  padding={"15.5px"}
                  title={"Submit"}
                  disabled={otp?.length === 6 ? false : true}
                  borderButton={false}
                  type={"submit"}
                />
              </Box>
            )}
            <Box mt={2}>
              <CustomButton
                padding={"15.5px"}
                title={"Request Otp"}
                borderButton={true}
                disabled={timer === 0 ? false : true}
                handleClick={(data) => handleRequestOtp(data)}
              />
            </Box>
            <Box mt={4}>
              <p style={styles.skipText}>Request OTP in {timer} Secs</p>
            </Box>
          </form>
        </Box>
      </>
    );
  };

  return (
    <Box height={"100vh"} bgcolor={Colors.white}>
      <Grid container>
        <Grid item md={6} width={"100%"}>
          <Box m={5}>
            <Box className="thrillhLogo">
              {/* <img src={logo} alt={"Logo Image"} width={"150px"} /> */}
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box width={500} marginTop={"40px"} className={"loginBox"}>
                <OtpForm />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} className="otpRightSec">
          <Box position={"fixed"} width={"100%"}>
            <Box
              position={"relative"}
              borderRadius={"20px 0px 0px 20px"}
              style={{
                width: "50%",
                height: "100vh",
                backgroundColor: "#c5c5c5",
              }}
            >
              <Box
                style={{
                  // backgroundImage: `url(${loginbg})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  width: "100%",
                  height: "100vh",
                  backgroundPosition: "center",
                }}
                position={"absolute"}
                top={"0"}
                zIndex={"1"}
              ></Box>
              <Box position={"absolute"} zIndex={"0"} top={"0"} right={"0"}>
                {/* <img
                  src={require("../../../images/topShape.png")}
                  alt={"Auth Bg"}
                  width={"300px"}
                /> */}
              </Box>
              <Box position={"absolute"} zIndex={"0"} bottom={"0"} left={"0"}>
                {/* <img
                  src={require("../../../images/bottomShape.png")}
                  alt={"Auth Bg"}
                  width={"300px"}
                /> */}
              </Box>
              <Box
                position={"absolute"}
                top={"0"}
                width={"100%"}
                height={"100%"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                {/* <img src={otpImageSvg} alt={"Auth Bg"} width={"640px"} /> */}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const styles = {
  authBg: {
    height: "100vh",
  },
  innerAuthBg: {
    width: 500,
    height: 500,
  },
  heading: {
    fontFamily: "MetropolisExtraBold",
    color: Colors.textColor,
  },
  subHeading: {
    fontFamily: "MetropolisLight",
    color: Colors.textColor,
    fontSize: 13,
  },
  inputStyle: {
    fontFamily: "MetropolisRegular",
    width: 60,
    height: 40,
    borderColor: Colors.lightBlue,
    borderRadius: 30,
    borderWidth: 1,
    fontSize: 14,
  },
  otpContainer: {
    marginTop: 30,
  },
  currentMobile: {
    fontFamily: "MetropolisSemiBold",
    fontSize: 18,
    color: "#000000",
  },
  changeText: {
    fontFamily: "MetropolisMedium",
    fontSize: 14,
    color: Colors.lightBlue,
    textDecoration: "underline",
  },
  skipText: {
    fontFamily: "MetropolisSemiBold",
    color: Colors.lightBlue,
    fontSize: 13,
    textAlign: "center",
  },
};

export default OtpScreen;
