import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { Navigate, useParams } from "react-router-dom";
import { useAppContext } from "./context/AppContext";

const PrivateRoute = ({
  children,
  roles = [],
  allowedActions = [],
}: {
  children: JSX.Element;
  roles: any;
  allowedActions: any;
}) => {
  const { user } = useAppContext() as any;

  const { actionName } = useParams();

  const userHasRequiredRole =
    user && roles?.includes(user.userType) ? true : false;
  if (!userHasRequiredRole) {
    return (
      <div className="access-denied-div">
        <SentimentVeryDissatisfiedIcon />
        <h1>Access Denied !</h1>
        <h3 style={{ margin: "0px" }}>You Don't have access to this page.</h3>
      </div>
    ); // build your won acpcess denied page (sth like 404)
  }

  if (
    allowedActions?.length > 0 &&
    (!actionName || !allowedActions.includes(actionName))
  ) {
    return <Navigate to="/page-not-found" replace />;
  }

  return children;
};

export default PrivateRoute;
