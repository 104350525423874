import { OnboardingStep1, OnboardingStep2 } from "../screens/Onboarding";
import { useRoutes } from "react-router-dom";
import LoginPage from "../screens/Login/login";
import TermsAndConditions from "../screens/TermsAndPolicies/terms-and-conditions";
import PrivacyPolicy from "../screens/TermsAndPolicies/privacy-policy";

const routes = [
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/user/onboarding/step/1",
    element: <OnboardingStep1 />,
  },
  {
    // path: "/user/onboarding/step/2/:leadId?/:token?",
    path: "/user/onboarding/step/2/lead/:leadId?",
    element: <OnboardingStep2 />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />,
  },
  {
    path: "*",
    element: <LoginPage />,
  },
];

const NoLoginRoutes = () => {
  const noLoginRoutes = useRoutes(routes);
  return <>{noLoginRoutes}</>;
};

export default NoLoginRoutes;
