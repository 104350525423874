import React from 'react';
import "./index.css";

function FullTeethSurface(props) {
    const { width = '50%', teethSurfaceStyles: {
        Top = {},
        Bottom = {},
        Left = {},
        Center = {},
        Right = {}
    } = {}, onClick = () => { } } = props;
    return (
        <svg width={width} onClick={onClick} version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 360.5 340.5" xmlSpace="preserve">
            <g id="out_line">
                <path className="st0" d="M41.4,91.5C69.3,35.7,132.9,5,198.1,15.9C266.8,27.5,320,86.2,329.5,161.2c7.2,56.3-29.7,119.7-83.6,143.8
		c-1.6-1.6-3.2-3.2-4.7-4.9c8.5-10.3,22.7-17.4,19.1-34.4c45.7-8.6,47.6-47.9,52.1-81.1c5-36.9-1.5-73.6-34.9-98.3
		c-3.7-2.7-11.3,0-17.1,0.2c-1.5-12.7,0.3-24.7-13.5-34.6c-36.8-26.4-115.6-26.3-152.3,0.1c-13.8,9.9-11.7,22.1-13.5,34.6
		c-13.7-4.1-24.7,0.4-34.1,10.4C45.1,95,43.3,93.3,41.4,91.5z"/>
                <path className="st0" d="M245.9,304.9c-52.6,41.6-132.1,32.9-185.5-20.4C3.6,227.9-4.1,149.8,41.5,91.6c1.8,1.7,3.6,3.5,5.5,5.2
		c-6,19.8-14.1,39.3-17.7,59.6c-8.5,49.2,14.1,94.9,51.8,109.3c3,34.7,31.2,39.4,56.4,44.6c35.2,7.2,70,1.6,103.8-10.1
		C242.8,301.7,244.3,303.4,245.9,304.9z"/>
            </g>
            <path id="Top" style={Top} className="st1" d="M81.1,86.4c1.8-12.5-0.3-24.7,13.5-34.6c36.7-26.4,115.5-26.5,152.3-0.1c13.8,9.9,12,22,13.5,34.6
	l0.3-0.3c-7.1-0.9-10.4,2-9.3,9.3l0.3-0.3c-7.1-0.8-10.2,2.3-9.3,9.3l0.3-0.3c-7.1-0.9-10,2.5-9.3,9.3l0.4-0.4
	c-6.9-0.7-10.2,2.3-9.3,9.3l0.3-0.3c-36.1-12.7-72.1-12.2-108.2,0l0.4,0.4c0.9-7.1-2.2-10.3-9.4-9.4l0.4,0.4
	c0.9-7.1-2.2-10.3-9.4-9.4l0.4,0.4c0.9-7.2-2.1-10.3-9.4-9.4l0.4,0.4C91,88.2,87.9,85,80.7,86L81.1,86.4z"/>
            <path id="Right" style={Right} className="st1" d="M260.4,86.3c5.8-0.2,13.5-2.9,17.1-0.2c33.4,24.7,39.9,61.4,34.9,98.3c-4.5,33.2-6.4,72.5-52.1,81.2
	l0.4,0.4c0.8-7.1-2.2-10.3-9.4-9.4l0.4,0.4c0.9-7.2-2.2-10.3-9.3-9.3l0.4,0.4c0.9-7.1-2.2-10.3-9.3-9.3l0.4,0.4
	c0.9-7.1-2.3-10.2-9.4-9.4l0.4,0.4c7.6-36,10.8-72.1,0-108.1c0,0-0.3,0.3-0.3,0.3c5.8-0.4,8.8-3.7,9.3-9.3c0,0-0.4,0.4-0.4,0.4
	c5.6-0.6,8.9-3.6,9.3-9.3c0,0-0.3,0.3-0.3,0.3c5.8-0.4,8.6-3.8,9.3-9.3c0,0-0.3,0.3-0.3,0.3c5.7-0.5,9.2-3.3,9.3-9.3L260.4,86.3z"/>
            <path id="Bottom" style={Bottom} className="st1" d="M241.2,300.1c-33.8,11.8-68.7,17.4-103.8,10.1C112.2,305,84,300.4,81,265.6l-0.3,0.4
	c5.8-0.4,8.9-3.5,9.3-9.3l-0.3,0.3c5.9-0.3,9-3.4,9.3-9.3l-0.3,0.3c5.9-0.3,8.9-3.5,9.3-9.3c0,0-0.3,0.3-0.3,0.3
	c5.8-0.4,8.9-3.6,9.3-9.3c0,0-0.3,0.3-0.3,0.3c36,4.1,72.1,4.7,108.1,0l-0.4-0.4c0.5,5.7,3.7,8.8,9.4,9.4c0,0-0.4-0.4-0.4-0.4
	c0.5,5.7,3.7,8.7,9.3,9.3c0,0-0.4-0.4-0.4-0.4c3.1,3.1,6.2,6.2,9.3,9.3l-0.4-0.4c3.1,3.1,6.2,6.3,9.4,9.4l-0.4-0.4
	C264,282.6,249.8,289.8,241.2,300.1z"/>
            <path id="Left" style={Left} className="st1" d="M81,265.6c-37.7-14.4-60.3-60.1-51.8-109.3c3.5-20.3,11.6-39.8,17.7-59.6c9.4-10,20.4-14.5,34.1-10.4
	L80.7,86c3.1,3.1,6.2,6.2,9.4,9.4L89.7,95c3.1,3.1,6.2,6.2,9.4,9.4c0,0-0.4-0.4-0.4-0.4c3.1,3.1,6.2,6.2,9.4,9.4l-0.4-0.4
	c3.1,3.1,6.2,6.2,9.4,9.4c0,0-0.4-0.4-0.4-0.4c-10,36-7.6,72.1,0.1,108.1l0.3-0.3c-7.2-1-10.1,2.3-9.3,9.3l0.3-0.3
	c-7.1-0.9-10.1,2.3-9.3,9.3l0.3-0.3c-7-0.8-10.2,2.2-9.3,9.3l0.3-0.3c-7-0.8-10.1,2.3-9.3,9.3L81,265.6z"/>
            <path className="st0" d="M224.8,230.1c-36,4.7-72.1,4.1-108.1,0c-7.7-36-10.1-72.1-0.1-108.1c36.1-12.1,72.1-12.6,108.2,0
	C235.6,158,232.4,194,224.8,230.1z M124.3,173.3c0.7,31.2,18.4,46.9,48.1,46.6c27.9-0.4,44.4-16.3,44.7-45.4
	c0.3-30.6-17.1-49.2-45.9-50.2C141,123.2,125.7,143,124.3,173.3z"/>
            <g id="line1">
                <g>
                    <path className="st0" d="M90,95.4c-3.1-3.1-6.2-6.2-9.4-9.4C87.9,85,91,88.2,90,95.4z" />
                    <path className="st0" d="M99,104.3c-3.1-3.1-6.2-6.2-9.4-9.4C96.9,94,100,97.2,99,104.3z" />
                    <path className="st0" d="M108,113.3c-3.1-3.1-6.2-6.2-9.4-9.4C105.8,103,108.9,106.2,108,113.3z" />
                    <path className="st0" d="M117,122.3c-3.1-3.1-6.2-6.3-9.4-9.4C114.8,112.1,117.9,115.2,117,122.3z" />
                </g>
                <g>
                    <path className="st0" d="M107.8,112.8c3.1,3.1,6.2,6.2,9.4,9.4C109.9,123.2,106.9,119.9,107.8,112.8z" />
                    <path className="st0" d="M98.8,103.8c3.1,3.1,6.2,6.2,9.4,9.4C100.9,114.1,97.9,111,98.8,103.8z" />
                    <path className="st0" d="M89.8,94.8c3.1,3.1,6.2,6.2,9.4,9.4C92,105.1,88.9,102,89.8,94.8z" />
                    <path className="st0" d="M80.8,85.8c3.1,3.1,6.2,6.3,9.4,9.4C83.1,96.1,79.9,93,80.8,85.8z" />
                </g>
            </g>
            <g id="line2">
                <path className="st0" d="M260.7,86c-0.1,6.1-3.6,8.8-9.3,9.3C250.3,88,253.6,85.2,260.7,86z" />
                <path className="st0" d="M251.8,95c-0.7,5.5-3.5,8.9-9.3,9.3C241.6,97.3,244.7,94.2,251.8,95z" />
                <path className="st0" d="M242.8,104c-0.4,5.8-3.7,8.8-9.3,9.3C232.8,106.4,235.7,103.1,242.8,104z" />
                <path className="st0" d="M233.8,112.9c-0.6,5.6-3.5,8.9-9.3,9.3C223.6,115.2,226.9,112.3,233.8,112.9z" />
            </g>
            <g id="line3">
                <path className="st0" d="M260.7,266c-3.1-3.1-6.2-6.3-9.4-9.4C258.5,255.7,261.6,259,260.7,266z" />
                <path className="st0" d="M251.5,256.5c3.1,3.1,6.2,6.3,9.4,9.4C253.7,266.8,250.7,263.6,251.5,256.5z" />
                <path className="st0" d="M242.8,248c-5.6-0.6-8.8-3.7-9.3-9.3C240.6,237.8,243.7,240.9,242.8,248z" />
                <path className="st0" d="M233.8,239.1c-5.7-0.5-8.8-3.7-9.4-9.4C231.5,228.9,234.7,231.9,233.8,239.1z" />
                <path className="st0" d="M251.7,257c-3.1-3.1-6.2-6.2-9.3-9.3C249.5,246.8,252.7,249.9,251.7,257z" />
                <path className="st0" d="M242.5,247.5c3.1,3.1,6.2,6.2,9.3,9.3C244.7,257.8,241.6,254.7,242.5,247.5z" />
            </g>
            <g id="line4">
                <path className="st0" d="M90.1,256.7c-0.4,5.8-3.5,8.9-9.3,9.3C80,259,83.1,255.9,90.1,256.7z" />
                <path className="st0" d="M108,238.7c-0.4,5.8-3.4,9-9.3,9.3C98,241,101,237.9,108,238.7z" />
                <path className="st0" d="M117,229.7c-0.4,5.8-3.6,8.9-9.3,9.3C106.9,232,109.8,228.8,117,229.7z" />
                <path className="st0" d="M99,247.7c-0.3,5.9-3.4,9-9.3,9.3C88.8,249.9,92.1,246.9,99,247.7z" />
            </g>
            <path id="Center" style={Center} className="st2" d="M130.4,147c0,0,6.5-20.5,34.9-22.6c0,0,43.6-4.4,51,38.4c0,0,8.9,45.3-29.4,55.3
	c0,0-39.5,10.2-56-18c0,0-10.2-15.3-5-39.6C125.9,160.4,128.1,150.7,130.4,147z"/>
        </svg>
    );
}

function TeethSurfaceWithoutCenter(props) {
    const { width = '50%', teethSurfaceStyles: {
        Top = {},
        Bottom = {},
        Left = {},
        Right = {}
    } = {}, onClick = () => { } } = props;
    return (
        <svg width={width} id="Layer_1" onClick={onClick} xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 360.5 340.5" enable-background="new 0 0 360.5 340.5">
            <g>
                <g>
                    <g>
                        <path fill="#A8A9AE" d="M21.18,144.732C12.512,199.12,32.731,252.16,72.148,284.208c6.94-7.988,16.882-19.228,20.257-23.039
				c-12.466-1.681-24.545,0.418-34.549-13.232c-26.726-36.465-27.429-115.288-1.315-152.286c9.8-13.885,21.862-12.227,34.502-13.795
				c-0.004-0.104-0.015-0.213-0.021-0.318l-23.867-23.08C43.009,81.476,26.505,111.316,21.18,144.732z"/>
                        <path id="Left" style={Left} fill="#F9F9FA" d="M92.553,260.188c-4.417,0.988-15.618,2.832-26.575-3.077c-1.195-0.644-4.845-2.707-9-7
				c-9.565-9.884-12.48-21.954-14-29c-7.034-32.618-11.395-52.837-6-81c0,0,5.04-26.308,19.563-45.46
				c2.056-2.711,4.625-5.958,8.853-8.497c7.841-4.708,16.597-4.176,25.648-5.299c3.035,2.976,6.071,5.951,9.106,8.926l-0.351-0.346
				c3.133,3.084,6.267,6.169,9.4,9.253l-0.345-0.337c3.142,3.089,6.284,6.179,9.426,9.269l-0.393-0.385
				c3.139,3.091,6.279,6.182,9.418,9.273c0,0-0.315-0.315-0.316-0.315c8.798,8.448,17.728,17.303,26.746,26.573
				c8.796,9.041,17.198,17.986,25.218,26.796c-16.912,18.135-33.825,36.271-50.737,54.406c-3.1,3.15-6.199,6.3-9.299,9.45
				l0.381-0.388c-3.096,3.146-6.191,6.293-9.287,9.439l0.375-0.38c-3.094,3.144-6.188,6.288-9.281,9.432l0.369-0.375
				C98.5,254.16,95.526,257.174,92.553,260.188z"/>
                    </g>
                    <g>
                        <g>
                            <path fill="#A8A9AE" d="M115.766,106.392c3.122,3.12,6.244,6.239,9.366,9.358C117.871,116.773,114.854,113.548,115.766,106.392z
					"/>
                            <path fill="#A8A9AE" d="M106.781,97.407c3.119,3.119,6.238,6.239,9.357,9.358C108.909,107.753,105.83,104.594,106.781,97.407z"
                            />
                            <path fill="#A8A9AE" d="M97.795,88.422c3.121,3.121,6.243,6.242,9.364,9.363C99.992,98.709,96.896,95.563,97.795,88.422z" />
                            <path fill="#A8A9AE" d="M88.805,79.431c3.125,3.125,6.249,6.251,9.374,9.376C91.03,89.704,87.917,86.567,88.805,79.431z" />
                        </g>
                        <g>
                            <path fill="#A8A9AE" d="M151.766,142.392c3.122,3.12,6.244,6.239,9.367,9.358C153.871,152.773,150.854,149.548,151.766,142.392z
					"/>
                            <path fill="#A8A9AE" d="M142.781,133.407c3.119,3.119,6.238,6.239,9.357,9.358C144.909,143.753,141.83,140.594,142.781,133.407z
					"/>
                            <path fill="#A8A9AE" d="M133.795,124.422c3.121,3.121,6.243,6.242,9.364,9.363C135.992,134.709,132.896,131.563,133.795,124.422
					z"/>
                            <path fill="#A8A9AE" d="M124.805,115.431c3.125,3.125,6.249,6.251,9.374,9.376C127.03,125.704,123.917,122.567,124.805,115.431z
					"/>
                        </g>
                        <path fill="#A8A9AE" d="M169.795,160.422c3.121,3.121,6.243,6.242,9.364,9.363C171.992,170.709,168.896,167.563,169.795,160.422z
				"/>
                        <path fill="#A8A9AE" d="M160.805,151.431c3.125,3.125,6.249,6.251,9.374,9.376C163.03,161.704,159.917,158.567,160.805,151.431z"
                        />
                    </g>
                </g>
                <g>
                    <path id="Bottom" style={Bottom} fill="#F9F9FA" d="M270.71,260.252c-1.783,12.509,0.325,24.656-13.43,34.628c-36.603,26.537-115.428,26.831-152.291,0.525
			c-13.834-9.872-12.113-21.925-13.616-34.573l-0.301,0.282c3.092-3.114,6.183-6.228,9.274-9.342l-0.347,0.349
			c3.101-3.117,6.201-6.235,9.302-9.352l-0.339,0.344c3.106-3.126,6.211-6.251,9.317-9.377l-0.387,0.391
			c3.107-3.123,6.215-6.246,9.322-9.369c0,0-0.317,0.314-0.317,0.314c8.494-8.754,17.394-17.638,26.711-26.608
			c9.087-8.75,18.075-17.104,26.927-25.079c18.047,17.006,36.095,34.012,54.142,51.018c3.134,3.116,6.268,6.232,9.402,9.348
			l-0.386-0.383c3.13,3.112,6.261,6.224,9.391,9.336l-0.379-0.377c3.128,3.11,6.256,6.22,9.384,9.33l-0.373-0.372
			C264.714,254.275,267.712,257.263,270.71,260.252z"/>
                    <g>
                        <g>
                            <path fill="#A8A9AE" d="M117.457,234.125c3.112-3.129,6.225-6.258,9.337-9.388C127.833,231.997,124.615,235.021,117.457,234.125
					z"/>
                            <path fill="#A8A9AE" d="M108.492,243.131c3.112-3.126,6.224-6.252,9.336-9.378C118.834,240.979,115.682,244.065,108.492,243.131
					z"/>
                            <path fill="#A8A9AE" d="M99.528,252.138c3.114-3.128,6.228-6.257,9.342-9.385C109.81,249.917,106.671,253.02,99.528,252.138z" />
                            <path fill="#A8A9AE" d="M90.557,261.148c3.118-3.132,6.236-6.263,9.354-9.396C100.826,258.9,97.696,262.02,90.557,261.148z" />
                        </g>
                        <g>
                            <path fill="#A8A9AE" d="M153.375,198.044c3.112-3.129,6.225-6.258,9.337-9.388C163.751,195.915,160.533,198.939,153.375,198.044
					z"/>
                            <path fill="#A8A9AE" d="M144.41,207.049c3.112-3.126,6.224-6.252,9.336-9.378C154.752,204.897,151.6,207.983,144.41,207.049z" />
                            <path fill="#A8A9AE" d="M135.446,216.056c3.114-3.128,6.228-6.257,9.342-9.385C145.728,213.835,142.589,216.938,135.446,216.056
					z"/>
                            <path fill="#A8A9AE" d="M126.475,225.066c3.118-3.132,6.236-6.264,9.354-9.396C136.744,222.818,133.614,225.938,126.475,225.066
					z"/>
                        </g>
                        <path fill="#A8A9AE" d="M171.364,179.974c3.114-3.128,6.228-6.257,9.342-9.385C181.646,177.753,178.507,180.857,171.364,179.974z
				"/>
                        <path fill="#A8A9AE" d="M162.393,188.984c3.118-3.132,6.236-6.264,9.354-9.396C172.662,186.736,169.532,189.856,162.393,188.984z
				"/>
                    </g>
                    <path fill="#A8A9AE" d="M153.886,331.021c9.911,1.846,41.327,6.353,78.152-7.047c24.11-8.774,45.459-23.572,61.586-43.197
			c-7.952-6.982-19.14-16.981-22.934-20.377c-1.746,12.457,0.291,24.547-13.41,34.48c-36.603,26.537-115.429,26.831-152.291,0.525
			c-13.834-9.872-12.113-21.925-13.616-34.573c-0.104,0.004-0.213,0.014-0.319,0.019c-6.733,7.618-13.466,15.236-20.199,22.853
			C79.704,293.649,107.612,322.404,153.886,331.021z"/>
                    <g>
                        <g>
                            <path fill="#A8A9AE" d="M205.622,196.536c3.147,3.095,6.293,6.189,9.44,9.284C207.809,206.9,204.766,203.699,205.622,196.536z"
                            />
                            <path fill="#A8A9AE" d="M196.566,187.622c3.143,3.094,6.287,6.189,9.431,9.283C198.776,197.952,195.672,194.817,196.566,187.622
					z"/>
                            <path fill="#A8A9AE" d="M187.509,178.709c3.146,3.096,6.292,6.193,9.438,9.289C189.787,188.978,186.666,185.856,187.509,178.709
					z"/>
                            <path fill="#A8A9AE" d="M178.448,169.789c3.149,3.1,6.299,6.201,9.448,9.301C180.754,180.045,177.617,176.932,178.448,169.789z"
                            />
                        </g>
                        <g>
                            <path fill="#A8A9AE" d="M241.906,232.25c3.147,3.095,6.293,6.189,9.44,9.284C244.092,242.614,241.05,239.413,241.906,232.25z" />
                            <path fill="#A8A9AE" d="M232.85,223.336c3.143,3.095,6.287,6.189,9.431,9.283C235.06,233.666,231.956,230.531,232.85,223.336z"
                            />
                            <path fill="#A8A9AE" d="M223.792,214.423c3.146,3.096,6.292,6.193,9.438,9.289C226.071,224.692,222.95,221.57,223.792,214.423z"
                            />
                            <path fill="#A8A9AE" d="M214.732,205.503c3.149,3.1,6.299,6.201,9.448,9.301C217.038,215.759,213.9,212.646,214.732,205.503z" />
                        </g>
                        <path fill="#A8A9AE" d="M260.076,250.137c3.146,3.096,6.292,6.193,9.438,9.289C262.355,260.406,259.234,257.284,260.076,250.137z
				"/>
                        <path fill="#A8A9AE" d="M251.016,241.217c3.149,3.1,6.299,6.201,9.448,9.301C253.322,251.473,250.184,248.36,251.016,241.217z" />
                    </g>
                </g>
                <g>
                    <path fill="#F9F9FA" id="Top" style={Top} d="M89.056,79.981c1.819-12.504-0.253-24.656,13.531-34.589c36.68-26.43,115.506-26.494,152.292-0.081
			c13.806,9.913,12.049,21.96,13.516,34.612l0.302-0.281c-7.073-0.861-10.405,2.013-9.302,9.315l0.349-0.348
			c-7.056-0.839-10.165,2.27-9.329,9.325l0.34-0.343c-7.095-0.86-10.016,2.451-9.344,9.35l0.388-0.39
			c-6.894-0.666-10.194,2.265-9.349,9.342c0,0,0.318-0.313,0.318-0.313c-8.52,8.729-17.446,17.587-26.789,26.53
			c-9.112,8.723-18.125,17.052-27,25c-17.998-17.059-35.996-34.118-53.993-51.176c0.888-7.137-2.225-10.274-9.375-9.375l0.385,0.384
			c0.899-7.141-2.197-10.287-9.364-9.363l0.377,0.378c0.951-7.188-2.128-10.347-9.357-9.357l0.372,0.373
			c1.147-2.733,0.459-5.881-1.683-7.811C94.386,79.402,91.536,78.939,89.056,79.981z"/>
                    <g>
                        <g>
                            <path fill="#A8A9AE" d="M98.024,88.974c-3.122-3.12-6.244-6.239-9.366-9.358C95.919,78.593,98.936,81.818,98.024,88.974z" />
                            <path fill="#A8A9AE" d="M107.009,97.959c-3.119-3.119-6.238-6.239-9.357-9.358C104.88,87.612,107.959,90.771,107.009,97.959z" />
                            <path fill="#A8A9AE" d="M115.995,106.944c-3.121-3.121-6.243-6.242-9.364-9.363C113.798,96.657,116.894,99.803,115.995,106.944z
					"/>
                            <path fill="#A8A9AE" d="M124.985,115.935c-3.125-3.125-6.249-6.251-9.374-9.376C122.76,105.661,125.873,108.798,124.985,115.935
					z"/>
                        </g>
                        <g>
                            <path fill="#A8A9AE" d="M134.024,124.974c-3.122-3.12-6.244-6.239-9.367-9.358C131.919,114.593,134.936,117.818,134.024,124.974
					z"/>
                            <path fill="#A8A9AE" d="M143.009,133.959c-3.119-3.119-6.238-6.239-9.357-9.358C140.88,123.612,143.959,126.771,143.009,133.959
					z"/>
                            <path fill="#A8A9AE" d="M151.995,142.944c-3.121-3.121-6.242-6.242-9.364-9.363
					C149.798,132.657,152.894,135.803,151.995,142.944z"/>
                            <path fill="#A8A9AE" d="M160.985,151.935c-3.125-3.125-6.249-6.251-9.374-9.376C158.76,141.661,161.873,144.798,160.985,151.935
					z"/>
                        </g>
                        <path fill="#A8A9AE" d="M170.024,160.974c-3.122-3.12-6.244-6.239-9.367-9.358C167.919,150.593,170.936,153.818,170.024,160.974z
				"/>
                        <path fill="#A8A9AE" d="M179.009,169.959c-3.119-3.119-6.238-6.239-9.357-9.358C176.88,159.612,179.959,162.771,179.009,169.959z
				"/>
                    </g>
                    <g>
                        <g>
                            <path fill="#A8A9AE" d="M242.232,106.555c-3.122,3.12-6.243,6.24-9.364,9.36C231.849,108.653,235.076,105.639,242.232,106.555z"
                            />
                            <path fill="#A8A9AE" d="M251.222,97.576c-3.121,3.117-6.243,6.234-9.364,9.351C240.874,99.698,244.035,96.621,251.222,97.576z"
                            />
                            <path fill="#A8A9AE" d="M260.213,88.595c-3.123,3.119-6.246,6.239-9.369,9.358C249.924,90.786,253.073,87.692,260.213,88.595z"
                            />
                            <path fill="#A8A9AE" d="M269.21,79.612c-3.127,3.123-6.255,6.245-9.382,9.369C258.934,81.829,262.074,78.719,269.21,79.612z" />
                        </g>
                        <g>
                            <path fill="#A8A9AE" d="M206.209,142.532c-3.122,3.12-6.243,6.24-9.364,9.361C195.826,144.63,199.053,141.616,206.209,142.532z"
                            />
                            <path fill="#A8A9AE" d="M215.199,133.553c-3.121,3.117-6.243,6.234-9.364,9.351
					C204.851,135.675,208.012,132.598,215.199,133.553z"/>
                            <path fill="#A8A9AE" d="M224.19,124.572c-3.123,3.119-6.246,6.239-9.369,9.358C213.901,126.763,217.05,123.669,224.19,124.572z"
                            />
                            <path fill="#A8A9AE" d="M233.187,115.589c-3.127,3.123-6.255,6.245-9.382,9.368
					C222.911,117.806,226.051,114.696,233.187,115.589z"/>
                        </g>
                        <path fill="#A8A9AE" d="M188.167,160.549c-3.123,3.119-6.246,6.239-9.369,9.358C177.878,162.74,181.027,159.646,188.167,160.549z
				"/>
                        <path fill="#A8A9AE" d="M197.164,151.565c-3.127,3.123-6.255,6.245-9.382,9.368C186.888,153.783,190.028,150.673,197.164,151.565
				z"/>
                    </g>
                    <path fill="#A8A9AE" d="M206.085,9.553c-9.906-1.875-41.308-6.474-78.173,6.819c-24.136,8.703-45.528,23.439-61.711,43.017
			c7.931,7.005,19.091,17.037,22.875,20.443c1.782-12.452-0.219-24.547,13.511-34.441c36.681-26.43,115.506-26.494,152.292-0.081
			c13.805,9.913,12.049,21.96,13.515,34.612c0.104-0.003,0.213-0.013,0.319-0.018c6.755-7.598,13.51-15.196,20.266-22.794
			C280.158,47.142,252.334,18.306,206.085,9.553z"/>
                </g>
                <path fill="#F9F9FA" id="Right" style={Right} d="M268.254,79.573c12.512,1.76,24.655-0.37,34.653,13.367c26.604,36.555,27.041,115.379,0.803,152.289
		c-9.847,13.852-21.903,12.153-34.548,13.68l0.283,0.3c0.828-7.077-2.062-10.395-9.359-9.258l0.349,0.347
		c0.806-7.06-2.318-10.154-9.369-9.285l0.344,0.338c0.827-7.099-2.498-10.004-9.394-9.3l0.391,0.386
		c0.633-6.897-2.313-10.183-9.386-9.305c0,0,0.314,0.317,0.315,0.317c-8.77-8.478-17.669-17.362-26.657-26.663
		c-8.766-9.071-17.137-18.044-25.128-26.881c16.973-18.078,33.947-36.157,50.92-54.235c7.141,0.854,10.263-2.273,9.331-9.419
		l-0.382,0.387c7.145,0.865,10.277-2.245,9.319-9.408l-0.377,0.379c7.192,0.917,10.336-2.177,9.313-9.401l-0.371,0.374
		c2.739,1.134,5.883,0.431,7.803-1.72C268.859,84.9,269.308,82.049,268.254,79.573z"/>
                <g>
                    <g>
                        <path fill="#A8A9AE" d="M259.304,88.583c3.105-3.137,6.209-6.274,9.314-9.411C269.675,86.429,266.464,89.461,259.304,88.583z" />
                        <path fill="#A8A9AE" d="M250.362,97.611c3.104-3.134,6.209-6.267,9.313-9.401C260.698,95.433,257.554,98.527,250.362,97.611z" />
                        <path fill="#A8A9AE" d="M241.42,106.64c3.106-3.136,6.213-6.272,9.319-9.408C251.696,104.394,248.564,107.505,241.42,106.64z" />
                        <path fill="#A8A9AE" d="M232.471,115.672c3.11-3.139,6.221-6.279,9.331-9.419C242.734,113.398,239.612,116.526,232.471,115.672z"
                        />
                    </g>
                    <g>
                        <path fill="#A8A9AE" d="M223.475,124.753c3.105-3.137,6.209-6.274,9.314-9.411C233.846,122.599,230.635,125.631,223.475,124.753z
				"/>
                        <path fill="#A8A9AE" d="M214.533,133.781c3.104-3.134,6.209-6.267,9.313-9.401C224.869,131.603,221.725,134.697,214.533,133.781z
				"/>
                        <path fill="#A8A9AE" d="M205.591,142.81c3.106-3.136,6.213-6.272,9.319-9.408C215.867,140.564,212.735,143.675,205.591,142.81z"
                        />
                        <path fill="#A8A9AE" d="M196.642,151.842c3.11-3.139,6.221-6.279,9.331-9.419C206.905,149.569,203.783,152.696,196.642,151.842z"
                        />
                    </g>
                    <path fill="#A8A9AE" d="M187.646,160.924c3.105-3.137,6.209-6.274,9.314-9.411C198.017,158.77,194.806,161.802,187.646,160.924z"
                    />
                    <path fill="#A8A9AE" d="M178.704,169.951c3.104-3.134,6.209-6.267,9.313-9.401C189.04,167.774,185.896,170.868,178.704,169.951z"
                    />
                </g>
                <g>
                    <g>
                        <path fill="#A8A9AE" d="M242.406,232.873c-3.135-3.107-6.27-6.213-9.405-9.32C240.259,222.5,243.289,225.713,242.406,232.873z" />
                        <path fill="#A8A9AE" d="M251.428,241.821c-3.132-3.106-6.263-6.213-9.395-9.319C249.257,231.483,252.349,234.629,251.428,241.821
				z"/>
                        <path fill="#A8A9AE" d="M260.451,250.769c-3.134-3.108-6.268-6.217-9.402-9.325C258.212,240.491,261.321,243.624,260.451,250.769
				z"/>
                        <path fill="#A8A9AE" d="M269.477,259.723c-3.137-3.112-6.275-6.225-9.413-9.337C267.211,249.458,270.336,252.583,269.477,259.723
				z"/>
                    </g>
                    <g>
                        <path fill="#A8A9AE" d="M206.259,197.021c-3.135-3.107-6.27-6.213-9.405-9.32C204.112,186.648,207.142,189.861,206.259,197.021z"
                        />
                        <path fill="#A8A9AE" d="M215.281,205.969c-3.132-3.106-6.263-6.213-9.395-9.319C213.11,195.631,216.202,198.777,215.281,205.969z
				"/>
                        <path fill="#A8A9AE" d="M224.304,214.916c-3.134-3.108-6.268-6.217-9.402-9.325C222.065,204.639,225.173,207.772,224.304,214.916
				z"/>
                        <path fill="#A8A9AE" d="M233.33,223.871c-3.137-3.112-6.275-6.225-9.413-9.337C231.064,213.606,234.189,216.731,233.33,223.871z"
                        />
                    </g>
                    <path fill="#A8A9AE" d="M188.157,179.064c-3.134-3.108-6.268-6.217-9.402-9.325C185.917,168.786,189.026,171.92,188.157,179.064z"
                    />
                    <path fill="#A8A9AE" d="M197.183,188.019c-3.137-3.112-6.275-6.225-9.413-9.337C194.916,177.754,198.042,180.878,197.183,188.019z
			"/>
                </g>
                <path fill="#A8A9AE" d="M339.236,196.267c8.851-54.358-11.191-107.466-50.499-139.646c-6.967,7.965-16.946,19.171-20.335,22.971
		c12.46,1.723,24.546-0.336,34.505,13.348c26.604,36.555,27.041,115.379,0.803,152.29c-9.847,13.852-21.903,12.153-34.548,13.679
		c0.004,0.104,0.014,0.213,0.02,0.319l23.789,23.16C317.193,259.449,333.798,229.665,339.236,196.267z"/>
                <g>
                    <g>
                        <path fill="#A8A9AE" d="M100.473,252.147c-3.094,3.147-6.188,6.294-9.282,9.442C90.109,254.336,93.309,251.293,100.473,252.147z"
                        />
                        <path fill="#A8A9AE" d="M109.385,243.089c-3.094,3.144-6.188,6.288-9.281,9.432C99.056,245.302,102.19,242.197,109.385,243.089z"
                        />
                        <path fill="#A8A9AE" d="M118.296,234.03c-3.096,3.146-6.192,6.293-9.287,9.439C108.027,236.311,111.149,233.189,118.296,234.03z"
                        />
                        <path fill="#A8A9AE" d="M127.215,224.968c-3.1,3.15-6.2,6.3-9.299,9.45C116.959,227.276,120.071,224.138,127.215,224.968z" />
                    </g>
                    <g>
                        <path fill="#A8A9AE" d="M136.18,215.856c-3.094,3.147-6.188,6.294-9.282,9.442C125.816,218.045,129.017,215.002,136.18,215.856z"
                        />
                        <path fill="#A8A9AE" d="M145.092,206.799c-3.094,3.144-6.188,6.288-9.281,9.432C134.763,209.011,137.897,205.907,145.092,206.799
				z"/>
                        <path fill="#A8A9AE" d="M154.004,197.74c-3.096,3.146-6.192,6.293-9.287,9.439C143.735,200.02,146.856,196.899,154.004,197.74z"
                        />
                        <path fill="#A8A9AE" d="M162.922,188.678c-3.1,3.15-6.2,6.3-9.299,9.45C152.666,190.986,155.778,187.848,162.922,188.678z" />
                    </g>
                    <path fill="#A8A9AE" d="M171.887,179.566c-3.094,3.147-6.188,6.294-9.282,9.442C161.524,181.755,164.724,178.712,171.887,179.566z
			"/>
                    <path fill="#A8A9AE" d="M180.799,170.508c-3.094,3.144-6.188,6.288-9.281,9.432C170.47,172.721,173.604,169.616,180.799,170.508z"
                    />
                </g>
            </g>
        </svg>
    );
}

export {
    FullTeethSurface,
    TeethSurfaceWithoutCenter
}