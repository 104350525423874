import { useForm, Controller } from "react-hook-form";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../components/Input";
import CustomButton from "../../components/Button";
import { apiRequest } from "../../app/services/requests";
import CustomSelectPicker from "../../components/SelectPicker";
import { statusApprovedRejected } from "./types";
import { useState } from "react";
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

const ApproveRejectForm = (props: any) => {
  const {
    isClientHistory = false,
    memberId,
    onClose,
    planId,
    userType,
    documents = [],
  } = props;
  const [tab, setTab] = useState(0);
  const userDefaultValues = () => {
    return {
      comments: "",
      caseHistoryStatus: "",
    };
  };
  const navigate = useNavigate();

  const schema = object({
    comments: string().required("Comments are required"),
    caseHistoryStatus: string().required("Comments are required"),
  });

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...userDefaultValues() },
  });

  const onSubmit = (formData: any) => {
    apiRequest({
      url: isClientHistory
        ? `plan/${planId}/update-case-history-status`
        : `plan/${planId}/family-member/${memberId}/update-case-history-status`,
      method: "PATCH",
      data: {
        [statusApprovedRejected[userType]]: formData?.caseHistoryStatus,
        [statusApprovedRejected[`${userType}-comment`]]: formData?.comments,
      },
    })
      .then((res: any) => {
        onClose();
        setTimeout(() => {
          props?.onGetClientFamilyMembers();
        }, 100);
      })
      .catch((err: any) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  return (
    <>
      <Tabs
        value={tab}
        onChange={(event, newValue) => {
          setTab(newValue);
        }}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
      >
        {documents.map((doc: any, index: number) => (
          <Tab key={index} label={`Document${index + 1}`} value={index} />
        ))}
      </Tabs>
      {documents.map((doc: any, index: number) => (
        <TabPanel value={tab} key={index} index={tab}>
          {tab === index && (
            <>
              {doc && (
                <img src={doc} alt="Document" style={{ width: "100%" }} />
              )}
            </>
          )}
        </TabPanel>
      ))}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Controller
              name="caseHistoryStatus"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectPicker
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Select Status"}
                  size={"small"}
                  fullWidth={true}
                  width={"100%"}
                  selectData={[
                    {
                      id: "approved",
                      name: "Approved",
                    },
                    {
                      id: "rejected",
                      name: "Rejected",
                    },
                  ]}
                  noneRequired={false}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="comments"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Enter Comments..."}
                  multiline
                  size={"small"}
                  rows={3}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <CustomButton
            border="1px solid #000000"
            color="#000000"
            title="Cancel"
            bgColor="#ffffff"
            onClick={() => props.onClose()}
            borderRadius="5px"
          />
          <Box marginLeft={1}>
            <CustomButton title={"Submit"} type="submit" borderRadius="5px" />
          </Box>
        </Box>
      </form>
    </>
  );
};

export default ApproveRejectForm;
