import React, { useState } from "react";
import { IRATable } from "../../components/ira-table/ira-table";
import DateTimeCell from "../../components/TableComponent/date-time-cell";
import { IRATableColumnProps } from "../../components/ira-table/ira-table.interfaces";
import NameCell from "../../components/TableComponent/name-cell/name-cell";
import ActionCell from "../../components/TableComponent/action-cell";
import Breadcrumb from "../../components/Breadcrumb";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import IRATypography from "../../components/ira-typography";
import { Box } from "@mui/material";
import CustomButton from "../../components/Button";
import CustomDialog from "../../components/CustomDialog";
import SelectClient from "../../components/SelectClient";
import { theme } from "../../theme/theme";

interface ISelectDialog {
  open?: boolean;
  data?: any;
}

const QueryList = (props: any) => {
  const { showOnlyList = true, hideBreadScrum } = props;
  const navigate = useNavigate();
  const [openSelectClientDialog, setOpenSelectClientDialog] =
    useState<ISelectDialog>({
      open: false,
      data: {},
    });

  const result = [
    {
      id: "123",
      createdAt: "2024-01-10T10:37:43.271+00:00",
      patient: {
        id: 1,
        firstName: "Nick",
        lastName: "JK",
        mobile: 9988665577,
      },
      service: "OP",
      totalCost: 15000,
      claim: 10000,
      status: "Claim",
    },
    {
      id: "124",
      createdAt: "2024-01-10T10:37:43.271+00:00",
      patient: {
        id: 1,
        firstName: "Nick",
        lastName: "JK",
        mobile: 9988665577,
      },
      service: "Treatment",
      totalCost: 15000,
      claim: 10000,
      status: "New Case",
    },
  ];

  const breadcrumbs = [
    <RouterLink to="/Dashboard" style={{ textDecoration: "none" }}>
      <IRATypography key="2" textVariant="h12WithSemiBold">
        Home
      </IRATypography>
    </RouterLink>,
    <IRATypography key="2" textVariant="h12WithSemiBold">
      Billing
    </IRATypography>,
  ];

  const activityTableColumns = () =>
    [
      {
        name: "queryId",
        label: "Query ID",
        component: NameCell,
        sortable: false,
        style: { width: "250px", color: theme.palette.customColors.main },
      },
      {
        name: "caseId",
        label: "Case ID",
        component: NameCell,
        sortable: false,
        style: { width: "250px", color: theme.palette.customColors.main },
      },
      {
        name: "createdAt",
        label: "Applied Date",
        component: DateTimeCell,
        sortable: false,
        style: { width: "250px", color: "#CB8C00" },
      },
      {
        name: "patientName",
        label: "Patient Name",
        component: NameCell,
        sortable: false,
        style: { width: "250px" },
      },
      {
        name: "queryName",
        label: "Query Name",
        component: NameCell,
        sortable: false,
        style: { width: "250px" },
      },
      {
        name: "queryType",
        label: "Query Type",
        component: NameCell,
        sortable: false,
        style: { width: "250px", color: theme.palette.customColors.main },
      },
      {
        name: "action",
        label: "Action",
        component: (props: any) => {
          return ActionCell({
            button: true,
            btnVariant: "primary",
            btnSize: "smallest",
            disabled: false,
            infoMessage: null,
            label: "View",
            onClick: () => {
              // onViewResult(props?.data);
              navigate(`/cases/${"ca6090934"}/details/${"case-history"}`);
            },
            ...props,
          } as any); // <-- This 'as any' is not needed and might be causing the issue
        },
      } as any,
    ] as Array<IRATableColumnProps>;

  const activityTableData = () => {
    return result?.map((row: any) => {
      return {
        ...row,
        queryId: {
          name: row?.id,
        },
        caseId: {
          name: row?.id,
        },
        createdAt: {
          dateTime: row?.createdAt,
        },
        patientName: {
          name: `${row?.patient?.firstName} ${row?.patient?.lastName} `,
        },
        queryName: {
          name: `Waht is lorrem ipsum content?`,
        },
        queryType: {
          name: "Approval",
        },
        action: {
          data: {
            id: row?.id,
            status: "reviewed",
            project: {
              id: row?.id,
            },
          },
        },
      };
    });
  };

  const handleButtonClick = (type: string) => {
    if (type === "addCase") {
      setOpenSelectClientDialog({ open: true });
    } else {
      navigate(`/cases/${"ca6090934"}/details/${"case-history"}`);
    }
  };

  return (
    <>
      {!hideBreadScrum && (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Breadcrumb title={`Query List`} breadcrumbsArr={[]} />

          <Box style={{ display: "flex", gap: "8px" }}>
            <CustomButton
              onClick={() => handleButtonClick("Button1")}
              title={"Export Cases"}
            />
          </Box>
        </Box>
      )}

      {showOnlyList && (
        <Box>
          <IRATable
            columns={activityTableColumns()}
            data={activityTableData()}
            onSort={(name: string, order: string) =>
              alert(`sort requested for ${name} with order ${order}`)
            }
          />
          {openSelectClientDialog.open && (
            <CustomDialog
              width={"400px"}
              open={openSelectClientDialog.open}
              handleClose={() =>
                setOpenSelectClientDialog({ open: false, data: {} })
              }
              title={"Select Client"}
              maxWidth={"lg"}
            >
              <Box>
                <SelectClient
                  onClose={() =>
                    setOpenSelectClientDialog({ open: false, data: {} })
                  }
                  navigateTo={(data: string) => {
                    navigate(`/cases/client/${"121213"}/add`);
                  }}
                />
              </Box>
            </CustomDialog>
          )}
        </Box>
      )}
    </>
  );
};

export default React.memo(QueryList);
