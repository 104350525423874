// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userView_card_height_width__lpFD8 {

    padding: 16px;
}
.userView_card_plan_career_etails__elVf9{
    padding-right: 20px;
}
.userView_personImage1__9TD8R{
    height: 120px;
    width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/screens/Users/UserView/userView.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;AACjB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,YAAY;AAChB","sourcesContent":[".card_height_width {\n\n    padding: 16px;\n}\n.card_plan_career_etails{\n    padding-right: 20px;\n}\n.personImage1{\n    height: 120px;\n    width: 200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card_height_width": `userView_card_height_width__lpFD8`,
	"card_plan_career_etails": `userView_card_plan_career_etails__elVf9`,
	"personImage1": `userView_personImage1__9TD8R`
};
export default ___CSS_LOADER_EXPORT___;
