import { Box, Grid, Typography } from "@mui/material";
import CustomButton from "../../../components/Button";
import { theme } from "../../../theme/theme";

import { useEffect, useState } from "react";
import { PaymentSuccessScreen } from "../components/payment-success";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "../../../app/services/requests";
import { useSnackbar } from "../../../app/context/useSnackbar";

declare global {
  interface Window {
    Razorpay: any;
  }
}

const Step3 = (props: any) => {
  const { onClickBack } = props;
  const { openSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const { clientId, orderId } = useParams<any>();

  const [clientDetails, setClientDetails] = useState("") as any;

  const [showPaymentSuccessScreen, setShowPaymentSuccessScreen] =
    useState<any>(false);

  const [orderDetails, setOrderDetails] = useState<any>({});

  const [orderSuccess, setOrderSuccess] = useState({});

  useEffect(() => {
    getClientDetails();
    if (orderId) {
      getOrderDetailsById(orderId);
    }
  }, [orderId]);

  const getClientDetails = () => {
    apiRequest({
      url: `client/${clientId}`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          setClientDetails(res);
        }
      })
      .catch((err) => {
        console.error("err", err);
      });
  };

  const getOrderDetailsById = (orderId?: any) => {
    apiRequest({
      url: `order/record/${orderId}`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          setOrderDetails({ ...res });
        }
      })
      .catch((err) => {
        console.error("err", err);
      });
  };

  function loadScript(src?: any) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const proceedPayment = async (orderId?: any) => {
    try {
      const res = await apiRequest({
        url: `order/${orderId}/proceed-payment`,
        method: "POST",
      });
      setOrderDetails(res);
      return res;
    } catch (err: any) {
      openSnackbar(err?.response?.data?.message, "error");
      throw err;
    }
  };

  function formatAmountForPayment(amount: number, currency: string): number {
    const threeDecimalCurrencies = ["BHD", "JOD", "KWD", "OMR", "TND"]; // Add more if needed

    if (threeDecimalCurrencies.includes(currency)) {
      const amountStr = amount.toFixed(3); // Ensure three decimal places
      const adjustedAmount = amountStr.slice(0, -1) + "0"; // Replace last digit with 0
      return Math.round(parseFloat(adjustedAmount) * 100); // Convert to minor units
    }

    return Math.round(amount * 100); // Default for two-decimal currencies
  }

  async function displayRazorpay() {
    try {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        openSnackbar(
          "Razorpay SDK failed to load. Please check your internet connection.",
          "error"
        );
        return;
      }

      if (!orderDetails?.id) {
        openSnackbar("Order details are missing. Please try again.", "error");
        return;
      }

      // ✅ Wait for updated order details
      const updatedOrder: any = await proceedPayment(orderDetails?.id);
      if (!updatedOrder) {
        openSnackbar("Failed to proceed with payment.", "error");
        return;
      }

      const {
        id: orderId,
        totalPriceAfterTax = 2000,
        currency = "INR",
      } = updatedOrder;

      const formattedAmount = formatAmountForPayment(
        totalPriceAfterTax,
        currency
      );

      if (!window.Razorpay) {
        openSnackbar(
          "Razorpay is not available. Please refresh the page and try again.",
          "error"
        );
        return;
      }

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: formattedAmount,
        currency: currency,
        name: "Dentassure",
        description: "Test Transaction",
        image: require("../../../app/assets/logo.png"),
        order_id: updatedOrder?.payment?.orderId, // ✅ Use updated order details
        handler: async (paymentResponse: any) => {
          try {
            const paymentData = {
              orderId: updatedOrder?.payment?.orderId, // ✅ Use updated order ID
              paymentId: paymentResponse.razorpay_payment_id,
            };

            const response = await apiRequest({
              url: `order/${updatedOrder.id}/status`,
              data: paymentData,
              method: "PATCH",
            });
            if (response?.status === "success") {
              openSnackbar("Your payment was successful!", "success");
              setOrderSuccess(response);
              setTimeout(() => navigate("/dashboard"), 3000);
            } else {
              openSnackbar("Payment failed. Please try again.", "error");
            }
          } catch (error: any) {
            console.error("Payment API Error:", error);
            openSnackbar(
              error?.response?.data?.message || "Payment processing failed.",
              "error"
            );
          }
        },
        prefill: {
          name: `${clientDetails?.firstName ?? ""} ${
            clientDetails?.lastName ?? ""
          }`,
          email: clientDetails?.email ?? "",
          contact: clientDetails?.mobile ?? "",
        },
        notes: {
          address: "Example Corporate Office",
        },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error("Razorpay Payment Error:", error);
      openSnackbar(
        "An unexpected error occurred. Please try again later.",
        "error"
      );
    }
  }

  return (
    <>
      <Box p={2}>
        {showPaymentSuccessScreen ? (
          <PaymentSuccessScreen />
        ) : (
          <Box p={"0px 20px 50px 20px"} mt={"10px"}>
            <Typography
              mt={"10px"}
              variant="h6"
              color={theme.palette.customColors.blackGrey}
              fontWeight={"600"}
              fontSize={"16px"}
            >
              Plan Details:
            </Typography>
            <Box display={"flex"} justifyContent={"space-between"} mt={"20px"}>
              <Typography variant="body2">
                Plan Name : <b>Simple Plan</b>
              </Typography>
              <Typography variant="body2">
                Plan Range : <b>Family</b>
              </Typography>
              <Typography variant="body2">
                Plan Period : <b>One Year</b>
              </Typography>
            </Box>
          </Box>
        )}

        {showPaymentSuccessScreen === false && (
          <Grid
            container
            flexDirection={"row"}
            spacing={0.5}
            justifyContent={"end"}
            mt={4}
          >
            <Grid item>
              <CustomButton
                border="1px solid #000000"
                color="#000000"
                title="Back"
                bgColor="#ffffff"
                handleButtonClick={() =>
                  navigate(
                    `/client/${clientId}/plan/standard/2/${orderDetails?.planId}`
                  )
                }
                borderRadius="5px"
              />
            </Grid>
            <Grid item>
              <CustomButton
                border="1px solid #000000"
                color="#000000"
                title="Cancel"
                bgColor="#ffffff"
                handleButtonClick={() => navigate("/dashboard")}
                borderRadius="5px"
              />
            </Grid>
            <Grid item>
              <CustomButton
                type="submit"
                title={"Pay Now"}
                backgroundcolor={"#204289"}
                bordercolor={"#204289"}
                color={"#fffff"}
                handleButtonClick={displayRazorpay}
                width={110}
                padding={7}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default Step3;
