import React, { useEffect, useState } from "react";
import { IRATable } from "../../components/ira-table/ira-table";
import DateTimeCell from "../../components/TableComponent/date-time-cell";
import { IRATableColumnProps } from "../../components/ira-table/ira-table.interfaces";
import NameCell from "../../components/TableComponent/name-cell/name-cell";
import ActionCell from "../../components/TableComponent/action-cell";
import Breadcrumb from "../../components/Breadcrumb";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import IRATypography from "../../components/ira-typography";
import { Box, Grid, InputAdornment } from "@mui/material";
import CustomButton from "../../components/Button";
import CustomDialog from "../../components/CustomDialog";
import SelectClient from "../../components/SelectClient";
import ResponseCell from "../../components/TableComponent/responce-cell/response-cell";
import { apiRequest } from "../../app/services/requests";
import CustomInput from "../../components/Input";
import { Close, Search } from "@mui/icons-material";
import CustomCard from "../../components/Card";
import moment from "moment";

const CaseList = (props: any) => {
  const { showOnlyList = true, hideBreadScrum } = props;
  const navigate = useNavigate();
  const [openSelectClientDialog, setOpenSelectClientDialog] = useState<any>({
    open: false,
    data: {},
  });

  const [caseList, setCaseList] = useState({ data: [], totalCount: 0 });
  const [searchTerm, setSearchTerm] = React.useState("");

  useEffect(() => {
    getAllCaseList();
  }, [searchTerm]);
  const getAllCaseList = () => {
    const payload = {
      ...(searchTerm && {
        searchText: searchTerm,
      }),
    };
    apiRequest({
      url: `case/list`,
      data: payload,

      method: "POST",
    })
      .then((res: any) => {
        if (res) {
          setCaseList(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  const breadcrumbs = [
    <RouterLink to="/Dashboard" style={{ textDecoration: "none" }} key="1001">
      <IRATypography
        key="2"
        textVariant="h12WithSemiBold"
        sx={{ fontSize: 14, color: "#222222" }}
      >
        Home
      </IRATypography>
    </RouterLink>,
    <IRATypography
      key="3"
      textVariant="h12WithSemiBold"
      sx={{ fontSize: 14, color: "#222222" }}
    >
      Cases List
    </IRATypography>,
  ];

  const activityTableColumns = () =>
    [
      {
        name: "caseId",
        label: "Case ID",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "createdAt",
        label: "Applied Date",
        component: DateTimeCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "clientName",
        label: "Client Name",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "service",
        label: "Service",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "totalCost",
        label: "Total Cost",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "claim",
        label: "Claim",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "status",
        label: "Status",
        component: ResponseCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "action",
        label: "Action",
        component: (props: any) => {
          return ActionCell({
            button: true,
            btnVariant: "primary",
            btnSize: "smallest",
            disabled: false,
            infoMessage: null,
            label: "View",
            onClick: () => {
              // onViewResult(props?.data);
              navigate(`/cases/${props?.data?.id}/details/${"case-history"}`);
            },
            ...props,
          } as any); // <-- This 'as any' is not needed and might be causing the issue
        },
      },
      {
        name: "action",
        label: "Action",
        component: (props: any) => {
          return ActionCell({
            button: true,
            btnVariant: "primary",
            btnSize: "smallest",
            disabled: false,
            infoMessage: null,
            label: "edit",
            onClick: () => {
              // onViewResult(props?.data);
              navigate(`/cases/client/${props?.data?.id}/3/edit`);
            },
            ...props,
          } as any); // <-- This 'as any' is not needed and might be causing the issue
        },
      } as any,
    ] as Array<IRATableColumnProps>;

  const activityTableData = () => {
    return caseList?.data?.map((row: any) => {
      return {
        ...row,
        caseId: {
          name: row?.id,
        },

        createdAt: {
          dateTime: moment(row?.createdAt).format("DD-MM-YYYY"),
        },

        clientName: {
          name: `${row?.client?.firstName} ${row?.client?.lastName} `,
        },
        service: {
          name: "A", //row?.service,
        },
        totalCost: {
          name: `${row?.totalCost ?? 0}`,
        },
        claim: {
          name: `${row?.claim ?? 0}`,
        },
        status: {
          response: `${row?.status ?? ""}`,
        },
        action: {
          data: {
            id: row?.id,
            status: "reviewed",
            project: {
              id: row?.id,
            },
          },
        },
      };
    });
  };

  const handleButtonClick = (type: string) => {
    setOpenSelectClientDialog({ open: true, data: { type: type } });
  };

  return (
    <>
      {!hideBreadScrum && (
        <Box
          sx={{ color: "#222222" }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Breadcrumb
            title={`Cases List`}
            key="case-list-breadcrumb"
            breadcrumbsArr={breadcrumbs}
          />

          <Box style={{ display: "flex", gap: "8px" }} sx={{ pt: 3, pb: 3 }}>
            <CustomButton
              onClick={() => handleButtonClick("Button1")}
              title={"Export List"}
              backgroundColor={"#5C4EFF"}
            />
            <CustomButton
              onClick={() => handleButtonClick("addCase")}
              title={"+ Add Case"}
              backgroundColor={"#5C4EFF"}
            />
          </Box>
        </Box>
      )}

      <Box>
        <CustomCard>
          <Grid container sx={{ padding: "10px" }}>
            <Grid item md={3} sx={{ background: "#F0F2F6", pb: "10px" }}>
              <CustomInput
                size={"small"}
                placeholder="Search by Client"
                placeholderColor="#204289"
                placeholderColorOpacity={1}
                placeholderSize={15}
                value={searchTerm}
                onChange={(event: any) => setSearchTerm(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      {searchTerm.length > 0 && (
                        <InputAdornment
                          sx={{ cursor: "pointer" }}
                          position="end"
                          onClick={() => setSearchTerm("")}
                        >
                          <Close />
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
            </Grid>

            <Grid />
            <Box>
              {/* <p>Hello</p> */}
              <IRATable
                columns={activityTableColumns()}
                data={activityTableData()}
                onSort={(name: string, order: string) =>
                  alert(`sort requested for ${name} with order ${order}`)
                }
              />
            </Box>
          </Grid>
        </CustomCard>
      </Box>

      {/* <CustomDialog>
        <Box>
          <SelectClient
            onClose={() => setOpenSelectClientDialog({ open: false, data: {} })}
            navigateTo={(data: string) => {
              navigate(`/cases/client/${"121213"}/add`);
            }}
          />
        </Box>
      </CustomDialog> */}
      {openSelectClientDialog.open && (
        // <CustomAutocomplete></CustomAutocomplete>
        <CustomDialog
          width={"600px"}
          open={openSelectClientDialog.open}
          handleClose={() =>
            setOpenSelectClientDialog({ open: false, data: {} })
          }
          title={"Select Client"}
          maxWidth={"lg"}
        >
          <>
            <Box>
              <SelectClient
                onClose={() =>
                  setOpenSelectClientDialog({ open: false, data: {} })
                }
                openSelectClientDialog={openSelectClientDialog}
                fromCommomnDashboard={true}
              />
            </Box>
          </>
        </CustomDialog>
      )}
    </>
  );
};

export default React.memo(CaseList);
