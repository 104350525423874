import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { object, string, number, TestContext, SchemaOf } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "../../../components/Input";
import CustomCheckbox from "../../../components/CustomCheckBox";
import CustomButton from "../../../components/Button";
import OtherServiceSetup from "./other-setup";
import CustomSelectPicker from "../../../components/SelectPicker";
import { SELECT_TYPES_FOR_SERVICE_SETUP } from "../../../app/utilities/constants";
import { apiRequest } from "../../../app/services/requests";
import { useNavigate } from "react-router-dom";

const ServiceSetup = (props: any) => {
  const { serviceId, planId, onClose } = props;
  const navigate = useNavigate();
  const [masterPlanTreatment, setMasterPlanTreatment] = useState<any>({});

  // Options for tooth categories
  const toothCategoryOptions = [
    { id: "fullMouth", name: "Full Mouth" },
    { id: "upperArch", name: "Upper Arch" },
    { id: "lowerArch", name: "Lower Arch" },
    { id: "quadrant1", name: "Quadrant 1" },
    { id: "quadrant2", name: "Quadrant 2" },
    { id: "quadrant3", name: "Quadrant 3" },
    { id: "quadrant4", name: "Quadrant 4" },
    { id: "custom", name: "Custom" },
  ];

  // Define the quadrants and teeth numbers for each category
  const allQuadrants = [
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "51",
    "52",
    "53",
    "54",
    "55",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "61",
    "62",
    "63",
    "64",
    "65",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "71",
    "72",
    "73",
    "74",
    "75",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "81",
    "82",
    "83",
    "84",
    "85",
  ];

  const teethNumbers = {
    fullMouth: allQuadrants,
    upperArch: [
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
    ],
    lowerArch: [
      "31",
      "32",
      "33",
      "34",
      "35",
      "36",
      "37",
      "38",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    quadrant1: [
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "51",
      "52",
      "53",
      "54",
      "55",
    ],
    quadrant2: [
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "61",
      "62",
      "63",
      "64",
      "65",
    ],
    quadrant3: [
      "31",
      "32",
      "33",
      "34",
      "35",
      "36",
      "37",
      "38",
      "71",
      "72",
      "73",
      "74",
      "75",
    ],
    quadrant4: [
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "81",
      "82",
      "83",
      "84",
      "85",
    ],
    custom: allQuadrants,
    other: [
      "Scalling",
      "X-ray",
      "Biopsy",
      "Medication",
      "Mesiodens",
      "Ortho Treatment",
      "Paramolar",
    ],
  } as any;

  // "Other" teeth options
  const otherTeeth = [
    "Scalling",
    "X-ray",
    "Biopsy",
    "Medication",
    "Mesiodens",
    "Ortho Treatment",
    "Paramolar",
  ];

  // Define default form values
  const defaultValues = {
    selectType: "teeth",
    limit: "",
    period: "",
    limitPerPeriod: "",
    toothCategory: "fullMouth",
    eligibleTooths: [],
  } as any;

  // Define the form values interface
  interface FormValues {
    limit: number;
    period: number;
    limitPerPeriod: number;
  }

  // Validation schema
  const validationSchema: SchemaOf<FormValues> = object({
    limit: number()
      .transform((value, originalValue) => {
        if (originalValue === "") return undefined; // Convert empty string to undefined
        if (isNaN(value)) throw new TypeError("Value must be a valid number");
        return value;
      })
      .required("Total Limit is required")
      .test({
        name: "max-regular-teeth",
        message: "Limit cannot be greater than selected teeth count",
        test: function (this: TestContext, value: any) {
          // Ensure the function has access to the correct context
          const { regularTeethCount: any, otherTeethCount } =
            calculateSelectedCounts();
          return (
            value !== undefined && value <= regularTeethCount + otherTeethCount
          );
        } as any,
      }),

    period: number()
      .transform((value, originalValue) => {
        if (originalValue === "") return undefined; // Convert empty string to undefined
        if (isNaN(value)) throw new TypeError("Value must be a valid number");
        return value;
      })
      .required("Period is required")
      .min(0, "Period must be at least 0 days")
      .max(365, "Period must be at most 365 days"), // Example max, adjust as needed

    limitPerPeriod: number()
      .transform((value, originalValue) => {
        if (originalValue === "") return undefined; // Convert empty string to undefined
        if (isNaN(value)) throw new TypeError("Value must be a valid number");
        return value;
      })
      .required("Period Limit is required")
      .test({
        name: "max-limit-per-period",
        message: "Limit per period cannot be greater than period",
        test: function (this: TestContext, value) {
          const limit = this.parent.limit;
          return value !== undefined && value <= limit;
        },
      }),
  });

  // Initialize useForm
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
    watch,
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  // Watch selected tooth category
  const selectedToothCategory = useWatch({
    control,
    name: "toothCategory",
    defaultValue: "fullMouth",
  });

  // Watch selected tooth category
  const selectedType = useWatch({
    control,
    name: "selectType",
    defaultValue: "teeth",
  });

  const limitValue = useWatch({
    control,
    name: "limit",
  });

  // Watch the eligibleTooths field
  const eligibleTooths = watch("eligibleTooths");

  useEffect(() => {
    if (serviceId) {
      getServicesByMasterTreatment(serviceId);
    }
  }, [serviceId]);

  useEffect(() => {
    if (selectedType) {
      setValue("eligibleTooths", []);
      if (selectedType === "other") {
        setValue("toothCategory", "other");
      } else {
        setValue("toothCategory", "fullMouth");
      }
    }
  }, [selectedType]);

  const getServicesByMasterTreatment = (serviceId: any) => {
    apiRequest({
      url: `master-plan-treatment/${serviceId}/record`,
      method: "GET",
    })
      .then((res: any) => {
        if (res) {
          if (res?.type) {
            setMasterPlanTreatment(res);
            // Use setValue to update specific form fields
            setValue(
              "toothCategory",
              res.type === "other" ? "other" : "fullMouth"
            );
            setValue("limit", res.limit.toString());
            setValue("period", res.period.toString());
            setValue("limitPerPeriod", res.limitPerPeriod.toString());
            setValue("eligibleTooths", res.eligibleTeeth);
            setValue("selectType", res.type);
          }
        }
      })
      .catch((err) => {
        // Handle error
      });
  };

  // Automatically set all teeth numbers for the selected category and reset previous selections
  useEffect(() => {
    if (!masterPlanTreatment?.type) {
      if (selectedToothCategory === "custom") {
        // For custom category, do not set any teeth numbers
        setValue("eligibleTooths", []);
      } else {
        // For other categories, set all teeth numbers for the selected category
        // setValue("eligibleTooths", teethNumbers[selectedToothCategory] || []);
      }
    }
  }, [selectedToothCategory, setValue]);
  // Handle form submission
  const onSubmit = (formData: any) => {
    const { selectType, eligibleTooths, limit, period, limitPerPeriod } =
      getValues();
    const payload = {
      type: selectType,
      eligibleTeeth: eligibleTooths,
      limit: Number(limit),
      period: Number(period),
      limitPerPeriod: Number(limitPerPeriod),
    };

    apiRequest({
      url: `master-plan/${planId}/treatment-setup/${serviceId}`,
      data: payload,
      method: "PATCH",
    })
      .then((res: any) => {
        alert("Service setup has been completed successfully!");
        onClose("fetch");
      })
      .catch((err) => {
        // if (err?.statusCode === 403 || err?.statusCode === 401) {
        //   if (navigate) {
        //     navigate("/login"); // Navigate to "/login" route
        //   }
        // }
      });
  };

  // Function to count selected teeth and "other" teeth
  const calculateSelectedCounts = () => {
    const selectedTeeth = getValues("eligibleTooths") || [];
    const regularTeethCount = selectedTeeth.filter((tooth: string) =>
      teethNumbers[selectedToothCategory]?.includes(tooth)
    ).length;
    const otherTeethCount = selectedTeeth.filter((tooth: string) =>
      otherTeeth.includes(tooth)
    ).length;

    return { regularTeethCount, otherTeethCount };
  };

  const [allSelected, setAllSelected] = useState(false); // State to track selection status
  const { regularTeethCount, otherTeethCount } = calculateSelectedCounts();

  // useEffect hook to recalculate when eligibleTooths changes
  useEffect(() => {
    calculateSelectedCounts();
  }, [eligibleTooths]); // This will run whenever `eligibleTooths` changes

  const toggleAllSelection = () => {
    if (allSelected) {
      setValue("eligibleTooths", []); // Deselect all
    } else {
      setValue("eligibleTooths", [
        ...teethNumbers[selectedToothCategory],
        ...otherTeeth,
      ]); // Select all
    }
    setAllSelected(!allSelected); // Toggle the selection state
  };

  return (
    <>
      <Box mt={5} ml={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} style={{ display: "flex" }}>
            <Controller
              name={`selectType`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl component="fieldset">
                  <RadioGroup {...field} row>
                    {SELECT_TYPES_FOR_SERVICE_SETUP.map(
                      (option: any, idx: any) => (
                        <FormControlLabel
                          key={idx}
                          value={option.id}
                          control={<Radio />}
                          label={option.name}
                        />
                      )
                    )}
                  </RadioGroup>
                  {error && (
                    <FormHelperText sx={{ color: "#d32f2f !important" }}>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          {selectedType === "teeth" && (
            <>
              <Grid item xs={6}>
                {/* Tooth Category Selector */}
                <Controller
                  name="toothCategory"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomSelectPicker
                      {...field}
                      label="Tooth Category"
                      error={!!error}
                      helperText={error ? error.message : ""}
                      size={"small"}
                      fullWidth={true}
                      width={"100%"}
                      selectData={toothCategoryOptions}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={3}>
                <CustomButton
                  onClick={toggleAllSelection}
                  variant="contained"
                  title={
                    allSelected ? "Deselect All Teeth" : "Select All Teeth"
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {`Total selected: ${
                    regularTeethCount + otherTeethCount
                  } (${regularTeethCount} teeth, ${otherTeethCount} other)`}
                </Typography>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            {/* Form Content */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box>
                {/* Teeth Numbers Selection */}

                {selectedType === "teeth" && (
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "16px",
                      flexWrap: "wrap",
                    }}
                  >
                    {[
                      ...teethNumbers[selectedToothCategory],
                      ...otherTeeth,
                    ]?.map((toothNumber: any, idx: any) => (
                      <Controller
                        key={idx}
                        name="eligibleTooths"
                        control={control}
                        render={({ field }) => (
                          <CustomCheckbox
                            checked={Boolean(
                              field?.value?.includes(toothNumber)
                            )}
                            onChange={() => {
                              const updatedTooths = field?.value?.includes(
                                toothNumber
                              )
                                ? field.value.filter(
                                    (number: string) => number !== toothNumber
                                  )
                                : [...field?.value, toothNumber];
                              setValue("eligibleTooths", updatedTooths);
                            }}
                            label={toothNumber}
                          />
                        )}
                      />
                    ))}
                  </Box>
                )}

                {selectedType === "other" && (
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "16px",
                      flexWrap: "wrap",
                    }}
                  >
                    {[...teethNumbers[selectedToothCategory]]?.map(
                      (toothNumber: any, idx: any) => (
                        <Controller
                          key={idx}
                          name="eligibleTooths"
                          control={control}
                          render={({ field }) => (
                            <CustomCheckbox
                              checked={Boolean(
                                field?.value?.includes(toothNumber)
                              )}
                              onChange={() => {
                                const updatedTooths = field?.value?.includes(
                                  toothNumber
                                )
                                  ? field.value.filter(
                                      (number: string) => number !== toothNumber
                                    )
                                  : [...field?.value, toothNumber];
                                setValue("eligibleTooths", updatedTooths);
                              }}
                              label={toothNumber}
                            />
                          )}
                        />
                      )
                    )}
                  </Box>
                )}

                {/* Total Limit Section */}
                <Grid item xs={12} mb={2} mt={4}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={6}>
                      <Controller
                        name="limit"
                        control={control}
                        render={({ field }) => (
                          <CustomInput
                            {...field}
                            error={!!errors.limit}
                            helperText={
                              errors.limit ? errors.limit.message : ""
                            }
                            label={"Total Limit"}
                            placeholder={"Ex 5"}
                            size={"small"}
                          />
                        )}
                      />
                    </Grid>
                    {limitValue && (
                      <Grid item xs={6}>
                        {selectedType === "teeth" ? (
                          <Typography>
                            Any {limitValue} teeth out of {allSelected} selected
                            teeth
                          </Typography>
                        ) : (
                          <Typography>{limitValue} times</Typography>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                {/* Period Section */}
                <Grid item xs={12} mb={2}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={6}>
                      <Controller
                        name="period"
                        control={control}
                        render={({ field }) => (
                          <CustomInput
                            {...field}
                            error={!!errors.period}
                            helperText={
                              errors.period ? errors.period.message : ""
                            }
                            label={"Period"}
                            placeholder={"Ex 5"}
                            size={"small"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Days(0-365)</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Period Limit Section */}
                <Grid item xs={12} mb={2}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={6}>
                      <Controller
                        name="limitPerPeriod"
                        control={control}
                        render={({ field }) => (
                          <CustomInput
                            {...field}
                            error={!!errors.limitPerPeriod}
                            helperText={
                              errors.limitPerPeriod
                                ? errors.limitPerPeriod.message
                                : ""
                            }
                            label={"Period Limit"}
                            placeholder={"Ex 5"}
                            size={"small"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Max teeth per period</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Submit Button */}
                <Box sx={{ textAlign: "center" }} mt={3}>
                  <CustomButton title="Submit" type="submit" />
                </Box>
              </Box>
            </form>
          </Grid>

          {/* Conditional rendering for OtherServiceSetup */}
          {selectedToothCategory === "anyOther" && <OtherServiceSetup />}
        </Grid>
      </Box>
    </>
  );
};

export default ServiceSetup;
