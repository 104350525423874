import React, { useEffect, useState } from "react";
import ClientProfileCard from "../../../components/ClientProfileCard";
import { Box, Button, Grid, Input, Typography } from "@mui/material";
import CustomCard from "../../../components/Card";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { object, string } from "yup";
import CustomInput from "../../../components/Input";
import { BANKING_NAMES, CARD_NO } from "../../../app/utilities/constants";
import { CheckBox } from "@mui/icons-material";
import CustomSelectPicker from "../../../components/SelectPicker";
import { Link as RouterLink, useParams } from "react-router-dom";
import IRATypography from "../../../components/ira-typography/ira-typography";
import Breadcrumb from "../../../components/Breadcrumb";
import CustomButton from "../../../components/Button";
import { apiRequest } from "../../../app/services/requests";

const PaymentMethod = () => {
  const [clientDet, setClientDet] = useState("") as any;
  const { clientId } = useParams();
  // console.log(clientDet, "Clients");

  useEffect(() => {
    getClientDetails();
  }, []);

  const getClientDetails = () => {
    apiRequest({
      url: `client/${clientId}`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          setClientDet(res);
        }
      })
      .catch((err) => {
        // if (err?.statusCode === 403 || err?.statusCode === 401) {
        //   if (navigate) {
        //     navigate("/login"); // Navigate to "/login" route
        //   }
        // }
      });
  };
  const breadcrumbs = [
    <RouterLink to="/Dashboard" style={{ textDecoration: "none" }}>
      <IRATypography
        key="2"
        textVariant="h12WithSemiBold"
        fontSize="12px"
        color="#222222"
      >
        Home
      </IRATypography>
    </RouterLink>,
    <IRATypography
      key="2"
      textVariant="h12WithSemiBold"
      fontSize="12px"
      color="#222222"
    >
      Client Profile
    </IRATypography>,
    <IRATypography
      key="2"
      textVariant="h12WithSemiBold"
      fontSize="12px"
      color="#222222"
    >
      Buy Plan
    </IRATypography>,
    <IRATypography
      key="2"
      textVariant="h12WithSemiBold"
      fontSize="12px"
      color="#222222"
    >
      Plan Payment
    </IRATypography>,
  ];
  const schema = object({
    cardNumber: string()
      .required("Card number is required")
      .matches(CARD_NO, "Phone number is not valid"),
    upiApps: string()
      .required("Card number is required")
      .matches(CARD_NO, "Phone number is not valid"),
    expiryNo: string()
      .required("Card number is required")
      .matches(CARD_NO, "Phone number is not valid"),
    cvv: string()
      .required("Card number is required")
      .matches(CARD_NO, "Phone number is not valid"),
    netBanking: string()
      .required("Card number is required")
      .matches(CARD_NO, "Phone number is not valid"),
  });
  const { control } = useForm({
    resolver: yupResolver(schema),
    // defaultValues: userDefaultValues(),
  });

  return (
    <div>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        mb={1}
      >
        <Breadcrumb title={`Payment Paln`} breadcrumbsArr={breadcrumbs} />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box>
            <ClientProfileCard clientDetails={clientDet} />
          </Box>
        </Grid>

        <Grid item xs={8} spacing={2} mt={2}>
          <CustomCard>
            <Box p={2}>
              <Box>
                <Typography variant="body1"> Plan Details</Typography>
              </Box>
              <Box my={1}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box display={"flex"}>
                      <Box>
                        <Typography> Application No:</Typography>
                      </Box>
                      <Box>
                        <Typography>{`128192 182819 88`}</Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box display={"flex"} justifyContent={"end"}>
                      <Box>
                        <Typography> Validity: </Typography>
                      </Box>
                      <Typography>{`  Monthly`}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box display={"flex"}>
                      <Box>
                        <Typography> Type:</Typography>
                      </Box>
                      <Typography>{`Family`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box display={"flex"} justifyContent={"end"}>
                      <Box>
                        <Typography> Payment Amount:</Typography>
                      </Box>
                      <Typography>{`10000`}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CustomCard>

          <Box mt={3}>
            <CustomCard>
              <Box p={5}>
                <Box display={"flex"} justifyContent={"space-evenly"}></Box>
                <Grid container spacing={3}>
                  {/* Add client details fields here */}
                  <Grid item xs={6}>
                    <Typography>Debit/Credit Details</Typography>
                    <Controller
                      name="cardNumber"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          label={"Card No"}
                          type="text"
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>UPI Apps</Typography>
                    <Controller
                      name="upiApps"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          label={"Enter Upi Id"}
                          type="text"
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Controller
                      name="expiryNo"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          label={"Expiry MM/"}
                          type="text"
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      name="cvv"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          label={"CVV"}
                          type="text"
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={7}>
                    <Typography>Net Banking</Typography>
                    <Controller
                      name="netBanking"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomSelectPicker
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          size={"small"}
                          fullWidth={true}
                          width={"100%"}
                          selectData={BANKING_NAMES}
                          label={"Select Bank"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>Cash Payments</Typography>
                    <Box display={"flex"} gap={2}>
                      <Controller
                        name="cardNumber"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CheckBox />
                        )}
                      />
                      <Typography>Check If Cash Payments</Typography>
                    </Box>
                  </Grid>
                  <Box
                    p={3}
                    gap={2}
                    display={"flex"}
                    justifyContent={"flex-end"}
                    width={"100%"}
                  >
                    <CustomButton
                      title={"Cancel"}
                      bgColor={"rgba(92, 78, 255, 1)"}
                    />
                    <CustomButton
                      title={"Pay"}
                      bgColor={"rgba(92, 78, 255, 1)"}
                      padding={"10px"}
                      width={20}
                    />
                  </Box>
                </Grid>
              </Box>
            </CustomCard>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentMethod;
