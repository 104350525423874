import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
// import uploadToS3, { downloadFileFromS3 } from "../../app/utilities/uploadToS3";
import cogoToast from "cogo-toast";
import { FILE_PATHS } from "../../app/utilities/constants";
import { styles } from "./styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FileUpload from "../../components/FileUploader/uplod-file";
import FileUploader from "../../components/FileUploader/file-upload";

const PreOperativePhotos = (props: any) => {
  let user = {} as any;

  const { medicalRecords = [], setMedicalRecords, handleUpdate } = props;
  const { PATIENTS_PATH } = FILE_PATHS;
  const [tabVal, setTabVal] = useState("photo");
  const [openModal, setOpenModal] = useState<any>(false);
  const [filteredFiles, setFilteredFiles] = useState<any>([]);
  const [recordType, setRecordType] = useState("");
  const [uploadPhoto, setUploadPhoto] = useState()

  const tableHeadData = ["Sno.", "Name", "File Type", "Actions"];

  const handleModal = (val: any) => {
    setOpenModal(val);
  };

  const loadFiles = (value: any) => {
    setFilteredFiles(
      medicalRecords.filter((file: any) => file.documentType === value)
    );
  };
  
  // const handleFileChange = (file: any) => {
  //   // setLoader(true);
  //   uploadToS3(PATIENTS_PATH, file, tabVal)
  //     .then(async (response: any) => {
  //       const files: any = response;
  //       setMedicalRecords([...medicalRecords, ...files]);
  //       setOpenModal(false);
  //       cogoToast.success("Files uploaded sucessfully", {
  //         position: "top-right",
  //         heading: "Success",
  //         hideAfter: 7,
  //       });
  //       // setLoader(false);
  //     })
  //     .catch(() => {
  //       // setLoader(false);
  //       cogoToast.error("Failed to upload file. Please try again", {
  //         position: "top-right",
  //         heading: "Error",
  //         hideAfter: 7,
  //       });
  //     });
  // };

  const deletePhotoDoc = async (data: any) => {
    const filtered = medicalRecords.filter(
      (photoDoc: any) => photoDoc.documentPath !== data.documentPath
    );
    setMedicalRecords(filtered);
    if (handleUpdate) {
      // setLoader(true);
      await handleUpdate(filtered);
      // setLoader(false);
    }
  };

  // const downloadFile = async (fileName: any, filePath: any) => {
  //   // setLoader(true);
  //   try {
  //     await downloadFileFromS3(filePath, fileName, true);
  //     // setLoader(false);
  //   } catch (err) {
  //     // setLoader(false);
  //     cogoToast.error("Failed to download. Please try again", {
  //       position: "top-right",
  //       heading: "Error",
  //       hideAfter: 7,
  //     });
  //   }
  // };

  const handleSubmit = async () => {
    await handleUpdate(medicalRecords);
  };
  

  return (
    <div>
      <Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={1}
          color="#6A6A6A"
          fontSize="14px"

        >
          <Typography>Treatment documents</Typography>
          {/* <FileUpload/> */}
        </Box>

        <Box sx={{ position: "relative" }} width={"100px"}>
          <Box>
           
            <img
              src="https://ecomposer.io/cdn/shop/articles/image9.png?v=1689814372"
              alt="menImage1"
              style={styles.imageOperative}
            />
             {/* <FileUpload/> */}
            {/* <Box sx={{ position: "absolute", top: "0", right: "0" }}>
              <HighlightOffIcon />
            </Box> */}


          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default PreOperativePhotos;
