import * as yup from "yup";

// Categories for the discounts
const categoriesDiscounts = ["preventive", "basic", "minor", "major", "other"];

const createDiscountField = (category: string, fieldName: string) => {
  return yup.number().when(["categories", "type"], {
    is: (categories: string[], type?: string) => {
      if (
        categories.includes(category) &&
        fieldName === "Discount" &&
        type === "discount"
      ) {
        return true;
      }
      const condition = categories.includes(category) && type === "standard";
      return condition;
    },
    then: yup
      .number()
      .required(
        `${
          category?.charAt(0).toUpperCase() + category?.slice(1)
        } ${fieldName} is required`
      )
      .min(0, "Must be at least 0%")
      .max(100, "Cannot exceed 100%"),
    otherwise: yup.number().notRequired(),
  });
};

const createWaitingPeriodField = (category: string, fieldName: string) => {
  return yup.number().when(["categories", "type"], {
    is: (categories: string[], type: string) =>
      categories.includes(category) && type === "standard",
    then: yup
      .number()
      .required(
        `${
          category.charAt(0).toUpperCase() + category.slice(1)
        } ${fieldName} is required`
      )
      .min(0, "Must be at least 0")
      .max(365, "Cannot exceed 365 days"),
    otherwise: yup.number().notRequired(), // Optional if category not selected or not standard/premium
  });
};

// Create the full set of discount fields for each category
const createCategoryDiscounts = (category: string) => ({
  [`${category}Discount`]: createDiscountField(category, "Discount"),
  [`${category}WaitingPeriod`]: createWaitingPeriodField(
    category,
    "Waiting Period"
  ),
  [`${category}AfterWaitingPeriod`]: createDiscountField(
    category,
    "After Waiting Period"
  ),
  [`${category}AfterOneYear`]: createDiscountField(category, "After One Year"),
  [`${category}AfterTwoYear`]: createDiscountField(category, "After Two Year"),
  [`${category}AfterThreeYear`]: createDiscountField(
    category,
    "After Three Year"
  ),
  [`${category}AfterFourYear`]: createDiscountField(
    category,
    "After Four Year"
  ),
});

// Master plan schema
export const masterPlanSchema = yup.object().shape({
  type: yup.string().required("Type is required"),
  name: yup.string().required("Plan Name is required"),
  premium: yup
    .number()
    .min(100, "Premium must be more than 100")
    .typeError("Premium must be a number")
    .required("Premium is required"),
  limit: yup
    .number()
    .typeError("Claim Limit must be a number")
    .min(1, "Claim Limit must be a positive number")
    .required("Claim Limit is required"),
  coPayType: yup.string().required("CoPay Type is required"),
  coPay: yup
    .number()
    .typeError("CoPay must be a number")
    .when("coPayType", {
      is: "percentage",
      then: yup
        .number()
        .max(100, "CoPay cannot be more than 100%")
        .required("CoPay is required"),
      otherwise: yup
        .number()
        .lessThan(
          yup.ref("premium"),
          "CoPay must be less than the premium amount"
        )
        .required("CoPay is required"),
    }),
  deductibleType: yup.string().required("Deductible Type is required"),
  deductible: yup
    .number()
    .typeError("Deductible must be a number")
    .when("deductibleType", {
      is: "percentage",
      then: yup
        .number()
        .max(100, "Deductible cannot be more than 100%")
        .required("Deductible is required"),
      otherwise: yup
        .number()
        .lessThan(
          yup.ref("premium"),
          "Deductible must be less than the premium amount"
        )
        .required("Deductible is required"),
    }),
  categories: yup
    .array()
    .of(yup.string())
    .min(1, "At least one category must be selected")
    .required("Categories are required"),

  // Dynamically generate discount fields for each category
  ...categoriesDiscounts.reduce((acc, category) => {
    return { ...acc, ...createCategoryDiscounts(category) };
  }, {}),
});

const defaultCategoryFields = [
  "Discount",
  "WaitingPeriod",
  "AfterWaitingPeriod",
  "AfterOneYear",
  "AfterTwoYear",
  "AfterThreeYear",
  "AfterFourYear",
];

// Generate default values for each category
const categoryDefaultValues = categoriesDiscounts.reduce(
  (acc: any, category: string) => {
    defaultCategoryFields.forEach((field: any) => {
      acc[`${category}${field}`] = undefined;
    });
    return acc;
  },
  {}
);

export const masterPlanDefaultValues = {
  type: "",
  name: "",
  premium: "",
  limit: "0",
  coPayType: "percentage",
  coPay: "0",
  deductibleType: "percentage",
  deductible: "0",
  categories: [],
  applicableToExistingUsers: true,
  enableCaseHistory: false,
  ...categoryDefaultValues,
};
