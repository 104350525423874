import { getTimeStops } from "./utils";

export const BACKEND_SERVER_URL = "https://api.dentassureplans.co.in";

export const TIME_DATA = getTimeStops("8:00", "20:00");

export const DATE_FORMAT_WITHOUT_YEAR = "do MMM";
export const DATE_FORMAT = "do MMM, yyyy";
export const TIME_FORMAT = "HH:mm";
export const TIME_FORMAT_TWELVE_HOURS = "p";
export const DATE_TIME_FORMAT = "do MMM, yyyy HH:mm";
export const DAY_MONTH_FORMAT = "M/d";
export const DATE_YYYY_MM_DD_FORMAT = "yyyy-MM-dd";

export const DATE_FORMAT_WITH_TIMEZONE = "Do MMM, YYYY";
export const TIME_FORMAT_WITH_TIMEZONE = "HH:mm";
export const DATE_TIME_FORMAT_WITH_TIMEZONE = "Do MMM, YYYY HH:mm";

export const USER_TYPES_DATA = [
  {
    id: "doctor",
    name: "Doctor",
  },
  {
    id: "assistent",
    name: "Assistent",
  },
];

export const GENDER_DATA = [
  {
    id: "male",
    name: "Male",
  },
  {
    id: "female",
    name: "Female",
  },
  {
    id: "other",
    name: "Other",
  },
];

export const BOOKING_TYPE_DATA = [
  {
    id: "OP",
    name: "OP",
  },
];

export const TIME_DURATIONS_DATA = [
  {
    id: "15",
    name: "15 min",
  },
  {
    id: "30",
    name: "30 min",
  },
  {
    id: "45",
    name: "45 min",
  },
  {
    id: "60",
    name: "60 min",
  },
  {
    id: "75",
    name: "1hr 15min",
  },
  {
    id: "90",
    name: "1hr 30min",
  },
  {
    id: "105",
    name: "1hr 45min",
  },
  {
    id: "120",
    name: "2hrs",
  },
];

export const SCHEME_DATA = [
  {
    id: 1,
    name: "Ayushman Bharat Yojana",
  },
];

export const APPOINTMENTS_FILTER = [
  {
    value: 1,
    name: "By Doctor",
  },
  {
    value: 2,
    name: "By Patient",
  },
];
export const APPOINTMENT_CLIENT_OPTIONS = [
  {
    id: "newClient",
    name: "New Client",
  },
  {
    id: "existingClient",
    name: "Existing Client",
  },
];
export const CO_PAY_OPTIONS = [
  {
    id: "percentage",
    name: "Percentage",
  },
  {
    id: "fixed",
    name: "Fixed Price",
  },
];

export const SELECT_TYPES_FOR_SERVICE_SETUP = [
  {
    id: "teeth",
    name: "Teeth",
  },
  {
    id: "other",
    name: "Other",
  },
];

export const DEDUCTIBLE_OPTIONS = [
  {
    id: "percentage",
    name: "Percentage",
  },
  {
    id: "fixed",
    name: "Fixed Price",
  },
];

export const TEETH_TYPES = [
  "Full Mouth",
  "Upper Urch",
  "Lower Urch",
  "Quadrant1",
  "Quadrant2",
  "Quadrant3",
  "Quadrant4",
  "anyOther",
  "Custom",
];

export const PUBLISH_PLAN_USER_OPTIONS = [
  {
    id: "true",
    name: "Existing Users and New Users",
  },
  {
    id: "false",
    name: "New Users",
  },
];

export const payTypeOptions = [
  {
    id: "percentage",
    name: "Percentage",
  },
  {
    id: "price",
    name: "Discount Amount",
  },
];

export const URLS = {
  GET_DASHBOARD_DATA_URL: "get-dashboard-data?limit=100",
  GET_PATIENTS_URL: "patients",
  CREATE_PATIENTS_URL: "patients",
  GET_PRESIGNED_URL: "get-upload-url",
  GET_DOWNLOAD_URL: "get-download-url",
  GET_CHAIRS_URL: "chairs",
  GET_USERS_URL: "users",
  APPOINTMENTS_URL: "appointments",
  GET_APPOINTMENTS_URL: "appointments",
  GET_TREATMENTS: "treatments",
  CASE_HISTORY_URL: "casehistory",
  CASE_HISTORY_APPOINTMENT_URL: "casehistory/appointment",
  EXAMINATION_URL: "examination",
  EXAMINATION_APPOINTMENT_URL: "examination/appointment",
  EXAMINATION_PATIENT_URL: "examination/patient",
  EXAMINATION_UPDATE_URL: "examination/update-examination",
  PRESCRIPTIONS_URL: "prescription",
  PRESCRIPTIONS_APPOINTMENT_URL: "prescription/appointment",
  PRESCRIPTIONS_PATIENT_URL: "prescription/patient",
  PRESCRIPTIONS_UPDATE_URL: "prescription/update-prescription",
  CASE_HISTORY_PATIENTS_URL: "casehistory/patient",
  TREATMENTS_URL: "treatment",
  TREATMENTS_APPOINTMENT_URL: "treatment/appointment",
  COURSE_CASEHISTORY_URL: "course/casehistory",
  COURSE_PRESCRIPTION_URL: "course/prescription",
  COURSE_EXAMINATION_URL: "course/examination",
  COURSE_APPOINTMENT_URL: "course/appointment",
  COURSE_PATIENT_URL: "course/patient",
  COURSE_URL: "course",
  GET_DOCTORS_URL: "users/get-doctors",
  GET_ASSISTANTS_URL: "users/get-assistants",
  GET_DOCTORS_AVAILABILITY_URL: "doctor-availability",
  GET_COURSE_MASTER_URL: "course-master",
  GET_INVESTIGATIONS_URL: "investigations-master",
  GET_SPECIALITY_URL: "speciality-master",
  GET_TREATMENTS_REQUIREMENTS_URL: "treatment-requirements",
  GET_TREATMENTS_GROUP_URL: "treatment-group",
  GET_CLINICS_URL: "clinics",
  GET_TREATMENTS_MASTERS_URL: "treatment-master",
  GET_INVENTORY_ITEMS: "inventory-items",
  VISITING_PLAN_URL: "visiting-plan",
  VISITING_PLAN_COURSE_URL: "visiting-plan/course",
  VISITING_PLAN_PATIENT_URL: "visiting-plan/patient",
  INVENTORY_ITEMS: "inventory-items",
  CONSENT_FORM_URL: "consent-form",
  CONSENT_FORM_COURSE_URL: "consent-form/course",
  LABS_URL: "labs",
  LAB_WORKS_URL: "lab-works",
  BILLINGS_URL: "billings",
  RECEIPTS_URL: "billings/billingActivity",
  GENERATE_PRESCRIPTION_BILL: "prescription/generateBill",
  GET_PHARMACY_INVENTORY_URL: "pharmacy-inventory",
  GET_PHARMACY_SUPPLIER_URL: "pharmacy-inventory-supplier",
  PHARMACY_INDENT_URL: "pharmacy-indent",
  EXPENSES_URL: "expenses",
  GET_SUPER_ADMIN_DASHBOARD_DATA_URL: "clinics/super-admin/dash-board",
};

export const IMAGE_MIME_TYPES = [
  "image/bmp",
  "image/jpeg",
  "image/x-png",
  "image/png",
  "image/gif",
];

export const COUNTRY_LIST = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan (Province of China)",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const TITLES = [
  {
    id: "mr",
    name: "Mr",
  },
  {
    id: "mrs",
    name: "Mrs",
  },
  {
    id: "ms",
    name: "Ms",
  },
];

export const RELATION_TITLES = [
  {
    id: "s/o",
    name: "S/O",
  },
  {
    id: "c/o",
    name: "C/O",
  },
  {
    id: "d/o",
    name: "D/O",
  },
];

export const NATIONALITY = [
  {
    id: "india",
    name: "India",
  },
  // {
  //   id: "other",
  //   name: "Other",
  // },
];

export const MARTIAL_STATUS = [
  {
    id: "married",
    name: "Married",
  },
  {
    id: "single",
    name: "UnMarried",
  },
  {
    id: "widowed",
    name: "Widowed",
  },
  {
    id: "divorced",
    name: "Divorced",
  },
  {
    id: "separated",
    name: "Separated",
  },
];

export const MARTIAL_STATUS1 = [
  {
    id: "single",
    name: "Single",
  },
  {
    id: "married",
    name: "Married",
  },
  {
    id: "divorced",
    name: "Divorced",
  },
];

export const PLAN_VALIDITY = [
  {
    id: "oneYear",
    name: "One Year",
  },
  {
    id: "lifeLong",
    name: "Life Long",
  },
];

export const PLAN_NAMES = [
  {
    id: "simple",
    name: "Simple Health Plan",
  },
  {
    id: "advanced",
    name: "Advanced Health Plan",
  },
  {
    id: "ortho",
    name: "Ortho Health Plan",
  },
];

export const PLAN_NAMES_FOR_DISCOUNT_PLAN = [
  {
    id: "20",
    name: "20%",
  },
  {
    id: "30",
    name: "30%",
  },
];

export const PLAN_TYPES = [
  {
    id: "health",
    name: "Health Plan",
  },
  {
    id: "discount",
    name: "Family",
  },
  {
    id: "customized",
    name: "Customized",
  },
];

export const PLAN_TYPES_FOR = [
  {
    id: "individual",
    name: "Individual",
  },
  {
    id: "family",
    name: "Family",
  },
];

export const GENDER = [
  {
    id: "male",
    name: "Male",
  },
  {
    id: "female",
    name: "Female",
  },
  {
    id: "other",
    name: "Other",
  },
];

export const USER_ROLES = [
  {
    id: "agent",
    name: "Agent",
  },
  {
    id: "accountant",
    name: "Accountant",
  },
  {
    id: "salesExecutive",
    name: "Sales Executive",
  },
  {
    id: "customerCare",
    name: "customerCare",
  },
  {
    id: "daDoctor",
    name: "DA Doctor",
  },
];

export const CHAIR_TYPES = [
  {
    id: "OP",
    name: "OP",
  },
  {
    id: "Treatment",
    name: "Treatment",
  },
  {
    id: "OP & Treatment",
    name: "OP & Treatment",
  },
];

export const VIBGYOR_COLORS = [
  {
    id: "violet",
    name: "Violet",
  },
  {
    id: "indigo",
    name: "Indigo",
  },
  {
    id: "blue",
    name: "Blue",
  },
  {
    id: "green",
    name: "Green",
  },
  {
    id: "yellow",
    name: "Yellow",
  },
  {
    id: "orange",
    name: "Orange",
  },
  {
    id: "red",
    name: "Red",
  },
];

export const NO_OF_BOOKINGS = [
  {
    id: 1,
    name: 1,
  },
  {
    id: 2,
    name: 2,
  },
  {
    id: 3,
    name: 3,
  },
  {
    id: 4,
    name: 4,
  },
];

export const PATIENT_RECORD_TYPES = [
  {
    id: "photo",
    name: "Photos",
  },
  {
    id: "radio-graph",
    name: "Radio Graphs",
  },
  {
    id: "document",
    name: "Documents",
  },
  {
    id: "other",
    name: "Others",
  },
];

export const PHONE_REG_EXP =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const PINCODE_REG_EXP = /^[1-9][0-9]{5}$/;
export const AADHAR_REG_EXP = /^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/gm;
export const CARD_NO = /^[1-9][0-9]{8}$/;
export const USER_TYPES_LABELS = {
  doctor: {
    label: "Doctor",
    createRoute: "/doctors/create",
    listRoute: "/doctors",
    viewEditRoute: "/doctors",
    tableHeadData: [
      {
        id: "id",
        numeric: false,
        sortable: true,
        disablePadding: true,
        label: "ID",
        align: "left",
      },
      {
        id: "firstName",
        numeric: true,
        sortable: true,
        disablePadding: false,
        label: "Doctor Name",
      },
      {
        id: "phoneNumber",
        numeric: true,
        disablePadding: false,
        label: "Contact No",
      },
      {
        id: "email",
        numeric: true,
        sortable: true,
        disablePadding: false,
        label: "Email",
      },
      {
        disablePadding: false,
        label: "Actions",
      },
    ],
  },
  assistant: {
    label: "Assistant",
    createRoute: "/assistants/create",
    listRoute: "/assistants",
    viewEditRoute: "/assistants",
    tableHeadData: [
      {
        id: "id",
        numeric: false,
        sortable: true,
        disablePadding: true,
        label: "ID",
        align: "left",
      },
      {
        id: "firstName",
        numeric: true,
        sortable: true,
        disablePadding: false,
        label: "Assistant Name",
      },
      {
        id: "phoneNumber",
        numeric: true,
        disablePadding: false,
        label: "Contact No",
      },
      {
        id: "email",
        numeric: true,
        sortable: true,
        disablePadding: false,
        label: "Email",
      },
      {
        disablePadding: false,
        label: "Actions",
      },
    ],
  },
  frontdesk: {
    label: "Front Desk",
    createRoute: "/FrontDesks/create",
    listRoute: "/FrontDesks",
    viewEditRoute: "/FrontDesks",
    tableHeadData: [
      {
        id: "id",
        numeric: false,
        sortable: true,
        disablePadding: true,
        label: "ID",
        align: "left",
      },
      {
        id: "firstName",
        numeric: true,
        sortable: true,
        disablePadding: false,
        label: "Name",
      },
      {
        id: "phoneNumber",
        numeric: true,
        disablePadding: false,
        label: "Contact No",
      },
      {
        id: "email",
        numeric: true,
        sortable: true,
        disablePadding: false,
        label: "Email",
      },
      {
        disablePadding: false,
        label: "Actions",
      },
    ],
  },
};
//BANKING_NAMES

export const BANKING_NAMES = {
  Bank1: "State Bank of India",
  Bank2: "Canara Bank",
  Bank3: "Punjab National Bank",
  Bank4: "Indian Oversease Bank",
  Bank5: "Andhra Bank",
  Bank6: "Bank Of Baroda",
  Bank7: "Indian Bank",
};

export const FILE_PATHS = {
  PATIENTS_PATH: "patients",
  USERS_PATH: "users",
  INVESTIGATIONS_PATH: "investigations",
  LAB_WORK: "lab",
  CONSENT_FORM: "consent-form",
  Inventory_Form: "inventory-path",
  CLINIC_PATH: "clinic-path",
};

export const GENDER_MAPPING = {
  male: "Male",
  female: "Female",
  other: "Other",
};

export const UPPER_TEETH = [
  { value: "18", name: "18", teethNumber: "18" },
  { value: "17", name: "17", teethNumber: "17" },
  { value: "16", name: "16", teethNumber: "16" },
  { value: "15", name: "15", teethNumber: "15" },
  { value: "14", name: "14", teethNumber: "14" },
  { value: "13", name: "13", teethNumber: "13" },
  { value: "12", name: "12", teethNumber: "12" },
  { value: "11", name: "11", teethNumber: "11" },
  { value: "21", name: "21", teethNumber: "21" },
  { value: "22", name: "22", teethNumber: "22" },
  { value: "23", name: "23", teethNumber: "23" },
  { value: "24", name: "24", teethNumber: "24" },
  { value: "25", name: "25", teethNumber: "25" },
  { value: "26", name: "26", teethNumber: "26" },
  { value: "27", name: "27", teethNumber: "27" },
  { value: "28", name: "28", teethNumber: "28" },
];

export const LOWER_TEETH = [
  { value: "48", name: "48", teethNumber: "48" },
  { value: "47", name: "47", teethNumber: "47" },
  { value: "46", name: "46", teethNumber: "46" },
  { value: "45", name: "45", teethNumber: "45" },
  { value: "44", name: "44", teethNumber: "44" },
  { value: "43", name: "43", teethNumber: "43" },
  { value: "42", name: "42", teethNumber: "42" },
  { value: "41", name: "41", teethNumber: "41" },
  { value: "31", name: "31", teethNumber: "31" },
  { value: "32", name: "32", teethNumber: "32" },
  { value: "33", name: "33", teethNumber: "33" },
  { value: "34", name: "34", teethNumber: "34" },
  { value: "35", name: "35", teethNumber: "35" },
  { value: "36", name: "36", teethNumber: "36" },
  { value: "37", name: "37", teethNumber: "37" },
  { value: "38", name: "38", teethNumber: "38" },
];

export const FULL_MOUTH = [...UPPER_TEETH, ...LOWER_TEETH];

export const TEETHS_LABEL_MAP = {
  fullMouth: "Full Mouth",
  lowerTeeth: "Lower Teeth",
  upperTeeth: "Upper Teeth",
};

export const TOOTH_SURFACES = [
  { name: "Buccal", value: "Buccal" },
  { name: "Central", value: "Central" },
  { name: "Distal", value: "Distal" },
  { name: "Lingual", value: "Lingual" },
  { name: "Mesial", value: "Mesial" },
];

export const TOOTH_SURFACES_PERIODONTAL = {
  Lingual: "Lingual",
  buccal: "Buccal",
  lingualReverse: "Lingual",
  buccalReverse: "Buccal",
};

export const PERIODONTAL_BUCCAL = [
  {
    text: "Mobility",
    color: "#204289",
    backgroundColor: "#CADDF5",
    captureData: [
      {
        teethNo: 18,
        inputValues: [0],
      },
      {
        teethNo: 17,
        inputValues: [0],
      },
      {
        teethNo: 16,
        inputValues: [0],
      },
      {
        teethNo: 15,
        inputValues: [0],
      },
      {
        teethNo: 14,
        inputValues: [0],
      },
      {
        teethNo: 13,
        inputValues: [0],
      },
      {
        teethNo: 12,
        inputValues: [0],
      },
      {
        teethNo: 11,
        inputValues: [0],
      },
      {
        teethNo: 21,
        inputValues: [0],
      },
      {
        teethNo: 22,
        inputValues: [0],
      },
      {
        teethNo: 23,
        inputValues: [0],
      },
      {
        teethNo: 24,
        inputValues: [0],
      },
      {
        teethNo: 25,
        inputValues: [0],
      },
      {
        teethNo: 26,
        inputValues: [0],
      },
      {
        teethNo: 27,
        inputValues: [0],
      },
      {
        teethNo: 28,
        inputValues: [0],
      },
    ],
  },
  {
    text: "Implant",
    color: "#FFFF",
    backgroundColor: "#7A7B7A",
    captureData: [
      {
        teethNo: 18,
        colorCodes: [false],
      },
      {
        teethNo: 17,
        colorCodes: [false],
      },
      {
        teethNo: 16,
        colorCodes: [false],
      },
      {
        teethNo: 15,
        colorCodes: [false],
      },
      {
        teethNo: 14,
        colorCodes: [false],
      },
      {
        teethNo: 13,
        colorCodes: [false],
      },
      {
        teethNo: 12,
        colorCodes: [false],
      },
      {
        teethNo: 11,
        colorCodes: [false],
      },
      {
        teethNo: 21,
        colorCodes: [false],
      },
      {
        teethNo: 22,
        colorCodes: [false],
      },
      {
        teethNo: 23,
        colorCodes: [false],
      },
      {
        teethNo: 24,
        colorCodes: [false],
      },
      {
        teethNo: 25,
        colorCodes: [false],
      },
      {
        teethNo: 26,
        colorCodes: [false],
      },
      {
        teethNo: 27,
        colorCodes: [false],
      },
      {
        teethNo: 28,
        colorCodes: [false],
      },
    ],
  },
  {
    text: "Furcation",
    color: "#FFFF",
    backgroundColor: "#2E8B57",
    captureData: [
      {
        teethNo: 18,
        inputValues: [0],
      },
      {
        teethNo: 17,
        inputValues: [0],
      },
      {
        teethNo: 16,
        inputValues: [0],
      },
      {
        teethNo: 15,
      },
      {
        teethNo: 14,
      },
      {
        teethNo: 13,
      },
      {
        teethNo: 12,
      },
      {
        teethNo: 11,
      },
      {
        teethNo: 21,
      },
      {
        teethNo: 22,
      },
      {
        teethNo: 23,
      },
      {
        teethNo: 24,
      },
      {
        teethNo: 25,
      },
      {
        teethNo: 26,
        inputValues: [0],
      },
      {
        teethNo: 27,
        inputValues: [0],
      },
      {
        teethNo: 28,
        inputValues: [0],
      },
    ],
  },
  {
    text: "Bleeding on probing",
    color: "#FFFF",
    backgroundColor: "#BF070A",
    captureData: [
      {
        teethNo: 18,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 17,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 16,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 15,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 14,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 13,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 12,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 11,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 21,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 22,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 23,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 24,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 25,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 26,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 27,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 28,
        colorCodes: [false, false, false],
      },
    ],
  },
  {
    text: "Plaque",
    color: "#FFFF",
    backgroundColor: "#017DC3",
    captureData: [
      {
        teethNo: 18,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 17,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 16,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 15,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 14,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 13,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 12,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 11,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 21,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 22,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 23,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 24,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 25,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 26,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 27,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 28,
        colorCodes: [false, false, false],
      },
    ],
  },
  {
    text: "Probing Depth",
    color: "#204289",
    backgroundColor: "#CADDF5",
    captureData: [
      {
        teethNo: 18,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 17,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 16,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 15,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 14,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 13,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 12,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 11,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 21,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 22,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 23,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 24,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 25,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 26,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 27,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 28,
        inputValues: [0, 0, 0],
      },
    ],
  },
  {
    text: "Gingival Margin",
    color: "#204289",
    backgroundColor: "#CADDF5",
    captureData: [
      {
        teethNo: 18,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 17,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 16,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 15,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 14,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 13,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 12,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 11,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 21,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 22,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 23,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 24,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 25,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 26,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 27,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 28,
        inputValues: [0, 0, 0],
      },
    ],
  },
];

export const PERIODONTAL_BUCCAL_REVERSE = [
  {
    text: "Gingival Margin",
    color: "#204289",
    backgroundColor: "#CADDF5",
    captureData: [
      {
        teethNo: 48,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 47,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 46,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 45,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 44,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 43,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 42,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 41,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 31,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 32,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 33,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 34,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 35,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 36,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 37,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 38,
        inputValues: [0, 0, 0],
      },
    ],
  },
  {
    text: "Probing Depth",
    color: "#204289",
    backgroundColor: "#CADDF5",
    captureData: [
      {
        teethNo: 48,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 47,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 46,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 45,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 44,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 43,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 42,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 41,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 31,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 32,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 33,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 34,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 35,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 36,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 37,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 38,
        inputValues: [0, 0, 0],
      },
    ],
  },
  {
    text: "Plaque",
    color: "#FFFF",
    backgroundColor: "#017DC3",
    captureData: [
      {
        teethNo: 48,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 47,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 46,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 45,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 44,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 43,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 42,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 41,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 31,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 32,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 33,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 34,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 35,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 36,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 37,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 38,
        colorCodes: [false, false, false],
      },
    ],
  },
  {
    text: "Bleeding on probing",
    color: "#FFFF",
    backgroundColor: "#BF070A",
    captureData: [
      {
        teethNo: 48,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 47,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 46,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 45,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 44,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 43,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 42,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 41,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 31,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 32,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 33,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 34,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 35,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 36,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 37,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 38,
        colorCodes: [false, false, false],
      },
    ],
  },
  {
    text: "Furcation",
    color: "#FFFF",
    backgroundColor: "#2E8B57",
    captureData: [
      {
        teethNo: 48,
        inputValues: [0],
      },
      {
        teethNo: 47,
        inputValues: [0],
      },
      {
        teethNo: 46,
        inputValues: [0],
      },
      {
        teethNo: 45,
      },
      {
        teethNo: 44,
      },
      {
        teethNo: 43,
      },
      {
        teethNo: 42,
      },
      {
        teethNo: 41,
      },
      {
        teethNo: 31,
      },
      {
        teethNo: 32,
      },
      {
        teethNo: 33,
      },
      {
        teethNo: 34,
      },
      {
        teethNo: 35,
      },
      {
        teethNo: 36,
        inputValues: [0],
      },
      {
        teethNo: 37,
        inputValues: [0],
      },
      {
        teethNo: 38,
        inputValues: [0],
      },
    ],
  },
  {
    text: "Implant",
    color: "#FFFF",
    backgroundColor: "#7A7B7A",
    captureData: [
      {
        teethNo: 48,
        colorCodes: [false],
      },
      {
        teethNo: 47,
        colorCodes: [false],
      },
      {
        teethNo: 46,
        colorCodes: [false],
      },
      {
        teethNo: 45,
        colorCodes: [false],
      },
      {
        teethNo: 44,
        colorCodes: [false],
      },
      {
        teethNo: 43,
        colorCodes: [false],
      },
      {
        teethNo: 42,
        colorCodes: [false],
      },
      {
        teethNo: 41,
        colorCodes: [false],
      },
      {
        teethNo: 31,
        colorCodes: [false],
      },
      {
        teethNo: 32,
        colorCodes: [false],
      },
      {
        teethNo: 33,
        colorCodes: [false],
      },
      {
        teethNo: 34,
        colorCodes: [false],
      },
      {
        teethNo: 35,
        colorCodes: [false],
      },
      {
        teethNo: 36,
        colorCodes: [false],
      },
      {
        teethNo: 37,
        colorCodes: [false],
      },
      {
        teethNo: 38,
        colorCodes: [false],
      },
    ],
  },
  {
    text: "Mobility",
    color: "#204289",
    backgroundColor: "#CADDF5",
    captureData: [
      {
        teethNo: 48,
        inputValues: [0],
      },
      {
        teethNo: 47,
        inputValues: [0],
      },
      {
        teethNo: 46,
        inputValues: [0],
      },
      {
        teethNo: 45,
        inputValues: [0],
      },
      {
        teethNo: 44,
        inputValues: [0],
      },
      {
        teethNo: 43,
        inputValues: [0],
      },
      {
        teethNo: 42,
        inputValues: [0],
      },
      {
        teethNo: 41,
        inputValues: [0],
      },
      {
        teethNo: 31,
        inputValues: [0],
      },
      {
        teethNo: 32,
        inputValues: [0],
      },
      {
        teethNo: 33,
        inputValues: [0],
      },
      {
        teethNo: 34,
        inputValues: [0],
      },
      {
        teethNo: 35,
        inputValues: [0],
      },
      {
        teethNo: 36,
        inputValues: [0],
      },
      {
        teethNo: 37,
        inputValues: [0],
      },
      {
        teethNo: 38,
        inputValues: [0],
      },
    ],
  },
  {
    text: "Note",
    color: "#204289",
    backgroundColor: "#CADDF5",
    captureData: [
      {
        teethNo: 48,
        notes: "",
      },
      {
        teethNo: 47,
        notes: "",
      },
      {
        teethNo: 46,
        notes: "",
      },
      {
        teethNo: 45,
        notes: "",
      },
      {
        teethNo: 44,
        notes: "",
      },
      {
        teethNo: 43,
        notes: "",
      },
      {
        teethNo: 42,
        notes: "",
      },
      {
        teethNo: 41,
        notes: "",
      },
      {
        teethNo: 31,
        notes: "",
      },
      {
        teethNo: 32,
        notes: "",
      },
      {
        teethNo: 33,
        notes: "",
      },
      {
        teethNo: 34,
        notes: "",
      },
      {
        teethNo: 35,
        notes: "",
      },
      {
        teethNo: 36,
        notes: "",
      },
      {
        teethNo: 37,
        notes: "",
      },
      {
        teethNo: 38,
        notes: "",
      },
    ],
  },
];

export const PERIODONTAL_LINGUAL = [
  {
    text: "Gingival Margin",
    color: "#204289",
    backgroundColor: "#CADDF5",
    captureData: [
      {
        teethNo: 18,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 17,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 16,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 15,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 14,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 13,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 12,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 11,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 21,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 22,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 23,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 24,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 25,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 26,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 27,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 28,
        inputValues: [0, 0, 0],
      },
    ],
  },
  {
    text: "Probing Depth",
    color: "#204289",
    backgroundColor: "#CADDF5",
    captureData: [
      {
        teethNo: 18,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 17,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 16,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 15,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 14,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 13,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 12,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 11,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 21,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 22,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 23,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 24,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 25,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 26,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 27,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 28,
        inputValues: [0, 0, 0],
      },
    ],
  },
  {
    text: "Plaque",
    color: "#FFFF",
    backgroundColor: "#017DC3",
    captureData: [
      {
        teethNo: 18,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 17,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 16,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 15,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 14,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 13,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 12,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 11,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 21,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 22,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 23,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 24,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 25,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 26,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 27,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 28,
        colorCodes: [false, false, false],
      },
    ],
  },
  {
    text: "Bleeding on probing",
    color: "#FFFF",
    backgroundColor: "#BF070A",
    captureData: [
      {
        teethNo: 18,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 17,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 16,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 15,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 14,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 13,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 12,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 11,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 21,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 22,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 23,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 24,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 25,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 26,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 27,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 28,
        colorCodes: [false, false, false],
      },
    ],
  },
  {
    text: "Furcation",
    color: "#FFFF",
    backgroundColor: "#2E8B57",
    captureData: [
      {
        teethNo: 18,
        inputValues: [0, 0],
      },
      {
        teethNo: 17,
        inputValues: [0, 0],
      },
      {
        teethNo: 16,
        inputValues: [0, 0],
      },
      {
        teethNo: 15,
      },
      {
        teethNo: 14,
        inputValues: [0, 0],
      },
      {
        teethNo: 13,
      },
      {
        teethNo: 12,
      },
      {
        teethNo: 11,
      },
      {
        teethNo: 21,
      },
      {
        teethNo: 22,
      },
      {
        teethNo: 23,
      },
      {
        teethNo: 24,
        inputValues: [0, 0],
      },
      {
        teethNo: 25,
      },
      {
        teethNo: 26,
        inputValues: [0, 0],
      },
      {
        teethNo: 27,
        inputValues: [0, 0],
      },
      {
        teethNo: 28,
        inputValues: [0, 0],
      },
    ],
  },
  {
    text: "Note",
    color: "#204289",
    backgroundColor: "#CADDF5",
    captureData: [
      {
        teethNo: 18,
        notes: "",
      },
      {
        teethNo: 17,
        notes: "",
      },
      {
        teethNo: 16,
        notes: "",
      },
      {
        teethNo: 15,
        notes: "",
      },
      {
        teethNo: 14,
        notes: "",
      },
      {
        teethNo: 13,
        notes: "",
      },
      {
        teethNo: 12,
        notes: "",
      },
      {
        teethNo: 11,
        notes: "",
      },
      {
        teethNo: 21,
        notes: "",
      },
      {
        teethNo: 22,
        notes: "",
      },
      {
        teethNo: 23,
        notes: "",
      },
      {
        teethNo: 24,
        notes: "",
      },
      {
        teethNo: 25,
        notes: "",
      },
      {
        teethNo: 26,
        notes: "",
      },
      {
        teethNo: 27,
        notes: "",
      },
      {
        teethNo: 28,
        notes: "",
      },
    ],
  },
];

export const PERIODONTAL_LINGUAL_REVERSE = [
  {
    text: "Furcation",
    color: "#FFFF",
    backgroundColor: "#2E8B57",
    captureData: [
      {
        teethNo: 48,
        inputValues: [0],
      },
      {
        teethNo: 47,
        inputValues: [0],
      },
      {
        teethNo: 46,
        inputValues: [0],
      },
      {
        teethNo: 45,
      },
      {
        teethNo: 44,
      },
      {
        teethNo: 43,
      },
      {
        teethNo: 42,
      },
      {
        teethNo: 41,
      },
      {
        teethNo: 31,
      },
      {
        teethNo: 32,
      },
      {
        teethNo: 33,
      },
      {
        teethNo: 34,
      },
      {
        teethNo: 35,
      },
      {
        teethNo: 36,
        inputValues: [0],
      },
      {
        teethNo: 37,
        inputValues: [0],
      },
      {
        teethNo: 38,
        inputValues: [0],
      },
    ],
  },
  {
    text: "Bleeding on probing",
    color: "#FFFF",
    backgroundColor: "#BF070A",
    captureData: [
      {
        teethNo: 48,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 47,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 46,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 45,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 44,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 43,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 42,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 41,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 31,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 32,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 33,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 34,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 35,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 36,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 37,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 38,
        colorCodes: [false, false, false],
      },
    ],
  },
  {
    text: "Plaque",
    color: "#FFFF",
    backgroundColor: "#017DC3",
    captureData: [
      {
        teethNo: 48,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 47,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 46,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 45,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 44,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 43,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 42,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 41,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 31,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 32,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 33,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 34,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 35,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 36,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 37,
        colorCodes: [false, false, false],
      },
      {
        teethNo: 38,
        colorCodes: [false, false, false],
      },
    ],
  },
  {
    text: "Probing Depth",
    color: "#204289",
    backgroundColor: "#CADDF5",
    captureData: [
      {
        teethNo: 48,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 47,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 46,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 45,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 44,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 43,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 42,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 41,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 31,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 32,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 33,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 34,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 35,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 36,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 37,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 38,
        inputValues: [0, 0, 0],
      },
    ],
  },
  {
    text: "Gingival Margin",
    color: "#204289",
    backgroundColor: "#CADDF5",
    captureData: [
      {
        teethNo: 48,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 47,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 46,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 45,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 44,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 43,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 42,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 41,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 31,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 32,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 33,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 34,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 35,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 36,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 37,
        inputValues: [0, 0, 0],
      },
      {
        teethNo: 38,
        inputValues: [0, 0, 0],
      },
    ],
  },
];

export const WEEK_DAYS = [
  { id: "MON", name: "Mon" },
  { id: "TUE", name: "Tues" },
  { id: "WED", name: "Wed" },
  { id: "THU", name: "Thur" },
  { id: "FRI", name: "Fri" },
  { id: "SAT", name: "Sat" },
  { id: "SUN", name: "Sun" },
];

export const WEEK_DAYS_NUM = {
  SUN: 0,
  MON: 1,
  TUE: 2,
  WED: 3,
  THU: 4,
  FRI: 5,
  SAT: 6,
};

export const APPOINTMENTS_STATUS = {
  booked: "Booked",
  cancelled: "Cancelled",
  canceled: "Cancelled",
  confirmed: "Confirmed",
  completed: "Completed",
  patientInChair: "Patient In Chair",
};

export const SURFACE_TEETH_ELEMENT_IDS = [
  "Top",
  "Right",
  "Bottom",
  "Left",
  "Center",
];

export const RELATIONSHIPS = [
  {
    id: "father",
    name: "Father",
  },
  {
    id: "mother",
    name: "Mother",
  },
  {
    id: "son",
    name: "Son",
  },
  {
    id: "daughter",
    name: "Daughter",
  },
  {
    id: "brother",
    name: "Brother",
  },
  {
    id: "sister",
    name: "Sister",
  },
  {
    id: "uncle",
    name: "Uncle",
  },
  {
    id: "aunt",
    name: "Aunt",
  },
  {
    id: "niece",
    name: "Niece",
  },
  {
    id: "nephew",
    name: "Nephew",
  },
  {
    id: "cousin(male)",
    name: "Cousin(male)",
  },
  {
    id: "cousin(female)",
    name: "Cousin(female)",
  },
];

export const APPOINTMENTS_AGGREGATIONS = [
  { name: "Today", id: "today" },
  { name: "Current Week", id: "current_week" },
  { name: "Current Month", id: "current_month" },
  { name: "Custom", id: "custom" },
];
export const USER_TYPES = [
  { name: "Today", id: "today" },
  { name: "Current Week", id: "current_week" },
  { name: "Current Month", id: "current_month" },
  { name: "Custom", id: "custom" },
];

export const TREATMENT_STATUS = {
  yetToStart: "Yet To Start",
  inProgress: "In-Progress",
  completed: "Completed",
  canceled: "Cancelled",
  patientDenied: "Patient Denied",
};

export const DEGREE_LIST = [
  {
    id: "bds",
    name: "BDS",
  },
  {
    id: "mds",
    name: "MDS",
  },
  {
    id: "others",
    name: "Others",
  },
];

export const FD_ASSISTANT_DEGREE_LIST = [
  {
    id: "belowTenth",
    name: "Below 10th",
  },
  {
    id: "10th",
    name: "10th",
  },
  {
    id: "intermediate",
    name: "Intermediate",
  },
  {
    id: "diploma",
    name: "Diploma",
  },
  {
    id: "degree",
    name: "Degree",
  },
  {
    id: "btech",
    name: "B.Tech",
  },
  {
    id: "pg",
    name: "PG",
  },
];

export const REFERRAL_TYPES = [
  {
    id: "doctor",
    name: "Doctor",
  },
  {
    id: "friend",
    name: "Friend",
  },
  {
    id: "relative",
    name: "Relative",
  },
  {
    id: "online",
    name: "Online",
  },
  {
    id: "advertisement",
    name: "Advertisement",
  },
  {
    id: "newspaper",
    name: "Newspaper",
  },
  {
    id: "website",
    name: "Website",
  },
];

export const AIR_CONDITION_OPTIONS = [
  {
    id: "true",
    name: "Air Conditioned",
  },
  {
    id: "false",
    name: "Not Air Conditioned",
  },
];

export const ORGANIZATION_MEMBERSHIP_OPTIONS = [
  {
    id: "true",
    name: "Yes",
  },
  {
    id: "false",
    name: "No",
  },
];

export const BILLING_STATUS = {
  paid: "Paid",
  unPaid: "Not Paid",
  partiallyPaid: "Partially Paid",
};

export const BILLING_TYPES = [
  { name: "OP", id: "OP" },
  { name: "Treatment", id: "Treatment" },
  { name: "Investigations", id: "Investigations" },
];

export const BILLING_STATUS_LIST = [
  { name: "Paid", id: "paid" },
  { name: "Not Paid", id: "unPaid" },
  { name: "Partially Paid", id: "partiallyPaid" },
];

export const PAYMENT_MODES = [
  { name: "UPI", id: "UPI" },
  { name: "Cash", id: "Cash" },
];

export const CLINIC_PERSON_ROLES = [
  {
    name: "Clinic Doctor",
    id: "ClinicDoctor",
  },
];
