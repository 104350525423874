import React, { CSSProperties } from "react";
import { Checkbox, Radio, TableCell, TableRow } from "@mui/material";
import { withStyles } from "@mui/styles";
import { theme } from "../../theme/theme";

export const IRATableCell = withStyles((props: any) => {
  return {
    head: {
      backgroundColor: theme.palette.customColors.lightWhiteGrey,
      color: "#0A192B",
      fontSize: 14,
      fontFamily: "MontserratRegular !important",
      padding: "5px 10px !important",
      fontWeight: "600 !important",
      lineHeight: "16px",
      // borderRadius:props.style.borderRadius
    },
    body: {
      fontSize: "12px !important",
      lineHeight: "150%",
      borderBottom: "1px solid #EEEEEE",
      fontWeight: 600,
      backgroundColor: "#ffffff",
      fontFamily: "MontserratRegular !important",
      padding: "5px 10px !important",
    },
  };
})(TableCell);

export const IRATableRow = withStyles({
  root: {
    "&:nth-of-type(odd)": {},
  },
})(TableRow);

export const SelectCell = ({ variant, style, domId, ...props }: any) => {
  return (
    <IRATableCell style={{ ...style, textAlign: "left" } as CSSProperties}>
      {variant === "radio" ? (
        <Radio
          icon={<img src={"https://svgur.com/i/11oX.svg"} />}
          checkedIcon={<img src={"https://svgur.com/i/11oX.svg"} />}
          style={{ padding: 0 }}
          {...props}
        />
      ) : (
        <Checkbox
          icon={<img src={"https://svgur.com/i/11oX.svg"} />}
          checkedIcon={<img src={"https://svgur.com/i/11oX.svg"} />}
          style={{ padding: 0 }}
          {...props}
        />
      )}
    </IRATableCell>
  );
};

export const DefaultCellComponent = ({
  compositeKey,
  style,
  className,
  data,
}: {
  compositeKey: string;
  style: React.CSSProperties;
  className: string;
  data: any;
}) => {
  return (
    <IRATableCell key={compositeKey} style={style} className={className}>
      {(typeof data === "object" ? Object.values(data)[0] : data) ?? "NA"}
    </IRATableCell>
  );
};

export const DefaultHeaderCellComponent = (props: any) => {
  return <IRATableCell {...props} />;
};
