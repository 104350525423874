import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import ProfilePicPng from "../../app/assets/DentaAssurePlans/profileImage.png";
import CustomButton from "../Button";
import { theme } from "../../theme/theme";
import ClientOtpverifyScreen from "../../screens/Client/ManageClient/client-otp-verify-screen";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const ClientPlanDetailsCard = (props: any) => {
  const [openOtpForm, setOpenOtpForm] = useState(false);
  const { client } = props;
  const { clientData } = client;
  // console.log(clientData, "Dataa...");
  const navigate = useNavigate();

  // console.log(clientData);

  const handleSubmit = () => {
    setOpenOtpForm(!openOtpForm);
  };

  const handleClick = () => {
    navigate(`/client/${clientData?.id}/view/plan-details`);
  };

  return (
    <>
      {" "}
      {openOtpForm === true ? (
        <ClientOtpverifyScreen client={client} />
      ) : (
        <Box>
          <Typography
            sx={{
              fontFamily: theme.typography.bold,
              fontSize: "16px",
            }}
          >
            Client Details
          </Typography>
          <Box>
            <Box
              padding={"10px"}
              bgcolor={"#E1F1FA"}
              mt={"6px"}
              borderRadius={"11px"}
            >
              <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                <Box
                  padding={"5px"}
                  borderRadius={"50%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"90px"}
                  border={"1px solid #7C77FE"}
                >
                  <img
                    width={"80px"}
                    src={ProfilePicPng}
                    alt="profilePic"
                    style={{ borderRadius: "50%" }}
                  />
                </Box>
                <Box width={"100%"}>
                  <Box
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography
                      variant="h5"
                      fontSize={"16px"}
                      sx={{
                        fontFamily: theme.typography.regular,
                      }}
                    >
                      {clientData?.firstName} {clientData?.lastName}
                    </Typography>
                    <CustomButton
                      title={"View Profile"}
                      type="button"
                      borderRadius="5px"
                      handleButtonClick={handleClick}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="h5"
                      fontSize={"14px"}
                      sx={{
                        fontFamily: theme.typography.regular,
                      }}
                    >
                      <b>Id: </b>
                      {clientData?.id}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            mt={"10px"}
            padding={"0px 10px 0px 10px"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            pt={2}
          >
            <Typography
              sx={{
                fontFamily: theme.typography.bold,
                fontSize: "13px",
              }}
            >
              <b>Plan Type:</b> {clientData?.plan?.planType || "N/A"}
            </Typography>
            <Typography
              sx={{
                fontFamily: theme.typography.bold,
                fontSize: "13px",
              }}
            >
              <b>Validity :</b> {clientData?.plan?.planValidity || "N/A"}
            </Typography>
          </Box>
          <Box
            mt={"10px"}
            padding={"0px 10px 0px 10px"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            pt={2}
          >
            <Typography
              sx={{
                fontFamily: theme.typography.bold,
                fontSize: "13px",
              }}
            >
              <b>Date Of Birth:</b>{" "}
              {moment(clientData?.dateOfBirth).format("DD-MM-YYYY") || "N/A"}
            </Typography>
            <Typography
              sx={{
                fontFamily: theme.typography.bold,
                fontSize: "13px",
              }}
            >
              <b>Mobile No:</b> {clientData?.mobileNumber || "N/A"}
            </Typography>
          </Box>
          <br></br>
          {props.fromCommomnDashboard === "fromCommomnDashboard" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "15px",
                pt: 2,
              }}
            >
              <CustomButton
                title={"Cancel"}
                handleButtonClick={() => navigate("/dashboard")}
              />
              <CustomButton title={"Next"} handleButtonClick={handleSubmit} />

              {/* <Button
                onClick={handleSubmit}
                sx={{
                  height: "35px",
                  width: "80px",
                  // bgcolor: "#5C4EFF",
                  // color: "white",
                }}
              >
                Next
              </Button> */}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default ClientPlanDetailsCard;
