import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CustomCard from "../Card";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useNavigate } from "react-router-dom";

const ClinicProfileCard = (props: any) => {
  const { userData } = props;
  const navigate = useNavigate();

  const pencilClickHandler = () => {
    navigate(`/hospital/${userData?.id}/edit`);
  };

  return (
    <CustomCard sx={{ padding: "16px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign={"right"}>
          {/* <CustomButton
            title={"Proceed"}
            bgColor={"rgba(92, 78, 255, 1)"}
            marginLeft={"71%"}
            marginBottom={"5%"}
          /> */}
          <ModeEditIcon
            sx={{ color: "blue", cursor: "pointer" }}
            onClick={pencilClickHandler}
          />
          <Box>
            <CustomCard
              sx={{
                backgroundColor: "rgba(225, 241, 250, 1)",
                padding: "16px",
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                justifyContent={"flex-end"}
              >
                <Box
                  sx={{
                    borderRadius: "50%",
                    width: "110px",

                    border: "2px solid #7C77FE",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "grid",
                  }}
                >
                  <Box
                    component="img"
                    src={require("../../app/assets/Hospital/user.png")}
                  ></Box>
                </Box>
                <Typography variant="body1" color="text.secondary">
                  <strong>{userData?.name}</strong>
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  <strong>Clinic ID : </strong> {userData?.id}
                </Typography>
              </Box>
            </CustomCard>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "5px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Status:</strong>
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {userData?.status}
            </Typography>
          </Box>

          <Box my={2}>
            <Typography style={{ fontSize: "17px" }} color="text.secondary">
              <strong>Clinic Details</strong>
            </Typography>
          </Box>
          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "5px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Name</strong>
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {userData?.name}
            </Typography>
          </Box>
          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "5px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Registration:</strong>
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {userData?.registrationNumber}
            </Typography>
          </Box>

          {/* <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "5px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Contact Person:</strong>
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {"06/09/1999"}
            </Typography>
          </Box> */}

          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "5px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Role:</strong>
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {userData?.contactPersonRole}
            </Typography>
          </Box>

          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "5px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Timings:</strong>
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {"Mon - Sat: 9:00AM - 5:00PM"}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box my={2}>
            <Typography style={{ fontSize: "17px" }} color="text.secondary">
              <strong>Communication Details</strong>
            </Typography>
          </Box>
          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "5px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Person Name:</strong>
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {userData?.contactPersonName}
            </Typography>
          </Box>

          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "5px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Primary Mobile:</strong>
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {userData?.mobileNumber}
            </Typography>
          </Box>

          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "5px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Whatsapp:</strong>
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {userData?.mobileNumber}
            </Typography>
          </Box>

          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "5px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Secondary Mobile:</strong>
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {userData?.alternateMobileNumber}
            </Typography>
          </Box>

          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "5px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Email:</strong>
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {userData?.email}
            </Typography>
          </Box>

          <Box my={2}>
            <Typography style={{ fontSize: "17px" }} color="text.secondary">
              <strong>Address Details</strong>
            </Typography>
          </Box>
          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "5px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>City:</strong>
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {userData?.address?.city}
            </Typography>
          </Box>

          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "5px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Country:</strong>
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {userData?.address?.country}
            </Typography>
          </Box>
          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "5px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>State:</strong>
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {userData?.address?.state}
            </Typography>
          </Box>
          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "5px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Street:</strong>
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {userData?.address?.street}
            </Typography>
          </Box>
          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "5px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Zip Code:</strong>
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {userData?.address?.zip}
            </Typography>
          </Box>
          <br />

          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "5px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Description:</strong>
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {userData?.description}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </CustomCard>
  );
};

export default ClinicProfileCard;
