export const styles = {
  breadcrumbSubtext: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: "primary.main",
    textDecoration: "none",
  },
  screenContainer: {
    marginTop: 2,
    marginRight: 2,
    marginBottom: 2,
  },
  cardHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rowData: {
    fontFamily: "Poppins",
    fontSize: 12,
    color: "primary.main",
  },
  patientName: {
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 400,
    color: "black",
    marginLeft: 1,
  },
  avatarImage: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  chairColor: {
    color: "#E5050B",
    fontSize: 12,
  },
  chairColorText: {
    fontFamily: "Poppins",
    fontSize: 12,
    color: "#E5050B",
  },
  notesIcon: {
    color: "#204289",
    fontSize: 25,
  },
  actionIcon: {
    color: "#204289",
    fontSize: 20,
  },
  filterContainer: {
    padding: 1,
  },
  tableContainer: {
    // marginTop : 1
  },
  tableCell: {
    fontFamily: "Poppins",
    fontSize: 12,
    color: "#204289",
    textAlign: "center",
    textDecoration: "none",
    marginLeft: 1,
    marginTop: 0.5,
  },
  appointmentDate: {
    fontFamily: "Poppins",
    fontSize: 13,
    fontWeight: 400,
    color: "#204289",
  },
  appointmentTime: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: "#204289",
  },
  Chair: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: "red",
  },
  heading: {
    fontFamily: "Poppins",
    fontSize: 15,
    color: "#021025",
    fontWeight: "600",
    marginTop: 6,
    marginLeft: 1,
  },
  verticalLine: {
    position: "absolute",
    //top : 40,
    zIndex: 99999,
  },
  screenContainer1: {
    marginTop: 2,
    marginRight: 2,
    marginBottom: 2,
  },
  doctorName: {
    fontFamily: "Poppins",
    fontSize: 13,
    color: "primary.main",
    fontWeight: "500",
    textAlign: "left",
  },
  investigation: {
    fontFamily: "Poppins",
    fontSize: 13,
    color: "primary.main",
    fontWeight: "500",
    textAlign: "right",
  },
  toothNumbers: {
    fontFamily: "Poppins",
    fontSize: 13,
    color: "#021025",
    fontWeight: "600",
    textAlign: "center",
  },
  blueLabel: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: "#204289",
    fontWeight: 600,
  },
  buttonContainer: {
    marginRight: 6,
    marginBottom: 3,
    marginTop: 3,
  },
};
