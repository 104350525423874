import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import CustomInput from "../../components/Input";
import CustomButton from "../../components/Button";
import styles from "./login.module.css";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import BgImage from "../../app/assets/Dashboard/login.jpg";
import Logo from "../../app/assets/logo.png";
import { useNavigate } from "react-router-dom";
import { theme } from "../../theme/theme";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiRequest } from "../../app/services/requests";
import { useAppContext } from "../../app/context/AppContext";
import { useSnackbar } from "../../app/context/useSnackbar";

const LoginPage = (props: any) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const { handleLoginSuccess } = useAppContext() as any;
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const schema = yup
    .object({
      email: yup
        .string()
        .required("Email is required")
        .test("len", "Must be  10-digit mobile number", (val) => {
          if (!val) return true; // Allow empty values
          const isEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(val);
          const isMobileNumber = /^\d{10}$/.test(val);
          return isEmail || isMobileNumber;
        }),
      password: yup
        .string()
        .required("Password is required")
        .min(6, "The password must be of six characters."),
    })
    .required();
  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { email: "", password: "" },
  });
  const onSubmit = (data: any) => {
    const payload = {
      password: data.password,
      email: data.email,
    };
    apiRequest({
      url: `user/login`,
      data: payload,
      method: "POST",
    })
      .then((res: any) => {
        if (res?.success === true) {
          localStorage.setItem("user", JSON.stringify(res));
          handleLoginSuccess(res);
          navigate("/dashboard");
          openSnackbar(res?.message, "success");
        }
        if (res?.success === false) {
          openSnackbar(res?.message, "error");
        }
      })
      .catch((err) => {});
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f8f9fc",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // Column in mobile, row in desktop
          width: "100%",
          maxWidth: "100%",
          alignItems: { xs: "center" },
          height: { xs: "100vh", md: "100vh" },
          borderRadius: "12px",
          overflow: "hidden",
          backgroundColor: "white",
        }}
      >
        {/* Left Section (Background + Logo) */}
        <Box
          sx={{
            width: { xs: "100%", md: "63%" }, // Full width in mobile, 63% in desktop
            height: { xs: "100vh", md: "90vh" }, // 40vh in mobile, auto in desktop
            backgroundImage: `url(${BgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", md: "flex-start" },
            justifyContent: "flex-start",
            padding: "20px",
            borderRadius: { xs: "0px", md: "30px" },
            margin: { xs: "0px", md: "16px" },
          }}
        >
          <img src={Logo} alt="Logo" style={{ width: "240px" }} />
        </Box>

        {/* Right Section (Sign-in Form) */}
        <Box
          sx={{
            width: { xs: "68%", md: "37%" }, // Full width in mobile, 37% in desktop
            display: "flex",
            position: { xs: "absolute", md: "relative" },
            top: { xs: "24%", md: "0" },
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "40px",
            borderRadius: { xs: "20px" },
            backgroundColor: { xs: "rgba(255, 255, 255, 0.81)", md: "white" }, // Add transparency in mobile
          }}
        >
          <Box sx={{ width: { xs: "100%", md: "70%" } }}>
            <Typography variant="h5" fontWeight={600} textAlign="center" mb={3}>
              Sign In
            </Typography>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <Box mb={2}>
                <Typography
                  color="textPrimary"
                  fontWeight={550}
                  fontSize={14}
                  pb={1}
                >
                  Email Address
                </Typography>
                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={!!error}
                      placeholder="Enter Email Address"
                    />
                  )}
                />
              </Box>
              <Box mb={3}>
                <Typography
                  color="textPrimary"
                  fontWeight={550}
                  fontSize={14}
                  pb={1}
                >
                  Password
                </Typography>
                <Controller
                  name="password"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={!!error}
                      placeholder="Enter Password"
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{ cursor: "pointer" }}
                          >
                            {showPassword ? (
                              <VisibilityIcon
                                onClick={handleClickShowPassword}
                              />
                            ) : (
                              <VisibilityOffIcon
                                onClick={handleClickShowPassword}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
              <CustomButton
                type="submit"
                title="Sign In"
                fullWidth
                backgroundcolor="#6c63ff"
              />
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginPage;
