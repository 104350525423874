import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import uploadToS3 from "../../../app/utilities/uploadToS3";
import { FILE_PATHS, URLS } from "../../../app/utilities/constants";
import cogoToast from "cogo-toast";
import { FileUploader } from "react-drag-drop-files";
import { ProfilePic } from "../../../app/utilities";
import CustomAutocomplete from "../../../components/Autocomplete";

const Documents = (props: any) => {
  // console.log(props);
  let user = {} as any;

  let defaultDocs = [
    {
      documentName: "007a6f8e-8251-4079-aee8-ed95d0748a56-images.jpeg",
      documentType: "photo",
      documentPath:
        "patients/1706003178729-007a6f8e-8251-4079-aee8-ed95d0748a56-images.jpeg",
      fileType: "image/jpeg",
    },
    {
      documentName:
        "8f173d18-c78a-4d28-85e3-24580afd15e2-SampleJPGImage_1mbmb - Copy.jpg",
      documentType: "photo",
      documentPath:
        "patients/1706003196379-8f173d18-c78a-4d28-85e3-24580afd15e2-SampleJPGImage_1mbmb - Copy.jpg",
      fileType: "image/jpeg",
    },
  ];
  // console.log(props?.userData?.aadharDocumentUrl);
  const [medicalRecords, setMedicalRecords] = useState<any>(defaultDocs);
  const [tabVal, setTabVal] = useState("photo");

  const { CASE_HISTORY_APPOINTMENT_URL, GET_COURSE_MASTER_URL } = URLS;

  const { PATIENTS_PATH } = FILE_PATHS;

  const handleFileChange = (file: any) => {
    // setLoader(true);
    uploadToS3(PATIENTS_PATH, file, tabVal)
      .then(async (response: any) => {
        const files: any = response;
        setMedicalRecords([...medicalRecords, ...files]);
        // setOpenModal(false);
        cogoToast.success("Files uploaded sucessfully", {
          position: "top-right",
          heading: "Success",
          hideAfter: 7,
        });
        // setLoader(false);
      })
      .catch(() => {
        // setLoader(false);
        cogoToast.error("Failed to upload file. Please try again", {
          position: "top-right",
          heading: "Error",
          hideAfter: 7,
        });
      });
  };

  const removeImage = (indexToRemove: number) => {
    setMedicalRecords((prevStepDetails: any) => {
      const updatedLabWorkPhotos = prevStepDetails?.filter(
        (_: any, index: number) => index !== indexToRemove
      );

      return {
        ...prevStepDetails,
        ...updatedLabWorkPhotos,
      };
    });
  };

  const deletePhotoDoc = async (data: any) => {
    const filtered = medicalRecords.filter(
      (photoDoc: any) => photoDoc.documentPath !== data.documentPath
    );
    setMedicalRecords(filtered);
  };

  return (
    <Box px={4}>
      <Box mt={2} style={{ display: "flex" }}>
        <Grid item xs={5} md={5} style={{ marginRight: "10px" }}>
          <CustomAutocomplete
            value={""}
            placeholder={"Enter Document Type"}
            singleSelection
            onChange={(event: React.SyntheticEvent, value: any) => {}}
            options={Object.keys({})}
          />
        </Grid>

        <FileUploader
          handleChange={handleFileChange}
          name="file"
          multiple
          classes="drop_area drop_zone"
        />
      </Box>
      <Box mb={1} mt={3}>
        <Typography> Registration Documents </Typography>
      </Box>

      <Grid>
        <Box>
          <img src={props?.userData?.aadharDocumentUrl} alt="AdahrCard" />
        </Box>
        <Box>
          <img src={props?.userData?.panDocumentUrl} alt="PanCard" />
        </Box>
      </Grid>
      <Box mt={2}>
        <Typography> Clinic Documents </Typography>
      </Box>
      <Grid container spacing={2} gap={6}>
        {medicalRecords?.length ? (
          medicalRecords?.map((photo: any, index: number) => (
            <Grid item width={"8%"} mt={3.5}>
              <Box sx={{ position: "relative" }}>
                <Box
                  component={"img"}
                  alt="photo"
                  src={require("../../../app/assets/Hospital/bin.png")}
                  sx={{
                    position: "absolute",
                    bottom: "0",
                    top: "5px",
                    width: "15px",
                    fontSize: "12px",
                    height: "19px",
                    // backgroundColor: "#e1e1e2",
                    borderRadius: "50%",
                    zIndex: "1",
                    left: "70px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    removeImage(index);
                  }}
                ></Box>

                <Box>
                  <ProfilePic
                    profilePhoto={
                      photo.photoPath ? photo.photoPath : photo.documentPath
                    }
                    styles={{
                      width: "90px",
                      height: "80px",
                      cursor: "pointer",
                    }}
                    isProfilePic={false}
                    onClick={() => {}}
                  />
                </Box>
              </Box>
            </Grid>
          ))
        ) : (
          <>{"No Images are available"}</>
        )}
      </Grid>
    </Box>
  );
};

export default Documents;
