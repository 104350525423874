import React, { useEffect } from "react";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import "./tabs.css";
import { theme } from "../../theme/theme";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tab-panel"
      hidden={value !== index}
      id={`simple-tab-panel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function CustomTabs(props) {
  const { tabOptions, onTabChange, currentTab = 0, disabled= false } = props;

  const [value, setValue] = React.useState(currentTab);

  useEffect(() => {
    setValue(currentTab);
  }, [currentTab]);

  const handleChange = (event, newIndex) => {
    const selectedTab = tabOptions[newIndex];

    if (selectedTab?.id) {
      setValue(selectedTab?.id);
      onTabChange(selectedTab?.id);
    } else {
      setValue(selectedTab?.index);
      onTabChange(selectedTab?.index);
    }
  };

  return (
    <TabContext value={value}>
      <Box>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          {tabOptions?.map((item, index) => {
            return (
              <Tab
                key={item?.id}
                label={item?.name}
                sx={{
                  "&.Mui-selected": {
                    bgcolor: theme.palette.customColors.white,
                    // boxShadow: theme.palette.customTabBoxShadow,
                    fontFamily: theme.typography.bold,
                  },
                  "&.MuiTab-root": {
                    textTransform: "none",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    fontFamily: theme.typography.bold,
                  },
                }}
                disabled={disabled}
              />
            );
          })}
        </TabList>
      </Box>
      <Box>
        {tabOptions?.map((option, index) => {
          return (
            <Box mt={3}>
              <TabPanel value={value} key={option?.id} index={index}>
                {option.component}
              </TabPanel>
            </Box>
          );
        })}
      </Box>
    </TabContext>
  );
}
