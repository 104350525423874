// TermsAndConditions.js
import { AppBar, Box, CssBaseline } from "@mui/material";
import { Container, Grid } from "@mui/material";
import styles from "../../components/Layout/layout.module.css";
import Background from "../../app/assets/Dashboard/background.jpg";

const TermsAndConditions = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <CssBaseline />
      <Box
        position="fixed"
        sx={{
          boxShadow: "none",
          zIndex: "3",
          width: "100%",
          backgroundColor: "#FFFFFF",
        }}
      >
        {/* header part */}
        <Box padding={"10px 50px 10px 50px"}>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <Box
              sx={{ cursor: "pointer" }}
              display={"flex"}
              gap={"20px"}
              alignItems={"center"}
            >
              <Box
                component="img"
                className={styles?.logoImage}
                alt="Logo"
                src={require("../../app/assets/logo.png")}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {/* background part*/}
      <Grid
        style={{
          backgroundImage: `url(${Background})`,
          backgroundRepeat: "repeat",
          backgroundSize: "cover",
          width: "100%",
        }}
      >
        <Box sx={{ padding: "0px 40px !important", marginTop: "120px" }}>
          <Box mt={10}>
            <h1>Terms and Conditions</h1>
            </Box>
            </Box>
            </Grid>
            <Box  sx={{ padding: "20px 40px !important"}}>
            <h2>1. Introduction</h2>
            <p>These Terms govern your use of the DentAssure website and mobile application. By accessing our platform, you agree to comply with these terms.</p>
            <h2>2. User Accounts</h2>
            <ul>
              <li>Clients, networking hospitals, and agents must create an account to access services.</li>
              <li>You are responsible for maintaining the confidentiality of your login credentials.</li>
            </ul>
            <h2>3. Insurance Policies and Claims</h2>
            <ul>
              <li>Policy details, coverage limits, and exclusions are defined in individual policy documents.</li>
              <li>Claims must be submitted within the stipulated timeframe with required documentation.</li>
              <li>DentAssure reserves the right to reject claims that do not meet policy terms.</li>
            </ul>
            <h2>4. User Conduct</h2>
            <ul>
              <li>Users shall not engage in fraudulent activities, including false claims.</li>
              <li>Misuse of the platform or sharing false information is prohibited.</li>
            </ul>
            <h2>5. Service Availability</h2>
            <ul>
              <li>We strive to ensure uninterrupted service but do not guarantee continuous availability due to technical or maintenance-related issues.</li>
            </ul>
            <h2>6. Liability Disclaimer</h2>
            <ul>
              <li>DentAssure is not responsible for incorrect medical treatments provided by networking hospitals.</li>
              <li>We are not liable for financial losses due to misuse of user credentials.</li>
            </ul>
            <h2>7. Modifications to Terms</h2>
            <p>DentAssure reserves the right to modify these Terms at any time.</p>
            <h2>8. Governing Law</h2>
            <p>These Terms are governed by the laws of India.</p>
            </Box>
        </Box>
  );
};

export default TermsAndConditions;
