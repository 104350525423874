// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .tabs {
  margin: 0px 180px;
} */

/* @media screen and (min-width: 1280px) and (max-width: 3000px) {
    .tabs {
      margin: 0px 180px -37px 180px !important;
    }
  } */

@media (min-width: 320px) and (max-width: 580px) {
  .boxedWidthForTabs {
    width: 340px;
  }
}
.boxedWidthForTabs {
  width: 1100px;
}
`, "",{"version":3,"sources":["webpack://./src/components/SecondaryCustomTabs/tabs.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;;;;KAIK;;AAEL;EACE;IACE,YAAY;EACd;AACF;AACA;EACE,aAAa;AACf","sourcesContent":["/* .tabs {\n  margin: 0px 180px;\n} */\n\n/* @media screen and (min-width: 1280px) and (max-width: 3000px) {\n    .tabs {\n      margin: 0px 180px -37px 180px !important;\n    }\n  } */\n\n@media (min-width: 320px) and (max-width: 580px) {\n  .boxedWidthForTabs {\n    width: 340px;\n  }\n}\n.boxedWidthForTabs {\n  width: 1100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
