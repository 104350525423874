// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.login_heading__DTOjo {
  font-size: 22px;
  font-weight: 700;
  color: #204289;
}
.login_logoImage__JB1OY {
  width: 40px;
}
.login_fieldIcon__3d\\+Wl {
  width: 12px;
}
.login_eyeIcon__BwYue {
  width: 16px;
}
.login_bgImage__rhGYZ {
  height: 92vh;
  width: 100%;
  object-fit: contain;
}

.login_logoText__JEgAY {
  font-weight: 600;
  color: white;
  padding-left: 2;
}

.login_row__XUERK {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.login_MainHeading__vTqxj {
  font-size: 24px;
  color: "#021025";
  font-weight: 550;
}
.login_SubHeading__cHqac {
  font-size: 14;
  color: "#021025";
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Login/login.module.css"],"names":[],"mappings":";;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;AACb;AACA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":["\n\n.heading {\n  font-size: 22px;\n  font-weight: 700;\n  color: #204289;\n}\n.logoImage {\n  width: 40px;\n}\n.fieldIcon {\n  width: 12px;\n}\n.eyeIcon {\n  width: 16px;\n}\n.bgImage {\n  height: 92vh;\n  width: 100%;\n  object-fit: contain;\n}\n\n.logoText {\n  font-weight: 600;\n  color: white;\n  padding-left: 2;\n}\n\n.row {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  margin-top: 15px;\n  margin-bottom: 15px;\n}\n.MainHeading {\n  font-size: 24px;\n  color: \"#021025\";\n  font-weight: 550;\n}\n.SubHeading {\n  font-size: 14;\n  color: \"#021025\";\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `login_heading__DTOjo`,
	"logoImage": `login_logoImage__JB1OY`,
	"fieldIcon": `login_fieldIcon__3d+Wl`,
	"eyeIcon": `login_eyeIcon__BwYue`,
	"bgImage": `login_bgImage__rhGYZ`,
	"logoText": `login_logoText__JEgAY`,
	"row": `login_row__XUERK`,
	"MainHeading": `login_MainHeading__vTqxj`,
	"SubHeading": `login_SubHeading__cHqac`
};
export default ___CSS_LOADER_EXPORT___;
