import { Box, Grid, Typography } from "@mui/material";
import greenTickSvg from "../../../app/assets/DentaAssurePlans/green-tick.png";
import { theme } from "../../../theme/theme";
import CustomButton from "../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { apiRequest } from "../../../app/services/requests";

export const PaymentSuccessScreen = (props: any) => {
  const navigate = useNavigate();
  const { clientId } = useParams();

  const [clientDet, setClientDet] = useState("") as any;
  // console.log(clientDet, "Clients");

  useEffect(() => {
    getClientDetails();
  }, []);

  const getClientDetails = () => {
    apiRequest({
      url: `client/${clientId}`,
      method: "GET",
    })
      .then((res: any) => {
        if (res) {
          setClientDet(res);
        }
      })
      .catch((err) => {
        // if (err?.statusCode === 403 || err?.statusCode === 401) {
        //   if (navigate) {
        //     navigate("/login"); // Navigate to "/login" route
        //   }
        // }
      });
  };

  const paymentDoneHandle = () => {
    navigate(`/client/${clientDet?.id}/view/plan-details`);
  };
  return (
    <>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Box
          display={"flex"}
          width={"400px"}
          height={"300px"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Box>
            <img src={greenTickSvg} alt="green" />
          </Box>
          <Box>
            <Typography
              textAlign={"center"}
              mt={"15px"}
              color={theme.palette.customColors.main}
            >
              Application No. : DADI121810282 Payment Successfully
            </Typography>
            <Typography textAlign={"center"} mt={"15px"}>
              Your plan has successfully submitted DentAssure team will verify
              your application Within 48 Hours
            </Typography>
          </Box>
          <Box mt={"10px"}>
            <CustomButton
              type="submit"
              title={"Done"}
              backgroundcolor={"#204289"}
              bordercolor={"#204289"}
              color={"#fffff"}
              width={110}
              padding={7}
              handleButtonClick={paymentDoneHandle}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
