export type NameColorMap<T> = {
  [type in T | any]: {
    name: string;
    color: string | any;
  };
};

export const statuses: NameColorMap<any> = {
  true: {
    name: 'Yes',
    color: '#26d400',
  },
  false: {
    name: 'Not yet',
    color: '#f8ba16',
  },

  default: {
    name: '-',
    color: null,
  },
};

export const responses: NameColorMap<any> = {

  NA: {
    name: '-',
    color: '#000000',
  },
  yetToRespond: {
    name: 'Yet to Respond',
    color: '#f8ba16',
  },
  accepted: {
    name: 'Accepted',
    color: '#26d400',
  },
  rejected: { name: 'Rejected', color: '#cb1930' },
  active: {
    name: 'Active',
    color: '#26d400',
  },
  inActive: {
    name: 'Inactive',
    color: '#f8ba16',
  },
  inactive: {
    name: 'Inactive',
    color: '#f8ba16',
  },
  pending: {
    name: 'Inactive',
    color: '#f8ba16',
  },
  cancelled: { name: 'Cancelled', color: '#cb1930' },
  'yet-to-start': {
    name: 'Yet to start',
    color: '#f8ba16',
  },
  'yet-to-acknowledge': {
    name: 'Yet to acknowledge',
    color: '#f8ba16',
  },
  acknowledged: {
    name: 'Acknowledged',
    color: '#26d400',
  },
  'yet-to-be-confirmed': {
    name: 'Yet to be confirmed',
    color: '#f8ba16',
  },
  'set-to-go-live': {
    name: 'Set to go live',
    color: '#04cee0',
  },
  confirmed: {
    name: 'Confirmed',
    color: '#26d400',
  },
  'in-progress': {
    name: 'In-Progress',
    color: '#f8ba16',
  },
  completed: {
    name: 'Completed',
    color: '#26d400',
  },
  registered: {
    name: 'Registered',
    color: '#26d400',
  },
  notRegistered: {
    name: 'Not Registered',
    color: '#f8ba16',
  },
  default: {
    name: '-',
    color: null,
  },
  started: {
    name: 'Started',
    color: '#f8ba16',
  },
  accessed: {
    name: 'Accessed',
    color: '#26d400',
  },
  notAccessed: {
    name: 'Not-accessed',
    color: '#cb1930',
  },
  Assigned: {
    name: 'Assigned',
    color: '#26d400',
  },
  Unassigned: {
    name: 'Unassigned',
    color: '#cb1930',
  },
  scheduled: {
    name: 'Scheduled',
    color: '#f8ba16',
  },
  'request-to-rescheduled': {
    name: 'Reschedule Requested',
    color: '#04cee0',
  },
  'yet-to-join': {
    name: 'Yet to join',
    color: '#f8ba16',
  },
  joined: {
    name: 'Joined',
    color: '#26d400',
  },
  'conducted-outside-prism': {
    name: 'Conducted Outside Prism',
    color: '#f8ba16',
  },
  'not-yet-started': {
    name: 'Not yet started',
    color: '#f8ba16',
  },
  'not-started': {
    name: 'Not started',
    color: '#cb1930',
  },
  reviewed: {
    name: 'Reviewed',
    color: '#26d400',
  },
  'not-reviewed': {
    name: 'Pending',
    color: '#f8ba16',
  },
  submitted: {
    name: 'Completed',
    color: '#26d400',
  },
  "new-case": {
    name: "New Case",
    color: "#CB8C00"
  },
  "claim": {
    name: "Claimed",
    color: "#CB8C00"
  },
  "startDate": {
    name: "startDate",
    color: "#CB8C00"
  }
};