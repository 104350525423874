import { Box, Grid, Paper, Typography } from "@mui/material";
import styles from "../.././screens/Client/client.module.css";
import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const ClientPlanDetails = (props: any) => {
  // let user = {} as any;s
  const { clientDetails } = props;

  return (
    <Box sx={{ pl: "10px" }}>
      <Box className={styles.clientPlanType}>
        <Typography>
          Plan Type:{" "}
          <strong style={{ fontSize: "14px", color: "#313035" }}>
            {" "}
            {clientDetails?.plan?.planType || "N/A"}
          </strong>
        </Typography>
        <Typography>
          Validity:{" "}
          <strong style={{ fontSize: "14px", color: "#313035" }}>
            {" "}
            {clientDetails?.plan?.planValidity || "N/A"}
          </strong>
        </Typography>
        <Typography>
          Status: <b>{clientDetails?.status}</b>
        </Typography>
      </Box>

      <Box className={styles.borderbottom}></Box>
      <Typography sx={{ p: 2 }}>Details</Typography>

      <Box className={styles.clientPlanType}>
        <Typography>
          Preventive :{" "}
          <strong style={{ fontSize: "14px", color: "#313035" }}>
            {" "}
            3/10 (50%) <ErrorOutlineIcon sx={{ fontSize: "14px" }} />{" "}
          </strong>
        </Typography>
        <Typography>
          Basic :{" "}
          <strong style={{ fontSize: "14px", color: "#313035" }}>
            {" "}
            3/5 (40%) <ErrorOutlineIcon sx={{ fontSize: "14px" }} />{" "}
          </strong>
        </Typography>
        <Typography>
          Minor :{" "}
          <strong style={{ fontSize: "14px", color: "#313035" }}>
            {" "}
            3/4 (20%) <ErrorOutlineIcon sx={{ fontSize: "14px" }} />{" "}
          </strong>
        </Typography>
        <Typography>
          Major :{" "}
          <strong style={{ fontSize: "14px", color: "#313035" }}>
            {" "}
            2/3 (10%) <ErrorOutlineIcon sx={{ fontSize: "14px" }} />{" "}
          </strong>
        </Typography>
      </Box>
      <Box className={styles.borderbottom}></Box>

      <Box>
        <br />
        <Typography>Services/Treatments Covered</Typography>
        <Typography className={styles.ServiecesText}>
          <br />- Some treatments once finished on a tooth cannot be done for
          1yr,3yrs,5yrs etc.
        </Typography>
        <br />
        <Typography className={styles.ServiecesText}>
          -Cap done cannot claimed for 5 years
        </Typography>
        <br />
        <Typography className={styles.ServiecesText}>
          - Denture Cannot be repeated with in 5 years{" "}
        </Typography>
        <br />
        <Typography className={styles.ServiecesText}>
          - Filling cannot be Claimed with in 2 years
        </Typography>
        <br />
        <Typography className={styles.ServiecesText}>
          - X rays are fixed per year
        </Typography>
        <br />
        <Box className={styles.borderbottom}></Box>
      </Box>

      <Grid
        item
        xs={12}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        paddingLeft={"10px"}
        paddingRight={"10px"}
        paddingTop={"5px"}
      >
        <Box>
          <Typography>Preventive:</Typography>
          <Typography>Treatment Covered 10, Discount 50%</Typography>
        </Box>
        <Box>
          <Typography>Basic:</Typography>
          <Typography>Treatment Covered 10, Discount 50%</Typography>
        </Box>
      </Grid>
      <br />
      <Grid
        item
        xs={12}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        paddingLeft={"10px"}
        paddingRight={"10px"}
        paddingTop={"5px"}
        paddingBottom={"15px"}
      >
        <Box>
          <Typography>Minor:</Typography>
          <Typography>Treatment Covered 10, Discount 50%</Typography>
        </Box>
        <Box>
          <Typography>Major:</Typography>
          <Typography>Treatment Covered 10, Discount 50%</Typography>
        </Box>
      </Grid>
    </Box>
  );
};

export default ClientPlanDetails;
