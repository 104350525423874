import { useEffect, useState } from "react";
import { Box, Typography, Grid, TableRow, TableCell } from "@mui/material";
import { styles } from "./styles";
import CustomButton from "../../../components/Button";
import CustomModal from "../../../components/Modal";
import AddEditInterpretation from "./add-edit-interpretation";
import { UploadInvestigationModal } from "./upload-investigation-modal";
import ImageView from "../../../components/ImageView";
import { ProfilePic } from "../../../app/utilities";
import CloseIcon from "@mui/icons-material/Close";
import SmartText from "../../../components/SmartText";
import CustomAutocomplete from "../../../components/Autocomplete";
import { PROVISIONAL_DIAGNOSIS_INVESTIGATON } from "../../../app/utilities/staticData";
import SampleTable from "../../../components/SampleTable";

const tableHeadData = [
  "Tooth No",
  "Provisional Diagnosis",
  "Investigation",
  "Interpretation",
  "Final Diagnosis",
  "Exam Notes",
  "Treatment Planned",
];

const CaseInvestigation = (props: any) => {
  const {
    investigationData,
    doctorName = "",
    handleBack,
    handleSaveAndExit,
    handleSaveAndNext,
    handleContinueToTreatment,
    treatments,
    majorInvestigation,
  } = props || {};

  const [openInvestigationModal, setOpenInvestigationModal] = useState<any>({
    dialogType: "",
    open: false,
    data: {},
  } as any);
  const [isDirty, setIsDirty] = useState(false);

  const [allInvestigations, setAllInvestigations] =
    useState<Array<any>>(investigationData);
  const [triggerModal, setTriggerModal] = useState(false);
  const [selectedImageView, setSelectedImageView] = useState({});
  const [investigationOpenModal, setInvestigationOpenModal] = useState(false);
  const [selectedInvestigations, setSelectedInvestigations] = useState<any>({});

  useEffect(() => {
    setAllInvestigations(investigationData);
  }, [investigationData]);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = "";
        if (window.confirm("Are you sure you want to discard your changes?")) {
          setIsDirty(false);
        }
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty]);

  const handleInvestigationCloseModal = () => {
    setOpenInvestigationModal({
      dialogType: "",
      open: false,
      data: {},
    });
  };

  const handleOpenInvestigationModal = (type: string, data: any) => {
    setOpenInvestigationModal({
      dialogType: type,
      open: true,
      data: {
        ...data,
      },
    });
  };

  const handleInterpretationSubmit = (formData: any) => {
    if (formData.interpretation) {
      const index = openInvestigationModal.data.currentInvestigationIndex;
      if (index > -1) {
        allInvestigations[index] = {
          ...allInvestigations[index],
          interpretation: formData.interpretation
            ? formData.interpretation
            : openInvestigationModal.data.interpretation,
        };
        setAllInvestigations([...allInvestigations]);
      }
      setOpenInvestigationModal({
        dialogType: "",
        open: false,
        data: {},
      });
    }
  };

  const handleNotesSubmit = (formData: any) => {
    if (formData.interpretation) {
      const index = openInvestigationModal.data.currentInvestigationIndex;
      if (index > -1) {
        allInvestigations[index] = {
          ...allInvestigations[index],
          examinationNotes: formData.interpretation
            ? formData.interpretation
            : openInvestigationModal.data.interpretation,
        };
        setAllInvestigations([...allInvestigations]);
      }
      setOpenInvestigationModal({
        dialogType: "",
        open: false,
        data: {},
      });
    }
  };

  const handleInvestigationChange = (
    values: any,
    name: string,
    investigationIndex: any
  ) => {
    const clonedData = JSON.parse(JSON.stringify(allInvestigations));
    clonedData[investigationIndex] = {
      ...clonedData[investigationIndex],
      [name]: values,
    };
    setAllInvestigations(clonedData);
    setIsDirty(true);
  };

  const checkAllTreatmentsPlannedDisabled = () => {
    let isDisabled = true;
    if (
      allInvestigations
        ?.filter((data) => data.selectedTeeth)
        ?.every(
          (investigation) =>
            (investigation.treatmentsPlanned || []).length > 0 &&
            (investigation.finalDiagnosis || []).length > 0
        )
    ) {
      isDisabled = false;
    }
    return isDisabled;
  };

  const handleInvestigationModal = () => {
    setInvestigationOpenModal(!investigationOpenModal);
  };

  const submitInvestigationFiles = () => {
    const clonedInvestigations = JSON.parse(JSON.stringify(investigationData));
    const foundIndex = clonedInvestigations.findIndex(
      (val: any) => val.id === selectedInvestigations.id
    );
    if (foundIndex >= 0) {
      clonedInvestigations[foundIndex].investigation =
        selectedInvestigations.investigations;
      setAllInvestigations(clonedInvestigations);
    }
    handleInvestigationModal();
  };

  const listOfMajorInvestigation = majorInvestigation?.length
    ? majorInvestigation
        ?.map((investigation: any, index: any) => investigation.name)
        .join(", ")
    : [];

  return (
    <>
      <Box>
        <Box sx={styles.screenContainer1}>
          <Grid
            container
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Grid item md={6}>
              <Typography sx={styles.doctorName}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{doctorName}&nbsp;&nbsp;&nbsp; |
                &nbsp;&nbsp;&nbsp; Sever Tooth Ache at 18 and 22
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography sx={styles.investigation}>
                Select Major Investigation: &nbsp;&nbsp;
                {listOfMajorInvestigation}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <Box sx={styles.tableContainer}>
              <SampleTable header={tableHeadData}>
                {allInvestigations?.map((row, investigationIndex) =>
                  row.selectedTeeth ? (
                    <TableRow key={row.id}>
                      <TableCell width={"5%"}>
                        <Typography sx={styles.toothNumbers}>
                          {row.teethNumber ? (
                            <SmartText text={`${row.teethNumber}`} />
                          ) : (
                            ""
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell width={"15%"}>
                        <Typography sx={styles.tableCell}>
                          {row.provisionalDiagnosis}
                        </Typography>
                      </TableCell>
                      <TableCell width={"20%"}>
                        <Typography
                          sx={styles.tableCell}
                          style={{
                            wordBreak: "break-all",
                            textDecoration: "underline",
                          }}
                          onClick={() => {
                            handleInvestigationModal();
                            setSelectedInvestigations({
                              investigations: row.investigation,
                              id: row.id,
                            });
                          }}
                        >
                          {(row?.investigation || []).map(
                            (investigate: any, index: any) => (
                              <>
                                <span
                                  style={{
                                    cursor: "pointer",
                                    paddingRight: "5px",
                                  }}
                                >
                                  {investigate.name}{" "}
                                  {row.investigation.length - 1 !== index &&
                                    ","}
                                </span>
                              </>
                            )
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell width={"10%"}>
                        <Grid
                          container
                          sx={{ padding: 1 }}
                          justifyContent={"center"}
                          alignItems={"center"}
                          textAlign={"center"}
                          flexDirection={"column"}
                        >
                          <Grid item xs={12} mb={1}>
                            {row.interpretation ? (
                              <>
                                <Box
                                  component="img"
                                  sx={{
                                    height: 25,
                                    width: 25,
                                    borderRadius: 22,
                                    cursor: "pointer",
                                    textAlign: "center",
                                  }}
                                  alt={row.id + "alt"}
                                  src={require("../../../app/assets/activeaccounts.svg")}
                                  onClick={() => {
                                    setOpenInvestigationModal({
                                      dialogType: "addEditInterpretation",
                                      open: true,
                                      data: {
                                        interpretation: row.interpretation,
                                        currentInvestigationIndex:
                                          investigationIndex,
                                      },
                                    });
                                  }}
                                />
                                <Typography
                                  sx={{ ...styles.tableCell, margin: 0 }}
                                >
                                  Note
                                </Typography>
                              </>
                            ) : (
                              <CustomButton
                                title={"+ Add"}
                                handleButtonClick={() => {
                                  handleOpenInvestigationModal(
                                    "addEditInterpretation",
                                    {
                                      interpretation: "",
                                      currentInvestigationIndex:
                                        investigationIndex,
                                    }
                                  );
                                }}
                                backgroundcolor={"#204289"}
                                color={"#fffff"}
                                width={70}
                                padding={3}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell width={"20%"}>
                        <CustomAutocomplete
                          name="finalDiagnosis"
                          multiple
                          defaultValue={
                            row.finalDiagnosis ? row.finalDiagnosis : []
                          }
                          onChange={(
                            event: React.SyntheticEvent,
                            values: any,
                            option: any
                          ) => {
                            handleInvestigationChange(
                              values,
                              "finalDiagnosis",
                              investigationIndex
                            );
                          }}
                          options={PROVISIONAL_DIAGNOSIS_INVESTIGATON}
                          label="Select Final Diagnosis"
                          limitTags={2}
                          addText
                        />
                      </TableCell>
                      <TableCell width={"10%"}>
                        <Grid
                          container
                          justifyContent={"center"}
                          alignItems={"center"}
                          flexDirection={"column"}
                        >
                          <Grid item xs={12} mb={1}>
                            {row.examinationNotes ? (
                              <>
                                <Box
                                  component="img"
                                  sx={{
                                    height: 25,
                                    width: 25,
                                    borderRadius: 22,
                                    cursor: "pointer",
                                    textAlign: "center",
                                  }}
                                  alt={row.id + "alt"}
                                  src={require("../../../app/assets/activeaccounts.svg")}
                                  onClick={() => {
                                    setOpenInvestigationModal({
                                      dialogType: "examinationNotes",
                                      open: true,
                                      data: {
                                        interpretation: row.examinationNotes,
                                        currentInvestigationIndex:
                                          investigationIndex,
                                      },
                                    });
                                  }}
                                />
                                <Typography
                                  sx={{ ...styles.tableCell, margin: 0 }}
                                >
                                  Note
                                </Typography>
                              </>
                            ) : (
                              <CustomButton
                                title={"+ Add Notes"}
                                handleButtonClick={() => {
                                  handleOpenInvestigationModal(
                                    "examinationNotes",
                                    {
                                      interpretation: "",
                                      currentInvestigationIndex:
                                        investigationIndex,
                                    }
                                  );
                                }}
                                backgroundcolor={"#204289"}
                                color={"#fffff"}
                                width={70}
                                padding={3}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell width={"20%"}>
                        <CustomAutocomplete
                          multiple
                          defaultValue={
                            row.treatmentsPlanned ? row.treatmentsPlanned : []
                          }
                          onChange={(
                            event: React.SyntheticEvent,
                            values: any,
                            option: any
                          ) => {
                            handleInvestigationChange(
                              values,
                              "treatmentsPlanned",
                              investigationIndex
                            );
                          }}
                          options={treatments}
                          label="Select Treatment"
                          limitTags={2}
                          addText
                        />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )
                )}
              </SampleTable>
            </Box>
          </Grid>
        </Grid>
        <CustomModal
          zIndex={"99999"}
          open={openInvestigationModal.open}
          width={"500px"}
          height={"auto"}
        >
          <Box>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography
                fontSize={"22px"}
               
                fontWeight={"600"}
                color={"#204289"}
              >
                {openInvestigationModal.dialogType === "addEditInterpretation"
                  ? "interpretation"
                  : openInvestigationModal.dialogType === "examinationNotes"
                  ? "Exam Notes"
                  : "Investigation Details"}
              </Typography>
              <CloseIcon
                onClick={handleInvestigationCloseModal}
                className="pointer"
              />
            </Box>
            {openInvestigationModal.dialogType === "addEditInterpretation" && (
              <Box mt={"20px"}>
                <AddEditInterpretation
                  onCancel={handleInvestigationCloseModal}
                  onSubmit={handleInterpretationSubmit}
                  defaultValue={{
                    interpretation: openInvestigationModal.data.interpretation,
                  }}
                />
              </Box>
            )}
            {openInvestigationModal.dialogType === "examinationNotes" && (
              <Box mt={"20px"}>
                <AddEditInterpretation
                  onCancel={handleInvestigationCloseModal}
                  onSubmit={handleNotesSubmit}
                  placeholder="Examination Notes"
                  defaultValue={{
                    interpretation: openInvestigationModal.data.interpretation,
                  }}
                />
              </Box>
            )}
            {openInvestigationModal.dialogType === "showInvestigationDocs" && (
              <Box mt={"20px"}>
                <Box
                  sx={{
                    marginTop: "6px",
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  {(openInvestigationModal?.data?.files || [])?.map(
                    (file: any) => (
                      <Box>
                        <ProfilePic
                          profilePhoto={file.documentPath}
                          styles={{
                            width: "130px",
                            height: "130px",
                            cursor: "pointer",
                          }}
                          isProfilePic={false}
                          onClick={() => {
                            setSelectedImageView({
                              path: file.documentPath,
                              name: file.documentName,
                            });
                            setTriggerModal(true);
                          }}
                        />
                        <Typography sx={{ ...styles.tableCell }}>
                          {file.documentName}
                        </Typography>
                      </Box>
                    )
                  )}
                </Box>
              </Box>
            )}
            {openInvestigationModal.dialogType === "examinationNotes" ||
              (openInvestigationModal.dialogType ===
                "showInvestigationDocs" && (
                <Box mt={2}>
                  <Grid
                    container
                    flexDirection={"row"}
                    spacing={1}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                  >
                    <Grid item>
                      <CustomButton
                        title={"Ok"}
                        handleButtonClick={handleInvestigationCloseModal}
                        backgroundcolor={"#204289"}
                        bordercolor={"#204289"}
                        color={"#fffff"}
                        width={120}
                        padding={7}
                      />
                    </Grid>
                  </Grid>
                </Box>
              ))}
          </Box>
        </CustomModal>
      </Box>
      <Box sx={styles.buttonContainer}>
        <Grid
          container
          flexDirection={"row"}
          spacing={1}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Grid item>
            <CustomButton
              title={"Cancel"}
              handleButtonClick={() => props?.onClose()}
              // backgroundcolor={"#FFFFFF"}
              // bordercolor={"#204289"}
              // color={"#204289"}
              width={100}
            />
          </Grid>
          <Grid item>
            <CustomButton
              title={"Save"}
              handleButtonClick={() => handleSaveAndExit(allInvestigations)}
              backgroundcolor={"#204289"}
              bordercolor={"#204289"}
              color={"#fffff"}
              width={120}
              disabled={checkAllTreatmentsPlannedDisabled()}
            />
          </Grid>
        </Grid>
      </Box>
      <UploadInvestigationModal
        investigationOpenModal={investigationOpenModal}
        handleInvestigationModal={handleInvestigationModal}
        selectedInvestigations={selectedInvestigations}
        setSelectedInvestigations={setSelectedInvestigations}
        setSelectedImageView={setSelectedImageView}
        setTriggerModal={setTriggerModal}
        submitInvestigationFiles={submitInvestigationFiles}
      />
      {triggerModal && (
        <ImageView
          triggerModal={triggerModal}
          triggerCloseModal={() => setTriggerModal(false)}
          imageDetails={selectedImageView}
        />
      )}
    </>
  );
};

export default CaseInvestigation;
