import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const CustomButton = (props: any) => {
  const {
    title,
    handleButtonClick,
    bgColor = "#5C4EFF",
    width,
    color = "#ffffff",
    endIcon,
    padding,
    type = "button",
    margin,
    disabled = false,
    ...rest
  } = props;

  const ColorButton = styled(Button)(() => ({
    textTransform: "none",
    fontSize: 14,
    fontWeight: props.fontWeight ? props.fontWeight : 400,
    borderColor: props.borderColor,
    marginRight: props.marginRight,
    marginLeft: props.marginLeft,
    marginTop: props.marginTop,
    marginBottom: props.marginBottom,
    height: props.height ? props.height : "auto",
    color: props.color ? props.color : color,
    backgroundColor: props.backgroundColor || bgColor,
    borderRadius: 7,
    width: props.width ? props.width : undefined,
    padding: padding ? padding : undefined,
    margin: margin ? margin : undefined,
    borderWidth: props.borderWidth ? props.borderWidth : "0px",
    borderStyle: props.borderStyle ? props.borderStyle : "",
    border:
      bgColor === "#FFFFFF" || bgColor === "#fffff"
        ? "1px solid " + color
        : props.border,
    opacity: 1,
    "&:hover": {
      backgroundColor: bgColor,
      opacity: 0.8,
    },
  }));

  return (
    <ColorButton
      variant="contained"
      type={type}
      onClick={handleButtonClick}
      endIcon={endIcon}
      startIcon={props.startIcon}
      className={props.className}
      onChange={props.onChange}
      disabled={disabled}
      {...rest}
    >
      {title}
    </ColorButton>
  );
};

export default CustomButton;
