import { format } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";
import { IRATableCellCustomization } from "../../ira-table/ira-table.interfaces";
import IRATableCellView from "../ira-table-cell-view";
import { IRATableCell } from "../../ira-table";
import {
  DATE_FORMAT,
  DATE_FORMAT_WITH_TIMEZONE,
  TIME_FORMAT,
} from "../../../app/utilities/constants";
import CustomDatePicker from "../../DatePicker";

/* eslint-disable-next-line */
export interface DateTimeCellProps extends IRATableCellCustomization {
  dateTime?: string | any;
  timeZone?: any;
  // response?: keyof typeof responses;
}

export const DateTimeCell = (props: DateTimeCellProps) => {
  const {
    dateTime,
    timeZone,
    domId,

    ...rest
  } = props;

  // const date = dateTime
  //   ? timeZone
  //     ? formatToTimeZone(dateTime, DATE_FORMAT_WITH_TIMEZONE, { timeZone })
  //     : format(new Date(dateTime), DATE_FORMAT)
  //   : "-";
  // const time = dateTime
  //   ? timeZone
  //     ? formatToTimeZone(dateTime, TIME_FORMAT, { timeZone })
  //     : format(new Date(dateTime), TIME_FORMAT)
  //   : "-";
  return (
    <IRATableCell {...rest}>
      <IRATableCellView line1={dateTime ? dateTime : "-"} />
      {/* <CustomDatePicker /> */}
    </IRATableCell>
  );
};

export default DateTimeCell;
