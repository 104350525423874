import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { IRATableCellCustomization } from "../../ira-table/ira-table.interfaces";
import { responses, statuses } from "./responses-statuses-map";
import { IRATableCell } from "../../ira-table";
import IRATypography from "../../ira-typography";


/* eslint-disable-next-line */
export interface ResponseCellProps extends IRATableCellCustomization {
  response?: keyof typeof responses;
  status?: boolean;
  info?: React.ReactNode;
  noColor?: boolean;
 
}

export const ResponseCell = (props: ResponseCellProps) => {
  const { response, status, info, noColor, ...rest } = props;
 

  const { name, color } =
    (response
      ? responses[response] || responses["default"]
      : status
      ? statuses[(status ? "true" : "false") ?? "default"]
      : responses["default"]);

  
      

      

      
  return (
    <IRATableCell {...rest}>
      <Box
        color={noColor ? null : color}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <IRATypography
          textVariant="h9"
          fontWeight={name === "-" || noColor ? 400 : 400}
        >
          {name ? name :'NA'}
        </IRATypography>
        {info && (
          <Tooltip
            title={<React.Fragment>{info}</React.Fragment>}
            placement="top-start"
          >
            <InfoIcon />
          </Tooltip>
        )}
      </Box>
    </IRATableCell>
  );
};

export default ResponseCell;
