import { Box, CircularProgress, Grid, Typography } from "@mui/material";

import { useForm, Controller } from "react-hook-form";
import { array, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import CustomSelectPicker from "../../../components/SelectPicker";
import {
  GENDER,
  PLAN_NAMES,
  PLAN_TYPES_FOR,
  PLAN_VALIDITY,
  RELATIONSHIPS,
} from "../../../app/utilities/constants";
import CustomInput from "../../../components/Input";
import CustomDatePicker from "../../../components/DatePicker";
import CustomButton from "../../../components/Button";
import { theme } from "../../../theme/theme";
import { apiRequest } from "../../../app/services/requests";
import { useEffect, useState } from "react";
import DetailPlan from "./DetailPlan";

interface IStep1Props {
  onStep1Submit: (data: any) => void;
  onClickSkip: () => void;
  showSkip: boolean;
}

const Step1 = (props: IStep1Props) => {
  const { onStep1Submit, onClickSkip, showSkip } = props;

  const navigate = useNavigate();

  const userDefaultValues = (actionType?: string) => {
    if (actionType === "reset") {
      return {
        planType: "",
        planFor: "",
      } as any;
    } else {
      return {
        planType: "",
        planFor: "",
      } as any;
    }
  };

  const schema = object({
    planType: string().required("Plan Type is required"),
  });

  const { control, handleSubmit, getValues, setValue, watch, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: userDefaultValues("default"),
  });

  const watchPlanType = watch("planType");

  const { clientId, planId, planCode } = useParams();

  const [masterPlanList, setMasterPlanList] = useState<any>({});
  const [planLoading, setPlanLoading] = useState<Boolean>(false);

  const onSubmit = (formData: any) => {
    if (planId) {
      return navigate(
        `/client/${clientId}/plan/standard/2/${planId}/${planCode}`
      );
    }
    const payload = {
      masterPlanId: Number(formData?.planType),
      clientId: Number(clientId),
    };
    apiRequest({
      url: `plan/assign`,
      data: payload,
      method: "POST",
    })
      .then((res: any) => {
        navigate(
          `/client/${clientId}/plan/standard/2/${res?.id}/${formData?.planType}`
        );
        onStep1Submit({ planType: getValues("planType") });
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  const handleViewDetailPlanClick = () => {
    const type = getValues("planType");
    alert("Show Plan details:" + " " + type);
  };

  useEffect(() => {
    getAllPlanList({});
  }, []);

  const getAllPlanList = (filters?: any) => {
    apiRequest({
      url: `master-plan/list?type=standard&status=published`,
      method: "GET",
    })
      .then((res: any) => {
        if (res) {
          const modifiedRes = {
            totalCount: res?.totalCount,
            results: res?.results?.map((data: any) => ({
              id: data?.id,
              name: data?.name,
              code: data?.code,
              type: data?.type,
              premium: data?.premium,
              limit: data?.limit,
              status: data?.status,
            })),
          };
          if (planId) {
            setPlanLoading(true);
            const selectedMasterPlan = modifiedRes?.results?.find(
              (x: any) => x.id === parseInt(planCode || "0")
            );
            reset({ planType: selectedMasterPlan.id });
            setPlanLoading(false);
          }
          setMasterPlanList(modifiedRes);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (planId) {
      getCurrentPlan(planId);
    }
  }, [planId]);

  const getCurrentPlan = (planId?: any) => {
    apiRequest({
      url: `plan/${planId}`,
      method: "GET",
    })
      .then((res: any) => {
        if (res) {
          console.log("res", res);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  if (planLoading) {
    return <CircularProgress />;
  }

  return (
    <Box p={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          color={theme.palette.customColors.blackGrey}
          fontSize={"16px"}
          variant="h6"
          fontWeight={"600"}
        >
          Plan Details
        </Typography>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          mt={2}
        >
          <Box width="100%">
            <Controller
              name="planType"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectPicker
                  {...field}
                  error={!!error}
                  disabled={!!planId}
                  helperText={error ? error.message : ""}
                  label={"Select Plan Name"}
                  size={"small"}
                  fullWidth={true}
                  width={"100%"}
                  selectData={masterPlanList?.results || []}
                />
              )}
            />
            <DetailPlan
              planType={watchPlanType}
              planList={masterPlanList?.results || []}
            />
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="end"
          mt={2}
          gap={2}
        >
          <Grid item>
            <CustomButton
              border="1px solid #000000"
              color="#000000"
              title="Cancel"
              bgColor="#ffffff"
              handleButtonClick={() => navigate("/dashboard")}
              borderRadius="5px"
            />
          </Grid>
          <Grid item>
            <CustomButton
              title={"Clear"}
              handleButtonClick={() => {
                reset(userDefaultValues("reset"));
              }}
              border="1px solid #000000"
              color="#000000"
              bgColor="#ffffff"
            />
          </Grid>
          <Grid item>
            <CustomButton
              type="submit"
              title={"Next"}
              backgroundcolor={"#204289"}
              bordercolor={"#204289"}
              color={"#fffff"}
              width={110}
              padding={7}
            />
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default Step1;
