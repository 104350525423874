import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography} from "@mui/material";
import Box from "@mui/material/Box";
const CustomSpinner = (props) => {
  return (
    <>
      <Box>
        {props.value ?
          // <>
          //   {/* <svg width="0" height="0">
          //     <defs>
          //       <linearGradient id='my_gradient' x1='70%' y1='60%' x2='0%' y2='30%'>
          //         <stop offset='0%' stopColor='rgba(232, 2, 18, 1)' />
          //         <stop offset='65%' stopColor='rgba(250, 142, 193, 1)' />
          //         <stop offset='35%' stopColor='rgba(255, 237, 237, 1)' />
          //       </linearGradient>
          //     </defs>
          //   </svg> */}
          //   <CircularProgress thickness={7} size={props.size} variant="determinate" value={props.value} 
          //   // sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} 
          //   color="rgba(232, 2, 18, 1)"
          //   /></>
            <CircularProgress color={props.color} thickness={7} size={props.size} variant="determinate" value={props.value}>
                <Typography variant="h6" style={{color: 'black'}}>{props.value} %</Typography>
            </CircularProgress> :
          <CircularProgress color="inherit" size={props.size} />}
      </Box>
    </>
  );
};

export default CustomSpinner;
