import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CustomCard from "../Card";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useNavigate } from "react-router-dom";

const ClinicProfileCard = (props: any) => {
  const { userData } = props;
  const navigate = useNavigate();

  const pencilClickHandler = () => {
    navigate(`/hospital/${userData?.id}/edit`);
  };

  return (
    <CustomCard sx={{ padding: "16px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign={"right"}>
          {/* <CustomButton
            title={"Proceed"}
            bgColor={"rgba(92, 78, 255, 1)"}
            marginLeft={"71%"}
            marginBottom={"5%"}
          /> */}
          <ModeEditIcon
            sx={{ color: "blue", cursor: "pointer" }}
            onClick={pencilClickHandler}
          />
          <Box>
            <CustomCard
              sx={{
                backgroundColor: "rgba(225, 241, 250, 1)",
                padding: "16px",
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                justifyContent={"flex-end"}
              >
                <Box
                  sx={{
                    borderRadius: "50%",
                    width: "110px",

                    border: "2px solid #7C77FE",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "grid",
                  }}
                >
                  <Box
                    component="img"
                    src={require("../../app/assets/Hospital/user.png")}
                  ></Box>
                </Box>
                <Typography variant="body1" color="text.secondary">
                  <strong>{userData?.name}</strong>
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  <strong>Clinic ID : </strong> {userData?.id}
                </Typography>
              </Box>
            </CustomCard>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "5px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Status:</strong>
            </Typography>
            <Typography variant="body1" color="primary">
            <strong>{userData?.status}</strong>
            </Typography>
          </Box>

          <Box my={2} margin={"10px"}>
            <Typography style={{ fontSize: "17px" }} color="Black">
              <strong>Clinic Details :-</strong>
            </Typography>
          </Box>
          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "7px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Name :</strong>
            </Typography>
            <Typography variant="body1" color="primary">
              <strong>{userData?.name}</strong>
            </Typography>
          </Box>
          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "7px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Registration :</strong>
            </Typography>
            <Typography variant="body1" color="primary">
              <strong>{userData?.registrationNumber}</strong>
            </Typography>
          </Box>

          {/* <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "5px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Contact Person:</strong>
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {"06/09/1999"}
            </Typography>
          </Box> */}

          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "7px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Role :</strong>
            </Typography>
            <Typography variant="body1" color="primary">
              <strong>{userData?.contactPersonRole}</strong>
            </Typography>
          </Box>

          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "7px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Timings :</strong>
            </Typography>
            <Typography variant="body1" color="primary">
              <strong>{"Mon-Sat: 9AM - 5PM"}</strong>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} >
          <Box my={2} margin={"10px"}>
            <Typography style={{ fontSize: "17px" }} color="text.primary">
              <strong>Communication Details :-</strong>
            </Typography>
          </Box>
          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "7px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Person Name :</strong>
            </Typography>
            <Typography variant="body1" color="primary">
              <strong>{userData?.contactPersonName}</strong>
            </Typography>
          </Box>

          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "7px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Primary Mobile :</strong>
            </Typography>
            <Typography variant="body1" color="primary">
              <strong>{userData?.mobileNumber}</strong>
            </Typography>
          </Box>

          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "7px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Whatsapp :</strong>
            </Typography>
            <Typography variant="body1" color="primary">
              <strong>{userData?.mobileNumber}</strong>
            </Typography>
          </Box>

          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "7px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Secondary Mobile :</strong>
            </Typography>
            <Typography variant="body1" color="primary">
              <strong>{userData?.alternateMobileNumber}</strong>
            </Typography>
          </Box>

          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "7px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Email :</strong>
            </Typography>
            <Typography variant="body1" color="primary">
              <strong>{userData?.email}</strong>
            </Typography>
          </Box>

          <Box my={2} margin={"10px"}>
            <Typography style={{ fontSize: "17px" }} color="text.primaryy">
              <strong>Address Details :-</strong>
            </Typography>
          </Box>
          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "7px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>City :</strong>
            </Typography>
            <Typography variant="body1" color="primary">
              <strong>{userData?.address?.city}</strong>
            </Typography>
          </Box>

          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "7px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Country :</strong>
            </Typography>
            <Typography variant="body1" color="primary">
              <strong>{userData?.address?.country}</strong>
            </Typography>
          </Box>
          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "7px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>State :</strong>
            </Typography>
            <Typography variant="body1" color="primary">
              <strong>{userData?.address?.state}</strong>
            </Typography>
          </Box>
          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "7px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Street :</strong>
            </Typography>
            <Typography variant="body1" color="priamry">
              <strong>{userData?.address?.street}</strong>
            </Typography>
          </Box>
          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "7px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Zip Code :</strong>
            </Typography>
            <Typography variant="body1" color="priamry">
              <strong>{userData?.address?.zip}</strong>
            </Typography>
          </Box>
          <br />

          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: "7px",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              <strong>Description :</strong>
            </Typography>
            <Typography variant="body1" color="primary">
              <strong>{userData?.description}</strong>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </CustomCard>
  );
};

export default ClinicProfileCard;
