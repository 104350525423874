import { IRATableCellCustomization } from "../../ira-table/ira-table.interfaces";
import IRATableCellView from "../ira-table-cell-view";
import { IRATableCell } from "../../ira-table";

/* eslint-disable-next-line */


export interface NameCellProps extends IRATableCellCustomization {
  name?: string;
}

export const NameCell = (props: NameCellProps) => {
  const { name, ...rest } = props;
  

  return (
    <IRATableCell {...rest}>
      <IRATableCellView line1={name} styles={rest.style}/>
    </IRATableCell>
  );
};

export default NameCell;
