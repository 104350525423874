import React, { useEffect, useState } from "react";
import { IRATable } from "../../components/ira-table/ira-table";
import DateTimeCell from "../../components/TableComponent/date-time-cell";
import { IRATableColumnProps } from "../../components/ira-table/ira-table.interfaces";
import NameCell from "../../components/TableComponent/name-cell/name-cell";
import ActionCell from "../../components/TableComponent/action-cell";
import { Box } from "@mui/material";
import { apiRequest } from "../../app/services/requests";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const ClientAppointments = () => {
  const [clientAppointments, setClientAppointments] = useState("") as any;
  const navigate = useNavigate();

  useEffect(() => {
    getAppointmentById();
  }, []);
  const getAppointmentById = () => {
    apiRequest({
      url: `appointment/list`, // Use the userId from the URL

      method: "POST",
    })
      .then((res: any) => {
        setClientAppointments(res);
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  const activityTableColumns = () =>
    [
      {
        name: "clientId",
        label: "Client ID",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "createdAt",
        label: "Date and Time",
        component: DateTimeCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "patientName",
        label: "Patient Name",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "bookingType",
        label: "Booking Type",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "treatment",
        label: "Treatment",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "action",
        label: "Action",
        component: (props: any) => {
          return ActionCell({
            button: true,
            btnVariant: "primary",
            btnSize: "smallest",
            disabled: false,
            infoMessage: null,
            label: "View",
            onClick: () => {
              // onViewResult(props?.data);
              alert("navigate to appointment view" + props.data?.id);
            },
            ...props,
          } as any); // <-- This 'as any' is not needed and might be causing the issue
        },
      } as any,
    ] as Array<IRATableColumnProps>;

  const activityTableData = () => {
    return clientAppointments?.data?.map((row: any) => {
      return {
        ...row,
        clientId: {
          name: row?.client?.id,
        },
        createdAt: {
          dateTime: moment(row.createdAt).format("DD-MM-YYYY"),
        },
        patientName: {
          name: `${row?.client?.firstName} ${row?.client?.lastName} `,
        },
        bookingType: {
          name: row?.appointmentType,
        },
        treatment: {
          name: row?.treatment || "RootCanal",
        },
        action: {
          data: {
            id: row?.id,
            status: "reviewed",
            project: {
              id: row?.id,
            },
          },
        },
      };
    });
  };

  return (
    <div>
      <Box p={3}>
        <IRATable
          columns={activityTableColumns()}
          data={activityTableData()}
          onSort={(name: string, order: string) =>
            alert(`sort requested for ${name} with order ${order}`)
          }
        />
      </Box>
    </div>
  );
};

export default ClientAppointments;
