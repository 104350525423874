import * as React from "react";
import Box from "@mui/material/Box";
import { Typography, Grid } from "@mui/material";

interface LabelBox {
  backgroundColor: any;
  text: any;
  color?: any;
  textRight?: any;
}
export default function LabelBox(props: LabelBox) {
  const { backgroundColor, text, color = "#ffff", textRight } = props;
  return (
    <Box
      sx={{
        padding: "8px",
        borderRadius: "8px",
        backgroundColor: backgroundColor,
        width: "160px",
      }}
      mb={1}
    >
      <Grid
        container
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item>
          <Typography sx={{ color: color, fontSize: "12px" }}>
            {text}
          </Typography>
        </Grid>
        {textRight && (
          <Grid item>
            <Typography sx={{ color: color, fontSize: "12px" }}>
              {textRight}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
