import React from "react";
import CustomCard from "../../../components/Card";
import { Box, Grid, Typography } from "@mui/material";
import healthPlanCardSvg from "../../../app/assets/DentaAssurePlans/health-plan-card.png";
import { theme } from "../../../theme/theme";
const PlanTopCard = (props: any) => {
  const { planType } = props;
  return (
    <>
      <CustomCard style={{ height: "145px" }}>
        <Grid container spacing={2} p={0}>
          <Grid item xs={4}>
            <img src={healthPlanCardSvg} alt="" />
          </Grid>
          <Grid item xs={8}>
            <Box>
              <Box mt={"15px"}>
                <Typography
                  color={theme.palette.customColors.main}
                  variant="h6"
                  fontWeight={"600"}
                >
                  {" "}
                  {planType === "discount"
                    ? "Discount Plan"
                    : "Health Plan"}{" "}
                </Typography>
              </Box>
              <Typography variant="body1" mt={"6px"} fontSize={"14px"}>
                {`It is a long established when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box p={2}></Box>
      </CustomCard>
    </>
  );
};

export default PlanTopCard;
