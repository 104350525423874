import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import CustomTabs from "../../components/CustomTabs";
import { useNavigate, useParams } from "react-router";
import Documents from "./Documents";
import Infrastructure from "./infrastructure";
import DentalServices from "./DentalServices";
import DoctorList from "../Doctor";
import Equipments from "./Equipment";
import ClinicProfileCard from "../../components/ClinicProfileCard";
import { apiRequest } from "../../app/services/requests";
import Breadcrumb from "../../components/Breadcrumb";
import IRATypography from "../../components/ira-typography/ira-typography";
import { Link as RouterLink } from "react-router-dom";
import { useAppContext } from "../../app/context/AppContext";

const HospitalProfile = () => {
  const { currentTab } = useParams();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { hospitalId } = useParams();
  const { user } = useAppContext() as any;
  const [clientDetails, setClientDetails] = useState("");
  const navigate = useNavigate();

  const tabs = [
    {
      index: 0,
      id: "documents",
      name: "Documents",
      component: <Documents clientDetails={clientDetails} />,
    },
    {
      index: 1,
      id: "doctors",
      name: "Doctors",
      component: <DoctorList userData={clientDetails} />,
    },
    {
      index: 2,
      id: "infrastructure",
      name: "Infrastructure",
      component: <Infrastructure />,
    },
    {
      index: 3,
      id: "equipments",
      name: "Equipment's",
      component: <Equipments />,
    },
    {
      index: 4,
      id: "dental-services",
      name: "Dental Services",
      component: <DentalServices />,
    },
  ];

  const breadcrumbs = [
    <RouterLink to="/Dashboard" style={{ textDecoration: "none" }} key="1001">
      <IRATypography
        key="2"
        textVariant="h12WithSemiBold"
        sx={{ fontSize: 14, color: "#222222" }}
      >
        Home
      </IRATypography>
    </RouterLink>,
    <IRATypography
      key="3"
      textVariant="h12WithSemiBold"
      sx={{ fontSize: 14, color: "#222222" }}
    >
      Hospital Profile
    </IRATypography>,
  ];

  useEffect(() => {
    if (currentTab) {
      const targetTab: any = tabs.find((tab) => tab.id === currentTab);
      setSelectedTab(targetTab?.index);
    }
  }, [currentTab]);

  useEffect(() => {
    getHospitalById(hospitalId);
  }, [hospitalId]);

  const getHospitalById = (hospitalId: any) => {
    apiRequest({
      url: `clinic/${hospitalId}`, // Use the documentId from the URL
      method: "GET",
    })
      .then((res: any) => {
        if (res) {
          setClientDetails(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  return (
    <Container maxWidth="lg" sx={{ padding: "0 !important" }}>
      <Box sx={{ paddingTop: 3 }}>
        <Breadcrumb
          title={`Hospital Profile`}
          key="case-list-breadcrumb"
          breadcrumbsArr={breadcrumbs}
        />
      </Box>

      <Box mt={2}>
        <Grid container spacing={2} style={{ marginBottom: "20px" }}>
          <Grid item xs={3.5}>
            <Box>
              <Box style={{ height: "100vh" }}>
                <ClinicProfileCard userData={clientDetails} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={8.5}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap={2}
              mb={2.5}
            >
              {/* Card 1 */}
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  flexBasis: "33.33%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src={require("../../app/assets/Hospital/rect4.png")}
                ></Box>
                <Box style={{ display: "grid", marginLeft: "10px" }}>
                  <Typography variant="h6">Appointments</Typography>
                  <Typography variant="h5" style={{ fontWeight: "700" }}>
                    100
                  </Typography>
                </Box>
              </Paper>

              {/* Card 2 */}
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  flexBasis: "33.33%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src={require("../../app/assets/Hospital/rect2.png")}
                ></Box>
                <Box style={{ display: "grid", marginLeft: "10px" }}>
                  <Typography variant="h6">Claims</Typography>
                  <Typography variant="h5" style={{ fontWeight: "700" }}>
                    50
                  </Typography>
                </Box>
              </Paper>

              {/* Card 3 */}
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  flexBasis: "33.33%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src={require("../../app/assets/Hospital/rect3.png")}
                ></Box>
                <Box style={{ display: "grid", marginLeft: "10px" }}>
                  <Typography variant="h6">Cases</Typography>
                  <Typography variant="h5" style={{ fontWeight: "700" }}>
                    100
                  </Typography>
                </Box>
              </Paper>
            </Box>
            <Paper style={{ height: "85vh" }}>
              {/* Content for the second column */}
              <CustomTabs
                tabOptions={tabs}
                currentTab={Number(selectedTab)}
                onTabChange={(value: any) => {
                  navigate(`/hospital/${hospitalId}/profile/${value}`);
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default HospitalProfile;
