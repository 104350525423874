import { Box, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import ClientSearch from "../../components/ClientProfileCard/ClientSearch";
import CustomSpinner from "../../components/Spinner";
import Backs from "../../app/assets/Dashboard/back.png";

const DashboardCounters = () => {
  const [clientData, setClientData] = useState({});
  return (
    <div>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        gap={2}
        mb={4}
      >
        {/* Card 1 */}
        <Paper
          elevation={3}
          sx={{
            p: 2,
            flexBasis: "40%",
            backgroundImage: `url(${Backs})`,
            backgroundRepeat: "round",
            borderRadius: "15px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          }}
        >
          <Typography variant="h6" style={{ fontWeight: "600" }}>
            Search Here
          </Typography>

          {/* <Box
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "space-between",
                gap: "15px",
              }}
            >
              <CustomInput
                width="280px"
                placeholder={"Enter DentAssure ID / Mobile No"}
                placeholderSize={17}
                placeholderColor={"rgba(178, 177, 185, 0.5)"}
              />
              <CustomButton
                title={"Proceed"}
                marginRight={"27px"}
                bgColor={"rgba(92, 78, 255, 1)"}
              />
            </Box> */}

          <Box
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "space-between",
              gap: "15px",
              width: "100%",
            }}
          >
            {/* <CustomInput
                width="280px"
                placeholder={"Enter DentAssure ID / Mobile No"}
                placeholderSize={17}
                placeholderColor={"rgba(178, 177, 185, 0.5)"}
                onChange={(e: any) => setSearchData(e.target.value)}
              /> */}

            <ClientSearch
              setClientData={setClientData}
              fromCommomnDashboard={"fromCommomnDashboard"}
            />
            {/* <CustomButton
                title={"Proceed"}
                marginRight={"27px"}
                bgColor={"rgba(92, 78, 255, 1)"}
                
              /> */}
          </Box>
        </Paper>

        {/* Card 2 */}
        <Paper
          elevation={3}
          sx={{ p: 2, flexBasis: "20%",             borderRadius: "15px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          }}
        >
          <Typography variant="h6" style={{ fontWeight: "600" }}>
            Cases
          </Typography>
          {/* <Typography variant="h6">Graph</Typography> */}
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h2" style={{ fontWeight: "700" }}>
              150
            </Typography>
            <CustomSpinner color={"error"} size={60} value={65} />
          </Box>
        </Paper>

        {/* Card 3 */}
        <Paper
          elevation={3}
          sx={{ p: 2, flexBasis: "20%",             borderRadius: "15px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          }}
        >
          <Typography variant="h6" style={{ fontWeight: "600" }}>
            Claims
          </Typography>
          {/* <Typography variant="h6">Graph</Typography> */}
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h2" style={{ fontWeight: "700" }}>
              150
            </Typography>
            <CustomSpinner color={"primary"} size={60} value={65} />
          </Box>
        </Paper>

        <Box flexDirection={"column"}>
          {/* Card 5 */}
          <Paper
            elevation={3}
            sx={{
              p: 2,
              flexBasis: "10%",
              display: "flex",
              justifyContent: "space-between",
                          borderRadius: "15px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          }}
          >
            <Typography variant="h6" style={{ fontWeight: "600" }}>
              Applied
            </Typography>
            <Typography variant="h6" style={{ fontWeight: "600" }}>
              150
            </Typography>
          </Paper>

          {/* Card 6 */}
          <Paper
            elevation={3}
            sx={{
              p: 2,
              flexBasis: "10%",
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
                          borderRadius: "15px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          }}
          >
            <Typography
              variant="h6"
              style={{ marginRight: 20, fontWeight: "600" }}
            >
              Approved
            </Typography>
            <Typography variant="h6" style={{ fontWeight: "600" }}>
              150
            </Typography>
          </Paper>
        </Box>
      </Box>
    </div>
  );
};

export default DashboardCounters;
