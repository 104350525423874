import React, { useEffect, useState } from "react";
import { IRATable } from "../../../components/ira-table/ira-table";
import { IRATableColumnProps } from "../../../components/ira-table/ira-table.interfaces";
import NameCell from "../../../components/TableComponent/name-cell/name-cell";
import ActionCell from "../../../components/TableComponent/action-cell";
import Breadcrumb from "../../../components/Breadcrumb";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Card, Grid, InputAdornment, Typography } from "@mui/material";
import CustomButton from "../../../components/Button";
import { apiRequest } from "../../../app/services/requests";
import CustomInput from "../../../components/Input";
import { Close, Search } from "@mui/icons-material";
import Pagination from "../../../components/Pagination";

interface ISelectDialog {
  open?: boolean;
  data?: any;
}

const UsersList = (props: any) => {
  const { showOnlyList = true, hideBreadScrum } = props;
  const navigate = useNavigate();
  const [userList, setUserList] = useState({ data: [], totalCount: 0 });
  const [searchTerm, setSearchTerm] = React.useState("");
  const [limit, setLimit] = useState<any>(10);
  const [skip, setSkip] = useState<any>(0);

  useEffect(() => {
    getAllUsers(searchTerm);
  }, [searchTerm, skip, limit]);

  const getAllUsers = (searchTerm?: any) => {
    const payload = {
      ...(searchTerm && {
        searchText: searchTerm,
      }),
      skip,
      limit,
    };
    apiRequest({
      url: `user/list`,
      data: payload,
      method: "POST",
    })
      .then((res) => {
        if (res) {
          setUserList(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };
  const breadcrumbs = [
    <RouterLink to="/Dashboard" style={{ textDecoration: "none" }} key="1001">
      <Typography key="2" fontSize={"13px"}>
        Home
      </Typography>
    </RouterLink>,
    <Typography key="3" fontSize={"13px"}>
      Users List
    </Typography>,
  ];

  const activityTableColumns = () =>
    [
      {
        name: "sno",
        label: "S.No",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "userName",
        label: "User Name",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "userType",
        label: "User Type",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "dob",
        label: "DOB",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "mobile",
        label: "Mobile",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "status",
        label: "Status",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "editAction",
        label: "",
        component: (props: any) => {
          return ActionCell({
            button: true,
            btnVariant: "primary",
            btnSize: "smallest",
            disabled: false,
            infoMessage: null,
            label: "Edit",
            onClick: () => {
              // setOpenSelectClientDialog({ open: true, data: props?.data });
            },
            ...props,
          } as any);
        },
      } as any,
      {
        name: "action",
        label: "Action",
        component: (props: any) => {
          return ActionCell({
            button: true,
            btnVariant: "primary",
            btnSize: "smallest",
            disabled: false,
            infoMessage: null,
            label: "View",
            onClick: () => {
              navigate(`/user/${props?.data?.id}/view`);
            },
            ...props,
          } as any);
        },
      } as any,
    ] as Array<IRATableColumnProps>;

  const activityTableData = () => {
    return userList?.data?.map((row: any) => {
      return {
        ...row,
        sno: {
          name: row?.id,
        },
        userName: {
          name: `${row?.firstName} ${row?.lastName} `,
        },
        userType: {
          name: row.userType,
        },
        dob: {
          name: `${row?.dateOfBirth}`,
        },
        mobile: {
          name: `${row?.mobileNumber}`,
        },
        status: {
          name: `${row?.status}`,
        },
        action: {
          data: {
            id: row?.id,
          },
        },
      };
    });
  };

  const handlePageChange = (page?: any) => {
    const newSkip = page * limit;
    setSkip(newSkip);
  };

  return (
    <>
      {!hideBreadScrum && (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Breadcrumb
            title={`Users List`}
            key="case-list-breadcrumb"
            breadcrumbsArr={breadcrumbs}
          />

          <Box style={{ display: "flex", gap: "8px" }}>
            <CustomButton
              onClick={() => navigate("/user/add")}
              title={"Add User +"}
            />
          </Box>
        </Box>
      )}

      <Box sx={{ pt: "10px" }}>
        <Card>
          <Grid container sx={{ padding: "10px" }}>
            <Grid item md={3} sx={{ background: "#F0F2F6", pb: "10px" }}>
              <CustomInput
                size={"small"}
                placeholder="Search by User"
                placeholderColor="#204289"
                placeholderColorOpacity={1}
                placeholderSize={15}
                value={searchTerm}
                onChange={(event: any) => setSearchTerm(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      {searchTerm.length > 0 && (
                        <InputAdornment
                          sx={{ cursor: "pointer" }}
                          position="end"
                          onClick={() => setSearchTerm("")}
                        >
                          <Close />
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <IRATable
            columns={activityTableColumns()}
            data={activityTableData()}
            onSort={(name: string, order: string) =>
              alert(`sort requested for ${name} with order ${order}`)
            }
          />
          <Box>
            <Pagination
              onPageChange={(data: any) => {
                handlePageChange(data);
              }}
              onRowsPerPageChange={(limit?: number, skip?: number) => {
                setLimit(limit);
                setSkip(0);
              }}
              totalCount={userList.totalCount}
            />
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default React.memo(UsersList);
