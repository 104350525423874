import { useState } from "react";
import CustomAutocomplete from "../Autocomplete";
import { debounce } from "lodash";
import { apiRequest } from "../../app/services/requests";
import { Box } from "@mui/material";
import CustomDialog from "../CustomDialog";
import ClientPlanDetailsCard from "../ClientPlanDetailsCard";

const ClientSearch = (props: any) => {
  const [openOfferSearchDropDown, setOpenOfferSearchDropDown] = useState(false);
  const [clientsList, setClientsList] = useState([]);
  const [openClinetDetailModal, setOpenClinetDetailModal] = useState({
    open: false,
    data: {},
  });

  const fetchClient = async (searchQuery: any) => {
    if (!searchQuery || searchQuery.trim() === "") {
      return; // Don't hit the API if searchQuery is empty
    }
    try {
      const response: any = await apiRequest({
        url: `client/all`,
        data: {
          searchText: searchQuery.trim(),
          limit: 100,
          skip: 0,
        },
        method: "POST",
      });
      const modifiedPostalCodes = response?.results?.map((client: any) => {
        return {
          name: `${client?.firstName} ${client?.lastName}`,
          value: client?.id,
          clientData: client,
        };
      });
      setClientsList(modifiedPostalCodes);
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedFetchOptions = debounce(fetchClient, 300);

  const handleChangeSearch = (event: any, value: any) => {
    const details = value;

    if (details) {
      props.setClientData(details);
    }

    if (props.fromCommomnDashboard === "fromCommomnDashboard") {
      if (details) {
        setOpenClinetDetailModal({ open: true, data: details });
      } else {
        setOpenClinetDetailModal({ open: false, data: {} });
      }
    }
  };

  return (
    <>
      {openClinetDetailModal.open && (
        <CustomDialog
          width={"650px"}
          open={openClinetDetailModal.open}
          handleClose={() =>
            setOpenClinetDetailModal({ open: false, data: {} })
          }
          title={"Select Client"}
          maxWidth={"lg"}
        >
          <Box>
            <ClientPlanDetailsCard
              client={openClinetDetailModal.data}
              fromCommomnDashboard={"fromCommomnDashboard"}
            />
          </Box>
        </CustomDialog>
      )}
      <Box width={"100%"}>
        <CustomAutocomplete
          open={openOfferSearchDropDown}
          onInputChange={(event: any, value: any) => {
            debouncedFetchOptions(value);
          }}
          onChange={handleChangeSearch}
          sx={{ width: "100%" }}
          options={clientsList?.length ? clientsList : []}
          onOpen={() => setOpenOfferSearchDropDown(true)}
          onClose={() => setOpenOfferSearchDropDown(false)}
          label="Enter Client Name/ Client ID/ Mobile No"
        />
      </Box>
    </>
  );
};

export default ClientSearch;
