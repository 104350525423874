// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `svg #Top:hover, svg #Bottom:hover, svg #Right:hover, svg #Left:hover, svg #Center:hover{
    cursor: pointer;
}

.st0 {
    fill: #A8A9AE;
}

.st1 {
    fill: #F9F9FA;
}

.st2 {
    fill: #FFFFFF;
}

.cls-1 {
    stroke-width: 5px;
}

.cls-1, .cls-2 {
    fill: #f9f9fa;
    stroke: #a8a9ae;
    stroke-miterlimit: 10;
}

.cls-2 {
    stroke-width: 3px
}`, "",{"version":3,"sources":["webpack://./src/components/SurfaceTeeth/index.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI;AACJ","sourcesContent":["svg #Top:hover, svg #Bottom:hover, svg #Right:hover, svg #Left:hover, svg #Center:hover{\n    cursor: pointer;\n}\n\n.st0 {\n    fill: #A8A9AE;\n}\n\n.st1 {\n    fill: #F9F9FA;\n}\n\n.st2 {\n    fill: #FFFFFF;\n}\n\n.cls-1 {\n    stroke-width: 5px;\n}\n\n.cls-1, .cls-2 {\n    fill: #f9f9fa;\n    stroke: #a8a9ae;\n    stroke-miterlimit: 10;\n}\n\n.cls-2 {\n    stroke-width: 3px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
