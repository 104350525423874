// import React, { useState, useEffect } from "react";
// import { useForm, Controller } from "react-hook-form";
// import {
//   Box,
//   Container,
//   Grid,
//   Typography,
//   FormControlLabel,
//   Radio,
//   RadioGroup,
//   Paper,
// } from "@mui/material";
// import { object, string } from "yup";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { useNavigate } from "react-router-dom";
// import { useSnackbar } from "../../app/context/useSnackbar";
// import { useAppContext } from "../../app/context/AppContext";
// import CustomInput from "../../components/Input";
// import CustomButton from "../../components/Button";
// import {
//   COUNTRY_LIST,
//   MARTIAL_STATUS1,
//   PHONE_REG_EXP,
//   GENDER_DATA,
// } from "../../app/utilities/constants";

// import StatesAndCities from "../../app/utilities/StatesAndCities.json";
// import CustomDatePicker from "../../components/DatePicker";

// import CustomCheckbox from "../../components/CustomCheckBox";
// import CustomAutocomplete from "../../components/Autocomplete";
// import CustomCard from "../../components/Card";
// import { apiRequest } from "../../app/services/requests";
// import styless from "./client.module.css";
// import { MuiOtpInput } from "mui-one-time-password-input";

// const ManageClient = (props: any) => {
//   const { currentSubCategory = {} as any, isEdit } = props;

//   const navigate = useNavigate();

//   const { handleLogoutSuccess } = useAppContext() as any;
//   const { openSnackbar } = useSnackbar() as any;

//   const [cities, setCities] = useState<any>([]);
//   const [statesAndCities] = useState<any>(StatesAndCities);
//   const [verifiedMobileNumber, setVerifiedMobileNumber] = useState<any>({});
//   const [otp, setOtp] = useState<Boolean>(false);
//   const [success, setSuccess] = useState<Boolean>(false);
//   const [otpValue, setOtpValue] = useState<any>();

//   useEffect(() => {
//     getAllCategories();
//   }, []);

//   const userDefaultValues = () => {
//     return {
//       zipCode: "232323",
//       city: "Anantapur",
//       state: "Andhra Pradesh",
//       country: "India",
//       addressLine2: "q",
//       addressLine1: "q",
//       pan: "",
//       aadhar: "",
//       email: "mnarendrasoft20@gmail.com",
//       maritalStatus: "married",
//       dateOfBirth: new Date(),
//       mobileNumber: "9603263099",
//       phonecode: "+91",
//       gender: "male",
//       lastName: "mnb",
//       firstName: "narendra",
//       appointment: "No : I9089A519GH818",
//       isPrimaryWhatsappNumber: true,
//       alternateMobileNumber: "",
//       isSecondaryWhatsappNumber: true,
//     } as any;
//   };

//   const schema = object({
//     firstName: string().required("First name is required"),
//     lastName: string().required("Last name is required"),
//     gender: string().ensure().required("Gender is required"),
//     mobileNumber: string()
//       .required("Mobile number is required")
//       .matches(PHONE_REG_EXP, "Phone number is not valid"),
//     dateOfBirth: string().ensure().required("Date of birth is required"),
//     maritalStatus: string().required("Email is required"),
//     // aadhaar: string().required("Aadhar no is required"),
//     email: string()
//       .required("Email is required")
//       .nullable()
//       .email("Email is not valid"),
//     aadhar: string().required("Aadhar  is required"),
//     pan: string().required("Pan  is required"),
//     addressLine1: string().required("Address Line 1 is required"),
//     addressLine2: string().required("Address Line 2 is required"),
//     country: string().required("Country is required"),
//     state: string().required("State is required"),
//     city: string().required("City is required"),
//     zipCode: string()
//       .required("Zip Code is required")
//       .max(6, "Zip Code should be of 6 characters"),
//   });

//   const { control, handleSubmit, getValues, setValue } = useForm({
//     resolver: yupResolver(schema),
//     defaultValues: userDefaultValues(),
//   });

//   const getAllCategories = () => {};

//   const onSubmit = (formData: any) => {
//     console.log("formData", formData);

//     const res = {
//       success: true,
//       message: "no error",
//       data: {
//         otp_token: 123,
//         mobile: 9603263099,
//       },
//     };

//     if (res?.success === true) {
//       openSnackbar(
//         "Success! An OTP has been sent to your registered mobile number",
//         "success"
//       );
//     } else {
//       openSnackbar(res?.message, "error");
//     }
//     setVerifiedMobileNumber({ ...res, mobile: res?.data?.mobile });

//     if (isEdit && currentSubCategory) {
//       const modifiedFormData = {
//         name: formData.subCategory,
//       };
//       apiRequest({
//         url: `sub-category/${currentSubCategory?._id}`,
//         data: modifiedFormData,
//         method: "PUT",
//       })
//         .then((res) => {
//           if (res) {
//             openSnackbar(
//               "Sub-category has been updated successfully",
//               "success"
//             );
//           }
//           props.onClose();
//           props.getAllSubCategories();
//         })
//         .catch((err) => {
//           openSnackbar(err?.response?.data?.message, "error");
//           if (err?.statusCode === 403 || err?.statusCode === 401) {
//             if (navigate) {
//               navigate("/login"); // Navigate to "/login" route
//               handleLogoutSuccess();
//             }
//           }
//         });
//     } else {
//       const modifiedFormData = {
//         category: formData.categoryName,
//         name: formData.subCategory,
//       };
//       apiRequest({
//         url: `sub-category`,
//         data: modifiedFormData,
//         method: "POST",
//       })
//         .then((res) => {
//           if (res) {
//             openSnackbar(
//               "Sub-Category has been created successfully",
//               "success"
//             );
//           }
//           props.onClose();
//           props.getAllSubCategories();
//         })
//         .catch((err) => {
//           openSnackbar(err?.response?.data?.message, "error");
//           if (err?.statusCode === 403 || err?.statusCode === 401) {
//             if (navigate) {
//               navigate("/login"); // Navigate to "/login" route
//               handleLogoutSuccess();
//             }
//           }
//         });
//     }
//   };

//   const Styles = {
//     breadcrumbSubtext: {
//       fontFamily: "Poppins",
//       fontSize: 13,
//       color: "#021025",
//       fontWeight: "550",
//     },
//     Container: {
//       padding: 2,
//     },
//     TotalAppointments: {
//       fontFamily: "poppins",
//       fontSize: 20,
//       color: "#021025",
//       fontWeight: 600,
//     },
//   };

//   const handleChange = (newValue: any) => {
//     setOtpValue(newValue);
//   };

//   return (
//     <>
//       <Container maxWidth="lg" className={otp || success ? styless.long : ""}>
//         {" "}
//         <Typography variant="h4" gutterBottom style={{ marginBottom: "0em" }}>
//           {otp ? "Client" : "Client Enrollment"}
//         </Typography>
//         <Typography variant="subtitle1" style={{ marginBottom: "0.7em" }}>
//           {otp ? "Home > Edit Client" : "Home > Client Enrollment"}
//         </Typography>
//         {/* <CustomCard>
//           <Box sx={Styles.Container}>
//             <Box
//               display={"flex"}
//               justifyContent={"space-between"}
//               alignItems={"center"}
//             >
//               <Box>
//                 <Typography sx={Styles.breadcrumbSubtext}>
//                   {"Info About Client Form"}
//                 </Typography>
//               </Box>
//             </Box>
//           </Box>
//         </CustomCard> */}
//         {/* Set the desired maxWidth */}
//         {/* {verifiedMobileNumber?.otp_token ?
//         {otp ? (
//           <OtpScreen
//             loginWithOtp={"loginWithOtp"}
//             verifiedMobileNumber={""}
//             details={""}
//             requestOTP={"loginWithOtp"}
//           />

//          ) :
//          ( */}
//         <Box mt={4}>
//           <Box
//             style={{
//               backgroundImage: `url(${require("../../app/assets/AddNewClient/back.png")})`,
//               height: "13vh",
//               backgroundRepeat: "round",
//               marginBottom: "2vh",
//               display: "flex",
//               alignItems: "center",
//             }}
//           >
//             <Box
//               style={{ marginLeft: "27vw", height: "70px" }}
//               component="img"
//               src={require("../../app/assets/logo.png")}
//             ></Box>
//             <Box style={{ marginLeft: "10px", width: "52%" }}>
//               <Typography
//                 style={{ color: "rgba(33, 159, 255, 1)", fontSize: "20px" }}
//               >
//                 {"Dentassure Client Enrollment Form"}
//               </Typography>
//               <Typography variant="body2" style={{ width: "60%" }}>
//                 {
//                   "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
//                 }
//               </Typography>
//             </Box>
//             <CustomButton
//               title={"Help"}
//               marginRight={"5px"}
//               bgColor={"rgba(92, 78, 255, 1)"}
//               height={"25px"}
//             />
//           </Box>
//           {otp ? (
//             <Paper
//               style={{
//                 justifyContent: "center",
//                 alignItems: "center",
//                 display: "grid",
//                 marginTop: "10px",
//                 marginBottom: "10px",
//                 borderRadius: "10px",
//                 paddingBottom: "20px",
//               }}
//             >
//               <Typography
//                 variant="h6"
//                 style={{
//                   textAlign: "center",
//                   marginTop: "10px",
//                   marginBottom: "10px",
//                   marginRight: "29%",
//                 }}
//               >
//                 Verification Code
//               </Typography>
//               <Box
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                   marginTop: "10px",
//                   marginBottom: "10px",
//                   alignItems: "center",
//                 }}
//               >
//                 <Box
//                   style={{
//                     background: "rgba(244, 244, 244, 1)",
//                     borderRadius: "10px",
//                     borderColor: "black",
//                     borderStyle: "solid",
//                     borderWidth: "1px",
//                     padding: "5px",
//                     paddingLeft: "15px",
//                     paddingRight: "15px",
//                     marginRight: "10px",
//                   }}
//                 >
//                   <Typography
//                     variant="subtitle1"
//                     style={{ textAlign: "center" }}
//                   >
//                     Primary Mobile No. : +91 80123 45678
//                   </Typography>
//                 </Box>
//                 <CustomButton
//                   title={"Change"}
//                   marginRight={"5px"}
//                   bgColor={"rgba(92, 78, 255, 1)"}
//                   height={"25px"}
//                 />
//               </Box>

//               <MuiOtpInput
//                 style={{
//                   width: "34%",
//                   alignSelf: "center",
//                   marginLeft: "33%",
//                   marginTop: "10px",
//                   marginBottom: "10px",
//                 }}
//                 value={otpValue}
//                 onChange={(text) => handleChange(text)}
//               />

//               <Box
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                   marginTop: "10px",
//                   marginBottom: "10px",
//                 }}
//               >
//                 <Typography variant="body1">Didn’t receive code ? </Typography>
//                 <Typography variant="body1"> Request Again</Typography>
//               </Box>
//               <CustomButton
//                 onClick={() => {
//                   setOtp(false);
//                   setSuccess(true);
//                 }}
//                 title={"Verify"}
//                 marginRight={"5px"}
//                 bgColor={"rgba(92, 78, 255, 1)"}
//                 height={"45px"}
//                 width={"12%"}
//                 marginLeft={"44%"}
//               />
//             </Paper>
//           ) :
//           success ? (
//             <Paper
//               style={{
//                 justifyContent: "center",
//                 alignItems: "center",
//                 display: "grid",
//                 marginTop: "10px",
//                 marginBottom: "10px",
//                 borderRadius: "10px",
//                 paddingBottom: "20px",
//               }}
//             >
//               <Box
//                 style={{ height: "70px", marginTop: "30px" }}
//                 className={styless.justing}
//                 component="img"
//                 src={require("../../app/assets/AddNewClient/success.png")}
//               ></Box>

//               <Typography
//                 variant="subtitle1"
//                 style={{
//                   textAlign: "center",
//                   marginTop: "10px",
//                   color: "rgba(92, 78, 254, 1)",
//                 }}
//               >
//                 ID : DADI121810282 Client enrolled Successfully
//               </Typography>

//               <Typography
//                 variant="subtitle1"
//                 style={{ textAlign: "center", marginBottom: "5px" }}
//               >
//                 Go to Profile Screen
//               </Typography>

//               <Box
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                   marginTop: "10px",
//                   marginBottom: "10px",
//                 }}
//               >
//                 <CustomButton
//                   onClick={() => {
//                     navigate("/dashboard");
//                   }}
//                   title={"Home"}
//                   bgColor={"rgba(92, 78, 255, 1)"}
//                   height={"40px"}
//                   width={"20vw"}
//                   marginLeft={"30%"}
//                   marginRight={"1vw"}
//                 />
//                 <CustomButton
//                   onClick={() => {
//                     setOtp(false);
//                     setSuccess(false);
//                   }}
//                   title={"Profile"}
//                   bgColor={"rgba(92, 78, 255, 1)"}
//                   height={"40px"}
//                   width={"20vw"}
//                   marginRight={"30%"}
//                 />
//               </Box>
//             </Paper>
//           )

//           : (
//             <CustomCard style={{ borderRadius: "10px" }}>
//               <Box px={4} py={4}>
//                 <form onSubmit={handleSubmit(onSubmit)}>
//                   {/* Client Details Section */}
//                   <Typography variant="body1">Application No</Typography>

//                   <Grid container spacing={3} mt={1}>
//                     <Grid item xs={4} sm={4} md={4} lg={4}>
//                       <Controller
//                         name="appointment"
//                         disabled
//                         control={control}
//                         render={({ field, fieldState: { error } }) => (
//                           <CustomInput
//                             {...field}
//                             error={!!error}
//                             helperText={error ? error.message : ""}
//                             // label={"Enter First Name"}
//                             InputProps={""}
//                             style={{ background: "rgba(244, 244, 244, 1)" }}
//                             size={"small"}
//                           />
//                         )}
//                       />
//                     </Grid>
//                   </Grid>

//                   <Typography variant="body1" style={{ marginTop: "10px" }}>
//                     Client Details
//                   </Typography>

//                   <Grid container spacing={3} mt={1}>
//                     {/* Add client details fields here */}
//                     <Grid item xs={12} md={4}>
//                       <Controller
//                         name="firstName"
//                         control={control}
//                         render={({ field, fieldState: { error } }) => (
//                           <CustomInput
//                             {...field}
//                             error={!!error}
//                             helperText={error ? error.message : ""}
//                             label={"Enter First Name"}
//                             InputProps={""}
//                             size={"small"}
//                           />
//                         )}
//                       />
//                     </Grid>
//                     <Grid item xs={12} md={4}>
//                       <Controller
//                         name="lastName"
//                         control={control}
//                         render={({ field, fieldState: { error } }) => (
//                           <CustomInput
//                             {...field}
//                             error={!!error}
//                             helperText={error ? error.message : ""}
//                             label={"Enter Last Name"}
//                             InputProps={""}
//                             size={"small"}
//                           />
//                         )}
//                       />
//                     </Grid>
//                     <Grid item xs={12} md={4}>
//                       <Controller
//                         name={"dateOfBirth"}
//                         rules={{ required: true }}
//                         control={control}
//                         render={({ field, fieldState: { error } }) => (
//                           <CustomDatePicker
//                             {...field}
//                             label={"End Date Of Birth"}
//                             error={!!error}
//                             helperText={error ? error.message : ""}
//                             disablePast={true}
//                           />
//                         )}
//                       />
//                     </Grid>
//                   </Grid>
//                   <Grid container item spacing={3}>
//                     <Grid item xs={6} md={4} mt={4} style={{ display: "flex" }}>
//                       {/* <Controller
//                         name="gender"
//                         control={control}
//                         render={({ field, fieldState: { error } }) => (
//                           <CustomSelectPicker
//                             {...field}
//                             error={!!error}
//                             helperText={error ? error.message : ""}
//                             label={"Select Gender"}
//                             size={"small"}
//                             fullWidth={true}
//                             width={"100%"}
//                             selectData={GENDER}
//                           />
//                         )}
//                       /> */}
//                       <Typography
//                         variant="regular"
//                         style={{ paddingTop: "11px", marginRight: "5px" }}
//                       >
//                         Sex :
//                       </Typography>
//                       <RadioGroup row>
//                         {GENDER_DATA.map((option: any, idx: any) => (
//                           <FormControlLabel
//                             key={idx}
//                             value={option.id}
//                             control={<Radio />}
//                             label={option.name}
//                           />
//                         ))}
//                       </RadioGroup>
//                     </Grid>
//                     <Grid
//                       item
//                       xs={6}
//                       md={6}
//                       mt={6}
//                       style={{ display: "flex", paddingTop: "10px" }}
//                     >
//                       {/* <Controller
//                         name="maritalStatus"
//                         control={control}
//                         render={({ field, fieldState: { error } }) => (
//                           <CustomSelectPicker
//                             {...field}
//                             error={!!error}
//                             helperText={error ? error.message : ""}
//                             size={"small"}
//                             fullWidth={true}
//                             width={"100%"}
//                             selectData={MARTIAL_STATUS}
//                             label={"Select Martial Status"}
//                           />
//                         )}
//                       /> */}
//                       <Typography
//                         variant="regular"
//                         style={{ paddingTop: "8px", marginRight: "5px" }}
//                       >
//                         Martial Status :
//                       </Typography>
//                       <RadioGroup row>
//                         {MARTIAL_STATUS1.map((option: any, idx: any) => (
//                           <FormControlLabel
//                             key={idx}
//                             value={option.id}
//                             control={<Radio />}
//                             label={option.name}
//                           />
//                         ))}
//                       </RadioGroup>
//                     </Grid>
//                   </Grid>

//                   {/* Communication Details Section */}
//                   <Box mt={3}>
//                     <Typography variant="body1">
//                       Communication Details
//                     </Typography>
//                     {/* Add communication details fields here */}
//                     <Grid container spacing={3}>
//                       <Grid item xs={12} md={4} mt={4}>
//                         <Box>
//                           <Box style={{ display: "flex" }}>
//                             <Controller
//                               name="phonecode"
//                               control={control}
//                               render={({ field, fieldState: { error } }) => (
//                                 <CustomInput
//                                   // label={"Primary Mobile No"}
//                                   style={{ width: "47px", marginRight: "10px" }}
//                                   type="text"
//                                   {...field}
//                                   error={!!error}
//                                   helperText={error ? error.message : ""}
//                                 />
//                               )}
//                             />
//                             <Controller
//                               name="mobileNumber"
//                               control={control}
//                               render={({ field, fieldState: { error } }) => (
//                                 <CustomInput
//                                   label={"Primary Mobile No"}
//                                   type="text"
//                                   {...field}
//                                   error={!!error}
//                                   helperText={error ? error.message : ""}
//                                 />
//                               )}
//                             />
//                           </Box>
//                           <Controller
//                             name="isPrimaryWhatsappNumber"
//                             control={control}
//                             render={({ field }) => (
//                               <CustomCheckbox
//                                 {...field}
//                                 label="Whatsapp Number"
//                                 // onChange={(e) => {
//                                 //   field.onChange(e);
//                                 //   console.log("isPrimaryWhatsappNumber", field);
//                                 // }}
//                               />
//                             )}
//                           />
//                         </Box>
//                       </Grid>
//                       <Grid item xs={12} md={4} mt={4}>
//                         <Box>
//                           <Box style={{ display: "flex" }}>
//                             <Controller
//                               name="phonecode"
//                               control={control}
//                               render={({ field, fieldState: { error } }) => (
//                                 <CustomInput
//                                   // label={"Primary Mobile No"}
//                                   style={{ width: "47px", marginRight: "10px" }}
//                                   type="text"
//                                   {...field}
//                                   error={!!error}
//                                   helperText={error ? error.message : ""}
//                                 />
//                               )}
//                             />
//                             <Controller
//                               name="alternateMobileNumber"
//                               control={control}
//                               render={({ field, fieldState: { error } }) => (
//                                 <CustomInput
//                                   label={"Secondary Mobile No"}
//                                   type="text"
//                                   {...field}
//                                   error={!!error}
//                                   helperText={error ? error.message : ""}
//                                 />
//                               )}
//                             />
//                           </Box>
//                           <Controller
//                             name="isSecondaryWhatsappNumber"
//                             control={control}
//                             render={({ field }) => (
//                               <CustomCheckbox
//                                 {...field}
//                                 label="Whatsapp Number"
//                                 // onChange={(e) => {
//                                 //   field.onChange(e);
//                                 //   console.log("isSecondaryWhatsappNumber", field);
//                                 // }}
//                               />
//                             )}
//                           />
//                         </Box>
//                       </Grid>
//                       <Grid item xs={12} md={4} mt={4}>
//                         <Controller
//                           name="email"
//                           control={control}
//                           render={({ field, fieldState: { error } }) => (
//                             <CustomInput
//                               {...field}
//                               error={!!error}
//                               helperText={error ? error.message : ""}
//                               label={"Email"}
//                               size={"small"}
//                             />
//                           )}
//                         />
//                       </Grid>
//                     </Grid>
//                   </Box>

//                   {/* Personal Identity Section */}
//                   <Box mt={4}>
//                     <Typography variant="body1">Personal Identity</Typography>
//                     {/* Add personal identity fields here */}
//                     <Grid container spacing={3} mt={1}>
//                       <Grid item xs={12} md={4}>
//                         <Controller
//                           name="aadhar"
//                           control={control}
//                           render={({ field, fieldState: { error } }) => (
//                             <Box
//                               style={{
//                                 backgroundColor: "white",
//                                 // width: '67%',
//                                 padding: "8px",
//                                 borderRadius: "10px",
//                                 // display: 'grid',
//                                 justifyContent: "space-between",
//                                 borderColor: "rgba(49, 48, 53, 1)",
//                                 borderStyle: "solid",
//                                 borderWidth: "1px",
//                                 display: "flex",
//                               }}
//                             >
//                               {/* <CustomUpload
//                                 onChange={(file) => field.onChange(file)}
//                                 onBlur={field.onBlur}
//                                 error={!!error}
//                                 helperText={error ? error.message : ""}
//                               /> */}
//                               <Typography variant="body1">
//                                 Upload Aadhar Card
//                               </Typography>
//                               <CustomButton
//                                 title={"Upload"}
//                                 marginRight={"5px"}
//                                 bgColor={"rgba(92, 78, 255, 1)"}
//                                 height={"25px"}
//                               />
//                             </Box>
//                           )}
//                         />
//                       </Grid>
//                       <Grid item xs={12} md={4}>
//                         <Controller
//                           name="pan"
//                           control={control}
//                           render={({ field, fieldState: { error } }) => (
//                             <>
//                               {/* <CustomUpload
//                                 onChange={(file) => field.onChange(file)}
//                                 onBlur={field.onBlur}
//                                 error={!!error}
// helperText={error ? error.message : ""}
//                               /> */}
//                               <Box
//                                 style={{
//                                   backgroundColor: "white",
//                                   // width: '67%',
//                                   padding: "8px",
//                                   borderRadius: "10px",
//                                   // display: 'grid',
//                                   justifyContent: "space-between",
//                                   borderColor: "rgba(49, 48, 53, 1)",
//                                   borderStyle: "solid",
//                                   borderWidth: "1px",
//                                   display: "flex",
//                                 }}
//                               >
//                                 {/* <CustomUpload
//                                 onChange={(file) => field.onChange(file)}
//                                 onBlur={field.onBlur}
//                                 error={!!error}
//                                 helperText={error ? error.message : ""}
//                               /> */}
//                                 <Typography variant="body1">
//                                   Upload PAN Card
//                                 </Typography>
//                                 <CustomButton
//                                   title={"Upload"}
//                                   marginRight={"5px"}
//                                   bgColor={"rgba(92, 78, 255, 1)"}
//                                   height={"25px"}
//                                 />
//                               </Box>
//                             </>
//                           )}
//                         />
//                       </Grid>
//                     </Grid>
//                   </Box>

//                   {/* Address for Communication Section */}
//                   <Box mt={3}>
//                     <Typography variant="body1">
//                       Address for Communication
//                     </Typography>
//                     {/* Add address for communication fields here */}

//                     <Grid container spacing={3} mt={1}>
//                       <Grid item xs={6} md={4}>
//                         <Controller
//                           name="addressLine1"
//                           control={control}
//                           render={({ field, fieldState: { error } }) => (
//                             <CustomInput
//                               {...field}
//                               error={!!error}
//                               helperText={error ? error.message : ""}
//                               label={"Enter Address Line 1"}
//                               InputProps={""}
//                               size={"small"}
//                             />
//                           )}
//                         />
//                       </Grid>
//                       <Grid item xs={6} md={4}>
//                         <Controller
//                           name="addressLine2"
//                           control={control}
//                           render={({ field, fieldState: { error } }) => (
//                             <CustomInput
//                               {...field}
//                               error={!!error}
//                               helperText={error ? error.message : ""}
//                               label={"Enter Address Line 2"}
//                               InputProps={""}
//                               size={"small"}
//                             />
//                           )}
//                         />
//                       </Grid>
//                       <Grid item xs={4} md={4}>
//                         <Box>
//                           <Controller
//                             name="country"
//                             defaultValue={"India"}
//                             control={control}
//                             rules={{ required: true }}
//                             render={({
//                               field: { ref, onChange, ...field },
//                               fieldState: { error },
//                             }) => (
//                               <CustomAutocomplete
//                                 field={field}
//                                 disabled
//                                 error={!!error}
//                                 helperText={error ? error.message : ""}
//                                 ref={ref}
//                                 singleSelection
//                                 onChange={(
//                                   event: React.SyntheticEvent,
//                                   value: any
//                                 ) => onChange(value)}
//                                 options={COUNTRY_LIST}
//                                 value={getValues("country")}
//                                 label="Country"
//                                 defaultValue={"India"}
//                               />
//                             )}
//                           />
//                         </Box>
//                       </Grid>
//                       <Grid item xs={4} md={4}>
//                         <Controller
//                           name="state"
//                           control={control}
//                           defaultValue={getValues("state")}
//                           rules={{ required: true }}
//                           render={({
//                             field: { ref, onChange, ...field },
//                             fieldState: { error },
//                           }) => (
//                             <CustomAutocomplete
//                               field={field}
//                               error={!!error}
//                               helperText={error ? error.message : ""}
//                               ref={ref}
//                               value={getValues("state")}
//                               singleSelection
//                               onChange={(
//                                 event: React.SyntheticEvent,
//                                 value: any
//                               ) => {
//                                 onChange(value);
//                                 setValue("city", "");
//                                 setCities(statesAndCities[value] || []);
//                               }}
//                               options={Object.keys(statesAndCities)}
//                               label="State"
//                             />
//                           )}
//                         />
//                       </Grid>
//                       <Grid item xs={4} md={4}>
//                         <Controller
//                           name="city"
//                           control={control}
//                           defaultValue={getValues("city")}
//                           rules={{ required: true }}
//                           render={({
//                             field: { ref, onChange, ...field },
//                             fieldState: { error },
//                           }) => (
//                             <CustomAutocomplete
//                               field={field}
//                               error={!!error}
//                               helperText={error ? error.message : ""}
//                               ref={ref}
//                               value={getValues("city")}
//                               singleSelection
//                               onChange={(
//                                 event: React.SyntheticEvent,
//                                 value: any
//                               ) => {
//                                 onChange(value);
//                                 setValue("city", value);
//                               }}
//                               options={cities}
//                               label="City"
//                             />
//                           )}
//                         />
//                       </Grid>
//                       <Grid item xs={4} md={4}>
//                         <Controller
//                           name="zipCode"
//                           control={control}
//                           rules={{ required: true }}
//                           render={({ field, fieldState: { error } }) => (
//                             <CustomInput
//                               {...field}
//                               error={!!error}
//                               helperText={error ? error.message : ""}
//                               label={"Zip code"}
//                               size={"small"}
//                               type={"number"}
//                             />
//                           )}
//                         />
//                       </Grid>
//                     </Grid>
//                   </Box>

//                   {/* Additional Sections can be added as needed */}
//                   <Box display="flex" justifyContent="flex-end" mt={2}>
//                     {/* <CustomButton
//                       border="1px solid #000000"
//                       color="#000000"
//                       title="Cancel"
//                       bgColor="#ffffff"
//                       handleClick={() => props.onClose()}
//                       borderRadius="5px"
//                     /> */}
//                     <CustomButton
//                       title={"Cancel"}
//                       marginRight={"5px"}
//                       bgColor={"rgba(255, 225, 229, 1)"}
//                       width={"100px"}
//                       color={"rgba(255, 97, 116, 1)"}
//                       borderColor={"rgba(255, 97, 116, 1)"}
//                       borderWidth={"1px"}
//                       borderStyle={"solid"}
//                     />
//                     <Box marginLeft={1}>
//                       {" "}
//                       {/* Add a margin to create space between buttons */}
//                       {/* <CustomButton
//                         title={
//                           currentSubCategory?.open === "add" ? "Create" : "Save"
//                         }
//                         type="submit"
//                         borderRadius="5px"
//                       /> */}
//                       <CustomButton
//                         onClick={() => setOtp(true)}
//                         title={
//                           currentSubCategory?.open === "add" ? "Create" : "Save"
//                         }
//                         marginRight={"5px"}
//                         bgColor={"rgba(92, 78, 255, 1)"}
//                         width={"100px"}
//                       />
//                     </Box>
//                   </Box>
//                 </form>
//               </Box>
//             </CustomCard>
//           )}
//         </Box>
//         {/* ) */}
//         {/* } */}
//       </Container>
//     </>
//   );
// };

// export default ManageClient;
import React from "react";
import ClientOtpSuccess from "./client-otp-success";
import ClientOtpverifyScreen from "./client-otp-verify-screen";
import AddEditClient from "./add-edit-client";

const ManageClient = () => {
  return (
    <>
      {/* <ClientOtpSuccess /> */}
      <AddEditClient />
      {/* <ClientOtpverifyScreen /> */}
    </>
  );
};

export default ManageClient;
