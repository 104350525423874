import React, { useState, useEffect } from "react";
import { Container, Typography, Paper, Box, Grid } from "@mui/material";
import "./dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import CustomDialog from "../../components/CustomDialog";
import BookAppointmentForm from "../Appointment/book-appointment-form";
import SelectClient from "../../components/SelectClient";
import CustomCard from "../../components/Card";
import CaseList from "../CasesAndClaims/case-list";
import QueryList from "../Query/query-list";
import { theme } from "../../theme/theme";
import { useAppContext } from "../../app/context/AppContext";
import DashboardCounters from "./dashboard-counters";
import PanelDoctorDashboard from "./panel-doctor-dashboard";
import SrPanelDoctorDashboard from "./sr-panel-doctor-dashboard";
import RequestList from "../Requests/request-list";
import CustomSpinner from "../../components/Spinner";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const ClientDashboard: React.FC = () => {
  const navigate = useNavigate();
  const currentUserDetails = JSON.parse(localStorage.getItem("user") || "{}");
  const { id: clientId } = currentUserDetails?.data?.client;
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [isFlipped, setFlipped] = useState(false);
  const [isFlipped1, setFlipped1] = useState(false);
  const [isFlipped2, setFlipped2] = useState(false);
  const [isFlipped3, setFlipped3] = useState(false);
  const [isFlipped4, setFlipped4] = useState(false);
  const [isFlipped5, setFlipped5] = useState(false);
  const [openSelectClientDialog, setOpenSelectClientDialog] = useState<any>({
    open: false,
    data: {},
  });

  const { user } = useAppContext() as any;

  const [openBookAppointmentDialog, setOpenBookAppointmentDialog] =
    useState<any>({
      open: false,
      data: {},
    });

  const handleLinkClick = (type?: string) => {
    if (type === "bookAppointment") {
      setOpenBookAppointmentDialog({ open: true, data: { type } });
    } else {
      setOpenSelectClientDialog({ open: true, data: { type } });
    }
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderCards = (role?: string) => {
    return (
      <>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          gap={2}
          mb={4}
        >
          {/* Card 1 */}
          <Paper
            elevation={3}
            sx={{
              p: 2,
              flexBasis: "26%",
              borderRadius: "15px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <Typography
              variant="h6"
              style={{ fontWeight: "600", fontSize: "16px" }}
            >
              My Usage
            </Typography>
            {/* <Typography variant="h6">Graph</Typography> */}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography variant="h3" style={{ fontWeight: "700" }}>
                  150
                </Typography>
                <Typography
                  variant="h5"
                  style={{ fontWeight: "700", color: "blue" }}
                >
                  New
                </Typography>
              </Box>
              <CustomSpinner color={"error"} size={60} value={65} />
            </Box>
          </Paper>

          {/* Card 2 */}
          <Paper
            elevation={3}
            sx={{
              p: 2,
              flexBasis: "26%",
              borderRadius: "15px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <Typography
              variant="h6"
              style={{ fontWeight: "600", fontSize: "16px" }}
            >
              Total Claims
            </Typography>
            {/* <Typography variant="h6">Graph</Typography> */}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h3" style={{ fontWeight: "700" }}>
                12
              </Typography>
              <CustomSpinner color={"error"} size={60} value={65} />
            </Box>
          </Paper>

          {/* Card 3 */}
          <Paper
            elevation={3}
            sx={{
              p: 2,
              flexBasis: "26%",
              borderRadius: "15px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <Typography
              variant="h6"
              style={{ fontWeight: "600", fontSize: "16px" }}
            >
              Total Cases
            </Typography>
            {/* <Typography variant="h6">Graph</Typography> */}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h3" style={{ fontWeight: "700" }}>
                15
              </Typography>
              <CustomSpinner color={"error"} size={60} value={65} />
            </Box>
          </Paper>

          {/* Card 4 */}
          <Paper
            elevation={3}
            sx={{
              p: 2,
              flexBasis: "26%",
              borderRadius: "15px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <Typography
              variant="h6"
              style={{ fontWeight: "600", fontSize: "16px" }}
            >
              Total Appointments
            </Typography>
            {/* <Typography variant="h6">Graph</Typography> */}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h3" style={{ fontWeight: "700" }}>
                15
              </Typography>
              <CustomSpinner color={"primary"} size={60} value={65} />
            </Box>
          </Paper>
        </Box>
        <div className="flexbox">
          <div className="w33p">
            {/* Clinic */}
            {role !== "local-admin" && (
              <Paper
                onMouseEnter={() => {
                  setFlipped(true);
                  setFlipped1(false);
                  setFlipped2(false);
                  setFlipped3(false);
                  setFlipped4(false);
                  setFlipped5(false);
                }}
                onMouseLeave={() => setFlipped(false)}
                className={`flip-card ${isFlipped ? "flipped" : ""}`}
                elevation={3}
                sx={{
                  p: 2,
                  flexBasis: "30%",
                  flexGrow: 1,
                  background:
                    "linear-gradient(rgba(227, 227, 255, 1), rgba(255, 229, 231, 1))",
                  borderRadius: "15px",
                  height: "170px",
                }}
              >
                <Box className="flip-card-inner">
                  <Box className="flip-card-front">
                    <Box
                      component="img"
                      width={"100%"}
                      src={require("../../app/assets/Dashboard/client_enrollment.png")}
                    ></Box>
                    <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                      My Plan Details
                    </Typography>
                  </Box>

                  {isFlipped ? (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      height="100%"
                      flexWrap="wrap"
                      className="flip-card-back"
                    >
                      <Box display="flex" flexDirection="column">
                        <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                          My Plan Details
                        </Typography>
                        <ul
                          className="pl-5"
                          style={{
                            listStyleType: "square",
                            marginTop: "2px",
                            color: "#5C4EFF",
                            fontSize: "14px",
                            lineHeight: "28px",
                          }}
                        >
                          <li>
                            <Link
                              to="/lead/list"
                              style={{ color: "#000", textDecoration: "none" }}
                            >
                              Leads
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/lead/list"
                              style={{ color: "#000", textDecoration: "none" }}
                            >
                              Sales
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/lead/list"
                              style={{ color: "#000", textDecoration: "none" }}
                            >
                              Client Details For Marketing
                            </Link>
                          </li>
                        </ul>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              </Paper>
            )}
          </div>
          <div className="w33p">
            {/* Client */}
            <Paper
              onMouseEnter={() => {
                setFlipped1(true);
                setFlipped(false);
                setFlipped2(false);
                setFlipped3(false);
                setFlipped4(false);
                setFlipped5(false);
              }}
              onMouseLeave={() => setFlipped1(false)}
              className={`flip-card ${isFlipped1 ? "flipped" : ""}`}
              elevation={3}
              sx={{
                p: 2,
                flexBasis: "30%",
                flexGrow: 1,
                background:
                  "linear-gradient(rgba(227, 227, 255, 1), rgba(255, 229, 231, 1))",
                borderRadius: "15px",
                height: "170px",
              }}
            >
              <Box className="flip-card-inner">
                <Box className="flip-card-front">
                  <Box
                    component="img"
                    width={"100%"}
                    src={require("../../app/assets/Dashboard/hospital_profile.png")}
                  ></Box>
                  <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                    My Appointments
                  </Typography>
                </Box>

                {isFlipped1 ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    height="100%"
                    flexWrap="wrap"
                    className="flip-card-back"
                  >
                    <Box display="flex" flexDirection="column">
                      <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                        My Appointments
                      </Typography>
                      <ul
                        className="pl-5"
                        style={{
                          listStyleType: "square",
                          marginTop: "2px",
                          color: "#5C4EFF",
                          fontSize: "14px",
                          lineHeight: "28px",
                        }}
                      >
                        <li>
                          <Link
                            to="/appointments"
                            style={{ color: "#000", textDecoration: "none" }}
                          >
                            Appointments List
                          </Link>
                        </li>
                      </ul>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Paper>
          </div>
          <div className="w33p">
            {/* Plans */}
            {role !== "local-admin" && (
              <Paper
                onMouseEnter={() => {
                  setFlipped2(true);
                  setFlipped1(false);
                  setFlipped(false);
                  setFlipped3(false);
                  setFlipped4(false);
                  setFlipped5(false);
                }}
                onMouseLeave={() => setFlipped2(false)}
                className={`flip-card ${isFlipped2 ? "flipped" : ""}`}
                elevation={3}
                sx={{
                  p: 2,
                  flexBasis: "30%",
                  flexGrow: 1,
                  background:
                    "linear-gradient(rgba(152, 209, 255, 1), rgba(237, 255, 245, 1))",
                  borderRadius: "15px",
                  height: "170px",
                }}
              >
                <Box className="flip-card-inner">
                  <Box className="flip-card-front">
                    <Box
                      component="img"
                      width={"100%"}
                      src={require("../../app/assets/Dashboard/dentassure_plan.png")}
                    ></Box>
                    <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                      My Treatments
                    </Typography>
                  </Box>

                  {isFlipped2 ? (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      height="100%"
                      flexWrap="wrap"
                      className="flip-card-back"
                    >
                      <Box display="flex" flexDirection="column">
                        <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                          My Treatments
                        </Typography>
                        <ul
                          className="pl-5"
                          style={{
                            listStyleType: "square",
                            marginTop: "2px",
                            color: "#5C4EFF",
                            fontSize: "14px",
                            lineHeight: "28px",
                          }}
                        >
                          <li>
                            <Link
                              to="/lead/list"
                              style={{ color: "#000", textDecoration: "none" }}
                            >
                              Standard Plan
                            </Link>
                          </li>
                          {/* <li>
                            <Link
                              to="/lead/list"
                              style={{ color: "#000", textDecoration: "none" }}
                            >
                              Customized Plan
                            </Link>
                          </li> */}
                          <li>
                            <Link
                              to="/lead/list"
                              style={{ color: "#000", textDecoration: "none" }}
                            >
                              Discount Plan
                            </Link>
                          </li>
                        </ul>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              </Paper>
            )}
          </div>
          <div className="w33p">
            {/* Appointments */}
            {role !== "local-admin" && (
              <Paper
                onMouseEnter={() => {
                  setFlipped3(true);
                  setFlipped1(false);
                  setFlipped(false);
                  setFlipped2(false);
                  setFlipped4(false);
                  setFlipped5(false);
                }}
                onMouseLeave={() => setFlipped3(false)}
                className={`flip-card ${isFlipped3 ? "flipped" : ""}`}
                elevation={3}
                sx={{
                  p: 2,
                  flexBasis: "30%",
                  flexGrow: 1,
                  background:
                    "linear-gradient(rgba(254, 235, 246, 1), rgba(229, 165, 246, 1))",
                  borderRadius: "15px",
                  height: "170px",
                }}
              >
                <Box className="flip-card-inner">
                  <Box className="flip-card-front">
                    <Box
                      component="img"
                      width={"100%"}
                      src={require("../../app/assets/Dashboard/book_appointment.png")}
                    ></Box>
                    <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                      My Claims
                    </Typography>
                  </Box>

                  {isFlipped3 ? (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      height="100%" // Ensure the Box takes full height of the parent Paper
                      flexWrap="wrap" // Allow flex items to wrap to the next row
                      className="flip-card-back"
                    >
                      <Box display="flex" flexDirection="column">
                        <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                          My Claims
                        </Typography>
                        <ul
                          className="pl-5"
                          style={{
                            listStyleType: "square",
                            marginTop: "2px",
                            color: "#5C4EFF",
                            fontSize: "14px",
                            lineHeight: "28px",
                          }}
                        >
                          <li>
                            <Link
                              to="/lead/list"
                              style={{ color: "#000", textDecoration: "none" }}
                            >
                              Products
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/lead/list"
                              style={{ color: "#000", textDecoration: "none" }}
                            >
                              Add Product
                            </Link>
                          </li>
                        </ul>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              </Paper>
            )}
          </div>
          <div className="w33p">
            {/* Cases */}
            {role !== "local-admin" && (
              <Paper
                onMouseEnter={() => {
                  setFlipped4(true);
                  setFlipped1(false);
                  setFlipped(false);
                  setFlipped2(false);
                  setFlipped3(false);
                  setFlipped5(false);
                }}
                onMouseLeave={() => setFlipped4(false)}
                className={`flip-card ${isFlipped4 ? "flipped" : ""}`}
                elevation={3}
                sx={{
                  p: 2,
                  flexBasis: "30%",
                  flexGrow: 1,
                  background:
                    "linear-gradient(rgba(228, 207, 245, 1), rgba(132, 197, 247, 1))",
                  borderRadius: "15px",
                  height: "170px",
                }}
              >
                <Box className="flip-card-inner">
                  <Box className="flip-card-front">
                    <Box
                      component="img"
                      width={"100%"}
                      src={require("../../app/assets/Dashboard/cases_claims.png")}
                    ></Box>
                    <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                      Buy Plan
                    </Typography>
                  </Box>

                  {isFlipped4 ? (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      height="100%"
                      flexWrap="wrap"
                      className="flip-card-back"
                    >
                      <Box display="flex" flexDirection="column">
                        <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                          Buy Plan
                        </Typography>
                        <ul
                          className="pl-5"
                          style={{
                            listStyleType: "square",
                            marginTop: "2px",
                            color: "#5C4EFF",
                            fontSize: "14px",
                            lineHeight: "28px",
                          }}
                        >
                          <li>
                            <Link
                              to={`/client/${clientId}/plan/select`}
                              style={{ color: "#000", textDecoration: "none" }}
                            >
                              Buy Plan
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/lead/list"
                              style={{ color: "#000", textDecoration: "none" }}
                            >
                              Add Case
                            </Link>
                          </li>
                        </ul>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              </Paper>
            )}
          </div>
          <div className="w33p">
            {/* Reports */}
            <Paper
              onMouseEnter={() => {
                setFlipped5(true);
                setFlipped1(false);
                setFlipped(false);
                setFlipped2(false);
                setFlipped3(false);
                setFlipped4(false);
              }}
              onMouseLeave={() => setFlipped5(false)}
              className={`flip-card ${isFlipped5 ? "flipped" : ""}`}
              elevation={3}
              sx={{
                p: 2,
                flexBasis: "30%",
                flexGrow: 1,
                background:
                  "linear-gradient(rgba(245, 163, 174, 1), rgba(205, 225, 249, 1))",
                borderRadius: "15px",
                height: "170px",
              }}
            >
              <Box className="flip-card-inner">
                <Box className="flip-card-front">
                  <Box
                    component="img"
                    width={"100%"}
                    src={require("../../app/assets/Dashboard/reports.png")}
                  ></Box>
                  <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                    My Tickets
                  </Typography>
                </Box>

                {isFlipped5 ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    height="100%"
                    flexWrap="wrap"
                    className="flip-card-back"
                  >
                    <Box display="flex" flexDirection="column">
                      <Typography variant="h6" sx={{ color: "#5C4EFF" }}>
                        My Tickets
                      </Typography>
                      <ul
                        className="pl-5"
                        style={{
                          listStyleType: "square",
                          marginTop: "2px",
                          color: "#5C4EFF",
                          fontSize: "14px",
                          lineHeight: "28px",
                        }}
                      >
                        <li>
                          <Link
                            to="/lead/list"
                            style={{ color: "#000", textDecoration: "none" }}
                          >
                            Ticket View
                          </Link>
                        </li>
                      </ul>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Paper>
            {openSelectClientDialog.open && (
              // <CustomAutocomplete></CustomAutocomplete>
              <CustomDialog
                width={"600px"}
                open={openSelectClientDialog.open}
                handleClose={() =>
                  setOpenSelectClientDialog({ open: false, data: {} })
                }
                title={"Select Client"}
                maxWidth={"lg"}
              >
                <>
                  <Box>
                    <SelectClient
                      onClose={() =>
                        setOpenSelectClientDialog({ open: false, data: {} })
                      }
                      openSelectClientDialog={openSelectClientDialog}
                      fromCommomnDashboard={true}
                    />
                  </Box>
                </>
              </CustomDialog>
            )}

            {openBookAppointmentDialog.open && (
              <CustomDialog
                width={"650px"}
                open={openBookAppointmentDialog.open}
                handleClose={() =>
                  setOpenBookAppointmentDialog({ open: false, data: {} })
                }
                title={"Book Appointment"}
                maxWidth={"lg"}
              >
                <Box>
                  <BookAppointmentForm
                    onClose={() =>
                      setOpenBookAppointmentDialog({ open: false, data: {} })
                    }
                    navigateTo={() => {
                      navigate(`/cases/client/${"121213"}/add`);
                    }}
                  />
                </Box>
              </CustomDialog>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Container maxWidth="lg" sx={{ marginTop: 2 }}>
        <>
          {renderCards()}
          <Box mt={4}>
            <Grid container spacing={2}>
              {/* First Column */}
              <Grid item xs={12} md={6}>
                <CustomCard>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    p="16px 16px 0px 16px"
                    textAlign="center"
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Typography
                        fontSize="14px"
                        sx={{ color: theme.palette.customColors.grey }}
                      >
                        My Claims
                      </Typography>
                      <Link
                        to="/query-list"
                        style={{
                          marginBottom: 2,
                          textDecoration: "none",
                          fontSize: "12px",
                          color: theme.palette.customColors.main,
                        }}
                      >
                        View All
                      </Link>
                    </Box>
                  </Box>
                  <Box p={2}>
                    <RequestList />
                  </Box>
                </CustomCard>
              </Grid>

              {/* Second Column */}
              <Grid item xs={12} md={6}>
                <CustomCard>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    p="16px 16px 0px 16px"
                    textAlign="center"
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Typography
                        fontSize="14px"
                        sx={{ color: theme.palette.customColors.grey }}
                      >
                        My Appointments
                      </Typography>
                      <Link
                        to="/query-list"
                        style={{
                          marginBottom: 2,
                          textDecoration: "none",
                          fontSize: "12px",
                          color: theme.palette.customColors.main,
                        }}
                      >
                        View All
                      </Link>
                    </Box>
                  </Box>
                  <Box p={2}>
                    <RequestList />
                  </Box>
                </CustomCard>
              </Grid>
            </Grid>
          </Box>
        </>
      </Container>
    </>
  );
};

export default ClientDashboard;
