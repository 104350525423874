import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "../../app/context/useSnackbar";
import { useAppContext } from "../../app/context/AppContext";
import CustomInput from "../../components/Input";
import CustomButton from "../../components/Button";
import {
  COUNTRY_LIST,
  MARTIAL_STATUS,
  PHONE_REG_EXP,
  GENDER_DATA,
} from "../../app/utilities/constants";
import StatesAndCities from "../../app/utilities/StatesAndCities.json";
import CustomDatePicker from "../../components/DatePicker";
import CustomSelectPicker from "../../components/SelectPicker";

import CustomCheckbox from "../../components/CustomCheckBox";
import CustomAutocomplete from "../../components/Autocomplete";
import OtpScreen from "../../components/Otp";
import { apiRequest } from "../../app/services/requests";

const DoctorPersonDetailsStep1 = (props: any) => {
  const { currentSubCategory = {} as any, isEdit, onStep1Submit } = props;

  const navigate = useNavigate();
  const { id } = useParams();

  const { openSnackbar } = useSnackbar() as any;
  const [cities, setCities] = useState<any>([]);
  const [statesAndCities] = useState<any>(StatesAndCities);
  const [verifiedMobileNumber, setVerifiedMobileNumber] = useState<any>({});

  useEffect(() => {
    getAllCategories();
  }, []);

  const userDefaultValues = () => {
    const defaultValues = {
      zipCode: "",
      city: "",
      state: "",
      country: "india",
      addressLine2: "",
      addressLine1: "",
      pan: "",
      aadhar: "",
      email: "",
      maritalStatus: "",
      dateOfBirth: "",
      mobileNumber: "",
      gender: "",
      lastName: "",
      firstName: "",
      isPrimaryWhatsappNumber: false,
      alternateMobileNumber: "",
      isSecondaryWhatsappNumber: false,
    };
    const mockData = {
      zipCode: "789456",
      city: "Vinukonda",
      state: "Andhra",
      country: "India",
      addressLine2: "Temple line",
      addressLine1: "Enugupalem",
      pan: "GCDPP1854G",
      // aadhar: "",
      email: "gummaprasad7@gmail.com",
      maritalStatus: "married",
      dateOfBirth: "12-08-2007",
      mobileNumber: "7997514823",
      gender: "male",
      lastName: "Gumma",
      firstName: "Prasad",
      isPrimaryWhatsappNumber: true,
      alternateMobileNumber: "9784569887",
      isSecondaryWhatsappNumber: true,
    } as any;

    return defaultValues as any;
  };

  const schema = object({
    firstName: string().required("First name is required"),
    lastName: string().required("Last name is required"),
    gender: string().ensure().required("Gender is required"),
    mobileNumber: string()
      .required("Mobile number is required")
      .matches(PHONE_REG_EXP, "Phone number is not valid"),
    dateOfBirth: string().ensure().required("Date of birth is required"),
    maritalStatus: string().required("Email is required"),
    // aadhaar: string().required("Aadhar no is required"),
    email: string()
      .required("Email is required")
      .nullable()
      .email("Email is not valid"),
    // aadhar: string().required("Aadhar  is required"),
    pan: string().required("Pan  is required"),
    addressLine1: string().required("Address Line 1 is required"),
    addressLine2: string().required("Address Line 2 is required"),
    country: string().required("Country is required"),
    state: string().required("State is required"),
    city: string().required("City is required"),
    zipCode: string()
      .required("Zip Code is required")
      .max(6, "Zip Code should be of 6 characters"),
  });

  const { control, handleSubmit, getValues, setValue } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: userDefaultValues(),
  });

  const getAllCategories = () => {};

  const onSubmit = (data: any) => {
    onStep1Submit({ ...data });
  };

  const Styles = {
    breadcrumbSubtext: {
      fontFamily: "Poppins",
      fontSize: 13,
      color: "#021025",
      fontWeight: "550",
    },
    Container: {
      padding: 2,
    },
    TotalAppointments: {
      fontFamily: "poppins",
      fontSize: 20,
      color: "#021025",
      fontWeight: 600,
    },
  };

  return (
    <>
      <Container maxWidth="lg">
        {/* Set the desired maxWidth */}
        {verifiedMobileNumber?.otp_token ? (
          <OtpScreen
            loginWithOtp={"loginWithOtp"}
            verifiedMobileNumber={verifiedMobileNumber}
            details={verifiedMobileNumber}
            requestOTP={"loginWithOtp"}
          />
        ) : (
          <Box mt={4} style={{ width: "80%", marginLeft: "10%" }}>
            <Box>
              <Box px={4} py={4}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {/* Client Details Section */}
                  {/* <Typography variant="body1">Client Details</Typography> */}

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: "50%",
                        width: "110px",
                        height: "110px",
                        border: "2px solid #7C77FE",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "grid",
                        marginRight: "20px",
                      }}
                    >
                      <Box
                        component="img"
                        src={require("../../app/assets/Hospital/user.png")}
                      ></Box>
                    </Box>
                    <Box>
                      <Typography variant="h6">Add Doctor Image</Typography>
                      <Typography variant="subtitle1">24px to 45px</Typography>
                    </Box>
                  </Box>

                  <Grid container spacing={4} mt={3}>
                    {/* Add client details fields here */}
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="firstName"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            label={"Enter First Name"}
                            InputProps={""}
                            size={"small"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="lastName"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            label={"Enter Last Name"}
                            InputProps={""}
                            size={"small"}
                          />
                        )}
                      />
                    </Grid>

                    {/* <Grid  xs={12} sx={{display:"flex"}}> */}
                    <Grid
                      item
                      xs={12}
                      md={6}
                      mt={1}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <Typography
                        variant="regular"
                        style={{
                          // paddingTop: "25px",
                          marginRight: "7px",
                          textAlign: "center",
                        }}
                      >
                        Sex :
                      </Typography>
                      <Controller
                        name={`gender`}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <FormControl component="fieldset">
                            <RadioGroup {...field} row>
                              {GENDER_DATA.map((option: any, idx: any) => (
                                <FormControlLabel
                                  key={idx}
                                  value={option.id}
                                  control={<Radio />}
                                  label={option.name}
                                />
                              ))}
                            </RadioGroup>
                            {error && (
                              <FormHelperText
                                sx={{ color: "#d32f2f !important" }}
                              >
                                {error.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={6} mt={1}>
                      <Controller
                        name="maritalStatus"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomSelectPicker
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            size={"small"}
                            fullWidth={true}
                            width={"100%"}
                            selectData={MARTIAL_STATUS}
                            label={"Select Martial Status"}
                          />
                        )}
                      />
                    </Grid>
                    {/* </Grid> */}
                    <Grid
                      item
                      xs={12}
                      md={12}
                      mt={1}
                      gap={3}
                      sx={{ display: "flex" }}
                      style={{ alignSelf: "flex-end" }}
                    >
                      <Grid item xs={12} md={6}>
                        <Controller
                          name="pan"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <CustomInput
                              {...field}
                              error={!!error}
                              helperText={error ? error.message : ""}
                              label={"Pan Number"}
                              InputProps={""}
                              size={"small"}
                            />
                          )}
                        />
                      </Grid>

                      <Grid xs={6}>
                        <Controller
                          name={"dateOfBirth"}
                          rules={{ required: true }}
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <CustomDatePicker
                              style={{ width: "100%" }}
                              {...field}
                              label={"End Date Of Birth"}
                              error={!!error}
                              helperText={error ? error.message : ""}
                              maxDate={new Date()}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Communication Details Section */}
                  <Box mt={5}>
                    <Typography variant="body1">
                      Communication Details
                    </Typography>
                    {/* Add communication details fields here */}
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} mt={2}>
                        <Box>
                          <Box style={{ display: "flex" }}>
                            <Controller
                              name="phonecode"
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <CustomInput
                                  label={"+91"}
                                  style={{ width: "47px", marginRight: "10px" }}
                                  type="text"
                                  {...field}
                                  error={!!error}
                                  helperText={error ? error.message : ""}
                                />
                              )}
                            />
                            <Controller
                              name="mobileNumber"
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <CustomInput
                                  style={{ width: "25vw" }}
                                  label={"Primary Mobile No"}
                                  type="text"
                                  {...field}
                                  error={!!error}
                                  helperText={error ? error.message : ""}
                                />
                              )}
                            />
                          </Box>
                          <Controller
                            name="isPrimaryWhatsappNumber"
                            control={control}
                            render={({ field }) => (
                              <CustomCheckbox
                                {...field}
                                label="Whatsapp Number"
                                // onChange={(e) => {
                                //   field.onChange(e);
                                //   console.log("isPrimaryWhatsappNumber", field);
                                // }}
                              />
                            )}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={5} mt={2} ml={2} sx={{ gap: 2 }}>
                        <Box>
                          <Box style={{ display: "flex" }}>
                            <Controller
                              name="phonecode"
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <CustomInput
                                  label={"+91"}
                                  style={{ width: "47px", marginRight: "10px" }}
                                  type="text"
                                  {...field}
                                  error={!!error}
                                  helperText={error ? error.message : ""}
                                />
                              )}
                            />
                            <Controller
                              name="alternateMobileNumber"
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <CustomInput
                                  style={{ width: "25.5vw" }}
                                  label={"Secondary Mobile No"}
                                  type="text"
                                  {...field}
                                  error={!!error}
                                  helperText={error ? error.message : ""}
                                />
                              )}
                            />
                          </Box>
                          <Controller
                            name="isSecondaryWhatsappNumber"
                            control={control}
                            render={({ field }) => (
                              <CustomCheckbox
                                {...field}
                                label="Whatsapp Number"
                              />
                            )}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6} mt={0}>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <CustomInput
                              {...field}
                              error={!!error}
                              helperText={error ? error.message : ""}
                              label={"Email"}
                              size={"small"}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {/* Address for Communication Section */}
                  <Box mt={5}>
                    <Typography variant="body1">
                      Address for Communication
                    </Typography>
                    {/* Add address for communication fields here */}

                    <Grid container spacing={3} mt={0}>
                      <Grid item xs={6} md={6}>
                        <Controller
                          name="addressLine1"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <CustomInput
                              {...field}
                              error={!!error}
                              helperText={error ? error.message : ""}
                              label={"Enter Address Line 1"}
                              InputProps={""}
                              size={"small"}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Controller
                          name="addressLine2"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <CustomInput
                              {...field}
                              error={!!error}
                              helperText={error ? error.message : ""}
                              label={"Enter Address Line 2"}
                              InputProps={""}
                              size={"small"}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Box>
                          <Controller
                            name="country"
                            defaultValue={"India"}
                            control={control}
                            rules={{ required: true }}
                            render={({
                              field: { ref, onChange, ...field },
                              fieldState: { error },
                            }) => (
                              <CustomAutocomplete
                                field={field}
                                disabled
                                error={!!error}
                                helperText={error ? error.message : ""}
                                ref={ref}
                                singleSelection
                                onChange={(
                                  event: React.SyntheticEvent,
                                  value: any
                                ) => onChange(value)}
                                options={COUNTRY_LIST}
                                value={getValues("country")}
                                label="Country"
                                defaultValue={"India"}
                              />
                            )}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Controller
                          name="state"
                          control={control}
                          defaultValue={getValues("state")}
                          rules={{ required: true }}
                          render={({
                            field: { ref, onChange, ...field },
                            fieldState: { error },
                          }) => (
                            <CustomAutocomplete
                              field={field}
                              error={!!error}
                              helperText={error ? error.message : ""}
                              ref={ref}
                              value={getValues("state")}
                              singleSelection
                              onChange={(
                                event: React.SyntheticEvent,
                                value: any
                              ) => {
                                onChange(value);
                                setValue("city", "");
                                setCities(statesAndCities[value] || []);
                              }}
                              options={Object.keys(statesAndCities)}
                              label="State"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Controller
                          name="city"
                          control={control}
                          defaultValue={getValues("city")}
                          rules={{ required: true }}
                          render={({
                            field: { ref, onChange, ...field },
                            fieldState: { error },
                          }) => (
                            <CustomAutocomplete
                              field={field}
                              error={!!error}
                              helperText={error ? error.message : ""}
                              ref={ref}
                              value={getValues("city")}
                              singleSelection
                              onChange={(
                                event: React.SyntheticEvent,
                                value: any
                              ) => {
                                onChange(value);
                                setValue("city", value);
                              }}
                              options={cities}
                              label="City"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Controller
                          name="zipCode"
                          control={control}
                          rules={{ required: true }}
                          render={({ field, fieldState: { error } }) => (
                            <CustomInput
                              {...field}
                              error={!!error}
                              helperText={error ? error.message : ""}
                              label={"Zip code"}
                              size={"small"}
                              type={"number"}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={6} md={6}>
                        <Box>
                          <Controller
                            name="country"
                            defaultValue={"India"}
                            control={control}
                            rules={{ required: true }}
                            render={({
                              field: { ref, onChange, ...field },
                              fieldState: { error },
                            }) => (
                              <CustomAutocomplete
                                field={field}
                                // disabled
                                error={!!error}
                                helperText={error ? error.message : ""}
                                ref={ref}
                                singleSelection
                                onChange={(
                                  event: React.SyntheticEvent,
                                  value: any
                                ) => onChange(value)}
                                options={COUNTRY_LIST}
                                value={getValues("country")}
                                label="Country"
                                defaultValue={"India"}
                              />
                            )}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  {/* Additional Sections can be added as needed */}
                  <Box display="flex" justifyContent="flex-end" mt={2}>
                    <CustomButton
                      title={"Cancel"}
                      marginRight={"5px"}
                      bgColor={"rgba(255, 225, 229, 1)"}
                      width={"100px"}
                      color={"rgba(255, 97, 116, 1)"}
                      borderColor={"rgba(255, 97, 116, 1)"}
                      borderWidth={"1px"}
                      borderStyle={"solid"}
                    />
                    <Box marginLeft={1}>
                      {" "}
                      <CustomButton
                        title={
                          currentSubCategory?.open === "add" ? "Create" : "Next"
                        }
                        marginRight={"5px"}
                        bgColor={"rgba(92, 78, 255, 1)"}
                        width={"100px"}
                        type="submit"
                      />
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        )}
      </Container>
    </>
  );
};

export default DoctorPersonDetailsStep1;
