import { Typography } from "@mui/material";

export const LabelText = ({
  children,
  variant = "body1",
  fontWeight = 600,
  color = "text.primary",
  ...props
}: any) => (
  <Typography
    variant={variant}
    fontWeight={fontWeight}
    color={color}
    {...props}
  >
    {children}
  </Typography>
);
