// import React from "react";

// const ApprovalAppliedReportList = () => {
//   let user = {} as any;
//   return <div>ApprovalAppliedReportList</div>;
// };

// export default ApprovalAppliedReportList;

import React, { useEffect, useState } from "react";
import { IRATable } from "../../components/ira-table/ira-table";
import DateTimeCell from "../../components/TableComponent/date-time-cell";
import { IRATableColumnProps } from "../../components/ira-table/ira-table.interfaces";
import NameCell from "../../components/TableComponent/name-cell/name-cell";
import ActionCell from "../../components/TableComponent/action-cell";
import Breadcrumb from "../../components/Breadcrumb";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// import { styles } from "./styles";
import IRATypography from "../../components/ira-typography";
import { Box, Card, Grid, InputAdornment, Typography } from "@mui/material";
import Button from "../../components/TableComponent/button";
import CustomButton from "../../components/Button";
import CustomDialog from "../../components/CustomDialog";
import SelectClient from "../../components/SelectClient";
import CustomInput from "../../components/Input";
import { Close, Search } from "@mui/icons-material";
import CustomDatePicker from "../../components/DatePicker";
import { apiRequest } from "../../app/services/requests";
import BookAppointmentForm from "../Appointment/book-appointment-form";
import { yupResolver } from "@hookform/resolvers/yup";
import NormalDatePicker from "../../components/DatePicker/datePicker";
import moment from "moment";

export interface ISelectDialog {
  open?: boolean;
  data?: any;
}

const ApprovalAppliedReportList = () => {
  const navigate = useNavigate();
  const [openSelectClientDialog, setOpenSelectClientDialog] =
    useState<ISelectDialog>({
      open: false,
      data: {},
    });

  const [approvalList, setApprovalList] = useState({
    data: [],
    totalCount: 0,
  });
  // console.log(approvalList, "Approval");
  const [searchTerm, setSearchTerm] = React.useState("");

  useEffect(() => {
    getAllApprovalList();
  }, [searchTerm]);
  const getAllApprovalList = () => {
    const payload = {
      ...(searchTerm && {
        searchText: searchTerm,
      }),
    };
    apiRequest({
      url: `case/list`,
      data: payload,

      method: "POST",
    })
      .then((res) => {
        if (res) {
          setApprovalList(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  const breadcrumbs = [
    <RouterLink to="/Dashboard" style={{ textDecoration: "none" }}>
      <IRATypography
        key="2"
        textVariant="h12WithSemiBold"
        sx={{ fontSize: "12px" }}
      >
        Home
      </IRATypography>
    </RouterLink>,
    <IRATypography
      key="2"
      textVariant="h12WithSemiBold"
      sx={{ fontSize: "12px" }}
    >
      Approvals List
    </IRATypography>,
  ];

  const activityTableColumns = () =>
    [
      {
        name: "caseId",
        label: "Case Id",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "date",
        label: " Date",
        component: DateTimeCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "patientName",
        label: " Patient Name",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "service",
        label: "Service",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "appliedDate",
        label: "Applied Date",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      // {
      //   name: "claimCost ",
      //   label: "Claim Cost",
      //   component: NameCell,
      //   sortable: false,
      //   style: { width: "350px" },
      // },
      {
        name: "status",
        label: "Status",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      } as any,
    ] as Array<IRATableColumnProps>;

  const activityTableData = () => {
    return approvalList?.data?.map((row: any) => {
      return {
        ...row,
        caseId: {
          name: row?.id,
        },
        date: {
          dateTime: moment(row?.createdAt).format("DD-MM-YYYY"),
        },
        patientName: {
          name: `${row?.client?.firstName} ${row?.client?.lastName}`,
        },
        service: {
          name: row.service ?? 0,
        },
        appliedDate: {
          name: `${row?.updatedAt}` ?? 0,
        },
        // claimCost: {
        //   name: `N/A`,
        //   // name: row?.client?.claimCost ?? "N/A",
        // },
        status: {
          name: `${row?.status}` ?? "-",
        },
      };
    });
  };

  const handleButtonClick = (type: string) => {
    if (type === "bookAppointment") {
      setOpenSelectClientDialog({ open: true });
    } else {
      navigate(`/cases/${"ca6090934"}/details/${"case-history"}`);
    }
  };

  return (
    <>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        sx={{ p: "5px" }}
      >
        <Breadcrumb title={`Approvals List`} breadcrumbsArr={breadcrumbs} />

        <Box style={{ display: "flex", gap: "8px" }}>
          <CustomButton
            onClick={() => handleButtonClick("Button1")}
            title={"Export List"}
          />
        </Box>
      </Box>

      <Card>
        <Box>
          <Grid
            container
            sx={{ padding: "10px" }}
            display="flex"
            justifyContent="space-between"
          >
            <Grid item md={3} sx={{ background: "#F0F2F6" }}>
              <CustomInput
                size={"small"}
                placeholder="Search by Claim & Name"
                placeholderColor="#204289"
                placeholderColorOpacity={1}
                placeholderSize={15}
                value={searchTerm}
                onChange={(event: any) => setSearchTerm(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      {searchTerm.length > 0 && (
                        <InputAdornment
                          sx={{ cursor: "pointer" }}
                          position="end"
                          onClick={() => setSearchTerm("")}
                        >
                          <Close />
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
            </Grid>

            <Grid sx={{ display: "flex", gap: "7px" }}>
              <Typography
                fontSize={"14px"}
                color="#313035"
                fontWeight="500px"
                paddingTop={"10px"}
              >
                Show Entries:{approvalList.totalCount}
              </Typography>
              {/* <CustomDatePicker /> */}
              {/* <Controller
                      name={"dateOfBirth"}
                      rules={{ required: true }}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomDatePicker
                          {...field}
                          label={"End Date Of Birth"}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          disablePast={true}
                        />
                      )}
                    /> */}
              <NormalDatePicker />
              <NormalDatePicker />
              {/* <CustomDatePicker /> */}
            </Grid>
          </Grid>

          <Box sx={{ padding: "10px" }}>
            <IRATable
              columns={activityTableColumns()}
              data={activityTableData()}
              onSort={(name: string, order: string) =>
                alert(`sort requested for ${name} with order ${order}`)
              }
            />
          </Box>
        </Box>
      </Card>
      {openSelectClientDialog.open && (
        <CustomDialog
          width={"650px"}
          open={openSelectClientDialog.open}
          handleClose={() =>
            setOpenSelectClientDialog({ open: false, data: {} })
          }
          title={"Book Appointment"}
          maxWidth={"lg"}
        >
          <Box>
            <BookAppointmentForm
              onClose={() =>
                setOpenSelectClientDialog({ open: false, data: {} })
              }
              navigateTo={(data: string) => {
                navigate(`/cases/patient/${"121213"}/add`);
              }}
            />
          </Box>
        </CustomDialog>
      )}
    </>
  );
};

export default React.memo(ApprovalAppliedReportList);
