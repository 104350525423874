import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import CustomCard from "../../../components/Card";
import { apiRequest } from "../../../app/services/requests";
import NameCell from "../../../components/TableComponent/name-cell/name-cell";
import { IRATable } from "../../../components/ira-table/ira-table";
import DateTimeCell from "../../../components/TableComponent/date-time-cell";
import moment from "moment";
import Pagination from "../../../components/Pagination";

const VersionHistoryList = (props: any) => {
  const { planCode } = props;
  const navigate = useNavigate();

  const [planList, setPlanList] = useState({ results: [], totalCount: 0 });
  const [limit, setLimit] = useState<any>(10);
  const [skip, setSkip] = useState<any>(0);
  const [filters, setFilters] = useState({
    search: "",
  });

  useEffect(() => {
    getVersionHistoryList(filters);
  }, [filters, skip, limit]);

  const getVersionHistoryList = (filters?: any) => {
    apiRequest({
      url: `master-plan/list-by-code?code=${planCode}&skip=${skip}&limit=${limit}${
        Boolean(filters.search?.length) ? `&search=${filters.search}` : ""
      }`,
      // data: payload,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          setPlanList(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  const activityTableColumns = () =>
    [
      {
        name: "planCode",
        label: "Plan Code",
        component: NameCell,
        sortable: false,
        style: { width: "10%" },
      },
      {
        name: "createdAt",
        label: "Created At",
        component: DateTimeCell,
        sortable: false,
        style: { width: "10%" },
      },
      {
        name: "planName",
        label: "Plan Name",
        component: NameCell,
        sortable: false,
        style: { width: "30%" },
      },
      {
        name: "premium",
        label: "Premium",
        component: NameCell,
        sortable: false,
        style: { width: "10%" },
      },
      {
        name: "claimLimit",
        label: "Claim Limit",
        component: NameCell,
        sortable: false,
        style: { width: "10%" },
      },
      {
        name: "categories",
        label: "Plan Categories",
        component: NameCell,
        sortable: false,
        style: { width: "20%" },
      },
      {
        name: "status",
        label: "Status",
        component: NameCell,
        sortable: false,
        style: { width: "10%" },
      },
      {
        name: "version",
        label: "Version",
        component: NameCell,
        sortable: false,
        style: { width: "10%" },
      },
    ] as any;

  const activityTableData = () => {
    return planList?.results?.map((row: any) => {
      let modifiedCategories = row?.categories;
      if (typeof row?.categories === "string") {
        modifiedCategories = row?.categories?.replace(/{|}/g, "").split(",");
      }
      return {
        ...row,
        planCode: {
          name: row?.code,
        },
        createdAt: {
          dateTime: moment(row?.createdAt).format("DD-MM-YYYY"),
        },
        planName: {
          name: row?.name,
        },
        premium: {
          name: row?.premium ?? 0,
        },
        claimLimit: {
          name: row?.limit ?? 0,
        },
        categories: {
          name: modifiedCategories?.length
            ? modifiedCategories?.join(", ")
            : "NA",
        },
        status: {
          name: row?.status ?? 0,
        },
        version: {
          name: `v.${row?.version}`,
        },
      };
    });
  };

  const handlePageChange = (page?: any) => {
    const newSkip = page * limit;
    setSkip(newSkip);
  };

  return (
    <>
      <Box>
        <CustomCard>
          <Grid container sx={{ padding: "10px" }}>
            <Box sx={{ width: "100%" }}>
              <IRATable
                columns={activityTableColumns()}
                data={activityTableData()}
                onSort={(name: string, order: string) =>
                  alert(`sort requested for ${name} with order ${order}`)
                }
              />
              <Pagination
                onPageChange={(data: any) => {
                  handlePageChange(data);
                }}
                onRowsPerPageChange={(limit?: number, skip?: number) => {
                  setLimit(limit);
                  setSkip(0);
                }}
                totalCount={planList.totalCount}
              />
            </Box>
          </Grid>
        </CustomCard>
      </Box>
    </>
  );
};

export default React.memo(VersionHistoryList);
