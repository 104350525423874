import React, { useEffect, useState } from "react";
import { IRATable } from "../../components/ira-table/ira-table";
import DateTimeCell from "../../components/TableComponent/date-time-cell";
import { IRATableColumnProps } from "../../components/ira-table/ira-table.interfaces";
import NameCell from "../../components/TableComponent/name-cell/name-cell";
import ActionCell from "../../components/TableComponent/action-cell";
import { Box } from "@mui/material";
import { apiRequest } from "../../app/services/requests";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const ClientCases = () => {
  const result = [
    {
      id: "123",
      createdAt: "2024-01-10T10:37:43.271+00:00",
      patient: {
        id: 1,
        firstName: "Nick",
        lastName: "JK",
      },
      bookingType: "OP",
      treatment: "-",
    },
    //   {
    //     id: "124",
    //     createdAt: "2024-01-10T10:37:43.271+00:00",
    //     patient: {
    //       id: 1,
    //       firstName: "Ram",
    //       lastName: "G",
    //     },
    //     bookingType: "TREATMENT",
    //     treatment: "Root Canal",
    //   },
  ];
  const navigate = useNavigate();

  const [caseList, setCaseList] = useState({ data: [], totalCount: 0 });
  const [searchTerm, setSearchTerm] = React.useState("");
  // console.log(caseList, "Cases");

  useEffect(() => {
    getAllCaseList();
  }, [searchTerm]);
  const getAllCaseList = () => {
    const payload = {
      ...(searchTerm && {
        searchText: searchTerm,
      }),
    };
    apiRequest({
      url: `case/list`,
      data: payload,

      method: "POST",
    })
      .then((res) => {
        if (res) {
          setCaseList(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  const activityTableColumns = () =>
    [
      {
        name: "caseId",
        label: "Case Id",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "createdAt",
        label: "Date and Time",
        component: DateTimeCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "patientName",
        label: "Patient Name",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      // {
      //   name: "relation",
      //   label: "Relation",
      //   component: NameCell,
      //   sortable: false,
      //   style: { width: "350px" },
      // },

      // {
      //   name: "cost",
      //   label: "Cost",
      //   component: NameCell,
      //   sortable: false,
      //   style: { width: "350px" },
      // },

      // {
      //   name: "claim",
      //   label: "Claim",
      //   component: NameCell,
      //   sortable: false,
      //   style: { width: "350px" },
      // },

      {
        name: "mobileNo",
        label: "Mobile No",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "status",
        label: "Status",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "action",
        label: "Action",
        component: (props: any) => {
          return ActionCell({
            button: true,
            btnVariant: "primary",
            btnSize: "smallest",
            disabled: false,
            infoMessage: null,
            label: "View",
            onClick: () => {
              navigate(`/cases/${props?.data?.id}/details/${"case-history"}`);
            },
            ...props,
          } as any); // <-- This 'as any' is not needed and might be causing the issue
        },
      } as any,
    ] as Array<IRATableColumnProps>;

  const activityTableData = () => {
    return caseList?.data?.map((row: any) => {
      return {
        ...row,
        caseId: {
          name: `${row?.id}`,
        },
        createdAt: {
          dateTime: moment(row.createdAt).format("DD-MM-YYYY"),
        },
        patientName: {
          name: `${row?.client?.firstName} ${row?.client?.lastName} `,
        },

        // relation: {
        //   name: `spouse `,
        // },
        // cost: {
        //   name: `5000`,
        // },
        // claim: {
        //   name: `5000`,
        // },
        mobileNo: {
          name: `${row?.client?.mobileNumber}`,
        },

        status: {
          name: `${row?.status}`,
        },
        action: {
          data: {
            id: row?.id,
            status: "reviewed",
            project: {
              id: row?.id,
            },
          },
        },
      };
    });
  };

  return (
    <div>
      <Box p={3}>
        <IRATable
          columns={activityTableColumns()}
          data={activityTableData()}
          onSort={(name: string, order: string) =>
            alert(`sort requested for ${name} with order ${order}`)
          }
        />
      </Box>
    </div>
  );
};

export default ClientCases;
