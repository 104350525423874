import React from "react";
import {
  Stepper as MuiStepper,
  Step,
  StepLabel,
  StepIconProps,
  Box,
  Typography,
  styled,
  StepConnector,
  stepConnectorClasses,
  stepLabelClasses,
  LinearProgress,
} from "@mui/material";
import { IStepperProps } from "./stepper.interface";

import CompletedStepIcon from "./CompletedStepIcon";
import BackgroundStepIcon from "./BackgroundStepIcon";

/**
 * Circle Stepper
 */
const CircleStepConnector = styled(StepConnector)({
  [`& .${stepConnectorClasses.line}`]: {
    borderTopStyle: "dashed",
    borderTopWidth: "2px",
    borderColor: "#466993",
  },
});

const CircleStep = styled(Step)({
  padding: "0px",
});

const CircleStepLabel = styled(StepLabel)({
  padding: "0px",
  [`& .${stepLabelClasses.iconContainer}`]: {
    padding: "0px",
  },
});

function StepIcon(props: StepIconProps) {
  const { active, completed, className, icon } = props;

  const renderStepIcon = () => {
    if (completed) {
      return <CompletedStepIcon />;
    }

    const labelColor = () => (active ? "#FFFFFF" : "#D4E5F8");
    const backgroundColor = () => (active ? "#33AB3F" : "#1D3A5C");

    return (
      <Box sx={{ position: "relative" }}>
        <BackgroundStepIcon primaryColor={backgroundColor()} />
        <Typography
          sx={{
            position: "absolute",
            top: "7px",
            left: icon === 1 ? "14px" : "12.5px",
            color: labelColor(),
            fontSize: "14px",
            fontWeight: 700,
          }}
        >
          {icon}
        </Typography>
      </Box>
    );
  };

  return <Box className={className}>{renderStepIcon()}</Box>;
}

function CircleStepper(props: IStepperProps) {
  const { steps, stepOptions, ...rest } = props;

  return (
    <MuiStepper connector={<CircleStepConnector />} {...rest}>
      {Array.from(Array(steps).keys()).map((key) => (
        <CircleStep key={key}>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Box>
              <CircleStepLabel StepIconComponent={StepIcon} />
            </Box>
            <Box ml={1} mr={1}>
              {stepOptions[key]}
            </Box>
          </Box>
        </CircleStep>
      ))}
    </MuiStepper>
  );
}

/**
 * Bar Stepper
 */
function BarStepper(props: IStepperProps) {
  const { steps, activeStep = 1, stepOptions, ...rest } = props;

  return (
    <MuiStepper
      activeStep={activeStep + 1}
      connector={null}
      {...rest}
      sx={{
        margin: "0 -4px",
        "& .MuiStep-root": {
          paddingLeft: "4px !important",
          paddingRight: "4px !important",
        },
      }}
    >
      {Array.from(Array(steps).keys()).map((key) => (
        <Step key={key} sx={{ flex: 1 }}>
          <StepLabel
            StepIconComponent={StepperBar}
            sx={{
              "& .MuiStepLabel-iconContainer": {
                width: "100%",
                paddingRight: "0px !important",
              },
            }}
          />
          <Box>{stepOptions[key]}</Box>
        </Step>
      ))}
    </MuiStepper>
  );
}

function StepperBar(props: StepIconProps) {
  const { completed } = props;

  return (
    <LinearProgress
      variant="determinate"
      value={completed ? 100 : 0}
      sx={{
        borderRadius: "13px !important",
        height: "8px !important",
        width: "100% !important",
        backgroundColor: "#d6f2d9 !important",
        "& .MuiLinearProgress-bar": {
          backgroundColor: "#33C042 !important",
          transitionDuration: "0.2s !important",
        },
      }}
    />
  );
}
export default function Stepper(props: IStepperProps) {
  const { stepperVariant = "circle", ...rest } = props;

  if (stepperVariant === "circle") {
    return <CircleStepper {...rest} />;
  }

  return <BarStepper {...rest} />;
}
