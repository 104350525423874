import React from "react";
import ClientOtpSuccess from "./client-otp-success";
import ClientOtpverifyScreen from "./client-otp-verify-screen";
import AddEditClient from "./add-edit-client";

const ManageClient = () => {
  return (
    <>
      {/* <ClientOtpSuccess /> */}
      <AddEditClient />
      {/* <ClientOtpverifyScreen /> */}
    </>
  );
};

export default ManageClient;
