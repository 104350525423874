import React from "react";
import { Container, Typography, Box } from "@mui/material";
import "./dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import CustomCard from "../../components/Card";
import { theme } from "../../theme/theme";
import { useAppContext } from "../../app/context/AppContext";
import RequestList from "../Requests/request-list";

const PanelDoctorDashboard: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAppContext() as any;

  return (
    <>
      <Container maxWidth="lg" sx={{ marginTop: 2 }}>
        <>
          <Box mt={4}>
            <CustomCard>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                p={"16px 16px 0px 16px"}
                textAlign={"center"}
              >
                <Box style={{ display: "flex" }}>
                  <Typography
                    fontSize={"14px"}
                    sx={{ color: theme.palette.customColors.grey }}
                  >
                    All Requests
                  </Typography>
                  {/* <Typography
                    ml={1.5}
                    sx={{
                      background: "rgba(104, 212, 180, 1)",
                      padding: "3px 10px",
                      borderRadius: "7px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    02
                  </Typography> */}
                </Box>
              </Box>
              <Box p={2}>
                <RequestList />
              </Box>
            </CustomCard>
          </Box>
        </>
      </Container>
    </>
  );
};

export default PanelDoctorDashboard;
