import { Box, Grid, TableCell, TableRow, Typography } from "@mui/material";
import CustomModal from "../../../components/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { FILE_PATHS } from "../../../app/utilities/constants";
import uploadToS3 from "../../../app/utilities/uploadToS3";
import cogoToast from "cogo-toast";
import SampleTable from "../../../components/SampleTable";
import CustomButton from "../../../components/Button";
import Button from "../../../components/Button";
import { useAppContext } from "../../../app/context/AppContext";
import { ProfilePic } from "../../../app/utilities";
import { styles } from "./styles";

export const UploadInvestigationModal = (props: any) => {
  const {
    investigationOpenModal,
    handleInvestigationModal,
    selectedInvestigations,
    submitInvestigationFiles,
    setSelectedInvestigations,
    setSelectedImageView,
    setTriggerModal,
  } = props;
  const { INVESTIGATIONS_PATH } = FILE_PATHS;
  const { setLoader }: any = useAppContext();

  const handleFileUpload = (file: any, index: any) => {
    setLoader(true);
    uploadToS3(INVESTIGATIONS_PATH, file, "photo")
      .then(async (response) => {
        const files: any = response;
        const clonedSelected = JSON.parse(
          JSON.stringify(selectedInvestigations)
        );
        clonedSelected.investigations[index] = {
          ...clonedSelected.investigations[index],
          files: [
            ...(clonedSelected.investigations[index].files || []),
            ...files,
          ],
        };
        setSelectedInvestigations(clonedSelected);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
        cogoToast.error("Failed to upload file. Please try again", {
          position: "top-right",
          heading: "Error",
          hideAfter: 7,
        });
      });
  };

  const removeImage = (index: any, imageIndex: any) => {
    const clonedSelected = JSON.parse(JSON.stringify(selectedInvestigations));
    const filtered = clonedSelected.investigations[index].files.filter(
      (file: any, i: any) => i !== imageIndex
    );
    clonedSelected.investigations[index].files = filtered;
    setSelectedInvestigations(clonedSelected);
  };

  const investigationTableHeadData = ["Investigation Name", "Action"];

  return (
    <CustomModal zIndex={"99999"} open={investigationOpenModal} width={"600px"}>
      <Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            fontSize={"22px"}
           
            fontWeight={"600"}
            color={"#204289"}
          >
            Upload Investigation
          </Typography>
          <CloseIcon onClick={handleInvestigationModal} />
        </Box>
        <Box mt={"20px"}>
          <SampleTable
            header={investigationTableHeadData}
            tableCellStyles={{ textAlign: "left" }}
          >
            {(selectedInvestigations.investigations || []).map(
              (row: any, index: any) => (
                <TableRow key={row.id}>
                  <TableCell sx={{ textAlign: "left", width: "70%" }}>
                    <Typography sx={{ ...styles.tableCell, textAlign: "left" }}>
                      <b>{row.name}</b>
                    </Typography>
                    <Box
                      sx={{
                        marginTop: "6px",
                        display: "flex",
                        gap: "10px",
                        flexWrap: "wrap",
                      }}
                    >
                      {(row.files || []).map((file: any, imageIndex: any) => (
                        <Box>
                          <Typography
                            sx={{
                              ...styles.tableCell,
                              //   ...styles.imageRemoveIcon,
                            }}
                            onClick={() => removeImage(index, imageIndex)}
                          >
                            <b>x</b>
                          </Typography>
                          <ProfilePic
                            profilePhoto={file.documentPath}
                            styles={{
                              width: "90px",
                              height: "80px",
                              cursor: "pointer",
                            }}
                            isProfilePic={false}
                            onClick={() => {
                              setSelectedImageView({
                                path: file.documentPath,
                                name: file.documentName,
                              });
                              setTriggerModal(true);
                            }}
                          />
                          <Typography sx={{ ...styles.tableCell }}>
                            {file.documentName}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      component="label"
                      sx={{ textTransform: "inherit", borderRadius: "7px" }}
                    >
                      Upload File
                      <input
                        type="file"
                        multiple
                        onChange={(e) =>
                          handleFileUpload(e.target.files, index)
                        }
                        hidden
                      />
                    </Button>
                  </TableCell>
                </TableRow>
              )
            )}
          </SampleTable>
        </Box>
        <Box mt={2}>
          <Grid
            container
            flexDirection={"row"}
            spacing={1}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Grid item>
              <CustomButton
                title={"Cancel"}
                handleButtonClick={handleInvestigationModal}
                backgroundcolor={"#FFFFFF"}
                bordercolor={"#204289"}
                color={"#204289"}
                width={100}
              />
            </Grid>
            <Grid item>
              <CustomButton
                title={"Submit"}
                handleButtonClick={submitInvestigationFiles}
                backgroundcolor={"#204289"}
                bordercolor={"#204289"}
                color={"#fffff"}
                width={120}
                padding={7}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </CustomModal>
  );
};
