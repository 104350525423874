import axiosInstance from "./axiosInstance";

export const apiRequest = (config = {} as any, headers = {} as any) => {
  const configObj = {
    ...config,
    headers: {
      ...(!headers.isFileUpload && { "Content-Type": "application/json" }),
      ...(headers?.Authorization
        ? { Authorization: headers.Authorization }
        : {}),
    },
    url: config.thirdPartyUrl ? config.url : config.url, // Since baseURL is set, no need to concatenate
  };

  return new Promise((resolve, reject) => {
    axiosInstance(configObj)
      .then((response: any) => resolve(response?.data))
      .catch((error) => reject(error));
  });
};

// import axios from "axios";
// import { BACKEND_SERVER_URL } from "../utilities/constants";

// function postHeaders() {
//   return {
//     "Content-Type": "application/json",
//   };
// }

// export const apiRequest = (config = {}, headers = {}) => {
//   const stingyUser = localStorage.getItem("user");
//   const user = JSON.parse(stingyUser);
//   const configObj = {
//     ...config,
//     headers: {
//       ...(!headers.isFileUpload && {
//         ...postHeaders(),
//       }),
//       ...(user &&
//         user?.accessToken ? {
//           Authorization: `Bearer ${user.accessToken}`,
//         }
//         : {Authorization : headers?.Authorization}
//       ),
//     },
//     url: config.thirdPartyUrl
//       ? config.url
//       : BACKEND_SERVER_URL + "/v1/" + config.url,
//   };
//   return new Promise((resolve, reject) => {
//     axios(configObj)
//       .then(function (response) {
//         resolve(response.data);
//       })
//       .catch(function (err) {
//         console.log("err", err?.response);
//         if (
//           err?.response?.statusText === "Forbidden" &&
//           err?.response?.status === 403
//         ) {
//           // Clear local storage
//           localStorage.clear();
//           // Navigate to login
//           window.location.href = "/login";
//         }
//         reject(err);
//       });
//   });
// };
