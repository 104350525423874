export const styles = {
    actionIcon : {
        color : '#204289',
        fontSize : 17,
    },
    tableCell : {
        fontFamily : 'Poppins',
        fontSize : 12,
        color : '#204289',
        textAlign : 'center',
        textDecoration: 'none',
    }
}