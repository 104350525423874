import React from "react";
import { SvgIcon } from "@mui/material";
import { IconographyProps } from "./stepper.interface";

const BackgroundStepIcon = ({
  primaryColor,
  sx,
  ...props
}: IconographyProps) => {
  return (
    <SvgIcon
      sx={{ fill: "none", width: 34, height: 35, ...sx }}
      viewBox="0 0 34 35"
      {...props}
    >
      <circle cx="17" cy="17.582" r="14" fill={primaryColor} />
      <circle cx="17" cy="17.582" r="16.5" stroke={primaryColor} />
      <circle cx="17" cy="17.582" r="14" fill={primaryColor} />
      <circle cx="17" cy="17.582" r="16.5" stroke={primaryColor} />
    </SvgIcon>
  );
};

export default BackgroundStepIcon;
