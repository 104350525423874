export const styles = {
  card: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  cardContainer: {
    boxShadow: "0px 0px 6px #00000029",
    borderRadius: "10px",
    opacity: 1,
  },
};
