export const styles = {
  tagBackground: {
    backgroundColor: "primary.main",
    padding: 0.4,
    borderRadius: 1,
  },
  actionIcon: {
    color: "white",
    fontSize: 14,
    marginTop: 0.3,
  },
  tagvalue: {
    fontFamily: "Poppins",
    fontSize: 12,
    color: "white",
    marginRight: 0.6,
  },
};
