import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { object, string, ref } from "yup";
import CustomInput from "../../../components/Input";
import CustomButton from "../../../components/Button";

function AddEditInterpretation(props: any) {
  const {
    disabled,
    onCancel,
    onSubmit,
    defaultValue,
    label = "Enter Interpretation",
    errorMsg = "Interpretation is required",
  } = props || {};
  const interpretationSchema = object({
    interpretation: string().ensure().required(errorMsg),
  });

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(interpretationSchema),
    defaultValues: defaultValue.interpretation
      ? { interpretation: defaultValue.interpretation }
      : { interpretation: "" },
  });

  const onInterpretationFormSubmit = (formData: any) => {
    props.onSubmit({ interpretation: formData.interpretation });
  };

  return (
    <Box pb={2}>
      <form
        name={"interpretationForm"}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onInterpretationFormSubmit)}
      >
        <Controller
          name="interpretation"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CustomInput
              {...field}
              multiline
              error={!!error}
              helperText={error ? error.message : ""}
              label={label}
              InputProps={""}
              size={"small"}
              disabled={disabled}
            />
          )}
        />
        <Grid
          container
          flexDirection={"row"}
          spacing={0.5}
          justifyContent={"end"}
          mt={2}
        >
          <Grid item>
            <CustomButton
              type="button"
              title={"Cancel"}
              backgroundcolor={"#FFFFFF"}
              bordercolor={"#204289"}
              color={"#204289"}
              width={110}
              handleButtonClick={onCancel}
              padding={7}
            />
          </Grid>
          <Grid item>
            <CustomButton
              type="submit"
              title={false ? "Update" : "Submit"}
              backgroundcolor={"#204289"}
              bordercolor={"#204289"}
              color={"#fffff"}
              width={110}
              padding={7}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default AddEditInterpretation;
