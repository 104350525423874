import React from "react";

import { Box, Button, Dialog, Typography } from "@mui/material";

const ConfirmationModal = (props: any) => {
  const { title, onConfirm, onCancel, open } = props;

  return (
    <Dialog open={open} style={{ minHeight: "200px", minWidth: "300px" }}>
      <Box
        display="flex"
        alignItems="center"
        p={2}
        style={{
          backgroundColor: "black",
          color: "white",
        }}
      >
        <Typography>{"Confirmation"}</Typography>
      </Box>
      <Box p={2} display="flex" justifyContent="center">
        <Typography>{title}</Typography>
      </Box>
      <Box display="flex" justifyContent="center" p={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirm}
          sx={{ marginRight: 2 }}
        >
          Yes
        </Button>
        <Button variant="contained" color="error" onClick={onCancel}>
          No
        </Button>
      </Box>
    </Dialog>
  );
};

export default ConfirmationModal;
