import { useEffect, useState } from "react";
import { Container, Grid, Box, Typography } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomTabs from "../../components/CustomTabs";
import { apiRequest } from "../../app/services/requests";
import CustomCard from "../../components/Card";
import SelectedServicesSummary from "./SelectedServices/selected-services-summary";

interface TabOption {
  index: number;
  id: string;
  name: string;
  component: JSX.Element;
}

const SelectedServices = (props: any) => {
  const navigate = useNavigate();

  const { planId, currentTab } = useParams<any>();
  const [masterPlanDetails, setMasterPlanDetails] = useState<any>({});
  const [selectedTab, setSelectedTab] = useState<any>(0);

  // //useEffect starts
  useEffect(() => {
    if (planId) {
      getMasterPlan(planId);
    }
  }, [planId]);

  const getMasterPlan = (planId: any) => {
    apiRequest({
      url: `master-plan/${planId}/record`,
      method: "GET",
    })
      .then((res: any) => {
        if (res) {
          setMasterPlanDetails(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate(`/"}`); // Navigate to "/login" route
          }
        }
      });
  };

  const getTabsByCategory = (categories: any) => {
    return categories?.map((category: any, index: number) => ({
      index, // Use the current index from the map function
      id: category,
      name: `${category?.charAt(0)?.toUpperCase() + category?.slice(1)}`,
      component: (
        <SelectedServicesSummary
          planId={planId}
          category={category?.toLowerCase()}
        />
      ),
    }));
  };

  const tabs: TabOption[] = getTabsByCategory(masterPlanDetails?.categories);

  useEffect(() => {
    if (currentTab && tabs?.length) {
      const targetTab: any = tabs?.find((tab: any) => tab?.id === currentTab);
      setSelectedTab(targetTab?.index);
    }
  }, [currentTab]);

  return (
    <Container maxWidth="lg" sx={{ padding: "0 !important" }}>
      <CustomCard sx={{ padding: 2, margin: 1 }}>
        <Box>
          <Link to={`/master/plan-list`} style={{ marginBottom: 2 }}>
            Back
          </Link>
        </Box>
        <Box mt={2}>
          <Typography variant="h6">Selected Services</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>{"Plan details comes here"}</Box>
            </Grid>
            <Grid item xs={12}>
              <CustomTabs
                tabOptions={tabs}
                currentTab={selectedTab}
                onTabChange={(value: any) => {
                  navigate(`/plan/${planId}/selected-services/${value}`);
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </CustomCard>
    </Container>
  );
};

export default SelectedServices;
