import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import AppointmentDetailsCard from "../../components/AppointmentDetailsCard";
import Breadcrumb from "../../components/Breadcrumb";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import IRATypography from "../../components/ira-typography";
import { apiRequest } from "../../app/services/requests";
import { useEffect, useState } from "react";
import NameCell from "../../components/TableComponent/name-cell";
import ActionCell from "../../components/TableComponent/action-cell";
import { IRATableColumnProps } from "../../components/ira-table/ira-table.interfaces";
import { Card, Typography } from "@mui/material";
import { IRATable } from "../../components/ira-table/ira-table";
import CustomButton from "../../components/Button";
import { useAppContext } from "../../app/context/AppContext";

const AppointmentDetails = () => {
  const navigate = useNavigate();
  const { planId } = useParams<any>();
  const [clientMemberList, setClientMemberList] = useState({
    results: [],
    totalCount: 0,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [openCaseHistoryModel, setOpenCaseHistoryModel] = useState({
    open: false,
    data: {} as any,
  });

  const [clientPlanDetails, setClientPlanDetails] = useState("") as any;

  useEffect(() => {
    getCurrentPlanById(planId);
  }, [planId]);

  const getCurrentPlanById = (planId?: any) => {
    apiRequest({
      url: `plan/${planId}`,
      method: "GET",
    })
      .then((res: any) => {
        if (res) {
          setClientPlanDetails(res);
        }
      })
      .catch((err: any) => {});
  };

  useEffect(() => {
    getClientFamilyMembers();
  }, [searchTerm]);

  const getClientFamilyMembers = () => {
    apiRequest({
      url: `plan/${planId}/client-family-members`,
      method: "GET",
    })
      .then((res: any) => {
        if (res) {
          setClientMemberList(res);
        }
      })
      .catch((err) => {});
  };

  const breadcrumbs = [
    <RouterLink to="/Dashboard" style={{ textDecoration: "none" }}>
      <IRATypography
        key="2"
        textVariant="h12WithSemiBold"
        fontSize="12px"
        color="#222222"
      >
        Home
      </IRATypography>
    </RouterLink>,
    <IRATypography
      key="2"
      textVariant="h12WithSemiBold"
      fontSize="12px"
      color="#222222"
    >
      Appointment View
    </IRATypography>,
  ];

  const tableColumns = () =>
    [
      {
        name: "sno",
        label: "S.No",
        component: NameCell,
        sortable: false,
        style: { width: "200px" },
      },
      {
        name: "name",
        label: "Name",
        component: NameCell,
        sortable: false,
        style: { width: "200px" },
      },
      {
        name: "relation",
        label: "Relation",
        component: NameCell,
        sortable: false,
        style: { width: "200px" },
      },
      {
        name: "dob",
        label: "DOB",
        component: NameCell,
        sortable: false,
        style: { width: "200px" },
      },
      {
        name: "gender",
        label: "Gender",
        component: NameCell,
        sortable: false,
        style: { width: "200px" },
      },
      {
        name: "editAction",
        label: "Action",
        component: (props: any) => {
          return ActionCell({
            button: true,
            btnVariant: "primary",
            btnSize: "smallest",
            disabled: false,
            infoMessage: null,
            label: "Add Case",
            onClick: () => {
              const { data = {} as any } = props || {};
              const url = `/cases/client/${data?.clientId}/add`;
              window.open(url, "_blank"); // Opens in a new tab
            },
            ...props,
          } as any);
        },
      } as any,
    ] as Array<IRATableColumnProps>;

  const tableData = () => {
    return clientMemberList?.results?.map((row: any) => {
      return {
        ...row,
        sno: {
          name: row?.id,
        },
        name: {
          name: `${row?.name ?? ""}`,
        },
        dob: {
          name: `${row?.dob}`,
        },
        gender: {
          name: `${row?.gender}`,
        },
        relation: {
          name: `${row?.relation}`,
        },
        caseHistoryStatus: {
          name: `${row?.status}`,
        },
        editAction: {
          data: {
            ...row,
          },
        },
      };
    });
  };

  const { user } = useAppContext() as any;

  return (
    <Container maxWidth="lg" sx={{ padding: "0 !important" }}>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        mb={1}
      >
        <Breadcrumb title={`Appointment View`} breadcrumbsArr={breadcrumbs} />
      </Box>
      <Box>
        <Grid container spacing={2}>
          {/* 3 columns */}
          <Grid item xs={3.5}>
            <Box>
              <AppointmentDetailsCard />
            </Box>
          </Grid>
          {/* 9 columns */}
          <Grid item xs={8.5}>
            <Paper sx={{ padding: "15px", height: "100%" }}>
              <>
                {/* Client Details */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    pt: "10px",
                  }}
                >
                  <Box
                    width={"100%"}
                    bgcolor={"#E1F1FA"}
                    borderRadius={"11px"}
                    padding="16px"
                    display={"flex"}
                    gap={8}
                    justifyContent={"space-evenly"}
                  >
                    <Box display="flex" flexDirection="column" gap={1}>
                      <Typography variant="body1" fontWeight={600}>
                        ID
                      </Typography>
                      <Typography variant="body2">
                        {clientPlanDetails?.client?.id}
                      </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" gap={1}>
                      <Typography variant="body1" fontWeight={600}>
                        Client Name
                      </Typography>
                      <Typography variant="body2">
                        {clientPlanDetails?.client?.firstName}{" "}
                        {clientPlanDetails?.client?.lastName}
                      </Typography>
                    </Box>

                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={1}
                      alignItems="center"
                    >
                      <Typography variant="body1" fontWeight={600}>
                        Action
                      </Typography>
                      <Typography variant="body2">
                        <CustomButton
                          title={"Add Case"}
                          handleButtonClick={() => {
                            const url = `/cases/client/${clientPlanDetails?.client?.id}/add`;
                            window.open(url, "_blank"); // Opens in a new tab
                          }}
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box my={1}>
                  <Typography variant="bold" color="#6A6A6A" fontSize="14px">
                    Family Members
                  </Typography>
                </Box>
                <Card>
                  <IRATable
                    columns={tableColumns()}
                    data={tableData()}
                    onSort={(name: string, order: string) =>
                      alert(`sort requested for ${name} with order ${order}`)
                    }
                  />
                </Card>
              </>
            </Paper>
          </Grid>
        </Grid>
        <></>
      </Box>
    </Container>
  );
};

export default AppointmentDetails;
