import { FormHelperText } from "@mui/material";
import { ChangeEvent } from "react";
import uploadToS3 from "../../app/utilities/uploadToS3";
import { FILE_PATHS } from "../../app/utilities/constants";
import { apiRequest } from "../../app/services/requests";

interface CustomUploadProps {
  key?: string;
  onChange: (file: File | null) => void;
  onBlur?: () => void;
  error?: any;
  helperText?: any;
  onFileUpload?: (url: string) => void;
}

const CustomUpload: React.FC<CustomUploadProps> = ({
  key,
  onChange,
  onBlur,
  helperText,
  onFileUpload,
}) => {
  const { PATIENTS_PATH } = FILE_PATHS;
  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;

    if (file) {
      try {
        const uploadedData: any = await uploadFileToS3(file);
      } catch (error) {
        console.error("Failed to upload file:", error);
        onChange(null);
      }
    } else {
      onChange(null);
    }
  };

  const uploadFileToS3 = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    apiRequest(
      {
        url: `upload`,
        data: formData,
        method: "POST",
      },
      {
        isFileUpload: true,
      }
    )
      .then((res) => {
        onFileUpload?.(res); // Call the callback with the uploaded data
        onChange(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <input
        key={key}
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        onBlur={onBlur}
      />
      {helperText && (
        <p>
          <FormHelperText sx={{ color: "#d32f2f !important" }}>
            {helperText}
          </FormHelperText>
        </p>
      )}
    </div>
  );
};

export default CustomUpload;
