import React, { useEffect, useState } from "react";
import { IRATable } from "../../components/ira-table/ira-table";
import { IRATableColumnProps } from "../../components/ira-table/ira-table.interfaces";
import NameCell from "../../components/TableComponent/name-cell/name-cell";
import ActionCell from "../../components/TableComponent/action-cell";
import Breadcrumb from "../../components/Breadcrumb";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Card, Grid, InputAdornment, Typography } from "@mui/material";
import CustomButton from "../../components/Button";
import { apiRequest } from "../../app/services/requests";
import IRATypography from "../../components/ira-typography/ira-typography";
import CustomInput from "../../components/Input";
import { Close, Search } from "@mui/icons-material";
import Pagination from "../../components/Pagination";

interface ISelectDialog {
  open?: boolean;
  data?: any;
}

const ClientList = (props: any) => {
  const { showOnlyList = true, hideBreadScrum } = props;
  const navigate = useNavigate();
  const [clientList, setClientList] = useState({ results: [], totalCount: 0 });
  const [searchTerm, setSearchTerm] = React.useState("");
  const [limit, setLimit] = useState<any>(10);
  const [skip, setSkip] = useState<any>(0);

  useEffect(() => {
    getAllClients();
  }, [searchTerm, skip, limit]);

  const getAllClients = () => {
    const payload = {
      ...(searchTerm && {
        searchText: searchTerm,
      }),
      limit,
      skip,
    };
    apiRequest({
      url: `client/all`,
      data: payload,
      method: "POST",
    })
      .then((res: any) => {
        if (res) {
          setClientList(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  const clientTableColumns = () =>
    [
      {
        name: "sno",
        label: "S.No",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "clientName",
        label: "Client Name",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "dob",
        label: "DOB",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "mobile",
        label: "Mobile No",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "email",
        label: "Email",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "editAction",
        label: "",
        component: (props: any) => {
          return ActionCell({
            button: true,
            btnVariant: "primary",
            btnSize: "smallest",
            disabled: false,
            infoMessage: null,
            label: "Edit",
            onClick: () => {
              // setOpenSelectClientDialog({ open: true, data: props?.data });
              navigate(`/client/${props?.data?.id}/edit`);
            },
            ...props,
          } as any);
        },
      } as any,
      {
        name: "action",
        label: "Action",
        component: (props: any) => {
          return ActionCell({
            button: true,
            btnVariant: "primary",
            btnSize: "smallest",
            disabled: false,
            infoMessage: null,
            label: "View",
            onClick: () => {
              navigate(`/client/${props?.data?.id}/view/plan-details`);
            },
            ...props,
          } as any);
        },
      } as any,
    ] as Array<IRATableColumnProps>;

  const breadcrumbs = [
    <RouterLink to="/Dashboard" style={{ textDecoration: "none" }} key="1001">
      <IRATypography
        key="2"
        textVariant="h12WithSemiBold"
        sx={{ fontSize: 14, color: "#222222" }}
      >
        Home
      </IRATypography>
    </RouterLink>,
    <IRATypography
      key="3"
      textVariant="h12WithSemiBold"
      sx={{ fontSize: 14, color: "#222222" }}
    >
      Client Profile
    </IRATypography>,
  ];

  const clientTableData = () => {
    return clientList?.results?.map((row: any) => {
      return {
        ...row,
        sno: {
          name: row?.id,
        },
        clientName: {
          name: `${row?.firstName} ${row?.lastName} `,
        },
        dob: {
          name: `${row?.dateOfBirth}`,
        },
        mobile: {
          name: `${row?.mobileNumber}`,
        },
        email: {
          name: `${row?.email}`,
        },
        editAction: {
          data: {
            id: row?.id,
          },
        },
        action: {
          data: {
            id: row?.id,
          },
        },
      };
    });
  };

  const handlePageChange = (page?: any) => {
    const newSkip = page * limit;
    setSkip(newSkip);
  };

  return (
    <>
      {!hideBreadScrum && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            pt: "10px",
          }}
        >
          <Breadcrumb
            title={`Client Profile`}
            key="case-list-breadcrumb"
            breadcrumbsArr={breadcrumbs}
          />
          <Box sx={{ display: "flex", gap: "8px", pt: "20px" }}>
            <CustomButton
              onClick={() => navigate("/client/add")}
              title={"Add Client +"}
            />
          </Box>
        </Box>
      )}

      <Box sx={{ pt: "5px" }}>
        <Card>
          <Grid container sx={{ padding: "10px" }}>
            <Grid item md={3} sx={{ background: "#F0F2F6", pb: "10px" }}>
              <CustomInput
                size={"small"}
                placeholder="Search by Client"
                placeholderColor="#204289"
                placeholderColorOpacity={1}
                placeholderSize={15}
                value={searchTerm}
                onChange={(event: any) => setSearchTerm(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      {searchTerm.length > 0 && (
                        <InputAdornment
                          sx={{ cursor: "pointer" }}
                          position="end"
                          onClick={() => setSearchTerm("")}
                        >
                          <Close />
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
            </Grid>

            <Grid />

            <IRATable
              columns={clientTableColumns()}
              data={clientTableData()}
              onSort={(name: string, order: string) =>
                alert(`sort requested for ${name} with order ${order}`)
              }
            />

            <Pagination
              onPageChange={(data: any) => {
                handlePageChange(data);
              }}
              onRowsPerPageChange={(limit?: number, skip?: number) => {
                setLimit(limit);
                setSkip(0);
              }}
              totalCount={clientList?.totalCount}
            />
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default React.memo(ClientList);
