import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "../../../app/services/requests";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import CustomSpinner from "../../../components/Spinner";
import axios from "axios";
import {
  BACKEND_SERVER_URL,
  COUNTRY_LIST,
  GENDER_DATA,
  MARTIAL_STATUS1,
  ONBOARD_ROLES,
} from "../../../app/utilities/constants";
import CustomSelectPicker from "../../../components/SelectPicker";
import CustomButton from "../../../components/Button";
import CustomInput from "../../../components/Input";
import FileUploader from "../../../components/FileUploader/file-upload";
import CustomAutocomplete from "../../../components/Autocomplete";
import StatesAndCities from "../../../app/utilities/StatesAndCities.json";
import CustomCheckbox from "../../../components/CustomCheckBox";
import { getOnlyDate, getTrimmedString } from "../../../app/utilities/utils";
import { useSnackbar } from "../../../app/context/useSnackbar";

interface LeadData {
  userType: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  message: string;
}

interface IDocuments {
  key: string;
  originalname: string;
  url: string;
  type: string;
}

interface IDocumentTypes {
  aadharDocument: IDocuments | null;
  panDocument: IDocuments | null;
}

export const OnboardingStep2 = () => {
  const { leadId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("t");
  const jwtToken = queryParams.get("jt");
  const authorizationToken = {
    Authorization: `Bearer ${jwtToken}`,
  };
  const { openSnackbar } = useSnackbar() as any;
  const navigate = useNavigate();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState<IDocumentTypes>({
    aadharDocument: null,
    panDocument: null,
  });
  const [cities, setCities] = useState<any>([]);
  const [statesAndCities] = useState<any>(StatesAndCities);
  const [leadData, setLeadData] = useState<LeadData | null>(null);
  const [awardsAndRewardsDetails, setAwardsAndRewardsDetails] = useState([
    { awardsAndRewards: "" },
  ]);
  const [trainingsAndCoursesDetails, setTrainingsAndCoursesDetails] = useState([
    { trainingsAndCourses: "" },
  ]);

  const [uploadEducationCertificates, setUploadEducationCertificates] =
    useState<any>({
      educationCertificatesResponseFiles: [],
    });
  const [workExperienceCertificates, setWorkExperienceCertificates] =
    useState<any>({
      workExperienceCertificatesResponseFiles: [],
    });

  const { control, handleSubmit, getValues, setValue, watch, reset } =
    useForm<any>();

  const fetchData = async () => {
    if (!leadId) return;
    // get lead details
    await axios({
      url: `${BACKEND_SERVER_URL}/v1/lead/${leadId}/record/${token}`,
      method: "GET",
      headers: authorizationToken,
    })
      .then((res: any) => {
        console.log("res", res);
        setLeadData(res?.data);
        reset({
          useType: res?.data?.userType,
          firstName: res?.data?.firstName,
          lastName: res?.data?.lastName,
          email: res?.data?.email,
          mobile: res?.data?.mobile,
          message: res?.data?.message,
        });
        setDataLoaded(true);
      })
      .catch((err) => {
        // console.error(err);
        openSnackbar(err, "error");
      });
  };

  const addAwardsAndRewards = () => {
    setAwardsAndRewardsDetails([
      ...awardsAndRewardsDetails,
      { awardsAndRewards: "" },
    ]);
  };
  const addTrainingsAndCourses = () => {
    setTrainingsAndCoursesDetails([
      ...trainingsAndCoursesDetails,
      { trainingsAndCourses: "" },
    ]);
  };

  const { remove: deleteAwardsAndRewards } = useFieldArray({
    control,
    name: "awardsAndRewardsDetails",
  });

  const { remove: deleteTrainingsAndCourses } = useFieldArray({
    control,
    name: "trainingsAndCoursesDetails",
  });

  const removeAwardsAndRewards = (index: number) => {
    const modifiedAwardsAndRewards = [...awardsAndRewardsDetails];
    modifiedAwardsAndRewards.splice(index, 1);
    setAwardsAndRewardsDetails(modifiedAwardsAndRewards);
    deleteAwardsAndRewards(index);
  };

  const removeTrainingsAndCourses = (index: number) => {
    const trainingsAndCourses = [...trainingsAndCoursesDetails];
    trainingsAndCourses.splice(index, 1);
    setTrainingsAndCoursesDetails(trainingsAndCourses);
    deleteTrainingsAndCourses(index);
  };

  const deleteEducationCertificates = (index: number) => {
    const updatedCertificates = { ...uploadEducationCertificates };
    const updatedFiles = [
      ...(updatedCertificates.educationCertificatesResponseFiles || []),
    ];
    updatedFiles.splice(index, 1);
    updatedCertificates.educationCertificatesResponseFiles = updatedFiles;
    setUploadEducationCertificates(updatedCertificates);
  };

  const deleteWorkExperienceCertificates = (index: any) => {
    const updatedCertificates = {
      ...workExperienceCertificates,
      coverImagesResponseFiles: [
        ...workExperienceCertificates?.workExperienceCertificatesResponseFiles,
      ],
    };
    workExperienceCertificates?.workExperienceCertificatesResponseFiles.splice(
      index,
      1
    );
    setWorkExperienceCertificates(updatedCertificates);
  };

  const onSubmit = (formData: any) => {
    const modifiedData = {
      // clinic
      ...(leadData?.userType === "clinic" && {
        name: formData?.hospitalName,
        registrationNumber: formData?.registrationNumber,
        contactPersonName: leadData?.firstName + leadData?.lastName,
        contactPersonRole: "NA",
        contactPersonMobileNumber: leadData?.mobile,
        timings: {
          friday: "9:00 AM - 5:00 PM",
          monday: "9:00 AM - 5:00 PM",
          sunday: "Closed",
          tuesday: "9:00 AM - 5:00 PM",
          saturday: "9:00 AM - 1:00 PM",
          thursday: "9:00 AM - 5:00 PM",
          wednesday: "9:00 AM - 5:00 PM",
        },
      }),
      // agent
      ...(leadData?.userType === "agent" && {
        userType: leadData?.userType,
        maritalStatus: formData?.maritalStatus,
        dateOfBirth: getOnlyDate(formData?.dateOfBirth),
        mobileNumber: leadData?.mobile,
        gender: formData?.gender,
        lastName: leadData?.lastName,
        firstName: leadData?.firstName,
        alternateMobileNumber: formData?.alternateMobileNumber,
        dentalCouncilNumber: formData?.dentalCouncilNumber,
        graduationYear: formData?.graduationYear,
        graduationInstitute: formData?.graduationInstitute,
        postGraduationYear: formData?.postGraduationYear,
        postGraduationInstitute: formData?.postGraduationInstitute,
        experienceInYears: formData?.experienceInYears,
        awardsAndRewards: formData?.awardsAndRewardsDetails?.map(
          (item: any) => item?.awardsAndRewards
        ),
        trainingsAndCourses: formData?.trainingsAndCoursesDetails?.map(
          (item: any) => item?.trainingsAndCourses
        ),
        educationCertificates:
          uploadEducationCertificates?.educationCertificatesResponseFiles?.map(
            (item: any) => item?.url
          ),
        workExperienceCertificates:
          workExperienceCertificates?.workExperienceCertificatesResponseFiles?.map(
            (item: any) => item?.url
          ),
        // achievementCertificates:
        //   achievementCertificates?.achievementCertificatesResponseFiles?.map(
        //     (item: any) => item?.url
        //   ),

        // additionalCertificates:
        //   additionalCertificates?.additionalCertificatesResponseFiles?.map(
        //     (item: any) => item?.url
        //   ),
      }),
      panDocumentUrl: uploadedDocuments?.panDocument?.url,
      adhaarDocumentUrl: uploadedDocuments?.aadharDocument?.url,
      email: leadData?.email,
      address: {
        addressLine1: formData?.addressLine1,
        addressLine2: formData?.addressLine2,
        state: formData?.state,
        city: formData?.city,
        country: formData?.country,
        zipCode: formData?.zipCode,
      },
      // createdById: user?.data?.id,
      // otherDocuments: [""],
      // bdsFile: bdsMdsDocs?.bdsFile?.url,
      // mdsFile: bdsMdsDocs?.mdsFile?.url,
    };

    apiRequest(
      {
        url: leadData?.userType === "agent" ? `user/create` : "clinic",
        data: modifiedData,
        method: "POST",
      },
      authorizationToken
    )
      .then((res: any) => {
        if (res) {
          navigate("/login");
          openSnackbar(
            `${
              leadData?.userType === "agent" ? "User" : "Clinic"
            } Created Successfully`,
            "success"
          );
        }
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  const onReset = () => {
    // setValue("firstName", "");
    // setValue("lastName", "");
    // setValue("email", "");
    // setValue("mobile", "");
    // setValue("message", "");
  };

  const handleFileChange = (file: IDocuments, type: string) => {
    setUploadedDocuments((prevStepDetails: any) => {
      return {
        ...prevStepDetails,
        [type]: file,
      };
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!dataLoaded) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CustomSpinner />
      </Box>
    );
  }
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box p={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="userType"
            control={control}
            disabled
            render={({ field, fieldState: { error } }) => (
              <CustomSelectPicker
                {...field}
                value={leadData?.userType}
                error={!!error}
                helperText={error ? error.message : ""}
                label={"Select User Role"}
                size={"small"}
                fullWidth={true}
                width={"100%"}
                selectData={ONBOARD_ROLES}
                noneRequired={false}
              />
            )}
          />
          <Box display="flex" flexDirection={"column"} gap={"16px"} mt={2}>
            <Controller
              name="firstName"
              control={control}
              disabled
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Enter First Name"}
                  InputProps={""}
                  size={"small"}
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              disabled
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Enter Last Name"}
                  InputProps={""}
                  size={"small"}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              disabled
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Enter Email"}
                  InputProps={""}
                  size={"small"}
                />
              )}
            />
            <Controller
              name="mobile"
              control={control}
              disabled
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Enter Mobile Number"}
                  InputProps={""}
                  size={"small"}
                />
              )}
            />
            <Controller
              name="message"
              control={control}
              disabled
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Enter Message"}
                  InputProps={""}
                  size={"small"}
                />
              )}
            />
            {leadData?.userType === "agent" && (
              <>
                <Box display="flex" alignItems="center" gap={10}>
                  <Box display="flex" gap={1} alignItems="center">
                    <Typography
                      variant="regular"
                      style={{
                        // paddingTop: "25px",
                        marginRight: "7px",
                        textAlign: "center",
                      }}
                    >
                      Sex :
                    </Typography>
                    <Controller
                      name={`gender`}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl component="fieldset">
                          <RadioGroup {...field} row>
                            {GENDER_DATA.map((option: any, idx: any) => (
                              <FormControlLabel
                                key={idx}
                                value={option.id}
                                control={<Radio />}
                                label={option.name}
                              />
                            ))}
                          </RadioGroup>
                          {error && (
                            <FormHelperText
                              sx={{ color: "#d32f2f !important" }}
                            >
                              {error.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Box>
                  <Box display="flex" gap={1} alignItems="center">
                    <Typography
                      variant="regular"
                      style={{
                        // paddingTop: "25px",
                        marginRight: "7px",
                        textAlign: "center",
                      }}
                    >
                      Marital Status :
                    </Typography>
                    <Controller
                      name={`maritalStatus`}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl component="fieldset">
                          <RadioGroup {...field} row>
                            {MARTIAL_STATUS1.map((option: any, idx: any) => (
                              <FormControlLabel
                                key={idx}
                                value={option.id}
                                control={<Radio />}
                                label={option.name}
                              />
                            ))}
                          </RadioGroup>
                          {error && (
                            <FormHelperText
                              sx={{ color: "#d32f2f !important" }}
                            >
                              {error.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" gap={10}>
                  <Typography
                    variant="regular"
                    style={{
                      // paddingTop: "25px",
                      marginRight: "7px",
                      textAlign: "center",
                    }}
                  >
                    Aadhar Card :
                  </Typography>
                  <FileUploader
                    uploadText="Upload Aadhar Card"
                    uploadType="aadharDocument"
                    handleUpload={(file: IDocuments) =>
                      handleFileChange(file, "aadharDocument")
                    }
                    accept={["image/*", "application/pdf"]}
                    token={jwtToken}
                  />
                  {uploadedDocuments?.aadharDocument?.url && (
                    <Typography variant="body2">
                      {getTrimmedString(
                        uploadedDocuments?.aadharDocument?.url,
                        20
                      )}
                    </Typography>
                  )}
                  <Typography
                    variant="regular"
                    style={{
                      // paddingTop: "25px",
                      marginRight: "7px",
                      textAlign: "center",
                    }}
                  >
                    Pan Card :
                  </Typography>
                  <FileUploader
                    uploadText="Upload Pan Card"
                    uploadType="panDocument"
                    handleUpload={(file: IDocuments) =>
                      handleFileChange(file, "panDocument")
                    }
                    accept={["image/*", "application/pdf"]}
                    token={jwtToken}
                  />
                  {uploadedDocuments?.panDocument?.url && (
                    <Typography variant="body2">
                      {getTrimmedString(
                        uploadedDocuments?.panDocument?.url,
                        20
                      )}
                    </Typography>
                  )}
                </Box>
                <Box display="flex" justifyContent="flex-start">
                  <Typography
                    variant="regular"
                    style={{
                      // paddingTop: "25px",
                      marginRight: "7px",
                      textAlign: "center",
                    }}
                  >
                    Career Details :
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={5} flexWrap="wrap">
                  <Controller
                    name="graduationYear"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : ""}
                        label={"Enter Graduation Year"}
                        InputProps={""}
                        size={"small"}
                      />
                    )}
                  />
                  <Controller
                    name="graduationInstitute"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : ""}
                        label={"Enter Graduation Institute"}
                        InputProps={""}
                        size={"small"}
                      />
                    )}
                  />
                  <Controller
                    name="postGraduationYear"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : ""}
                        label={"Enter Post Graduation Year"}
                        InputProps={""}
                        size={"small"}
                      />
                    )}
                  />
                  <Controller
                    name="postGraduationInstitute"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : ""}
                        label={"Enter Post Graduation Institute"}
                        InputProps={""}
                        size={"small"}
                      />
                    )}
                  />
                  <Controller
                    name="experienceInYears"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : ""}
                        label={"Enter Experience In Years"}
                        InputProps={""}
                        size={"small"}
                      />
                    )}
                  />
                </Box>
                <Box display="flex" alignItems="center" gap={5}>
                  <Controller
                    name="hasOrganizationMembership"
                    control={control}
                    render={({ field }) => (
                      <CustomCheckbox
                        {...field}
                        label="Has Organization Membership"
                      />
                    )}
                  />
                </Box>
                <Box display="flex" alignItems="center" gap={5}>
                  {awardsAndRewardsDetails.map((item, index) => {
                    return (
                      <Box
                        key={index}
                        mt={1}
                        display={"flex"}
                        gap={"10px"}
                        alignItems={"top"}
                        justifyContent={"center"}
                      >
                        <Box width={"100%"}>
                          <Controller
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                {...field}
                                label={"Enter Awards and Rewards Points"}
                                type={"text"}
                                error={!!error}
                                helperText={error ? error.message : ""}
                              />
                            )}
                            name={`awardsAndRewardsDetails.${index}.awardsAndRewards`}
                            control={control}
                          />
                        </Box>
                        <Box>
                          {index < 1 && (
                            <Button
                              sx={{
                                border: "1px solid #5C4EFF",
                                fontSize: "12px",
                                color: "#5C4EFF",
                              }}
                              onClick={() => addAwardsAndRewards()}
                            >
                              Add
                            </Button>
                          )}
                          {index > 0 && (
                            <Button
                              sx={{
                                border: "1px solid #5C4EFF",
                                color: "#5C4EFF",
                                fontSize: "12px",
                              }}
                              onClick={() => removeAwardsAndRewards(index)}
                            >
                              Delete
                            </Button>
                          )}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
                <Box display="flex" alignItems="center" gap={5}>
                  {trainingsAndCoursesDetails.map((item, index) => {
                    return (
                      <Box
                        key={index}
                        mt={1}
                        display={"flex"}
                        gap={"10px"}
                        alignItems={"top"}
                        justifyContent={"center"}
                      >
                        <Box width={"100%"}>
                          <Controller
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                {...field}
                                label={"Enter Training And Courses"}
                                type={"text"}
                                error={!!error}
                                helperText={error ? error.message : ""}
                              />
                            )}
                            name={`trainingsAndCoursesDetails.${index}.trainingsAndCourses`}
                            control={control}
                          />
                        </Box>
                        <Box>
                          {index < 1 && (
                            <Button
                              sx={{
                                border: "1px solid #5C4EFF",
                                fontSize: "12px",
                                color: "#5C4EFF",
                              }}
                              onClick={() => addTrainingsAndCourses()}
                            >
                              Add
                            </Button>
                          )}
                          {index > 0 && (
                            <Button
                              sx={{
                                border: "1px solid #5C4EFF",
                                color: "#5C4EFF",
                                fontSize: "12px",
                              }}
                              onClick={() => removeTrainingsAndCourses(index)}
                            >
                              Delete
                            </Button>
                          )}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
                <Box display="flex" alignItems="center">
                  <FileUploader
                    key={"educationCertificates"}
                    uploadAadharCard={uploadEducationCertificates}
                    setUploadEducationCertificates={
                      setUploadEducationCertificates
                    }
                    accept={"image/png,image/jpeg,image/jpg"}
                    displayType={"educationCertificates"}
                    uploadType={"educationCertificates"}
                    uploadText={"Upload Education Certificate"}
                    token={jwtToken}
                  />
                  {Boolean(
                    uploadEducationCertificates
                      ?.educationCertificatesResponseFiles?.length
                  ) &&
                    uploadEducationCertificates
                      ?.educationCertificatesResponseFiles?.length && (
                      <Box display={"flex"} gap={"10px "} flexWrap={"wrap"}>
                        {uploadEducationCertificates?.educationCertificatesResponseFiles?.map(
                          (cover: any, index: any) => {
                            return (
                              <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                gap={"30px"}
                                bgcolor={"#D5D5D5"}
                                padding={"5px 10px"}
                                borderRadius={"5px"}
                                fontSize={"10px"}
                                mt={2}
                              >
                                {index + 1} {cover?.originalname}
                                <Typography
                                  component={"span"}
                                  sx={{ cursor: "pointer" }}
                                  onClick={() =>
                                    deleteEducationCertificates(index)
                                  }
                                  fontSize={"12px"}
                                >
                                  X
                                </Typography>
                              </Box>
                            );
                          }
                        )}
                      </Box>
                    )}
                </Box>
                <Box display="flex" alignItems="center" gap={5} flexWrap="wrap">
                  <FileUploader
                    key={"workExperienceCertificates"}
                    uploadAadharCard={workExperienceCertificates}
                    setWorkExperienceCertificates={
                      setWorkExperienceCertificates
                    }
                    accept={"image/png,image/jpeg,image/jpg"}
                    displayType={"workExperienceCertificates"}
                    uploadType={"workExperienceCertificates"}
                    uploadText={"Upload Work Experience Certificates"}
                    token={jwtToken}
                  />
                  {Boolean(
                    workExperienceCertificates
                      ?.workExperienceCertificatesResponseFiles?.length
                  ) &&
                    workExperienceCertificates
                      ?.workExperienceCertificatesResponseFiles?.length && (
                      <Box display={"flex"} gap={"10px "} flexWrap={"wrap"}>
                        {workExperienceCertificates?.workExperienceCertificatesResponseFiles?.map(
                          (cover: any, index: any) => {
                            return (
                              <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                gap={"30px"}
                                bgcolor={"#D5D5D5"}
                                padding={"5px 10px"}
                                borderRadius={"5px"}
                                fontSize={"10px"}
                                mt={2}
                              >
                                {index + 1} {cover?.originalname}
                                <Typography
                                  component={"span"}
                                  sx={{ cursor: "pointer" }}
                                  onClick={() =>
                                    deleteWorkExperienceCertificates(index)
                                  }
                                  fontSize={"12px"}
                                >
                                  X
                                </Typography>
                              </Box>
                            );
                          }
                        )}
                      </Box>
                    )}
                </Box>
              </>
            )}
            {leadData?.userType === "clinic" && (
              <>
                <Box display="flex" justifyContent="flex-start">
                  <Typography
                    variant="regular"
                    style={{
                      // paddingTop: "25px",
                      marginRight: "7px",
                      textAlign: "center",
                    }}
                  >
                    Hospital Details :
                  </Typography>
                </Box>
                <Controller
                  name="hospitalName"
                  control={control}
                  disabled
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      width={"250px"}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      label={"Enter Hospital Name"}
                      InputProps={""}
                      size={"small"}
                    />
                  )}
                />
                <Controller
                  name="registrationNumber"
                  control={control}
                  disabled
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      width={"250px"}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      label={"Enter Hospital Regd No"}
                      InputProps={""}
                      size={"small"}
                    />
                  )}
                />
              </>
            )}
            <Box display="flex" justifyContent="flex-start">
              <Typography
                variant="regular"
                style={{
                  // paddingTop: "25px",
                  marginRight: "7px",
                  textAlign: "center",
                }}
              >
                Address for communication :
              </Typography>
            </Box>
            <Box display="flex" gap={4} width={"100%"} flexWrap={"wrap"}>
              <Controller
                name="addressLine1"
                control={control}
                disabled
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : ""}
                    label={"Enter Address Line 1"}
                    width={"250px"}
                    InputProps={""}
                    size={"small"}
                  />
                )}
              />
              <Controller
                name="addressLine2"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : ""}
                    label={"Enter Address Line 2"}
                    width={"250px"}
                    InputProps={""}
                    size={"small"}
                  />
                )}
              />
              <Controller
                name="zipCode"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : ""}
                    label={"Zip code"}
                    width={"250px"}
                    size={"small"}
                    type={"number"}
                  />
                )}
              />
              <Controller
                name="country"
                defaultValue={"India"}
                control={control}
                rules={{ required: true }}
                render={({
                  field: { ref, onChange, ...field },
                  fieldState: { error },
                }) => (
                  <CustomAutocomplete
                    field={field}
                    width={200}
                    disabled
                    error={!!error}
                    helperText={error ? error.message : ""}
                    ref={ref}
                    singleSelection
                    onChange={(event: React.SyntheticEvent, value: any) =>
                      onChange(value)
                    }
                    options={COUNTRY_LIST}
                    value={getValues("country")}
                    label="Country"
                    defaultValue={"India"}
                  />
                )}
              />
              <Controller
                name="state"
                control={control}
                defaultValue={getValues("state")}
                rules={{ required: true }}
                render={({
                  field: { ref, onChange, ...field },
                  fieldState: { error },
                }) => (
                  <CustomAutocomplete
                    field={field}
                    error={!!error}
                    helperText={error ? error.message : ""}
                    ref={ref}
                    value={getValues("state")}
                    singleSelection
                    onChange={(event: React.SyntheticEvent, value: any) => {
                      onChange(value);
                      setValue("city", "");
                      setCities(statesAndCities[value] || []);
                    }}
                    options={Object.keys(statesAndCities)}
                    label="State"
                  />
                )}
              />
              <Controller
                name="city"
                control={control}
                defaultValue={getValues("city")}
                rules={{ required: true }}
                render={({
                  field: { ref, onChange, ...field },
                  fieldState: { error },
                }) => (
                  <CustomAutocomplete
                    field={field}
                    error={!!error}
                    helperText={error ? error.message : ""}
                    ref={ref}
                    value={getValues("city")}
                    singleSelection
                    onChange={(event: React.SyntheticEvent, value: any) => {
                      onChange(value);
                      setValue("city", value);
                    }}
                    options={cities}
                    label="City"
                  />
                )}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <CustomButton
                title={"Reset"}
                marginRight={"5px"}
                bgColor={"rgba(255, 225, 229, 1)"}
                width={"100px"}
                color={"rgba(255, 97, 116, 1)"}
                borderColor={"rgba(255, 97, 116, 1)"}
                borderWidth={"1px"}
                borderStyle={"solid"}
                onClick={onReset}
              />
              <Box marginLeft={1}>
                <CustomButton
                  type={"submit"}
                  title={"Save"}
                  marginRight={"5px"}
                  bgColor={"rgba(92, 78, 255, 1)"}
                  width={"100px"}
                />
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
