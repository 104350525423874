import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import styles from "../../Login/login.module.css";
import BgImage from "../../../app/assets/Dashboard/login.jpg";
import Logo from "../../../app/assets/logo.png";
import { theme } from "../../../theme/theme";
import { Controller, useForm } from "react-hook-form";
import CustomSelectPicker from "../../../components/SelectPicker";
import { ONBOARD_ROLES } from "../../../app/utilities/constants";
import { string, object } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "../../../components/Input";
import CustomButton from "../../../components/Button";
import { apiRequest } from "../../../app/services/requests";
import { useSnackbar } from "../../../app/context/useSnackbar";

const schema = object({
  userType: string().required("Required"),
  firstName: string().required("Required"),
  lastName: string().required("Required"),
  email: string().required("Email is required"),
  mobile: string()
    .required("Mobile number is required")
    .matches(
      /^[0-9]{10,15}$/,
      "Mobile number must be between 10 and 15 digits"
    ),
  message: string().required("Message required"),
});

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  userType: string;
  message: string;
};

export const OnboardingStep1 = () => {
  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    userType: "",
    message: "",
  };
  const { openSnackbar } = useSnackbar();
  const { control, handleSubmit, getValues, setValue, watch } =
    useForm<FormValues>({
      resolver: yupResolver(schema),
      defaultValues: defaultValues,
    });
  const watchUserType = watch("userType");
  const onSubmit = async (data: FormValues) => {
    console.log(data);
    const response: any = await apiRequest({
      url: `lead/add`,
      data,
      method: "POST",
    });
    openSnackbar(
      response ? "Successfully Data updated" : "Something went Wrong",
      ` ${response ? "success" : "error"} `
    );
    onReset();
    console.log("added", response);
  };
  const onReset = () => {
    setValue("firstName", "");
    setValue("lastName", "");
    setValue("email", "");
    setValue("mobile", "");
    setValue("message", "");
  };
  return (
    <>
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f8f9fc",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" }, // Column in mobile, row in desktop
            width: "100%",
            maxWidth: "100%",
            alignItems: { xs: "center" },
            height: { xs: "100vh", md: "100vh" },
            borderRadius: "12px",
            overflow: "hidden",
            backgroundColor: "white",
          }}
        >
          {/* Left Section (Background + Logo) */}
          <Box
            sx={{
              width: { xs: "100%", md: "63%" }, // Full width in mobile, 63% in desktop
              height: { xs: "100vh", md: "90vh" }, // 40vh in mobile, auto in desktop
              backgroundImage: `url(${BgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-start" },
              justifyContent: "flex-start",
              padding: "20px",
              borderRadius: { xs: "0px", md: "30px" },
              margin: { xs: "0px", md: "16px" },
            }}
          >
            <img src={Logo} alt="Logo" style={{ width: "240px" }} />
          </Box>
          {/* Right Section (Sign-in Form) */}
          <Box
            sx={{
              width: { xs: "68%", md: "37%" }, // Full width in mobile, 37% in desktop
              display: "flex",
              position: { xs: "absolute", md: "relative" },
              top: { xs: "24%", md: "0" },
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "40px",
              borderRadius: { xs: "20px" },
              backgroundColor: { xs: "rgba(255, 255, 255, 0.81)", md: "white" }, // Add transparency in mobile
            }}
          >
            <Box sx={{ width: { xs: "100%", md: "70%" } }}>
              <Typography
                variant="h5"
                fontWeight={600}
                textAlign="center"
                mb={3}
              >
                Onboarding
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="userType"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomSelectPicker
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      label={"Select User Role"}
                      size={"small"}
                      fullWidth={true}
                      width={"100%"}
                      selectData={ONBOARD_ROLES}
                      noneRequired={false}
                    />
                  )}
                />
                {watchUserType && (
                  <Box
                    display="flex"
                    flexDirection={"column"}
                    gap={"16px"}
                    mt={2}
                  >
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          label={"Enter First Name"}
                          InputProps={""}
                          size={"small"}
                        />
                      )}
                    />
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          label={"Enter Last Name"}
                          InputProps={""}
                          size={"small"}
                        />
                      )}
                    />
                    <Controller
                      name="email"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          label={"Enter Email"}
                          InputProps={""}
                          size={"small"}
                        />
                      )}
                    />
                    <Controller
                      name="mobile"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          label={"Enter Mobile Number"}
                          InputProps={""}
                          size={"small"}
                        />
                      )}
                    />
                    <Controller
                      name="message"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          label={"Enter Message"}
                          InputProps={""}
                          size={"small"}
                        />
                      )}
                    />
                    <Box display="flex" justifyContent="flex-end" mt={2}>
                      <CustomButton
                        title={"Reset"}
                        marginRight={"5px"}
                        bgColor={"rgba(255, 225, 229, 1)"}
                        width={"100px"}
                        color={"rgba(255, 97, 116, 1)"}
                        borderColor={"rgba(255, 97, 116, 1)"}
                        borderWidth={"1px"}
                        borderStyle={"solid"}
                        onClick={onReset}
                      />
                      <Box marginLeft={1}>
                        <CustomButton
                          type={"submit"}
                          title={"Save"}
                          marginRight={"5px"}
                          bgColor={"rgba(92, 78, 255, 1)"}
                          width={"100px"}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OnboardingStep1;
