import React, { useState, useEffect } from "react";
import { Container, Typography, Paper, Box } from "@mui/material";
import "./dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import CustomDialog from "../../components/CustomDialog";
import BookAppointmentForm from "../Appointment/book-appointment-form";
import SelectClient from "../../components/SelectClient";
import CustomCard from "../../components/Card";
import CaseList from "../CasesAndClaims/case-list";
import QueryList from "../Query/query-list";
import { theme } from "../../theme/theme";
import { useAppContext } from "../../app/context/AppContext";
import DashboardCounters from "./dashboard-counters";
import PanelDoctorDashboard from "./panel-doctor-dashboard";
import SrPanelDoctorDashboard from "./sr-panel-doctor-dashboard";
import RequestList from "../Requests/request-list";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const LocalAdminDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [isFlipped, setFlipped] = useState(false);
  const [isFlipped1, setFlipped1] = useState(false);
  const [isFlipped2, setFlipped2] = useState(false);
  const [isFlipped3, setFlipped3] = useState(false);
  const [isFlipped4, setFlipped4] = useState(false);
  const [isFlipped5, setFlipped5] = useState(false);
  const [openSelectClientDialog, setOpenSelectClientDialog] = useState<any>({
    open: false,
    data: {},
  });

  const { user } = useAppContext() as any;

  const [openBookAppointmentDialog, setOpenBookAppointmentDialog] =
    useState<any>({
      open: false,
      data: {},
    });

  const handleLinkClick = (type?: string) => {
    if (type === "bookAppointment") {
      setOpenBookAppointmentDialog({ open: true, data: { type } });
    } else {
      setOpenSelectClientDialog({ open: true, data: { type } });
    }
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderCards = (role?: string) => {
    return (
      <>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap" // Add flexWrap to wrap content to the next row
          justifyContent="space-between" // Adjust spacing between cards
          gap={2}
        >
          <Paper
            onMouseEnter={() => {
              setFlipped(false);
              setFlipped1(false);
              setFlipped2(false);
              setFlipped3(true);
            }}
            onMouseLeave={() => setFlipped3(false)}
            className={`flip-card ${isFlipped3 ? "flipped" : ""}`}
            elevation={3}
            sx={{
              p: 2,
              flexBasis: "30%",
              flexGrow: 1,
              background:
                "linear-gradient(rgba(184, 178, 255, 1), rgba(255, 227, 247, 1))",
              borderRadius: "15px",
              height: "170px",
            }}
          >
            <Box className="flip-card-inner">
              <Box className="flip-card-front">
                <Box
                  component="img"
                  src={require("../../app/assets/Dashboard/hospital_profile.png")}
                ></Box>
                <Typography variant="h6">Clinic</Typography>
              </Box>

              {isFlipped3 ? (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  height="100%"
                  flexWrap="wrap"
                  className="flip-card-back"
                >
                  <Link
                    to="/hospital/list"
                    style={{
                      marginBottom: 2,
                      textDecoration: "none",
                      fontSize: "16px",
                      color: theme.palette.customColors.main,
                    }}
                  >
                    Clinic List
                  </Link>

                  <Link
                    to="/hospital/add"
                    style={{
                      marginBottom: 2,
                      textDecoration: "none",
                      fontSize: "16px",
                      color: theme.palette.customColors.main,
                    }}
                  >
                    Add Clinic
                  </Link>
                </Box>
              ) : null}
            </Box>
          </Paper>

          {/* Client */}
          <Paper
            onMouseEnter={() => {
              setFlipped1(true);
              setFlipped(false);
              setFlipped2(false);
              setFlipped3(false);
              setFlipped4(false);
              setFlipped5(false);
            }}
            onMouseLeave={() => setFlipped1(false)}
            className={`flip-card ${isFlipped1 ? "flipped" : ""}`}
            elevation={3}
            sx={{
              p: 2,
              flexBasis: "30%",
              flexGrow: 1,
              background:
                "linear-gradient(rgba(227, 227, 255, 1), rgba(255, 229, 231, 1))",
              borderRadius: "15px",
              height: "170px",
            }}
          >
            <Box className="flip-card-inner">
              <Box className="flip-card-front">
                <Box
                  component="img"
                  src={require("../../app/assets/Dashboard/client_enrollment.png")}
                ></Box>
                <Typography variant="h6">Client Enrollment</Typography>
              </Box>

              {isFlipped1 ? (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  height="100%"
                  flexWrap="wrap"
                  className="flip-card-back"
                >
                  <Box display="flex" flexDirection="column">
                    <Link
                      to="/client/add"
                      style={{ marginBottom: 2, textDecoration: "none" }}
                    >
                      New Client
                    </Link>
                    <Box
                      onClick={() => handleLinkClick("renewal")}
                      style={{ marginBottom: 2, cursor: "pointer" }}
                    >
                      Renew Plan
                    </Box>
                    <Box
                      onClick={() => handleLinkClick("upgrade-renewal")}
                      style={{ marginBottom: 2, cursor: "pointer" }}
                    >
                      Upgrade & Renewal Plan
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Box
                      onClick={() => handleLinkClick("upgrade")}
                      style={{ marginBottom: 2, cursor: "pointer" }}
                    >
                      Upgrade Plan
                    </Box>
                    <Box
                      onClick={() => handleLinkClick("cancel")}
                      style={{ marginBottom: 2, cursor: "pointer" }}
                    >
                      Cancel Plan
                    </Box>
                    <Link
                      to="/client/list"
                      style={{ marginBottom: 2, textDecoration: "none" }}
                    >
                      Client List
                    </Link>
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Paper>

          {/* Reports */}
          <Paper
            onMouseEnter={() => {
              setFlipped5(true);
              setFlipped1(false);
              setFlipped(false);
              setFlipped2(false);
              setFlipped3(false);
              setFlipped4(false);
            }}
            onMouseLeave={() => setFlipped5(false)}
            className={`flip-card ${isFlipped5 ? "flipped" : ""}`}
            elevation={3}
            sx={{
              p: 2,
              flexBasis: "30%",
              flexGrow: 1,
              background:
                "linear-gradient(rgba(245, 163, 174, 1), rgba(205, 225, 249, 1))",
              borderRadius: "15px",
              height: "170px",
            }}
          >
            <Box className="flip-card-inner">
              <Box className="flip-card-front">
                <Box
                  component="img"
                  src={require("../../app/assets/Dashboard/reports.png")}
                ></Box>
                <Typography variant="h6">Reports</Typography>
              </Box>

              {isFlipped5 ? (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  height="100%"
                  flexWrap="wrap"
                  className="flip-card-back"
                >
                  <Box display="flex" flexDirection="column">
                    <Link
                      to="/reports/cases-and-claims"
                      style={{ marginBottom: 2 }}
                    >
                      Cases & Claims Reports
                    </Link>
                    <Link to="/reports/treatments" style={{ marginBottom: 2 }}>
                      Treatment Reports
                    </Link>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Link
                      to="/reports/approval-and-applied"
                      style={{ marginBottom: 2 }}
                    >
                      Applied & Approval Reports
                    </Link>
                    <Link to="/reports/appointments">Appointment Reports</Link>
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Paper>

          {openSelectClientDialog.open && (
            // <CustomAutocomplete></CustomAutocomplete>
            <CustomDialog
              width={"600px"}
              open={openSelectClientDialog.open}
              handleClose={() =>
                setOpenSelectClientDialog({ open: false, data: {} })
              }
              title={"Select Client"}
              maxWidth={"lg"}
            >
              <>
                <Box>
                  <SelectClient
                    onClose={() =>
                      setOpenSelectClientDialog({ open: false, data: {} })
                    }
                    openSelectClientDialog={openSelectClientDialog}
                    fromCommomnDashboard={true}
                  />
                </Box>
              </>
            </CustomDialog>
          )}

          {openBookAppointmentDialog.open && (
            <CustomDialog
              width={"650px"}
              open={openBookAppointmentDialog.open}
              handleClose={() =>
                setOpenBookAppointmentDialog({ open: false, data: {} })
              }
              title={"Book Appointment"}
              maxWidth={"lg"}
            >
              <Box>
                <BookAppointmentForm
                  onClose={() =>
                    setOpenBookAppointmentDialog({ open: false, data: {} })
                  }
                  navigateTo={() => {
                    navigate(`/cases/client/${"121213"}/add`);
                  }}
                />
              </Box>
            </CustomDialog>
          )}
        </Box>
      </>
    );
  };

  return (
    <>
      <Container maxWidth="lg" sx={{ marginTop: 2 }}>
        <>
          {renderCards()}
          <Box mt={4}>
            <CustomCard>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                p={"16px 16px 0px 16px"}
                textAlign={"center"}
              >
                <Box style={{ display: "flex" }}>
                  <Typography
                    fontSize={"14px"}
                    sx={{ color: theme.palette.customColors.grey }}
                  >
                    All Requests
                  </Typography>
                  {/* <Typography
                    ml={1.5}
                    sx={{
                      background: "rgba(104, 212, 180, 1)",
                      padding: "3px 10px",
                      borderRadius: "7px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    02
                  </Typography> */}
                </Box>
              </Box>
              <Box p={2}>
                <RequestList />
              </Box>
            </CustomCard>
          </Box>
        </>
      </Container>
    </>
  );
};

export default LocalAdminDashboard;
