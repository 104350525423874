import { useEffect, useState } from "react";
import { Container, Grid, Box, Typography } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomTabs from "../../components/CustomTabs";
import { apiRequest } from "../../app/services/requests";
import AssignServiceForm from "./Plans/assign-service-form";
import CustomCard from "../../components/Card";
import CustomDialog from "../../components/CustomDialog";
import CustomButton from "../../components/Button";

interface TabOption {
  index: number;
  id: string;
  name: string;
  component: JSX.Element;
}

const AssignServices = (props: any) => {
  const navigate = useNavigate();

  const { planId, currentTab } = useParams<any>();
  const [masterPlanDetails, setMasterPlanDetails] = useState<any>({});
  const [selectedTab, setSelectedTab] = useState<any>(0);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<any>({
    open: false,
    data: {},
  });

  // //useEffect starts
  useEffect(() => {
    if (planId) {
      getMasterPlan(planId);
    }
  }, [planId]);

  const getMasterPlan = (planId: any) => {
    apiRequest({
      url: `master-plan/${planId}/record`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          setMasterPlanDetails(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate(`/"}`); // Navigate to "/login" route
          }
        }
      });
  };

  const getTabsByCategory = (categories: any) => {
    return categories?.map((category: any, index: number) => ({
      index, // Use the current index from the map function
      id: category,
      name: `${category?.charAt(0)?.toUpperCase() + category?.slice(1)}`,
      component: (
        <AssignServiceForm
          planId={planId}
          category={category?.toLowerCase()}
          onClose={(isNavigate: any) => {
            let currentIndex = masterPlanDetails?.categories?.findIndex(
              (cName: any) => cName === currentTab
            );
            // Check if currentIndex is valid and less than the length of the categories array
            if (
              !isNavigate &&
              currentIndex >= 0 &&
              currentIndex < masterPlanDetails?.categories.length - 1
            ) {
              currentIndex = currentIndex + 1;
              navigate(
                `/plan/${planId}/assign-services/${masterPlanDetails?.categories[currentIndex]}`
              );
            } else {
              // Navigate to the plan list if there are no more categories
              setOpenConfirmationDialog({ open: true, data: {} });
            }
          }}
          allCategories={masterPlanDetails?.categories}
        />
      ),
    }));
  };

  const tabs: TabOption[] = getTabsByCategory(masterPlanDetails?.categories);

  useEffect(() => {
    if (currentTab && tabs?.length) {
      const targetTab: any = tabs?.find((tab: any) => tab?.id === currentTab);
      setSelectedTab(targetTab?.index);
    }
  }, [currentTab, masterPlanDetails?.categories]);

  const handleUpgradeClose = () => {
    setOpenConfirmationDialog({ open: false, data: {} });
  };

  return (
    <Container maxWidth="lg" sx={{ padding: "0 !important" }}>
      <CustomCard sx={{ padding: 4, margin: 2 }}>
        <Box mt={2}>
          <Grid container spacing={2} style={{ marginBottom: "20px" }}>
            <Grid item xs={12}>
              <Link to={`/master/plan-list`} style={{ marginBottom: 2 }}>
                Back
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Box>{"Plan details"}</Box>
              <Box
                paddingRight={1}
                mt={"10px"}
                pl={2}
                display={"flex"}
                justifyContent={"flex-start"}
                gap={2}
              >
                <Typography sx={{ fontSize: "12px", fontWeight: "800" }}>
                  Name:
                </Typography>
                <Typography variant="body2">
                  {masterPlanDetails?.name}{" "}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <CustomTabs
                tabOptions={tabs}
                currentTab={selectedTab}
                onTabChange={(value: any) => {
                  navigate(`/plan/${planId}/assign-services/${value}`);
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </CustomCard>
      {openConfirmationDialog?.open && (
        <CustomDialog
          width={"400px"}
          open={openConfirmationDialog.open}
          title={"Confirmation"}
          handleClose={handleUpgradeClose}
        >
          <Box>
            <Box mb={3}>
              <Typography>
                Are you sure you want to navigate to the plan list?
              </Typography>
            </Box>
            {/* Additional Sections can be added as needed */}
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <CustomButton
                border="1px solid #000000"
                color="#000000"
                title="No"
                bgColor="#ffffff"
                onClick={handleUpgradeClose}
                borderRadius="5px"
              />
              <Box marginLeft={1}>
                {" "}
                {/* Add a margin to create space between buttons */}
                <CustomButton
                  title={"Yes"}
                  type="button"
                  borderRadius="5px"
                  handleButtonClick={() => navigate("/master/plan-list")}
                />
              </Box>
            </Box>
          </Box>
        </CustomDialog>
      )}
    </Container>
  );
};

export default AssignServices;
