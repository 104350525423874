import React from "react";
import { Box, Grid } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import IRATypography from "../../../components/ira-typography/ira-typography";
import Breadcrumb from "../../../components/Breadcrumb";
import ClientProfileCard from "../../../components/ClientProfileCard";
import CustomCard from "../../../components/Card";
import CaseHistoryForm from "../case-history-form";


const AddEditCaseHistory = () => {
  const breadcrumbs = [
    <RouterLink to="/Dashboard" style={{ textDecoration: "none" }}>
      <IRATypography
        key="2"
        textVariant="h12WithSemiBold"
        fontSize="12px"
        color="#222222"
      >
        Home
      </IRATypography>
    </RouterLink>,
    <IRATypography
      key="2"
      textVariant="h12WithSemiBold"
      fontSize="12px"
      color="#222222"
    >
      Client Profile
    </IRATypography>,
     <IRATypography
     key="2"
     textVariant="h12WithSemiBold"
     fontSize="12px"
     color="#222222"
   >
    Buy Plan
   </IRATypography>,
    <IRATypography
    key="2"
    textVariant="h12WithSemiBold"
    fontSize="12px"
    color="#222222"
  >
    Plan Payment
  </IRATypography>,
  ];
  return (
    <div>
       <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        mb={1}
      >
        <Breadcrumb title={`Health Status`}
         breadcrumbsArr={breadcrumbs} />
      </Box>

      <Box display={"flex"}>
        <Grid container xs={12}>
          <Grid item xs={4} >
            <ClientProfileCard />
          </Grid>

         
            <Grid item xs={8} mt={2} pl={5}>
              <CustomCard>
                {/* <CaseHistoryDemo /> */}
                <CaseHistoryForm/>
              </CustomCard>
            </Grid>
         
        </Grid>
      </Box>
    </div>
  );
};

export default AddEditCaseHistory;
