import React from "react";
import { Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import CustomModal from "../Modal";
import { styles } from "./styles";
// import { downloadFileFromS3 } from "../../app/utilities/uploadToS3";
import { ProfilePic } from "../../app/utilities";

const ImageView = (Props: any) => {
  const {
    imageDetails = {},
    triggerModal = false,
    triggerCloseModal = () => {},
    title = "Image View",
  } = Props;
  // const downloadFile = () => {
  //   downloadFileFromS3(imageDetails.path)
  //     .then((res: any) => {
  //       const { downloadURL = "" } = res;
  //       fetch(downloadURL).then((res) => {
  //         res.blob().then((blob) => {
  //           const url = window.URL.createObjectURL(new Blob([blob]));
  //           const link = document.createElement("a");
  //           link.href = url;
  //           link.setAttribute("download", imageDetails.name || "test.jpg");
  //           document.body.appendChild(link);
  //           link.click();
  //           link.parentNode && link.parentNode.removeChild(link);
  //         });
  //       });
  //     })
  //     .catch((err) => console.log(err));
  // };
  return (
    <CustomModal zIndex={"99999"} open={triggerModal} width={"500px"}>
      <Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            fontSize={"22px"}
           
            fontWeight={"600"}
            color={"#204289"}
          >
            {imageDetails.name || title}
          </Typography>
          <CloseIcon
            onClick={() => triggerCloseModal()}
            sx={{ cursor: "pointer" }}
          />
        </Box>
        <Box mt={"20px"}>
          <ProfilePic
            profilePhoto={imageDetails.path}
            styles={{ width: "450px" }}
            isProfilePic={false}
          />
          <Button
            variant="text"
            component="label"
            sx={{ textTransform: "inherit", borderRadius: "7px" }}
            // onClick={() => downloadFile()}
          >
            <CloudDownloadOutlinedIcon sx={styles.actionIcon} />
            &nbsp;&nbsp;
            <Typography sx={styles.tableCell}>Download File</Typography>
          </Button>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default ImageView;
