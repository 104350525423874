import { Box, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CustomInput from "../../../components/Input";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomCard from "../../../components/Card";
import CustomCheckbox from "../../../components/CustomCheckBox";
import CustomButton from "../../../components/Button";

const OtherServiceSetup = () => {
  const userDefaultValues = () => {
    return {
      defaultDiscount: "",
      waitingPeriod: "",
      afterWaitingPeriod: "",
      afterOneYear: "",
      afterTwoYear: "",
      defaultDiscount1: "",
    } as any;
  };
  const schema = object({
    defaultDiscount: string().required("Hospital Regd No is required"),
    waitingPeriod: string().required("Hospital Regd No is required"),
    afterWaitingPeriod: string().required("Hospital Regd No is required"),
    afterOneYear: string().required("Hospital Regd No is required"),
    afterTwoYear: string().required("Hospital Regd No is required"),
  });

  const { control, handleSubmit, getValues, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: userDefaultValues(),
  });
  const onSubmit = (formData: any) => {
    const { clientType, doctorId, clientId, startDate, endDate, ...rest } =
      formData;
    // moment(startDate).format(DATE_TIME_FORMAT),
    const payload = {};
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box p={2}>
          <CustomCard>
            <Box p={3}>
              <Typography fontSize={20}>
                <b> Service SetUp : DAS3</b>
              </Typography>

              <Box
                display={"flex"}
                justifyContent={"space-evenly"}
                mt={2}
                mr={50}
              ></Box>

              <Box
                display={"flex"}
                justifyContent={"space-evenly"}
                mt={2}
                mr={30}
              >
                {/* <Typography mt={2}>Categories: </Typography> */}
                <Controller
                  name="preventive"
                  control={control}
                  render={({ field }) => (
                    <CustomCheckbox {...field} label="Scallimg" />
                  )}
                />
                <Controller
                  name="basic"
                  control={control}
                  render={({ field }) => (
                    <CustomCheckbox {...field} label="Xray" />
                  )}
                />
                <Controller
                  name="minor"
                  control={control}
                  render={({ field }) => (
                    <CustomCheckbox {...field} label="Biopsy" />
                  )}
                />
                <Controller
                  name="major"
                  control={control}
                  render={({ field }) => (
                    <CustomCheckbox {...field} label="Medication" />
                  )}
                />
                <Controller
                  name="major"
                  control={control}
                  render={({ field }) => (
                    <CustomCheckbox {...field} label="Mesiodens" />
                  )}
                />
                <Controller
                  name="major"
                  control={control}
                  render={({ field }) => (
                    <CustomCheckbox {...field} label="Ortho Treatment" />
                  )}
                />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-evenly"}
                mt={2}
                mr={50}
              >
                <Typography>Total Limit:</Typography>
                <Controller
                  name="defaultDiscount"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      label={"Total Limit"}
                      placeholder={"Ex 5"}
                      InputProps={""}
                      size={"small"}
                    />
                  )}
                />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-evenly"}
                mt={2}
                mr={50}
                pl={4}
              >
                <Typography>Period:</Typography>
                <Controller
                  name="waitingPeriod"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      label={"Period"}
                      placeholder={"Ex 5"}
                      InputProps={""}
                      size={"small"}
                    />
                  )}
                />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-evenly"}
                mt={2}
                mr={50}
              >
                <Typography>Period Limit:</Typography>
                <Controller
                  name="afterWaitingPeriod"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      label={"Period Limit"}
                      placeholder={"Ex 5"}
                      InputProps={""}
                      size={"small"}
                    />
                  )}
                />
              </Box>
              <br></br>
              <Box sx={{ textAlign: "center" }}>
                <CustomButton title="Submit" type="button"></CustomButton>
              </Box>
            </Box>
          </CustomCard>
        </Box>
      </form>
    </>
  );
};

export default OtherServiceSetup;
