import { Box, Grid } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import CustomInput from "../../../components/Input";
import CustomButton from "../../../components/Button";

function AddEditInterpretation(props: any) {
  const { onCancel, notes = "", handleNotesSubmit } = props || {};
  const { control, handleSubmit } = useForm({
    defaultValues: { notes },
  });

  return (
    <Box pb={2}>
      <form
        name={"periodental-notes"}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(handleNotesSubmit)}
      >
        <Controller
          name="notes"
          control={control}
          rules={{ required: "This field is required" }}
          render={({ field, fieldState: { error } }) => (
            <CustomInput
              {...field}
              multiline
              error={!!error}
              helperText={error ? error.message : ""}
              label={"Enter Notes"}
              InputProps={""}
              size={"small"}
            />
          )}
        />
        <Grid
          container
          flexDirection={"row"}
          spacing={0.5}
          justifyContent={"end"}
          mt={2}
        >
          <Grid item>
            <CustomButton
              type="button"
              title={"Cancel"}
              backgroundcolor={"#FFFFFF"}
              bordercolor={"#204289"}
              color={"#204289"}
              width={110}
              handleButtonClick={onCancel}
              padding={7}
            />
          </Grid>
          <Grid item>
            <CustomButton
              type="submit"
              title={notes ? "Update" : "Submit"}
              backgroundcolor={"#204289"}
              bordercolor={"#204289"}
              color={"#fffff"}
              width={110}
              padding={7}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default AddEditInterpretation;
