import React, { useEffect, useState } from "react";
import { useAppContext } from "./app/context/AppContext";
import Layout from "./components/Layout";
import LoginPage from "./screens/Login/login";
import { useLocation, useNavigate } from "react-router-dom";
import { OnboardingStep1 } from "./screens/Onboarding";
import PrivacyPolicy from "./screens/TermsAndPolicies/privacy-policy";
import TermsAndConditions from "./screens/TermsAndPolicies/terms-and-conditions";
import NoLoginRoutes from "./app/NoLoginRoutes";

function App() {
  const { user: contextUser } = useAppContext() as any;
  const [user, setUser] = useState(contextUser);
  const navigate = useNavigate();
  const location = useLocation();

  // Define paths that should be accessible without login
  const isPublicPage = ["/user/onboarding/step/1", "/privacy-policy", "/terms-and-conditions"].includes(location.pathname);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user") || "{}");
    setUser(storedUser);
  }, [contextUser]);

  // useEffect(() => {
  //   if (user?.accessToken) {
  //        navigateBasedOnRole();
  //      }
  // }, [user]);

  const navigateBasedOnRole = () => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const user = JSON.parse(storedUser);
      if (user && user.userType) {
        switch (user.userType) {
          case "clinic":
          case "admin":
          case "client":
            navigate("/dashboard");
            break;
          default:
            navigate("/login");
        }
      } else {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  return <>{!user?.accessToken ? <NoLoginRoutes /> : <Layout />}</>;
}

export default App;
