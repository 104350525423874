import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Container,
  Grid,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import CustomInput from "../../../components/Input";
import CustomButton from "../../../components/Button";
import {
  COUNTRY_LIST,
  MARTIAL_STATUS1,
  PHONE_REG_EXP,
  GENDER_DATA,
} from "../../../app/utilities/constants";
import StatesAndCities from "../../../app/utilities/StatesAndCities.json";
import CustomDatePicker from "../../../components/DatePicker";
import CustomCheckbox from "../../../components/CustomCheckBox";
import CustomAutocomplete from "../../../components/Autocomplete";
import CustomCard from "../../../components/Card";
import styless from "../client.module.css";
import { apiRequest } from "../../../app/services/requests";
import { useSnackbar } from "../../../app/context/useSnackbar";
import { Recycling } from "@mui/icons-material";
import { log } from "console";
import FileUploader from "../../../components/FileUploader/file-upload";
import { getOnlyDate } from "../../../app/utilities/utils";

const AddEditClient = (props: any) => {
  const { currentSubCategory = {} as any } = props;
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [cities, setCities] = useState<any>([]);
  const [statesAndCities] = useState<any>(StatesAndCities);
  const [currentClientDetails, setCurrentClientDetails] = useState({} as any);
  const [uploadAadharCard, setUploadAadharCard] = useState<any>("");
  const [uploadPanCard, setUploadPanCard] = useState<any>("");
  const [otp] = useState<Boolean>(false);
  const [success] = useState<Boolean>(false);

  const panInputRef = useRef<HTMLInputElement | null>(null);
  const aadharInputRef = useRef<HTMLInputElement | null>(null);

  const { openSnackbar } = useSnackbar();

  const userDefaultValues = () => {
    return {} as any;
  };

  const schema = object({
    firstName: string().required("First name is required"),
    lastName: string().required("Last name is required"),
    mobileNumber: string()
      .required("Mobile number is required")
      .min(10, "Mobile number must be at least 10 characters long")
      .matches(PHONE_REG_EXP, "Phone number is not valid"),
    dateOfBirth: string().ensure().required("Date of birth is required"),
    email: string()
      .required("Email is required")
      .nullable()
      .email("Email is not valid"),
    addressLine1: string().required("Address Line 1 is required"),
    addressLine2: string().required("Address Line 2 is required"),
    country: string().required("Country is required"),
    state: string().required("State is required"),
    city: string().required("City is required"),
    zipCode: string()
      .required("Zip Code is required")
      .max(6, "Zip Code should be of 6 characters"),
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: userDefaultValues(),
  });
  console.log(errors);
  const handleCancel = () => {
    navigate("/dashboard");
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;
    if (files && files.length > 0) {
      const file = files[0];
      // You can handle the file upload logic here (e.g., upload to server or store locally)
    }
  };

  const onSubmit = (data: any) => {
    const payload = {
      email: data?.email,
      dateOfBirth: getOnlyDate(data?.dateOfBirth),
      mobileNumber: data?.mobileNumber,
      lastName: data?.lastName,
      firstName: data?.firstName,
      maritalStatus: data?.maritalStatus,
      gender: data?.gender,
      photo: data?.photo,
      hasWhatsAppForMobileNumber: true,
      alternateMobileNumber: data?.alternateMobileNumber,
      hasWhatsAppForAlternateMobileNumber: true,
      address: {
        addressLine1: data?.addressLine1,
        addressLine2: data?.addressLine2,
        city: data?.city,
        country: data?.country,
        state: data?.state,
        zipCode: data?.zipCode,
      },
      panDocumentUrl: uploadPanCard?.url,
      adhaarDocumentUrl: uploadAadharCard?.url,
    };

    apiRequest({
      url: `client`,
      data: payload,
      method: "POST",
    })
      .then((res) => {
        openSnackbar("Client Saved Successfully", "success");
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  useEffect(() => {
    if (clientId) {
      getClientDetails();
    }
  }, [clientId]);

  const getClientDetails = () => {
    apiRequest({
      url: `client/${clientId}`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          setCurrentClientDetails(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  return (
    <>
      <Container maxWidth="lg" className={otp || success ? styless.long : ""}>
        {" "}
        <Box sx={{ paddingTop: "15px" }}>
          <Typography
            fontSize={"20px"}
            gutterBottom
            style={{ marginBottom: "0em" }}
          >
            {otp ? "Client" : "Client Enrollment"}
          </Typography>
          <Typography fontSize={"12px"} style={{ marginBottom: "0.7em" }}>
            {otp ? "Home > Edit Client" : "Home > Client Enrollment"}
          </Typography>
        </Box>
        <Box mt={2}>
          <Box
            style={{
              backgroundImage: `url(${require("../../../app/assets/AddNewClient/back.png")})`,
              height: "13vh",
              backgroundRepeat: "round",
              marginBottom: "2vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              style={{ marginLeft: "27vw", height: "70px" }}
              component="img"
              src={require("../../../app/assets/logo.png")}
            ></Box>
            <Box style={{ marginLeft: "10px", width: "52%" }}>
              <Typography
                style={{ color: "rgba(33, 159, 255, 1)", fontSize: "20px" }}
              >
                {"Dentassure Client Enrollment Form"}
              </Typography>
              <Typography variant="body2" style={{ width: "60%" }}>
                {
                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                }
              </Typography>
            </Box>
            <CustomButton
              title={"Help"}
              marginRight={"5px"}
              bgColor={"rgba(92, 78, 255, 1)"}
              height={"25px"}
            />
          </Box>

          <CustomCard style={{ borderRadius: "10px" }}>
            <Box px={4} py={4}>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* Client Details Section */}

                <Typography variant="body1" style={{ marginTop: "10px" }}>
                  Client Details
                </Typography>

                <Grid container spacing={3} mt={0}>
                  {/* Add client details fields here */}
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          label={"Enter First Name"}
                          InputProps={""}
                          size={"small"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          label={"Enter Last Name"}
                          InputProps={""}
                          size={"small"}
                        />
                      )}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={4}>
                    <Controller
                      name={"dateOfBirth"}
                      rules={{ required: true }}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomDatePicker
                          {...field}
                          label={"End Date Of Birth"}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          disablePast={true}
                          onChange={handleSubmit}
                          value={currentClientDetails?.dateOfBirth}
                        />
                      )}
                    />
                  </Grid> */}
                  <Grid item xs={12} md={4}>
                    <Controller
                      name={"dateOfBirth"}
                      rules={{ required: true }}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomDatePicker
                          // style={{ width: "80%" }}
                          {...field}
                          label={"End Date Of Birth"}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          maxDate={new Date()}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={3}>
                  <Grid
                    item
                    xs={6}
                    md={4}
                    mt={4}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      variant="regular"
                      style={{ paddingTop: "11px", marginRight: "5px" }}
                    >
                      Sex :
                    </Typography>
                    <Controller
                      name={`gender`}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl component="fieldset">
                          <RadioGroup {...field} row>
                            {GENDER_DATA.map((option: any, idx: any) => (
                              <FormControlLabel
                                key={idx}
                                value={option.id}
                                control={<Radio />}
                                label={option.name}
                              />
                            ))}
                          </RadioGroup>
                          {error && (
                            <FormHelperText
                              sx={{ color: "#d32f2f !important" }}
                            >
                              {error.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    mt={6}
                    style={{ display: "flex", paddingTop: "10px" }}
                  >
                    <Typography
                      variant="regular"
                      style={{ paddingTop: "8px", marginRight: "5px" }}
                    >
                      Marital Status :
                    </Typography>
                    <Controller
                      name={`maritalStatus`}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl component="fieldset">
                          <RadioGroup {...field} row>
                            {MARTIAL_STATUS1.map((option: any, idx: any) => (
                              <FormControlLabel
                                key={idx}
                                value={option.id}
                                control={<Radio />}
                                label={option.name}
                              />
                            ))}
                          </RadioGroup>
                          {error && (
                            <FormHelperText
                              sx={{ color: "#d32f2f !important" }}
                            >
                              {error.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>

                {/* Communication Details Section */}
                <Box mt={4}>
                  <Typography variant="body1">Communication Details</Typography>
                  {/* Add communication details fields here */}
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4} mt={2}>
                      <Box>
                        <Box style={{ display: "flex" }}>
                          <Controller
                            name="phonecode"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                // label={"Primary Mobile No"}
                                style={{ width: "47px", marginRight: "10px" }}
                                type="text"
                                {...field}
                                error={!!error}
                                helperText={error ? error.message : ""}
                              />
                            )}
                          />
                          <Controller
                            name="mobileNumber"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                label={"Primary Mobile No"}
                                type="text"
                                {...field}
                                error={!!error}
                                helperText={error ? error.message : ""}
                              />
                            )}
                          />
                        </Box>
                        <Controller
                          name="hasWhatsAppForMobileNumber"
                          control={control}
                          render={({ field }) => (
                            <CustomCheckbox
                              {...field}
                              label="Whatsapp Number"
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} mt={2}>
                      <Box>
                        <Box style={{ display: "flex" }}>
                          <Controller
                            name="phonecode"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                // label={"Primary Mobile No"}
                                style={{ width: "47px", marginRight: "10px" }}
                                type="text"
                                {...field}
                                error={!!error}
                                helperText={error ? error.message : ""}
                              />
                            )}
                          />
                          <Controller
                            name="alternateMobileNumber"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                style={{ width: "100%" }}
                                label={"Secondary Mobile No"}
                                type="text"
                                {...field}
                                error={!!error}
                                helperText={error ? error.message : ""}
                              />
                            )}
                          />
                        </Box>
                        <Controller
                          name="hasWhatsAppForAlternateMobileNumber"
                          control={control}
                          render={({ field }) => (
                            <CustomCheckbox
                              {...field}
                              label="Whatsapp Number"
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} mt={2}>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            label={"Email"}
                            size={"small"}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>

                {/* Personal Identity Section */}
                <Box mt={4}>
                  <Typography variant="body1">Personal Identity</Typography>
                  {/* Add personal identity fields here */}
                  <Grid container spacing={3} mt={1}>
                    <Grid item xs={12} md={4}>
                      <FileUploader
                        key={"aadharCard"}
                        uploadAadharCard={uploadAadharCard}
                        setUploadAadharCard={setUploadAadharCard}
                        accept={"image/png,image/jpeg,image/jpg"}
                        displayType={"aadharCard"}
                        uploadType={"aadharCard"}
                        uploadText={
                          uploadAadharCard?.originalname
                            ? uploadAadharCard?.originalname
                            : "Upload  Aadhar"
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FileUploader
                        key={"panCard"}
                        uploadPanCard={uploadPanCard}
                        setUploadPanCard={setUploadPanCard}
                        accept={"image/png,image/jpeg,image/jpg"}
                        displayType={"panCard"}
                        uploadType={"panCard"}
                        uploadText={
                          uploadPanCard?.originalname
                            ? uploadPanCard?.originalname
                            : "Upload PanCard "
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>

                {/* Address for Communication Section */}
                <Box mt={5}>
                  <Typography variant="body1">
                    Address for Communication
                  </Typography>
                  {/* Add address for communication fields here */}

                  <Grid container spacing={3} mt={0}>
                    <Grid item xs={6} md={4}>
                      <Controller
                        name="addressLine1"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            label={"Enter Address Line 1"}
                            InputProps={""}
                            size={"small"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Controller
                        name="addressLine2"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            label={"Enter Address Line 2"}
                            InputProps={""}
                            size={"small"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid container spacing={2} mt={1} ml={1}>
                      <Grid item xs={4}>
                        <Box>
                          <Controller
                            name="country"
                            defaultValue={"India"}
                            control={control}
                            rules={{ required: true }}
                            render={({
                              field: { ref, onChange, ...field },
                              fieldState: { error },
                            }) => (
                              <CustomAutocomplete
                                field={field}
                                disabled
                                error={!!error}
                                helperText={error ? error.message : ""}
                                ref={ref}
                                singleSelection
                                onChange={(
                                  event: React.SyntheticEvent,
                                  value: any
                                ) => onChange(value)}
                                options={COUNTRY_LIST}
                                value={getValues("country")}
                                label="Country"
                                defaultValue={"India"}
                              />
                            )}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name="state"
                          control={control}
                          defaultValue={getValues("state")}
                          rules={{ required: true }}
                          render={({
                            field: { ref, onChange, ...field },
                            fieldState: { error },
                          }) => (
                            <CustomAutocomplete
                              field={field}
                              error={!!error}
                              helperText={error ? error.message : ""}
                              ref={ref}
                              value={getValues("state")}
                              singleSelection
                              onChange={(
                                event: React.SyntheticEvent,
                                value: any
                              ) => {
                                onChange(value);
                                setValue("city", "");
                                setCities(statesAndCities[value] || []);
                              }}
                              options={Object.keys(statesAndCities)}
                              label="State"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name="city"
                          control={control}
                          defaultValue={getValues("city")}
                          rules={{ required: true }}
                          render={({
                            field: { ref, onChange, ...field },
                            fieldState: { error },
                          }) => (
                            <CustomAutocomplete
                              field={field}
                              error={!!error}
                              helperText={error ? error.message : ""}
                              ref={ref}
                              value={getValues("city")}
                              singleSelection
                              onChange={(
                                event: React.SyntheticEvent,
                                value: any
                              ) => {
                                onChange(value);
                                setValue("city", value);
                              }}
                              options={cities}
                              label="City"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={4} md={4}>
                      <Controller
                        name="zipCode"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            label={"Zip code"}
                            size={"small"}
                            type={"number"}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>

                {/* Additional Sections can be added as needed */}
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  <CustomButton
                    title={"Cancel"}
                    marginRight={"5px"}
                    bgColor={"rgba(255, 225, 229, 1)"}
                    width={"100px"}
                    color={"rgba(255, 97, 116, 1)"}
                    borderColor={"rgba(255, 97, 116, 1)"}
                    borderWidth={"1px"}
                    borderStyle={"solid"}
                    onClick={handleCancel}
                  />

                  <Box marginLeft={1}>
                    <CustomButton
                      title={
                        currentSubCategory?.open === "add" ? "Create" : "Save"
                      }
                      marginRight={"5px"}
                      bgColor={"rgba(92, 78, 255, 1)"}
                      width={"100px"}
                      type={"submit"}
                    />
                  </Box>
                </Box>
              </form>
            </Box>
          </CustomCard>
        </Box>
      </Container>
    </>
  );
};

export default AddEditClient;
