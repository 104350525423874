import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { theme } from "../../../theme/theme";

interface IDetailPlanProps {
  planType: string;
  planList: any;
}

// id: data?.id,
// name: data?.name,
// code: data?.code,
// type: data?.type,
// premium: data?.premium,
// limit: data?.limit,
// status: data?.status,
export const DetailsWithLabel = ({ label, value }: any) => {
  return (
    <Grid container>
      <Grid item xs={3}>
        <Typography
          color={theme.palette.customColors.blackGrey}
          fontSize={"16px"}
          variant="h6"
          fontWeight={"600"}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography
          color={theme.palette.customColors.blackGrey}
          fontSize={"16px"}
          variant="h6"
          fontWeight={"600"}
        >
          {`:  ${value}`}
        </Typography>
      </Grid>
    </Grid>
  );
};

const DetailPlan = (props: IDetailPlanProps) => {
  const { planType, planList } = props;
  const selectedPlan = planList?.find((plan: any) => plan.id === planType);
  if(!selectedPlan) return null;
  return (
    <Box
      p={2}
      mt={2}
      display={"flex"}
      flexDirection={"column"}
      style={{ backgroundColor: "#f5f5f5" }}
      width={"100%"}
      gap={1}
    >
      <DetailsWithLabel label="Plan Name" value={selectedPlan?.name || "NA"} />
      <DetailsWithLabel label="Plan Code" value={selectedPlan?.code || "NA"} />
      <DetailsWithLabel label="Plan Type" value={selectedPlan?.type || "NA"} />
      <DetailsWithLabel label="Premium Amount" value={selectedPlan?.premium || "NA"} />
      <DetailsWithLabel label="Plan Limit" value={selectedPlan?.limit || "NA"} />
      {/* <DetailsWithLabel label="Status" value={selectedPlan?.status || "NA"} /> */}
    </Box>
  );
};

export default DetailPlan;
