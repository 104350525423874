import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import { Container, Grid } from "@mui/material";
import styles from "./layout.module.css";
import routes from "../../app/routes";
import { useNavigate, useRoutes } from "react-router-dom";
import profileImagePng from "../../app/assets/DentaAssurePlans/profileImage.png";
import clinicImage from "../../app/assets/DentaAssurePlans/clinicImage.png";
import PopOver from "../PopOver";
import Background from "../../app/assets/Dashboard/background.jpg";
import { useAppContext } from "../../app/context/AppContext";

const drawerWidth = 245;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Layout() {
  const [open] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const routesList = useRoutes(routes);

  const { handleLogoutSuccess, user } = useAppContext() as any;

  const navigate = useNavigate();

  const openPopUp = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const headerMenu = [
    {
      id: 1,
      name: "Logout",
      onClick: () => {
        setAnchorEl(null);
        localStorage.removeItem("user");
        navigate("/");
        handleLogoutSuccess();
      },
    },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{
          boxShadow: "none",
          zIndex: "3",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Box padding={"10px 50px 10px 50px"}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box
              sx={{ cursor: "pointer" }}
              display={"flex"}
              gap={"20px"}
              alignItems={"center"}
              onClick={() => navigate("/dashboard")}
            >
              <Box
                component="img"
                className={styles?.locationImage}
                alt="Logo"
                src={require("../../app/assets/logo.png")}
              />

              <Typography
                className={styles.locationHeading}
                color={"#219FFF"}
                fontWeight={"600"}
                variant="h6"
              >
                Dentassure
              </Typography>
            </Box>
            <Box
              display={"flex"}
              gap={"10px"}
              alignItems={"center"}
              // paddingBottom={5}
            >
              <Box
                onClick={handleClick}
                display={"flex"}
                gap={"10px"}
                alignItems={"center"}
                sx={{ cursor: "pointer" }}
              >
                <img
                  style={{
                    width: "40px",
                    borderRadius: "40%",
                    height: "40px",
                  }}
                  src={clinicImage}
                  alt="logo"
                />
                <Box>
                  <Typography
                    color={"#219FFF"}
                    fontWeight={"600"}
                    variant="h6"
                    fontSize={"15px"}
                  >
                    {user?.userType}
                  </Typography>
                </Box>
              </Box>
              <Box mt={2}>
                <PopOver
                  anchorEl={anchorEl}
                  open={openPopUp}
                  onClose={handleClose}
                  options={headerMenu}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </AppBar>

      <Box
        minHeight={"100vh"}
        style={{
          backgroundImage: `url(${Background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "100%",
        }}
        sx={{ padding: "0 !important", marginTop: "60px" }}
      >
        <Container>{routesList}</Container>
      </Box>
    </Box>
  );
}
