import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CustomDialog = (props: any) => {
  const {
    handleClose,
    fullScreen = false,
    fullWidth = false,
    maxWidth,
    open = false,
    title,
    width,
    height = "",
    ...rest
  } = props;

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown
        {...rest}
      >
        {title && (
          <DialogTitle sx={{ borderBottom: "1px solid #dfdfdf" }}>
            <Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                <Typography
                  fontSize={"22px"}
                  fontWeight={"600"}
                  color={"#204289"}
                >
                  {title}
                </Typography>
              </Box>
            </Box>
          </DialogTitle>
        )}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            backgroundColor: "#dfdfdf", //  204289 Add this line for background color
            borderRadius: "50%", // Optional: Add border-radius for a circular button
          }}
        >
          <CloseIcon />
        </IconButton>
        {props.children && (
          <DialogContent sx={{ width }}>{props.children}</DialogContent>
        )}
        {props.footerActions && (
          <DialogActions>{props.footerActions}</DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default CustomDialog;
