import { useForm, Controller } from "react-hook-form";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../components/Button";
import { AIR_CONDITION_OPTIONS } from "../../app/utilities/constants";

import CustomSelectPicker from "../../components/SelectPicker";
import CustomCheckbox from "../../components/CustomCheckBox";
import { apiRequest } from "../../app/services/requests";

const InfrastructureForm = (props: any) => {
  const { editData = {} as any, isEdit, onClose } = props;

  const navigate = useNavigate();

  const { hospitalId } = useParams();

  const userDefaultValues = (isEdit: boolean) => {
    if (!isEdit) {
      return {
        areaInSquareFeet: "",
        isAirConditioned: true,
        isWheelChairAccess: false,
        isPowerBackup: false,
        isOperationTheaterAttachedHospital: false,
        isAmbulanceOrTransportAvailable: false,
      } as any;
    } else {
      return {
        ...editData,
      };
    }
  };

  const schema = object({
    areaInSquareFeet: string().required("Type is required"),
  });

  const { control, handleSubmit, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...userDefaultValues(isEdit) },
  });

  const onSubmit = (formData: any) => {
    const formValues = getValues();
    const data = {
      ...formValues,
      clinicId: Number(hospitalId),
    } as any;
    data.isAirConditioned = JSON.parse(formValues.isAirConditioned);
    if (!isEdit) {
      apiRequest({
        url: `clinic/infrastructure/add`,
        data: data,

        method: "POST",
      })
        .then((res) => {
          onClose();
          props.onFetchInfrastructure();
        })
        .catch((err) => {
          if (err?.statusCode === 403 || err?.statusCode === 401) {
            if (navigate) {
              navigate("/login"); // Navigate to "/login" route
            }
          }
        });
    } else {
      const { id, createdAt, updatedAt, isAirConditioned, ...payload } =
        getValues();
      apiRequest({
        url: `clinic/infrastructure/${editData?.id}/update`,
        data: {
          ...payload,
          clinicId: Number(hospitalId),
          isAirConditioned: JSON.parse(isAirConditioned),
        },
        method: "PATCH",
      })
        .then((res) => {
          onClose();
          if (props?.onFetchInfrastructure) {
            props.onFetchInfrastructure();
          }
        })
        .catch((err) => {
          if (err?.statusCode === 403 || err?.statusCode === 401) {
            if (navigate) {
              navigate("/login"); // Navigate to "/login" route
            }
          }
        });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Client Details Section */}
        <Grid container spacing={3}>
          <Grid item xs={6} md={6}>
            <Controller
              name="areaInSquareFeet"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectPicker
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Area In SquareFeet"}
                  size={"small"}
                  fullWidth={true}
                  width={"100%"}
                  selectData={[
                    {
                      id: "500-1000",
                      name: "500-1000 Square Feet",
                    },
                  ]}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={6} alignItems="center">
            <Controller
              name={`isAirConditioned`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl component="fieldset">
                  <RadioGroup {...field} row>
                    {AIR_CONDITION_OPTIONS.map((option: any, idx: any) => (
                      <FormControlLabel
                        key={idx}
                        value={option.id}
                        control={<Radio />}
                        label={option.name}
                      />
                    ))}
                  </RadioGroup>
                  {error && (
                    <FormHelperText sx={{ color: "#d32f2f !important" }}>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="isWheelChairAccess"
              defaultValue={true}
              control={control}
              render={({ field }) => (
                <CustomCheckbox
                  {...field}
                  label="Wheel Chair Access"
                  checked={field.value || false}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="isPowerBackup"
              control={control}
              render={({ field }) => (
                <CustomCheckbox
                  {...field}
                  label="Power Backup"
                  checked={field.value || false}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="isOperationTheaterAttachedHospital"
              control={control}
              render={({ field }) => (
                <CustomCheckbox
                  {...field}
                  label="Operation Theater Attached Hospital"
                  checked={field.value || false}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="isAmbulanceOrTransportAvailable"
              control={control}
              render={({ field }) => (
                <CustomCheckbox
                  {...field}
                  label="Ambulance / Transport Facility"
                  checked={field.value || false}
                />
              )}
            />
          </Grid>
        </Grid>

        {/* Additional Sections can be added as needed */}
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <CustomButton
            border="1px solid #000000"
            color="#000000"
            title="Cancel"
            bgColor="#ffffff"
            onClick={() => props.onClose()}
            borderRadius="5px"
          />
          <Box marginLeft={1}>
            {" "}
            {/* Add a margin to create space between buttons */}
            <CustomButton
              title={editData?.open === "add" ? "Create" : "Save"}
              type="submit"
              borderRadius="5px"
            />
          </Box>
        </Box>
      </form>
    </>
  );
};

export default InfrastructureForm;
