import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import FileUploader from "../../../components/FileUploader/file-upload";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "../../../app/services/requests";
import CustomButton from "../../../components/Button";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { useSnackbar } from "../../../app/context/useSnackbar";
import { getTrimmedString } from "../../../app/utilities/utils";
interface IDocuments {
  key: string;
  originalname: string;
  url: string;
  type: string;
}

interface IDocumentTypes {
  aadharDocument: IDocuments | null;
  panDocument: IDocuments | null;
}

const Documents = (props: any) => {
  const { hospitalId } = useParams();
  const { clientDetails } = props;
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [uploadedDocuments, setUploadedDocuments] = useState<IDocumentTypes>({
    aadharDocument: null,
    panDocument: null,
  });
  const [otherDocuments, setOtherDocuments] = useState<IDocuments[]>([]);
  interface IDialogState {
    type: string;
    file?: IDocuments;
  }

  const [dialogState, setDialogState] = useState<IDialogState | null>(null);

  const handleFileChange = (file: IDocuments, type: string) => {
    setUploadedDocuments((prevStepDetails: any) => {
      return {
        ...prevStepDetails,
        [type]: file,
      };
    });
  };

  const handleDeleteFile = (type: string, file?: any) => {
    setDialogState({ type, file });
  };

  const handleDialogConfirm = () => {
    const { type, file } = dialogState as IDialogState;
    if (file && type === "otherDocuments") {
      setOtherDocuments((prevStepDetails: any) => {
        return prevStepDetails.filter(
          (item: IDocuments) => item.url !== file.url
        );
      });
    } else {
      setUploadedDocuments((prevStepDetails: any) => {
        return {
          ...prevStepDetails,
          [type]: null,
        };
      });
    }
    setDialogState(null);
  };

  const handleOnSave = () => {
    delete clientDetails?.equipments;
    delete clientDetails?.infrastructure;
    const payload = {
      ...clientDetails,
      aadharDocumentUrl: uploadedDocuments?.aadharDocument?.url,
      panDocumentUrl: uploadedDocuments?.panDocument?.url,
      otherDocuments: otherDocuments?.map((file: IDocuments) => file.url) ?? [],
    };
    apiRequest({
      url: `clinic/${hospitalId}`,
      data: payload,
      method: "PATCH",
    })
      .then((res) => {
        if (res) {
          openSnackbar("Documents updated successfully", "success");
          navigate(`/dashboard`);
        }
      })
      .catch((err) => {
        if (navigate) {
          navigate("/login"); // Navigate to "/login" route
        }
      });
  };

  useEffect(() => {
    if (clientDetails) {
      setUploadedDocuments({
        aadharDocument: {
          key: "aadharDocument",
          originalname: "",
          url: clientDetails?.aadharDocumentUrl || "",
          type: "",
        },
        panDocument: {
          key: "panDocument",
          originalname: "",
          url: clientDetails?.panDocumentUrl || "",
          type: "",
        },
      });
      const otherDocs = clientDetails?.otherDocuments?.map(
        (url: string, index: number) => {
          if(url){
            return{
              key: `otherDocuments-${index}`,
              originalname: "",
              url,
              type: "",
            }
          }
        }
      );
      setOtherDocuments(otherDocs);
    }
  }, [clientDetails]);

  return (
    <Box px={4}>
      <Box
        mt={2}
        display="flex"
        flexDirection="column"
        gap={2}
        alignItems="flex-start"
        width="100%"
      >
        {/* Uploads */}
        <Box>
          <Typography> Registration Documents </Typography>
        </Box>
        <Box width="100%">
          <FileUploader
            uploadText="Upload Aadhar Card"
            uploadType="aadharDocument"
            handleUpload={(file: IDocuments) =>
              handleFileChange(file, "aadharDocument")
            }
            accept={["image/*", "application/pdf"]}
          />
          {uploadedDocuments?.aadharDocument && (
            <Box
              display={"flex"}
              gap={1}
              alignItems={"center"}
              justifyContent="center"
              mt={1}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Typography variant="body2">
                  {uploadedDocuments?.aadharDocument?.originalname
                    ? uploadedDocuments?.aadharDocument?.originalname
                    : getTrimmedString(
                        uploadedDocuments?.aadharDocument?.url,
                        10
                      )}
                </Typography>
                <img
                  src={uploadedDocuments?.aadharDocument?.url}
                  alt="aadhar"
                />
              </Box>
              <DeleteForeverIcon
                fontSize="small"
                color="error"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleDeleteFile("aadharDocument");
                }}
              />
            </Box>
          )}
        </Box>
        <Box width="100%">
          <FileUploader
            uploadText="Upload Pan Card"
            uploadType="panDocument"
            handleUpload={(file: IDocuments) =>
              handleFileChange(file, "panDocument")
            }
            accept={["image/*", "application/pdf"]}
          />
          {uploadedDocuments?.panDocument && (
            <Box
              display={"flex"}
              gap={1}
              alignItems={"center"}
              justifyContent="center"
              mt={1}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Typography variant="body2">
                  {uploadedDocuments?.panDocument?.originalname
                    ? uploadedDocuments?.panDocument?.originalname
                    : getTrimmedString(uploadedDocuments?.panDocument?.url, 10)}
                </Typography>
                <img src={uploadedDocuments?.panDocument?.url} alt="pan" />
              </Box>
              <DeleteForeverIcon
                fontSize="small"
                color="error"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleDeleteFile("panDocument");
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box mt={2}>
        <Typography> Other Documents </Typography>
        <Box width="100%">
          <FileUploader
            key={"otherDocuments"}
            uploadType={"otherDocuments"}
            uploadedFiles={otherDocuments}
            setUploadedFiles={setOtherDocuments}
            accept={"image/png,image/jpeg,image/jpg"}
            multiple
            uploadText={"Upload Other Documents"}
          />
          <Box display={"flex"} gap={1} overflow={"auto"} width="100%">
            {otherDocuments?.map((file: IDocuments, index: number) => (
              <Box display={"flex"} justifyContent={"flex-start"} mt={1}>
                <Typography variant="body2">
                  {file?.originalname
                    ? file.originalname
                    : `${getTrimmedString(file.url, 10)}`}
                </Typography>
                <DeleteForeverIcon
                  fontSize="small"
                  key={file.url+index}
                  style={{ cursor: "pointer" }}
                  color="error"
                  onClick={() => {
                    handleDeleteFile("otherDocuments", file);
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <CustomButton
          title={"Cancel"}
          marginRight={"5px"}
          bgColor={"rgba(255, 225, 229, 1)"}
          width={"100px"}
          color={"rgba(255, 97, 116, 1)"}
          borderColor={"rgba(255, 97, 116, 1)"}
          borderWidth={"1px"}
          borderStyle={"solid"}
          onClick={() => {
            navigate(`/dashboard`);
          }}
        />
        <CustomButton
          bgColor={"rgba(92, 78, 255, 1)"}
          onClick={handleOnSave}
          type={"submit"}
          title={"Save"}
        />
      </Box>
      <ConfirmationModal
        open={!!dialogState?.type}
        title={"Are you sure you want to delete the document?"}
        onConfirm={handleDialogConfirm}
        onCancel={() => setDialogState(null)}
      />
    </Box>
  );
};

export default Documents;
