import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ClientProfileCard from "../../../components/ClientProfileCard";
import { Card } from "@material-ui/core";
import style from "../UserView/userView.module.css";
import { theme } from "../../../theme/theme";
import { apiRequest } from "../../../app/services/requests";
import { useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

// import PlanTopCard from "../components/plan-top-card";

const UserView = (props: any) => {
  let { userId } = useParams();

  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState({}) as any;

  useEffect(() => {
    getUserById();
  }, []);

  const getUserById = async () => {
    await apiRequest({
      url: `user/${userId}`, // Use the userId from the URL

      method: "GET",
    })
      .then((res) => {
        if (res) {
          setUserDetails(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        mt={2}
        mr={6}
        p={1}
        gap={1}
      >
        <Typography
          variant="h6"
          color={'blue'}
          fontWeight={"600"}
          fontSize={"16px"}
          onClick={() => navigate("/user/list")}
          style={{ cursor: "pointer" }}
        >
          {'User List'}
        </Typography>
        <Typography
          variant="h6"
          color={theme.palette.customColors.blackGrey}
          fontWeight={"600"}
          fontSize={"16px"}
        >
          {'>'}
        </Typography>
        <Typography
          variant="h6"
          color={theme.palette.customColors.blackGrey}
          fontWeight={"600"}
          fontSize={"16px"}
        >
          User Details
        </Typography>
      </Box>
      <Grid container spacing={2} mt={"10px"}>
        {/* 3 columns */}
        <Grid item xs={3.5}>
          <Box>
            <ClientProfileCard clientDetails={userDetails} />
          </Box>
        </Grid>

        <Grid item xs={8} spacing={2} mt={"2.5px"}>
          <Card className={style.card_height_width}>
            <Grid pb={3}>
              <Box>
                <Typography
                  variant="h6"
                  color={theme.palette.customColors.blackGrey}
                  fontWeight={"600"}
                  fontSize={"16px"}
                >
                  Personal Identity
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Grid container xs={12}>
                  <Grid item xs={6}>
                    <Box paddingRight={1} mt={"10px"}>
                      <img
                        src={userDetails.panDocumentUrl}
                        alt="personImage1"
                        className={style.personImage1}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box paddingRight={1} mt={"10px"}>
                      <img
                        src={userDetails.panDocumentUrl}
                        alt="personImage1"
                        className={style.personImage1}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box mt={2}>
                <Typography
                  variant="h6"
                  color={theme.palette.customColors.blackGrey}
                  fontWeight={"600"}
                  fontSize={"16px"}
                >
                  Careerer Details
                </Typography>
              </Box>

              <Grid container xs={12}>
                {userDetails?.userType === "daDoctor" && (
                  <Grid item xs={4}>
                    <Box paddingRight={1} mt={"10px"}>
                      <Typography>Dental Council Number:</Typography>
                      <Typography variant="body2">
                        {userDetails?.dentalCouncilNumber}
                      </Typography>
                    </Box>
                  </Grid>
                )}

                <Grid item xs={4}>
                  <Box mt={"10px"}>
                    <Typography>Graduation Year:</Typography>
                    <Typography variant="body2">
                      {userDetails?.graduationYear}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Box mt={"10px"}>
                    <Typography>Graduation Institution:</Typography>
                    <Typography variant="body2">
                      {" "}
                      {userDetails?.graduationInstitute}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Box mt={"10px"}>
                    <Typography>Post Graduation Year:</Typography>
                    <Typography variant="body2">
                      {" "}
                      {userDetails?.postGraduationYear}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Box mt={"10px"}>
                    <Typography>Post Graduation Institution:</Typography>
                    <Typography variant="body2">
                      {" "}
                      {userDetails?.postGraduationInstitute}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Box mt={2}>
                <Typography
                  variant="h6"
                  color={theme.palette.customColors.blackGrey}
                  fontWeight={"600"}
                  fontSize={"16px"}
                >
                  Awards and Rewards
                </Typography>
              </Box>

              <Grid container xs={12}>
                <Grid item xs={4}>
                  <Box paddingRight={1} mt={"10px"}>
                    <Typography>Awards and Rewards Points</Typography>
                    <Typography variant="body2">
                      {userDetails.awardsAndRewards?.map(
                        (item: any, index: number) =>
                          `${item}${
                            index !== userDetails.awardsAndRewards.length - 1
                              ? ", "
                              : ""
                          }`
                      )}
                    </Typography>
                  </Box>
                </Grid>

                <Grid container xs={12}>
                  <Box paddingRight={1} mt={"10px"}>
                    <Typography>
                      <strong>Educational Certificates</strong>
                    </Typography>

                    {userDetails?.educationCertificates?.map((item: any) => {
                      return (
                        <Grid item xs={4}>
                          <Box paddingRight={1} mt={"10px"}>
                            <img
                              src={item}
                              alt="personImage1"
                              className={style.personImage1}
                            />
                          </Box>
                        </Grid>
                      );
                    })}
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Box paddingRight={1} mt={"10px"}>
                    <Typography>
                      <strong>Training And Courses</strong>
                    </Typography>
                    <Typography variant="body2">
                      {" "}
                      {userDetails.trainingsAndCourses?.map(
                        (item: any) => item
                      )}
                    </Typography>
                  </Box>
                </Grid>

                <Grid container xs={12}>
                  <Box paddingRight={1} mt={"10px"}>
                    <Typography>
                      <strong>Work Experiance</strong>
                    </Typography>

                    {userDetails?.workExperienceCertificates?.map(
                      (item: any) => {
                        return (
                          <Grid item xs={4}>
                            <Box paddingRight={1} mt={"10px"}>
                              <img
                                src={item}
                                alt="personImage1"
                                className={style.personImage1}
                              />
                            </Box>
                          </Grid>
                        );
                      }
                    )}
                  </Box>
                </Grid>

                <Grid container xs={12}>
                  <Box paddingRight={1} mt={"10px"}>
                    <Typography>
                      <strong>Additional Certificates</strong>
                    </Typography>

                    {userDetails?.additionalCertificates?.map((item: any) => {
                      return (
                        <Grid item xs={4}>
                          <Box paddingRight={1} mt={"10px"}>
                            <img
                              src={item}
                              alt="personImage1"
                              className={style.personImage1}
                            />
                          </Box>
                        </Grid>
                      );
                    })}
                  </Box>
                </Grid>

                <Grid container xs={12}>
                  <Box mt={2}>
                    <Typography
                      variant="h6"
                      color={theme.palette.customColors.blackGrey}
                      fontWeight={"600"}
                      fontSize={"16px"}
                    >
                      Achievements
                    </Typography>
                  </Box>
                </Grid>

                <Grid container xs={12}>
                  {userDetails?.achievementCertificates?.map((item: any) => {
                    return (
                      <Grid item xs={4}>
                        <Box paddingRight={1} mt={"10px"}>
                          <img
                            src={item}
                            alt="personImage1"
                            className={style.personImage1}
                          />
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>

              <Box mt={2}>
                <Typography
                  variant="h6"
                  color={theme.palette.customColors.blackGrey}
                  fontWeight={"600"}
                  fontSize={"16px"}
                >
                  Address For Communication
                </Typography>
              </Box>

              <Grid container xs={12}>
                <Grid item xs={4}>
                  <Box paddingRight={1} mt={"10px"}>
                    <Typography>Address Line 1:</Typography>
                    <Typography variant="body2">
                      {" "}
                      {userDetails?.address?.addressLine1}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Box mt={"10px"}>
                    <Typography>Address Line 2:</Typography>
                    <Typography variant="body2">
                      {" "}
                      {userDetails?.address?.addressLine2}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Box mt={"10px"}>
                    <Typography>Country:</Typography>
                    <Typography variant="body2">
                      {" "}
                      {userDetails?.address?.country}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Box mt={"10px"}>
                    <Typography>State:</Typography>
                    <Typography variant="body2">
                      {" "}
                      {userDetails?.address?.state}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Box mt={"10px"}>
                    <Typography>City:</Typography>
                    <Typography variant="body2">
                      {" "}
                      {userDetails?.address?.city}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box mt={"10px"}>
                    <Typography>Zip Code: </Typography>
                    <Typography variant="body2">
                      {" "}
                      {userDetails?.address?.zipCode}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default UserView;
