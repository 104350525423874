type FormData = {
  [key: string]: any;
};

export const convertStringToNumber = (data: FormData) => {
  const keysToConvert = [
    "premium",
    "limit",
    "coPay",
    "deductible",
    "preventiveDiscount",
    "preventiveWaitingPeriod",
    "preventiveAfterWaitingPeriod",
    "preventiveAfterOneYear",
    "preventiveAfterTwoYear",
    "basicDiscount",
    "basicWaitingPeriod",
    "basicAfterWaitingPeriod",
    "basicAfterOneYear",
    "basicAfterTwoYear",
    "minorDiscount",
    "minorWaitingPeriod",
    "minorAfterWaitingPeriod",
    "minorAfterOneYear",
    "minorAfterTwoYear",
    "majorDiscount",
    "majorWaitingPeriod",
    "majorAfterWaitingPeriod",
    "majorAfterOneYear",
    "majorAfterTwoYear",
    "otherAfterFourYear",
    "otherAfterOneYear",
    "otherAfterThreeYear",
    "otherAfterTwoYear",
    "otherAfterWaitingPeriod",
    "otherDiscount",
    "otherWaitingPeriod",
  ];

  // Iterate over the object and convert strings to numbers where needed
  const convertedData: FormData = { ...data };

  keysToConvert.forEach((key) => {
    if (key in convertedData && typeof convertedData[key] === "string") {
      const parsedValue = Number(convertedData[key]);
      // Check if the conversion was successful (not NaN)
      if (!isNaN(parsedValue)) {
        convertedData[key] = parsedValue;
      }
    }
  });

  return convertedData;
};
