import React, { useEffect, useState } from "react";
import { IRATable } from "../../components/ira-table/ira-table";
import { IRATableColumnProps } from "../../components/ira-table/ira-table.interfaces";
import NameCell from "../../components/TableComponent/name-cell/name-cell";
import ActionCell from "../../components/TableComponent/action-cell";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import CustomDialog from "../../components/CustomDialog";
import SelectClient from "../../components/SelectClient";
import style from "../CasesAndClaims/case-list.module.css";
import ResponseCell from "../../components/TableComponent/responce-cell/response-cell";
import { apiRequest } from "../../app/services/requests";
import moment from "moment";

interface ISelectDialog {
  open?: boolean;
  data?: any;
}

const CaseExamination = (props: any) => {
  const { ExaminationID } = props;

  const [caseExaminationData, setCaseExaminationData] = useState([]) as any;
  // console.log(caseExaminationData);
  const { id } = useParams();
  const navigate = useNavigate();
  const [openSelectClientDialog, setOpenSelectClientDialog] =
    useState<ISelectDialog>({
      open: false,
      data: {},
    });

  useEffect(() => {
    if (ExaminationID && ExaminationID.examinationId) {
      getExaminationById(ExaminationID.examinationId);
    }
  }, [ExaminationID]);

  const getExaminationById = (id: string) => {
    apiRequest({
      url: `case/examination/${id}`, // Use the id parameter instead of caseHistoryId variable

      method: "GET",
    })
      .then((res: any) => {
        if (res) {
          setCaseExaminationData(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  const activityTableColumns = () =>
    [
      {
        name: "toothNo",
        label: "Teeth NO.",
        component: NameCell,
        sortable: false,
        style: { width: "250px" },
      },
      {
        name: "surface",
        label: "Surface",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "findings",
        label: "Findings",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "provisionalDiagnosis",
        label: "Provisional Diagnosis",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "suggestedTreatments",
        label: "Suggested Treatments",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "investigation",
        label: "Investigation",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "finalDiagnosis",
        label: "Final Diagnosis",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "treatmentsPlanned",
        label: "Treatment Planned",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "examinationNotes",
        label: "Exam Notes",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "action",
        label: "Action",
        component: (props: any) => {
          return ActionCell({
            button: true,
            btnVariant: "primary",
            btnSize: "smallest",
            disabled: false,
            infoMessage: null,
            label: "Edit",
            onClick: () => {
              // onViewResult(props?.data);
              navigate(`/cases/${props?.data?.id}/details/${"case-history"}`);
            },
            ...props,
          } as any); // <-- This 'as any' is not needed and might be causing the issue
        },
      } as any,
    ] as Array<IRATableColumnProps>;

  const activityTableData = () => {
    return caseExaminationData?.examinationLines?.map((row: any) => {
      let surface: any;
      row?.surface?.map((row1: any) => {
        surface = row1;
      });

      let suggestedTreatments: any;
      row?.suggestedTreatments?.map((row2: any) => {
        suggestedTreatments = row2;
      });

      let investigation: any;
      row?.investigation?.map((row4: any) => {
        investigation = row4;
      });

      let finalDiagnosis: any;
      row?.finalDiagnosis?.map((row5: any) => {
        finalDiagnosis = row5;
      });
      let treatmentsPlanned: any;
      row?.treatmentsPlanned?.map((row5: any) => {
        treatmentsPlanned = row5;
      });

      return {
        ...row,
        examinationId: {
          name: row?.id,
        },
        createdAt: {
          dateTime: moment(row.createdAt).format("DD-MM-YYYY"),
        },
        toothNo: {
          name: `${row?.teethNumber}`,
        },
        surface: {
          name: `${surface?.name}`,
        },
        findings: {
          name: `${row?.findings ?? 0}`,
        },
        provisionalDiagnosis: {
          name: `${row?.provisionalDiagnosis ?? 0}`,
        },
        suggestedTreatments: {
          name: `${suggestedTreatments?.name ?? ""}`,
        },
        investigation: {
          name: `${investigation?.name ?? ""}`,
        },
        finalDiagnosis: {
          name: `${finalDiagnosis?.name ?? ""}`,
        },
        treatmentsPlanned: {
          name: `${treatmentsPlanned?.name}`,
        },
        examinationNotes: {
          name: `${row?.examinationNotes}`,
        },

        action: {
          data: {
            id: row?.id,
            status: "reviewed",
            project: {
              id: row?.id,
            },
          },
        },
      };
    });
  };

  return (
    <>
      <Box sx={{ pl: 3, pr: 3, borderRadius: 3 }}>
        <IRATable
          className={style.table_heading_layout}
          key={"case-examination-table"}
          columns={activityTableColumns()}
          data={activityTableData()}
          onSort={(name: string, order: string) =>
            alert(`sort requested for ${name} with order ${order}`)
          }
        />
        {openSelectClientDialog.open && (
          <CustomDialog
            width={"400px"}
            open={openSelectClientDialog.open}
            handleClose={() =>
              setOpenSelectClientDialog({ open: false, data: {} })
            }
            title={"Select Client"}
            maxWidth={"lg"}
          >
            <Box>
              <SelectClient
                onClose={() =>
                  setOpenSelectClientDialog({ open: false, data: {} })
                }
                navigateTo={(data: string) => {
                  navigate(`/cases/client/${"121213"}/add`);
                }}
              />
            </Box>
          </CustomDialog>
        )}
      </Box>
    </>
  );
};

export default React.memo(CaseExamination);
