import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CustomCard from "../Card";
import { theme } from "../../theme/theme";
import moment from "moment";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useNavigate } from "react-router-dom";

const ClientProfileCard = (props: any) => {
  const { clientDetails } = props;
  // const navigate = useNavigate();

  // const pencilClickHandler = () => {
  //   navigate("/client/add");
  // };

  return (
    <>
      <Box>
        <CustomCard sx={{ padding: "16px" }}>
          {/* <Box sx={{ color: "blue", cursor: "pointer", textAlign: "right" }}>
            <ModeEditIcon onClick={pencilClickHandler} />
          </Box> */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  mb={"-36px"}
                  alignItems={"center"}
                >
                  <Box
                    padding={"5px"}
                    borderRadius={"50%"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    width={"90px"}
                    border={"1px solid #7C77FE"}
                  >
                    <img
                      width={"80px"}
                      src={
                        clientDetails?.photo ??
                        "https://avatars.mds.yandex.net/i?id=b507a2b8d9382967a186c654f1eeaa74-5262078-images-taas-consumers&n=27&h=480&w=480"
                      }
                      alt="profilePic"
                      style={{
                        borderRadius: "50%",
                        height: "50PX",
                        width: "80px",
                      }}
                    />
                  </Box>
                </Box>
                <Box>
                  <Box
                    width={"100%"}
                    bgcolor={"#E1F1FA"}
                    borderRadius={"11px"}
                    padding={"40px 20px 20px 20px"}
                  >
                    <Box>
                      <Typography
                        variant="h5"
                        fontSize={"16px"}
                        sx={{
                          fontFamily: theme.typography.regular,
                          textAlign: "center",
                        }}
                      >
                        {clientDetails?.firstName} {clientDetails?.lastName}
                      </Typography>
                      <Typography
                        variant="h5"
                        fontSize={"16px"}
                        sx={{
                          fontFamily: theme.typography.regular,
                          textAlign: "center",
                        }}
                      >
                        ID: {clientDetails?.id}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box my={2}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography variant="body2"> Plan Status:</Typography>
                  <Typography variant="body2">
                    <b> {clientDetails?.status ?? "N/A"}</b>
                  </Typography>
                </Box>
                <br></br>
                <Typography
                  variant="h6"
                  color={theme.palette.customColors.blackGrey}
                  fontWeight={"600"}
                  fontSize={"16px"}
                >
                  Client Details
                </Typography>
              </Box>

              {/* <Box
                display={"flex"}
                justifyContent={"space-between"}
                mt={"10px"}
              >
                <Typography variant="body2">Plan Id:</Typography>
                <Typography variant="body2">
                  {clientDetails?.plan?.id ?? "N/A"}
                </Typography>
              </Box> */}
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography variant="body2"> Sex:</Typography>
                <Typography variant="body2">
                  <b> {clientDetails?.gender ?? "N/A"}</b>
                </Typography>
              </Box>
              {/* <Box display={"flex"} justifyContent={"space-between"}>
                <Typography variant="body2"> DOB</Typography>
                <Typography variant="body2">
                  <b> {clientDetails?.dateOfBirth ?? "N/A"}</b>
                </Typography>
              </Box> */}
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                mt={"10px"}
              >
                <Typography variant="body2">DOB:</Typography>
                <Typography variant="body2">
                  {moment(clientDetails?.dateOfBirth).format("DD-MM-YYYY") ??
                    "N/A"}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                mt={"10px"}
              >
                <Typography variant="body2">Marital Status:</Typography>
                <Typography variant="body2">
                  {clientDetails?.maritalStatus ?? "N/A"}
                </Typography>
              </Box>

              <Box my={2}>
                <Typography
                  fontSize={"16px"}
                  variant="h6"
                  color={theme.palette.customColors.blackGrey}
                  fontWeight={"600"}
                >
                  Communication Details
                </Typography>
              </Box>

              <Box
                display={"flex"}
                justifyContent={"space-between"}
                mt={"10px"}
              >
                <Typography variant="body2"> Primary Mobile.No</Typography>
                <Typography variant="body2">
                  {" "}
                  {clientDetails?.mobileNumber ?? "N/A"}
                </Typography>
              </Box>

              <Box
                display={"flex"}
                justifyContent={"space-between"}
                mt={"10px"}
              >
                <Typography variant="body2"> Secondary Mobile.No</Typography>
                <Typography variant="body2">
                  {" "}
                  {clientDetails?.alternateMobileNumber ?? "N/A"}
                </Typography>
              </Box>
              {/* <Box display={"flex"} justifyContent={"space-between"}>
                <Typography variant="body2"> Status</Typography>
                <Typography variant="body2">
                  <b> {clientDetails?.status ?? "Status"}</b>
                </Typography>
              </Box> */}
              {/* <Box
                display={"flex"}
                justifyContent={"space-between"}
                mt={"10px"}
              >
                <Typography variant="body2">First Name</Typography>
                <Typography variant="body2">
                  {clientDetails?.firstName ?? "Prasad"}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                mt={"10px"}
              >
                <Typography variant="body2">Last Name</Typography>
                <Typography variant="body2">
                  {clientDetails?.lastName ?? "Gumma"}
                </Typography>
              </Box> */}
            </Grid>

            <Grid item xs={12}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                mt={"10px"}
              >
                <Typography variant="body2">Email</Typography>
                <Typography variant="body2">
                  {" "}
                  {clientDetails?.email ?? "N/A"}
                </Typography>
              </Box>
              <Box my={2}>
                <Typography
                  fontSize={"16px"}
                  variant="h6"
                  color={theme.palette.customColors.blackGrey}
                  fontWeight={"600"}
                >
                  Address:
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                mt={"10px"}
              >
                <Typography variant="body2">City</Typography>
                <Typography variant="body2">
                  {" "}
                  {clientDetails?.address?.city ?? "N/A"}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                mt={"10px"}
              >
                <Typography variant="body2">State</Typography>
                <Typography variant="body2">
                  {" "}
                  {clientDetails?.address?.state ?? "N/A"}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                mt={"10px"}
              >
                <Typography variant="body2">Country</Typography>
                <Typography variant="body2">
                  {" "}
                  {clientDetails?.address?.country ?? ""}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                mt={"10px"}
              >
                <Typography variant="body2">Postal Code</Typography>
                <Typography variant="body2">
                  {" "}
                  {clientDetails?.address?.postCode ?? clientDetails?.address?.zipCode ?? 'N/A'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CustomCard>
      </Box>
    </>
  );
};

export default ClientProfileCard;
