import React from "react";
import IRATypography from "../../ira-typography";
import { Typography } from "@mui/material";
// import IRAStatusView from '../ira-status-view';

/* eslint-disable-next-line */
export interface IRATableCellViewProps {
  line1?: React.ReactNode;
  line2?: React.ReactNode;
  styles?:any
  // status?: keyof typeof responses;
}

export const IRATableCellView = (props: IRATableCellViewProps) => {
  const { line1, line2 , styles} = props;
  return (
    <div>
      {typeof line1 === "string" ? (
        <Typography fontSize={"14px"} color={styles?.color}>
          {line1}
        </Typography>
      ) : (
        <>{line1} </>
      )}
      {line2 && typeof line2 === "string" ? (
        <IRATypography textVariant="h11WithMedium" color="textSecondary">
          {line2}
        </IRATypography>
      ) : (
        <>{line2} </>
      )}
      {/* {status && <IRAStatusView status={status} />} */}
    </div>
  );
};

export default IRATableCellView;
