import { Box, Grid, Typography } from "@mui/material";

import { useForm, Controller } from "react-hook-form";
import { array, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import CustomSelectPicker from "../../../components/SelectPicker";
import {
  GENDER,
  MARTIAL_STATUS,
  PLAN_NAMES,
  PLAN_NAMES_FOR_DISCOUNT_PLAN,
  PLAN_TYPES,
  PLAN_TYPES_FOR,
  PLAN_VALIDITY,
  RELATIONSHIPS,
} from "../../../app/utilities/constants";
import CustomInput from "../../../components/Input";
import CustomDatePicker from "../../../components/DatePicker";
import CustomButton from "../../../components/Button";

interface IStep1Props {
  onStep1Submit: (data: any) => void;
  onClickSkip: () => void;
  showSkip: boolean;
}

const Step1 = (props: IStep1Props) => {
  const { onStep1Submit, onClickSkip, showSkip } = props;

  const navigate = useNavigate();
  const defaultFamilyMember = {
    name: "Glad",
    relation: "son",
    gender: "male",
    dob: new Date(),
  };

  const userDefaultValues = (actionType?: string) => {
    if (actionType === "reset") {
      return {
        planType: "",
        planFor: "",
        planValidity: "",
        spouseName: "",
        spouseDateOfBirth: new Date(),
        familyMembers: [
          {
            name: "",
            relation: "",
            gender: "",
            dob: new Date(),
          },
        ],
      } as any;
    } else {
      return {
        planType: "20",
        planFor: "individual",
        planValidity: "oneYear",
        spouseName: "MH",
        spouseDateOfBirth: new Date(),
        familyMembers: [defaultFamilyMember],
      } as any;
    }
  };

  const schema = object({
    planType: string().required("Plan Type is required"),
    planFor: string().required("Plan Range is required"),
    planValidity: string().ensure().required("Validity is required"),
    spouseName: string().ensure().required("Spouse Name is required"),
    spouseDateOfBirth: string()
      .ensure()
      .required("Spouse Date of Birth is required"),
    familyMembers: array().of(
      object({
        name: string().required("Name is required"),
        relation: string().required("Relation is required"),
        gender: string().required("Gender is required"),
        dob: string().required("Date of Birth is required"),
      })
    ),
  });

  const { control, handleSubmit, getValues, setValue, watch, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: userDefaultValues("default"),
  });

  watch(["familyMembers", "planFor"]);

  const { familyMembers = [] }: any = getValues();

  const styles = {
    breadcrumbSubtext: {
      fontFamily: "Poppins",
      fontSize: 13,
      color: "#021025",
      fontWeight: "550",
    },
    Container: {
      padding: 2,
    },
    TotalAppointments: {
      fontFamily: "poppins",
      fontSize: 20,
      color: "#021025",
      fontWeight: 600,
    },
    iconContainer: {
      backgroundColor: "primary.main",
      padding: 2,
      borderRadius: "10px",
      height: 38,
      width: 38,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "5px",
      cursor: "pointer",
    },
    addIcon: {
      color: "white",
    },
  } as any;

  const addFamilyMembers = () => {
    const { familyMembers = [] }: any = getValues();
    const modifiedDetails: any = [...familyMembers, defaultFamilyMember];
    setValue("familyMembers", modifiedDetails, {
      shouldValidate: true,
    });
  };

  const removeFamilyMembers = (index: any) => {
    const { familyMembers = [] }: any = getValues();
    const filteredData = familyMembers.filter(
      (previous: any, i: any) => i !== index
    );
    setValue("familyMembers", filteredData, {
      shouldValidate: true,
    });
  };

  const onSubmit = (formData: any) => {
    console.log("formData", formData);

    onStep1Submit({ planType: getValues("planType") });
  };

  const handleViewDetailPlanClick = () => {
    const type = getValues("planType");
    alert("Show Plan details:" + " " + type);
  };

  return (
    <Box p={2}>
      {/* Your Basic Member Details Form or Component */}

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Client Details Section */}
        <Typography variant="body1">Client Details</Typography>

        <Grid container spacing={3}>
          <Grid item xs={6} md={4} mt={4}>
            <Controller
              name="planType"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectPicker
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Select Plan Name"}
                  size={"small"}
                  fullWidth={true}
                  width={"100%"}
                  selectData={PLAN_NAMES_FOR_DISCOUNT_PLAN}
                />
              )}
            />
            <Box onClick={handleViewDetailPlanClick} sx={{ cursor: "pointer" }}>
              <Typography variant="body2">View Detail Plan</Typography>
            </Box>
          </Grid>

          <Grid item xs={6} md={4} mt={4}>
            <Controller
              name="planFor"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectPicker
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Select Plan Range/For"}
                  size={"small"}
                  fullWidth={true}
                  width={"100%"}
                  selectData={PLAN_TYPES_FOR}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} md={4} mt={4}>
            <Controller
              name="planValidity"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectPicker
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  size={"small"}
                  fullWidth={true}
                  width={"100%"}
                  selectData={PLAN_VALIDITY}
                  label={"Select Plan Period"}
                />
              )}
            />
          </Grid>
        </Grid>

        <Box mt={2}>
          <Typography sx={styles.header} mb={1.5} mt={2.5}>
            Family Details
          </Typography>

          <Grid container spacing={2} mb={2}>
            <>
              <Grid item xs={6} md={4}>
                <Controller
                  name={`spouseName`}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      label={"Enter Spouse Name"}
                      size={"small"}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6} md={4}>
                <Controller
                  name={`spouseDateOfBirth`}
                  control={control}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <CustomDatePicker
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      maxDate={new Date()}
                      fullWidth={"fullWidth"}
                      label={"Select Spouse Date of Birth"}
                      onChange={(event: any) => {
                        // field.onChange(event ? getYearMonth(event.$d) : null);
                        field.onChange(event);
                      }}
                      // views={["month", "year"]}
                    />
                  )}
                />
              </Grid>
            </>
          </Grid>
          {getValues("planFor") === "family" && (
            <>
              <Typography sx={styles.header} mb={1.5} mt={2.5}>
                Add Child or Add Members
              </Typography>
              {/* Communication Details Section */}
              <Grid container flexDirection={"column"} spacing={2}>
                {(familyMembers || []).map((previous: any, index: any) => {
                  return (
                    <>
                      <Grid item mb={3}>
                        <Grid container flexDirection={"row"} spacing={1}>
                          <Grid item xs={6} md={4}>
                            <Controller
                              name={`familyMembers.${index}.name`}
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <CustomInput
                                  {...field}
                                  error={!!error}
                                  helperText={error ? error.message : ""}
                                  label={"Enter Name"}
                                  size={"small"}
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={6} md={4}>
                            <Controller
                              name={`familyMembers.${index}.gender`}
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <CustomSelectPicker
                                  {...field}
                                  error={!!error}
                                  helperText={error ? error.message : ""}
                                  size={"small"}
                                  fullWidth={true}
                                  width={"100%"}
                                  selectData={GENDER}
                                  label={"Select Gender"}
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={6} md={4}>
                            <Controller
                              name={`familyMembers.${index}.relation`}
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <CustomSelectPicker
                                  {...field}
                                  error={!!error}
                                  helperText={error ? error.message : ""}
                                  size={"small"}
                                  fullWidth={true}
                                  width={"100%"}
                                  selectData={RELATIONSHIPS}
                                  label={"Select Relation"}
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={6} md={4} mt={1.5}>
                            <Controller
                              name={`familyMembers.${index}.dob`}
                              control={control}
                              render={({
                                field: { ref, ...field },
                                fieldState: { error },
                              }) => (
                                <CustomDatePicker
                                  {...field}
                                  error={!!error}
                                  helperText={error ? error.message : ""}
                                  maxDate={new Date()}
                                  fullWidth={"fullWidth"}
                                  label={"Select Date of birth"}
                                  onChange={(event: any) => {
                                    // field.onChange(event ? getYearMonth(event.$d) : null);
                                    field.onChange(event);
                                  }}
                                  // views={["month", "year"]}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md={2} mt={1.5}>
                            <Grid
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <Box sx={styles.iconContainer}>
                                <AddOutlinedIcon
                                  sx={styles.addIcon}
                                  onClick={() => addFamilyMembers()}
                                />
                              </Box>
                              {index > 0 && (
                                <Box sx={styles.iconContainer}>
                                  <RemoveOutlinedIcon
                                    sx={styles.addIcon}
                                    onClick={() => removeFamilyMembers(index)}
                                  />
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </>
          )}
        </Box>

        <Grid
          container
          flexDirection={"row"}
          spacing={0.5}
          justifyContent={"end"}
        >
          <Grid item>
            <CustomButton
              border="1px solid #000000"
              color="#000000"
              title="Cancel"
              bgColor="#ffffff"
              handleButtonClick={() => navigate("/users/all")}
              borderRadius="5px"
            />
          </Grid>
          <Grid item>
            <CustomButton
              title={"Clear"}
              handleButtonClick={() => {
                reset(userDefaultValues("reset"));
              }}
              border="1px solid #000000"
              color="#000000"
              bgColor="#ffffff"
            />
          </Grid>
          <Grid item>
            <CustomButton
              type="submit"
              title={"Next"}
              backgroundcolor={"#204289"}
              bordercolor={"#204289"}
              color={"#fffff"}
              width={110}
              padding={7}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Step1;
