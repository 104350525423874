import React, { useEffect, useState } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CustomInput from "../../../components/Input";
import CustomCheckbox from "../../../components/CustomCheckBox";
import CustomButton from "../../../components/Button";
import CustomCard from "../../../components/Card";
import CustomSelectPicker from "../../../components/SelectPicker";
import { apiRequest } from "../../../app/services/requests";
import { convertStringToNumber } from "../utills";
import { useNavigate, useParams } from "react-router-dom";
import {
  masterPlanTypeOptions,
  payTypeOptions,
  PUBLISH_PLAN_USER_OPTIONS,
} from "../../../app/utilities/constants";
import { masterPlanDefaultValues, masterPlanSchema } from "./validation-schema";

const AddEditMasterPlan = () => {
  const navigate = useNavigate();
  const { planId } = useParams();
  const [planDetails, setPlanDetails] = useState<any>({});

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<any>({
    resolver: yupResolver(masterPlanSchema),
    defaultValues: masterPlanDefaultValues, // Ensure `masterPlanDefaultValues` has all required fields
  });

  const selectedCategories = useWatch({
    control,
    name: "categories",
    defaultValue: [],
  });

  const selectedDeductibleType = useWatch({
    control,
    name: "deductibleType",
    defaultValue: "percentage",
  });

  const selectedCoPayType = useWatch({
    control,
    name: "coPayType",
    defaultValue: "percentage",
  });

  const premiumWatch = useWatch({
    control,
    name: "premium",
    defaultValue: "",
  });

  const masterPlanTypeWatch = useWatch({
    control,
    name: "type",
    defaultValue: "", // Default to an empty string
  });

  useEffect(() => {
    if (premiumWatch) {
      const claimLimit = parseFloat(premiumWatch) * 3; // Multiply premium by 3
      setValue("limit", claimLimit);
    }
  }, [premiumWatch, setValue]);

  useEffect(() => {
    if (planId) {
      getPlanDetails(planId);
    }
  }, [planId]);

  const getPlanDetails = (planId?: string) => {
    apiRequest({
      url: `master-plan/${planId}/record`,
      method: "GET",
    })
      .then((res: any) => {
        if (res) {
          setPlanDetails({ id: res?.id, status: res?.status });
          reset({
            ...res,
          });
        }
      })
      .catch((err) => {
        //check
      });
  };

  const onSubmit = (data: any) => {
    const formData = convertStringToNumber(data);
    const payload = {
      ...formData,
    };
    if (!planId) {
      apiRequest({
        url: `master-plan/create`,
        data: payload,
        method: "POST",
      })
        .then((res: any) => {
          alert("New Master Plan has been created successfully!");
          navigate("/master/plan-list");
        })
        .catch((err) => {
          // if (err?.statusCode === 403 || err?.statusCode === 401) {
          //   if (navigate) {
          //     navigate("/login"); // Navigate to "/login" route
          //   }
          // }
        });
    } else {
      const {
        id,
        createdAt,
        updatedAt,
        status,
        code,
        publishedAt,
        comments,
        version,
        applicableToExistingUsers,
        ...updatePayload
      } = payload;
      if (planDetails?.status === "published") {
        updatePayload.applicableToExistingUsers = JSON.parse(
          payload?.applicableToExistingUsers
        );
      }
      apiRequest({
        url: `master-plan/${planId}/update`,
        data: updatePayload,
        method: "PATCH",
      })
        .then((res: any) => {
          alert("Master Plan has been updated successfully!");
          navigate("/master/plan-list");
        })
        .catch((err) => {});
    }
  };

  const renderCategoryFields = (
    categoryPrefix: "preventive" | "basic" | "minor" | "major" | "other"
  ) => {
    const capitalPrefix =
      categoryPrefix.charAt(0).toUpperCase() + categoryPrefix.slice(1);
    return (
      <>
        <Grid item xs={12} mb={1}>
          <Typography>{capitalPrefix}</Typography>
        </Grid>

        {/* Grid container for fields */}
        <Grid container spacing={2} item xs={12} mb={3}>
          <Grid item xs={4}>
            <Controller
              name={`${categoryPrefix}Discount` as const}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  type={"number"}
                  label={`${capitalPrefix} Immediately Discount(%)`}
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Grid>
          {masterPlanTypeWatch && masterPlanTypeWatch !== "discount" && (
            <>
              <Grid item xs={4}>
                <Controller
                  name={`${categoryPrefix}WaitingPeriod` as const}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      type={"number"}
                      label={`${capitalPrefix} Waiting Period(In Days)`}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Controller
                  name={`${categoryPrefix}AfterWaitingPeriod` as const}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      type={"number"}
                      label={`${capitalPrefix} Discount After Waiting Period(%)`}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Controller
                  name={`${categoryPrefix}AfterOneYear` as const}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      type={"number"}
                      label={`${capitalPrefix} Discount After One Year (%)`}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Controller
                  name={`${categoryPrefix}AfterTwoYear` as const}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      type={"number"}
                      label={`${capitalPrefix} Discount After Two Years (%)`}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name={`${categoryPrefix}AfterThreeYear` as const}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      type={"number"}
                      label={`${capitalPrefix} Discount After Three Years (%)`}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name={`${categoryPrefix}AfterFourYear` as const}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      type={"number"}
                      label={`${capitalPrefix} Discount After Four Years (%)`}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
      </>
    );
  };

  return (
    <CustomCard sx={{ padding: 4, margin: 2 }}>
      <Box mb={1.5}>
        <Typography variant="body1">Create Plan</Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="type"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectPicker
                  {...field}
                  label="Select Type"
                  error={!!error}
                  helperText={error ? error.message : ""}
                  size={"small"}
                  fullWidth={true}
                  width={"100%"}
                  noneRequired={false}
                  selectData={masterPlanTypeOptions}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="enableCaseHistory"
              control={control}
              render={({ field }) => (
                <CustomCheckbox
                  {...field}
                  label="Enable Case History"
                  labelCustomStyles={{ lineHeight: "10px!important" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  label="Plan Name"
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="premium"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  label="Premium"
                  type="number"
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="limit"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  label="Claim Limit"
                  type="number"
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="coPayType"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectPicker
                  {...field}
                  label="CoPay Type"
                  error={!!error}
                  helperText={error ? error.message : ""}
                  size={"small"}
                  fullWidth={true}
                  width={"100%"}
                  noneRequired={false}
                  selectData={payTypeOptions}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="coPay"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  label={`CoPay(${selectedCoPayType !== "price" ? "%" : "₹"})`}
                  type="number"
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="deductibleType"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectPicker
                  {...field}
                  label="Deductible Type"
                  error={!!error}
                  helperText={error ? error.message : ""}
                  size={"small"}
                  fullWidth={true}
                  width={"100%"}
                  selectData={payTypeOptions}
                  noneRequired={false}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="deductible"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  label={`Deductible(${
                    selectedDeductibleType !== "price" ? "%" : "₹"
                  })`}
                  type="number"
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Select Categories</Typography>
            <Controller
              name="categories"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "16px",
                      flexWrap: "wrap",
                    }}
                  >
                    {["preventive", "basic", "minor", "major", "other"].map(
                      (category) => (
                        <CustomCheckbox
                          key={category}
                          checked={field.value.includes(category)}
                          onChange={() => {
                            const updatedCategories = field.value.includes(
                              category
                            )
                              ? field.value.filter(
                                  (cat: string) => cat !== category
                                )
                              : [...field.value, category];
                            setValue("categories", updatedCategories); // Update the form value
                          }}
                          label={
                            category.charAt(0).toUpperCase() + category.slice(1)
                          }
                        />
                      )
                    )}
                  </div>
                  {selectedCategories?.length === 0 && error && (
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      {error.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {selectedCategories.includes("preventive") &&
              renderCategoryFields("preventive")}
            {selectedCategories.includes("basic") &&
              renderCategoryFields("basic")}
            {selectedCategories.includes("minor") &&
              renderCategoryFields("minor")}
            {selectedCategories.includes("major") &&
              renderCategoryFields("major")}
            {selectedCategories.includes("other") &&
              renderCategoryFields("other")}

            {["preventive", "basic", "minor", "major", "other"].some(
              (category: string) => selectedCategories?.includes(category)
            ) && (
              <Grid item xs={12} mb={1}>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: "bold !important" }}
                >
                  {"*Note: All discounts with continuous coverage only."}
                </Typography>
              </Grid>
            )}
          </Grid>
          {planDetails?.status === "published" && (
            <Grid item xs={12} md={12} style={{ display: "flex" }}>
              <Controller
                name={`applicableToExistingUsers`}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl component="fieldset">
                    <RadioGroup {...field} row>
                      {PUBLISH_PLAN_USER_OPTIONS.map(
                        (option: any, idx: any) => (
                          <FormControlLabel
                            key={idx}
                            value={option.id}
                            control={<Radio />}
                            label={option.name}
                          />
                        )
                      )}
                    </RadioGroup>
                    {error && (
                      <FormHelperText sx={{ color: "#d32f2f !important" }}>
                        {error.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <CustomButton
                border="1px solid #000000"
                color="#000000"
                title="Cancel"
                bgColor="#ffffff"
                onClick={() => navigate("/master/plan-list")}
                borderRadius="5px"
              />
              <Box marginLeft={1}>
                <CustomButton type="submit" title="Submit" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </form>
    </CustomCard>
  );
};

export default AddEditMasterPlan;
