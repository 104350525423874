import { Box, Grid, Typography } from "@mui/material";
import styles from "../../../src/screens/Client/client.module.css";
import React from "react";

const ClientDetailsCounter = (props: any) => {
  const { CounterData } = props;
  return (
    <>
      <Grid item xs={4} className={styles.PlansPaper}>
        <Box display={"flex"} gap={"10px"}>
          <Box
            className={styles.underCard}
            sx={{ background: CounterData.color }}
          ></Box>

          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"space-between"}
            flexDirection={"column"}
          >
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography fontSize={"12px"} variant="h6" fontWeight={600}>
                {CounterData?.heading}
              </Typography>
              {CounterData?.id === 1 && (
                <Box className={styles.activeTextbox}>
                  <Typography sx={{ fontSize: "10px", textAlign: "center" }}>
                    Active
                  </Typography>
                </Box>
              )}
            </Box>
            {CounterData?.id === 1 ? (
              <>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography sx={{ color: "#313035", fontSize: "20px" }}>
                    <strong>Family</strong>
                  </Typography>
                  <Typography
                    sx={{
                      color: "#313035",
                      fontSize: "12px",
                      p: 1,
                      fontWeight: 600,
                    }}
                  >
                    monthly
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{ color: "#313035", fontSize: "10px", fontWeight: 600 }}
                  >
                    {CounterData?.remainingdays} days remaining
                  </Typography>
                </Box>
              </>
            ) : (
              <Typography sx={{ fontSize: "26px", fontWeight: 600 }}>
                {CounterData.claimsCount}
              </Typography>
            )}
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default ClientDetailsCounter;
