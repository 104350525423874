import { Box, Divider, Grid, Typography } from "@mui/material";
import CustomButton from "../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CustomToggleButton from "../../../components/ToggleButtons";
import ServicesTreatmentsCoveredList from "./services-treatments-covered-list";
import ClientDetailsCounter from "../../Client/client-details-counter";
import CustomCard from "../../../components/Card";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { apiRequest } from "../../../app/services/requests";

const UpgradePlanDetails = (props: any) => {
  const navigate = useNavigate();

  const [alignment, setAlignment] = useState<string | null>("Monthly");
  const alignmentButtons = ["Monthly", "Yearly"]; // Specify the buttons you want

  const { clientId } = useParams();
  // console.log(clientId, "CID");

  const handleAlignmentChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    setAlignment(newAlignment);
  };
  let data = [
    {
      id: 1,
      heading: "Plans",
      remainingdays: "22",
      color: "#219FFF4D",
    },
    {
      id: 2,
      heading: "Claims",
      claimsCount: "05",
      color: "#68D4B44D",
    },
    {
      id: 3,
      heading: "Cases",
      claimsCount: "08",
      color: "#FF98A54D",
    },
  ];

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", gap: 2, borderRadius: 6 }}>
          {data?.map((item) => {
            return <ClientDetailsCounter CounterData={item} />;
          })}
        </Box>

        <Box sx={{ mt: "10px" }}>
          <CustomCard>
            <Box p={2} mb={2}>
              <Box onClick={props?.onHandleBackArrow} display={"flex"}>
                <ArrowBackIcon sx={{ color: "blue", cursor: "pointer" }} />
                <Typography
                  fontWeight={600}
                  sx={{ cursor: "pointer" }}
                >{`Upgrade Plan`}</Typography>
              </Box>
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Box
                      display="flex"
                      justifyContent={"flex-start"}
                      flexDirection={"column"}
                      alignItems="center"
                    >
                      <Typography
                        fontSize={25}
                        fontWeight={700}
                      >{`Rs.1800`}</Typography>

                      <Box>
                        <CustomToggleButton
                          value={alignment}
                          onChange={handleAlignmentChange}
                          buttons={alignmentButtons}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box display="flex" alignItems="center">
                          <Box>
                            <Typography>Preventive : </Typography>
                          </Box>
                          <Typography>{`3/ 10 (50%)`}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box display="flex" alignItems="center">
                          <Box>
                            <Typography> Basic : </Typography>
                          </Box>
                          <Typography>{`3/ 10 (50%)`}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box display="flex" alignItems="center">
                          <Box>
                            <Typography> Minor : </Typography>
                          </Box>
                          <Typography>{`3/ 10 (50%)`}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box display="flex" alignItems="center">
                          <Box>
                            <Typography> Major : </Typography>
                          </Box>
                          <Typography>{`3/ 10 (50%)`}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Divider sx={{ my: 1 }} /> {/* Adjust margin as needed */}
            <Box p={2}>
              <Box>
                <Typography variant="body1">
                  Services Treatment Covered
                </Typography>
              </Box>
              <Box px={2}>
                <ServicesTreatmentsCoveredList />
              </Box>
            </Box>
            <hr></hr>
            <Box
              pb={5}
              pt={5}
              display={"flex"}
              gap={2}
              justifyContent={"flex-end"}
              mr={5}
            >
              <CustomButton
                border="1px solid #000000"
                color="#000000"
                title="Cancel"
                bgColor="#ffffff"
                handleButtonClick={() => navigate("/dashboard")}
                borderRadius="5px"
              />
              <CustomButton
                handleButtonClick={() =>
                  navigate(`/client/${clientId}/plan/payment`)
                }
                type="button"
                title={"Upgrade with Plan"}
                backgroundcolor={"#204289"}
                bordercolor={"#204289"}
                color={"#fffff"}
                // padding={7}
              />
            </Box>
          </CustomCard>
        </Box>
      </Box>
    </>
  );
};

export default UpgradePlanDetails;
