import { Box, Typography } from "@mui/material";
import React from "react";
import { apiRequest } from "../../app/services/requests";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import CustomSelectPicker from "../../components/SelectPicker";
import CustomDatePicker from "../../components/DatePicker";
import CustomButton from "../../components/Button";
import CustomInput from "../../components/Input";
import {
  extractErrorMessage,
  getConvertTimeToDate,
} from "../../app/utilities/utils";
import { useSnackbar } from "../../app/context/useSnackbar";

interface ClientAppointmentProps {
  userDetails: any;
  onClose: any;
}

const timeSlots = [
  {
    id: "1",
    name: "8:00 AM",
  },
  {
    id: "1",
    name: "9:00 AM",
  },
];

const ClientAppointmentForm = (props: ClientAppointmentProps) => {
  const { openSnackbar } = useSnackbar();
  const schema = object({
    plan: string().ensure().required("Plan required"),
    clinic: string().ensure().required("clinic required"),
    startDate: string().ensure().required("Date is required"),
    startTime: string().ensure().required("Time required"),
  });
  const userDefaultValues = () => {
    return {
      plan: "",
      clinic: "",
      startDate: "",
      startTime: "",
    } as any;
  };

  const { control, handleSubmit, getValues, watch, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: userDefaultValues(),
  });
  const navigate = useNavigate();
  const { userDetails, onClose } = props;
  const { id: clientId } = userDetails?.data?.client;
  const [clinicList, setClinicList] = React.useState([]);
  const [masterPlanList, setMasterPlanList] = React.useState<{
    totalCount: any;
    results: any[];
  } | null>(null);
  const [clientPlanDetails, setClientPlanDetails] = React.useState<any>(null);
  const currentPlanDetails = React.useRef<{ id: string | number } | null>(null);

  // get master-plan list
  const getMasterPlanList = () => {
    apiRequest({
      url: `master-plan/list?type=standard&status=published`,
      method: "GET",
    }).then((res: any) => {
      if (res) {
        const modifiedRes = {
          totalCount: res?.totalCount,
          results: res?.results?.map((data: any) => ({
            id: data?.id,
            name: data?.name,
            code: data?.code,
            type: data?.type,
            premium: data?.premium,
            limit: data?.limit,
            status: data?.status,
          })),
        };
        setMasterPlanList(modifiedRes);
        getClientPlanDetails(modifiedRes);
      }
    });
  };

  // get client plan details
  const getClientPlanDetails = async (
    planList: { totalCount: any; results: any[] } | null
  ) => {
    await apiRequest({
      url: `plan/current/${clientId}`,
      method: "GET",
    })
      .then((response: any) => {
        console.log("client plan fetched", response);
        currentPlanDetails.current =
          planList?.results?.find(
            (planData) => planData.id === response?.masterPlanId
          ) || null;
        setClientPlanDetails(response);
        reset({ plan: currentPlanDetails?.current?.id });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  // get Clinic list
  const getClinicList = async () => {
    await apiRequest({
      url: `clinic/list`, // Use the userId from the URL
      method: "POST",
    })
      .then((res: any) => {
        setClinicList(res?.data);
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  const onSubmit = (data: any) => {
    console.log(data);
    const { clinic, plan, startTime, startDate, type, mobileNumber } = data;
    // const timeSlot = timeSlots.find((time) => time.id === startTime);
    const { totalMembers = 0 } = clientPlanDetails || {};

    const appointmentAt = getConvertTimeToDate(startDate, startTime);
    const payload = {
      clientId: Number(clientId),
      type,
      clinicId: Number(clinic),
      planId: Number(plan),
      appointmentAt,
      mobileNumber,
      noOfMembers:totalMembers
    };
    apiRequest({
      url: `appointment/create`,
      data: payload,
      method: "POST",
    })
      .then((res) => {
        console.log("client Saved", res);
        onClose();
      })
      .catch((err) => {
        console.error(err);
        openSnackbar(extractErrorMessage(err));
      });
  };
  React.useEffect(() => {
    Promise.all([getClinicList(), getMasterPlanList()]);
  }, []);
  return (
    <Box display="flex" alignContent="center" flexDirection="column">
      <Box display="flex" justifyContent="flex-start" mb={2}>
        {/* todo -> need to replace count after getting client plan */}
        <Typography variant="body1">{`Family Count: 6`}</Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" gap="8px">
            <Controller
              name="plan"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectPicker
                  {...field}
                  error={!!error}
                  disabled={!!currentPlanDetails?.current}
                  helperText={error ? error.message : ""}
                  label={"Select Plan"}
                  size={"small"}
                  fullWidth={true}
                  width={"100%"}
                  selectData={masterPlanList?.results}
                />
              )}
            />
            <Controller
              name="clinic"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectPicker
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Select Clinic"}
                  size={"small"}
                  fullWidth={true}
                  width={"100%"}
                  selectData={clinicList}
                />
              )}
            />
            <Controller
              name="mobileNumber"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Enter Mobile Number"}
                  width="150px"
                  fullWidth={true}
                />
              )}
            />
          </Box>
          <Box display="flex" gap="8px">
            <Controller
              name="type"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectPicker
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Select Appointment Type"}
                  size={"small"}
                  fullWidth={true}
                  width={"100%"}
                  selectData={[
                    {
                      id: "outpatient",
                      name: "OP",
                    },
                    {
                      id: "treatment",
                      name: "treatment",
                    },
                    {
                      id: "diagnostic",
                      name: "Pre-Plan Diagnosis",
                    },
                  ]}
                />
              )}
            />
            <Controller
              name={"startDate"}
              rules={{ required: true }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomDatePicker
                  {...field}
                  label={"Start Date"}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  disablePast={true}
                />
              )}
            />
            <Controller
              name="startTime"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectPicker
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Start Time"}
                  size={"small"}
                  fullWidth={true}
                  width={"100%"}
                  selectData={timeSlots}
                />
              )}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <CustomButton
              border="1px solid #000000"
              color="#000000"
              title="Cancel"
              bgColor="#ffffff"
              onClick={() => onClose()}
              borderRadius="5px"
            />
            <Box marginLeft={1}>
              {" "}
              {/* Add a margin to create space between buttons */}
              <CustomButton title={"Create"} type="submit" borderRadius="5px" />
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default ClientAppointmentForm;
