import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

interface CustomToggleButtonProps {
  value: string | null;
  onChange: (
    event: React.MouseEvent<HTMLElement>,
    alignment: string | null
  ) => void;
  buttons: string[];
  props: any;
}

const CustomToggleButton: React.FC<any> = ({
  value,
  onChange,
  buttons,
  props,
}) => {
  const capitalizeFirstLetter = (str: string) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
  };

  return (
    <ToggleButtonGroup
      value={value}
      color="primary"
      sx={{
        borderRadius: "1px solid red",
      }}
      exclusive
      onChange={onChange}
      aria-label="text alignment"
    >
      {buttons.map((buttonValue: any) => (
        <ToggleButton
          key={buttonValue}
          value={buttonValue}
          aria-label={`${buttonValue} aligned`}
          sx={{
            border: props?.border ? props.border : "1px solid #ccc",
            fontSize: props?.fontSize ? props.fontSize : "14px",
            height: props?.height ? props.height : "36px",
            textTransform: props?.textTransform
              ? props?.textTransform
              : "lowercase",
          }}
        >
          {capitalizeFirstLetter(buttonValue)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default CustomToggleButton;
