import React, { useState } from "react";
import { Box, Typography, Checkbox } from "@mui/material";
import moment from "moment";
import CustomAutocomplete from "../../../components/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";
import { styles } from "./styles";
import CustomButton from "../../../components/Button";
import SmartText from "../../../components/SmartText";
import CustomModal from "../../../components/Modal";
import { convertObjectToString } from "../../../app/utilities/utils";
import AddEditInterpretation from "../Investigation/add-edit-interpretation";
import {
  MAJOR_INVESTIGATIONS,
  PROVISIONAL_DIAGNOSIS,
} from "../../../app/utilities/staticData";
import "./index.css";

interface ExaminationTableInterface {
  investigationData: any;
  setInvestigationData: any;
  editInvestigation: any;
  deleteInvestigation: any;
  doctorName: any;
  investigationOptions: any;
  treatments: any;
  majorInvestigation: any;
  setMajorInvestigation: any;
}

const ExaminationTable = (Props: ExaminationTableInterface) => {
  const {
    investigationData,
    setInvestigationData,
    editInvestigation,
    majorInvestigation,
    setMajorInvestigation,
    deleteInvestigation,
    doctorName,
    investigationOptions,
    treatments = [],
  } = Props;
  const [openInvestigationModal, setOpenInvestigationModal] = useState<any>({
    open: false,
    data: {},
  } as any);
  const [searchByType, setSearchByType] = useState("");

  const updateInvestigationData = (index: any, val: any, key: any) => {
    const clonedData = JSON.parse(JSON.stringify(investigationData));
    clonedData[index][key] = val;
    setInvestigationData(clonedData);
  };

  const getTeethSurfaceMapping = (teethNo: any, surfaces: any) => {
    return (surfaces || [])
      .reduce((acc: any, surface: any) => {
        acc.push(`${teethNo}${surface.value.charAt(0).toUpperCase()}`);
        return acc;
      }, [])
      .toString();
  };

  const getTeethSingleSurfaceMapping = (teethNos: any) => {
    return teethNos
      .reduce((acc: any, teeth: any) => {
        acc.push(
          `${teeth.teethNumber}${
            teeth.surface && teeth.surface.length
              ? teeth.surface[0].value.charAt(0).toUpperCase()
              : ""
          }`
        );
        return acc;
      }, [])
      .toString();
  };

  const getActionIcons = (
    row: any,
    index: any,
    mainIndex: any,
    teethType: any,
    completeRowData: any = {}
  ) => {
    return (
      <Box className="flex gap10">
        <Box
          className="flex-direction-column pointer align-items-center"
          onClick={() =>
            editInvestigation(row, index, teethType, completeRowData)
          }
        >
          <CreateOutlinedIcon sx={styles.actionIcon} />
          <Typography sx={styles.tableCell}>Edit</Typography>
        </Box>
        <Box
          className="flex-direction-column pointer align-items-center"
          onClick={() => deleteInvestigation(index, mainIndex, teethType)}
        >
          <DeleteOutlineOutlined sx={styles.deleteActionIcon} />
          <Typography sx={styles.deleteTableCell}>Delete</Typography>
        </Box>
      </Box>
    );
  };

  const handleInvestigationCloseModal = () => {
    setOpenInvestigationModal({
      open: false,
      data: {},
    });
  };

  const handleInterpretationSubmit = (formData: any) => {
    updateInvestigationData(
      openInvestigationModal.data.mainIndex,
      formData.interpretation,
      "examinationNotes"
    );
    handleInvestigationCloseModal();
  };

  const handleOpenInvestigationModal = (data: any) => {
    setOpenInvestigationModal({
      open: true,
      data: {
        ...data,
      },
    });
  };

  return (
    <Box className="flex-direction-column gap10">
      <Box className="space-between">
        <Typography ml={3} sx={{ color: "#204289", marginLeft: "0px" }}>
          {moment().format("DD MMM YYYY")} | {doctorName}
        </Typography>
        <Box className="width25">
          <CustomAutocomplete
            multiple
            value={majorInvestigation ? majorInvestigation : []}
            onChange={(event: React.SyntheticEvent, value: any) =>
              setMajorInvestigation(value)
            }
            options={investigationOptions ? investigationOptions : []}
            placeholder="Major Investigation"
          />
        </Box>
      </Box>
      <table className="examination-table">
        <thead className="primary-label">
          <tr>
            <td>Tooth No</td>
            <td>Surfaces</td>
            <td>Findings</td>
            <td>Provisional Diagnosis</td>
            <td>Suggested Treatment</td>
            <td>Investigations</td>
            <td>Notes</td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>
          {investigationData?.map((row: any, mainIndex: any) => {
            let rowSpan = {};
            if (row.teethType === "periodental") {
              rowSpan = { rowSpan: row.surface.length };
            } else {
              rowSpan =
                row.teethNumber.length <= 2
                  ? { rowSpan: row.toothNumbers.length }
                  : {};
            }
            return (
              <>
                <tr>
                  <td {...rowSpan}>
                    <div style={{ alignItems: "start", display: "flex" }}>
                      {row.teethType === "periodental" && (
                        <div className="charttype-div">Periodontal</div>
                      )}
                      {/* {row.teethNumber} */}
                      <SmartText
                        text={`${
                          row.teethType === "periodental"
                            ? row.teethNumber
                            : row.teethNumber.replaceAll(",", "  ")
                        }`}
                      />
                    </div>
                  </td>
                  <td>
                    {row.teethType === "periodental" ? (
                      `${row.teethNumber}${row.surface[0].value
                        .charAt(0)
                        .toUpperCase()}`
                    ) : (
                      <>
                        {row.teethNumber.length <= 2 ? (
                          getTeethSurfaceMapping(
                            row.toothNumbers[0].teethNumber,
                            row.toothNumbers[0].surface
                          ).replaceAll(",", "  ")
                        ) : (
                          <SmartText
                            text={getTeethSingleSurfaceMapping(
                              row.toothNumbers
                            ).replaceAll(",", "  ")}
                          />
                        )}
                      </>
                    )}
                  </td>
                  <td>
                    {row.teethType === "periodental"
                      ? convertObjectToString(row.surface[0].findings)
                      : row.toothNumbers[0].findings}
                  </td>
                  <td {...rowSpan}>
                    <CustomAutocomplete
                      value={row.provisionalDiagnosis || ""}
                      singleSelection
                      addText
                      onChange={(event: React.SyntheticEvent, value: any) =>
                        updateInvestigationData(
                          mainIndex,
                          value,
                          "provisionalDiagnosis"
                        )
                      }
                      options={PROVISIONAL_DIAGNOSIS}
                      label="Provisional Diagnosis"
                    />
                  </td>
                  <td {...rowSpan}>
                    <CustomAutocomplete
                      multiple
                      value={row.suggestedTreatments || []}
                      onChange={(event: React.SyntheticEvent, value: any) =>
                        updateInvestigationData(
                          mainIndex,
                          value,
                          "suggestedTreatments"
                        )
                      }
                      options={treatments ? treatments : []}
                      placeholder="Suggested Treatments"
                    />
                  </td>
                  <td {...rowSpan}>
                    <CustomAutocomplete
                      multiple
                      value={row.investigation || []}
                      onChange={(event: React.SyntheticEvent, value: any) =>
                        updateInvestigationData(
                          mainIndex,
                          value,
                          "investigation"
                        )
                      }
                      options={investigationOptions ? investigationOptions : []}
                      placeholder="Investigation"
                    />
                  </td>
                  <td {...rowSpan}>
                    {row.examinationNotes ? (
                      <Box
                        className="flex-direction-column pointer align-items-center"
                        onClick={() => {
                          setOpenInvestigationModal({
                            open: true,
                            data: {
                              examinationNotes: row.examinationNotes,
                              mainIndex,
                            },
                          });
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            height: 25,
                            width: 25,
                          }}
                          alt={row.id + "alt"}
                          src={require("../../../app/assets/activeaccounts.svg")}
                        />
                        <Typography sx={{ ...styles.tableCell, margin: 0 }}>
                          Note
                        </Typography>
                      </Box>
                    ) : (
                      <CustomButton
                        title={"Add"}
                        handleButtonClick={() => {
                          handleOpenInvestigationModal({
                            examinationNotes: "",
                            mainIndex,
                          });
                        }}
                        className="btn-primary"
                      />
                    )}
                  </td>
                  <td>
                    {row.teethType === "periodental" ? (
                      <>
                        {getActionIcons(
                          row.surface[0],
                          row.surface.length > 1 ? 0 : undefined,
                          mainIndex,
                          row.teethType,
                          row
                        )}
                      </>
                    ) : (
                      <>
                        {row.teethNumber.length <= 2
                          ? getActionIcons(
                              row.toothNumbers[0],
                              row.toothNumbers.length > 1 ? 0 : undefined,
                              mainIndex,
                              row.teethType
                            )
                          : getActionIcons(
                              row,
                              undefined,
                              mainIndex,
                              row.teethType
                            )}
                      </>
                    )}
                  </td>
                </tr>
                {row.teethType === "periodental" ? (
                  <>
                    {row.surface.map(
                      (surface: any, index: any) =>
                        index !== 0 && (
                          <tr>
                            <td>{`${row.teethNumber}${surface.value
                              .charAt(0)
                              .toUpperCase()}`}</td>
                            <td>{convertObjectToString(surface.findings)}</td>
                            <td>
                              {getActionIcons(
                                surface,
                                index,
                                mainIndex,
                                row.teethType,
                                row
                              )}
                            </td>
                          </tr>
                        )
                    )}
                  </>
                ) : (
                  <>
                    {row.teethNumber.length <= 2 && (
                      <>
                        {row.toothNumbers.map(
                          (tooth: any, index: any) =>
                            index !== 0 && (
                              <tr>
                                <td>
                                  {getTeethSurfaceMapping(
                                    tooth.teethNumber,
                                    tooth.surface
                                  )}
                                </td>
                                <td>{tooth.findings}</td>
                                <td>
                                  {getActionIcons(
                                    tooth,
                                    index,
                                    mainIndex,
                                    row.teethType
                                  )}
                                </td>
                              </tr>
                            )
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            );
          })}
        </tbody>
      </table>
      <CustomModal
        zIndex={"99999"}
        open={openInvestigationModal.open}
        width={"500px"}
        height={"auto"}
      >
        <Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography className="primary-label">Examination Notes</Typography>
            <CloseIcon
              onClick={handleInvestigationCloseModal}
              className="pointer"
            />
          </Box>
          <Box mt={"20px"}>
            <AddEditInterpretation
              onCancel={handleInvestigationCloseModal}
              onSubmit={handleInterpretationSubmit}
              defaultValue={{
                interpretation: openInvestigationModal.data.examinationNotes,
              }}
              label="Enter Exam Notes"
              errorMsg="Examination notes is required"
            />
          </Box>
        </Box>
      </CustomModal>
    </Box>
  );
};

export default ExaminationTable;
