import axios from "axios";
import { BACKEND_SERVER_URL } from "../utilities/constants";

const axiosInstance = axios.create({
  baseURL: `${BACKEND_SERVER_URL}/v1/`,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const userString = localStorage.getItem("user");
    const user = userString ? JSON.parse(userString) : null;

    if (user?.accessToken) {
      config.headers.Authorization = `Bearer ${user.accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("API Error:", error?.response);

    // Handle CORS errors (net::ERR_FAILED, CORS policy)
    if (!error.response) {
      console.error("CORS or Network Issue Detected");
      alert("Something went wrong!");
      localStorage.clear();
      window.location.href = "/login";
      return Promise.reject(new Error("CORS Error"));
    }

    // Handle Forbidden (403) Errors
    if (error.response.status === 403) {
      localStorage.clear();
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
