import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../app/context/useSnackbar";
import { useAppContext } from "../../app/context/AppContext";
import CustomInput from "../../components/Input";
import CustomButton from "../../components/Button";

import CustomDatePicker from "../../components/DatePicker";
import CustomSelectPicker from "../../components/SelectPicker";
import {
  APPOINTMENT_CLIENT_OPTIONS,
  PHONE_REG_EXP,
} from "../../app/utilities/constants";
import { apiRequest } from "../../app/services/requests";
import moment from "moment";

const BookAppointmentForm = (props: any) => {
  const { currentSubCategory = {} as any } = props;

  const navigate = useNavigate();

  const [appointmentData, setAppointmentData] = useState() as any;
  const { handleLogoutSuccess, user } = useAppContext() as any;
  const { openSnackbar } = useSnackbar() as any;

  const [clientList, setClientList] = useState() as any;
  const [doctorList, setDoctorList] = useState() as any;

  const userDefaultValues = () => {
    return {
      clientType: "newClient",
      mobileNumber: "",
      appointmentType: "",
      clientId: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      doctorId: "",
    } as any;
  };

  const schema = object({
    mobileNumber: string()
      .required("Mobile number is required")
      .matches(PHONE_REG_EXP, "Phone number is not valid"),
    appointmentType: string().required("Type is required"),
    clientId: string().required("Patient is required"),
    startDate: string().ensure().required("Date is required"),
    endDate: string().ensure().required("Date is required"),
    startTime: string().ensure().required("Time required"),
    endTime: string().ensure().required("Time required"),
    doctorId: string().ensure().required("Doctor required"),
  });

  const { control, handleSubmit, getValues, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: userDefaultValues(),
  });

  const getClientList = () => {
    apiRequest({
      url: `client/all`, // Use the userId from the URL
      method: "POST",
    })
      .then((res) => {
        let data = res?.data?.map((item: any) => ({
          name: `${item?.firstName} ${item?.lastName}`,
          id: item?.id,
          mobileNumber: item?.mobileNumber,
        }));
        setClientList(data);
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  const getDoctorsByClient = (clientId: any) => {
    apiRequest({
      url: `clinic/${clientId}/doctor/list`, // Use the userId from the URL
      method: "POST",
    })
      .then((res) => {
        let data = res?.data?.map((item: any) => ({
          name: `${item?.firstName} ${item?.lastName}`,
          id: item?.id,
        }));
        setDoctorList(data);
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  useEffect(() => {
    if (user?.success) {
      getClientList();
      getDoctorsByClient(user?.data?.client?.id);
    }
  }, [user?.success]);

  watch(["clientType", "clientId"]);
  const { clientType, clientId } = getValues();
  useEffect(() => {
    if (clientId) {
      setValue("mobileNumber", getClientById(clientId));
    }
  }, [clientId]);

  const getClientById = (id: any) => {
    debugger;
    const { mobileNumber = "" } =
      clientList.find((client: any) => client.id === id) || {};
    return mobileNumber;
  };

  const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZ"; // ISO 8601 format

  const onSubmit = (formData: any) => {
    const { clientType, doctorId, clientId, startDate, endDate, ...rest } =
      formData;
    // moment(startDate).format(DATE_TIME_FORMAT),
    const payload = {
      doctorId: Number(doctorId),
      clientId: Number(clientId),
      startDate: startDate ? new Date(startDate) : undefined,
      endDate: endDate ? new Date(endDate) : undefined,
      ...rest,
    };

    apiRequest({
      url: `appointment`,
      data: payload,
      method: "POST",
    })
      .then((res) => {})
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container item spacing={3}>
          <Grid item xs={12} md={12} style={{ display: "flex" }}>
            <Controller
              name={`clientType`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl component="fieldset">
                  <RadioGroup {...field} row>
                    {APPOINTMENT_CLIENT_OPTIONS.map((option: any, idx: any) => (
                      <FormControlLabel
                        key={idx}
                        value={option.id}
                        control={<Radio />}
                        label={option.name}
                      />
                    ))}
                  </RadioGroup>
                  {error && (
                    <FormHelperText sx={{ color: "#d32f2f !important" }}>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          {clientType === "existingClient" && (
            <Grid container item spacing={3} mb={2}>
              <Grid item xs={6} md={6}>
                <Controller
                  name="clientId"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomSelectPicker
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      label={"Select Client"}
                      size={"small"}
                      fullWidth={true}
                      width={"100%"}
                      selectData={clientList}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Controller
                  name="doctorId"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomSelectPicker
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      label={"Select Doctor"}
                      size={"small"}
                      fullWidth={true}
                      width={"100%"}
                      selectData={doctorList}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        {/* Client Details Section */}
        <Grid container spacing={3} mt={1}>
          {/* Add client details fields here */}
          <Grid item xs={12} md={6}>
            <Controller
              name="mobileNumber"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  label={"Client Mobile No"}
                  type="text"
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  disabled={Boolean(clientType === "existingClient")}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="appointmentType"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectPicker
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Appointment Type"}
                  size={"small"}
                  fullWidth={true}
                  width={"100%"}
                  selectData={[
                    {
                      id: "OP",
                      name: "OP",
                    },
                  ]}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={3} mt={1}>
          <Grid item xs={6} md={6}>
            <Box sx={{ width: "100%" }}>
              <Controller
                name={"startDate"}
                rules={{ required: true }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomDatePicker
                    {...field}
                    label={"Start Date"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                    disablePast={true}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="startTime"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectPicker
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Start Time"}
                  size={"small"}
                  fullWidth={true}
                  width={"100%"}
                  selectData={[
                    {
                      id: "1",
                      name: "8:00 AM",
                    },
                    {
                      id: "1",
                      name: "9:00 AM",
                    },
                  ]}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={3} mt={1}>
          <Grid item xs={6} md={6}>
            <Box sx={{ width: "100%" }}>
              <Controller
                name={"endDate"}
                rules={{ required: true }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomDatePicker
                    {...field}
                    label={"End Date"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                    disablePast={true}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="endTime"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectPicker
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"End Time"}
                  size={"small"}
                  fullWidth={true}
                  width={"100%"}
                  selectData={[
                    {
                      id: "1",
                      name: "8:00 PM",
                    },
                    {
                      id: "1",
                      name: "9:00 PM",
                    },
                  ]}
                />
              )}
            />
          </Grid>
        </Grid>
        {/* Additional Sections can be added as needed */}
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <CustomButton
            border="1px solid #000000"
            color="#000000"
            title="Cancel"
            bgColor="#ffffff"
            onClick={() => props.onClose()}
            borderRadius="5px"
          />
          <Box marginLeft={1}>
            {" "}
            {/* Add a margin to create space between buttons */}
            <CustomButton
              title={currentSubCategory?.open === "add" ? "Create" : "Save"}
              type="submit"
              borderRadius="5px"
            />
          </Box>
        </Box>
      </form>
    </>
  );
};

export default BookAppointmentForm;
