import React, { useEffect, useState } from "react";
import { IRATable } from "../../components/ira-table/ira-table";
import DateTimeCell from "../../components/TableComponent/date-time-cell";
import { IRATableColumnProps } from "../../components/ira-table/ira-table.interfaces";
import NameCell from "../../components/TableComponent/name-cell/name-cell";
import ActionCell from "../../components/TableComponent/action-cell";
import Breadcrumb from "../../components/Breadcrumb";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { styles } from "./styles";
import IRATypography from "../../components/ira-typography";
import { Box, Card, Grid, InputAdornment, Typography } from "@mui/material";
import Button from "../../components/TableComponent/button";
import CustomButton from "../../components/Button";
import CustomDialog from "../../components/CustomDialog";
import SelectClient from "../../components/SelectClient";
import BookAppointmentForm from "./book-appointment-form";
import CustomInput from "../../components/Input";
import { Close, Search } from "@mui/icons-material";
import CustomDatePicker from "../../components/DatePicker";
import { apiRequest } from "../../app/services/requests";
import moment from "moment";
import ClientAppointmentForm from "./client-book-appointment-form";

export interface ISelectDialog {
  open?: boolean;
  data?: any;
}

const AppointmentList = () => {
  const navigate = useNavigate();
  const currentUserDetails = JSON.parse(localStorage.getItem("user") || "{}");
  const { userType } = currentUserDetails?.data;
  const [openSelectClientDialog, setOpenSelectClientDialog] =
    useState<ISelectDialog>({
      open: false,
      data: {},
    });

  const [appointmentList, setAppointmentList] = useState({
    results: [],
    totalCount: 0,
  });
  const [searchTerm, setSearchTerm] = React.useState("");

  useEffect(() => {
    getAllAppointmentList();
  }, [searchTerm]);
  const getAllAppointmentList = () => {
    const payload = {
      ...(searchTerm && {
        searchText: searchTerm,
      }),
    };
    apiRequest({
      url: `appointment/list`,
      data: payload,

      method: "POST",
    })
      .then((res: any) => {
        if (res) {
          setAppointmentList(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  const breadcrumbs = [
    <RouterLink to="/Dashboard" style={{ textDecoration: "none" }}>
      <IRATypography
        key="2"
        textVariant="h12WithSemiBold"
        sx={{ fontSize: "12px" }}
      >
        Home
      </IRATypography>
    </RouterLink>,
    <IRATypography
      key="2"
      textVariant="h12WithSemiBold"
      sx={{ fontSize: "12px" }}
    >
      Appointment List
    </IRATypography>,
  ];

  const activityTableColumns = () =>
    [
      {
        name: "appontmentId",
        label: "Appt.ID",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "createdAt",
        label: "Date & Time",
        component: DateTimeCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "clientName",
        label: " Client Name",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "type",
        label: "App.Type",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "doctorName",
        label: "Doctor",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "status",
        label: "Status",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "action",
        label: "Action",
        component: (props: any) => {
          return ActionCell({
            button: true,
            btnVariant: "primary",
            btnSize: "smallest",
            disabled: false,
            infoMessage: null,
            label: "View/Start",
            onClick: () => {
              navigate(
                `/appointments/${props?.data?.id}/details/plan/${props?.data?.planId}`
              );
            },
            ...props,
          } as any); // <-- This 'as any' is not needed and might be causing the issue
        },
      } as any,
    ] as Array<IRATableColumnProps>;

  const activityTableData = () => {
    return appointmentList?.results?.map((row: any) => {
      return {
        ...row,
        appontmentId: {
          name: row?.id,
        },
        createdAt: {
          dateTime: moment(row.createdAt).format("DD-MM-YYYY"),
        },
        clientName: {
          name: `${row?.client?.firstName} ${row?.client?.lastName}`,
        },
        type: {
          name: `${row?.type}` || "",
        },
        doctorName: {
          name: `${row?.doctor?.firstName ?? "NA"} ${
            row?.doctor?.lastName ?? ""
          }`,
        },
        status: {
          name: `${row?.status}`,
        },
        action: {
          data: {
            id: row?.id,
            planId: row?.planId,
          },
        },
      };
    });
  };

  const handleButtonClick = (type: string) => {
    if (type === "bookAppointment") {
      setOpenSelectClientDialog({ open: true });
    } else {
      navigate(`/cases/${"ca6090934"}/details/${"case-history"}`);
    }
  };

  return (
    <>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        sx={{ p: "5px" }}
      >
        <Breadcrumb title={`Appointments List`} breadcrumbsArr={breadcrumbs} />

        <Box style={{ display: "flex", gap: "8px" }}>
          <CustomButton
            onClick={() => handleButtonClick("Button1")}
            title={"Export List"}
          />
          <CustomButton
            onClick={() => handleButtonClick("bookAppointment")}
            title={"Book Appointment "}
          />
        </Box>
      </Box>

      <Card>
        <Box>
          <Grid
            container
            sx={{ padding: "10px" }}
            display="flex"
            justifyContent="space-between"
          >
            <Grid item md={3} sx={{ background: "#F0F2F6" }}>
              <CustomInput
                size={"small"}
                placeholder="Search by Client"
                placeholderColor="#204289"
                placeholderColorOpacity={1}
                placeholderSize={15}
                value={searchTerm}
                onChange={(event: any) => setSearchTerm(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      {searchTerm.length > 0 && (
                        <InputAdornment
                          sx={{ cursor: "pointer" }}
                          position="end"
                          onClick={() => setSearchTerm("")}
                        >
                          <Close />
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
            </Grid>

            <Grid sx={{ display: "flex", gap: "7px" }}>
              {/* <CustomDatePicker /> */}
              {/* <CustomDatePicker /> */}
            </Grid>
          </Grid>

          <Box p={3}>
            <IRATable
              columns={activityTableColumns()}
              data={activityTableData()}
              onSort={(name: string, order: string) =>
                alert(`sort requested for ${name} with order ${order}`)
              }
            />
          </Box>
        </Box>
      </Card>
      {openSelectClientDialog.open && (
        <CustomDialog
          width={"650px"}
          open={openSelectClientDialog.open}
          handleClose={() =>
            setOpenSelectClientDialog({ open: false, data: {} })
          }
          title={"Book Appointment"}
          maxWidth={"lg"}
        >
          <Box>
            {userType === "client" ? (
              <ClientAppointmentForm
                userDetails={currentUserDetails}
                onClose={() =>
                  setOpenSelectClientDialog({ open: false, data: {} })
                }
              />
            ) : (
              <BookAppointmentForm
                onClose={() =>
                  setOpenSelectClientDialog({ open: false, data: {} })
                }
                navigateTo={(data: string) => {
                  navigate(`/cases/client/${"121213"}/add`);
                }}
              />
            )}
          </Box>
        </CustomDialog>
      )}
    </>
  );
};

export default React.memo(AppointmentList);
