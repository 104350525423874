import React, { useEffect, useRef, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import CustomButton from "../../../components/Button";
import CustomAutocomplete from "../../../components/Autocomplete";
import CustomModal from "../../../components/Modal";
import PeriodontalChart from "./PeriodontalChart";
import DentalChart, { PRIMARY_TOOTHS } from "./DentalChart";
import {
  FULL_MOUTH,
  UPPER_TEETH,
  LOWER_TEETH,
  TOOTH_SURFACES,
} from "../../../../src/app/utilities/constants";
import { CLINICAL_FINDINGS } from "../../../../src/app/utilities/staticData";
import { styles } from "./styles";
import { groupByToothFindings } from "../../../../src/app/utilities/utils";
import ExaminationTable from "./Table";

interface ExaminationTabsInterface {
  setInvestigationData: any;
  investigationData: any;
  dentalFormData: any;
  setDentalFormData: any;
  openModal: any;
  handleModal: any;
  setPeriodentalChart: any;
  periodentalChart: any;
  selectedPeriodentalData: any;
  defaultPeriodentalData: any;
  setSelectedPeriodentalData: any;
  treatments: any;
}

const formDefaultValues = {
  selectedTooths: [],
  surface: [],
  findings: "",
  teethType: "custom",
  isPrimaryTeeth: false,
  selectedTeeth: true,
};
const Examination = (props: ExaminationTabsInterface) => {
  const {
    setInvestigationData,
    investigationData,
    dentalFormData = {},
    setDentalFormData,
    openModal,
    handleModal,
    setPeriodentalChart,
    periodentalChart,
    selectedPeriodentalData,
    defaultPeriodentalData,
    setSelectedPeriodentalData,
  } = props;
  const firstUpdate = useRef(true);

  const FULLMOUTH: any = FULL_MOUTH;
  const LOWERTEETH: any = LOWER_TEETH;
  const UPPERTEETH: any = UPPER_TEETH;
  const [isSurfaceFieldDisable, setIsSurfaceFieldDisable] = useState(false);

  const [openPeriodentalModal, setOpenPeriodentalModal] = useState<any>(false);

  const { control, handleSubmit, getValues, setValue, reset, watch } = useForm({
    defaultValues: formDefaultValues,
  });
  watch(["selectedTooths", "isPrimaryTeeth", "findings", "surface"]);
  const handleTeethTypeChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    onToothChange(newValue);
    setValue("teethType", newValue);
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    clearForm();
  }, [investigationData]);

  useEffect(() => {
    if (Object.keys(dentalFormData).length) {
      reset({
        selectedTooths: dentalFormData.toothNumbers,
        ...dentalFormData,
      });
    }
  }, [dentalFormData]);

  useEffect(() => {
    const teeths: any = getValues("selectedTooths");
    if (teeths.length) {
      const surface = teeths[0].surface || [];
      const isAllSurfacesSame = teeths.every(
        (e: any) => JSON.stringify(e.surface || []) === JSON.stringify(surface)
      );
      setIsSurfaceFieldDisable(!isAllSurfacesSame);
    } else {
      setIsSurfaceFieldDisable(false);
    }
  }, [JSON.parse(JSON.stringify(getValues("selectedTooths")))]);

  const handleAdd = () => {
    const { selectedTooths }: any = getValues();
    const dentalTypeData =
      investigationData.filter((d: any) => d.teethType === "dental") || [];
    const periodontalTypeData =
      investigationData.filter((d: any) => d.teethType === "periodental") || [];
    setInvestigationData([
      ...groupByToothFindings(dentalTypeData, selectedTooths),
      ...periodontalTypeData,
    ]);
  };

  const clearForm = () => {
    reset(formDefaultValues);
    setDentalFormData({});
  };

  const onToothChange = (val: any) => {
    switch (val) {
      case "fullMouth":
        return setValue("selectedTooths", FULLMOUTH);
      case "lowerTeeth":
        return setValue("selectedTooths", LOWERTEETH);
      case "upperTeeth":
        return setValue("selectedTooths", UPPERTEETH);
      default:
        setValue("selectedTooths", []);
    }
  };

  const handlePeriodentalModal = (val: any) => {
    setOpenPeriodentalModal(val);
  };

  const editInvestigation = (
    data: any,
    index: any,
    teethType: any,
    completeRowData: any = {}
  ) => {
    if (teethType === "periodental") {
      data.periodontalData.Note = {
        teethNo: completeRowData.teethNumber,
        notes: completeRowData.examinationNotes,
      };
      const clonedData = JSON.parse(JSON.stringify(defaultPeriodentalData));
      for (const [index, type] of clonedData[
        data.periodentalChartType
      ].entries()) {
        const { text, captureData }: any = type;
        const t = data.periodontalData[`${text}`];
        if (t) {
          for (const [i, v] of captureData.entries()) {
            if (v.teethNo === t.teethNo) {
              clonedData[data.periodentalChartType][index].captureData[i] = t;
            }
          }
        }
      }
      setPeriodentalChart(clonedData);
      setSelectedPeriodentalData(data);
      handlePeriodentalModal(true);
    } else {
      let paramData = data;
      if (paramData.toothNumbers) {
        paramData = {
          ...data,
          isPrimaryTeeth: PRIMARY_TOOTHS.includes(data.teethNumber),
          toothNumbers: (data.toothNumbers || []).map((d: any) => ({
            ...d,
            value: d.teethNumber,
            name: d.teethNumber,
          })),
        };
      } else {
        paramData = {
          ...data,
          isPrimaryTeeth: PRIMARY_TOOTHS.includes(data.teethNumber),
          toothNumbers: [
            {
              ...data,
              value: data.teethNumber,
              name: data.teethNumber,
            },
          ],
        };
      }
      window.scrollTo(0, 0);
      setDentalFormData({
        index,
        ...paramData,
      });
    }
  };

  const deleteInvestigation = (index: any, mainIndex: any, teethType: any) => {
    let filtered = [...investigationData];
    if (mainIndex >= 0 && !index) {
      filtered = investigationData.filter(
        (data: any, i: any) => i !== mainIndex
      );
    }
    if (mainIndex >= 0 && index >= 0) {
      filtered = investigationData.map((data: any, i: any) => {
        if (i === mainIndex) {
          return teethType === "periodental"
            ? {
                ...data,
                surface: (data.surface || []).filter(
                  (d: any, id: any) => id !== index
                ),
              }
            : {
                ...data,
                toothNumbers: (data.toothNumbers || []).filter(
                  (d: any, id: any) => id !== index
                ),
              };
        }
        return data;
      });
    }
    setInvestigationData(filtered);
  };
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(handleAdd)}
    >
      <Grid
        container
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ paddingLeft: 2, paddingRight: 2 }}
      >
        <Grid item lg={12}>
          <Box mt={1} mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={9}>
                <Box mb={1}>
                  <Grid
                    container
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Grid item>
                      <Typography style={styles.nametext}>
                        Dental Chart
                      </Typography>
                    </Grid>
                    <Grid item>
                      <CustomButton
                        title={"Periodontal Chart"}
                        handleButtonClick={() => {
                          setPeriodentalChart(defaultPeriodentalData);
                          setSelectedPeriodentalData({});
                          handleModal(true);
                        }}
                        backgroundcolor={"#204289"}
                        bordercolor={"#204289"}
                        color={"#fffff"}
                        marginTop={"40px"}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <DentalChart
                  setValue={setValue}
                  selectedTooths={getValues("selectedTooths")}
                  isPrimaryTeeth={getValues("isPrimaryTeeth")}
                  findings={getValues("findings")}
                  surface={getValues("surface")}
                  handleTeethTypeChange={handleTeethTypeChange}
                  teethType={getValues("teethType")}
                />
              </Grid>
              <Grid item xs={6} md={3} sx={{ marginTop: "-50px" }}>
                <Box mt={7}>
                  <FormGroup>
                    <Controller
                      name="isPrimaryTeeth"
                      render={({ field }) => (
                        <FormControlLabel
                          sx={{
                            color: "#204289",
                            "& span.MuiCheckbox-root": { color: "inherit" },
                            mt: 3,
                          }}
                          control={
                            <Checkbox
                              {...field}
                              defaultChecked={getValues("isPrimaryTeeth")}
                            />
                          }
                          label="Primary Teeth"
                        />
                      )}
                      control={control}
                    />
                  </FormGroup>
                </Box>
                <Box mt={2}>
                  <Grid item>
                    <Controller
                      name="selectedTooths"
                      control={control}
                      defaultValue={getValues("selectedTooths")}
                      rules={{ required: "This field is required" }}
                      render={({
                        field: { ref, onChange, ...field },
                        fieldState: { error },
                      }) => (
                        <CustomAutocomplete
                          field={field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          ref={ref}
                          value={getValues("selectedTooths")}
                          multiple
                          onChange={(event: React.SyntheticEvent, value: any) =>
                            onChange(value)
                          }
                          options={FULL_MOUTH}
                          label="Selected tooth"
                          limitTags={4}
                        />
                      )}
                    />
                  </Grid>
                </Box>
                <Box mt={2}>
                  <Grid item>
                    <Controller
                      name="surface"
                      control={control}
                      defaultValue={getValues("surface")}
                      render={({
                        field: { ref, onChange, ...field },
                        fieldState: { error },
                      }) => (
                        <CustomAutocomplete
                          field={field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          ref={ref}
                          value={getValues("surface")}
                          multiple
                          onChange={(event: React.SyntheticEvent, value: any) =>
                            onChange(value)
                          }
                          options={TOOTH_SURFACES}
                          label="Tooth Surface"
                          disabled={isSurfaceFieldDisable}
                        />
                      )}
                    />
                  </Grid>
                </Box>
                <Box mt={2}>
                  <Grid item>
                    <Controller
                      name="findings"
                      control={control}
                      defaultValue={getValues("findings")}
                      rules={{ required: "This field is required" }}
                      render={({
                        field: { ref, onChange, ...field },
                        fieldState: { error },
                      }) => (
                        <CustomAutocomplete
                          field={field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          ref={ref}
                          value={getValues("findings")}
                          singleSelection
                          onChange={(event: React.SyntheticEvent, value: any) =>
                            onChange(value)
                          }
                          options={CLINICAL_FINDINGS}
                          label="Clinical Findings"
                        />
                      )}
                    />
                  </Grid>
                </Box>
                <Box mt={2}>
                  <Grid
                    container
                    flexDirection={"row"}
                    spacing={1}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                  >
                    <Grid item>
                      <CustomButton
                        title={"Cancel"}
                        handleButtonClick={clearForm}
                        backgroundcolor={"#FFFFFF"}
                        bordercolor={"#204289"}
                        color={"#fffff"}
                        width={110}
                      />
                    </Grid>
                    <Grid item>
                      <CustomButton
                        title={
                          Object.keys(dentalFormData).length ? "Update" : "Add"
                        }
                        type="submit"
                        handleButtonClick={() => {}}
                        backgroundcolor={"#204289"}
                        bordercolor={"#204289"}
                        color={"#fffff"}
                        width={110}
                        padding={7}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <CustomModal
            open={openModal}
            handleClose={""}
            handleOpen={() => handleModal(true)}
            width={"85%"}
            height={"90%"}
          >
            <Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography>Periodontal Chart</Typography>
                <CloseIcon
                  onClick={() => handleModal(false)}
                  sx={styles.closeicon}
                />
              </Box>
              <Box mt={"20px"}></Box>
              <PeriodontalChart
                handleModal={handleModal}
                investigationData={investigationData}
                setInvestigationData={setInvestigationData}
                periodentalChart={periodentalChart}
                setPeriodentalChart={setPeriodentalChart}
                selectedPeriodentalData={selectedPeriodentalData}
              />
            </Box>
          </CustomModal>
        </Grid>
      </Grid>
      {Boolean(investigationData.length) && (
        <Grid item lg={12}>
          <ExaminationTable
            majorInvestigation={""}
            setMajorInvestigation={""}
            investigationData={investigationData}
            setInvestigationData={setInvestigationData}
            editInvestigation={editInvestigation}
            deleteInvestigation={deleteInvestigation}
            doctorName={""}
            investigationOptions={""}
            treatments={""}
          />
        </Grid>
      )}
    </Box>
  );
};

export default Examination;
