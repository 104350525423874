import { Box, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "../../../components/Button";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "../../../app/context/AppContext";
import CustomInput from "../../../components/Input";

const ClientOtpverifyScreen = (props: any) => {
  const { client } = props;
  const navigate = useNavigate();
  const [otp, setOtp] = React.useState("");
  const [mobileNo, setMobileNo] = useState(true);

  const handleChange = (newValue: any) => {
    setOtp(newValue);
  };

  const changeHandler = () => {
    setMobileNo(!mobileNo);
  };

  return (
    <>
      <Paper
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "grid",
          marginTop: "10px",
          marginBottom: "10px",
          borderRadius: "10px",
          paddingBottom: "20px",
        }}
      >
        <Typography
          variant="h6"
          style={{
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "10px",
            marginRight: "29%",
            paddingLeft: "120px",
            color: "blue",
          }}
        >
          Verification Code
        </Typography>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            marginBottom: "10px",
            alignItems: "center",
          }}
        >
          {mobileNo ? (
            <Box
              style={{
                background: "rgba(244, 244, 244, 1)",
                borderRadius: "10px",
                borderColor: "black",
                borderStyle: "solid",
                borderWidth: "1px",
                padding: "10px",
                // paddingLeft: "15px",
                // paddingRight: "15px",
                marginRight: "10px",
              }}
            >
              <Typography variant="subtitle1" style={{ textAlign: "center" }}>
                Primary Mobile No. : +91 80123 45678
              </Typography>
            </Box>
          ) : (
            // <CustomInput label=" Primary Mobile No. : +91 80123 45678"></CustomInput>
            // "N/A"
            <Box pr={3}>
              <CustomInput label="Phone No :+91 1234567890"></CustomInput>
            </Box>
          )}

          {mobileNo ? (
            <CustomButton
              title={"Change"}
              marginRight={"5px"}
              bgColor={"rgba(92, 78, 255, 1)"}
              height={40}
              handleButtonClick={changeHandler}
            />
          ) : (
            <CustomButton
              title={"Submit"}
              marginRight={"5px"}
              bgColor={"rgba(92, 78, 255, 1)"}
              height={40}
              handleButtonClick={changeHandler}
              onChange={props.onChange}
            />
          )}
        </Box>

        <MuiOtpInput
          style={{
            width: "34%",
            alignSelf: "center",
            marginLeft: "33%",
            marginTop: "10px",
            marginBottom: "10px",
          }}
          value={otp}
          onChange={handleChange}
        />

        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
          gap={2}
        >
          <Typography variant="body1">Didn’t receive code ? </Typography>

          <Typography variant="body1">
            {" "}
            <Link to={"/"}>Request Again</Link>
          </Typography>
        </Box>
        <CustomButton
          title={"Verify"}
          marginRight={"5px"}
          bgColor={"rgba(92, 78, 255, 1)"}
          height={"45px"}
          width={"12%"}
          marginLeft={"44%"}
          handleButtonClick={() =>
            navigate(`/client/${client.value}/view/plan-details`)
          }
        />
      </Paper>
    </>
  );
};

export default ClientOtpverifyScreen;
