import React, { useEffect, useState } from "react";
import { apiRequest } from "../../app/services/requests";
import { useParams } from "react-router-dom";
import { Grid, Card, CardContent, Typography, Paper, Box } from "@mui/material";
import { IRequestResponse, statusApprovedRejected } from "./types";
import { LabelText } from "./style";
import CustomAccordion from "../../components/Accordion";
import CustomDialog from "../../components/CustomDialog";
import CustomButton from "../../components/Button";
import ApproveRejectForm from "./approve-reject-form";
import { useAppContext } from "../../app/context/AppContext";

const RequestDetails = () => {
  const { user = {} } = useAppContext() as any;
  const userType = user?.data.userType;
  const { planId } = useParams<any>();
  const [requestDetails, setRequestDetails] = useState<IRequestResponse>(
    {} as IRequestResponse
  );
  const [clientMemberList, setClientMemberList] = useState({
    results: [],
    totalCount: 0,
  });

  const [openSelectClientDialog, setOpenSelectClientDialog] = useState<any>({
    open: false,
    data: {},
  });

  useEffect(() => {
    if (planId) {
      getMasterPlan(planId);
    }
  }, [planId]);

  const getMasterPlan = (planId: any) => {
    apiRequest({
      url: `plan/${planId}`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          setRequestDetails(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
        }
      });
  };

  useEffect(() => {
    getClientFamilyMembers(planId);
  }, [planId]);

  const getClientFamilyMembers = (planId?: any) => {
    apiRequest({
      url: `plan/${planId}/client-family-members`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          setClientMemberList(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
        }
      });
  };

  const renderResponseDetails = () => (
    <CustomAccordion title={"Client and Plan Details"}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display={"flex"} justifyContent={"flex-end"} mb={2}>
            <CustomButton
              title={"View Documents & Update Status"}
              type="button"
              borderRadius="5px"
              handleButtonClick={() => handleClick(requestDetails?.id)}
            />
          </Box>
        </Grid>
        {/* General Info & Client Info */}
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {/* General Info */}
            <Grid item xs={12} sm={6}>
              <LabelText variant="h6">General Info</LabelText>
              <LabelText>Status:</LabelText>
              <Typography>{requestDetails?.status}</Typography>

              <LabelText>Case History:</LabelText>
              <Typography>
                {requestDetails?.enableCaseHistory ? "Enabled" : "Disabled"}
              </Typography>

              <LabelText>Created At:</LabelText>
              <Typography>
                {new Date(requestDetails?.createdAt).toLocaleString()}
              </Typography>
            </Grid>

            {/* Client Info */}
            <Grid item xs={12} sm={6}>
              <LabelText variant="h6">Client Info</LabelText>
              <LabelText>Name:</LabelText>
              <Typography>
                {requestDetails?.client?.firstName}{" "}
                {requestDetails?.client?.lastName}
              </Typography>

              <LabelText>Email:</LabelText>
              <Typography>{requestDetails?.client?.email}</Typography>
            </Grid>
            {/* Master Plan Info */}
            <Grid item xs={12} sm={6}>
              <LabelText variant="h6">Plan Details</LabelText>
              <LabelText>Type:</LabelText>
              <Typography>{requestDetails?.masterPlan?.type}</Typography>

              <LabelText>Code:</LabelText>
              <Typography>{requestDetails?.masterPlan?.code}</Typography>

              <LabelText>Name:</LabelText>
              <Typography>{requestDetails?.masterPlan?.name}</Typography>

              <LabelText>Start:</LabelText>
              <Typography>
                {new Date(requestDetails?.startsAt).toLocaleString()}
              </Typography>

              <LabelText>End:</LabelText>
              <Typography>
                {new Date(requestDetails?.endsAt).toLocaleString()}
              </Typography>
            </Grid>

            {/* Status Info */}
            <Grid item xs={12} sm={6}>
              <LabelText variant="h6">Status</LabelText>
              <LabelText>Panel Doctor Status:</LabelText>
              <Typography>
                {requestDetails?.caseHistory?.panelDoctorStatus}
              </Typography>

              <LabelText>SR Doctor Status:</LabelText>
              <Typography>
                {requestDetails?.caseHistory?.srPanelDoctorStatus || "N/A"}
              </Typography>

              <LabelText>Local Admin Status:</LabelText>
              <Typography>
                {requestDetails?.caseHistory?.localAdminStatus || "N/A"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomAccordion>
  );

  const handleClick = (data?: any) => {
    const isClientHistory = Boolean(requestDetails?.caseHistory?.status);
    setOpenSelectClientDialog({
      open: true,
      data: {
        memberId: data?.id,
        isClientHistory: isClientHistory ,
        documents: isClientHistory ? requestDetails?.caseHistory?.documents : data?.caseHistory?.documents,
      },
    });
  };

  const handleClose = (data?: any) => {
    setOpenSelectClientDialog({
      open: false,
      data: {},
    });
  };

  const statusName = statusApprovedRejected[userType];
  const statusComment = statusApprovedRejected[`${userType}-comment`];

  const renderCaseHistoryCards = () => (
    <Box>
      <Typography variant="h6" gutterBottom>
        Case History Details
      </Typography>

      <Grid container spacing={3}>
        {clientMemberList?.results?.map((data: any) => (
          <Grid item xs={12} key={data?.id}>
            <Card>
              <CardContent>
                <Grid item xs={12}>
                  <Box display={"flex"} justifyContent={"flex-end"} mb={2}>
                    <CustomButton
                      title={"View Documents & Update Status"}
                      type="button"
                      borderRadius="5px"
                      handleButtonClick={() => handleClick(data)}
                    />
                  </Box>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <LabelText>Name:</LabelText>
                    <Typography variant="h6" gutterBottom>
                      {data?.name} ({data?.relation})
                    </Typography>
                  </Grid>
                  {/* General Details */}
                  <Grid item xs={4}>
                    <LabelText>Date of Birth:</LabelText>
                    <Typography>
                      {new Date(data?.dob).toLocaleDateString()}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <LabelText>Relation:</LabelText>
                    <Typography>{data?.relation}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <LabelText>Status:</LabelText>
                    <Typography>{data?.status}</Typography>
                  </Grid>
                  {/* Past Illness */}
                  <Grid item xs={4}>
                    <LabelText>Past Illness:</LabelText>
                    <Typography>
                      {data?.caseHistory.pastIllness.length > 0
                        ? data?.caseHistory.pastIllness
                            .map((ill: any) => ill?.name)
                            .join(", ")
                        : "None"}
                    </Typography>
                  </Grid>

                  {/* Present Illness */}
                  <Grid item xs={4}>
                    <LabelText>Present Illness:</LabelText>
                    <Typography>
                      {data?.caseHistory.presentIllness.length > 0
                        ? data?.caseHistory.presentIllness
                            .map((ill: any) => ill?.name)
                            .join(", ")
                        : "None"}
                    </Typography>
                  </Grid>

                  {/* Chief Complaints */}
                  <Grid item xs={4}>
                    <LabelText>Chief Complaints:</LabelText>
                    <Typography>
                      {data?.caseHistory.chiefComplaints.length > 0
                        ? data?.caseHistory.chiefComplaints
                            .map((comp: any) => comp?.name)
                            .join(", ")
                        : "None"}
                    </Typography>
                  </Grid>

                  {/* Previous Treatments */}
                  <Grid item xs={4}>
                    <LabelText>Previous Treatments:</LabelText>
                    {data?.caseHistory.previousTreatments.length > 0 ? (
                      data?.caseHistory.previousTreatments.map(
                        (treatment: any, index: number) => (
                          <Typography key={index}>
                            {treatment.treatmentName} -{" "}
                            {new Date(
                              treatment.treatmentMonthYear
                            ).toLocaleDateString()}
                          </Typography>
                        )
                      )
                    ) : (
                      <Typography>None</Typography>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <LabelText>Status & Comments:</LabelText>
                    <Box
                      display={"flex"}
                      justifyContent={"flex-start"}
                      flexDirection={"column"}
                      alignItems={"start"}
                    >
                      {data?.caseHistory["panelDoctorStatus"] && (
                        <Box>
                          <LabelText>Panel Doctor:</LabelText>
                          <Typography>
                            Status:{data?.caseHistory[statusName]}
                          </Typography>
                          <Typography>
                            Comment:{data?.caseHistory[statusComment]}
                          </Typography>
                        </Box>
                      )}
                      {data?.caseHistory["srPanelDoctorStatus"] && (
                        <Box>
                          <LabelText>SR Panel Doctor:</LabelText>
                          <Typography>
                            Status:{data?.caseHistory[statusName] || "N/A"}
                          </Typography>
                          <Typography>
                            Comment: {data?.caseHistory[statusComment]}
                          </Typography>
                        </Box>
                      )}

                      {data?.caseHistory["localAdminStatus"] && (
                        <Box>
                          <LabelText>Local Admin:</LabelText>
                          <Typography>
                            Status: {data?.caseHistory[statusName] || "N/A"}
                          </Typography>
                          <Typography>
                            Comment: {data?.caseHistory[statusComment]}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  return (
    <Box sx={{ padding: 3, margin: 1 }}>
      <LabelText variant="h6" gutterBottom>
        Request Details
      </LabelText>
      {renderResponseDetails()}
      <Box mt={3}>{renderCaseHistoryCards()}</Box>

      {openSelectClientDialog.open && (
        <CustomDialog
          width={"800px"}
          open={openSelectClientDialog.open}
          handleClose={handleClose}
          title={"Update Status"}
          maxWidth={"lg"}
        >
          <ApproveRejectForm
            onClose={handleClose}
            memberId={openSelectClientDialog?.data?.memberId}
            planId={planId}
            userType={userType}
            isClientHistory={openSelectClientDialog?.data?.isClientHistory}
            onGetClientFamilyMembers={() => getClientFamilyMembers(planId)}
            documents={openSelectClientDialog?.data?.documents}
          />
        </CustomDialog>
      )}
    </Box>
  );
};

export default RequestDetails;
