import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CustomCard from "../Card";
import CustomButton from "../Button";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "../../app/services/requests";

import { styles } from "./styles";

const AppointmentDetailsCard = ({ clientDetails = {} }: any) => {
  const [appointmentDetails, setAppointmentDetails] = useState<any>({});
  const navigate = useNavigate();
  const { appointmentID } = useParams();

  useEffect(() => {
    getAppointmentById(appointmentID);
  }, [appointmentID]);

  const getAppointmentById = (appointmentID: any) => {
    apiRequest({
      url: `appointment/${appointmentID}`, // Use the userId from the URL

      method: "GET",
    })
      .then((res) => {
        if (res) {
          setAppointmentDetails(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  const renderBasicDetails = () => {
    return (
      <>
        {[
          {
            label: "Treatment Name",
            value: "Root Canal",
          },
          { label: "Date", value: appointmentDetails.startDate },
          { label: "Time", value: appointmentDetails.startTime },
          {
            label: "Status",
            value: appointmentDetails.status ?? "Confirmed",
          },
        ].map((detail, index) => (
          <Box key={`${index + 1}`}>
            {/* key= ${index + 1} without ID We Can Used it*/}
            <Typography sx={styles?.appointmentDetailsCard}>
              {detail.label}:
              <Box
                sx={{
                  color: detail.label === "Status" ? "#009267" : "",
                }}
              >
                <strong>{detail?.value}</strong>
              </Box>
            </Typography>
            <br />
          </Box>
        ))}
      </>
    );
  };

  return (
    <CustomCard sx={{ height: "667px", padding: "16px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box my={1}>
            <Typography variant="body1" color="#6A6A6A" fontSize="14px">
              Appointment Details
            </Typography>
          </Box>
          <Box>
            <CustomCard
              sx={{
                height: "80px",
                backgroundColor: "#E1F1FA",
                padding: "16px",
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                justifyContent={"flex-end"}
              >
                <Typography fontSize="14px" color="#313035" textAlign="center">
                  <strong>Appointment Type:</strong>{" "}
                  {appointmentDetails.appointmentType}
                </Typography>
                <Typography
                  variant="body2"
                  color="#222222"
                  fontSize="12px"
                  textAlign="center"
                >
                  <br /> Appointment ID: {appointmentDetails.clientId}
                </Typography>
              </Box>
            </CustomCard>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {renderBasicDetails()}
        </Grid>
        <Grid item xs={12}>
          <Box my={1}>
            <Typography variant="body1" color="#6A6A6A" fontSize="14px">
              <strong>Payment Details</strong>
            </Typography>
          </Box>
          <Typography sx={styles?.appointmentDetailsCard}>
            Status:<strong>{"Paid"}</strong>
          </Typography>
          <br />
        </Grid>

        <Box sx={{ pl: "70px", pt: "200px", justifyContent: "flex-end" }}>
          <CustomButton
            // onClick={() => handleButtonClick("bookAppointment")}

            title={"Create Case"}
            endIcon={<OpenInNewIcon sx={{ fontSize: "12px" }} />}
          />
        </Box>
      </Grid>
    </CustomCard>
  );
};

export default AppointmentDetailsCard;
