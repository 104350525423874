import React from "react";
import { Box, TextField } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import InputAdornment from "@mui/material/InputAdornment";
import { styles } from "./styles";
// import "./style.css";
interface customInputInterface {
  size: any;
  placeholder?: any;
  InputProps?: any;
  disabled?: boolean;
  multiline?: boolean;
  placeholderColor?: any;
  placeholderColorOpacity?: any;
  placeholderSize?: any;
  rows?: any;
  value?: any;
  onChange?: any;
  defaultValue?: any;
  name?: any;
  variant?: any;
  label?: any;
  type?: any;
  required?: any;
  showPassword?: any;
  onHandleClick?: any;
}

const CustomInput = (props: any) => {
  const {
    size,
    placeholder,
    InputProps,
    disabled,
    multiline,
    placeholderColor,
    placeholderColorOpacity,
    placeholderSize,
    rows,
    value,
    onChange,
    defaultValue,
    name,
    variant = "outlined",
    label,
    type = "text",
    required = false,
    ...rest
  } = props;

  const rootStyles = {
    "& fieldset": {
      borderRadius: `10px`,
      borderColor: `#9DA2AB`,
      boxShadow: "0px 0px 10px #00000014",
      borderWidth: 1,
    },
    "& .MuiInputBase-input": {
      fontSize: "13px",
      padding: props.padding ? props.padding : "11px",
      width: props.width
    },
    "& input::placeholder": {
      color: placeholderColor ? placeholderColor : "#9DA2AB",
      fontSize: placeholderSize ? placeholderSize : 12,
      opacity: placeholderColorOpacity ? placeholderColorOpacity : 1,
    },
  };

  return (
    <Box>
      <TextField
        {...rest}
        required={required}
        type={type}
        name={name}
        defaultValue={defaultValue}
        
        disabled={disabled}
        multiline={multiline}
        InputProps={props.InputProps}
        sx={{
          "& .MuiOutlinedInput-root": rootStyles,
          color: "#204289",
          "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
            color: "#9DA2AB",
            fontSize: "13px",
            opacity: 1,
            top: "3px"
          },
        }}
        size={size ? size : "small"}
        fullWidth
        placeholder={placeholder}
        rows={rows}
        onChange={onChange}
        value={value}
        label={label}
        variant={variant}
      />
    </Box>
  );
};


export default CustomInput;
