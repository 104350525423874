import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../app/context/useSnackbar";
import { useAppContext } from "../../app/context/AppContext";
import CustomInput from "../../components/Input";
import CustomButton from "../../components/Button";

import StatesAndCities from "../../app/utilities/StatesAndCities.json";
import CustomDatePicker from "../../components/DatePicker";
import CustomSelectPicker from "../../components/SelectPicker";
import {
  APPOINTMENT_CLIENT_OPTIONS,
  PHONE_REG_EXP,
} from "../../app/utilities/constants";
import { apiRequest } from "../../app/services/requests";

const EditTreatment = (props: any) => {
  const { currentSubCategory = {} as any } = props;

  const navigate = useNavigate();

  const [appointmentData, setAppointmentData] = useState() as any;
  const { handleLogoutSuccess } = useAppContext() as any;
  const { openSnackbar } = useSnackbar() as any;

  const [cities, setCities] = useState<any>([]);
  const [statesAndCities] = useState<any>(StatesAndCities);
  const [clientData, setClientData] = useState() as any;
  const [doctorsData, setDoctorsData] = useState() as any;
  // console.log(doctorsData);

  // console.log(clientData);

  // useEffect(() => {
  //   getAllCategories();
  // }, []);

  // useEffect(() => {
  //   getAllcaseList();
  // }, []);
  // const getAllcaseList = () => {
  //   apiRequest({
  //     url: `appointment`,
  //     data: {
  //       // userType: "superAdmin",
  //     },

  //     method: "POST",
  //   })
  //     .then((res:any) => {
  //       if (res) {
  //         setAppointmentData(res);
  //       }
  //     })
  //     .catch((err) => {
  //       if (err?.statusCode === 403 || err?.statusCode === 401) {
  //         if (navigate) {
  //           navigate("/login"); // Navigate to "/login" route
  //         }
  //       }
  //     });
  // };

  // const onSubmit = (data: any) => {
  //   // console.log("formData", data);

  // };

  const userDefaultValues = () => {
    return {
      teathNumber: "22",
      treatmentName: "Root Canal",
      doctorName: "Suman",
      startDate: "10/02/1999",
      endDate: "17/02/1999",
      startTime: "6:00 AM",
      endTime: "7:00 PM",
      status: "Active",
    } as any;
  };

  const schema = object({
    teathNumber: string().required("Teath number is required"),
    treatmentName: string().required("Treatment Name is required"),
    doctorName: string().required("Patient is required"),
    startDate: string().ensure().required("Date is required"),
    endDate: string().ensure().required("Date is required"),
    startTime: string().ensure().required("Time required"),
    endTime: string().ensure().required("Time required"),
    status: string().ensure().required("Status Required"),
  });

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: userDefaultValues(),
  });

  useEffect(() => {
    getAppointmentById();
  }, []);
  const getAppointmentById = () => {
    apiRequest({
      url: `client/all`, // Use the userId from the URL

      method: "POST",
    })
      .then((res: any) => {
        let data = res?.results?.map((item: any) => ({
          name: `${item?.firstName} ${item?.lastName}`,
          id: item?.id,
        }));
        setClientData(data);
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  // useEffect(() => {
  //   getDoctorsById();
  // }, []);
  // const getDoctorsById = () => {
  //   apiRequest({
  //     url: `clinic/clinicId/doctor/list`, // Use the userId from the URL

  //     method: "POST",
  //   })
  //     .then((res:any) => {
  //       let data = res?.data?.map((item: any) => ({
  //         name: `${item?.firstName} ${item?.lastName}`,
  //         id: item?.id,
  //       }));
  //       setDoctorsData(data);
  //     })
  //     .catch((err) => {
  //       if (err?.statusCode === 403 || err?.statusCode === 401) {
  //         if (navigate) {
  //           navigate("/login"); // Navigate to "/login" route
  //         }
  //       }
  //     });
  // };

  const onSubmit = (formData: any) => {
    // console.log("formData", formData);
    apiRequest({
      url: `appointment`,
      data: formData,

      method: "POST",
    })
      .then((res: any) => {})
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography padding={2}>
          <strong>Add Treatment Details</strong>
        </Typography>
        <Grid container spacing={3}>
          {/* Add client details fields here */}
          <Grid item xs={12} md={6}>
            <Controller
              name="teathNumber"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Teath Number"}
                  InputProps={""}
                  size={"small"}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="treatmentName"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Treatment Name"}
                  InputProps={""}
                  size={"small"}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="doctorName"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Doctor Name"}
                  InputProps={""}
                  size={"small"}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={3} mt={1}>
          <Grid item xs={6} md={6}>
            <Box sx={{ width: "100%" }}>
              <Controller
                name={"startDate"}
                rules={{ required: true }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomDatePicker
                    {...field}
                    label={"Start Date"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                    disablePast={true}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={6}>
            <Box sx={{ width: "100%" }}>
              <Controller
                name={"endDate"}
                rules={{ required: true }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomDatePicker
                    {...field}
                    label={"End Date"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                    disablePast={true}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="startTime"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectPicker
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Start Time"}
                  size={"small"}
                  fullWidth={true}
                  width={"100%"}
                  selectData={[
                    {
                      id: "1",
                      name: "8:00 AM",
                    },
                    {
                      id: "1",
                      name: "9:00 AM",
                    },
                  ]}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="endTime"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectPicker
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"End Time"}
                  size={"small"}
                  fullWidth={true}
                  width={"100%"}
                  selectData={[
                    {
                      id: "1",
                      name: "8:00 PM",
                    },
                    {
                      id: "1",
                      name: "9:00 PM",
                    },
                  ]}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={3} mt={1}>
          <Grid item xs={12} md={6}>
            <Controller
              name="status"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  label={"Doctor Name"}
                  InputProps={""}
                  size={"small"}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={3} mt={1}></Grid>
        {/* Additional Sections can be added as needed */}
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <CustomButton
            border="1px solid #000000"
            color="#000000"
            title="Cancel"
            bgColor="#ffffff"
            onClick={() => props.onClose()}
            borderRadius="5px"
          />
          <Box marginLeft={1}>
            {" "}
            {/* Add a margin to create space between buttons */}
            <CustomButton
              title={currentSubCategory?.open === "add" ? "Create" : "Save"}
              type="submit"
              borderRadius="5px"
            />
          </Box>
        </Box>
      </form>
    </>
  );
};

export default EditTreatment;
