import React from "react";
import { Box } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const filter = createFilterOptions<any>();

const CustomAutocomplete = (props: any) => {
  const {
    placeholder,
    options,
    disabled,
    multiline,
    placeholderColor,
    placeholderColorOpacity,
    placeholderSize,
    defaultValue,
    label,
    multiple,
    onChange,
    value,
    singleSelection,
    field,
    ref,
    error,
    onInputChange,
    addText,
    limitTags,
    ...rest
  } = props;

  const styledAutoComplete = {
    "& .MuiFormLabel-root:not(.MuiInputLabel-shrink)": {
      fontSize: "13px",
      top: "-3px",
    },
    "& .MuiFormLabel-root": {
      color: "rgb(157, 162, 171)",
    },
    "& .MuiButtonBase-root.MuiChip-root": {
      borderRadius: "5px",
      height: "unset",
      backgroundColor: "#204289",
      color: "#ffff",
      "& svg": {
        color: "#ffff",
      },
    },
    "& .MuiChip-label.MuiChip-labelMedium": {
      whiteSpace: "break-spaces",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `10px`,
        borderColor: `rgba(0, 0, 0, 0.23)`,
        boxShadow: "0px 0px 10px #00000014",
        borderWidth: 1,
      },
      "& .MuiInputBase-input": {
        fontSize: "13px",
        padding: "3px !important",
      },
      "& input::placeholder": {
        color: placeholderColor ? placeholderColor : "rgba(0, 0, 0, 0.23)",
        fontSize: placeholderSize ? placeholderSize : 12,
        opacity: placeholderColorOpacity ? placeholderColorOpacity : 1,
        width: props.width,
      },
    },
  };

  const getAddCustomTextOpt = (multiple = false) => {
    const addCustomTextOpt = addText
      ? {
          filterOptions: (options: any, params: any) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option: any) =>
              typeof option === "object"
                ? inputValue === option.name
                : inputValue === option
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push(
                multiple ? { name: inputValue, value: inputValue } : inputValue
              );
            }
            return filtered;
          },
          getOptionLabel: (option: any) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              const updatedOption = option.replace("Add ", "");
              return updatedOption;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return typeof option === "object"
              ? option.name || ""
              : option.toString();
          },
        }
      : {};

    return addCustomTextOpt;
  };

  let autoCompleteProps = {};
  if (singleSelection) {
    autoCompleteProps = {
      renderOption: (props: any, option: any) => <li {...props}>{option}</li>,
      ...getAddCustomTextOpt(),
    };
  } else {
    autoCompleteProps = {
      multiple,
      getOptionLabel: (option: any) => option.name || "",
      ...getAddCustomTextOpt(true),
      ...(limitTags && {
        limitTags,
      }),
    };
  }

  return (
    <Autocomplete
      {...autoCompleteProps}
      disabled={disabled}
      sx={styledAutoComplete}
      multiple={multiple}
      options={options}
      defaultValue={defaultValue}
      // filterOptions={(options) => options}
      // isOptionEqualToValue={(option: any, value: any) =>
      //   option?.value === value?.value
      // }
      // getOptionLabel={(option: any) => option.name || ""}
      onChange={onChange}
      onInputChange={onInputChange}
      value={value}
      renderInput={(params) => (
        <TextField
          {...field}
          {...params}
          error={error}
          helperText={props.helperText}
          inputRef={ref}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export const CustomAutoDropdown = (props: any) => {
  const {
    placeholderColor,
    placeholderColorOpacity,
    placeholderSize,
    label,
    placeholder,
    error,
    ref,
    field,
    sx = {},
    ...rest
  } = props;
  const styledAutoComplete = {
    "& .MuiFormLabel-root:not(.MuiInputLabel-shrink)": {
      fontSize: "13px",
      top: "-3px",
    },
    "& .MuiFormLabel-root": {
      color: "rgb(157, 162, 171)",
    },
    "& .MuiButtonBase-root.MuiChip-root": {
      borderRadius: "5px",
      height: "unset",
      backgroundColor: "#204289",
      color: "#ffff",
      "& svg": {
        color: "#ffff",
      },
    },
    "& .MuiChip-label.MuiChip-labelMedium": {
      whiteSpace: "break-spaces",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `10px`,
        borderColor: `rgba(0, 0, 0, 0.23)`,
        boxShadow: "0px 0px 10px #00000014",
        borderWidth: 1,
      },
      "& .MuiInputBase-input": {
        fontSize: "13px",
        padding: "3px !important",
      },
      "& input::placeholder": {
        color: placeholderColor ? placeholderColor : "rgba(0, 0, 0, 0.23)",
        fontSize: placeholderSize ? placeholderSize : 12,
        opacity: placeholderColorOpacity ? placeholderColorOpacity : 1,
      },
    },
  };

  return (
    <Autocomplete
      sx={{ ...styledAutoComplete, ...sx }}
      getOptionLabel={(option: any) => option.label}
      renderInput={(params: any) => (
        <TextField
          {...field}
          {...params}
          error={error}
          helperText={props.helperText}
          inputRef={ref}
          label={label}
          placeholder={placeholder}
          fullWidth={props.fullWidth}
        />
      )}
      {...rest}
    />
  );
};

export default CustomAutocomplete;
