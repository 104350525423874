import { Box, Divider, Grid, Typography } from "@mui/material";
import CustomButton from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import ServicesTreatmentsCoveredList from "./services-treatments-covered-list";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomCard from "../../../components/Card";
import ClientDetailsCounter from "../../Client/client-details-counter";

const CancelPlanDetails = (props: any) => {
  const navigate = useNavigate();

  let data = [
    {
      id: 1,
      heading: "Plans",
      remainingdays: "0",
      color: "#219FFF4D",
    },
    {
      id: 2,
      heading: "Claims",
      claimsCount: "05",
      color: "#68D4B44D",
    },
    {
      id: 3,
      heading: "Cases",
      claimsCount: "08",
      color: "#FF98A54D",
    },
  ];
  return (
    <>
      <Box>
        <Box sx={{ display: "flex", gap: 2, borderRadius: 6 }}>
          {data?.map((item) => {
            return <ClientDetailsCounter CounterData={item} />;
          })}
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <CustomCard>
            <Box p={2} mb={2}>
              <Box onClick={props?.onHandleBackArrow} display={"flex"}>
                <ArrowBackIcon sx={{ color: "blue", cursor: "pointer" }} />
                <Typography
                  fontWeight={600}
                  sx={{ cursor: "pointer" }}
                >{` Cancel Plan`}</Typography>
              </Box>
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Box>
                      <Box>
                        <Typography>Preventive</Typography>
                      </Box>
                      <Typography>{`PLAN1`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Box>
                        <Typography> Range</Typography>
                      </Box>
                      <Typography>{`Family`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Box>
                        <Typography> Price</Typography>
                      </Box>
                      <Typography>{`15000 monthly`}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Divider sx={{ my: 1 }} /> {/* Adjust margin as needed */}
            <Box p={2}>
              <Box>
                <Typography variant="body1"> Plan Details</Typography>
              </Box>
              <Box my={1}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box>
                      <Box>
                        <Typography> Application No</Typography>
                      </Box>
                      <Typography>{`1921928192819281`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Box>
                        <Typography> Validity</Typography>
                      </Box>
                      <Typography>{`Monthly`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Box>
                        <Typography> Type</Typography>
                      </Box>
                      <Typography>{`Individual`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Box>
                        <Typography> Payment Amount</Typography>
                      </Box>
                      <Typography>{`10000`}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box p={2}>
                <Box>
                  <Typography variant="body1">
                    Services Treatment Covered
                  </Typography>
                </Box>
                <Box px={2}>
                  <ServicesTreatmentsCoveredList />
                </Box>
              </Box>
              <Divider sx={{ my: 1 }} /> {/* Adjust margin as needed */}
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box>
                      <Box>
                        <Typography>Preventive</Typography>
                      </Box>
                      <Typography>{`PLAN1`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Box>
                        <Typography> Basic</Typography>
                      </Box>
                      <Typography>{`Family`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Box>
                        <Typography> Minor</Typography>
                      </Box>
                      <Typography>{`15000 monthly`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Box>
                        <Typography> Major</Typography>
                      </Box>
                      <Typography>{`15000 monthly`}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Grid
              container
              flexDirection={"row"}
              spacing={1}
              justifyContent={"end"}
              mt={2}
              paddingRight={5}
              paddingBottom={2}
            >
              <Grid item>
                <CustomButton
                  border="1px solid #000000"
                  color="#000000"
                  title="Cancel"
                  bgColor="#ffffff"
                  handleButtonClick={() => navigate("/dashboard")}
                  borderRadius="5px"
                />
              </Grid>
              <Grid item>
                <CustomButton
                  type="button"
                  title={"Cancel Plan"}
                  backgroundcolor={"#204289"}
                  bordercolor={"#204289"}
                  color={"#fffff"}
                  width={110}
                  padding={7}
                />
              </Grid>
            </Grid>
          </CustomCard>
        </Box>
      </Box>
    </>
  );
};

export default CancelPlanDetails;
