import { Box, Grid, Paper, Typography } from "@mui/material";
import styles from "../.././screens/Client/client.module.css";
import React, { useEffect, useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CustomButton from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { apiRequest } from "../../app/services/requests";

const ClientPlanDetails = (props: any) => {
  // let user = {} as any;s
  const { clientDetails } = props;
  const navigate = useNavigate();

  const [masterPlanDetails, setMasterPlanDetails] = useState<any>({});

  const navigateTo = () =>
    navigate(`/client/${clientDetails?.clientId}/plan/select`);

  useEffect(() => {
    if (clientDetails?.planId) {
      getMasterPlan(clientDetails?.planId);
    }
  }, [clientDetails?.planId]);

  const getMasterPlan = (planId: any) => {
    apiRequest({
      url: `master-plan/${planId}/record`,
      method: "GET",
    })
      .then((res: any) => {
        if (res) {
          setMasterPlanDetails(res);
          console.log("masterPlanDetails", res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate(`/"}`); // Navigate to "/login" route
          }
        }
      });
  };

  let modifiedCategories = masterPlanDetails?.categories;
  if (typeof masterPlanDetails?.categories === "string") {
    modifiedCategories = masterPlanDetails?.categories
      ?.replace(/{|}/g, "")
      .split(",");
  }

  return (
    <Box sx={{ pl: "10px" }}>
      {clientDetails?.planId ? (
        <>
          <Box className={styles.clientPlanType}>
            <Typography>
              Plan Type:{" "}
              <strong style={{ fontSize: "14px", color: "#313035" }}>
                {" "}
                {`${masterPlanDetails?.code}-${masterPlanDetails?.type}` ||
                  "N/A"}
              </strong>
            </Typography>
            <Typography>
              Validity:{" "}
              <strong style={{ fontSize: "14px", color: "#313035" }}>
                {" "}
                {masterPlanDetails?.planValidity || "N/A"}
              </strong>
            </Typography>
            <Typography>
              Status: <b>{masterPlanDetails?.status}</b>
            </Typography>
          </Box>

          <Box className={styles.borderbottom}></Box>
          <Typography sx={{ p: 2 }}>Details</Typography>

          <Box className={styles.clientPlanType}>
            <Typography>
              Preventive :{" "}
              <strong style={{ fontSize: "14px", color: "#313035" }}>
                {" "}
                3/10 (50%) <ErrorOutlineIcon sx={{ fontSize: "14px" }} />{" "}
              </strong>
            </Typography>
            <Typography>
              {modifiedCategories}
              {/* <strong style={{ fontSize: "14px", color: "#313035" }}>
                {" "}
                3/5 (40%) <ErrorOutlineIcon sx={{ fontSize: "14px" }} />{" "}
              </strong> */}
            </Typography>
          </Box>
          <Box className={styles.borderbottom}></Box>

          <Box>
            <br />
            <Typography>Services/Treatments Covered</Typography>
            <Typography className={styles.ServiecesText}>
              <br />- Some treatments once finished on a tooth cannot be done
              for 1yr,3yrs,5yrs etc.
            </Typography>
            <br />
            <Typography className={styles.ServiecesText}>
              -Cap done cannot claimed for 5 years
            </Typography>
            <br />
            <Typography className={styles.ServiecesText}>
              - Denture Cannot be repeated with in 5 years{" "}
            </Typography>
            <br />
            <Typography className={styles.ServiecesText}>
              - Filling cannot be Claimed with in 2 years
            </Typography>
            <br />
            <Typography className={styles.ServiecesText}>
              - X rays are fixed per year
            </Typography>
            <br />
            <Box className={styles.borderbottom}></Box>
          </Box>
          {/* 
          <Grid
            item
            xs={12}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            paddingLeft={"10px"}
            paddingRight={"10px"}
            paddingTop={"5px"}
          >
            <Box>
              <Typography>Preventive:</Typography>
              <Typography>Treatment Covered 10, Discount 50%</Typography>
            </Box>
            <Box>
              <Typography>Basic:</Typography>
              <Typography>Treatment Covered 10, Discount 50%</Typography>
            </Box>
          </Grid>
          <br />
          <Grid
            item
            xs={12}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            paddingLeft={"10px"}
            paddingRight={"10px"}
            paddingTop={"5px"}
            paddingBottom={"15px"}
          >
            <Box>
              <Typography>Minor:</Typography>
              <Typography>Treatment Covered 10, Discount 50%</Typography>
            </Box>
            <Box>
              <Typography>Major:</Typography>
              <Typography>Treatment Covered 10, Discount 50%</Typography>
            </Box>
          </Grid> */}
        </>
      ) : (
        <>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={300}
          >
            <CustomButton
              type="button"
              title="Buy a Plan"
              handleButtonClick={navigateTo}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ClientPlanDetails;
