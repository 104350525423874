import React, { useEffect, useState } from "react";
import { IRATable } from "../../components/ira-table/ira-table";
import { IRATableColumnProps } from "../../components/ira-table/ira-table.interfaces";
import NameCell from "../../components/TableComponent/name-cell/name-cell";
import ActionCell from "../../components/TableComponent/action-cell";
import Breadcrumb from "../../components/Breadcrumb";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Card, Grid, InputAdornment, Typography } from "@mui/material";
import CustomButton from "../../components/Button";
import { apiRequest } from "../../app/services/requests";
import IRATypography from "../../components/ira-typography/ira-typography";
import CustomInput from "../../components/Input";
import { Close, Search } from "@mui/icons-material";
import CustomDialog from "../../components/CustomDialog";
import CustomSelectPicker from "../../components/SelectPicker";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "../../app/context/useSnackbar";
import Pagination from "../../components/Pagination";

const LeadList = (props: any) => {
  const { showOnlyList = true, hideBreadScrum, hideSearch } = props;
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [leadList, setLeadList] = useState({ results: [], totalCount: 0 });
  const [searchTerm, setSearchTerm] = React.useState("");
  const [statusDialogOpen, setStatusDialogOpen] = useState<{
    id: string;
  } | null>(null);

  const { control, handleSubmit, setValue } = useForm<any>({
    defaultValues: {},
  });

  const [limit, setLimit] = useState<any>(10);
  const [skip, setSkip] = useState<any>(0);

  useEffect(() => {
    getAllClients();
  }, [searchTerm, skip, limit]);

  const getAllClients = () => {
    const payload = {
      ...(searchTerm && {
        searchText: searchTerm,
      }),
    };
    apiRequest({
      url: `lead/list?limit=${limit}&skip=${skip}&sort=-createdAt`,
      method: "GET",
    })
      .then((res: any) => {
        if (res) {
          setLeadList(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  const activityTableColumns = () =>
    [
      {
        name: "id",
        label: "Id",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "clientName",
        label: "Name",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "mobile",
        label: "Mobile No",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "userType",
        label: "User Type",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "email",
        label: "Email",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "editAction",
        label: "",
        component: (props: any) => {
          return ActionCell({
            button: true,
            btnVariant: "primary",
            btnSize: "smallest",
            disabled: props.data.status !== "new",
            infoMessage: "Status",
            label: "Update Status",
            onClick: () => {
              setStatusDialogOpen(props.data);
            },
            ...props,
          } as any);
        },
      } as any,
      {
        name: "action",
        label: "Action",
        component: (props: any) => {
          return ActionCell({
            button: true,
            btnVariant: "primary",
            btnSize: "smallest",
            disabled: props.data.status !== "processed",
            infoMessage: "Invite",
            label: "Send Invite",
            onClick: () => {
              // navigate(`/lead/${props?.data?.id}/view/plan-details`);
            },
            ...props,
          } as any);
        },
      } as any,
    ] as Array<IRATableColumnProps>;

  const breadcrumbs = [
    <RouterLink to="/Dashboard" style={{ textDecoration: "none" }} key="1001">
      <IRATypography
        key="2"
        textVariant="h12WithSemiBold"
        sx={{ fontSize: 14, color: "#222222" }}
      >
        Home
      </IRATypography>
    </RouterLink>,
    <IRATypography
      key="3"
      textVariant="h12WithSemiBold"
      sx={{ fontSize: 14, color: "#222222" }}
    >
      Lead List
    </IRATypography>,
  ];

  const activityTableData = () => {
    return leadList?.results
    ?.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()) 
    .map((row: any) => {
      return {
        ...row,
        id: {
          name: row?.id,
        },
        clientName: {
          name: `${row?.firstName} ${row?.lastName} `,
        },
        dob: {
          name: `${row?.dateOfBirth}`,
        },
        mobile: {
          name: `${row?.mobile}`,
        },
        userType: {
          name: `${row?.userType}`,
        },
        email: {
          name: `${row?.email}`,
        },
        editAction: {
          data: row,
        },
        action: {
          data: row,
        },
      };
    });
  };

  const onStatusSubmit = async (data: any) => {
    const response: any = await apiRequest({
      method: "PATCH",
      url: `lead/${statusDialogOpen?.id}/status`,
      data: data,
    });
    setStatusDialogOpen(null);
    getAllClients();
    openSnackbar(
      response ? "Successfully Status updated" : "Something went Wrong",
      ` ${response ? "success" : "error"} `
    );
  };

  useEffect(() => {
    if (statusDialogOpen === null) {
      setValue("comment", "");
      setValue("status", null);
    }
  }, [statusDialogOpen]);

  const handlePageChange = (page?: any) => {
    const newSkip = page * limit;
    setSkip(newSkip);
  };

  return (
    <>
      {!hideBreadScrum && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            pt: "10px",
          }}
        >
          <Breadcrumb
            title={`Lead List`}
            key="lead-list-breadcrumb"
            breadcrumbsArr={breadcrumbs}
          />
          <Box sx={{ display: "flex", gap: "8px", pt: "20px" }}>
            <CustomButton
              onClick={() => navigate("/lead/add")}
              title={"Add Lead +"}
            />
          </Box>
        </Box>
      )}

      <Box sx={{ pt: "5px" }}>
        <Card>
        {!hideSearch && (
          <Grid container sx={{ padding: "10px" }}>
            <Grid item md={3} sx={{ background: "#F0F2F6", pb: "10px" }}>
              <CustomInput
                size={"small"}
                placeholder="Search by Name"
                placeholderColor="#204289"
                placeholderColorOpacity={1}
                placeholderSize={15}
                value={searchTerm}
                onChange={(event: any) => setSearchTerm(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      {searchTerm.length > 0 && (
                        <InputAdornment
                          sx={{ cursor: "pointer" }}
                          position="end"
                          onClick={() => setSearchTerm("")}
                        >
                          <Close />
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
            </Grid>
            <Grid />
          </Grid>
        )}

          <IRATable
            columns={activityTableColumns()}
            data={activityTableData()}
            onSort={(name: string, order: string) =>
              alert(`sort requested for ${name} with order ${order}`)
            }
          />

          <Box display={"flex"} justifyContent={"flex-end"}>
            <Box>
              <Pagination
                onPageChange={(data: any) => {
                  handlePageChange(data);
                }}
                onRowsPerPageChange={(limit?: number, skip?: number) => {
                  setLimit(limit);
                  setSkip(0);
                }}
                totalCount={leadList?.totalCount}
              />
            </Box>
          </Box>
        </Card>
      </Box>
      <CustomDialog
        open={!!statusDialogOpen}
        width="650px"
        handleClose={() => setStatusDialogOpen(null)}
        title={"Update Status"}
        maxWidth={"lg"}
        F
      >
        <Box display="flex" flexDirection={"column"} width={"100%"} gap={2}>
          <form onSubmit={handleSubmit(onStatusSubmit)}>
            <Box display="flex" flexDirection="column" gap={2}>
              <Controller
                name="status"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomSelectPicker
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : ""}
                    label={"Select Status"}
                    size={"small"}
                    fullWidth={true}
                    width={"100%"}
                    selectData={[
                      { id: "new", name: "New" },
                      { id: "processed", name: "Processed" },
                      { id: "not-interested", name: "Not-Interested" },
                    ]}
                    noneRequired={false}
                  />
                )}
              />
              <Controller
                name="comment"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : ""}
                    label={"Enter Comment"}
                    InputProps={""}
                    size={"small"}
                  />
                )}
              />
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <CustomButton
                  title={"Cancel"}
                  marginRight={"5px"}
                  bgColor={"rgba(255, 225, 229, 1)"}
                  width={"100px"}
                  color={"rgba(255, 97, 116, 1)"}
                  borderColor={"rgba(255, 97, 116, 1)"}
                  borderWidth={"1px"}
                  borderStyle={"solid"}
                  onClick={() => setStatusDialogOpen(null)}
                />
                <Box marginLeft={1}>
                  <CustomButton
                    type={"submit"}
                    title={"Save"}
                    marginRight={"5px"}
                    bgColor={"rgba(92, 78, 255, 1)"}
                    width={"100px"}
                  />
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      </CustomDialog>
    </>
  );
};

export default React.memo(LeadList);
