// PageNotFound.js
import { Box } from "@mui/material";
import React from "react";

const PageNotFound = () => {
  return (
    <Box mt={10}>
      <h1>404 - Page Not Found</h1>
      {/* Add your custom 404 content here */}
    </Box>
  );
};

export default PageNotFound;
