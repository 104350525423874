import { useEffect, useState } from "react";
import { Container, Grid, Paper, Box, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import CustomTabs from "../../components/CustomTabs";
import ClinicProfileCard from "../../components/ClinicProfileCard";
import { apiRequest } from "../../app/services/requests";
import AdditionalInfo from "../Hospital/AdditionalInfo/additional-info";
import DoctorDocuments from "../Hospital/DoctorDocuments/doctor-documents";
import DoctorDetails from "./doctor-details";
import DoctorPersonalDetails from "./doctor-details";
// import DoctorDetails from "./doctor-details";

interface TabOption {
  index: number;
  id: string;
  name: string;
  component: JSX.Element;
}

const DoctorProfileCard = (props: any) => {
  const [doctorDetails, setDoctorDetails] = useState("");
  const navigate = useNavigate();

  const { doctorId, hospitalId } = props;
  const [selectedTab, setSelectedTab] = useState<any>(0);

  // //useEffect starts

  useEffect(() => {
    if (doctorId) {
      getDocotorDetails(doctorId);
    }
  }, [doctorId]);

  const getDocotorDetails = (doctorId: any) => {
    apiRequest({
      url: `clinic/${hospitalId}/doctor/${doctorId}/details`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          setDoctorDetails(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate(`/"}`); // Navigate to "/login" route
          }
        }
      });
  };

  const tabs = [
    {
      index: 0,
      id: 0,
      name: "Additional Info",
      component: <AdditionalInfo />,
    },
    {
      index: 1,
      id: 1,
      name: "Documents",
      component: <DoctorDocuments />,
    },
  ];

  // console.log("doctorDetails", doctorDetails);

  return (
    <Container maxWidth="lg" sx={{ padding: "0 !important" }}>
      <Box mt={2}>
        <Grid container spacing={2} style={{ marginBottom: "20px" }}>
          <Grid item xs={3.5}>
            <Box style={{ height: "100vh" }}>
              <DoctorPersonalDetails userData={doctorDetails} />
            </Box>
          </Grid>
          <Grid item xs={8.5}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap={2}
              mb={2.5}
            >
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  flexBasis: "33.33%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src={require("../../app/assets/Hospital/rect4.png")}
                />
                <Box style={{ display: "grid", marginLeft: "10px" }}>
                  <Typography variant="h6">Check Up</Typography>
                  <Typography variant="h5" style={{ fontWeight: "700" }}>
                    100
                  </Typography>
                </Box>
              </Paper>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  flexBasis: "33.33%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src={require("../../app/assets/Hospital/rect2.png")}
                />
                <Box style={{ display: "grid", marginLeft: "10px" }}>
                  <Typography variant="h6">Treatments</Typography>
                  <Typography variant="h5" style={{ fontWeight: "700" }}>
                    50
                  </Typography>
                </Box>
              </Paper>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  flexBasis: "33.33%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src={require("../../app/assets/Hospital/rect3.png")}
                />
                <Box style={{ display: "grid", marginLeft: "10px" }}>
                  <Typography variant="h6">Cases</Typography>
                  <Typography variant="h5" style={{ fontWeight: "700" }}>
                    100
                  </Typography>
                </Box>
              </Paper>
            </Box>
            <CustomTabs
              tabOptions={tabs}
              currentTab={selectedTab}
              onTabChange={(value: number) => {
                setSelectedTab(value);
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default DoctorProfileCard;
