import moment from "moment";

export const getDateMonthYear = (dateObj) => {
  // const month = dateObj.getUTCMonth() + 1; //months from 1-12
  // const day = dateObj.getUTCDate() + 1;
  // const year = dateObj.getUTCFullYear();
  // return `${month}/${day === 32 ? 1 : day }/${year}`
  return moment(dateObj).format("MM/DD/YYYY");
};

export const getYearMonth = (dateObj) => {
  // const month = dateObj.getUTCMonth() + 1; //months from 1-12
  // const day = dateObj.getUTCDate() + 1;
  // const year = dateObj.getUTCFullYear();
  // return `${month}/${day === 32 ? 1 : day }/${year}`.
  return moment(dateObj).format("MM/YYYY");
};

export const getString = (data, key = "name") => {
  return data.map((item) => item[key] || "").join(" ");
};

export const convertToDateAndTime = (dateandtime) => {
  let datetime = new Date(dateandtime);
  let date = datetime.toLocaleDateString();
  date = moment(date).format("DD MMM YYYY");
  let hours = datetime.getHours();
  let miniutes = datetime.getMinutes();
  if (miniutes === 0) {
    miniutes = "00";
  }
  let dayType = hours < 12 ? "Am" : "Pm";
  return date + " | " + hours + ":" + miniutes + " " + dayType;
};

export const getTimeStops = (start, end) => {
  const startTime = moment(start, "hh:mm A");
  const endTime = moment(end, "hh:mm A");
  if (endTime.isBefore(startTime)) {
    endTime.add(1, "day");
  }
  const timeStops = [];
  while (startTime <= endTime) {
    timeStops.push(new moment(startTime).format("hh:mm A"));
    startTime.add(15, "minutes");
  }
  return timeStops;
};

export const getAppointmentsPastFutureDates = () => {
  const todayDate1 = new Date();
  const todayDate2 = new Date();
  const pastThreeMonthsDate = new Date(
    todayDate1.setMonth(todayDate1.getMonth() - 3)
  );
  const futureOneMonthDate = new Date(
    todayDate2.setMonth(todayDate2.getMonth() + 1)
  );
  return {
    pastThreeMonthsDate,
    futureOneMonthDate,
  };
};

export const getTimeDuration = (startTime, endTime) => {
  const start = moment(startTime);
  const end = moment(endTime);
  const duration = moment.duration(end.diff(start));
  return duration.asMinutes();
};

export const getAge = (dateOfBirth) => {
  return `${moment().diff(dateOfBirth, "years", false)} Yrs`;
};

export const getOnlyDate = (date, format = "YYYY-MM-DD") => {
  return moment(date).format(format);
};

export const getStartAndTime = (start, end) => {
  const startAt = moment(start).format("HH:mm");
  const endAt = moment(end).format("HH:mm");
  const slots = getTimeStops(startAt, endAt);
  slots.splice(-1);
  return slots;
};

export const isDateInLastWeek = (date) => {
  // Create a date to check (replace 'YYYY-MM-DD' with the date you want to check)
  const dateToCheck = moment(date);
  // Get the current date
  const currentDate = moment();
  // Calculate the start date of the current ISO week (Monday)
  const currentWeekStartDate = currentDate.clone().startOf("isoWeek");
  // Calculate the end date of the last ISO week (Sunday)
  const lastWeekEndDate = currentWeekStartDate.clone().subtract(1, "day");
  const lastWeekStartDate = lastWeekEndDate.clone().subtract(6, "days");
  // Check if the date is in the last week from Monday to Sunday based on ISO week
  return dateToCheck.isBetween(lastWeekStartDate, lastWeekEndDate, null, "[]");
};

export const isDateInCurrentMonth = (date) => {
  const dateToCheck = moment(date);
  // Get the current date
  const currentDate = moment();
  // Check if the year and month of dateToCheck match the current year and month
  return dateToCheck.isSame(currentDate, "month");
};

export const isDateInLastMonth = (date) => {
  // Create a date to check (replace 'YYYY-MM-DD' with the date you want to check)
  const dateToCheck = moment(date);
  // Get the current date
  const currentDate = moment();
  // Calculate the start of the current month
  const startOfCurrentMonth = currentDate.clone().startOf("month");
  // Calculate the start of the previous month
  const startOfLastMonth = currentDate
    .clone()
    .subtract(1, "month")
    .startOf("month");
  // Check if dateToCheck is after or equal to the start of the last month
  // and before the start of the current month
  return (
    dateToCheck.isSameOrAfter(startOfLastMonth, "day") &&
    dateToCheck.isBefore(startOfCurrentMonth, "day")
  );
};

export const getConvertTimeToDate = (date, time) => {
  const selectedTime = moment(time, "hh:mm A").format("HH:mm");
  return moment(moment(date).format("MM/DD/YYYY") + " " + selectedTime);
};

// Custom validation function for a 10-digit phone number
export const validateMobileNumber = (value) => {
  const phoneNumberRegex = /^\d{10}$/; // Matches exactly 10 digits
  if (!phoneNumberRegex.test(value)) {
    return "Mobile number must be 10 digits";
  }
  return true;
};

// Define a validateEmail function
export const validateEmail = (value) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(value) || "Invalid email address";
};

export const uniqueDataFromArray = (arr, key = "value") => {
  const uniqueIds = [];
  return arr.filter((element) => {
    const isDuplicate = uniqueIds.includes(element[key]);
    if (!isDuplicate) {
      uniqueIds.push(element[key]);
      return true;
    }
    return false;
  });
};

export const groupByToothFindings = (
  data,
  selectedData = [],
  teethType = "dental"
) => {
  const prevData = data.reduce((prev, examination) => {
    const d = (examination.toothNumbers || []).map((teeth) => ({
      ...teeth,
      examinationNotes: examination.examinationNotes || "",
      provisionalDiagnosis: examination.provisionalDiagnosis || "",
      suggestedTreatments: examination.suggestedTreatments || [],
      investigation: examination.investigation || [],
      selectedTeeth: true,
      teethType: examination.teethType || "",
      interpretation: examination.interpretation || "",
      finalDiagnosis: examination.finalDiagnosis || [],
      treatmentsPlanned: examination.treatmentsPlanned || [],
      periodontalFindings: examination.periodontalFindings || [],
    }));
    return [...prev, ...d];
  }, []);
  const t = [...prevData, ...selectedData].reduce((acc, teeth) => {
    const prevVal = acc[`${teeth.teethNumber}-${teeth.findings}`] || null;
    if (prevVal) {
      acc[`${teeth.teethNumber}-${teeth.findings}`] = {
        ...teeth,
        ...prevVal,
        isPrimaryTeeth: false,
        findings: teeth.findings,
        selectedTeeth: true,
        teethType,
        surface: uniqueDataFromArray([
          ...(prevVal.surface || []),
          ...(teeth.surface || []),
        ]),
        toothNumbers: [
          {
            teethNumber: teeth.teethNumber,
            surface: uniqueDataFromArray([
              ...(prevVal.toothNumbers[0].surface || []),
              ...(teeth.surface || []),
            ]),
            findings: teeth.findings,
          },
        ],
      };
    } else {
      acc[`${teeth.teethNumber}-${teeth.findings}`] = {
        ...teeth,
        isPrimaryTeeth: false,
        teethType,
        selectedTeeth: true,
        findings: teeth.findings,
        surface: teeth.surface,
        toothNumbers: [
          {
            teethNumber: teeth.teethNumber,
            surface: teeth.surface,
            findings: teeth.findings,
          },
        ],
      };
    }
    return acc;
  }, {});

  const p = Object.values(t).reduce((acc, teeth) => {
    const prevVal = acc[`${teeth.teethNumber}`] || null;
    if (prevVal) {
      acc[`${teeth.teethNumber}`] = {
        ...acc[`${teeth.teethNumber}`],
        ...teeth,
        toothNumbers: [
          ...acc[`${teeth.teethNumber}`].toothNumbers,
          ...teeth.toothNumbers,
        ],
      };
    } else {
      acc[`${teeth.teethNumber}`] = {
        ...teeth,
      };
    }
    return acc;
  }, {});

  const y = Object.values(p).reduce((acc, teeth) => {
    if (
      teeth.surface &&
      teeth.surface.length <= 1 &&
      teeth.toothNumbers &&
      teeth.toothNumbers.length <= 1 &&
      teeth.findings
    ) {
      if (Object.values(acc[`${teeth.findings}`] || {}).length > 0) {
        acc[`${teeth.findings}`] = [
          {
            ...acc[`${teeth.findings}`][0],
            toothNumbers: [
              ...acc[`${teeth.findings}`][0].toothNumbers,
              {
                teethNumber: teeth.teethNumber,
                surface: teeth.surface,
                findings: teeth.findings,
              },
            ],
            teethNumber: `${acc[`${teeth.findings}`][0].teethNumber}, ${
              teeth.teethNumber
            }`,
          },
        ];
      } else {
        acc[`${teeth.findings}`] = [
          {
            ...teeth,
            toothNumbers: [
              {
                teethNumber: teeth.teethNumber,
                surface: teeth.surface,
                findings: teeth.findings,
              },
            ],
          },
        ];
      }
    } else {
      acc["other"] = [...(acc["other"] || []), teeth];
    }
    return acc;
  }, {});

  const d = Object.values(y)
    .flat()
    .reduce((acc, teeth) => {
      const prevVal = acc[`${teeth.teethNumber}`] || null;
      if (prevVal) {
        acc[`${teeth.teethNumber}`] = {
          ...acc[`${teeth.teethNumber}`],
          ...teeth,
          toothNumbers: [
            ...acc[`${teeth.teethNumber}`].toothNumbers,
            ...teeth.toothNumbers,
          ],
        };
      } else {
        acc[`${teeth.teethNumber}`] = {
          ...teeth,
        };
      }
      return acc;
    }, {});

  const m = JSON.parse(JSON.stringify(Object.values(d))).reduce(
    (acc, teeth) => {
      const prevVal = acc[`${teeth.findings}`] || null;
      if (prevVal && (!teeth.surface || !teeth.surface.length)) {
        acc[`${teeth.findings}`] = {
          ...teeth,
          teethNumber: `${prevVal.teethNumber}, ${teeth.teethNumber}`,
          toothNumbers: [...prevVal.toothNumbers, ...teeth.toothNumbers],
        };
      } else {
        acc[`${teeth.findings}`] = teeth;
      }
      return acc;
    },
    {}
  );

  const l = JSON.parse(JSON.stringify(Object.values(d)));

  for (const [idx, value] of Object.values(d).entries()) {
    const h = value;
    if (h.teethNumber.length <= 2) {
      h.toothNumbers.reduce((acc, curr, index1) => {
        if (curr.surface && curr.surface.length) {
          for (const [index, value1] of curr.surface.entries()) {
            for (const [key, q] of acc.slice(0, index1).entries()) {
              if (
                (q.surface || []).map((sur) => sur.value).includes(value1.value)
              ) {
                const foundIdx = l[idx].toothNumbers[key].surface.findIndex(
                  (g) => g.value === value1.value
                );
                l[idx].toothNumbers[key].surface = l[idx].toothNumbers[
                  key
                ].surface.filter((g, i) => i !== foundIdx);
                if (!l[idx].toothNumbers[key].surface[0]) {
                  l[idx].toothNumbers = l[idx].toothNumbers.filter(
                    (g, index) => index !== key
                  );
                }
              }
            }
          }
        }
        return [...acc, curr];
      }, []);
    }
  }
  return l;
};
export const extractErrorMessage = (error) => {
  if (error.response) {
    // The request was made, but the server responded with an error status code
    if (error.response.data && error.response.data.message) {
      // Check if there is a specific error message in the response data
      return error.response.data.message;
    } else {
      // If there is no specific message, use a generic error message
      return "An error occurred while processing your request.";
    }
  } else if (error.message) {
    // Check if the error object has a message property (e.g., for network errors)
    return error.message;
  } else if (error instanceof Error) {
    // Check if it's a custom error object with a message property
    return error.message;
  } else {
    // Something else happened that triggered an error
    return "An error occurred.";
  }
};

export const convertObjectToString = (data) => {
  let str = "";
  for (let d in data) {
    str += `${d} (${data[d]}) \n`;
  }
  return str;
};

export function extractFilenameFromPath(filePath) {
  const lastHyphenIndex = filePath ? filePath.lastIndexOf("-") : -1;

  let modifiedFilePath = filePath;
  if (lastHyphenIndex !== -1) {
    modifiedFilePath = filePath.substring(lastHyphenIndex + 1);
  }

  return modifiedFilePath;
}

export const getDateMonthYearFormat = (dateObj) => {
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // pad month with leading zero if necessary
  const day = dateObj.getDate().toString().padStart(2, "0"); // pad day with leading zero if necessary
  const year = dateObj.getFullYear();
  return `${year}-${month}-${day}`;
};

export function CapitalizeFirstLetter(str) {
  if (typeof str !== "string" || str.length === 0) return "";

  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const calculateBillingAmount = (selectedBills = [], billings = []) =>
  selectedBills.reduce((acc, bill) => {
    const { billAmount = 0, paidAmount = 0 } =
      billings.find((b) => b.id === bill) || {};
    return (acc += Number(billAmount));
  }, 0);

export const calculateBillAmount = (selectedBills = [], billings = []) =>
  selectedBills.reduce((acc, bill) => {
    const { billAmount = 0, paidAmount = 0 } =
      billings.find((b) => b.id === bill) || {};
    return (acc += Number(billAmount) - Number(paidAmount));
  }, 0);

export const getRequiredStringByRole = (userType, name) => {
  return userType === "doctor" ? `Dr.${name}` : name;
};

export const getAllDatesInMonthYear = (year, month) => {
  // Month in Moment.js is 0-indexed, so subtract 1 from the provided month
  const startDate = moment(`${year}-${month + 1}-01`);
  const endDate = moment(startDate).endOf("month");

  const dates = [];
  let currentDate = startDate;

  while (currentDate.isSameOrBefore(endDate)) {
    dates.push(currentDate.format("YYYY-MM-DD"));
    currentDate.add(1, "day");
  }

  return dates;
};

export const generateTimeSlots = () => {
  const timeArray = [];

  for (let hour = 0; hour <= 23; hour++) {
    for (let minute = 0; minute <= 30; minute += 30) {
      const hourStr = hour.toString().padStart(2, "0");
      const minuteStr = minute.toString().padStart(2, "0");
      const timeString = `${hourStr}:${minuteStr}`;
      timeArray.push(timeString);
    }
  }
  return timeArray;
};

export const roleBasedPatientURL = (user) => {
  const GET_PATIENTS_URL = "patients";
  if (user.userType === "doctor") {
    return `${GET_PATIENTS_URL}?skip=0&limit=1000&doctorId=${user.id}`;
  } else if (
    ["clinicAdmin", "pharmacist", "frontdesk"].includes(user.userType)
  ) {
    return `${GET_PATIENTS_URL}?skip=0&limit=1000&clinicId=${user.clinicId}`;
  } else {
    return `${GET_PATIENTS_URL}?skip=0&limit=1000}`;
  }
};

export function pxToRem(value) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ xs, sm, md, lg, xl }) {
  return {
    fontSize: pxToRem(xs),
    ...(sm
      ? {
          "@media (min-width:600px)": {
            fontSize: pxToRem(sm),
          },
        }
      : {}),
    ...(md
      ? {
          "@media (min-width:1024px)": {
            fontSize: pxToRem(md),
          },
        }
      : {}),
    ...(lg
      ? {
          "@media (min-width:1200px)": {
            fontSize: pxToRem(lg),
          },
        }
      : {}),
    ...(xl
      ? {
          "@media (min-width:1440px)": {
            fontSize: pxToRem(xl),
          },
        }
      : {}),
  };
}

export const stripAllHtml = (html) => {
  var doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};

export const getTrimmedString = (str, length = 100) => {
  return str?.length > length ? "..." + str?.slice(-length) : str;
}