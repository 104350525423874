import { Box, Grid, Typography } from "@mui/material";
import CustomButton from "../../../components/Button";
import { theme } from "../../../theme/theme";

import { useEffect, useState } from "react";
import { PaymentSuccessScreen } from "../components/payment-success";

const Step3 = (props: any) => {
  const { onClickBack } = props;

  const [showPaymentSuccessScreen, setShowPaymentSuccessScreen] =
    useState<any>(false);


  return (
    <>
      <Box p={2}>
        {showPaymentSuccessScreen ? (
          <PaymentSuccessScreen />
        ) : (
          <Box p={"0px 20px 50px 20px"} mt={"10px"}>
            <Typography
              mt={"10px"}
              variant="h6"
              color={theme.palette.customColors.blackGrey}
              fontWeight={"600"}
              fontSize={"16px"}
            >
              Plan Details:
            </Typography>
            <Box display={"flex"} justifyContent={"space-between"} mt={"20px"}>
              <Typography variant="body2">
                Plan Name : <b>Simple Plan</b>
              </Typography>
              <Typography variant="body2">
                Plan Range : <b>Family</b>
              </Typography>
              <Typography variant="body2">
                Plan Period : <b>One Year</b>
              </Typography>
            </Box>
          </Box>
        )}

        {showPaymentSuccessScreen === false && (
          <Grid
            container
            flexDirection={"row"}
            spacing={0.5}
            justifyContent={"end"}
            mt={4}
          >
            <Grid item>
              <CustomButton
                border="1px solid #000000"
                color="#000000"
                title="Back"
                bgColor="#ffffff"
                handleButtonClick={onClickBack}
                borderRadius="5px"
              />
            </Grid>
            <Grid item>
              <CustomButton
                border="1px solid #000000"
                color="#000000"
                title="Cancel"
                bgColor="#ffffff"
                // handleButtonClick={() => navigate("/dashboard")}
                borderRadius="5px"
              />
            </Grid>
            <Grid item>
              <CustomButton
                type="submit"
                title={"Pay Now"}
                backgroundcolor={"#204289"}
                bordercolor={"#204289"}
                color={"#fffff"}
                handleButtonClick={() => setShowPaymentSuccessScreen(true)}
                width={110}
                padding={7}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default Step3;
