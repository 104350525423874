import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import CustomCard from "../../components/Card";
import CustomTabs from "../../components/CustomTabs";
import { useNavigate, useParams } from "react-router";
import CaseExamination from "./case-examination";
import CaseHistory from "./case-history";
import CaseInvestigation from "./case-investigation";
import PreOperativePhotos from "./pre-operative-photos";
import TreatmentPlanning from "./treatment-planning";
import CaseViewCard from "../../components/CaseViewCard";
import IRATypography from "../../components/ira-typography/ira-typography";
import Breadcrumb from "../../components/Breadcrumb";
import { Link as RouterLink } from "react-router-dom";
import CaseHistoryDetails from "./case-history-details";
import { apiRequest } from "../../app/services/requests";

const CaseDetails = () => {
  const { caseId, currentTab } = useParams();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [caseViewTabDetails, setcaseViewTabDetails] = useState();
  // console.log(caseViewTabDetails, "Prasad");

  useEffect(() => {
    getcaseDetails();
  }, []);

  const getcaseDetails = () => {
    apiRequest({
      url: `case/4`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          setcaseViewTabDetails(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate(`/cases/${"ca6090934"}/details/${"case-history"}`); // Navigate to "/login" route
          }
        }
      });
  };
  const tabs = [
    {
      index: 0,
      id: "case-history",
      name: "Case History",
      component: <CaseHistoryDetails caseHistoryID={caseViewTabDetails} />,
      // component: <CaseHistory />,
    },
    {
      index: 1,
      id: "examination",
      name: "Examination",
      component: <CaseExamination ExaminationID={caseViewTabDetails} />,
    },
    {
      index: 2,
      id: "investigation",
      name: "Investigation",
      component: <CaseInvestigation ExaminationID={caseViewTabDetails} />, //Invistigation Also Same to Examination
    },
    {
      index: 3,
      id: "pre-operative-photos",
      name: "Pre-operative Photos",
      component: <PreOperativePhotos />,
    },
    {
      index: 4,
      id: "treatment-planning",
      name: "Tentative T.Planning",
      component: <TreatmentPlanning caseDetails={caseViewTabDetails} />,
    },
  ];
  const breadcrumbs = [
    <RouterLink to="/Dashboard" style={{ textDecoration: "none" }} key="1001">
      <IRATypography
        key="2"
        textVariant="h12WithSemiBold"
        sx={{ fontSize: 14, color: "#222222" }}
      >
        Home
      </IRATypography>
    </RouterLink>,
    <IRATypography
      key="3"
      textVariant="h12WithSemiBold"
      sx={{ fontSize: 14, color: "#222222" }}
    >
      Cases View
    </IRATypography>,
  ];

  useEffect(() => {
    if (currentTab) {
      const targetTab: any = tabs.find((tab) => tab.id === currentTab);
      setSelectedTab(targetTab?.index);
    }
  }, [currentTab]);

  const navigate = useNavigate();

  return (
    <Container maxWidth="lg" sx={{ padding: "0 !important" }}>
      <Box sx={{ paddingTop: 2 }}>
        <Breadcrumb
          title={`Cases View`}
          key="case-list-breadcrumb"
          breadcrumbsArr={breadcrumbs}
        />
      </Box>
      <Box mt={2}>
        <Grid container spacing={2}>
          {/* 3 columns */}
          <Grid item xs={3.5}>
            <Box>
              <CaseViewCard />
            </Box>
          </Grid>
          {/* 9 columns */}
          <Grid item xs={8.5}>
            <Paper>
              {/* Content for the second column */}
              <CustomTabs
                tabOptions={tabs}
                currentTab={Number(selectedTab)}
                onTabChange={(value: any) => {
                  navigate(`/cases/${caseId}/details/${value}`);
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default CaseDetails;
