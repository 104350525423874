import React, { useState, useEffect } from "react";
import { Container, Typography, Paper, Box } from "@mui/material";
import "./dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import CustomDialog from "../../../components/CustomDialog";
import BookAppointmentForm from "../../Appointment/book-appointment-form";
import SelectClient from "../../../components/SelectClient";
import CustomCard from "../../../components/Card";
import CaseList from "../../CasesAndClaims/case-list";
import QueryList from "../../Query/query-list";
import { theme } from "../../../theme/theme";
import DashboardCounters from "../../Dashboard/dashboard-counters";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const SuperAdminDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  // const [clientData, setClientData] = useState({});
  const [isFlipped, setFlipped] = useState(false);
  const [isFlipped1, setFlipped1] = useState(false);
  const [isFlipped2, setFlipped2] = useState(false);
  const [isFlipped3, setFlipped3] = useState(false);
  const [openSelectClientDialog, setOpenSelectClientDialog] = useState<any>({
    open: false,
    data: {},
  });

  const [openBookAppointmentDialog, setOpenBookAppointmentDialog] =
    useState<any>({
      open: false,
      data: {},
    });

  const handleLinkClick = (type?: string) => {
    if (type === "bookAppointment") {
      setOpenBookAppointmentDialog({ open: true, data: { type } });
    } else {
      setOpenSelectClientDialog({ open: true, data: { type } });
    }
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Container maxWidth="lg" sx={{ marginTop: 2 }}>
        <DashboardCounters />

        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap" // Add flexWrap to wrap content to the next row
          justifyContent="space-between" // Adjust spacing between cards
          gap={2}
        >
          {/* Card 1 */}
          <Paper
            onMouseEnter={() => {
              setFlipped(true);
              setFlipped1(false);
              setFlipped2(false);
            }}
            onMouseLeave={() => setFlipped(false)}
            className={`flip-card ${isFlipped ? "flipped" : ""}`}
            elevation={3}
            sx={{
              p: 2,
              flexBasis: "30%",
              flexGrow: 1,
              background:
                "linear-gradient(rgba(184, 178, 255, 1), rgba(255, 227, 247, 1))",
              borderRadius: "15px",
              height: "170px",
            }}
          >
            <Box className="flip-card-inner">
              <Box className="flip-card-front">
                <Box
                  component="img"
                  src={require("../../../app/assets/Dashboard/hospital_profile.png")}
                ></Box>
                <Typography variant="h6">Users</Typography>
              </Box>

              {isFlipped ? (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  height="100%"
                  flexWrap="wrap"
                  className="flip-card-back"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width={"100%"}
                  >
                    <Link
                      to="/user/add"
                      style={{
                        marginBottom: 2,
                        textDecoration: "none",
                        fontSize: "16px",
                        color: theme.palette.customColors.main,
                      }}
                    >
                      Add User
                    </Link>
                    <Link
                      to="/user/list"
                      style={{
                        marginBottom: 2,
                        textDecoration: "none",
                        fontSize: "16px",
                        color: theme.palette.customColors.main,
                      }}
                    >
                      Users List
                    </Link>
                    {/* <Link
                      to="/user/12122/view"
                      style={{
                        marginBottom: 2,
                        textDecoration: "none",
                        fontSize: "16px",
                        color: theme.palette.customColors.main,
                      }}
                    >
                      Users View
                    </Link> */}
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Paper>
          <Paper
            onMouseEnter={() => {
              setFlipped(false);
              setFlipped1(false);
              setFlipped2(false);
              setFlipped3(true);
            }}
            onMouseLeave={() => setFlipped3(false)}
            className={`flip-card ${isFlipped3 ? "flipped" : ""}`}
            elevation={3}
            sx={{
              p: 2,
              flexBasis: "30%",
              flexGrow: 1,
              background:
                "linear-gradient(rgba(184, 178, 255, 1), rgba(255, 227, 247, 1))",
              borderRadius: "15px",
              height: "170px",
            }}
          >
            <Box className="flip-card-inner">
              <Box className="flip-card-front">
                <Box
                  component="img"
                  src={require("../../../app/assets/Dashboard/hospital_profile.png")}
                ></Box>
                <Typography variant="h6">Masters</Typography>
              </Box>

              {isFlipped3 ? (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  height="100%"
                  flexWrap="wrap"
                  className="flip-card-back"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width={"100%"}
                  >
                    <Link
                      to="/master/plan-list"
                      style={{
                        marginBottom: 2,
                        textDecoration: "none",
                        fontSize: "16px",
                        color: theme.palette.customColors.main,
                      }}
                    >
                      Plans
                    </Link>
                    {/* <Link
                      to="/service/setup"
                      style={{
                        marginBottom: 2,
                        textDecoration: "none",
                        fontSize: "16px",
                        color: theme.palette.customColors.main,
                      }}
                    >
                      Service SetUp
                    </Link> */}
                    <Link
                      to="/master/plan/create"
                      style={{
                        marginBottom: 2,
                        textDecoration: "none",
                        fontSize: "16px",
                        color: theme.palette.customColors.main,
                      }}
                    >
                      Add Plan
                    </Link>
                  </Box>
                  <Link
                    to="/hospital/list"
                    style={{
                      marginBottom: 2,
                      textDecoration: "none",
                      fontSize: "16px",
                      color: theme.palette.customColors.main,
                    }}
                  >
                    Clinic List
                  </Link>

                  <Link
                    to="/hospital/add"
                    style={{
                      marginBottom: 2,
                      textDecoration: "none",
                      fontSize: "16px",
                      color: theme.palette.customColors.main,
                    }}
                  >
                    Add Clinic
                  </Link>
                  {/* <Link
                    to="/master-plan/add-services"
                    style={{
                      marginBottom: 2,
                      textDecoration: "none",
                      fontSize: "16px",
                      color: theme.palette.customColors.main,
                    }}
                  >
                    Add Services
                  </Link> */}
                </Box>
              ) : null}
            </Box>
          </Paper>
          <Box
            sx={{
              p: 2,
              flexBasis: "30%",
              flexGrow: 1,

              borderRadius: "15px",
              height: "170px",
            }}
          ></Box>
          <Box
            sx={{
              flexBasis: "30%",
              flexGrow: 1,

              height: "170px",
            }}
          ></Box>

          {openSelectClientDialog.open && (
            <CustomDialog
              width={"600px"}
              open={openSelectClientDialog.open}
              handleClose={() =>
                setOpenSelectClientDialog({ open: false, data: {} })
              }
              title={"Select Client"}
              maxWidth={"lg"}
            >
              <Box>
                <SelectClient
                  onClose={() =>
                    setOpenSelectClientDialog({ open: false, data: {} })
                  }
                  navigateTo={(data: string) => {
                    if (
                      ["health", "discount", "customize"]?.includes(
                        openSelectClientDialog.data?.type
                      )
                    ) {
                      navigate(
                        `/client/${data}/plan/${openSelectClientDialog.data?.type}`
                      );
                    } else if (
                      openSelectClientDialog.data?.type === "addCase"
                    ) {
                      navigate(`/cases/client/${data}/add`);
                    } else if (
                      [
                        "renewal",
                        "upgrade",
                        "upgrade-renewal",
                        "cancel",
                      ]?.includes(openSelectClientDialog.data?.type)
                    ) {
                      navigate(
                        `/client/${data}/plan/action/${openSelectClientDialog.data?.type}`
                      );
                    } else {
                      navigate(`/cases/client/${data}/add`);
                    }
                  }}
                  fromDashboard={true}
                  openSelectClientDialog={openSelectClientDialog}
                />
              </Box>
            </CustomDialog>
          )}

          {openBookAppointmentDialog.open && (
            <CustomDialog
              width={"650px"}
              open={openBookAppointmentDialog.open}
              handleClose={() =>
                setOpenBookAppointmentDialog({ open: false, data: {} })
              }
              title={"Book Appointment"}
              maxWidth={"lg"}
            >
              <Box>
                <BookAppointmentForm
                  onClose={() =>
                    setOpenBookAppointmentDialog({ open: false, data: {} })
                  }
                  navigateTo={(data: string) => {
                    navigate(`/cases/client/${"121213"}/add`);
                  }}
                />
              </Box>
            </CustomDialog>
          )}
        </Box>
        <Box mt={4}>
          <CustomCard style={{ height: "350px" }}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              p={"16px 16px 0px 16px"}
              textAlign={"center"}
            >
              <Box style={{ display: "flex" }}>
                <Typography
                  fontSize={"14px"}
                  sx={{ color: theme.palette.customColors.grey }}
                >
                  All Queries
                </Typography>
                <Typography
                  sx={{
                    background: "rgba(104, 212, 180, 1)",
                    padding: "3px 10px",
                    borderRadius: "7px",
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  02
                </Typography>
              </Box>
              <Box>
                <Link
                  to="/query-list"
                  style={{
                    marginBottom: 2,
                    textDecoration: "none",
                    fontSize: "12px",
                    color: theme.palette.customColors.main,
                  }}
                >
                  View All
                </Link>
              </Box>
            </Box>
            <Box p={2}>
              <QueryList hideBreadScrum={true} showOnlyList={true} />
            </Box>
          </CustomCard>
        </Box>

        <Box mt={4} mb={4}>
          <CustomCard style={{ height: "350px" }}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              p={"16px 16px 0px 16px"}
              textAlign={"center"}
            >
              <Box>
                <Typography
                  fontSize={"14px"}
                  sx={{ color: theme.palette.customColors.grey }}
                >
                  New Cases
                </Typography>
              </Box>
              <Box>
                <Link
                  to="/cases"
                  style={{
                    marginBottom: 2,
                    textDecoration: "none",
                    fontSize: "12px",
                    color: theme.palette.customColors.main,
                  }}
                >
                  View All
                </Link>
              </Box>
            </Box>
            <Box p={2}>
              <CaseList hideBreadScrum={true} showOnlyList={true} />
            </Box>
          </CustomCard>
        </Box>
      </Container>
    </div>
  );
};

export default SuperAdminDashboard;
