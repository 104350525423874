// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.doctor_alg__otX5S {
    display: ruby-text;
}

.doctor_gends__Pz9Mb {
    margin-block: auto;
    margin-top: -20px;
}

.doctor_justing__bpD-v {
    justify-self: center;
}`, "",{"version":3,"sources":["webpack://./src/screens/Doctor/doctor.module.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":["\n.alg {\n    display: ruby-text;\n}\n\n.gends {\n    margin-block: auto;\n    margin-top: -20px;\n}\n\n.justing {\n    justify-self: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alg": `doctor_alg__otX5S`,
	"gends": `doctor_gends__Pz9Mb`,
	"justing": `doctor_justing__bpD-v`
};
export default ___CSS_LOADER_EXPORT___;
