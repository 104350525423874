export { default } from "./ira-table";
export {
  DefaultCellComponent,
  DefaultHeaderCellComponent,
  IRATableCell,
  IRATableRow,
} from "./ira-table.components";
// export {
//   IRATableCellCustomization,
//   IRATableColumnProps,
//   IRATableProps
// } from './ira-table.interfaces';
export { IRATableStyles } from "./ira-table.styles";
