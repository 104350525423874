import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { apiRequest } from "../../../app/services/requests";
import { useParams } from "react-router-dom";
import moment from "moment";
import CustomButton from "../../../components/Button";

const TreatmentList = (props: any) => {
  const {
    fetchServicesAgain = false,
    selectedServices,
    onCancel,
    selectedTab,
  } = props;
  const [treatmentList, setTreatmentList] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null); // To show validation error

  const { hospitalId } = useParams();

  const isAllServicesTabSelected = Boolean(selectedTab === 0);

  useEffect(() => {
    if (selectedServices?.length) {
      getAllTreatmentList(selectedServices);
    }
  }, [selectedServices]);

  const getAllTreatmentList = (treatmentIds: any) => {
    const skip = 0;
    apiRequest({
      url: `treatment/list?ids=[${treatmentIds}]`,
      method: "GET",
    })
      .then((res: any) => {
        if (res) {
          const modifiedServices = res?.results?.map((row: any) => ({
            id: row.id ?? row, // Ensure there's an `id` or unique identifier
            createdAt: row.createdAt ?? "",
            code: row?.code,
            name: row.name ?? "",
            aliasName: row.aliasName ? row.aliasName : row.name,
            price: row.price ?? "",
          }));
          setTreatmentList(modifiedServices);
        }
      })
      .catch((err: any) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          // navigate("/login");
        }
      });
  };

  const handleInputChange = useCallback(
    (id: string, value: string) => {
      setTreatmentList((prevList) =>
        prevList.map((item) =>
          item.id === id ? { ...item, aliasName: value } : item
        )
      );
    },
    [setTreatmentList]
  );

  const handleSubmit = () => {
    // Check if any aliasName is empty
    const hasEmptyAlias = treatmentList.some((tl) => !tl.aliasName.trim());

    if (hasEmptyAlias) {
      setError("Please fill all Alias Names before submitting.");
      // Scroll to the top of the page so the user sees the error message
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }

    // Proceed with submission if all aliasName are filled
    setError(null); // Clear error if validation passes

    const filteredPayload = isAllServicesTabSelected
      ? treatmentList.map((tl) => ({
          treatmentId: tl.id,
          aliasName: tl.aliasName,
        }))
      : { treatmentIds: treatmentList.map((tl) => tl?.id) };

    apiRequest({
      url: isAllServicesTabSelected
        ? `clinic/${hospitalId}/treatment/assign`
        : `clinic/${hospitalId}/treatment/delete`,
      data: filteredPayload,
      method: isAllServicesTabSelected ? "POST" : "DELETE",
    })
      .then(() => {
        onCancel && onCancel(false);
      })
      .catch((err) => {
        console.error("Submission error:", err);
      });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ padding: 2 }}>
            {/* Error message displayed in red above the table */}
            {error && (
              <Box sx={{ color: "red", marginBottom: "16px" }}>{error}</Box>
            )}

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>S.No</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Added Date</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Alias Name</TableCell>
                    <TableCell>Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {treatmentList.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row?.code}</TableCell>
                      <TableCell>
                        {moment(row.createdAt).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      {isAllServicesTabSelected ? (
                        <TableCell>
                          <TextField
                            value={row.aliasName}
                            onChange={(e) =>
                              handleInputChange(row.id, e.target.value)
                            }
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                        </TableCell>
                      ) : (
                        <TableCell>{row.aliasName}</TableCell>
                      )}
                      <TableCell>{row.price}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            mt={2}
          >
            <CustomButton
              border="1px solid #000000"
              color="#000000"
              title="Cancel"
              bgColor="#ffffff"
              handleButtonClick={onCancel}
              borderRadius="5px"
            />
            <Box marginLeft={1}>
              <CustomButton
                title={isAllServicesTabSelected ? "Assign" : "Unassign"}
                type="button"
                borderRadius="5px"
                handleButtonClick={handleSubmit}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TreatmentList;
