import Box from "@mui/material/Box";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import React, {
  createElement,
  CSSProperties,
  FunctionComponent,
  memo,
  ReactNode,
  useEffect,
  useState,
} from "react";
import {
  DefaultCellComponent,
  IRATableCell,
  IRATableRow,
  SelectCell,
} from "./ira-table.components";
import { IRATableColumnProps, IRATableProps } from "./ira-table.interfaces";
import { IRATableStyles } from "./ira-table.styles";

import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import { Button, Tooltip } from "@mui/material";

const headerGlobalStyles: CSSProperties = {
  width: "100%",
};
const selectionInitialState = {
  index: [],
  data: [],
  lastChangedData: null,
};
const DEFAULT_EMPTY_CELL_COLUMNS = ["select", "action"];

export const IRATable = (props: IRATableProps) => {
  const {
    cellComponent,
    headerCellComponent,
    rowComponent,
    rowHeaderComponent,
    rowStyle,
    headerStyle,
    rowClassName,
    headerClassName,
    onSort,
    sortedBy,
    sortableLabelProps,
    selectVariant,
    onSelectionChange,
    includeClearRadio = false,
    disableSelectAll = false,
    innerRef,
    className,
  } = props;

  //#region with Enhanced Features

  const [selection, setSelection] = useState<any>(selectionInitialState);
  const [{ data, columns }, setDataColumns] = useState<Partial<IRATableProps>>(
    {}
  );
  const handleChange = async (
    key: number | string,
    currentData: any,
    checked: boolean
  ) => {
    if (selectVariant === "radio") {
      setSelection({
        index: checked ? [key] : [],
        data: checked ? [currentData] : [],
        lastChangedData: checked ? currentData : null,
      });
      setDataColumns(({ data, columns }: any) => ({
        columns: columns,
        data: data.map((_: any, index: number) => ({
          ..._,
          select: {
            data: _?.select?.data,
            selected: key === index ? checked : _?.select?.selected,
          },
        })),
      }));
    }
    if (selectVariant === "checkbox") {
      setSelection(({ index, data }: any) => {
        if (checked)
          return {
            index: index?.concat(key),
            data: data?.concat(currentData),
            lastChangedData: currentData,
          };
        else {
          const rIndex = index.indexOf(key);
          return {
            index: index.filter((_: any, key: any) => key !== rIndex),
            data: data.filter((_: any, key: any) => key !== rIndex),
            lastChangedData: null,
          };
        }
      });
    }
  };
  const handleSelectAll = (e: React.SyntheticEvent, checked: boolean) => {
    let indices = [] as any,
      selected = [] as any;
    setDataColumns(({ data, columns }: any) => ({
      columns,
      data: data?.map((_: any, index: number) => {
        if (checked) {
          indices.push(index);
          selected.push(_?.select?.data);
        }
        return {
          ..._,
          select: {
            data: _.select?.data,
            selected: checked,
          },
        };
      }),
    }));
    setSelection({
      data: selected,
      index: indices,
      lastChangedData: checked ? props?.data.map((_) => _?.select?.data) : [],
    });
  };
  const extendedFeatures: {
    [key: string]: IRATableColumnProps;
  } = {
    checkbox: {
      name: "select",
      sortable: false,
      label: (
        <Checkbox
          icon={<img src={"https://svgur.com/i/11oX.svg"} />}
          checkedIcon={<img src={"https://svgur.com/i/11oX.svg"} />}
          color="primary"
          onChange={handleSelectAll}
          checked={
            (props?.data?.length > 0 &&
              selection?.data?.length === props?.data?.length) ??
            false
          }
          disabled={disableSelectAll ?? false}
          style={{ padding: 0 }}
        />
      ),
      // component: SelectCell,
      style: { width: "20px" },
    },
    radio: {
      name: "select",
      label:
        includeClearRadio && selection.index.length === 1 ? (
          <IconButton
            style={{
              // as border radius is effecting the
              paddingLeft: "-2px !important",
            }}
            size="small"
            onClick={() => setSelection(selectionInitialState)}
          >
            <Tooltip title="Clear selection" placement="top">
              <HighlightOffIcon />
            </Tooltip>
          </IconButton>
        ) : (
          ""
        ),
      sortable: false,
      // component: SelectCell,
      style: { paddingRight: "9px", width: "40px" },
    },
  };

  useEffect(() => {
    if (selectVariant) {
      setDataColumns({
        columns: [].concat(
          extendedFeatures[selectVariant] as any,
          ...(props.columns as any)
        ),
        data:
          selectVariant === "radio"
            ? props?.data?.map((_, index) => {
                if (_?.select?.selected && !selection?.index?.length) {
                  setSelection({
                    index: [index],
                    data: _?.select?.data,
                    lastChangedData: null,
                  });
                }
                return _;
              })
            : props?.data.map((_: any, index) => {
                if (_?.select?.selected) {
                  setSelection((prevSelection: any) => ({
                    index: [...(prevSelection?.index ?? []), index],
                    data: [...(prevSelection?.data ?? []), data],
                    lastChangedData: null,
                  }));
                }
                return _;
              }),
      });
    } else {
      setDataColumns({ data: props.data, columns: props.columns });
    }
  }, [props.data, props.columns]);

  useEffect(() => {
    if (onSelectionChange && selectVariant)
      onSelectionChange(
        selectVariant,
        selection.lastChangedData,
        selection.data
      );
  }, [selection]);
  //#endregion

  const _renderCell = (
    data: any,
    component: ReactNode,
    name: string,
    key?: number | string,
    style?: CSSProperties,
    className?: string
    // stickyHeader?: string,
  ) => {
    const renderComponent = (data?.component ??
      component ??
      cellComponent) as FunctionComponent;
    const compositeKey = `${name}-${key}`;
    const renderProps =
      typeof data === "object"
        ? { ...data, key: compositeKey, domid: compositeKey, style, className }
        : { data, key: compositeKey, domid: compositeKey, style, className };

    return renderComponent
      ? createElement(
          renderComponent,
          name === "select"
            ? {
                ...renderProps,
                variant: selectVariant,
                onChange: (
                  e: React.ChangeEvent<HTMLInputElement>,
                  checked?: boolean
                ) =>
                  handleChange(
                    (key = 0),
                    data?.data,
                    checked ?? e?.target?.checked
                  ),
                value: selection?.index[0] === key,
                checked: selection?.index?.includes(key),
              }
            : renderProps,
          data?.children ?? null
        )
      : DefaultCellComponent({ compositeKey, style, className, data } as any);
  };
  const _renderHeaderData = () =>
    createElement(
      (rowHeaderComponent ?? IRATableRow) as FunctionComponent,
      null,
      columns?.map((col: IRATableColumnProps, index: number) =>
        createElement(
          (col.headerCellComponent ??
            headerCellComponent ??
            IRATableCell) as FunctionComponent,
          { key: index },
          col.sortable ? (
            <TableSortLabel
              active={sortedBy?.name === col.name}
              direction={sortedBy?.name === col.name ? sortedBy?.order : "asc"}
              onClick={() =>
                onSort
                  ? onSort(col.name, sortedBy?.order === "asc" ? "desc" : "asc")
                  : {}
              }
              {...sortableLabelProps}
            >
              {generateHeaderCell(col)}
            </TableSortLabel>
          ) : (
            generateHeaderCell(col)
          )
        )
      )
    );

  const headerMergedStyles = {
    ...(headerGlobalStyles ?? {}),
    ...(headerStyle ?? {}),
  };

  return (
    <IRATableStyles>
      <TableContainer>
        <Table
          // stickyHeader={stickyHeader}
          aria-label="IRA table"
          // innerRef={innerRef}
          className={className}
          {
            // inner ref is currently defined only for sticky use case
            ...(innerRef ? { stickyHeader: true } : {})
          }
        >
          <TableHead style={headerMergedStyles} className={headerClassName}>
            {_renderHeaderData()}
          </TableHead>

          <TableBody>
            {data?.length
              ? data?.map((row: any, index: number) =>
                  createElement(
                    (rowComponent ?? IRATableRow) as FunctionComponent,
                    {
                      key: index,
                      style: rowStyle,
                      className: rowClassName,
                    } as any,
                    columns?.map((col: IRATableColumnProps, _: number) =>
                      _renderCell(
                        row[col.name],
                        col.component,
                        col.name,
                        index,
                        col.style,
                        col.className
                      )
                    )
                  )
                )
              : createElement(
                  (rowComponent ?? IRATableRow) as FunctionComponent,
                  { style: rowStyle, className: rowClassName } as any,
                  columns?.map((col: IRATableColumnProps, _: number) =>
                    _renderCell(
                      _renderNoDataColumn(col),
                      null,
                      col.name,
                      _,
                      col.style,
                      col.className
                    )
                  )
                )}
          </TableBody>
        </Table>
      </TableContainer>
    </IRATableStyles>
  );
};

export default memo(IRATable);

/**
 * @method generateHeaderCell
 * @description this will generate the header cell properly based on col props
 * @param {IRATableColumnProps} column column with props
 * @return {React.ReactNode} generated label component
 */
function generateHeaderCell(column: IRATableColumnProps): React.ReactNode {
  if (!column?.label) {
    return null;
  }

  const handleHeaderClick = (e?: React.SyntheticEvent) => {
    if (e?.stopPropagation) {
      e.stopPropagation();
    }
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (column?.headerCallback) {
      column.headerCallback();
    }
  };

  if (column?.headerCallback) {
    return (
      <Button variant="contained" onClick={handleHeaderClick}>
        {column?.label}
      </Button>
    );
  }
  return column?.label;
}

/**
 * @method _renderNoDataColumn
 * @param {IRATableColumnProps} column table column to render the no data cell
 * @returns no data component of the column or empty strings if it default empty cell component else "No Data" string
 */
function _renderNoDataColumn(column: IRATableColumnProps): ReactNode {
  // early return instead of ternary return
  if (column.noDataComponent) {
    return createElement(column.noDataComponent, {}, null);
  }
  if (DEFAULT_EMPTY_CELL_COLUMNS.includes(column.name)) {
    // do not return null, as this method is being used as Component in createElement
    return "";
  }
  return "No Data";
}
