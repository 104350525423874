import React from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { CSSObject } from "@emotion/react";

interface CustomCheckboxProps {
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  field?: any;
  checked?: boolean;
  labelCustomStyles?: CSSObject;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  label,
  onChange,
  checked,
  field,
  labelCustomStyles,
}) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            {...field}
            onChange={onChange}
            checked={checked}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 20,
              },
            }}
          />
        }
        label={<p>{label}</p>}
        labelPlacement="end"
        sx={{
          "& .MuiFormControlLabel-label": {
            ...labelCustomStyles,
          },
        }}
      />
    </FormGroup>
  );
};

export default CustomCheckbox;
