import React from "react";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import { styles } from "./styles";
import { theme } from "../../theme/theme";
import style from "../../screens/CasesAndClaims/case-list.module.css"

interface BreadcrumbProps {
  breadcrumbsArr: any;
  title: string;
}

const Breadcrumb = (props: BreadcrumbProps) => {
  const { title, breadcrumbsArr } = props;
  return (
    <Box style={styles.breadcrumbContainer}>
      <Typography
        sx={styles.breadcrumbTitle}
        className={style.cases_list_title}
      >
        {title}
      </Typography>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbsArr}
      </Breadcrumbs>
    </Box>
  );
};

export default Breadcrumb;
