import { styled } from "@mui/system";

export const IRATableStyles = styled("div")({
  grayClr: {
    color: "#eeeeee",
  },
  icon: {
    width: "15px",
    height: "15px",
  },
  tableData: {
    "& .MuiTableContainer-root": {
      minHeight: "150px",
      borderRadius: "4px",
      transition: "0.3s",
    },
    "& .MuiTable-root": {
      visibility: "visible",
      transition: "0.3s",
      border: "1px solid rgba(0,0,0,0.1)",
      borderRadius: "4px",
    },
    "& .MuiTableRow-root": {
      borderBottom: 0,
    },
    "& .MuiTableCell-body": {
      backgroundColor: "#ffffff",
    },
    "& .MuiTableCell-head": {
      borderBottom: "0px",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "21px",
      color: "#0A192B",
      textTransform: "capitalize",
      textAlign: "center",
      "&:first-of-type": {
        paddingLeft: "20px !important",
        textAlign: "left",
      },
    },
    "& .MuiGrid-container": {
      width: "100%",
      margin: "0px",
    },
    "& .MuiGrid-item": {
      padding: "0px",
    },
    "& th": {
      padding: "10px 12px 10px 12px",
      textTransform: "capitalize",
      borderBottom: "0px",
    },
    "& th:first-of-type": {
      paddingLeft: "20px !important",
      borderRadius: "3px 0 0 0",
    },
    "& th:last-child": {
      borderRadius: "0 4px 0 0",
    },
    "& td": {
      padding: "16px 12px 16px 12px",
      lineHeight: "20px",
      verticalAlign: "middle",
      textAlign: "center",
    },
    "& td:first-of-type": {
      paddingLeft: "20px",
      textAlign: "left",
    },
    "& tbody": {
      "& tr": {
        borderBottom: "1px solid #eeeeee !important",
        borderCollapse: "collapse",
        "&:last-child": {
          "& td": {
            borderBottom: "none !important",
          },
          "& td:first-of-type": {
            borderRadius: "0 0 0 3px !important",
          },
          "& td:last-child": {
            borderRadius: "0 0 3px 0 !important",
          },
        },
      },
    },
  },
});
