import React from "react";
import CommonDashboard from "./common-dashboard";
import SuperAdminDashboard from "../Users/Dashboard";
import { useAppContext } from "../../app/context/AppContext";

const Dashboard = () => {
  const { user } = useAppContext() as any;

  return (
    <>
      {user?.userType === "superAdmin" ? (
        <SuperAdminDashboard />
        ) : (
        <CommonDashboard />
      )}
    </>
  );
};

export default Dashboard;
